import * as React from 'react';
import { FunctionComponent } from 'react';
import { IconContext } from 'react-icons';
import { FaCircle } from 'react-icons/fa';
import { StatusCardStatusService } from './StatusCardStatusService';

interface StatusIconProps {
    statusId: number,
    disabled?: boolean,
    statusCardStatusService: StatusCardStatusService
}

const StatusIcon: FunctionComponent<StatusIconProps> = ({ statusId, disabled, statusCardStatusService }) => {

    const classes = [];
    classes.push('dashboard-table-action-status-icon');
    if (disabled === true)
        classes.push('disable');

    const status = statusCardStatusService.getStatus(statusId);
    classes.push(status.IconClass);
    return <IconContext.Provider value={{ className: classes.join(' ') }}>
        <FaCircle />
    </IconContext.Provider>;
}

export default StatusIcon;