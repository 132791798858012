import React, { FunctionComponent } from "react";
import { UserCourse } from "./UserCourse";
import { IUserEducationViewModel } from "..";
import { UserListCourseViewModel } from '@murphy-frontend/web-core/features/UsersAdmin/components/UsersList';

interface IProps {
  user: IUserEducationViewModel;
  filterCourse?: number[];
  allCourses?: UserListCourseViewModel[];
  filterLanguage?: number[];
  filterStatus?: string[];
}
export const UserCourses = ({
  user,
  filterCourse,
  filterLanguage,
  filterStatus,
  allCourses,
}: IProps) => {
  const userCourseList: number[] = [];
  const sortedCourses = user?.courses?.sort((a, b) =>
    a.trainingTag.toLowerCase() < b.trainingTag.toLowerCase() ? 1 : -1
  );
  const filteredList = sortedCourses?.filter((course) => {
    if (userCourseList.includes(course.id)) {
      return;
    }
    if (!filterCourse.includes(course.id)) {
      return;
    }
    userCourseList.push(course.id);
    return course;
  });
  return (
    <div className="education-admin-user">
      <div className="table-column-group-header-label education-admin-username">
        {user.email}
      </div>
      <div className="education-admin-course-list">
        {filteredList?.map((course) => {
          if (!filterCourse.includes(course.id) || !filterLanguage.includes(course.TrainingLanguageId) || !filterStatus.includes(course.trainingTag)) {
            return;
          }
          return (
            <React.Fragment key={course.id}>
              <UserCourse course={course} allCourses={allCourses} />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
