/* eslint-disable no-undef */
export const LocalStorageKeys = Object.freeze({
  SelectedCustomerId: 'selectedCustomerId',
  CurrentObjectId: 'currentObjectId',
  Language: 'lang',
  Translations: 'translations',
  SelectedTimeZone: 'selectedTimeZone',
  LogoutTriggeredFromPortal: 'logoutTriggeredFromPortal',
  SelectedCourseLanguages: 'selectedCourseLanguages',
  CourseFilter: 'courseFilter',
  Provider: 'provider',
  LoginFirstRedirectHasBeenMade: 'loginFirstRedirectHasBeenMade',
});

export function setSelectedCustomerId(customerId: string) {
  window.localStorage.setItem(LocalStorageKeys.SelectedCustomerId, customerId);
}

export function removeSelectedCustomerId() {
  window.localStorage.removeItem(LocalStorageKeys.SelectedCustomerId);
}

export function getSelectedCustomerId(): string {
  return window.localStorage.getItem(LocalStorageKeys.SelectedCustomerId);
}

export function setCurrentObjectId(objectId: string) {
  window.localStorage.setItem(LocalStorageKeys.CurrentObjectId, objectId);
}

export function getCurrentObjectId(): string {
  return window.localStorage.getItem(LocalStorageKeys.CurrentObjectId);
}

export function removeCurrentObjectId() {
  window.localStorage.removeItem(LocalStorageKeys.CurrentObjectId);
}

export function setLanguage(lang: string) {
  window.localStorage.setItem(LocalStorageKeys.Language, lang);
}

export function removeLanguage() {
  window.localStorage.removeItem(LocalStorageKeys.Language);
}

export function getLanguage(): string {
  return window.localStorage.getItem(LocalStorageKeys.Language);
}

export function setTranslations(translations: Record<string, string>) {
  window.localStorage.setItem(LocalStorageKeys.Translations, translations);
}

export function removeTranslations() {
  window.localStorage.removeItem(LocalStorageKeys.Translations);
}

export function getTranslations(): any {
  return window.localStorage.getItem(LocalStorageKeys.Translations);
}

export function setSelectedTimeZone(selectedTimeZone: string) {
  window.localStorage.setItem(LocalStorageKeys.SelectedTimeZone, selectedTimeZone);
}

export function removeSelectedTimeZone() {
  window.localStorage.removeItem(LocalStorageKeys.SelectedTimeZone);
}

export function getSelectedTimeZone(): string {
  return window.localStorage.getItem(LocalStorageKeys.SelectedTimeZone);
}

export function setLogoutTriggeredFromPortal(loginTriggeredFromPortal: boolean) {
  return window.localStorage.setItem(LocalStorageKeys.LogoutTriggeredFromPortal, loginTriggeredFromPortal.toString());
}

export function removeLogoutTriggeredFromPortal() {
  window.localStorage.removeItem(LocalStorageKeys.LogoutTriggeredFromPortal);
}

export function getLogoutTriggeredFromPortal(): boolean {
  return window.localStorage.getItem(LocalStorageKeys.LogoutTriggeredFromPortal) === "true" || false;
}

export function setSelectedCourseLanguages(value: string) {
  window.localStorage.setItem(LocalStorageKeys.SelectedCourseLanguages, value);
};
export function getSelectedCourseLanguages() {
  return window.localStorage.getItem(LocalStorageKeys.SelectedCourseLanguages);
};
export function setCourseFilter(value: string) {
  window.localStorage.setItem(LocalStorageKeys.SelectedCourseLanguages, value);
};
export function getCourseFilter() {
  return window.localStorage.getItem(LocalStorageKeys.SelectedCourseLanguages);
};

export function clearNonPersistentItems() {
  const lang = getLanguage();
  const timeZone = getSelectedTimeZone();
  const selectedCustomerId = getSelectedCustomerId();
  const logoutTriggeredFromPortal = getLogoutTriggeredFromPortal();
  const translations = getTranslations();
  const selectedCourseLanguages = getSelectedCourseLanguages();

  window.localStorage.clear();

  if (lang) {
    setLanguage(lang);
  }
  if (translations) {
    setTranslations(translations);
  }
  if (timeZone) {
    setSelectedTimeZone(timeZone);
  }
  if (selectedCustomerId) {
    setSelectedCustomerId(selectedCustomerId);
  }
  if (logoutTriggeredFromPortal) {
    setLogoutTriggeredFromPortal(logoutTriggeredFromPortal);
  }
  if (selectedCourseLanguages) {
    setSelectedCourseLanguages(selectedCourseLanguages);
  }
}
