import * as React from 'react'
import { ActionsCard, UpsertActionsCard } from '../../api/ActionsCardApi'
import DashboardTable from '@murphy-frontend/web-core/components/DashboardTable'
import { useState, useEffect } from 'react';
import { useUpsertActionsCard } from '../../mutations';
import { useGetDashboardActionsCards } from '../../queries';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import ActionsCardsTableRow from './ActionsCardsTableRow';
import ActionsCardsTableHeader from './ActionsCardsTableHeader';
import DashboardSearch from '@murphy-frontend/web-core/components/DashboardSearch';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import { TimeZoneType } from "@murphy-frontend/common/services/TimeService"

type ActionsCardsTableProps = {
    dashboardId: string,
    translations: Record<string, string>,
    timeZone: TimeZoneType,
}

const ActionsCardsTable = ({ dashboardId, translations, timeZone }: ActionsCardsTableProps) => {
    const [actionsCards, setActionsCards] = useState<ActionsCard[]>([]);
    const [filteredActionsCards, setFilteredActionsCards] = useState<ActionsCard[]>([]);
    const [currentSearchText, setCurrentSearchText] = useState('');
    const [searchActive, setSearchActive] = useState(false);

    const [rows, setRows] = useState([]);

    const { mutate: upsertActionsCard, isPending: upsertActionsCardIsPending } = useUpsertActionsCard(dashboardId);
    const { openModal, closeModal } = useModal();

    const { data: actionsCardsData, error: actionsCardsError,
        isError: actionsCardsIsError, isLoading: actionsCardsIsLoading } = useGetDashboardActionsCards(dashboardId);

    function statusCardSortFunction(a: ActionsCard, b: ActionsCard) {
        return (+b.Important) - (+a.Important) || a.Title.localeCompare(b.Title);
    }

    useEffect(() => {
        if (actionsCardsIsLoading === false) {
            const filteredCards = actionsCardsData.filter(p => p.archived === false)
                .sort(statusCardSortFunction);
            setActionsCards(filteredCards);
            setFilteredActionsCards(filteredCards);
        }
    }, [actionsCardsIsLoading, actionsCardsData])

    const onClickUpdateStatus = (id: number, status: number) => {
        const card = actionsCards.find(p => p.ID === id);
        const updatedCard: UpsertActionsCard = {
            Title: card.Title,
            Message: card.Message,
            Status: status,
            ProvidedBy: card.ProvidedBy,
            Owner: card.Owner,
            isDecision: card.isDecision,
            Important: card.Important,
            isCommunication: card.isCommunication,
            ParentId: id,
        }
        upsertActionsCard(updatedCard);
    }

    const onToggleSearch = () => {
        setSearchActive(!searchActive);
    }

    const onSortActionCardsByCreated = (dir: 'asc' | 'desc') => {
        filteredActionsCards.sort((a: ActionsCard, b: ActionsCard) => {
            const ascResult: number = +(new Date(a.CreatedDateTime)) - +(new Date(b.CreatedDateTime));
            return dir === 'asc' ? ascResult : ascResult * -1;
        });
        const tableRows = getTableRows(filteredActionsCards);
        setRows(tableRows);
    }

    const getTableRows = (newFilteredCards: ActionsCard[]) => {
        return newFilteredCards.map((actionsCard) => {
            return <ActionsCardsTableRow
                translations={translations}
                timeZone={timeZone}
                actionsCard={actionsCard}
                openModal={openModal}
                closeModal={closeModal}
                dashboardId={dashboardId}
                updateStatus={onClickUpdateStatus} />
        });
    }

    useEffect(() => {
        let newFilteredCards = [...actionsCards];
        if (currentSearchText) {
            const searchValue = currentSearchText.toUpperCase();
            newFilteredCards =
                newFilteredCards.filter((p) =>
                    (p.Title && p.Title.toUpperCase().includes(searchValue)) ||
                    (p.Owner && p.Owner.toUpperCase().includes(searchValue)) ||
                    (p.ProvidedBy && p.ProvidedBy.toUpperCase().includes(searchValue)) ||
                    (p.Message && p.Message.toUpperCase().includes(searchValue)) ||
                    (p.user && p.user.toUpperCase().includes(searchValue))
                );
            setFilteredActionsCards(newFilteredCards);
        } else setFilteredActionsCards(actionsCards);
        const tableRows = getTableRows(newFilteredCards);
        setRows(tableRows);
        return () => {
            setRows([]);
        }
    }, [actionsCards, currentSearchText, translations, timeZone])

    const header = <ActionsCardsTableHeader
        timeZone={timeZone}
        translations={translations}
        dashboardId={dashboardId}
        openModal={openModal}
        closeModal={closeModal}
        toggleSearch={onToggleSearch}
        sortActionCardsByCreated={onSortActionCardsByCreated}
    />

    const subHeader = <div>
        <DashboardSearch
            value={currentSearchText}
            setValue={setCurrentSearchText}
            placeholder={translations['placeholder-lang-search-action']}
            onClickClear={() => setCurrentSearchText('')} />
    </div>

    if (actionsCardsIsLoading || upsertActionsCardIsPending) {
        return (<Spinner isGlobal />);
    }

    return <DashboardTable
        rows={rows}
        header={header}
        subHeader={searchActive && subHeader}
    />
}

export default ActionsCardsTable;