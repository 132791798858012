import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useInjection } from "../../contexts/InversifyContext";
import { DashboardEntityTypes } from "../../enums";
import DashboardApiType, {
  IDashboardApi,
  CreateDashboardRequest,
  PostDashboardGoalRequest,
  PostDashboardAssumptionRequest,
  PostDashboardBackGroundRequest,
  PostDashboardActionRequest,
  PatchDashboardActionRequest,
  PostDashboardStatusRequest,
  PatchDashboardStatusRequest,
  PostDashboardInformationRequest,
  PatchDashboardInformationRequest,
  PostDashboardPartyRequest,
  PostDashboardPartyPeopleRequest,
  MutateDashboardFileRequest,
  UpdateDashboardRequest,
  PostDashboardAlertUsersRequest,
} from "../../interfaces/IDashboardApi";
import QueryKeys from "./QueryKeys";

export const useCreateDashboard = (customerId: string) => {
  const queryClient = useQueryClient();
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );

  return useMutation({
    mutationFn: (createDashboardRequest: CreateDashboardRequest) =>
      dashboardApi.createDashboard(createDashboardRequest),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.list(customerId) });
    },
  });
};

export const useEditDashboard = (customerId: string, dashboardId: string) => {
  const queryClient = useQueryClient();
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );

  return useMutation({
    mutationFn: (updateDashboardRequest: UpdateDashboardRequest) =>
      dashboardApi.editDashboard(dashboardId, updateDashboardRequest),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.list(customerId) });
      queryClient.invalidateQueries({
        queryKey: QueryKeys.dashboardentity(
          dashboardId,
          DashboardEntityTypes.Dashboard
        ),
      });
    },
  });
};

export const usePostDashboardGoal = (dashboardId: string | undefined) => {
  const queryClient = useQueryClient();
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );

  return useMutation({
    mutationFn: (postDashboardGoalRequest: PostDashboardGoalRequest) =>
      dashboardApi.postDashboardGoal(dashboardId, postDashboardGoalRequest),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.dashboardentity(
          dashboardId,
          DashboardEntityTypes.Goals
        ),
      });
    },
  });
};

export const usePostDashboardBackGround = (dashboardId: string) => {
  const queryClient = useQueryClient();
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );

  return useMutation({
    mutationFn: (
      postDashboardBackGroundRequest: PostDashboardBackGroundRequest
    ) =>
      dashboardApi.postDashboardBackGround(
        dashboardId,
        postDashboardBackGroundRequest
      ),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.dashboardentity(
          dashboardId,
          DashboardEntityTypes.BackGround
        ),
      });
    },
  });
};

export const usePostDashboardAssumption = (dashboardId: string) => {
  const queryClient = useQueryClient();
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );

  return useMutation({
    mutationFn: (
      postDashboardAssumptionRequest: PostDashboardAssumptionRequest
    ) =>
      dashboardApi.postDashboardAssumption(
        dashboardId,
        postDashboardAssumptionRequest
      ),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.dashboardentity(
          dashboardId,
          DashboardEntityTypes.Assumption
        ),
      });
    },
  });
};

export const usePostDashboardAction = (dashboardId: string) => {
  const queryClient = useQueryClient();
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );

  return useMutation({
    mutationFn: (postDashboardActionRequest: PostDashboardActionRequest) =>
      dashboardApi.postDashboardAction(dashboardId, postDashboardActionRequest),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.dashboardentity(
          dashboardId,
          DashboardEntityTypes.ActionsCard
        ),
      });
    },
  });
};

export const usePatchDashboardAction = (dashboardId: string) => {
  const queryClient = useQueryClient();
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );

  return useMutation({
    mutationFn: (patchDashboardActionRequest: PatchDashboardActionRequest) =>
      dashboardApi.patchDashboardAction(
        dashboardId,
        patchDashboardActionRequest
      ),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.dashboardentity(
          dashboardId,
          DashboardEntityTypes.ActionsCard
        ),
      });
    },
  });
};

export const usePostDashboardStatus = () => {
  const queryClient = useQueryClient();
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );

  return useMutation({
    mutationFn: (postDashboardStatusRequest: PostDashboardStatusRequest) =>
      dashboardApi.postDashboardStatus(
        postDashboardStatusRequest.DashboardId,
        postDashboardStatusRequest
      ),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.dashboardentity(
          variables.DashboardId,
          DashboardEntityTypes.StatusCard
        ),
      });
    },
  });
};

export const usePatchDashboardStatus = () => {
  const queryClient = useQueryClient();
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );

  return useMutation({
    mutationFn: (patchDashboardStatusRequest: PatchDashboardStatusRequest) =>
      dashboardApi.patchDashboardStatus(
        patchDashboardStatusRequest.DashboardId,
        patchDashboardStatusRequest
      ),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.dashboardentity(
          variables.DashboardId,
          DashboardEntityTypes.StatusCard
        ),
      });
    },
  });
};

export const usePostDashboardInformation = (dashboardId: string) => {
  const queryClient = useQueryClient();
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );

  return useMutation({
    mutationFn: (
      postDashboardInformationRequest: PostDashboardInformationRequest
    ) =>
      dashboardApi.postDashboardInformation(
        dashboardId,
        postDashboardInformationRequest
      ),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.dashboardentity(
          dashboardId,
          DashboardEntityTypes.InformationCard
        ),
      });
    },
  });
};

export const usePatchDashboardInformation = (dashboardId: string) => {
  const queryClient = useQueryClient();
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );

  return useMutation({
    mutationFn: (
      patchDashboardInformationRequest: PatchDashboardInformationRequest
    ) =>
      dashboardApi.patchDashboardInformation(
        dashboardId,
        patchDashboardInformationRequest
      ),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.dashboardentity(
          dashboardId,
          DashboardEntityTypes.InformationCard
        ),
      });
    },
  });
};

export const usePostDashboardParty = (dashboardId: string) => {
  const queryClient = useQueryClient();
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );

  return useMutation({
    mutationFn: (postDashboardPartyRequest: PostDashboardPartyRequest) =>
      dashboardApi.postDashboardParty(dashboardId, postDashboardPartyRequest),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.dashboardentity(
          dashboardId,
          DashboardEntityTypes.Party
        ),
      });
    },
  });
};

export const usePostDashboardPartyPeople = (dashboardId: string) => {
  const queryClient = useQueryClient();
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );

  return useMutation({
    mutationFn: (
      postDashboardPartyPeopleRequest: PostDashboardPartyPeopleRequest
    ) =>
      dashboardApi.postDashboardPartyPeople(
        dashboardId,
        postDashboardPartyPeopleRequest
      ),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.dashboardentity(
          dashboardId,
          DashboardEntityTypes.PartyPeople
        ),
      });
    },
  });
};

export const usePostDashboardAlertUsers = (dashboardId: string) => {
  const queryClient = useQueryClient();
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );

  return useMutation({
    mutationFn: (
      postDashboardAlertUsersRequest: PostDashboardAlertUsersRequest
    ) =>
      dashboardApi.postDashboardAlertUsers(
        dashboardId,
        postDashboardAlertUsersRequest
      ),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.dashboardentity(
          dashboardId,
          DashboardEntityTypes.AlertUsers
        ),
      });
    },
  });
};

export const useMutateDashboardFile = (dashboardId: string) => {
  const queryClient = useQueryClient();
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );

  return useMutation({
    mutationFn: (mutateDashboardFileRequest: MutateDashboardFileRequest) =>
      dashboardApi.mutateDashboardFile(dashboardId, mutateDashboardFileRequest),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.dashboardentity(
          dashboardId,
          DashboardEntityTypes.DashboardFile
        ),
      });
    },
  });
};
