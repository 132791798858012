export type Drone = {
  Id: number;
  Title: string; // Or maybe this should be called name?
  Tabs: ReadonlyArray<DroneTab>;
};

export type DroneTab = {
  Title: string;
  Components: ReadonlyArray<DroneComponent>;
};

export type DroneComponent =
  | DroneComponentTitle
  | DroneComponentAccordion
  | DroneComponentText
  | DroneComponentCheckList
  | DroneComponentCompletedButton;

export enum DroneComponentType {
  Title = "Title",
  Text = "Text",
  Accordion = "Accordion",
  CheckList = "CheckList",
  CompletedButton = "CompletedButton",
}

interface DroneComponentBase<T extends DroneComponentType> {
  __Type: T;
}

export interface DroneComponentTitle
  extends DroneComponentBase<DroneComponentType.Title> {
  Title: string;
}

export interface DroneComponentAccordion
  extends DroneComponentBase<DroneComponentType.Accordion> {
  Title: string;
  Content: string;
}

export interface DroneComponentCompletedButton
  extends DroneComponentBase<DroneComponentType.CompletedButton> {
  Title: string;
}

export interface DroneComponentText
  extends DroneComponentBase<DroneComponentType.Text> {
  Title?: string;
  Content: string;
}

export interface DroneComponentCheckList
  extends DroneComponentBase<DroneComponentType.CheckList> {
  Title: string;
  Items: ReadonlyArray<DroneComponentCheckListItem>;
}

export type DroneComponentCheckListItem =
  | DroneComponentCheckListItemTextInput
  | DroneComponentCheckListCheckbox
  | DroneComponentCheckListSelect;

export enum DroneCheckListItemType {
  TextInput = "TextInput",
  Checkbox = "Checkbox",
  Select = "Select",
}

interface DroneCheckListItemBase<T extends DroneCheckListItemType> {
  __Type: T;
  FieldId: string; // Used when updating value
  Label: string;
}

export interface DroneComponentCheckListItemTextInput
  extends DroneCheckListItemBase<DroneCheckListItemType.TextInput> {
  Placeholder?: string;
  Value?: string;
}

export interface DroneComponentCheckListCheckbox
  extends DroneCheckListItemBase<DroneCheckListItemType.Checkbox> {
  Value?: boolean;
}

export interface DroneComponentCheckListSelect
  extends DroneCheckListItemBase<DroneCheckListItemType.Select> {
  Options: ReadonlyArray<DroneComponentCheckListSelectOption>;
  Value?: string;
}

export interface DroneComponentCheckListSelectOption {
  Label: string;
  Value: string;
}
