import * as React from 'react';
import { FunctionComponent } from 'react';
import { IconContext } from 'react-icons';
import { RiCheckboxBlankFill, RiCheckboxBlankLine, RiCheckboxFill } from 'react-icons/ri';
import { ActionCardStatuses } from '@murphy-frontend/common/enums';

interface ActionsStatusIconProps {
    status: number,
    disabled?: boolean,
}

const ActionsStatusIcon: FunctionComponent<ActionsStatusIconProps> = ({ status, disabled }) => {

    const classes = [];
    classes.push('dashboard-table-action-status-icon');
    if (disabled === true)
        classes.push('disable');

    if (status === ActionCardStatuses.NotStarted) {
        classes.push('notstarted');
        return <IconContext.Provider value={{ className: classes.join(' ') }}>
            <RiCheckboxBlankLine />
        </IconContext.Provider>;
    }
    if (status === ActionCardStatuses.Ongoing) {
        classes.push('ongoing');
        return <IconContext.Provider value={{ className: classes.join(' ') }}>
            <RiCheckboxBlankFill />
        </IconContext.Provider>;

    }
    if (status === ActionCardStatuses.Done) {
        return <IconContext.Provider value={{ className: classes.join(' ') }}>
            <RiCheckboxFill />
        </IconContext.Provider>;
    }
}

export default ActionsStatusIcon;