import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';
import { PartyPeople } from '../../api/PartyPeopleApi';
import ExpandableRow from '@murphy-frontend/web-core/components/DashboardTable/ExpandableRow';
import PartyPeopleExpandedContent from './PartyPeopleExpandedContent';

interface PartyPeopleSubListItemProps {
    partyPeople: PartyPeople,
    dashboardId: string,
    openModal: any,
    closeModal: any,
    translations: Record<string, string>,
}

const PartyPeopleSubListItem: FunctionComponent<PartyPeopleSubListItemProps>
    = ({ partyPeople,
        dashboardId,
        openModal,
        closeModal,
        translations, }) => {

        const header = <>
            <div className="dashboard-table-row-text">
                <b>{translations['class-lang-ny-aktor-title']}:&nbsp;&nbsp;{partyPeople.Name}</b>
            </div>
        </>

        const addedHeaderClasses = [];
        addedHeaderClasses.push('dashboard-table-row-subgrid-header');
        const addedExpandedContentClasses = [];
        addedExpandedContentClasses.push('dashboard-table-row-subgrid');

        const expandableContent =
            <PartyPeopleExpandedContent
                partyPeople={partyPeople}
                dashboardId={dashboardId}
                openModal={openModal}
                closeModal={closeModal}
                translations={translations}
                hideUser={true} />;


        return (<>
            <ExpandableRow
                addHeaderClasses={addedHeaderClasses}
                headerContent={header}
                addExpandedContentClasses={addedExpandedContentClasses}
                expandedContent={expandableContent} />
        </>)
    }

export default PartyPeopleSubListItem;