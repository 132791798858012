
import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";
import { AxiosResponse } from "axios";
import { CustomerBlockDto, CustomerRequirementLevelDto, RequirementBlockDto, RequirementCategoryDto, UpdateBlockStatusesRequest, UpdateCustomerGoalLevelRequest } from "./models";

export class RequirementApi {
    constructor(
        private apiService: IApiService,
    ) { }

    getRequirementBlocks = async (): Promise<RequirementBlockDto[]> => {
        const url = 'api/RequirementBlock';
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    getCustomerRequirementBlocks = async (customerId: string): Promise<CustomerBlockDto[]> => {
        const url = `api/Customer/${customerId}/RequirementBlock`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    updateCustomerRequirementBlockStatus = (request: UpdateBlockStatusesRequest): Promise<AxiosResponse> => {
        const url = `api/Customer/${request.CustomerId}/RequirementBlock`;
        return this.apiService.callApi(url, 'POST', request);
    }

    getRequirementLevels = async (customerId: string): Promise<CustomerRequirementLevelDto[]> => {
        const url = `api/Customer/${customerId}/RequirementLevel`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    updateCustomerGoalLevel = (request: UpdateCustomerGoalLevelRequest): Promise<AxiosResponse> => {
        const url = `api/Customer/${request.CustomerId}/RequirementLevel`;
        return this.apiService.callApi(url, 'POST', request);
    }

    getRequirementCategories = async (): Promise<RequirementCategoryDto[]> => {
        const url = 'api/RequirementCategory';
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }
}