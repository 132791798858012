import React, { useState, useEffect, FunctionComponent } from 'react';
import { IoPersonAddSharp } from 'react-icons/io5';
import { IconContext } from 'react-icons/lib';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import { ContactListPaginated } from './components/ContactListPaginated';
import EditContact from './components/EditContact';
import SearchBar from '@murphy-frontend/web-core/components/SearchBar';
import { useGetContacts, useGetCustomerContactOptionSets } from '../../common/api/Contacts/queries';
import { deleteContactMutation } from '../../common/api/Contacts/mutations';
import { useUserPreferences } from '../../common/contexts/UserPreferencesContext';
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';
import { ContactModel, OptionSetModel } from '../../common/api/Contacts/ContactsApi';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { useCustomer } from '../../common/contexts/CustomerContext';

const Contacts: FunctionComponent = () => {
  const { customer } = useCustomer();
  const { translations } = useUserPreferences();
  const { openModal, closeModal } = useModal();

  const [error, setError] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [optionSets, setOptionSets] = useState([]);

  const [filteredContacts, setFilteredContacts] = useState([]);
  const [currentSearchText, setCurrentSearchText] = useState('');

  const {
    isLoading: isLoadingContacts, isError: isErrorContacts, data: dataContacts, error: errorContacts,
  } = useGetContacts(customer?.Id);

  const {
    isLoading: isLoadingCustomerContactOptionSets, isError: isErrorCustomerContactOptionSets, data: dataCustomerContactOptionSets,
    error: errorCustomerContactOptionSets,
  } = useGetCustomerContactOptionSets(customer?.Id);

  const { mutate: delMutate, isPending: deleteIsLoading } = deleteContactMutation(translations);

  const createContactViewModels = (data: ContactModel[]) => {
    const mappedData = data.map((p) => ({
      name: p.Name,
      id: p.ID,
      email: p.Email,
      mobile: p.MobileNr,
      type: 'contact',
      selectedOptions: p.SelectedOptions.map((q) => ({
        id: q.ID,
        value: q.Value,
        optionSetId: q.OptionSetId,
        optionSetName: q.OptionSetName,
      })),
    }));

    mappedData.sort((a, b) => a.name.localeCompare(b.name));
    return mappedData;
  };

  const onGetOptionSetSuccess = (data: OptionSetModel[]) => {
    const mappedData = data.map((p) => ({
      name: p.Name,
      id: p.ID,
      options: p.Options.map((q) => ({
        id: q.ID,
        value: q.Value,
      })),
    }
    ));

    mappedData.sort((a, b) => a.name.localeCompare(b.name));
    setOptionSets(mappedData);
  };

  const onFail = (errorData) => {
    if (errorData.statusText) {
      setError(errorData.statusText);
    } else {
      setError('Error');
    }
  };

  function ErrorMessage() {
    return <p>{error}</p>;
  }

  function DeleteConfirmationBody({ name }) {
    return (
      <p>
        {translations.delete}
        {' '}
        <strong>{name}</strong>
        {' '}
        ?
      </p>
    );
  }

  const onClickNewContact = () => {
    const modalConf: ModalConfiguration = {
      title: translations.newcontact,
      body: <EditContact
        translations={translations}
        customerId={customer.Id}
        optionSets={optionSets}
        isEditing={false}
        onSuccess={() => closeModal()}
        onFail={() => closeModal()}
      />,
      hideButton: true,
      type: 'info',
    };

    openModal(modalConf);
  };

  const onClickEditContact = (contactId: number) => {
    const contactToEdit = contacts.filter((p) => p.id === contactId)[0];

    const modalConf: ModalConfiguration = {
      title: translations.updatecontact,
      body: <EditContact
        translations={translations}
        customerId={customer.Id}
        contactId={contactToEdit.id}
        nameInit={contactToEdit.name}
        emailInit={contactToEdit.email}
        mobileInit={contactToEdit.mobile}
        optionSets={optionSets}
        selectedOptions={contactToEdit.selectedOptions}
        isEditing
        onSuccess={() => closeModal()}
        onFail={() => closeModal()}
      />,
      hideButton: true,
      type: 'info',
    };

    openModal(modalConf);
  };

  const onClickDeleteContact = (contactId: number) => {
    const contactToEdit = contacts.filter((p) => p.id === contactId)[0];
    const modalConf: ModalConfiguration = {
      title: translations.deletecontact,
      body: <DeleteConfirmationBody name={contactToEdit.name} />,
      hideButton: false,
      buttonText: translations.delete,
      type: 'warning',
      okCallback: () => delMutate(contactToEdit.id),
    };

    openModal(modalConf);
  };

  const onSearchChanged = (event) => {
    const newValue = event.target.value;
    setCurrentSearchText(newValue);
    if (!newValue) {
      setFilteredContacts([...contacts]);
    } else {
      const searchValue = newValue.toUpperCase();
      const newFilteredContacts = contacts.filter((p) => p.name.toUpperCase().startsWith(searchValue));
      setFilteredContacts(newFilteredContacts);
    }
  };

  useEffect(() => {
    if (isLoadingCustomerContactOptionSets === false) {
      onGetOptionSetSuccess(dataCustomerContactOptionSets);
    }
  }, [customer]);

  useEffect(() => {
    if (isLoadingContacts === false) {
      const contactViewModels = createContactViewModels(dataContacts);
      setFilteredContacts(contactViewModels);
      setContacts(contactViewModels);
    }
  }
    , [dataContacts]);

  useEffect(() => {
    if (error) {
      const modalConf: ModalConfiguration = {
        title: 'Error',
        body: <ErrorMessage />,
        buttonText: 'Ok',
        type: 'error',
        okCallback: () => { setError(null); },
      };

      openModal(modalConf);
    }
  }, [error]);

  if (isLoadingContacts || isLoadingCustomerContactOptionSets || deleteIsLoading) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }

  return (
    <div className="pbs-wrapper">
      <h1>{translations.contacts}</h1>
      <span className="gradient" />
      <div className="pbs-grid">
        <div className="contacts-controls">
          <div>
            <SearchBar
              val={currentSearchText}
              handleChange={onSearchChanged}
              placeholder={translations['placeholder-lang-search-action']}
            />
          </div>
          <div>
            <IconContext.Provider value={{ className: 'contacts-controls-icon' }}>
              <IoPersonAddSharp onClick={onClickNewContact} />
            </IconContext.Provider>
          </div>
        </div>
        <div className="table-container">
          <ContactListPaginated
            contacts={filteredContacts}
            translations={translations}
            onClickEdit={onClickEditContact}
            onClickDelete={onClickDeleteContact}
            showDelete
            showEdit
          />
        </div>
      </div>
    </div>
  );
}

export default Contacts;
