import { FunctionComponent, useState } from "react";
import * as React from 'react'
import { Party } from "../../api/PartyApi";
import { PartyPeople } from "../../api/PartyPeopleApi";
import { IconContext } from "react-icons";
import { RiMoreFill, RiPencilFill } from "react-icons/ri";
import PartyPeopleContactList from "../PartyPeople/PartyPeopleContactList";
import { useSpring, animated } from 'react-spring'
import useMeasure from 'react-use-measure'
import { AiOutlinePlus } from "react-icons/ai";
import UpsertPartyPeopleComponent from "../PartyPeople/UpsertPartyPeopleComponent";
import ActionsButton from "@murphy-frontend/web-core/components/ActionsButton";

interface PartyTableRowProps {
    party: Party,
    partyPeople: PartyPeople[],
    openEditCard: (party: Party) => void,
    openPartyPeople: (partyId: number) => void,
    translations: Record<string, string>;
    openModal: any,
    closeModal: any,
    dashboardId: string,
}

const PartyTableRow: FunctionComponent<PartyTableRowProps> =
    ({ party, partyPeople, openEditCard, openPartyPeople, translations, openModal, closeModal, dashboardId }) => {

        const [isExpanded, setIsExpanded] = useState<boolean>(false);
        const onExpandClick = () => {
            setIsExpanded(!isExpanded);
        };

        const [ref, bounds] = useMeasure();
        const props = useSpring({
            from: {
                height: 0
            },
            to: {
                height: isExpanded ? bounds.height : 0
            }
        });

        const onClickEdit = (party: Party, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            event.preventDefault();
            openEditCard(party);
        }

        const onClickOpenContacts = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            event.preventDefault();
            onExpandClick();
        }

        const onClickCreatePartyPeople = () => {
            const upsertPartyPeopleComponent =
                <UpsertPartyPeopleComponent
                    translations={translations}
                    dashboardId={dashboardId}
                    onSuccess={() => { closeModal(); }}
                    partyId={party.PartyId}
                />;

            const configuration = {
                centerContent: false,
                hideButton: true,
                body: upsertPartyPeopleComponent,
                title: translations['class-lang-ny-aktor-title'],
            }
            openModal(configuration);
        }

        const partyPartyPeople = partyPeople.filter(p => p.PartyId === party.PartyId);

        const editIcon = <IconContext.Provider value={{ className: 'dashboard-table-action-status-icon party-icon' }}>
            <RiPencilFill />
        </IconContext.Provider>;

        const addContactIcon = <IconContext.Provider value={{ className: 'dashboard-table-action-status-icon party-icon' }}>
            <AiOutlinePlus />
        </IconContext.Provider>;

        const moreButtonIcon = <IconContext.Provider value={{ className: 'dashboard-table-actions-button-icon party-icon' }}>
            <RiMoreFill />
        </IconContext.Provider>;

        const actionButtonItems = [];
        actionButtonItems.push(
            <li key="addpartypeople" onClick={() => onClickCreatePartyPeople()}>
                <div className="vertical-aligner">
                    {addContactIcon}
                    &nbsp;&nbsp;
                    <span>{translations['class-btn-new-aktor']}</span>
                </div>
            </li>);
        actionButtonItems.push(
            <li key="editparty" onClick={(e) => { onClickEdit(party, e) }}>
                <div className="vertical-aligner">
                    {editIcon}
                    &nbsp;&nbsp;
                    <span>{translations.edit}</span>
                </div>
            </li>
        );

        const actionsButton = <ActionsButton actionButtonItems={actionButtonItems} icon={moreButtonIcon} pullRightOnSmallScreen={true} />
        const rowDisplayText = ` (${partyPartyPeople.length} ${translations.contacts})`;

        return (<><div className="dashboard-table-row dashboard-table-clickable-item" onClick={(e) => { onClickOpenContacts(e) }}>
            <div className='left-icon' onClick={(e) => { onClickEdit(party, e); e.stopPropagation(); }}>
                {actionsButton}
            </div>
            <div className="dashboard-table-row-text">
                <b>{party.PartyName}</b>{rowDisplayText}
            </div>
        </div>
            <animated.div style={{ ...props, overflow: "hidden" }}>
                <div ref={ref} className="dashboard-party-table-expanded-container">
                    <PartyPeopleContactList
                        translations={translations}
                        partyPeople={partyPartyPeople}
                        openModal={openModal}
                        closeModal={closeModal}
                        dashboardId={dashboardId}
                        partyId={party.PartyId}
                    />
                </div>
            </animated.div>
        </>
        )
    }

export default PartyTableRow;