import React, { useState, useEffect, FunctionComponent } from 'react';
import {
  useNavigate,
} from 'react-router-dom';

import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import Button from '@murphy-frontend/web-core/components/Button';
import SearchBar from '@murphy-frontend/web-core/components/SearchBar';

import { roles } from '@murphy-frontend/web-core/constants';
import { useGetUsers } from '@murphy-frontend/common/api/Users/Queries';
import { useDeleteUserMutation } from '@murphy-frontend/common/api/Users/Mutations';
import { CustomerUserTrainingSessionStates, Products, Roles, TrainingTypes } from '@murphy-frontend/common/enums';
import { useGetTrainings } from '../../common/api/Training/queries';
import { TimeZoneType, getConvertedDate } from '@murphy-frontend/common/services/TimeService'
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';
import { useUserPreferences } from '../../common/contexts/UserPreferencesContext';
import useWindowDimensions from "@murphy-frontend/web-core/hooks/useWindowDimensions";
import { useCustomer } from '../../common/contexts/CustomerContext';
import { useUser } from '../../common/contexts/UserContext';
import { MurphyUser } from '@murphy-frontend/common/interfaces/IUsersApi';
import FeatureServiceType, { IFeatureService } from '@murphy-frontend/common/interfaces/IFeatureService';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import UsersList, { UserListCourseViewModel, UserListTrainingSessionViewModel, UserListViewModel } from '@murphy-frontend/web-core/features/UsersAdmin/components/UsersList';

const UsersAdmin: FunctionComponent = () => {
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { translations, timeZone, } = useUserPreferences();
  const { currentSize } = useWindowDimensions();

  const [error, setError] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState<UserListViewModel[]>([]);
  const [allCourses, setAllCourses] = useState<UserListCourseViewModel[]>([]);

  const featureService = useInjection<IFeatureService>(
    FeatureServiceType.IFeatureService,
  );

  const [currentSearchText, setCurrentSearchText] = useState('');

  const { customer, allCustomerProducts } = useCustomer();
  const { user } = useUser();
  const { mutate: mutateDel, isPending: isDeleteLoading } = useDeleteUserMutation(translations);

  if (user.Role !== Roles.Admin) {
    navigate('/portal');
    toast('Unauthorized');
    return null;
  }

  const {
    isLoading: isLoadingCourses, isError: isErrorCourses, data: dataCourses, error: errorCourses,
  } = useGetTrainings({
    customerId: customer.Id,
  });

  const {
    isLoading: isLoadingUsers, isError: isErrorUsers, data: dataUsers, error: errorUsers,
  } = useGetUsers(customer.Id);

  const mapUsers = (rawDataUsers: MurphyUser[], tz: TimeZoneType): UserListViewModel[] => rawDataUsers.map((rawUser) => {
    const mappedUserTrainingSessions = rawUser?.UserTrainingSessions?.map(
      (uts) => ({
        id: uts.TrainingId,
        date: getConvertedDate(dayjs.utc(uts.Date), tz),
        trainingTypeId: uts.TrainingTypeId,
        trainingSessionStateId: uts.TrainingSessionStateID,
      } as UserListTrainingSessionViewModel),
    );

    return {
      id: rawUser.CustomerUserId,
      username: rawUser.Username,
      email: rawUser.Email,
      role: roles.filter((r) => r.id === rawUser.Role)[0].value,
      mobile: rawUser.Phonenumber,
      deviceToken: rawUser.DeviceToken,
      permissions: rawUser.Permissions,
      completedCourses: mappedUserTrainingSessions
        ?.filter((row) => row.trainingTypeId === TrainingTypes.Course
          && (row.trainingSessionStateId === CustomerUserTrainingSessionStates.Completed))
        ?? [],
      bookedCourses: mappedUserTrainingSessions
        ?.filter((row) => row.trainingTypeId === TrainingTypes.Course
          && (row.trainingSessionStateId === CustomerUserTrainingSessionStates.Booked))
        ?? [],
      completedExcercises: mappedUserTrainingSessions
        ?.filter((row) => row.trainingTypeId === TrainingTypes.Excersise
          && (row.trainingSessionStateId === CustomerUserTrainingSessionStates.Completed))
        ?? [],
    } as UserListViewModel;
  });

  useEffect(() => {
    if (dataCourses && dataCourses.length > 0) {
      const mappedCourses: UserListCourseViewModel[] = dataCourses.filter((p) => p.TrainingTypeId === TrainingTypes.Course)
        .map((course) => {
          return {
            id: course.Id,
            name: course.Name,
            displayName: course.Name,
          } as UserListCourseViewModel
        });

      setAllCourses(mappedCourses);
    }
  }, [dataCourses]);

  const DeleteConfirmationBody = ({ userRow }) => (
    <>
      <p>
        {translations.delete}
        {' '}
        <strong>{userRow.username}</strong>
        {' '}
        ?
      </p>
    </>
  );

  const onClickDeleteUser = (userRow: UserListViewModel) => {
    const modalConf: ModalConfiguration = {
      title: `${translations.delete} ${translations.theuser}`,
      body: <DeleteConfirmationBody userRow={userRow} />,
      hideButton: false,
      buttonText: translations.delete,
      type: 'warning',

      okCallback: () => mutateDel(userRow.id),
    };

    openModal(modalConf);
  };

  const onClickEditUser = (userRow: UserListViewModel) => {
    const url = `/users/${userRow.id}`;
    navigate(url);
  };

  const onClickNewUser = () => {
    navigate('/usernew');
  };

  const onSearchChanged = (event) => {
    const newValue = event.target.value;
    setCurrentSearchText(newValue);
  };

  useEffect(() => {
    if (!isLoadingUsers && timeZone) {
      let newFilteredUsers = mapUsers(dataUsers, timeZone);

      if (currentSearchText) {
        const searchValue = currentSearchText.toUpperCase();
        newFilteredUsers = newFilteredUsers.filter((p) => p.username.toUpperCase().startsWith(searchValue) || p.email.toUpperCase().startsWith(searchValue));
      }

      setFilteredUsers(newFilteredUsers);
    }
  }, [currentSearchText, dataUsers, isLoadingUsers, timeZone]);

  const ErrorMessage = () => (<p>{error}</p>);

  useEffect(() => {
    setError(errorUsers);
  }, [isErrorUsers]);

  useEffect(() => {
    setError(errorCourses);
  }, [isErrorCourses]);

  useEffect(() => {
    if (error) {
      const modalConf: ModalConfiguration = {
        title: 'Error',
        body: <ErrorMessage />,
        buttonText: 'Ok',
        type: 'error',
        okCallback: () => { setError(null); },
      };
      openModal(modalConf);
    }
  }, [error]);

  if (isLoadingCourses || isLoadingUsers || isDeleteLoading) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }

  const isCreateAndDeleteEnabled = featureService.createUserIsEnabled() === true;

  return (
    <div className="generalwrapper">
      <section className="header-container vertical-aligner">
        <div className="one">
          <h4>
            {translations.useradmin.toUpperCase()}
          </h4>
        </div>
        {isCreateAndDeleteEnabled && <div className="two">
          <Button isIconButton buttonStyle="btn--add" buttonSize="btn-with-icon" onClick={onClickNewUser}><span className="btn-with-icon-text">{translations.addoneuser}</span></Button>
        </div>}
        <div className="three">
          <SearchBar val={currentSearchText} handleChange={onSearchChanged} placeholder={translations['placeholder-lang-search-action']} />
        </div>
      </section>
      <section className="user-table-container">
        <UsersList
          translations={translations}
          users={filteredUsers}
          allProducts={allCustomerProducts}
          allCourses={allCourses}
          onClickEditUser={onClickEditUser}
          onClickDeleteUser={onClickDeleteUser}
          currentScreenSize={currentSize}
          isDeleteUserEnabled={isCreateAndDeleteEnabled}
          isTrainingEnabled={allCustomerProducts.map(p => p.id).includes(Products.Training)}
        />
      </section>
    </div>
  );
}

export default UsersAdmin;
