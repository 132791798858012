import { useState, useEffect, FunctionComponent, useCallback, useMemo } from 'react';
import { usePatchStatusCardOtherDashboard, useUpsertStatusCardOtherDashboard } from '../../mutations';
import ChildStatusCardForm, { ChildStatusCardFormDto } from './ChildStatusCardForm';
import { PatchStatusCard, StatusCard, UpsertStatusCard } from '../../api/StatusCardApi';
import { useGetChildCustomers } from '@murphy-frontend/common/api/Customers/Queries';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import React from 'react';
import { MurphyBasicCustomerDto } from '@murphy-frontend/common/interfaces/ICustomersApi';
interface UpsertChildStatusProps {
    dashboardId: string,
    onSuccess: () => void,
    currentCustomer: MurphyBasicCustomerDto,
    translations: Record<string, string>,
    childStatusCard?: StatusCard,
}

const UpsertChildStatus: FunctionComponent<UpsertChildStatusProps> = ({ dashboardId, onSuccess, translations, childStatusCard, currentCustomer }) => {
    const { mutate: upsertStatusCardMutation, isPending: upsertIsLoading } = useUpsertStatusCardOtherDashboard();
    const { mutate: patchStatusCardMutation, isPending: patchIsLoading } = usePatchStatusCardOtherDashboard();
    const { data: childCustomers, isLoading: childCustomersIsLoading } = useGetChildCustomers(currentCustomer.Id);

    const [childStatusCardDto, setChildStatusCardDto] = useState<ChildStatusCardFormDto>(null);

    const onSubmitHandler = useCallback((statusCardFormDto: ChildStatusCardFormDto) => {
        const upsertStatusCard: UpsertStatusCard = {
            Title: statusCardFormDto.Title,
            ParentDashboardId: dashboardId,
            DashboardId: statusCardFormDto.ChildDashboardId ?? childStatusCard.DashboardId,
            Important: childStatusCard?.Important,
            AlternatedTime: childStatusCard?.AlternatedTime,
            Status: childStatusCard?.Status,
            Message: childStatusCard?.Message,
            ProvidedBy: childStatusCard?.ProvidedBy,
            UpdatedFromDashboardId: dashboardId,
        }

        if (childStatusCard && childStatusCard.ID) {
            upsertStatusCard.ParentId = childStatusCard.ID;
        }

        upsertStatusCardMutation(upsertStatusCard, {
            onSuccess: onSuccess
        });
    }, [childStatusCard, dashboardId, upsertStatusCardMutation, onSuccess]);

    const onClickArchiveStatusCard = () => {
        const patchCard: PatchStatusCard = {
            UpdatedFromDashboardId: dashboardId,
            DashboardId: childStatusCard.DashboardId,
            Id: childStatusCard.ID,
            archived: true,
        }

        patchStatusCardMutation(patchCard, {
            onSuccess: () => {
                onSuccess();
            }
        });
    }

    // map a child status card to a child status card dto
    useEffect(() => {
        if (childStatusCard) {
            const childStatusCardDto: ChildStatusCardFormDto = {
                Title: childStatusCard.Title,
                ChildCustomerId: childStatusCard.ChildCustomerId,
                ChildDashboardId: childStatusCard.DashboardId,
                ChildCustomerName: childStatusCard.ChildCustomerName,
                ChildDashboardName: childStatusCard.DashboardName,
            }
            setChildStatusCardDto(childStatusCardDto);
        }
    }, [childStatusCard]);

    const filteredCustomers = useMemo(() => {
        if (!childCustomers || !currentCustomer) return [];
        return childCustomers.filter(c => c.Id !== currentCustomer.Id);
    }, [childCustomers, currentCustomer]);

    if (childCustomersIsLoading || currentCustomer?.Id === undefined)
        return (<div className="spinner-container-global-center"><Spinner /></div>);

    return (
        <div>
            <ChildStatusCardForm
                translations={translations}
                onSubmit={onSubmitHandler}
                onClickArchive={onClickArchiveStatusCard}
                isLoading={upsertIsLoading || patchIsLoading}
                childCustomers={filteredCustomers}
                childStatusCardDto={childStatusCardDto}
            />
        </div>
    );
}

export default UpsertChildStatus;
