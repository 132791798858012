import { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { DashboardCardFilter } from '../models';

export interface DashboardFile {
    ID: number,
    fileType: number,
    FilePath: string,
    CreatedDateTime: Date,
    RemovedDateTime?: Date,
    ModifiedDateTime?: Date,
    DashboardId: string,
    FileName: string,
    archived: boolean,
    user: string,
    addedBy?: string,
    removedBy?: string,
    modifiedBy?: string,
    versionTo?: Date,
}

export interface UpsertDashboardFile {
    Id?: string,
    FileName?: string,
    archived: boolean,
    file?: File,
}

export class DashboardFileApi {
    parentId: string;
    baseUrl: string;
    apiService: IApiService;

    constructor(apiService: IApiService, dashboardId: string) {
        this.parentId = dashboardId
        this.baseUrl = `api/Dashboard/${dashboardId}/File`;
        this.apiService = apiService;
    }

    getById = (id: string) => {
        const url = `${this.baseUrl}/${id}`;
        return this.apiService.callApi(url, 'GET', null);
    }

    getAll = (filter?: DashboardCardFilter) => {
        let searchParamsString = '';
        if (filter) {
            const searchParams = new URLSearchParams();

            if (filter.fromDate) {
                searchParams.append('fromDate', filter.fromDate.toUTCString());
            }
            searchParamsString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
        }
        const url = `${this.baseUrl}${searchParamsString}`;
        return this.apiService.callApi(url, 'GET', null);
    }

    upsertDashboardFile = (upsertDashboardFile: UpsertDashboardFile) => {
        const url = `${this.baseUrl}`;
        const formData = new FormData();
        if (upsertDashboardFile.file) {
            formData.append('File', upsertDashboardFile.file);
        }
        const { file, ...formDataWithoutFile } = upsertDashboardFile;
        formData.append('FileJsonData', JSON.stringify(formDataWithoutFile));

        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        return this.apiService.callApi(url, 'POST', formData, headers);
    }
};
