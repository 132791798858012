import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { IconContext } from "react-icons/lib";
import { RiPencilFill } from "react-icons/ri";
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import TabPanel, { TabType } from '@murphy-frontend/web-core/components/Tabs/TabPanel';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { Assumption } from "../../api/AssumptionApi";
import { BackGround } from "../../api/BackGroundApi";
import { useGetAssumptions, useGetBackGrounds } from "../../queries";
import UpsertAssumptionComponent from "../Assumption/UpsertAssumptionComponent";
import UpsertBackGroundComponent from "../BackGround/UpsertBackGroundComponent";
import { parseHtmlAndLinkifySafe } from "@murphy-frontend/common/utils";

interface BackGroundAssumptionTabsProps {
    dashboardId: string,
    translations: Record<string, string>,
}

const BackGroundAssumptionTabs: FunctionComponent<BackGroundAssumptionTabsProps> = ({ dashboardId, translations }) => {
    const [tabs, setTabs] = useState<TabType[]>([]);
    const [assumption, setAssumption] = useState<Assumption>(null);
    const [backGround, setBackGround] = useState<BackGround>(null);
    const [selectedTabId, setSelectedTabId] = useState<number>(1);
    const onClickTab = (tabId: number) => {
        setSelectedTabId(tabId);
    };

    const {
        isLoading: assumptionIsLoading, isError: assumptionIsError, data: assumptionData, error: assumptionError,
    } = useGetAssumptions(dashboardId)

    const {
        isLoading: backGroundIsLoading, isError: backGroundIsError, data: backGroundData, error: backGroundError,
    } = useGetBackGrounds(dashboardId)

    const { openModal, closeModal } = useModal();

    const onClickEditAssumption = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        const upsertAssumptionComponent = <UpsertAssumptionComponent dashboardId={dashboardId} translations={translations} assumption={assumption} onSuccess={() => closeModal()} />

        const configuration = {
            centerContent: false,
            hideButton: true,
            body: upsertAssumptionComponent,
            title: translations['class-lang-prognosis-card'],
        }
        openModal(configuration);
    }

    const onClickEditBackGround = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        const uspertBackGroundComponent = <UpsertBackGroundComponent dashboardId={dashboardId} translations={translations} backGround={backGround} onSuccess={() => closeModal()} />

        const configuration = {
            centerContent: false,
            hideButton: true,
            body: uspertBackGroundComponent,
            title: translations['class-lang-background-card'],
        }
        openModal(configuration);
    }

    useEffect(() => {
        if (!assumptionIsLoading) {
            const assumptionNewData = assumptionData.find(p => p.archived === false);
            if (assumptionNewData) {
                setAssumption(assumptionNewData);
            }
        }
    }, [assumptionData, assumptionIsLoading])

    useEffect(() => {
        if (!backGroundIsLoading) {
            const backGroundNewData = backGroundData.find(p => p.archived === false);
            if (backGroundNewData) {
                setBackGround(backGroundNewData);
            }
        }
    }, [backGroundData, backGroundIsLoading])

    const setInitialTabs = () => {
        const initialTabs: Array<TabType> = [
            {
                id: 1,
                displayText: translations['class-lang-background-card'] as string,
                customClass: 'tab-solid-panel-tab',
                customSelectedClass: 'tab-solid-panel-tab-selected-alt',
            },
            {
                id: 2,
                displayText: translations['class-lang-prognosis-card'] as string,
                customClass: 'tab-solid-panel-tab',
                customSelectedClass: 'tab-solid-panel-tab-selected-alt',
            },
        ];
        setTabs(initialTabs);
    };

    useEffect(() => {
        if (translations) {
            setInitialTabs();
        }
    }, [translations]);

    if (assumptionIsLoading || backGroundIsLoading) {
        return (<div className="spinner-container-global-center"><Spinner /></div>);
    }

    return (<div>
        <TabPanel tabs={tabs} onClickTab={onClickTab} selectedTabId={selectedTabId} customClass='tab-solid-panel' />
        {selectedTabId === 1 && <div className='goals-tab-content tab-solid-panel-tab-selected-alt'>
            <div className='tab-content-text'>
                <b>
                    {translations['class-what-has-happened']}
                </b>
                <br />
                {backGround?.WhatMessage && parseHtmlAndLinkifySafe(backGround.WhatMessage)}
                <br />
                <br />
                <b>
                    {translations['class-when-did-it-happen']}
                </b>
                <br />
                {backGround?.WhenMessage && parseHtmlAndLinkifySafe(backGround.WhenMessage)}
                <br />
                <br />
                <b>
                    {translations['class-how-did-it-happen']}
                </b>
                <br />
                {backGround?.HowMessage && parseHtmlAndLinkifySafe(backGround.HowMessage)}
                <br />
                <br />
                <b>
                    {translations['class-affected']}
                </b>
                <br />
                {backGround?.AffectedMessage && parseHtmlAndLinkifySafe(backGround.AffectedMessage)}
                <br />
                <br />
                <b>
                    {translations['class-extent']}
                </b>
                <br />
                {backGround?.ExtentMessage && parseHtmlAndLinkifySafe(backGround.ExtentMessage)}
            </div>
            <div className="goals-edit-item-button-container">
                <div className="goals-edit-item-button" onClick={(e) => { onClickEditBackGround(e) }}>
                    <IconContext.Provider value={{ className: 'goals-edit-item-icon' }}>
                        <RiPencilFill />
                    </IconContext.Provider>
                </div>
            </div>
        </div>}
        {selectedTabId === 2 && <div className='goals-tab-content tab-solid-panel-tab-selected-alt'>
            <div className='tab-content-text'>
                <b>
                    {translations['class-button-best-case']}:
                </b>
                <br />
                {assumption?.Best && parseHtmlAndLinkifySafe(assumption.Best)}
                <br />
                <br />
                <b>
                    {translations['class-button-likely-scenario']}:
                </b>
                <br />
                {assumption?.Prob && parseHtmlAndLinkifySafe(assumption.Prob)}
                <br />
                <br />
                <b>
                    {translations['class-button-worst-case']}:
                </b>
                <br />
                {assumption?.Worst && parseHtmlAndLinkifySafe(assumption.Worst)}
            </div>
            <div className="goals-edit-item-button-container">
                <div className="goals-edit-item-button" onClick={(e) => { onClickEditAssumption(e) }}>
                    <IconContext.Provider value={{ className: 'goals-edit-item-icon' }}>
                        <RiPencilFill onClick={() => { }} />
                    </IconContext.Provider>
                </div>
            </div>
        </div>}
    </div>);
}

export default BackGroundAssumptionTabs;