import * as React from "react"
import {useState, useEffect, FunctionComponent} from "react"
import {useNavigate, useParams} from "react-router-dom"
import Button from "@murphy-frontend/web-core/components/Button"
import Input from "@murphy-frontend/web-core/components/Input"
import OptionSet from "@murphy-frontend/web-core/components/OptionSet"
import Spinner from "@murphy-frontend/web-core/components/Spinner"
import ContactList from "../../Contacts/components/ContactList"
import SearchBar from "@murphy-frontend/web-core/components/SearchBar"
import {dashboardNameIsValid} from "@murphy-frontend/common/validation"
import {dashboardTypes} from "@murphy-frontend/web-core/constants"
import {DashboardTypes} from "@murphy-frontend/common/enums"
import {translateConstants} from "../../../common/services/translationService"
import {useGetUsers} from "@murphy-frontend/common/api/Users/Queries"
import {useGetDashboard, useGetDashboardMembers, useGetDashboardTemplates} from "../../../common/api/Dashboards/queries"
import {
	useAddMemberToDashboard,
	useCreateDashboard,
	useEditDashboard,
	useRemoveMemberFromDashboard
} from "../../../common/api/Dashboards/mutations"
import {useModal} from "@murphy-frontend/web-core/contexts/ModalContext"
import {useUserPreferences} from "../../../common/contexts/UserPreferencesContext"
import {ModalConfiguration} from "@murphy-frontend/web-core/components/Modal"
import {useCustomer} from "../../../common/contexts/CustomerContext"
import {useUser} from "../../../common/contexts/UserContext"
import DeleteTemplate from "../components/DeleteTemplate"

const UpsertDashboard: FunctionComponent = () => {
	const {id} = useParams()
	const navigate = useNavigate()
	const noSelection = "No selection"
	const {customer} = useCustomer()
	const {user} = useUser()

	const {openModal, closeModal} = useModal()
	const {translations, language} = useUserPreferences()

	const [error, setError] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [isEditMode, setIsEditMode] = useState(false)

	const [isArchived, setIsArchived] = useState(null)
	const [dashboardName, setDashboardName] = useState("")
	const [oldDashboardName, setOldDashboardName] = useState("")

	const [dashboardMembers, setDashboardMembers] = useState([])
	const [currentSearchText, setCurrentSearchText] = useState("")

	const [users, setUsers] = useState([])
	const [filteredUsers, setFilteredUsers] = useState([])

	const [templates, setTemplates] = useState([])
	const [selectedTemplateId, setSelectedTemplateId] = useState(noSelection)

	const [allDashboardTypes, setAllDashboardTypes] = useState([])
	const [selectedDashboardTypeId, setSelectedDashboardTypeId] = useState(1)
	const [oldSelectedDashboardTypeId, setOldSelectedDashboardTypeId] = useState(1)

	const [dashboardNameErrorMessage, setDashboardNameErrorMessage] = useState("")
	const [dashboardTypeErrorMessage, setDashboardTypeErrorMessage] = useState("")

	const {
		isLoading: isLoadingUsers,
		isError: isErrorUsers,
		data: dataUsers,
		error: errorUsers
	} = useGetUsers(customer.Id)

	const {
		isLoading: isLoadingDasboard,
		isError: isErrorDashboard,
		data: dataDashboard,
		error: errorDashboard
	} = useGetDashboard(id)

	const {
		isLoading: isLoadingDasboardMembers,
		isError: isErrorDashboardMembers,
		data: dataDashboardMembers,
		error: errorDashboardMembers
	} = useGetDashboardMembers(id, selectedDashboardTypeId)

	const {
		isLoading: isLoadingDashboardTemplates,
		isError: isErrorDashboardTemplates,
		data: dataDashboardTemplates,
		error: errorDashboardTemplates
	} = useGetDashboardTemplates(customer.Id)

	const {mutate: editDashboard, isPending: editDashboardIsLoading} = useEditDashboard(customer.Id, translations)
	const {mutate: createDashboard, isPending: createDashboardIsLoading} = useCreateDashboard(customer.Id, translations)
	const {mutate: addMember, isPending: addMemberIsLoading} = useAddMemberToDashboard(id, translations)
	const {mutate: removeMember, isPending: removeMemberIsLoading} = useRemoveMemberFromDashboard(id, translations)

	const mapDashboard = rawDashboard => ({
		id: rawDashboard.dashboardid,
		name: decodeURIComponent(rawDashboard.Name),
		isarchived: rawDashboard.Arkiverad,
		dashboardtypeid: rawDashboard.DashboardTypeId
	})

	const mapDashboardMembers = members =>
		members.map(memb => ({
			id: memb.Id,
			name: memb.Name,
			customeruserid: memb.CustomerUserId.toUpperCase(),
			type: "user"
		}))

	const onSearchChanged = event => {
		const newValue = event.target.value
		setCurrentSearchText(newValue)
	}

	useEffect(() => {
		if (dataDashboard) {
			const mappedDashboard = mapDashboard(dataDashboard)
			setDashboardName(mappedDashboard.name)
			setOldDashboardName(mappedDashboard.name)
			setIsArchived(mappedDashboard.isarchived)
			setSelectedDashboardTypeId(mappedDashboard.dashboardtypeid)
			setOldSelectedDashboardTypeId(mappedDashboard.dashboardtypeid)
		}
	}, [dataDashboard])

	useEffect(() => {
		if (dataDashboardMembers) {
			const membersWithPermission = dataDashboardMembers.filter(memb => memb.hasPermission === true)
			const mappedMembers = mapDashboardMembers(membersWithPermission)
			setDashboardMembers(mappedMembers)
		}
	}, [dataDashboardMembers])

	const onChangeDashboardTypeId = event => {
		const parsedTypeId = Number.parseInt(event.target.value, 10)
		setSelectedDashboardTypeId(parsedTypeId)
	}

	const onChangeTemplateId = event => {
		setSelectedTemplateId(event.target.value)
	}

	const onClickAddMember = (customerUserId: string) => {
		addMember(customerUserId)
	}

	const onClickDeleteTemplate = () => {
		const modalConf: ModalConfiguration = {
			title: translations.deletetemplate,
			body: (
				<DeleteTemplate
					customerId={customer.Id}
					templateId={selectedTemplateId}
					closeModal={closeModal}
					translations={translations}
				/>
			),
			hideButton: true,
			disableOverflow: true,
			type: "info"
		}

		openModal(modalConf)
	}

	const onClickRemoveMember = (dashboardMemberId: string) => {
		const dashBoardMember = dashboardMembers.filter(memb => memb.id === dashboardMemberId)[0]
		if (dashBoardMember.name === user.Username) {
			const modalConf: ModalConfiguration = {
				title: "Cannot delete yourself",
				body: <p>Cannot delete yourself from explicit dashboard</p>,
				buttonText: "Ok",
				type: "warning"
			}

			openModal(modalConf)
		} else {
			removeMember(dashboardMemberId)
		}
	}

	useEffect(() => {
		if (id) {
			setIsEditMode(true)
		} else {
			setDashboardName("")
			setIsEditMode(false)
		}
	}, [id])

	useEffect(() => {
		setAllDashboardTypes(translateConstants(language, dashboardTypes))
	}, [])

	useEffect(() => {
		setAllDashboardTypes(translateConstants(language, dashboardTypes))
	}, [language])

	useEffect(() => {
		if (dataDashboardTemplates) {
			const mappedTemplates = dataDashboardTemplates.map(template => ({
				id: template.TemplateId,
				value: template.TemplateName
			}))

			mappedTemplates.push({
				id: noSelection,
				value: "-------"
			})

			setTemplates(mappedTemplates)
		}
	}, [dataDashboardTemplates])

	const onChangeDashbordName = event => {
		const newVal = event.target.value
		const val = newVal
		if (!newVal) {
			setDashboardNameErrorMessage("")
		} else if (!dashboardNameIsValid(newVal)) {
			setDashboardNameErrorMessage("Dashboard is not valid")
		} else {
			setDashboardNameErrorMessage("")
		}
		setDashboardName(val)
	}

	function ErrorMessage() {
		return <p>{error}</p>
	}

	const onSubmitArchiveDashboard = () => {
		const editDashboardRequest = {
			DashboardId: id,
			ArchiveBoard: true
		}

		editDashboard(editDashboardRequest, {
			onSuccess: () => {
				navigate("/crisis/")
			}
		})
	}

	const onSubmitActivateDashboard = () => {
		const editDashboardRequest = {
			DashboardId: id,
			ActivateBoard: true
		}

		editDashboard(editDashboardRequest, {
			onSuccess: () => {
				navigate("/crisis/")
			}
		})
	}

	function ArchiveConfirmationBody() {
		return (
			<p>
				{translations.archive} <strong>{dashboardName}</strong> ?
			</p>
		)
	}

	function ActivateConfirmationBody() {
		return (
			<p>
				{translations.activate} <strong>{dashboardName}</strong> ?
			</p>
		)
	}

	const onClickArchiveDashboard = () => {
		const modalConf: ModalConfiguration = {
			title: `${translations.archive} dashboard`,
			body: <ArchiveConfirmationBody />,
			hideButton: false,
			buttonText: translations.archive,
			type: "warning",

			okCallback: () => onSubmitArchiveDashboard()
		}

		openModal(modalConf)
	}

	const onClickActivateDashboard = () => {
		const modalConf: ModalConfiguration = {
			title: `${translations.activate} dashboard`,
			body: <ActivateConfirmationBody />,
			hideButton: false,
			buttonText: translations.activate,
			type: "info",

			okCallback: () => onSubmitActivateDashboard()
		}
		openModal(modalConf)
	}

	const onClickNewDashboard = () => {
		navigate("/dashboards/")
	}

	const onClickBack = () => {
		navigate("/crisis")
	}

	const onClickSave = e => {
		e.preventDefault()
		if (isEditMode) {
			const updateDashboardRequest = {
				DashboardId: id
			}

			if (dashboardName) {
				updateDashboardRequest.NewName = encodeURIComponent(dashboardName)
			}

			if (selectedDashboardTypeId > -1) {
				updateDashboardRequest.Dashboardtype = selectedDashboardTypeId
			}

			editDashboard(updateDashboardRequest)
		} else {
			if (!dashboardName) {
				setDashboardNameErrorMessage("Must set name")
				return
			}

			if (selectedDashboardTypeId < 0) {
				setDashboardTypeErrorMessage("Must select type")
				return
			}

			const createDashboardRequest = {
				Name: encodeURIComponent(dashboardName),
				Dashboardtype: selectedDashboardTypeId,
				CustomerId: customer.Id
			}

			if (selectedTemplateId && selectedTemplateId !== noSelection) {
				createDashboardRequest.TemplateId = selectedTemplateId
			}

			createDashboard(createDashboardRequest, {
				onSuccess: response => {
					const url = `/dashboards/${response.data.dashboardid}`
					navigate(url)
				}
			})
		}
	}

	useEffect(() => {
		if (dataUsers) {
			const mappedData = dataUsers.map(p => ({
				id: p.CustomerUserId.toUpperCase(),
				name: p.Username,
				email: p.Email,
				mobile: p.Phonenumber,
				type: "user"
			}))

			mappedData.sort((a, b) => a.name - b.name)
			setUsers(mappedData)
		}
	}, [dataUsers])

	useEffect(() => {
		if (users) {
			let nonMemberUsers = users.filter(p => !dashboardMembers.map(q => q.customeruserid).includes(p.id))

			if (currentSearchText) {
				const searchValue = currentSearchText.toUpperCase()
				nonMemberUsers = nonMemberUsers.filter(p => p.name.toUpperCase().startsWith(searchValue))
			}

			setFilteredUsers(nonMemberUsers)
		}
	}, [dashboardMembers, users, currentSearchText])

	useEffect(() => {
		if (error) {
			const modalConf: ModalConfiguration = {
				title: "Error",
				body: <ErrorMessage />,
				buttonText: "Ok",
				type: "error",
				okCallback: () => {
					setError(null)
				}
			}

			openModal(modalConf)
		}
	}, [error])

	if (
		isLoading ||
		isLoadingDasboard ||
		isLoadingDasboardMembers ||
		isLoadingDashboardTemplates ||
		isLoadingUsers ||
		addMemberIsLoading ||
		removeMemberIsLoading ||
		editDashboardIsLoading ||
		createDashboardIsLoading
	) {
		return <Spinner isGlobal />
	}

	const saveButtonDisabled = !!dashboardNameErrorMessage

	return (
		<div className='generalwrapper'>
			<section className='header-container vertical-aligner'>
				<div className='one'>
					<h4>{isEditMode ? oldDashboardName : translations["lang-new-dasboard"]}</h4>
				</div>
				<div className='two controls-container'>
					{isEditMode ? (
						<>
							<div>
								<Button
									isIconButton
									isNeutralButton
									buttonStyle='btn--back'
									buttonSize='btn-with-icon'
									onClick={onClickBack}
								/>
							</div>
							<div>
								<Button
									isIconButton
									buttonStyle='btn--add'
									buttonSize='btn-with-icon'
									onClick={onClickNewDashboard}
								/>
							</div>
							<div>
								{isArchived === false ? (
									<Button
										isIconButton
										isNegativeButton
										buttonStyle='btn--archive'
										onClick={onClickArchiveDashboard}
										buttonSize='btn-with-icon'
									/>
								) : (
									<Button
										isIconButton
										isNeutralButton
										buttonStyle='btn--unarchive'
										onClick={onClickActivateDashboard}
										buttonSize='btn-with-icon'
									/>
								)}
							</div>
						</>
					) : (
						<div>
							<Button
								isIconButton
								isNeutralButton
								buttonStyle='btn--back'
								buttonSize='btn-with-icon'
								onClick={onClickBack}
							/>
						</div>
					)}
				</div>
				<div className='three' />
			</section>
			<section className='container-with-background'>
				<br />
				<span className='form-section-header margin-left-20'>INFO</span>
				<div className='form-container'>
					<form className='row'>
						<div className='column vertical-aligner horizontal-aligner'>
							<div className='form-section'>
								<Input
									val={dashboardName}
									isRequired
									handleChange={onChangeDashbordName}
									title={translations.name}
									errorMessage={dashboardNameErrorMessage}
								/>
								<OptionSet
									optionSetName={translations.typ}
									direction='row'
									key={1}
									optionSetId={1}
									options={allDashboardTypes}
									selectedOption={selectedDashboardTypeId}
									handleChange={onChangeDashboardTypeId}
								/>

								{/* templates can only be set when creating a new board */}
								{!isEditMode && templates.length > 1 ? (
									<OptionSet
										optionSetName={translations.template}
										direction='row'
										key={2}
										optionSetId={1}
										options={templates}
										selectedOption={selectedTemplateId}
										handleChange={onChangeTemplateId}
									/>
								) : null}

								<Button
									isLoading={editDashboardIsLoading}
									disabled={saveButtonDisabled}
									type='submit'
									onClick={onClickSave}
									buttonStyle='btn--primary'
									buttonSize='btn-medium'
								>
									{translations.save}
								</Button>
							</div>
						</div>
						{selectedTemplateId && selectedTemplateId !== noSelection && (
							<div className='column vertical-aligner-end horizontal-aligner margin-botton-20'>
								<Button
									isNegativeButton={true}
									buttonStyle='btn--primary'
									buttonSize='btn--medium'
									onClick={onClickDeleteTemplate}
								>
									{translations.delete}
								</Button>
							</div>
						)}
					</form>
				</div>
			</section>
			{oldSelectedDashboardTypeId === DashboardTypes.ExplicitPermissions &&
			selectedDashboardTypeId === DashboardTypes.ExplicitPermissions &&
			isEditMode === true ? (
				<section className='container-with-background margin-top-10'>
					<br />
					<div className='form-section-header margin-left-20'>{translations.permissions}</div>
					<div className='contacts-container remove-justify-center'>
						<div className='container-with-vertical-spaces'>
							<div className='form-section-header'>{translations.userswithaccess}</div>
							<div>
								<ContactList
									translations={translations}
									contacts={dashboardMembers}
									showDelete
									onClickDelete={onClickRemoveMember}
									hideExpand
								/>
							</div>
						</div>
						<div className='container-with-vertical-spaces'>
							<div className='form-section-header'>{translations.adduser}</div>
							<div className='contacts-controls'>
								<div>
									<SearchBar
										val={currentSearchText}
										handleChange={onSearchChanged}
										placeholder={translations["placeholder-lang-search-action"]}
									/>
								</div>
							</div>
							<div className='contacts-box'>
								<ContactList
									translations={translations}
									contacts={filteredUsers}
									showAdd
									onClickAdd={onClickAddMember}
									hideExpand
								/>
							</div>
						</div>
					</div>
				</section>
			) : null}
		</div>
	)
}

export default UpsertDashboard
