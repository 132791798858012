import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { UpsertActionsCard, PatchActionsCard, ActionsCardApi } from './api/ActionsCardApi';
import { AssumptionApi, UpsertAssumption } from './api//AssumptionApi';
import { BackGroundApi, UpsertBackGround } from './api//BackGroundApi';
import { GoalsApi, UpsertGoals } from './api//GoalsApi';
import { PatchStatusCard, StatusCardApi, UpsertStatusCard } from './api/StatusCardApi';
import { PartyApi, UpsertParty } from './api/PartyApi';
import { PartyPeopleApi, UpsertPartyPeople } from './api/PartyPeopleApi';
import { DashboardFileApi, UpsertDashboardFile } from './api/DashboardFileApi';
import { InformationCardApi, PatchInformationCard, UpsertInformationCard } from './api/InformationCardApi';
import { DashboardLogApi, InsertDashboardLog } from './api/DashboardLogApi';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';

// get instance of the apiService
const useApiService = () => useInjection<IApiService>(ApiServiceType.IApiService);

const useUpsertApi = (apiCall, errorMessage = 'Error') => {
    return useMutation({
        mutationFn: apiCall,
        onError: (err) => {
            // Assuming err.response.data contains the error message.
            // Adjust this according to the actual error response structure.
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error(errorMessage);
            }
        },
        onSuccess: (context) => {
            // You can handle the success event here if needed
            // For example, you might want to show a success message or 
            // trigger some other action on successful completion of the API call.
        },
    });
}

export const useUpsertInformationCard = (dashboardId: string) => {
    const apiService = useApiService();
    const informationCardApi = new InformationCardApi(apiService, dashboardId);

    return useUpsertApi((upsertInformationCard: UpsertInformationCard) => informationCardApi.upsertInformationCard(upsertInformationCard));
};

export const usePatchInformationCard = (dashboardId: string) => {
    const apiService = useApiService();
    const informationCardApi = new InformationCardApi(apiService, dashboardId);

    return useUpsertApi((patchInformationCard: PatchInformationCard) => informationCardApi.patchInformationCard(patchInformationCard));
}


export const useUpsertActionsCard = (dashboardId: string) => {
    const apiService = useApiService();
    const actionsCardApi = new ActionsCardApi(apiService, dashboardId);

    return useUpsertApi((createActionsCard: UpsertActionsCard) => actionsCardApi.upsertActionCard(createActionsCard));
};

export const usePatchActionsCard = (dashboardId: string) => {
    const apiService = useApiService();
    const actionsCardApi = new ActionsCardApi(apiService, dashboardId);

    return useUpsertApi((patchActionCard: PatchActionsCard) => actionsCardApi.patchActionCard(patchActionCard));
};

export const useUpsertStatusCard = (dashboardId: string) => {
    const apiService = useApiService();
    const statusCardApi = new StatusCardApi(apiService, dashboardId);

    return useUpsertApi((upsertStatusCard: UpsertStatusCard) => statusCardApi.upsertStatusCard(upsertStatusCard));
};

export const useUpsertStatusCardOtherDashboard = () => {
    const apiService = useApiService();

    return useUpsertApi((upsertStatusCard: UpsertStatusCard) => {
        if (upsertStatusCard.DashboardId === undefined)
            throw new Error("DashboardId is undefined");

        const statusCardApi = new StatusCardApi(apiService, upsertStatusCard.DashboardId);
        statusCardApi.upsertStatusCard(upsertStatusCard)
    });
};

export const usePatchStatusCard = (dashboardId: string) => {
    const apiService = useApiService();
    const statusCardApi = new StatusCardApi(apiService, dashboardId);

    return useUpsertApi((patchStatusCard: PatchStatusCard) => statusCardApi.patchStatusCard(patchStatusCard));
};

export const usePatchStatusCardOtherDashboard = () => {
    const apiService = useApiService();

    return useUpsertApi((patchStatusCard: PatchStatusCard) => {
        if (patchStatusCard.DashboardId === undefined)
            throw new Error("DashboardId is undefined");

        const statusCardApi = new StatusCardApi(apiService, patchStatusCard.DashboardId);
        statusCardApi.patchStatusCard(patchStatusCard)
    });
};

export const useUpsertGoals = (dashboardId: string) => {
    const apiService = useApiService();
    const goalsApi = new GoalsApi(apiService, dashboardId);

    return useUpsertApi((upsertGoals: UpsertGoals) => goalsApi.upsertGoals(upsertGoals));
};

export const useUpsertBackGround = (dashboardId: string) => {
    const apiService = useApiService();
    const backGroundApi = new BackGroundApi(apiService, dashboardId);

    return useUpsertApi((upsertBackGround: UpsertBackGround) => backGroundApi.upsertBackGround(upsertBackGround));
};

export const useUpsertAssumption = (dashboardId: string) => {
    const apiService = useApiService();
    const assumptionApi = new AssumptionApi(apiService, dashboardId);

    return useUpsertApi((upsertAssumption: UpsertAssumption) => assumptionApi.upsertAssumption(upsertAssumption));
};

export const useUpsertParty = (dashboardId: string) => {
    const apiService = useApiService();
    const partyApi = new PartyApi(apiService, dashboardId);

    return useUpsertApi((upsertParty: UpsertParty) => partyApi.upsertParty(upsertParty));
};

export const useUpsertPartyPeople = (dashboardId: string) => {
    const apiService = useApiService();
    const partyPeopleApi = new PartyPeopleApi(apiService, dashboardId);

    return useUpsertApi((upsertPartyPeople: UpsertPartyPeople) => partyPeopleApi.upsertPartyPeople(upsertPartyPeople));
};

export const useUpsertDashboardFile = (dashboardId: string) => {
    const apiService = useApiService();
    const dashboardFileApi = new DashboardFileApi(apiService, dashboardId);

    return useUpsertApi((upsertDashboardFile: UpsertDashboardFile) => dashboardFileApi.upsertDashboardFile(upsertDashboardFile));
};

export const useInsertDashboardLog = (dashboardId: string) => {
    const apiService = useApiService();
    const dashboardLogApi = new DashboardLogApi(apiService, dashboardId);

    return useUpsertApi((insertDashboardLog: InsertDashboardLog) => dashboardLogApi.insertDashboardLog(insertDashboardLog));
};
