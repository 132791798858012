export interface MurphyBasicCustomerDto {
    Id: string,
    Name: string,
    ExternalContactsEnabled: boolean,
    ActiveProductIds: number[],
}

export interface ICustomersApi {
    getCustomers: () => Promise<MurphyBasicCustomerDto[]>;
    getCustomersMobile: () => Promise<MurphyBasicCustomerDto[]>;
    getCustomer: (customerId: string) => Promise<MurphyBasicCustomerDto>;
    getChildCustomers: (customerId: string) => Promise<MurphyBasicCustomerDto[]>;
}

const CustomersApiType = {
    ICustomersApi: Symbol("ICustomersApi"),
};

export default CustomersApiType;