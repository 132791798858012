import { InformationCard, UpsertInformationCard } from "../../api/InformationCardApi";
import UpsertInformationCardComponent from "./UpsertInformationCardComponent";
import * as React from 'react';
import InformationCardFormatted from "./InformationCardFormatted";
import Button from '@murphy-frontend/web-core/components/Button';
import CardFooter from "../MainLog/CardFooter";
import { FunctionComponent } from "react";
import { useUpsertInformationCard } from "../../mutations";

interface InformationCardExpandedContentProps {
    informationCard: InformationCard,
    dashboardId: string,
    openModal: any,
    closeModal: any,
    translations: Record<string, string>,
}

const InformationCardExpandedContent: FunctionComponent<InformationCardExpandedContentProps> = ({ informationCard, dashboardId, openModal, closeModal, translations }) => {

    const { mutate: upsertInformationCardMutation, isPending } = useUpsertInformationCard(dashboardId);

    const onClickEdit = () => {
        const upsertInformationCardComponent =
            <UpsertInformationCardComponent
                translations={translations}
                dashboardId={dashboardId}
                informationCard={informationCard}
                onSuccess={() => closeModal()} />;

        const configuration = {
            centerContent: false,
            hideButton: true,
            body: upsertInformationCardComponent,
            title: translations['lang-modal-situation-card'],
        }
        openModal(configuration);
    }

    const onClickReactivate = () => {
        const newCard: UpsertInformationCard = { ...informationCard }
        newCard.ParentId = informationCard.ID;
        newCard.archived = false;
        upsertInformationCardMutation(newCard);
    }

    let footer;
    if (informationCard.archived !== true) {
        const button =
            <Button
                onClick={() => onClickEdit()}>
                {translations['class-lang-button-edit']}
            </Button>
        footer = <CardFooter rightContent={button} />
    }

    if (informationCard.archived === true && !informationCard.child) {
        const button =
            <Button
                isLoading={isPending}
                isNeutralButton
                buttonStyle="btn--unarchive"
                buttonSize="btn-with-icon"
                onClick={() => onClickReactivate()}>
                {translations['activate']}
            </Button>
        footer = <CardFooter rightContent={button} />
    }
    return <InformationCardFormatted infoCard={informationCard} translations={translations} cardFooter={footer} />
}

export default InformationCardExpandedContent;