import { useQuery } from '@tanstack/react-query';
import { DashboardTypes } from '@murphy-frontend/common/enums';
import { Dashboard, DashboardApi, DashboardMember, Template } from './DashboardsApi';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';

const useDashboardApi = () => {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  return new DashboardApi(apiService);
};

export function useDashboards(customerId: string) {
  const dashboardApi = useDashboardApi();

  return useQuery({
    queryKey: queryKeys.list(customerId),
    queryFn: () => dashboardApi.getCustomerDashboards(customerId).then(({ data }) => data as Dashboard[]),
    enabled: !!customerId, staleTime: 60 * 60 * 1000
  });
}

export function useGetDashboard(dashboardId: string) {
  const dashboardApi = useDashboardApi();

  return useQuery({
    queryKey: queryKeys.detail(dashboardId),
    queryFn: () => dashboardApi.getById(dashboardId).then(({ data }) => data),
    enabled: !!dashboardId, staleTime: 60 * 60 * 1000
  });
}

export function useGetDashboardMembers(dashboardId: string, dashboardTypeId: number) {
  const dashboardApi = useDashboardApi();

  return useQuery({
    queryKey: queryKeys.members(dashboardId),
    queryFn: () => dashboardApi.getDashboardMembers(dashboardId).then(({ data }) => data),
    enabled: !!dashboardId && dashboardTypeId === DashboardTypes.ExplicitPermissions,
    staleTime: 60 * 60 * 1000
  });
}

export function useGetDashboardTemplates(customerId: string) {
  const dashboardApi = useDashboardApi();

  return useQuery({
    queryKey: ['templates', customerId],
    queryFn: () => dashboardApi.getTemplatesByCustomerId(customerId).then(({ data }) => data),
    enabled: !!customerId,
    staleTime: 60 * 60 * 1000
  });
}
