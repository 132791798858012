import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';
import {
  useNavigate,
} from 'react-router-dom';

import dayjs from 'dayjs';
import Button from '@murphy-frontend/web-core/components/Button';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import SearchBar from '@murphy-frontend/web-core/components/SearchBar';;
import FilterControl, { FilterType } from '@murphy-frontend/web-core/components/FilterControl';
import DashboardsList, { DashboardsListViewModel } from './components/DashboardsList';

import { openInNewTab } from '@murphy-frontend/common/utils';
import { getConvertedDate } from '@murphy-frontend/common/services/TimeService'

import { useDashboards } from '../../common/api/Dashboards/queries';
import { useEditDashboard } from '../../common/api/Dashboards/mutations';
import { Dashboard, UpdateDashboardRequest } from '../../common/api/Dashboards/DashboardsApi';
import { useCustomer } from '../../common/contexts/CustomerContext';
import { OnboardingFlowIds, useOnboarding } from '../../common/contexts/OnboardingContext';
import DashboardAdminOnboardingSteps from './DashboardAdminOnboardingSteps';
import { parseHtml } from '@murphy-frontend/web-core/features/RichText/helpers';
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';
import RichTextDisplay from '@murphy-frontend/web-core/features/RichText/RichTextDisplay';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import InfoIcon from '@murphy-frontend/web-core/components/InfoIcon';

interface DashboardsProps {
  spawnModal: any,
  translations: Record<string, string>,
  currentScreenSize: any,
  timeZone: any,
  language: any,
}

const Dashboards: FunctionComponent<DashboardsProps> = ({ spawnModal, translations, timeZone, language, }) => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { customer } = useCustomer();

  const [filteredDashboards, setFilteredDashboards] = useState([]);

  const [allFilters, setAllFilters] = useState([]);
  const [currentSearchText, setCurrentSearchText] = useState('');

  const [stepsEnabled, setStepsEnabled] = useState<boolean>(false);
  const [onboardingTerminated, setOnboardingTerminated] = useState<boolean>(null);
  const { setOnboardingIdIsComplete, incompletedOnboardingFlows, isLoading: onBoardingIsLoading, resetOnboarding, setCurrentStep, onboardingsCurrentStep } = useOnboarding();

  const {
    isLoading, isError, data, error,
  } = useDashboards(customer?.Id);

  const { mutate: editDashboard, isPending: editDashboardIsLoading } = useEditDashboard(customer?.Id, translations);

  const mapDashboards = (rawDataDashboards: Dashboard[]): DashboardsListViewModel[] => rawDataDashboards.map((dash) => ({
    id: dash.dashboardid,
    dashboardname: decodeURIComponent(dash.Name),
    isarchived: dash.Arkiverad,
    link: dash.adminlink,
    dashboardtypeid: dash.DashboardTypeId,
    createddatetime: getConvertedDate(dayjs.utc(dash.CreatedDateTime), timeZone),
    lastupdatedatetime: dash.LastUpdateDateTime ? getConvertedDate(dayjs.utc(dash.LastUpdateDateTime), timeZone) : '',
  }));

  const onSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setCurrentSearchText(newValue);
  };

  function ErrorMessage() {
    return <p>{error.message}</p>;
  }

  const onClickEditDashboard = (dashboardRow: DashboardsListViewModel) => {
    const url = `/dashboards/${dashboardRow.id}`;
    navigate(url);
  };

  const onSubmitArchiveDashboard = (dashboardId: string) => {
    const editDashboardRequest: UpdateDashboardRequest = {
      DashboardId: dashboardId,
      ArchiveBoard: true,
    };

    editDashboard(editDashboardRequest);
  };

  const onSubmitActivateDashboard = (dashboardId: string) => {
    const editDashboardRequest: UpdateDashboardRequest = {
      DashboardId: dashboardId,
      ActivateBoard: true,
    };

    editDashboard(editDashboardRequest);
  };

  function ArchiveConfirmationBody(dashboardname: string) {
    return (
      <p>
        {translations.archive}
        {' '}
        <strong>{dashboardname}</strong>
        {' '}
        ?
      </p>
    );
  }

  function ActivateConfirmationBody(dashboardname: string) {
    return (
      <p>
        {translations.activate}
        {' '}
        <strong>{dashboardname}</strong>
        {' '}
        ?
      </p>
    );
  }

  const onClickArchiveDashboard = (dashboardRow: DashboardsListViewModel) => {
    const modalConf = {
      title: `${translations.archive} dashboard`,
      body: ArchiveConfirmationBody(dashboardRow.dashboardname),
      hideButton: false,
      buttonText: translations.archive,
      type: 'warning',

      okCallback: () => onSubmitArchiveDashboard(dashboardRow.id),
    };
    spawnModal(modalConf);
  };

  const onClickActivateDashboard = (dashboardRow: DashboardsListViewModel) => {
    const modalConf = {
      title: `${translations.activate} dashboard`,
      body: ActivateConfirmationBody(dashboardRow.dashboardname),
      hideButton: false,
      buttonText: translations.activate,
      type: 'info',

      okCallback: () => onSubmitActivateDashboard(dashboardRow.id),
    };
    spawnModal(modalConf);
  };

  const onClickGoToDashboard = (row: DashboardsListViewModel) => {
    const linkToDashboard = `/dashboard/${row.id}`;
    openInNewTab(linkToDashboard);
  };

  const onToggleFilter = (filt: FilterType) => {
    const updatedFilters = allFilters.map((filter) => {
      const updatedItem = { ...filter };
      if (filter.columnname === filt.columnname && filter.filtervalue === filt.filtervalue) {
        updatedItem.isActive = !filter.isActive;
      }
      return updatedItem;
    });

    setAllFilters(updatedFilters);
  };

  const onClickNewDashboard = () => {
    navigate('/dashboards/new');
  };

  useEffect(() => {
    if (data && data.length > 0) {
      let newFilteredDashboards = mapDashboards(data);

      if (currentSearchText) {
        const searchValue = currentSearchText.toUpperCase();
        newFilteredDashboards = newFilteredDashboards.filter((p) => p.dashboardname.toUpperCase().startsWith(searchValue));
      }
      if (allFilters) {
        for (let i = 0; i < allFilters.length; i++) {
          const currentFilter = allFilters[i];
          if (currentFilter.isActive === true) {
            newFilteredDashboards = newFilteredDashboards.filter((dash) => {
              if (dash[currentFilter.columnname] === currentFilter.filtervalue) {
                return dash;
              }
            });
          }
        }
      }

      setFilteredDashboards(newFilteredDashboards);
    }
  }, [allFilters, currentSearchText, data, timeZone]);

  const generateInitialFilters = () => {
    const allPossibleFilters = [
      {
        displayName: translations.onlyactive,
        columnname: 'isarchived',
        filtervalue: false,
        isActive: true,
      },
      {
        displayName: translations.onlyarchived,
        columnname: 'isarchived',
        filtervalue: true,
        isActive: false,
      },
    ];

    setAllFilters(allPossibleFilters);
  };

  useEffect(() => {
    if (customer.Id) {
      generateInitialFilters();
    }
  }, [customer]);

  useEffect(() => {
    generateInitialFilters();
  }, [translations]);

  useEffect(() => {
    if (error) {
      const modalConf = {
        title: 'Error',
        body: <ErrorMessage />,
        buttonText: 'Ok',
        type: 'error',
      };
      spawnModal(modalConf);
    }
  }, [isError]);

  useEffect(() => {
    if (onboardingTerminated === true) {
      return;
    }
    const shouldShowOnboarding = incompletedOnboardingFlows.map(p => p.OnboardingFlowId).includes(OnboardingFlowIds.Dashboard);
    if (shouldShowOnboarding && !onBoardingIsLoading) {
      const currentStep = onboardingsCurrentStep.get(OnboardingFlowIds.Dashboard);
      if (currentStep === 0) {
        const stepsFromApi = incompletedOnboardingFlows.find(p => p.OnboardingFlowId === OnboardingFlowIds.Dashboard)?.StepsHtmlContents;
        const introText = stepsFromApi.get(1);
        const parsedHtmlIntro = introText ? parseHtml(introText) : "";
        const onClickOk = () => {
          setCurrentStep(OnboardingFlowIds.Dashboard, 1);
          closeModal();
          setStepsEnabled(true);
        }

        const onClickClose = () => {
          setOnboardingIsComplete();
        }

        const modalConf: ModalConfiguration = {
          okCallback: onClickOk,
          onCloseModal: onClickClose,
          type: 'info',
          centerContent: true,
          body: <div className="intro-outro-container">
            <RichTextDisplay content={parsedHtmlIntro} />
          </div>,
          title: "Tutorial",
          buttonText: "Start",
          position: 'center'
        }
        openModal(modalConf);
      }
      else {
        setStepsEnabled(true);
      }
    }
  }, [incompletedOnboardingFlows, onBoardingIsLoading, onboardingsCurrentStep]);

  const handleOnboardingComplete = () => {
    setOnboardingIsComplete();
  };

  const setOnboardingIsComplete = () => {
    setOnboardingTerminated(true);
    setOnboardingIdIsComplete(OnboardingFlowIds.Dashboard);
    setCurrentStep(OnboardingFlowIds.Dashboard, 0);
    setStepsEnabled(false);
  }

  if (editDashboardIsLoading || isLoading || !translations) {
    return (<Spinner isGlobal />);
  }

  const onClickStartOnboarding = () => {
    setOnboardingTerminated(false);
    resetOnboarding(OnboardingFlowIds.Dashboard);
  }

  const stepsComponent = <DashboardAdminOnboardingSteps
    stepsEnabled={stepsEnabled}
    onComplete={handleOnboardingComplete}
    setStepsEnabled={setStepsEnabled} />

  const stepsIcon = <InfoIcon
    place={"right-end"}
    onHoverContents={<Button onClick={onClickStartOnboarding}>Start tutorial</Button>}
    clickable={true}
  />

  return (
    <div className="generalwrapper-dashboard">
      {stepsEnabled && stepsComponent}
      <section className="header-container vertical-aligner">
        <div className="one header-with-info-button">
          <div className="dashboard-new">
            <Button isIconButton buttonStyle="btn--add" buttonSize="btn-with-icon" onClick={onClickNewDashboard}><span className="btn-with-icon-text">{translations['lang-new-dasboard']}</span></Button>

          </div>
          {stepsIcon}
        </div>
        <div className='two' />
        <div className='three'>
          <SearchBar val={currentSearchText} handleChange={onSearchChanged} placeholder={translations['placeholder-lang-search-action']} />
        </div>

      </section>
      <section className="user-table-container">
        <div className="filter-control-wrapper">
          <div className="table-column-group-header-label">FILTER</div>
          <FilterControl allFilters={allFilters} onToggleFilter={onToggleFilter} translations={translations} />
        </div>

        <DashboardsList
          dashboards={filteredDashboards}
          onClickGoToDashboard={onClickGoToDashboard}
          onClickEditDashboard={onClickEditDashboard}
          onClickArchiveDashboard={onClickArchiveDashboard}
          onClickActivateDashboard={onClickActivateDashboard}
          translations={translations}
          language={language}
        />

      </section>
    </div>
  );
}

export default Dashboards;