import React, { useState, useEffect, FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import logo from '../../../../assets/img/Praeparatus_Supervivet_Practise_Risk.jpg';
import { useDimensions } from "../../../hooks/useDimensions";

interface GuideCardProps {
  title: string;
  description: string;
  footer: any;
  backGroundUrl: any;
  trainingId: any;
  translations: Record<string, string>;
  language?: string;
}

export const GuideCard: FunctionComponent<GuideCardProps> = ({
  title,
  description,
  footer,
  backGroundUrl,
  trainingId,
  translations,
  language,
}) => {
  const navigate = useNavigate();
  const [snipDescription, setSnipDescription] = useState(false);
  const [coursecardTextClass, setCoursecardTextClass] =
    useState("course-card-text");

  const [isExpanded, setIsExpanded] = useState(false);

  const [ref, { height }] = useDimensions();

  const onClickImage = () => {
    const url = `/training/${trainingId}`;
    navigate(url);
  };

  useEffect(() => {
    if (isExpanded) {
      setCoursecardTextClass("course-card-text-expanded");
    } else if (height) {
      if (height > 120) {
        setSnipDescription(true);
        setCoursecardTextClass("course-card-text course-card-text-more");
      } else {
        setSnipDescription(false);
        setCoursecardTextClass("course-card-text");
      }
    } else {
      setSnipDescription(false);
      setCoursecardTextClass("course-card-text");
    }
  }, [height, isExpanded]);

  const onClickExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const formatString = (str: string) => {
    const lines = str.split(/\n/);
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="course-card-container">
      <div className="course-card-header">
        <div
          onClick={onClickImage}
          className="course-card-background-image"
          style={{
            backgroundImage: `url(${backGroundUrl ?? logo})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          {language && <div className="course-card-completed-sessions">
            {language &&
              <div className="course-card-booked-icon">
                {language}
              </div>
            }
          </div>}

        </div>
      </div>
      <div className="course-card-body">
        <h2>{title}</h2>
        <div className={coursecardTextClass}>
          <p ref={ref}>{formatString(description)}</p>
        </div>
        {snipDescription === true && (
          <div className="course-card-more">
            {!isExpanded && (
              <p onClick={onClickExpand}>{translations.showmore} &gt;</p>
            )}
          </div>
        )}
      </div>
      <div className="course-card-footer">{footer}</div>
    </div>
  );
};
