import { inject, injectable } from "inversify";
import ApiServiceType, { IApiService } from "../../interfaces/IApiService";
import { ContactModel, IContactsApi } from "../../interfaces/IContactsApi";


@injectable()
export class ContactsApi implements IContactsApi {

    constructor(
        @inject(ApiServiceType.IApiService) private apiService: IApiService,
    ) { }

    getContactsByCustId = async (customerId: string) => {
        const url = `/api/Contact?customerId=${customerId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };

    createContact = (request: ContactModel) => {
        const url = '/api/Contact';
        return this.apiService.callApi(url, 'POST', request);
    };

    editContact = (request: ContactModel) => {
        const url = `/api/Contact/${request.ID}`;
        return this.apiService.callApi(url, 'PUT', request);
    };

    removeContact = (contactId: number) => {
        const url = `/api/Contact/${contactId}`;
        return this.apiService.callApi(url, 'DELETE');
    };

    getCustomerContactsOptionSets = async (customerId: string) => {
        const url = `/api/OptionSet?customerId=${customerId}&isContactsEnabled=true`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };


}