import * as dayjs from 'dayjs';
import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';
import Button from '@murphy-frontend/web-core/components/Button';
import SearchBar from '@murphy-frontend/web-core/components/SearchBar';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { useUserPreferences } from '../../../../common/contexts/UserPreferencesContext';
import { FileTypes, Roles } from '@murphy-frontend/common/enums';
import { getConvertedDate } from '@murphy-frontend/common/services/TimeService'
import EditFile, { EditFileRequest } from '@murphy-frontend/web-core/components/EditFile';
import FileList, { FileListFile } from '@murphy-frontend/web-core/components/FileList';
import FileUploadControl from '@murphy-frontend/web-core/components/FileUploadControl';
import { DashboardFile, UpsertDashboardFile } from '../../api/DashboardFileApi';
import { useUpsertDashboardFile } from '../../mutations';
import { useGetDashboardFiles } from '../../queries';
import { useUser } from "../../../../common/contexts/UserContext";

interface DashboardFilesProps {
  dashboardId: string;
}

const DashboardFiles: FunctionComponent<DashboardFilesProps> = ({
  dashboardId,
}) => {
  const { openModal, closeModal } = useModal();
  const { timeZone, translations } = useUserPreferences();
  const { user } = useUser();
  const [error, setError] = useState(null);

  const [files, setFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);

  const [currentSearchText, setCurrentSearchText] = useState("");

  const { mutate: upsertDashboardFileMutation, isPending: upsertIsLoading } =
    useUpsertDashboardFile(dashboardId);

  const {
    isLoading: isLoadingDashboardFiles,
    isError: isErrorDashboardFiles,
    data: dataDashboardFiles,
    error: getDashboardFilesError,
  } = useGetDashboardFiles(dashboardId);

  const mapFiles = (filesArray: DashboardFile[]) =>
    filesArray.map((file) => ({
      id: file.ID,
      filepath: file.FilePath,
      filename: file.FileName,
      createddatetime: getConvertedDate(
        dayjs.utc(file.CreatedDateTime),
        timeZone
      ),
    }));

  function DeleteConfirmationBody({ name }) {
    return (
      <p>
        {translations["del-file"]} <strong>{name}</strong>{" "}
      </p>
    );
  }

  useEffect(() => {
    if (!isLoadingDashboardFiles) {
      const filteredFiles = dataDashboardFiles.filter(
        (p) => p.archived === false
      );
      setFiles(filteredFiles);
    }
  }, [dataDashboardFiles, isLoadingDashboardFiles]);

  const submitRemoveFile = (fileId: string) => {
    const upsertDashboardFile: UpsertDashboardFile = {
      Id: fileId,
      archived: true,
    };
    upsertDashboardFileMutation(upsertDashboardFile);
  };

  const submitEditFile = (editFileRequest: EditFileRequest) => {
    const upsertDashboardFile: UpsertDashboardFile = {
      Id: editFileRequest.Id,
      FileName: editFileRequest.NewName,
      archived: false,
    };
    return upsertDashboardFileMutation(upsertDashboardFile, {
      onSettled: () => {
        closeModal();
      }
    });
  };

  const submitAddFile = (file: File) => {
    closeModal();

    const upsertDashboardFile: UpsertDashboardFile = {
      file: file,
      archived: false,
    };
    return upsertDashboardFileMutation(upsertDashboardFile, {
      onSettled: () => {
        closeModal();
      }
    });
  };

  const onClickNewFile = () => {
    const uploadTemplate = (
      <FileUploadControl
        translations={translations}
        onSubmit={submitAddFile}
        onClose={() => closeModal()}
      />
    );
    const modalConf = {
      title: translations["lang-button-add-file"],
      body: uploadTemplate,
      buttonText: "Ok",
      type: "info",
      hideButton: true,
    };
    openModal(modalConf);
  };

  const onClickEditFile = (fileRow: FileListFile) => {
    const modalConf = {
      title: translations.edit,
      body: (
        <EditFile
          id={fileRow.id}
          nameInit={fileRow.filename}
          onSuccess={() => closeModal()}
          onFail={() => closeModal()}
          translations={translations}
          onSaveEdit={submitEditFile}
        />
      ),
      hideButton: true,
      type: "info",
    };

    openModal(modalConf);
  };

  const onClickRemoveFile = (fileRow: FileListFile) => {
    const modalConf = {
      title: translations["class-remove-file"],
      body: <DeleteConfirmationBody name={fileRow.filename} />,
      hideButton: false,
      buttonText: translations["class-lang-user-table-delete"],
      type: "warning",
      okCallback: () => submitRemoveFile(fileRow.id),
    };

    openModal(modalConf);
  };

  const onSearchChanged = (event) => {
    const newValue = event.target.value;
    setCurrentSearchText(newValue);
  };

  function ErrorMessage() {
    return <p>{error}</p>;
  }

  useEffect(() => {
    if (files) {
      let newFilteredFiles = mapFiles(files);

      if (currentSearchText) {
        const searchValue = currentSearchText.toUpperCase();
        newFilteredFiles = newFilteredFiles.filter((p) =>
          p.filename.toUpperCase().startsWith(searchValue)
        );
      }
      setFilteredFiles(newFilteredFiles);
    }
  }, [files, currentSearchText, timeZone]);

  useEffect(() => {
    if (error) {
      const modalConf = {
        title: "Error",
        body: <ErrorMessage />,
        buttonText: "Ok",
        type: "error",
        okCallback: () => {
          setError(null);
        },
      };
      openModal(modalConf);
    }
  }, [error]);

  if (isLoadingDashboardFiles || upsertIsLoading) {
    return (
      <div className="spinner-container-global-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="generalwrapper">
      <section className="header-container vertical-aligner">
        <div className="one" />
        {user?.Role !== Roles.Trial && (
          <div className="two">
            <Button
              isIconButton
              buttonStyle="btn--add"
              buttonSize="btn-with-icon"
              onClick={onClickNewFile}
            >
              <span className="btn-with-icon-text">
                {translations.uploadnewfile}
              </span>
            </Button>
          </div>
        )}
        <div className="three">
          <SearchBar
            val={currentSearchText}
            handleChange={onSearchChanged}
            placeholder={translations["placeholder-lang-search-action"]}
          />
        </div>
      </section>
      <section className="user-table-container">
        <div>
          <FileList
            files={filteredFiles}
            onClickRemoveFile={onClickRemoveFile}
            onClickEditFile={onClickEditFile}
          />
        </div>
      </section>
    </div>
  );
};

export default DashboardFiles;