import React, { useState } from "react";
import { RiCalendarCheckFill, RiTimer2Fill, RiListCheck2, RiCheckFill, RiCalendar2Fill } from "react-icons/ri";
import { BsCalendar2Week } from "react-icons/bs";
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { useUserPreferences } from "../../../common/contexts/UserPreferencesContext";
import { IconContext } from "react-icons/lib";

export const UserCourse = ({ course, allCourses }: any) => {
    const { openModal, closeModal } = useModal();
    const [hover, setHover] = useState<boolean>(false);
    const { translations } =
        useUserPreferences();
    const [formattedCourse, setFormattedCourse] = useState<any>(course)
    allCourses?.map((courseFromAll: any) => {
        if (courseFromAll.id === course.id) {
            formattedCourse.name = courseFromAll.name;
            formattedCourse.displayName = courseFromAll.displayName;

        }
    });


    const openCourseInfoModal = (course: any) => {
        const courseInfo =
            <div onClick={() => closeModal()}>
                {course?.trainingTag === 'trainingCompleted' ?
                    <div>
                        {translations.eacompletedinfo}
                    </div> : null}
                {course?.trainingTag === 'trainingBooked' ?
                    <div className='education-admin-course-modal-innerWrapper'>
                        <p>{translations.eabookedinfo}</p>
                        <p>{course?.date}.</p>
                    </div> : null}
            </div>;
        const configuration = {
            centerContent: false,
            hideButton: true,
            body: courseInfo,
            title: course?.name,
            type: course?.trainingTag
        }
        openModal(configuration);
    }

    return (
        <div className={'filter-control-box education-admin-course-wrapper'} onClick={() => openCourseInfoModal(course)} onMouseEnter={() => formattedCourse.trainingTag !== 'trainingCompleted' && setHover(true)} onMouseLeave={() => setHover(false)}>
            {`${!hover ? formattedCourse?.name?.slice(0, 12) : course?.date}`}
            <div className={`education-admin-course-icon ${formattedCourse.trainingTag === 'trainingCompleted' ? 'education-admin-course-completed' : formattedCourse.trainingTag === 'trainingBooked' ? 'education-admin-course-booked' : ''}`}>
                {formattedCourse.trainingTag === 'trainingCompleted' ?
                    <IconContext.Provider value={{ className: 'education-admin-icon' }}>
                        <RiCheckFill color={'#fff'} />
                    </IconContext.Provider>
                    : null}
                {formattedCourse.trainingTag === 'trainingBooked' ?
                    <IconContext.Provider value={{ className: 'education-admin-icon' }}>
                        <BsCalendar2Week color={'#fff'} />
                    </IconContext.Provider>
                    : null}
            </div>
        </div>
    )
}