import { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { DashboardCardFilter } from '../models';

export interface PartyPeople {
    ID: number,
    PartyId: number,
    PersonGuid: string,
    Name: string,
    Email: string,
    PhoneNr: string,
    Title: string,
    CreatedDateTimeUtc: Date,
    VersionToUtc: Date,
    archived: boolean,
    createdby: string,
    action: string,
}

export interface UpsertPartyPeople {
    PartyPeopleId?: number,
    Name: string,
    Email: string,
    PhoneNr: string,
    Title: string,
    archived: boolean,
    PartyId: number,
}

export class PartyPeopleApi {
    parentId: string;
    baseUrl: string;
    apiService: IApiService;

    constructor(apiService: IApiService, dashboardId: string) {
        this.parentId = dashboardId
        this.baseUrl = `api/Dashboard/${dashboardId}/PartyPeople`;
        this.apiService = apiService;
    }

    getById = (id: string) => {
        const url = `${this.baseUrl}/${id}`;
        return this.apiService.callApi(url, 'GET', null);
    }

    getAll = (filter?: DashboardCardFilter) => {
        let searchParamsString = '';
        if (filter) {
            const searchParams = new URLSearchParams();

            if (filter.fromDate) {
                searchParams.append('fromDate', filter.fromDate.toUTCString());
            }
            searchParamsString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
        }
        const url = `${this.baseUrl}${searchParamsString}`;
        return this.apiService.callApi(url, 'GET', null);
    }

    upsertPartyPeople = (upsertPartyPeople: UpsertPartyPeople) => {
        const url = `${this.baseUrl}`;
        return this.apiService.callApi(url, 'POST', upsertPartyPeople);
    }
};
