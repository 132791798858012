import ApiServiceType, { IApiService } from "@murphy-frontend/common/interfaces/IApiService";
import { inject, injectable } from "inversify";

export interface TrainingSessionModel {
    ID: number,
    TrainingId: number,
    Name: string,
    Desc: string,
    StartDateTimeUtc: Date,
    EndDateTimeUtc: Date,
    CustomerId?: string,
    ExternalLink: string,
    LastTimeToEnterUtc: Date,
    Educator: string,
    UseExternalLink: boolean,
    UrlToMeeting: string,
    isAvailableToRegister: boolean;
    AttendeeSpotLeft?: number | null
}

@injectable()
export class TrainingSessionApi {

    constructor(
        @inject(ApiServiceType.IApiService) private apiService: IApiService,
    ) { }

    getTrainingSessions = async (
        {
            customerId = null,
            trainingId = null,
        } = {},
    ): Promise<TrainingSessionModel[]> => {
        const baseUrl = 'api/Training/TrainingSession';
        const searchParams = new URLSearchParams();

        if (customerId) {
            searchParams.append('customerId', customerId);
        }
        if (trainingId) {
            searchParams.append('trainingId', trainingId);
        }

        const searchParamsString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
        const url = `${baseUrl}${searchParamsString}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };

    getTrainingSession = async (id: number): Promise<TrainingSessionModel> => {
        const url = `api/Training/TrainingSession/${id}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };
}

const TrainingSessionApiType = {
    TrainingSessionApi: Symbol("TrainingSessionApi"),
}

export default TrainingSessionApiType;