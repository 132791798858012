import { useQuery } from '@tanstack/react-query';
import { SendOutResponseApi } from './SendOutResponseApi';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import queryKeys from './queryKeys';

const useApiService = () => useInjection<IApiService>(ApiServiceType.IApiService);

export function useGetSendOutResponse(id: string) {
  const apiService = useApiService();
  const sendOutResponseApi = new SendOutResponseApi(apiService);

  const getSendOutResponseAsync = async (id: string) => {
    const data = await sendOutResponseApi.getSendOutResp(id);
    return { data }.data.data;
  };

  return useQuery({
    queryKey: queryKeys.detail(id),
    queryFn: () => getSendOutResponseAsync(id),
    enabled: !!id, staleTime: Infinity
  });
}

export function useGetSendOutResponses(sendOutBatchId: string) {
  const apiService = useApiService();
  const sendOutResponseApi = new SendOutResponseApi(apiService);

  const getSendOutResponsesByBatchIdAsync = async (sendOutBatchId: string) => {
    const data = await sendOutResponseApi.getSendOutResponsesByBatchId(sendOutBatchId);
    return { data }.data.data;
  };

  return useQuery({
    queryKey: queryKeys.list(sendOutBatchId),
    queryFn: () => getSendOutResponsesByBatchIdAsync(sendOutBatchId),
    enabled: !!sendOutBatchId, staleTime: Infinity
  });
}
