import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { RequirementApi } from './RequirementApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdateBlockStatusesRequest, UpdateCustomerGoalLevelRequest } from './models';
import { toast } from 'react-toastify';

export function useUpdateCustomerBlockStatuses() {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const requirementApi = new RequirementApi(apiService);

    return useMutation({
        mutationFn: (updateRequest: UpdateBlockStatusesRequest) => requirementApi.updateCustomerRequirementBlockStatus(updateRequest),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: queryKeys.customerBlocks(variables.CustomerId) });
            toast.success("Success");
        },
    });
}

export function useUpdateCustomerGoalLevel() {
    const queryClient = useQueryClient();
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const requirementApi = new RequirementApi(apiService);

    return useMutation({
        mutationFn: (updateRequest: UpdateCustomerGoalLevelRequest) => requirementApi.updateCustomerGoalLevel(updateRequest),
        onError: (err: any) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: queryKeys.requirementLevels(variables.CustomerId) });
            toast.success("Success");
        },
    });
}
