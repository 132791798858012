import { useQuery } from '@tanstack/react-query';
import { DashboardEntityTypes } from '@murphy-frontend/common/enums';
import { ActionsCardApi } from './api/ActionsCardApi';
import { AssumptionApi } from './api//AssumptionApi';
import { BackGroundApi } from './api//BackGroundApi';
import { GoalsApi } from './api//GoalsApi';
import { PartyApi } from './api/PartyApi';
import queryKeys from './queryKeys';
import { StatusCardApi } from './api/StatusCardApi';
import { PartyPeopleApi } from './api/PartyPeopleApi';
import { DashboardFileApi } from './api/DashboardFileApi';
import { InformationCardApi } from './api/InformationCardApi';
import { DashboardApi } from '../../common/api/Dashboards/DashboardsApi';
import { DashboardLogApi } from './api/DashboardLogApi';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
const useApiService = () => useInjection<IApiService>(ApiServiceType.IApiService);

const useDashboardApi = () => new DashboardApi(useApiService());
const useInformationCardApi = (dashboardId: string) => new InformationCardApi(useApiService(), dashboardId);
const useActionsCardApi = (dashboardId: string) => new ActionsCardApi(useApiService(), dashboardId);
const useStatusCardApi = (dashboardId: string) => new StatusCardApi(useApiService(), dashboardId);
const useGoalsApi = (dashboardId: string) => new GoalsApi(useApiService(), dashboardId);
const useBackGroundApi = (dashboardId: string) => new BackGroundApi(useApiService(), dashboardId);
const useAssumptionApi = (dashboardId: string) => new AssumptionApi(useApiService(), dashboardId);
const usePartyApi = (dashboardId: string) => new PartyApi(useApiService(), dashboardId);
const usePartyPeopleApi = (dashboardId: string) => new PartyPeopleApi(useApiService(), dashboardId);
const useDashboardFileApi = (dashboardId: string) => new DashboardFileApi(useApiService(), dashboardId);
const useDashboardLogApi = (dashboardId: string) => new DashboardLogApi(useApiService(), dashboardId);

export function useGetDashboard(dashboardId: string) {
    const dashboardApi = useDashboardApi();
    return useQuery({
        queryKey: queryKeys.dashboardentity(dashboardId, DashboardEntityTypes.Dashboard),
        queryFn: () => dashboardApi.getById(dashboardId).then(({ data }) => data),
        enabled: !!dashboardId, staleTime: Infinity
    });
}

export function useGetDashboardInformationCards(dashboardId: string) {
    const informationCardApi = useInformationCardApi(dashboardId);
    return useQuery({
        queryKey: queryKeys.dashboardentity(dashboardId, DashboardEntityTypes.InformationCard),
        queryFn: () => informationCardApi.getAll().then(({ data }) => data),
        enabled: !!dashboardId, staleTime: Infinity
    });
}

export function useGetDashboardActionsCards(dashboardId: string) {
    const actionsCardApi = useActionsCardApi(dashboardId);
    return useQuery({
        queryKey: queryKeys.dashboardentity(dashboardId, DashboardEntityTypes.ActionsCard),
        queryFn: () => actionsCardApi.getAll().then(({ data }) => data),
        enabled: !!dashboardId, staleTime: Infinity
    });
}

export function useGetDashboardStatusCards(dashboardId: string) {
    const statusCardApi = useStatusCardApi(dashboardId);
    return useQuery({
        queryKey: queryKeys.dashboardentity(dashboardId, DashboardEntityTypes.StatusCard),
        queryFn: () => statusCardApi.getAll().then(({ data }) => data),
        enabled: !!dashboardId, staleTime: Infinity
    });
}

export function useGetGoals(dashboardId: string) {
    const goalsApi = useGoalsApi(dashboardId);
    return useQuery({
        queryKey: queryKeys.dashboardentity(dashboardId, DashboardEntityTypes.Goals),
        queryFn: () => goalsApi.getAll().then(({ data }) => data),
        enabled: !!dashboardId, staleTime: Infinity
    });
}

export function useGetBackGrounds(dashboardId: string) {
    const backGroundApi = useBackGroundApi(dashboardId);
    return useQuery({
        queryKey: queryKeys.dashboardentity(dashboardId, DashboardEntityTypes.BackGround),
        queryFn: () => backGroundApi.getAll().then(({ data }) => data),
        enabled: !!dashboardId, staleTime: Infinity
    });
}

export function useGetAssumptions(dashboardId: string) {
    const assumptionApi = useAssumptionApi(dashboardId);
    return useQuery({
        queryKey: queryKeys.dashboardentity(dashboardId, DashboardEntityTypes.Assumption),
        queryFn: () => assumptionApi.getAll().then(({ data }) => data),
        enabled: !!dashboardId, staleTime: Infinity
    });
}

export function useGetParties(dashboardId: string) {
    const partyApi = usePartyApi(dashboardId);
    return useQuery({
        queryKey: queryKeys.dashboardentity(dashboardId, DashboardEntityTypes.Party),
        queryFn: () => partyApi.getAll().then(({ data }) => data),
        enabled: !!dashboardId, staleTime: Infinity
    });
}

export function useGetPartyPeople(dashboardId: string) {
    const partyPeopleApi = usePartyPeopleApi(dashboardId);
    return useQuery({
        queryKey: queryKeys.dashboardentity(dashboardId, DashboardEntityTypes.PartyPeople),
        queryFn: () => partyPeopleApi.getAll().then(({ data }) => data),
        enabled: !!dashboardId, staleTime: Infinity
    });
}

export function useGetDashboardFiles(dashboardId: string) {
    const dashboardFileApi = useDashboardFileApi(dashboardId);
    return useQuery({
        queryKey: queryKeys.dashboardentity(dashboardId, DashboardEntityTypes.DashboardFile),
        queryFn: () => dashboardFileApi.getAll().then(({ data }) => data),
        enabled: !!dashboardId, staleTime: Infinity
    });
}

export function useGetDashboardLogs(dashboardId: string) {
    const dashboardLogApi = useDashboardLogApi(dashboardId);
    return useQuery({
        queryKey: queryKeys.dashboardentity(dashboardId, DashboardEntityTypes.DashboardLog),
        queryFn: () => dashboardLogApi.getAll().then(({ data }) => data),
        enabled: !!dashboardId, staleTime: Infinity
    });
}
