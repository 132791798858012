import { useRef } from 'react';

// Define the type for the onSubmit prop expected by the hook
type SubmitHandler = (formData: FormData) => Promise<void>;

// The hook returns an object containing the form reference and the handleSubmit function
interface UseFormSubmitReturn {
    formRef: React.RefObject<HTMLFormElement>;
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
}

function useFormSubmit(onSubmit: SubmitHandler): UseFormSubmitReturn {
    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevent the default form submission

        if (!formRef.current) {
            return; // Guard clause if the form ref is null
        }

        const formData = new FormData(formRef.current); // Create a FormData object from the form

        try {
            await onSubmit(formData); // Pass the form data to the onSubmit function passed to the hook
        } catch (error) {
            if (error instanceof Error) {
                console.error('Form submission failed:', error.message);
            } else {
                console.error('Form submission failed with unknown error');
            }
        }
    };

    return { formRef, handleSubmit };
}

export default useFormSubmit;
