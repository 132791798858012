import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";

export interface InsertLogRequest {
    Name: string,
    Message: string,
    StackTrace?: string,
}

export class LogService {

    constructor(private apiService: IApiService) { }

    error(error: Error) {

        const insertLogRequest: InsertLogRequest = {
            Name: error.name,
            Message: error.message,
            StackTrace: error.stack,
        }
        this.apiService.callApiWithoutToken('api/Log', 'POST', insertLogRequest);
    }
}