import { useQuery } from '@tanstack/react-query';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import queryKeys from './queryKeys';
import TrainingApiType, { TrainingApi } from './TrainingApi';

export function useGetTrainings(filter: {
  customerId?: string;
  customerUserId?: string;
}) {
  const api = useInjection<TrainingApi>(TrainingApiType.TrainingApiType);
  return useQuery({
    queryKey: queryKeys.all,
    queryFn: () => api.getAllTrainings(filter),
    enabled: !!filter.customerId || !!filter.customerUserId
  });
}

export function useGetTraining(id: number) {
  const api = useInjection<TrainingApi>(TrainingApiType.TrainingApiType);
  return useQuery({
    queryKey: queryKeys.detail(id),
    queryFn: () => api.getTraining(id),
    enabled: !!id
  });
}
