import { useQuery } from '@tanstack/react-query';
import { MBSApi } from './MBSApi';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import queryKeys from './queryKeys';

const useApiService = () => useInjection<IApiService>(ApiServiceType.IApiService);

export function useGetSendOuts(customerId: string) {
  const apiService = useApiService();
  const mbsApi = new MBSApi(apiService);

  return useQuery({
    queryKey: queryKeys.list(customerId),
    queryFn: () => mbsApi.getSendOuts(customerId).then((data: any) => ({ data }.data.data)),
    enabled: !!customerId, refetchOnWindowFocus: true
  });
}
