import React, { useState } from "react";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import Button from '@murphy-frontend/web-core/components/Button';
import { useUserPreferences } from "../../../common/contexts/UserPreferencesContext";
import SearchBar from '@murphy-frontend/web-core/components/SearchBar';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import ExistingAccountCard from "../components/ExistingAccountCard";
import OptionSet from '@murphy-frontend/web-core/components/OptionSet';
import { roles } from '@murphy-frontend/web-core/constants';
import { toast } from 'react-toastify';
import { useCustomer } from "../../../common/contexts/CustomerContext";
import { useInjection } from "@murphy-frontend/common/contexts/InversifyContext";
import UsersApiType, { IUsersApi } from "@murphy-frontend/common/interfaces/IUsersApi";

const AddUserFromExistingAccount: FunctionComponent = () => {
    const navigate = useNavigate();
    const { customer } = useCustomer();
    const { translations } = useUserPreferences();
    const [role, setRole] = useState(0);

    const [searchString, setSearchString] = useState('');
    const [searchResult, setSearchResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const usersApi = useInjection<IUsersApi>(UsersApiType.IUsersApi);

    const onClickBack = () => {
        navigate(-1);
    };

    const onChangeSearchString = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setSearchString(e.target.value);
    }

    const onClickSearch = async () => {
        setIsLoading(true);

        usersApi.getExternalUserByUserName(searchString).then((res) => {
            setIsLoading(false);
            setSearchResult(res.data);
        }).catch(() => {
            setIsLoading(false);
            setIsError(true);
        });
    }

    const submit = async () => {
        setIsLoading(true)

        const addUserToExternalProviderRequest = {
            CustomerId: customer.Id,
            Role: role,
            ExternalId: searchResult.SID
        };

        usersApi.addUserToADGroup(addUserToExternalProviderRequest).then((res) => {
            setIsLoading(false);
            toast.success(`${translations.theuser} ${translations.created}`);
            navigate(-1);
        }).catch(err => {
            setIsLoading(false);
            setIsError(true);
        })
    }

    const onChangeRole = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const parsedRoleId = Number.parseInt(event.target.value, 10);
        setRole(parsedRoleId);
    };

    return (<div className="generalwrapper padding-bottom-60">
        <section className="header-container vertical-aligner">
            <div className="one">
                <h4>
                    {translations.newuser.toUpperCase()}
                </h4>
            </div>
            <div className="two">
                <div>
                    <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
                </div>
            </div>
            <div className="three" />
        </section>
        <section className="existing-account-result-card">
            <div >
                <label>
                    {translations.enterusername}
                </label>
                <br />
                <br />
                <SearchBar
                    val={searchString}
                    showSearchIcon={searchString && searchString.length > 0}
                    handleChange={onChangeSearchString}
                    onClickSearchIcon={onClickSearch}

                />
                <br />
            </div>
            {
                isLoading ?
                    <div className="spinner-container-center-small"><Spinner /></div>
                    :
                    searchResult &&
                    <div >
                        <ExistingAccountCard
                            translations={translations}
                            existingAccountViewModel={searchResult} />
                        <div >
                            <OptionSet
                                optionSetName={translations['lang-label-role']}
                                direction="row"
                                key={1}
                                optionSetId={1}
                                options={roles.filter(p => p.id < 3)}
                                selectedOption={role}
                                handleChange={onChangeRole}
                            />
                        </div>
                        <br />
                        <br />
                        <Button isIconButton buttonStyle="btn--add" buttonSize="btn-with-icon" onClick={submit}><span className="btn-with-icon-text">{translations.save}</span></Button>
                    </div>}
        </section>

    </div>)
}

export default AddUserFromExistingAccount;