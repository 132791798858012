import * as React from 'react'
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '@murphy-frontend/web-core/components/Button';
import MurphyDatePicker from '@murphy-frontend/web-core/components/MurphyDatePicker';
import * as dayjs from 'dayjs';

const meetingSchema = yup.object().shape({
    StatusMeetingDateTime: yup.date().nullable().notRequired(),
},
);

export interface MeetingFormDto {
    StatusMeetingDateTime?: Date
}

interface MeetingFormProps {
    meeting?: MeetingFormDto,
    onSubmit: (data: MeetingFormDto) => void,
    translations?: any,
    isLoading?: boolean,
}

const MeetingForm = ({ isLoading, meeting, onSubmit, translations }: MeetingFormProps) => {
    const {
        control, register, handleSubmit, formState: { errors, dirtyFields, isDirty }, reset, getValues,
    } = useForm<MeetingFormDto>({
        resolver: yupResolver(meetingSchema),
        defaultValues: {
        },
    });

    useEffect(() => {
        if (meeting) {
            const defaults = {
                StatusMeetingDateTime: meeting.StatusMeetingDateTime ? dayjs(meeting.StatusMeetingDateTime).toDate() : undefined,
            };
            reset(defaults);
        }
    }, [meeting, reset]);

    const onSubmitHandler = (data: MeetingFormDto) => {
        onSubmit(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>

            <div className="dashboardform-container">
                <div className='meeting-form-date-picker-container'>
                    <MurphyDatePicker
                        title={translations.date}
                        isControlled
                        control={control}
                        name="StatusMeetingDateTime"
                        locale="sv"
                        showTime={true}
                        errorMessage={errors.StatusMeetingDateTime?.message}
                    />
                </div>
                <div className='form-buttons'>
                    {isDirty && <Button disabled={!isDirty || isLoading} onClick={() => reset()} buttonStyle="btn--undo" buttonSize="btn--medium" isNeutralButton={true}>{translations.undo}</Button>}
                    <Button isLoading={isLoading} disabled={!isDirty || (errors && errors.length > 0)} type="submit" buttonStyle="btn--primary" buttonSize="btn--medium">{translations.save}</Button>
                </div>
            </div>
        </form >
    );
}

export default MeetingForm;