import React from 'react';

function MBSListHeader({ translations }) {
  return (
    <li className="item item-container item-container-configuration-pbslist">
      <div className="attribute" data-name={translations.datename} title={translations.datename}>{translations.datename}</div>
      <div className="attribute" data-name={translations.sender} title={translations.sender}>{translations.sender}</div>
      <div className="attribute" data-name={translations.title} title={translations.title}>{translations.title}</div>
      <div className="attribute" data-name={translations.statusname} title={translations.statusname}>{translations.statusname}</div>
    </li>
  );
}

export default MBSListHeader;
