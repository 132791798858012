import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";


export class TrainingFileApi {
  constructor(private apiService: IApiService) { }

  getTrainingFiles({ trainingId = null } = {}) {
    const baseUrl = 'api/Training/Training/File';
    const searchParams = new URLSearchParams();

    if (trainingId) {
      searchParams.append('trainingId', trainingId);
    }
    const searchParamsString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
    const url = `${baseUrl}${searchParamsString}`;
    return this.apiService.callApiWithoutToken(url, 'GET');
  };
}
