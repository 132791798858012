import { FunctionComponent } from "react";
import TitleWithControls from "./TitleWithControls";
import React from "react";
import { DroneComponent } from "@murphy-frontend/common/api/DroneEvents/types";

interface TitleComponentProps {
    eventFlowBlock: DroneComponent;
}

const TitleComponent: FunctionComponent<TitleComponentProps> = (
    {
        eventFlowBlock,
    }
) => {

    const content = <span>
        {eventFlowBlock.Title}
    </span>;

    return (<div className="m-spacing">
        <div className="l-text">
            <TitleWithControls
                content={content}
            />
        </div>
    </div>);
}

export default TitleComponent;