import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";

export interface OptionModel {
    ID: number,
    Value: string,
    OptionSetId: number,
    OptionSetName: string,
}

export interface OptionSetModel {
    ID: number,
    Name: string,
    CustomerId: string,
    EnabledForContacts: boolean,
    Options: OptionModel[],
}

export interface SelectedOptionSetViewModel {
    id: number,
    value: any,
    optionSetId: number,
    optionSetName: string,
}

export interface OptionViewModel {
    id: number,
    value: any,
}

export interface OptionSetViewModel {
    name: string,
    id: number,
    options: OptionViewModel[],
}

export interface ContactModel {
    ID?: number,
    uniqueKey?: string,
    Name: string,
    Email?: string,
    MobileNr?: string,
    DeviceToken?: string,
    CustomerId: string,
    type?: string,
    SelectedOptions?: OptionModel[],
}

export interface ContactViewModel {
    id: number,
    name: string,
    email?: string,
    mobile?: string,
    selectedOptions?: SelectedOptionSetViewModel[],
}

export class ContactsApi {
    apiService: IApiService;

    constructor(apiService: IApiService) {
        this.apiService = apiService;
    }

    getContactsByCustId = (customerId: string) => {
        const url = `/api/Contact?customerId=${customerId}`;
        return this.apiService.callApi(url, 'GET');
    };

    createContact = (request: ContactModel) => {
        const url = '/api/Contact';
        return this.apiService.callApi(url, 'POST', request);
    };

    editContact = (request: ContactModel) => {
        const url = `/api/Contact/${request.ID}`;
        return this.apiService.callApi(url, 'PUT', request);
    };

    removeContact = (contactId: number) => {
        const url = `/api/Contact/${contactId}`;
        return this.apiService.callApi(url, 'DELETE');
    };

    getCustomerContactsOptionSets = (customerId: string) => {
        const url = `/api/OptionSet?customerId=${customerId}&isContactsEnabled=true`;
        return this.apiService.callApi(url, 'GET');
    };

}