import { FunctionComponent } from "react";
import CheckListTitleWithControls from "./CheckboxTitleWithControls";
import { DroneComponentCheckListCheckbox } from "@murphy-frontend/common/api/DroneEvents/types";
import React from "react";

interface CheckboxInputComponentProps {
    eventFlowRespondBlock: DroneComponentCheckListCheckbox;
}

const CheckboxInputComponent: FunctionComponent<CheckboxInputComponentProps> = (
    {
        eventFlowRespondBlock,
    }) => {

    const content = <span>
        {eventFlowRespondBlock.Label}
    </span>;

    return (
        <>
            <div className="m-spacing"></div>
            <div className="drone-checklist-checkbox-wrapper">
                <div className="drone-text-wrapper">
                    <div className="drone-text-input-label">
                        <CheckListTitleWithControls
                            content={content}
                        />
                    </div>
                </div>
                <div className="drone-checkbox-wrapper">
                    <input disabled type="checkbox" className="drone-checkbox" checked={eventFlowRespondBlock.Value ?? false}></input>
                </div>
            </div>
        </>
    );
}

export default CheckboxInputComponent;