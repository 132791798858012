import { View, Text, } from "@react-pdf/renderer"
import * as React from "react"
import { PartyPeople } from "../../../api/PartyPeopleApi";

export const PartyPeoplePdfRow = (translations: Record<string, string>, styles: any, partyPeople: PartyPeople) => {
    let title = translations['class-lang-title-infocard-actor-updated'];
    if (partyPeople.action === "Created") {
        title = translations['class-lang-title-infocard-actor-created'];
    }

    return <View>
        <Text>
            <Text style={styles.label}>
                {title}&nbsp;
            </Text>
        </Text>
        {partyPeople.Name ?
            <Text>
                <Text style={styles.label}>
                    {translations['class-lang-modal-actor-name']}:&nbsp;
                </Text>
                <Text>
                    {partyPeople.Name}
                </Text>
            </Text> : null
        }
        {partyPeople.Email ?
            <Text>
                <Text style={styles.label}>
                    {translations['class-lang-modal-actor-email']}:&nbsp;
                </Text>
                <Text>
                    {partyPeople.Email}
                </Text>
            </Text> : null
        }
        {partyPeople.PhoneNr ?
            <Text>
                <Text style={styles.label}>
                    {translations['class-lang-modal-actor-phone']}:&nbsp;
                </Text>
                <Text>
                    {partyPeople.PhoneNr}
                </Text>
            </Text> : null
        }
        {partyPeople.Title ?
            <Text>
                <Text style={styles.label}>
                    {translations['class-lang-modal-actor-worktitle']}:&nbsp;
                </Text>
                <Text>
                    {partyPeople.Title}
                </Text>
            </Text> : null
        }
    </View>
}

