import { useUpsertOnBoardingFlowMutation } from "@murphy-frontend/common/api/Users/Mutations";
import { useGetUserOnBoardingFlows } from "@murphy-frontend/common/api/Users/Queries";
import { UpsertUserOnboardingFlowRequest, UserOnboardingFlow } from "@murphy-frontend/common/interfaces/IUsersApi";
import React, { createContext, useContext, useEffect, useState } from "react";
import PersistenceType, {
    IPersistenceService,
} from '@murphy-frontend/common/interfaces/IPersistenceService';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import { useUser } from "./UserContext";

interface OnboardingContextType {
    setOnboardingIdIsComplete: (onboardingFlowId: number) => void,
    resetOnboarding: (onboardingFlowId: number) => void,
    incompletedOnboardingFlows?: UserOnboardingFlow[],
    isLoading: boolean,
    onboardingsCurrentStep: Map<number, number>,
    setCurrentStep: (onboardingFlowId: number, step: number) => void,
}

export enum OnboardingFlowIds {
    Portal = 1,
    Dashboard = 2,
    MurphyStandard = 3,
    Academy = 4,
    Alert = 5,
}

const OnboardingContext = createContext<OnboardingContextType | undefined>(undefined);

export function OnboardingProvider({ children }: { children: React.ReactNode }) {
    const [incompletedOnboardingFlows, setIncompletedOnboardingFlows] = useState<Array<UserOnboardingFlow>>([]);
    const [onboardingsCurrentStep, setOnboardingsCurrentStep] = useState<Map<number, number>>(new Map<number, number>());

    const { user } = useUser();

    const persistenceService = useInjection<IPersistenceService>(
        PersistenceType.IPersistenceService,
    );

    const excludedPaths = ['/login', '/pbsresponse/', '/training/'];
    const isPathExcluded = excludedPaths.some(excludedPath => location.pathname.startsWith(excludedPath));
    const { data: userOnboardingFlows, isLoading: userOnboardingFlowsLoading } = useGetUserOnBoardingFlows(!isPathExcluded && user !== null);
    const { mutate: upsertOnBoardingFlow, isPending: upsertOnBoardingFlowLoading } = useUpsertOnBoardingFlowMutation();

    const setOnboardingIdIsComplete = (onboardingFlowId: number) => {
        const upsertOnBoardingFlowRequest: UpsertUserOnboardingFlowRequest = {
            OnboardingFlowId: onboardingFlowId,
            IsCompleted: true
        }
        return upsertOnBoardingFlow(upsertOnBoardingFlowRequest);
    }

    const setCurrentStep = (onboardingFlowId: number, step: number) => {
        persistenceService.setOnboaringFlowCurrentStep(onboardingFlowId, step);
        const newOnboardingCurrentSteps = onboardingsCurrentStep.set(onboardingFlowId, step);
        setOnboardingsCurrentStep(newOnboardingCurrentSteps);
    }

    const resetOnboarding = (onboardingFlowId: number) => {
        persistenceService.setOnboaringFlowCurrentStep(onboardingFlowId, 0);
        const upsertOnBoardingFlowRequest: UpsertUserOnboardingFlowRequest = {
            OnboardingFlowId: onboardingFlowId,
            IsCompleted: false
        }
        upsertOnBoardingFlow(upsertOnBoardingFlowRequest);
    }

    useEffect(() => {
        if (!userOnboardingFlowsLoading && userOnboardingFlows) {
            const newIncompleteOnboardingFlows = userOnboardingFlows.filter((p) => p.IsCompleted === false);
            setIncompletedOnboardingFlows(newIncompleteOnboardingFlows);
            const newOnboardingCurrentSteps = new Map<number, number>();
            userOnboardingFlows.forEach((p) => {
                const currentStep = persistenceService.getOnboaringFlowCurrentStep(p.OnboardingFlowId) || 0;
                newOnboardingCurrentSteps.set(p.OnboardingFlowId, currentStep)
            });
            setOnboardingsCurrentStep(newOnboardingCurrentSteps);
        }
    }, [userOnboardingFlows]);

    const isLoading = userOnboardingFlowsLoading || upsertOnBoardingFlowLoading;

    const value = {
        setOnboardingIdIsComplete,
        setCurrentStep,
        resetOnboarding,
        onboardingsCurrentStep,
        incompletedOnboardingFlows,
        isLoading,
    }

    return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
}

// Hook for child components to get the object ...
// ... and re-render when it changes.
export const useOnboarding = () => {
    const context = useContext(OnboardingContext);
    if (!context) throw new Error("OnboardingContext Provider not found");
    return context as OnboardingContextType;
}
