import { AxiosResponse } from "axios";

export interface UserTrainingSession {
    CustomerUserId?: string,
    ID: string,
    TrainingId: number,
    TrainingSessionId: number,
    StartDateTimeUtc: Date,
    EndDateTimeUtc: Date,
    CustomerId?: string,
    ExternalLink: string,
    UrlToMeeting: string,
    TrainingSessionStateId: number,
    TrainingName: string,
    CustomerName: string,
    UserName: string
}

export interface CreateCustomerUserTrainingSessionRequest {
    TrainingSessionId: number,
    CustomerUserId: string | null | undefined,
    Email?: string,
    CustomerName?: string,
}

export interface UpdateCustomerUserTrainingSessionsRequest {
    Id: string,
    NewStateId: number,
}

export interface IUserTrainingSessionsApi {
    getUserTrainingSessionsByCustomerUserId: (customerUserId: string) => Promise<UserTrainingSession[]>,
    updateUserTrainingSession: (updateUserTrainingSessionRequest: UpdateCustomerUserTrainingSessionsRequest) => Promise<AxiosResponse<any, any>>,
    createUserTrainingSession: (createUserTrainingSessionRequest: CreateCustomerUserTrainingSessionRequest) => Promise<AxiosResponse<any, any>>,
    deleteUserTrainingSession: (id: number) => Promise<AxiosResponse<any, any>>,
}

const UsersTrainingSessionsApiType = {
    IUsersTrainingSessionsApi: Symbol("IUsersTrainingSessionsApi"),
};

export default UsersTrainingSessionsApiType;
