import { useQuery } from '@tanstack/react-query';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import queryKeys from './queryKeys';
import TrainingSessionApiType, { TrainingSessionApi } from './TrainingSessionsApi';

export function useGetAllTrainingSessions() {
  const api = useInjection<TrainingSessionApi>(TrainingSessionApiType.TrainingSessionApi);
  return useQuery({
    queryKey: queryKeys.all,
    queryFn: () => api.getTrainingSessions()
  });
}

export function useGetTrainingSessions(filter: {
  customerId?: any;
  trainingId?: any;
}) {
  const api = useInjection<TrainingSessionApi>(TrainingSessionApiType.TrainingSessionApi);

  return useQuery({
    queryKey: queryKeys.list(filter),
    queryFn: () => api.getTrainingSessions(filter)
  });
}

export function useGetTrainingSession(id: number) {
  const api = useInjection<TrainingSessionApi>(TrainingSessionApiType.TrainingSessionApi);

  return useQuery({
    queryKey: queryKeys.detail(id),
    queryFn: () => api.getTrainingSession(id),
    enabled: !!id
  });
}
