import * as React from 'react';
import { useState, FunctionComponent } from 'react';
import {
  RiPencilFill, RiAddCircleFill, RiDeleteBin7Fill, RiArrowUpSLine, RiArrowDownSLine, RiShieldUserFill, RiUserFill,
} from 'react-icons/ri';

import { IconContext } from 'react-icons/lib';

interface ContactListItemProps {
  onClickEdit?: (id: number, type: string) => void,
  onClickDelete?: (id: number, type: string) => void,
  onClickAdd?: (id: number, type: string) => void,
  id: number,
  type?: string,
  name: string,
  email?: string,
  mobile?: string,
  selectedOptions?: any[],
  showEdit?: boolean,
  showDelete?: boolean,
  showAdd?: boolean,
  hideExpand?: boolean,
  translations: Record<string, string>
}

const ContactListItem: FunctionComponent<ContactListItemProps> = ({ id, type, name, email, mobile, selectedOptions,
  showEdit, showDelete, showAdd, hideExpand,
  onClickEdit, onClickDelete, onClickAdd, translations }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  let itemClass = 'contact-item';
  if (!hideExpand) {
    itemClass += ' cursor-pointer';
  }

  return (
    <li className="contact-list-item" key={id} title={type === 'contact' ? 'Kontakt' : 'Murphyanvändare'}>
      <div className={itemClass} onClick={!hideExpand ? onExpandClick : () => { }}>

        <span className="recipient-item-text">
          <strong>{name}</strong>
        </span>

        {type === 'contact'
          ? (
            <IconContext.Provider value={{ className: 'contact-item-icon' }}>
              <RiShieldUserFill />
            </IconContext.Provider>
          ) : null}

        {type === 'user'
          ? (
            <IconContext.Provider value={{ className: 'contact-item-icon' }}>
              <RiUserFill />
            </IconContext.Provider>
          ) : null}

        <div className="right-container">
          {showEdit === true
            ? (
              <IconContext.Provider value={{ className: 'contact-item-icon cursor-pointer' }}>
                <RiPencilFill onClick={(e) => { onClickEdit(id, type); e.stopPropagation(); }} />
              </IconContext.Provider>
            ) : null}
          {showAdd === true
            ? (
              <IconContext.Provider value={{ className: 'contact-item-icon cursor-pointer' }}>
                <RiAddCircleFill onClick={(e) => { onClickAdd(id, type); e.stopPropagation(); }} />
              </IconContext.Provider>
            ) : null}
          {showDelete === true
            ? (
              <IconContext.Provider value={{ className: 'contact-item-icon cursor-pointer' }}>
                <RiDeleteBin7Fill onClick={(e) => { onClickDelete(id, type); e.stopPropagation(); }} />
              </IconContext.Provider>
            ) : null}
          {hideExpand === true ? null
            : (
              <IconContext.Provider value={{ className: 'contact-item-icon cursor-pointer' }}>
                {isExpanded ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
              </IconContext.Provider>
            )}
        </div>
      </div>

      {isExpanded ? (
        <>
          <div className="contact-item">
            <span className="recipient-item-text">
              <strong>
                {translations.email}
                :
                {' '}
              </strong>
              {' '}
              {email}
            </span>
          </div>
          <div className="contact-item">
            <span className="recipient-item-text">
              <strong>
                {translations.phonenumber}
                :
                {' '}
              </strong>
              {' '}
              {mobile}
            </span>
          </div>

          {selectedOptions ? selectedOptions.map((p) => (
            <div key={p.optionSetId} className="contact-item">
              <span className="recipient-item-text">
                <strong>
                  {p.optionSetName}
                  :
                  {' '}
                </strong>
                {' '}
                {p.value}
              </span>
            </div>
          )) : null}

        </>
      ) : null}

    </li>
  );
}

export default ContactListItem;
