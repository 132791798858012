import { AxiosResponse } from "axios";
import { IDashboardFile } from "./IFilesApi";


export interface DashboardEvent {
    Payload: any,
    EventType: number,
    Entity: string,
}

export interface DashboardCardFilter {
    fromDate?: Date
}

export interface Dashboard {
    adminlink?: string,
    Name: string,
    dashboardid?: string | undefined,
    Arkiverad?: boolean,
    DashboardTypeId?: number,
    CreatedDateTime?: Date,
    LastUpdateDateTime?: Date,
    StatusMeetingDateTime?: Date,
    StatusTypeId?: number,
    ID?: string;
    Status?: number,
}

export interface Template {
    TemplateId: string,
    TemplateName: string,
}

export interface CreateDashboardRequest {
    CustomerId: string | null | undefined,
    Name: string,
    TemplateId?: string | null | undefined,
    Dashboardtype: number,
}

export interface UpdateDashboardRequest {
    DashboardId: string | undefined,
    NewName?: string | undefined,
    ActivateBoard?: boolean | undefined,
    ArchiveBoard?: boolean | undefined,
    Dashboardtype?: number | undefined,
}

export interface DashboardGoal {
    ID: number,
    Title: string,
    message?: string,
    goalType: number,
    CreatedDateTime: Date,
    hl: number,
    archived: boolean,
    user: string,
    versionTo?: Date,
    length?: number,
    Status?: number,
}

export interface PostDashboardGoalRequest {
    Title: string,
    message?: string,
    goalType: number,
}

export interface DashboardBackGround {
    ID: number,
    WhatMessage?: string,
    WhenMessage?: string,
    HowMessage?: string,
    AffectedMessage?: string,
    ExtentMessage?: string,
    CreatedDateTime: Date,
    archived: boolean,
    user: string,
    versionTo?: Date,
    Status?: number,
}

export interface PostDashboardBackGroundRequest {
    WhatMessage?: string | null | undefined,
    WhenMessage?: string | null | undefined,
    HowMessage?: string | null | undefined,
    AffectedMessage?: string | null | undefined,
    ExtentMessage?: string | null | undefined,
}

export interface DashboardAssumption {
    ID: number,
    Best: string,
    Worst: string,
    Prob: string,
    CreatedDateTime: Date,
    archived: boolean,
    user: string,
    versionTo?: Date,
    Status?: number,
}

export interface PostDashboardAssumptionRequest {
    Best: string,
    Worst: string,
    Prob: string,
}

export interface DashboardAction {
    ID: number,
    Title: string,
    Message?: string,
    CreatedDateTime: Date,
    Status: number,
    ProvidedBy?: string,
    Owner?: string,
    isDecision: boolean,
    Important: boolean,
    child?: number,
    archived: boolean,
    AlternatedTime?: Date,
    user: string,
    isCommunication: boolean,
    LatestSmsNotificationSent: Date | null | undefined,
    LatestEmailNotificationSent: Date | null | undefined,
    LatestPushNotificationSent: Date | null | undefined,
}

export interface PostDashboardActionRequest {
    Title: string,
    Message?: string,
    Status?: number,
    ProvidedBy?: string,
    Owner?: string,
    isDecision: boolean,
    Important: boolean,
    AlternatedTime?: Date | null | undefined,
    ParentId?: number,
    archived?: boolean,
    isCommunication: boolean,
    LatestSmsNotificationSent: Date | null | undefined,
    LatestEmailNotificationSent: Date | null | undefined,
    LatestPushNotificationSent: Date | null | undefined,
}

export interface PatchDashboardActionRequest {
    Id: number,
    archived?: boolean,
    child?: number,
}

export interface DashboardStatus {
    ID: number,
    Title: string,
    Message?: string,
    CreatedDateTime: Date,
    Status: number,
    ProvidedBy?: string,
    Important: boolean,
    child?: number,
    archived: boolean,
    AlternatedTime?: Date,
    user: string,
    ParentDashboardId?: string,
    DashboardId?: string,
    DashboardName?: string,
    ChildCustomerId?: string,
    ChildCustomerName?: string,
}

export interface PostDashboardStatusRequest {
    Title: string,
    Message?: string,
    Status?: number,
    ProvidedBy?: string,
    Important?: boolean,
    AlternatedTime?: Date | null | undefined,
    ParentId?: number | string | undefined,
    archived?: boolean,
    ParentDashboardId?: string | null | undefined,
    DashboardId?: string,
    UpdatedFromDashboardId: string,
}

export interface PatchDashboardStatusRequest {
    UpdatedFromDashboardId: string,
    DashboardId: string,
    Id: number,
    archived?: boolean,
    child?: number,
}

export interface DashboardLog {
    ID: number,
    LogType: number,
    CreatedDateTimeUtc: Date,
    userid: string,
    user: string,
    DashboardId: number,
    Status?: number,
}

export interface PostDashboardLogRequest {
    LogType: number,
    userid: string,
}

export interface IDashboardInformation {
    ID: number,
    Title: string,
    Message?: string,
    CreatedDateTime: Date,
    Important: boolean,
    Status: number,
    ProvidedBy?: string
    Confirmed: boolean,
    child?: number,
    archived: boolean,
    AlternatedTime?: Date,
    user: string,
    Owner: string,
    isDecision: boolean,
}
export interface PostDashboardInformationRequest {
    Title: string,
    Message?: string,
    Confirmed: boolean,
    Status: number,
    ProvidedBy?: string,
    Owner: string,
    isDecision: boolean,
    Important: boolean,
    AlternatedTime?: Date | null,
    ParentId?: number,
    archived?: boolean
}

export interface PatchDashboardInformationRequest {
    Id: number,
    archived?: boolean,
    child?: number,
}

export interface DashboardParty {
    ID: number,
    PartyId: number,
    PartyGuid: string,
    PartyName: string,
    PartyInfoId: number,
    CreatedDateTimeUtc: Date,
    VersionToUtc: Date,
    archived: boolean,
    createdby: string,
    action: string,
    DashboardId: number,
}

export interface PostDashboardPartyRequest {
    PartyId?: number,
    PartyInfoId?: number,
    PartyName?: string,
    PartyGuid?: string,
    archived: boolean,
}

export interface DashboardPartyPeople {
    ID: number,
    PartyId: number,
    PersonGuid: string,
    Name: string,
    Email: string,
    PhoneNr: string,
    Title: string,
    CreatedDateTimeUtc: Date,
    VersionToUtc: Date,
    archived: boolean,
    createdby: string,
    action: string,
}

export interface PostDashboardPartyPeopleRequest {
    PartyPeopleId?: number,
    Name: string,
    Email: string,
    PhoneNr: string,
    Title: string,
    archived: boolean,
    PartyId: number,
}

export interface MutateDashboardFileRequest {
    Id?: string,
    FileName?: string | null | undefined,
    archived: boolean | null | undefined,
    file?: File | null | undefined,
}


export interface DashboardAlertUser {
    Id: string,
    Name?: string,
    Email?: boolean,
    Sms?: boolean,
    Push?: boolean
}

export interface PostDashboardAlertUsersRequest {
    CustomerUser?: string;
    email?: boolean;
    sms?: boolean;
    push?: boolean;

}



export interface IDashboardApi {
    getDashboardById: (dashboardId: string) => Promise<Dashboard>;
    getDashboards: (customerId: string) => Promise<Dashboard[]>;
    createDashboard: (createDashboardRequest: CreateDashboardRequest) => Promise<AxiosResponse<any, any>>;
    editDashboard: (dashboardId: string, updateDashboardRequest: UpdateDashboardRequest) => Promise<AxiosResponse<any, any>>;
    getTemplatesByCustomerId: (customerId: string) => Promise<Template[]>;
    getDashboardGoals: (dashboardId: string) => Promise<DashboardGoal[]>;
    postDashboardGoal: (dashboardId: string, postDashboardGoalRequest: PostDashboardGoalRequest) => Promise<AxiosResponse<any, any>>;
    getDashboardBackgrounds: (dashboardId: string) => Promise<DashboardBackGround[]>;
    postDashboardBackGround: (dashboardId: string, postDashboardBackGroundRequest: PostDashboardBackGroundRequest) => Promise<AxiosResponse<any, any>>;
    getDashboardAssumptions: (dashboardId: string) => Promise<DashboardAssumption[]>;
    postDashboardAssumption: (dashboardId: string, postDashboardAssumptionRequest: PostDashboardAssumptionRequest) => Promise<AxiosResponse<any, any>>;
    getDashboardActions: (dashboardId: string) => Promise<DashboardAction[]>;
    postDashboardAction: (dashboardId: string, postDashboardActionCardRequest: PostDashboardActionRequest) => Promise<AxiosResponse<any, any>>;
    patchDashboardAction: (dashboardId: string, patchDashboardActionCardRequest: PatchDashboardActionRequest) => Promise<AxiosResponse<any, any>>;
    getDashboardStatuses: (dashboardId: string) => Promise<DashboardStatus[]>;
    postDashboardStatus: (dashboardId: string, postDashboardStatusRequest: PostDashboardStatusRequest) => Promise<AxiosResponse<any, any>>;
    patchDashboardStatus: (dashboardId: string, patchDashboardStatusRequest: PatchDashboardStatusRequest) => Promise<AxiosResponse<any, any>>;
    getDashboardLogItems: (dashboardId: string) => Promise<DashboardLog[]>;
    postDashboardLogItem: (dashboardId: string, postDashboardLogRequest: PostDashboardLogRequest) => Promise<AxiosResponse<any, any>>;
    getDashboardFiles: (dashboardId: string) => Promise<IDashboardFile[]>;
    getDashboardInformation: (dashboardId: string) => Promise<IDashboardInformation[]>;
    postDashboardInformation: (dashboardId: string, postDashboardInformationRequest: PostDashboardInformationRequest) => Promise<AxiosResponse<any, any>>;
    patchDashboardInformation: (dashboardId: string, patchDashboardInformationRequest: PatchDashboardInformationRequest) => Promise<AxiosResponse<any, any>>;
    getDashboardParty: (dashboardId: string) => Promise<DashboardParty[]>;
    postDashboardParty: (dashboardId: string, postDashboardPartyRequest: PostDashboardPartyRequest) => Promise<AxiosResponse<any, any>>;
    getDashboardPartyPeople: (dashboardId: string) => Promise<DashboardPartyPeople[]>;
    postDashboardPartyPeople: (dashboardId: string, postDashboardPartyPeopleRequest: PostDashboardPartyPeopleRequest) => Promise<AxiosResponse<any, any>>;
    mutateDashboardFile: (dashboardId: string, mutateDashboardFileRequest: MutateDashboardFileRequest) => Promise<AxiosResponse<any, any>>;
    getDashboardAlertUsers: (dashboardId: string) => Promise<DashboardAlertUser[]>;
    postDashboardAlertUsers: (dashboardId: string, postDashboardAlertUsersRequest: PostDashboardAlertUsersRequest) => Promise<AxiosResponse<any, any>>;

}

const DashboardApiType = {
    IDashboardApi: Symbol("IDashboardApi"),
};

export default DashboardApiType;