import { useQuery } from '@tanstack/react-query';
import { useInjection } from '../../contexts/InversifyContext';
import ContactGroupApiType, { IContactGroupApi } from '../../interfaces/IContactGroupApi';
import queryKeys from './QueryKeys';

export function useGetContactGroups(customerId: string | null | undefined) {
  const api = useInjection<IContactGroupApi>(ContactGroupApiType.IContactGroupApi);

  return useQuery({
    queryKey: queryKeys.list(customerId),
    queryFn: () => api.getContactGroupsByCustId(customerId, true, true),
    enabled: !!customerId, staleTime: 60 * 60 * 1000
  });
}

export function useGetContactGroupUsers(contactGroupId: number) {
  const api = useInjection<IContactGroupApi>(ContactGroupApiType.IContactGroupApi);

  return useQuery({
    queryKey: queryKeys.users(contactGroupId),
    queryFn: () => api.getUsersByContactGrpId(contactGroupId),
    enabled: !!contactGroupId, staleTime: 60 * 60 * 1000
  });
}

export function useGetContactGroupContacts(contactGroupId: number) {
  const api = useInjection<IContactGroupApi>(ContactGroupApiType.IContactGroupApi);

  return useQuery({
    queryKey: queryKeys.contacts(contactGroupId),
    queryFn: () => api.getContactsByContactGrpId(contactGroupId),
    enabled: !!contactGroupId, staleTime: 60 * 60 * 1000
  });
}

export function useGetContractGroup(contactGroupId: number) {
  const api = useInjection<IContactGroupApi>(ContactGroupApiType.IContactGroupApi);
  return useQuery({
    queryKey: queryKeys.detail(contactGroupId),
    queryFn: () => api.getContactGrpById(contactGroupId, true, true),
    enabled: !!contactGroupId, staleTime: 60 * 60 * 1000
  });
}
