import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';
import { PartyPeople, UpsertPartyPeople } from '../../api/PartyPeopleApi';
import { useUpsertPartyPeople } from '../../mutations';
import PartyPeopleForm, { PartyPeopleFormDto } from './PartyPeopleForm';

interface UpsertPartyPeopleProps {
    dashboardId: string,
    partyPeople?: PartyPeople,
    partyId: number,
    onSuccess: () => void,
    translations: Record<string, string>,
}

const UpsertPartyPeopleComponent: FunctionComponent<UpsertPartyPeopleProps> = ({ partyId, dashboardId, partyPeople, onSuccess, translations }) => {

    const { mutate: upsertPartyPeopleMutation, isPending } = useUpsertPartyPeople(dashboardId);
    const [partyPeopleFormData, setPartyPeopleFormData] = useState<PartyPeopleFormDto>(null);

    const onSubmitHandler = (partyPeopleFormDto: PartyPeopleFormDto) => {

        const upsertPartyPeople: UpsertPartyPeople = {
            PartyPeopleId: partyPeople?.ID,
            Name: partyPeopleFormDto.Name,
            Email: partyPeopleFormDto.Email,
            PhoneNr: partyPeopleFormDto.PhoneNr,
            Title: partyPeopleFormDto.Title,
            archived: partyPeopleFormDto.archived,
            PartyId: partyId,
        }

        upsertPartyPeopleMutation(upsertPartyPeople, {
            onSuccess: () => {
                onSuccess();
            }
        });
    };

    const onClickArchivePartyPeople = () => {
        const upsertPartyPeople: UpsertPartyPeople = {
            PartyPeopleId: partyPeople.ID,
            Name: partyPeople.Name,
            Email: partyPeople.Email,
            PhoneNr: partyPeople.PhoneNr,
            Title: partyPeople.Title,
            archived: true,
            PartyId: partyId,
        }

        upsertPartyPeopleMutation(upsertPartyPeople, {
            onSuccess: () => {
                onSuccess();
            }
        });
    }

    useEffect(() => {
        if (partyPeople) {
            const partyFormDto: PartyPeopleFormDto = {
                Name: partyPeople.Name,
                Email: partyPeople.Email,
                PhoneNr: partyPeople.PhoneNr,
                Title: partyPeople.Title,
                archived: partyPeople.archived,
            }
            setPartyPeopleFormData(partyFormDto);
        }
    }, [partyPeople])

    return (
        <div>
            <PartyPeopleForm
                translations={translations}
                onSubmit={onSubmitHandler}
                onClickArchive={onClickArchivePartyPeople}
                partyPeople={partyPeopleFormData}
                isLoading={isPending}
            />
        </div>
    );
}

export default UpsertPartyPeopleComponent;