import { FunctionComponent, useRef, useState } from 'react';
import {
  FaBars,
} from 'react-icons/fa';
import {
  RiBroadcastFill, RiHome2Line, RiTimeLine, RiBookOpenLine, RiUserSettingsLine, RiArrowDownSLine,
} from 'react-icons/ri';
import {
  GiBullseye,
} from 'react-icons/gi';
import { BsExclamationCircle } from 'react-icons/bs';

import {
  IoLanguage,
} from 'react-icons/io5';

import {
  HiOutlineCog,
} from 'react-icons/hi';

import { BiSolidPyramid } from "react-icons/bi";

// import MyMurphyLogo from "../assets/img/Logo_MyMurphy.svg";
import MyMurphyLogo from "@murphy-frontend/web-core/assets/img/mymurphy_logo.svg";
import CheckMarkIcon from "@murphy-frontend/web-core/assets/icons/CheckMarkIcon.svg";

import { Link, useNavigate } from 'react-router-dom';
import { IconContext } from 'react-icons/lib';
import NavDropdownMenu from '@murphy-frontend/web-core/components/NavDropdownMenu';
import { languages, timeZones } from '@murphy-frontend/web-core/constants';
import OptionSet from '@murphy-frontend/web-core/components/OptionSet';
import UserProfileMenuCard from '@murphy-frontend/web-core/components/UserProfileMenuCard';
import useDetectOutsideClick from '../common/hooks/useDetectOutsideClick';
import { translateConstants } from '../common/services/translationService';
import { Roles } from '../../../common/enums';
import { useUserPreferences } from '../common/contexts/UserPreferencesContext';
import React from 'react';
import { enabledFeatures, enabledProducts } from '../common/variables';
import { useCustomer } from '../common/contexts/CustomerContext';
import { useUser } from '../common/contexts/UserContext';
import { openInNewTab } from "../../../common/utils"
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import FeatureServiceType, { IFeatureService } from '@murphy-frontend/common/interfaces/IFeatureService';

const Navbar: FunctionComponent = () => {
  const dropdownRef = useRef(null);
  const { customer, setSelectedCustomerId, userCustomers } = useCustomer();
  const { user, setUserLanguage } = useUser();

  const {
    timeZone, language, setTimeZone, translations, setLanguage,
  } = useUserPreferences();

  const [click, setClick] = useDetectOutsideClick(dropdownRef, false);
  const [clickAdmin, setClickAdmin] = useDetectOutsideClick(dropdownRef, false);
  const [clickUserProfile, setClickUserProfile] = useState(false);

  const featureService = useInjection<IFeatureService>(
    FeatureServiceType.IFeatureService,
  );

  const handleClick = () => {
    setClickAdmin(false);
    setClickUserProfile(false);
    setClick(!click);
  };
  const handleClickAdmin = () => {
    setClick(false);
    setClickUserProfile(false);
    setClickAdmin(!clickAdmin);

  };
  const handleClickUserProfile = () => {
    setClick(false);
    setClickAdmin(false);
    setClickUserProfile(!clickUserProfile);

  };
  const closeMobileMenu = () => {
    setClick(false);
    setClickAdmin(false);
    setClickUserProfile(false);

  };

  const onChangeTimeZone = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    setTimeZone(event.target.value);
  };

  const onChangeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    setUserLanguage(event.target.value);
    // setLanguage(event.target.value);
  };

  const onSelectedCustomerChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newCustomerId = event.target.value;
    setSelectedCustomerId(newCustomerId);
  };

  const onClickSupportMenuItem = (event: React.MouseEvent<HTMLLIElement>) => {
    event.preventDefault();
    if (language === "swe") {
      openInNewTab("https://murphy-solution.helpjuice.com/sv_SE");
    }
    else if (language === "nor") {
      openInNewTab("https://murphy-solution.helpjuice.com/no_NO");
    }
    else if (language === "eng") {
      openInNewTab("https://murphy-solution.helpjuice.com/en_US");
    }
    else if (language === "fin") {
      openInNewTab("https://murphy-solution.helpjuice.com/en_US");
    }
  }

  const menuIconClass = 'menu-icon';
  let menuReactIcon = 'react-icons';
  const menuIconClass2 = 'menu-icon2';
  let menuReactIcon2 = 'react-icons';
  const menuIconClass3 = 'menu-icon3';
  if (click) {
    menuReactIcon += ' menu-icon-selected';
  }
  if (clickAdmin) {
    menuReactIcon2 += ' menu-icon-selected';
  }

  const settingsItems = [];
  const hamburgerItems = [];

  const HomeMenuItem = (
    <div className="nav-bar-menu-item-container">
      <div className="nav-bar-menu-icon-container">
        <IconContext.Provider value={{ className: 'nav-bar-menu-icon' }}>
          <RiHome2Line />
        </IconContext.Provider>
      </div>

      <div className="nav-bar-menu-item-text">{translations.home}</div>
    </div>
  );
  const startItem = (
    <li key="start" className="nav-item default-module-color">
      <Link
        to="/portal"
        className="nav-links light-text"
        onClick={closeMobileMenu}
      >
        {HomeMenuItem}
      </Link>
    </li>
  );
  hamburgerItems.push(startItem);

  const languagePicker = (
    <li key="languagePicker" className="nav-item">
      <div className="navbar-optionset-container">
        <IconContext.Provider value={{ className: 'navbar-optionset-icon' }}>
          <IoLanguage />
        </IconContext.Provider>
        <div className="navbar-optionset">
          <OptionSet
            direction="column"
            key={1}
            optionSetId={1}
            options={languages}
            selectedOption={language}
            handleChange={onChangeLanguage}
          />
        </div>
      </div>
    </li>
  );
  settingsItems.push(languagePicker);

  if (user && customer) {
    if (user?.mbsEnabled && enabledProducts.mbs === 'true') {
      const MBSMenuItem = (
        <div className="nav-bar-menu-item-container">
          <div className="nav-bar-menu-icon-container">
            <IconContext.Provider value={{ className: 'nav-bar-menu-icon' }}>
              <RiBroadcastFill />
            </IconContext.Provider>
          </div>
          <span className="nav-bar-menu-item-text">{translations.mbsmodulename}</span>
          <div className="nav-bar-menu-icon-container">
            <IconContext.Provider value={{ className: 'nav-bar-menu-icon' }}>
              <RiArrowDownSLine />
            </IconContext.Provider>
          </div>
        </div>
      );

      const mbsLinks = [{ url: '/pbscreatenew', displayName: translations.newmbs },
      { url: '/pbslist', displayName: translations.sentmbs },
      ];

      if (customer.ExternalContactsEnabled === true) {
        mbsLinks.push({ url: '/contacts', displayName: translations.contacts });
        mbsLinks.push({ url: '/contactgroups', displayName: translations.contactgroups });
      }

      const mbsMenuItem = (
        <li key="pbsmenu" className="nav-item mbs-module-color">
          <NavDropdownMenu
            onClickItem={closeMobileMenu}
            title={MBSMenuItem}
            items={mbsLinks}
            customClass="light-text"
          />
        </li>
      );
      hamburgerItems.push(mbsMenuItem);
    }

    if (enabledProducts.exercise === 'true') {
      const ExcersiseMenuItem = (
        <div className="nav-bar-menu-item-container">
          <div className="nav-bar-menu-icon-container">
            <IconContext.Provider value={{ className: 'nav-bar-menu-icon' }}>
              <GiBullseye />
            </IconContext.Provider>
          </div>
          <span className="nav-bar-menu-item-text">{translations.excercisesmodulename}</span>
        </div>
      );

      const excersiseItem = (
        <li key="excersises" className="nav-item excercises-module-color">
          <Link
            to="/excersises"
            className="nav-links light-text"
            onClick={closeMobileMenu}
          >
            {ExcersiseMenuItem}
          </Link>
        </li>
      );
      if (user.excersisesEnabled === true)
        hamburgerItems.push(excersiseItem);
    }

    if (user.traningEnabled === true && enabledProducts.training === 'true') {
      const CourseMenuItem = (
        <div className="nav-bar-menu-item-container">
          <div className="nav-bar-menu-icon-container">
            <IconContext.Provider value={{ className: 'nav-bar-menu-icon' }}>
              <RiBookOpenLine />
            </IconContext.Provider>
          </div>
          <span className="nav-bar-menu-item-text">{translations.coursesmodulename}</span>
        </div>
      );

      const courseItem = (
        <li key="courses" className="nav-item courses-module-color">
          <Link
            to="/courses"
            className="nav-links light-text"
            onClick={closeMobileMenu}
          >
            {CourseMenuItem}
          </Link>
        </li>
      );
      hamburgerItems.push(courseItem);
    }

    if (user.whiteEnabled === true && enabledProducts.white === 'true') {
      const CrisisMenuItem = (
        <div className="nav-bar-menu-item-container">
          <div className="nav-bar-menu-icon-container">
            <IconContext.Provider value={{ className: 'nav-bar-menu-icon' }}>
              <BsExclamationCircle />
            </IconContext.Provider>
          </div>
          <span className="nav-bar-menu-item-text">{translations.whitemodulename}</span>
        </div>
      );

      const crisisMenuItem = (
        <li key="crisis" className="nav-item white-module-color">
          <Link
            onClick={closeMobileMenu}
            className="nav-links light-text"
            to="/crisis"
          >
            {CrisisMenuItem}
          </Link>
        </li>
      );
      hamburgerItems.push(crisisMenuItem);
    }

    if (user.myMurphyEnabled === true) {
      const MyMurphyMenuItem = (
        <div className="nav-bar-menu-item-container">
          <div className="nav-bar-menu-icon-container">
            <IconContext.Provider value={{ className: 'nav-bar-menu-icon' }}>
              <BiSolidPyramid />
            </IconContext.Provider>
          </div>
          <span className="nav-bar-menu-item-text">{translations.pyramidmodulename}</span>
        </div>
      );

      const myMurphyMenuItem = (
        <li key="murphystandard" className="nav-item pyramid-module-color">
          <Link
            onClick={closeMobileMenu}
            className="nav-links light-text"
            to="/murphystandard"
          >
            {MyMurphyMenuItem}
          </Link>
        </li>
      );
      hamburgerItems.push(myMurphyMenuItem);
    }

    if (user.eventFlowsEnabled === true) {
      const EventFlowsMenuItem = (
        <div className="nav-bar-menu-item-container">
          <div className="nav-bar-menu-icon-container">
            <div className="nav-bar-menu-icon">
              <CheckMarkIcon />
            </div>
          </div>
          <span className="nav-bar-menu-item-text">{translations.eventflowsmodulename}</span>
        </div>
      );

      const eventFlowLinks = [
        { url: '/startedeventflows', displayName: translations.startedeventflows },
      ];

      const eventFlowsMenuItem = (
        <li key="eventflowsmenu" className="nav-item eventflow-module-color">
          <NavDropdownMenu
            onClickItem={closeMobileMenu}
            title={EventFlowsMenuItem}
            items={eventFlowLinks}
            customClass="light-text"
          />
        </li>
      );
      hamburgerItems.push(eventFlowsMenuItem);
    }

    const timeZonePicker = (
      <li key="timeZonePicker" className="nav-item">
        <div className="navbar-optionset-container">
          <IconContext.Provider value={{ className: 'navbar-optionset-icon' }}>
            <RiTimeLine />
          </IconContext.Provider>
          <div className="navbar-optionset">

            <OptionSet
              direction="column"
              key={1}
              optionSetId={1}
              options={translateConstants(language, timeZones)}
              selectedOption={timeZone}
              handleChange={onChangeTimeZone}
            />
          </div>
        </div>
      </li>
    );
    settingsItems.push(timeZonePicker);

    if (user.Role === Roles.Admin || user.IsSuperAdmin === true) {
      const internalAdminLinks = [];

      const newAdminUsersPage = { url: '/users', displayName: translations.useradmin };
      internalAdminLinks.push(newAdminUsersPage);

      const newAdminEducationPage = { url: '/education', displayName: translations.educationAdmin };
      internalAdminLinks.push(newAdminEducationPage);

      if (featureService.createUserIsEnabled() === true) {
        const newUserPage = { url: '/usernew', displayName: `+ ${translations.newuser}` };
        internalAdminLinks.push(newUserPage);
      }

      const AdminMenuItem = (
        <div className="nav-bar-menu-item-container">
          <div className="nav-bar-menu-icon-container">
            <IconContext.Provider value={{ className: 'nav-bar-menu-icon' }}>
              <RiUserSettingsLine />
            </IconContext.Provider>
          </div>
          <span className="nav-bar-menu-item-text">Admin</span>
          <div className="nav-bar-menu-icon-container">
            <IconContext.Provider value={{ className: 'nav-bar-menu-icon' }}>
              <RiArrowDownSLine />
            </IconContext.Provider>
          </div>
        </div>
      );

      const adminMenuItem = (
        <li key="adminmenu" className="nav-item">
          <NavDropdownMenu
            onClickItem={closeMobileMenu}
            title={AdminMenuItem}
            items={internalAdminLinks}
            externalLinks={undefined}
            customClass={undefined} />
        </li>
      );
      settingsItems.push(adminMenuItem);
    }

    const supportItem = (
      <li onClick={onClickSupportMenuItem} key="supportlink" className="nav-item">
        <div className="nav-bar-menu-item-container nav-links">
          <span className="nav-bar-menu-item-text">Knowledge base</span>
        </div>
      </li>
    )

    settingsItems.push(supportItem)
  }

  return (
    <nav className="navbar">
      <div ref={dropdownRef} className="navbar-container">
        <div className="navbar-links">
        </div>
        <Link
          to="/portal"
          className="navbar-logo"
          onClick={closeMobileMenu}
        >
          <MyMurphyLogo
            alt="logo"
            className="navbar-safe-logo"
          />
        </Link>
        {user && customer
          && (
            <>
              <div className={menuIconClass3}>
                <UserProfileMenuCard
                  userName={user.Username}
                  customerName={customer.Name}
                  setIsClicked={handleClickUserProfile}
                  isClicked={clickUserProfile}
                  allLicences={userCustomers}
                  currentCustomerId={customer.Id}
                  onLicenseChange={onSelectedCustomerChange}
                  translations={translations}
                />
              </div>

              <div className={menuIconClass} role="button" tabIndex={0} onClick={handleClick} onKeyUp={handleClick}>
                <IconContext.Provider value={{ className: menuReactIcon }}>
                  <FaBars />
                </IconContext.Provider>
              </div>

            </>
          )}
        {settingsItems.length > 0 ? (
          <div className={menuIconClass2} role="button" tabIndex={0} onClick={handleClickAdmin} onKeyUp={handleClickAdmin}>
            <IconContext.Provider value={{ className: menuReactIcon2 }}>
              <HiOutlineCog />
            </IconContext.Provider>
          </div>
        )
          : null}
        {
          <ul className={click || clickAdmin ? 'nav-menu active' : 'nav-menu'}>
            {user && customer && click ? hamburgerItems.map((p) => p) : null}
            {clickAdmin ? settingsItems.map((p) => p) : null}
          </ul>
        }
      </div>
    </nav>
  );
}

export default Navbar;
