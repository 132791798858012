import { IApiService } from "../../interfaces/IApiService";
import { CreateCustomerFileRequest, CustomerFileResponse, DeleteCustomerFileRequest, UpdateCustomerFileRequest } from "./models";
import { AxiosResponse } from "axios";

export class CustomerFileApi {
    constructor(
        private apiService: IApiService
    ) { }

    getCustomerFiles = async (customerId: string): Promise<CustomerFileResponse[]> => {
        const url = `/api/Customer/${customerId}/File`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    getHistoricFiles = async (customerId: string, fileTypeId: number): Promise<CustomerFileResponse[]> => {
        const url = `/api/Customer/${customerId}/File/History/${fileTypeId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    createCustomerFile = (createCustomerFileRequest: CreateCustomerFileRequest): Promise<AxiosResponse<any, any>> => {
        const url = `/api/Customer/${createCustomerFileRequest.CustomerId}/File`;
        const formData = new FormData();
        formData.append('File', createCustomerFileRequest.File);
        const { File, ...formDataWithoutFile } = createCustomerFileRequest;
        formData.append('FileJsonData', JSON.stringify(formDataWithoutFile));
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        return this.apiService.callApi(url, 'POST', createCustomerFileRequest, headers);
    }

    updateCustomerFile = (updateCustomerFileRequest: UpdateCustomerFileRequest): Promise<AxiosResponse<any, any>> => {
        const url = `/api/Customer/${updateCustomerFileRequest.CustomerId}/File/${updateCustomerFileRequest.CustomerFileId}`;
        const formData = new FormData();
        if (updateCustomerFileRequest.FileHasChanged) {
            formData.append('File', updateCustomerFileRequest.File);
        }
        const { File, ...formDataWithoutFile } = updateCustomerFileRequest;
        formData.append('FileJsonData', JSON.stringify(formDataWithoutFile));
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        return this.apiService.callApi(url, 'PUT', updateCustomerFileRequest, headers);
    }

    deleteCustomerFile = (deleteCustomerFileRequest: DeleteCustomerFileRequest): Promise<AxiosResponse<any, any>> => {
        const url = `/api/Customer/${deleteCustomerFileRequest.CustomerId}/File/${deleteCustomerFileRequest.CustomerFileId}`;
        return this.apiService.callApi(url, 'DELETE');
    }

    download = async (customerId: string, id: number) => {
        const url = `/api/Customer/${customerId}/File/${id}/Download`;
        const responseType = 'blob';
        const { data } = await this.apiService.callApi(url, 'GET', null, null, responseType);
        return new Blob([data]);
    }

    downloadHistoricFile = async (customerId: string, fileHandleId: number) => {
        const url = `/api/Customer/${customerId}/File/History/${fileHandleId}/Download`;
        const responseType = 'blob';
        const { data } = await this.apiService.callApi(url, 'GET', null, null, responseType);
        return new Blob([data]);
    }
}