import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useInjection } from '../../contexts/InversifyContext';
import PreferencesApiType, {
	IPreferencesApi,
} from '../../interfaces/IPreferencesApi';
import QueryKeys from './QueryKeys';

export const postDeviceToken = () => {
	const queryClient = useQueryClient();
	const preferencesApi = useInjection<IPreferencesApi>(PreferencesApiType.IPreferencesApi);

	return useMutation({
		mutationFn: (deviceToken: string) => preferencesApi.postDeviceToken(deviceToken),
		onError: (err) => {
			console.log(err);
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: QueryKeys.all });
		},
	});
};

export const postLanguageId = () => {
	const queryClient = useQueryClient();
	const preferencesApi = useInjection<IPreferencesApi>(PreferencesApiType.IPreferencesApi);

	return useMutation({
		mutationFn: (languageId: number) => preferencesApi.postLanguageId(languageId),
		onError: (err) => {
			console.log(err);
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: QueryKeys.all });
		},
	});
};
