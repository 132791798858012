import "reflect-metadata";
import React from 'react';
import ReactDOM from 'react-dom/client';
import '@murphy-frontend/web-core/styles/main.scss';
import { QueryClient, QueryClientProvider, QueryCache } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastContainer, toast } from 'react-toastify';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import App from './App';
import { reactPlugin } from './common/logging/appInsights';
import { InversifyProvider } from '@murphy-frontend/common/contexts/InversifyContext';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-tooltip/dist/react-tooltip.css';
import container from '../inversify.config';
import { logToApi } from "./common/variables";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.state.data !== undefined) {
        toast.error(`Something went wrong: ${error.message}`);
      }
    },
  }),
});

const useAppInsights = logToApi !== 'true';

const WrappedApp = () => {
  if (useAppInsights) {
    return (
      <AppInsightsContext.Provider value={reactPlugin}>
        <App />
      </AppInsightsContext.Provider>
    );
  }
  return <App />;
};

ReactDOM.createRoot(document.getElementById('app') as HTMLElement).render(
  <React.StrictMode>
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
    />
    <InversifyProvider container={container}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <WrappedApp />
      </QueryClientProvider>
    </InversifyProvider>
  </React.StrictMode>
);
