const QueryKeys = {
  all: ["events"],
  startedDroneEvents: (customerId: string) => [...QueryKeys.all, "startedDroneEvents", customerId],
  possibleDroneEvents: () => [...QueryKeys.all, "possibleDroneEvents"],
  droneEvent: (id) => [...QueryKeys.all, "droneEvent", id],
  saveFieldInDroneEvent: (droneId) => [
    ...QueryKeys.all,
    "saveFieldInDroneEvent",
    droneId,
  ],
  completeDroneEvent: (customerId) => [
    ...QueryKeys.all,
    "completeDroneEvent",
    customerId,
  ],
};

export default QueryKeys;
