import React, { useRef } from "react";
import CheckedIcon from "@murphy-frontend/web-core/assets/icons/checked.svg";
import VerifiedIcon from "@murphy-frontend/web-core/assets/icons/verified.svg";
import useTruncatedText from "@murphy-frontend/web-core/hooks/useTruncatedText";
import { useMeasure } from "@uidotdev/usehooks";

interface RequirementPyramidBlockProps {
    level?: number;
    isPressed: boolean;
    text: string;
    onClick: () => void,
    showProgressBar: boolean;
    progress: number;
    isComplete: boolean;
    isVerified?: boolean;
    showCheckbox?: boolean;
    onClickCheckbox?: () => void;
    fontSize?: 'small' | 'medium';
    isLoading?: boolean;
    id?: number;
    isBlock?: boolean;
    isFlashing?: boolean;
}

const RequirementPyramidBlock: React.FC<RequirementPyramidBlockProps> = ({
    level,
    isPressed,
    onClick,
    text,
    showProgressBar,
    progress,
    isComplete,
    isVerified,
    showCheckbox,
    onClickCheckbox,
    fontSize,
    isLoading,
    id,
    isBlock,
    isFlashing
}) => {
    // Function to get the class name for the level
    const getLevelClass = (level: number): string => {
        switch (level) {
            case 1: return "level-1";
            case 2: return "level-2";
            case 3: return "level-3";
            case 4: return "level-4";
            default: return "block-width";
        }
    };

    const textRef = useRef<HTMLElement>(null);
    const [measureRef, { height }] = useMeasure<HTMLDivElement>();
    const truncatedText = useTruncatedText(textRef, text, height ?? 134);

    const additionalClass = isPressed && isComplete ? 'pressed-filled' : isPressed ? 'pressed' : isComplete ? 'filled' : '';
    let classes = `pyramid-block ${getLevelClass(level)} ${additionalClass}`;
    // classes += isFlashing ? ' zoom-in-zoom-out-animation' : '';
    classes += id ? ` block-${id}` : '';
    classes += isBlock ? ' block-block' : '';

    const progressBar = (
        <div className="progress-bar-container">
            <div className="progress-bar">
                <div className="progress" style={{ width: `${progress}%` }}>
                    {progress > 30 ? (
                        // If progress is more than 30%, text inside the progress bar
                        <span className="progress-text">{progress}%</span>
                    ) : (
                        // If progress is 30% or less, text outside the progress bar
                        <span className="progress-text" style={{ color: 'black', position: 'absolute', right: '-50px' }}>
                            {progress}%
                        </span>
                    )}
                </div>
                {progress <= 30 && (
                    // Placeholder to keep space for text when it's outside the progress bar
                    <span className="progress-text" style={{ visibility: 'hidden' }}>
                        {progress}%
                    </span>
                )}
            </div>
        </div>
    );

    const shouldShowIcon = isComplete || isVerified || showCheckbox || isLoading;

    let topRightIcon = null;

    if (isLoading === true) {
        topRightIcon = <div className="top-right top-right-icon-checkbox-container"><div className="small-spinner" /></div>;
    }
    else if (isVerified && isComplete) {
        topRightIcon = <div className="top-right top-right-icon-verified-container" onClick={onClickCheckbox}><VerifiedIcon /></div>;
    } else if (!isVerified && isComplete) {
        topRightIcon = <div className="top-right top-right-icon" onClick={onClickCheckbox}><CheckedIcon /></div>;
    } else if (showCheckbox) {
        topRightIcon = <div className="top-right top-right-icon-checkbox-container"><div className="checkbox" onClick={onClickCheckbox} /></div>;
    }

    const levelTitleClass = `level-title ${fontSize || ''}`;

    // the existence of this inner div is because of a bug in the onboarding flow where the block could not be clicked when the animation was set on the div with the ref
    // apparently, adding an inner div and setting the animation on that div fixed the issue
    const innerDivBaseClasses = `pyramid-block ${getLevelClass(level)}`;
    const innerDivClasses = `${innerDivBaseClasses} ${isFlashing ? ' zoom-in-zoom-out-animation size100' : ''}`;

    return (

        <div className={classes} onClick={onClick} ref={measureRef}>
            <div className={isFlashing ? innerDivClasses : ''}>
                {shouldShowIcon && topRightIcon}
                <div ref={textRef} className={levelTitleClass}>
                    {truncatedText}
                </div>
                {!isComplete && showProgressBar && progressBar}
            </div>
        </div>

    );
}

export default RequirementPyramidBlock;
