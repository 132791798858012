import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';

interface DashboardNavbarProps {

}

const DashboardNavbar: FunctionComponent<DashboardNavbarProps> = ({ children }) => {
    return (
        <nav className="dashboard-nav-bar">
            {children}
        </nav>
    );
}

export default DashboardNavbar;