import { ActionsCard } from "../../../api/ActionsCardApi";
import { Assumption } from "../../../api/AssumptionApi";
import { BackGround } from "../../../api/BackGroundApi";
import { DashboardFile } from "../../../api/DashboardFileApi";
import { DashboardLog } from "../../../api/DashboardLogApi";
import { Goals } from "../../../api/GoalsApi";
import { InformationCard } from "../../../api/InformationCardApi";
import { Party } from "../../../api/PartyApi";
import { PartyPeople } from "../../../api/PartyPeopleApi";
import { StatusCard } from "../../../api/StatusCardApi";
import { StatusCardStatusService } from "../../StatusCards/StatusCardStatusService";
import { ActionsCardMainLogCard, ActionsMainLogCardProps } from "./ActionsCardMainLogCard";
import { AssumptionMainLogCard, AssumptionMainLogCardProps } from "./AssumptionMainLogCard";
import { BackGroundMainLogCard, BackGroundMainLogCardProps } from "./BackGroundMainLogCard";
import { DashboardFileMainLogCard, DashboardFileMainLogCardProps } from "./DashboardFileMainLogCard";
import { DashboardLogMainLogCard, DashboardLogMainLogCardProps } from "./DashboardLogMainLogCard";
import { GoalMainLogCard, GoalMainLogCardProps } from "./GoalMainLogCard";
import { InformationCardMainLogCard, InformationCardMainLogCardProps } from "./InformationCardMainLogCard";
import { PartyMainLogCard, PartyMainLogCardProps } from "./PartyMainLogCard";
import { PartyPeopleMainLogCard, PartyPeopleMainLogCardProps } from "./PartyPeopleMainLogCard";
import { StatusCardMainLogCard, StatusCardMainLogCardProps } from "./StatusCardMainLogCard";

export interface BaseCardProps {
    translations: Record<string, string>,
    timeZone: any,
    dashboardId: string,
    openModal: () => void,
    closeModal: () => void,
}

export class MainLogCardFactory {
    translations: Record<string, string>;
    openModal: () => void;
    closeModal: () => void;
    timeZone: any;
    dashboardId: string;
    statusCardStatusService: StatusCardStatusService;

    constructor(translations: Record<string, string>, statusCardStatusService: StatusCardStatusService, openModal: () => void, closeModal: () => void, timeZone: any, dashboardId: string) {
        this.translations = translations;
        this.openModal = openModal;
        this.closeModal = closeModal;
        this.timeZone = timeZone;
        this.dashboardId = dashboardId;
        this.statusCardStatusService = statusCardStatusService;
    }

    createBaseProps = () => {
        const props: BaseCardProps = {
            translations: this.translations,
            timeZone: this.timeZone,
            dashboardId: this.dashboardId,
            openModal: this.openModal,
            closeModal: this.closeModal
        }
        return props;
    }

    createActionCard = (card: ActionsCard) => {
        const baseProps = this.createBaseProps();
        const props: ActionsMainLogCardProps =
        {
            ...baseProps,
            actionLogCard: card
        }
        return ActionsCardMainLogCard(props);
    }

    createInformationCard = (card: InformationCard) => {
        const baseProps = this.createBaseProps();
        const props: InformationCardMainLogCardProps =
        {
            ...baseProps,
            informationCard: card,
        }
        return InformationCardMainLogCard(props);
    }

    createStatusCard = (card: StatusCard) => {
        const baseProps = this.createBaseProps();
        const props: StatusCardMainLogCardProps =
        {
            ...baseProps,
            statusCard: card,
            statusCardStatusService: this.statusCardStatusService,
        }
        return StatusCardMainLogCard(props);
    }

    createAssumptionCard = (card: Assumption) => {
        const baseProps = this.createBaseProps();
        const props: AssumptionMainLogCardProps =
        {
            ...baseProps,
            assumption: card,
        }
        return AssumptionMainLogCard(props);
    }

    createBackGroundCard = (card: BackGround) => {
        const baseProps = this.createBaseProps();
        const props: BackGroundMainLogCardProps =
        {
            ...baseProps,
            backGround: card,
        }
        return BackGroundMainLogCard(props);
    }

    createDashboardFileCard = (card: DashboardFile, eventType: 'added' | 'removed' | 'modified') => {
        const baseProps = this.createBaseProps();
        const props: DashboardFileMainLogCardProps =
        {
            ...baseProps,
            file: card,
            eventType: eventType
        }
        return DashboardFileMainLogCard(props);
    }

    createGoalCard = (card: Goals) => {
        const baseProps = this.createBaseProps();
        const props: GoalMainLogCardProps =
        {
            ...baseProps,
            goal: card,
        }
        return GoalMainLogCard(props);
    }

    createPartyCard = (card: Party) => {
        const baseProps = this.createBaseProps();
        const props: PartyMainLogCardProps =
        {
            ...baseProps,
            party: card,
        }
        return PartyMainLogCard(props);
    }

    createPartyPeopleCard = (card: PartyPeople) => {
        const baseProps = this.createBaseProps();
        const props: PartyPeopleMainLogCardProps =
        {
            ...baseProps,
            partyPeople: card,
        }
        return PartyPeopleMainLogCard(props);
    }

    createDashboardLogCard = (card: DashboardLog) => {
        const baseProps = this.createBaseProps();
        const props: DashboardLogMainLogCardProps =
        {
            ...baseProps,
            dashboardLog: card,
        }
        return DashboardLogMainLogCard(props);
    }
}
