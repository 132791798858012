import * as React from 'react';
import Header from './Header';
import Row from './Row';

type DashboardTableProps = {
    rows: any[],
    header: any,
    subHeader?: any,
}

const DashboardTable: React.FC<DashboardTableProps> = ({ rows, subHeader, header }) => {

    return <div className='dashboard-table'>
        <Header>
            {header}
        </Header>
        <div className='dashboard-table-rows-container'>
            {subHeader}

            {
                rows.map((row) => {
                    return <Row>
                        {row}
                    </Row>
                })
            }
        </div>
    </div>
}

export default DashboardTable;