import { useQuery } from '@tanstack/react-query';
import { FileApi } from './FileApi';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import queryKeys from './queryKeys';

const useApiService = () => useInjection<IApiService>(ApiServiceType.IApiService);

export function useGetCustomerFiles(customerId: string) {
  const apiService = useApiService();
  const fileApi = new FileApi(apiService);

  const getFilesByCustomerCall = async (customerId: string) => {
    const data = await fileApi.getFilesByCustomer(customerId);
    return { data }.data.data;
  };

  return useQuery({
    queryKey: queryKeys.customerList(customerId),
    queryFn: () => getFilesByCustomerCall(customerId),
    enabled: !!customerId, staleTime: 60 * 60 * 1000
  });
}
