import { FunctionComponent } from "react";
import * as React from 'react'
import { ActionsCard } from "../../api/ActionsCardApi";
import ExpandableRow from "@murphy-frontend/web-core/components/DashboardTable/ExpandableRow";
import ActionsCardExpandedContent from "./ActionsCardExpandedContent";
import ActionStatusSelector from "./ActionsStatusSelector";
import { TimeZoneType } from "@murphy-frontend/common/services/TimeService"

interface ActionsCardsTableRowProps {
    actionsCard: ActionsCard,
    updateStatus: (id: number, newStatus: number) => void,
    translations: Record<string, string>;
    timeZone: TimeZoneType;
    dashboardId: string;
    openModal: any,
    closeModal: any,
}

const ActionsCardsTableRow: FunctionComponent<ActionsCardsTableRowProps> =
    ({ actionsCard, dashboardId, updateStatus, translations, openModal, closeModal, timeZone }) => {

        const onClickUpdate = (newStatus: number, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            event.preventDefault();
            updateStatus(actionsCard.ID, newStatus);
        }

        const actionsStatusSelector = <ActionStatusSelector
            onClickStatus={onClickUpdate}
            translations={translations}
            currentStatus={actionsCard.Status}
            pullRightOnSmallScreen={true}
        />

        const header = <>
            <div className='left-icon'>
                {actionsStatusSelector}
            </div>
            <div className="dashboard-table-row-text">
                {actionsCard.Title}
            </div>
        </>

        const expandableContent =
            <ActionsCardExpandedContent
                actionsCard={actionsCard}
                dashboardId={dashboardId}
                openModal={openModal}
                closeModal={closeModal}
                translations={translations}
                timeZone={timeZone} />;

        return <ExpandableRow headerContent={header} expandedContent={expandableContent} />
    }

export default ActionsCardsTableRow;