import * as React from 'react';
import { FunctionComponent } from 'react';
import { DashboardFile } from '../../api/DashboardFileApi';
interface DashboardFileFormattedProps {
    dashboardFile: DashboardFile;
    user: string,
    translations: Record<string, string>
    cardFooter?: any
}

const DashboardFileFormatted: FunctionComponent<DashboardFileFormattedProps> = ({ dashboardFile, translations, user, cardFooter }) => {
    return (
        <div className="formatted-card">
            <p>
                <strong>{translations['class-lang-by-user']}</strong>&nbsp;{user}
            </p>
            {dashboardFile &&
                <>
                    <br />
                    {dashboardFile.FileName}
                    <br />
                </>}
            {cardFooter &&
                <>
                    <br />
                    <hr></hr>
                    <br />
                    {cardFooter}
                </>
            }
        </div >
    );
}

export default DashboardFileFormatted;