import { useQuery } from '@tanstack/react-query';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import UsersTrainingSessionsApiType, { IUserTrainingSessionsApi } from './IUserTrainingSessionsApi';

export function useGetUserTrainingSessionsByCustomerUserId(customerUserId: string | undefined) {
    const api = useInjection<IUserTrainingSessionsApi>(UsersTrainingSessionsApiType.IUsersTrainingSessionsApi);

    return useQuery({
        queryKey: queryKeys.customerList(customerUserId),
        queryFn: () => api.getUserTrainingSessionsByCustomerUserId(customerUserId),
        enabled: !!customerUserId, staleTime: Infinity
    });
}