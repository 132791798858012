import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import FilterControl, { FilterType } from '@murphy-frontend/web-core/components/FilterControl';
import DashboardSearch from "@murphy-frontend/web-core/components/DashboardSearch";
import MBSSearchRecipientsTable from "./MBSSearchRecipientsTable";
import { MBSRecipient } from "./models";

interface MBSSearchRecipientsProps {
    translations: Record<string, string>,
    recipients: MBSRecipient[]
    addRecipient?: (recipient: MBSRecipient) => void,
    clickRecipient?: (recipient: MBSRecipient) => void,
}

const MBSSearchRecipients: FunctionComponent<MBSSearchRecipientsProps> = (
    {
        translations,
        recipients,
        addRecipient,
        clickRecipient,
    }
) => {
    const [currentSearchText, setCurrentSearchText] = useState<string>('');

    const [filters, setFilters] = useState<FilterType[]>([]);
    const [markAllIsToggled, setMarkAllIsToggled] = useState<boolean>(true);
    const [filteredRecipients, setFilteredRecipients] = useState<MBSRecipient[]>();

    const onToggleMarkAllFilters = () => {
        const newAllCardTypes = filters.map(p => {
            return { ...p, isActive: !markAllIsToggled }
        })

        setFilters(newAllCardTypes);
        setMarkAllIsToggled(!markAllIsToggled);
    }

    const onToggleFilter = (filt: FilterType) => {
        const updatedFilters = filters.map((filter) => {
            const updatedItem = { ...filter };
            if (filter.columnname === filt.columnname && filter.filtervalue === filt.filtervalue) {
                updatedItem.isActive = !filter.isActive;
            }
            return updatedItem;
        });

        if (updatedFilters.every(p => p.isActive)) {
            setMarkAllIsToggled(true);
        } else {
            setMarkAllIsToggled(false);
        }

        setFilters(updatedFilters);
    };

    const onResetFilters = () => {
        const initFilters = generateRecipientTypeFilters();
        setFilters(initFilters);
    }

    const generateRecipientTypeFilters = () => {
        const allPossibleFilters = [
            {
                displayName: translations['lang-users'],
                columnname: 'type',
                filtervalue: 'internaluser',
                isActive: true,
            },
            {
                displayName: translations.contacts,
                columnname: 'type',
                filtervalue: 'internalcontact',
                isActive: true,
            },
            {
                displayName: translations.contactgroups,
                columnname: 'type',
                filtervalue: 'internalgroup',
                isActive: true,
            },
        ];
        return allPossibleFilters;
    };

    useEffect(() => {
        if (translations) {
            const initFilters = generateRecipientTypeFilters();
            setFilters(initFilters);
        }
    }, [translations])

    useEffect(() => {

        let newFilteredRecipients = [...recipients];
        if (currentSearchText) {
            const searchValue = currentSearchText.toUpperCase();
            newFilteredRecipients = newFilteredRecipients
                .filter((p) =>
                    p.name.toUpperCase().includes(searchValue)
                );
        }
        if (filters) {
            let cardsWithActiveFilters: MBSRecipient[] = [];
            for (let i = 0; i < filters.length; i++) {
                const currentFilter = filters[i];
                if (currentFilter.isActive === true) {
                    const currentFilterMatchingRecipients = newFilteredRecipients.filter((recipient: any) => {
                        if (recipient[currentFilter.columnname] === currentFilter.filtervalue) {
                            return recipient;
                        }
                    });
                    cardsWithActiveFilters = cardsWithActiveFilters.concat(currentFilterMatchingRecipients);
                }
            }
            newFilteredRecipients = cardsWithActiveFilters;
        }
        setFilteredRecipients(newFilteredRecipients);

    }, [recipients, filters, currentSearchText]);

    return (
        <div className="mbs-search-recipients">
            <div>
                <FilterControl
                    allFilters={filters}
                    onToggleFilter={onToggleFilter}
                    showReset={false}
                    onClickResetFilters={onResetFilters}
                    translations={translations}
                    showMarkAll={true}
                    onToggleMarkAll={onToggleMarkAllFilters}
                    markAllIsToggled={markAllIsToggled}
                />
            </div>
            <div>
                <DashboardSearch
                    value={currentSearchText}
                    setValue={setCurrentSearchText}
                    placeholder={translations['placeholder-lang-search-action']}
                    onClickClear={() => setCurrentSearchText('')} />
            </div>
            <div>
                <MBSSearchRecipientsTable
                    recipients={filteredRecipients}
                    translations={translations}
                    addRecipient={addRecipient}
                    clickRecipient={clickRecipient}
                />
            </div>
        </div>
    );
}

export default MBSSearchRecipients;