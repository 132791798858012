import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useInjection } from "../../contexts/InversifyContext";
import QueryKeys from "./QueryKeys";
import DroneEventsApiType, {
  IDroneEventsApi,
} from "../../interfaces/IDroneEventsApi";
import { toast } from "react-toastify";
import { Drone } from "./types";

export function useStartedDroneEvents(customerId: string | undefined, includeClosed?: boolean) {
  const eventsApi = useInjection<IDroneEventsApi>(
    DroneEventsApiType.IDroneEventsApi
  );
  return useQuery({
    queryKey: QueryKeys.startedDroneEvents(customerId),
    queryFn: () => eventsApi.getStartedEvents(customerId, includeClosed),
    enabled: !!customerId,
  });
}

export function useDroneEvents(customerId: string | undefined) {
  const eventsApi = useInjection<IDroneEventsApi>(
    DroneEventsApiType.IDroneEventsApi
  );
  return useQuery({
    queryKey: QueryKeys.possibleDroneEvents(),
    queryFn: () => eventsApi.getEvents(customerId),
    enabled: !!customerId,
  });
}

export function useDroneEvent(eventId: number | null) {
  const eventsApi = useInjection<IDroneEventsApi>(
    DroneEventsApiType.IDroneEventsApi
  );
  return useQuery({
    queryKey: QueryKeys.droneEvent(eventId),
    queryFn: () => eventsApi.getEvent(eventId),
    enabled: !!eventId,
  });
}

export function useSaveEventField(droneId: Drone["Id"]) {
  const eventsApi = useInjection<IDroneEventsApi>(
    DroneEventsApiType.IDroneEventsApi
  );
  const queryClient = useQueryClient();

  return useMutation({
    onError: (e) => toast.error(e.message),
    mutationKey: QueryKeys.saveFieldInDroneEvent(droneId),
    mutationFn: async (props: {
      droneId: number;
      fieldId: number;
      updateValue: boolean | string;
    }) => {
      const res = await eventsApi.saveFieldInEvent(props);
      queryClient.invalidateQueries();
      return res;
    },
  });
}