import React, {useState, FunctionComponent} from "react"
import Button from "@murphy-frontend/web-core/components/Button"
import {DeleteTemplateRequest} from "../../../common/api/Dashboards/DashboardsApi"
import {useDeleteTemplate} from "../../../common/api/Dashboards/mutations"

interface DeleteTemplateProps {
	translations: Record<string, string>
	customerId: string
	templateId: string
	closeModal: () => void
}

const DeleteTemplate: FunctionComponent<DeleteTemplateProps> = ({translations, closeModal, customerId, templateId}) => {
	const [isLoading, setIsLoading] = useState(false)

	const {mutate: deleteTemplate} = useDeleteTemplate(customerId, translations)

	const handleDelete = () => {
		setIsLoading(true)
		const deleteTemplateRequest: DeleteTemplateRequest = {
			customerId,
			templateId
		}

		deleteTemplate(deleteTemplateRequest, {
			onSuccess: response => {
				setIsLoading(false)
				closeModal()
			},
			onError: () => {
				setIsLoading(false)
				closeModal()
			}
		})
	}

	return (
		<div>
			<p>{translations.deletetemplateheader}</p>
			<div className='modal-footer margin-top-20'>
				<Button
					buttonStyle='btn--primary'
					buttonSize='btn--medium'
					onClick={handleDelete}
					isLoading={isLoading}
				>
					{translations.yes}
				</Button>
			</div>
		</div>
	)
}

export default DeleteTemplate
