import { View, Text, } from "@react-pdf/renderer"
import * as React from "react"
import { GoalTypes } from "@murphy-frontend/common/enums";
import { convertBreaksToNewLine } from "@murphy-frontend/common/utils";
import { Goals } from "../../../api/GoalsApi"

export const GoalsPdfRow = (translations: Record<string, string>, styles: any, goals: Goals) => {
    const title = goals.goalType === GoalTypes.MainGoal ? translations['class-lang-title-infocard-target'] : translations['class-lang-title-infocard-com-target'];
    return <Text>
        <Text>
            <Text style={styles.label}>
                {title}:&nbsp;
            </Text>
            <Text>
                {goals.message ? convertBreaksToNewLine(goals.message) : ''}
            </Text>
        </Text>
    </Text>
}

