import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import RequirementPyramidContent from "./RequirementPyramidContent";
import RequirementCategoryButtons from "./RequirementCategoryButtons";
import RequirementPyramidActionButtons from "./RequirementStandardFilterButtons";
import MyCompanyRequirementPyramid from "./MyCompanyRequirementPyramid";
import LevelContent from "./LevelContent";
import { useGetCustomerBlocks, useGetRequirementLevels } from "../api/queries";
import { useCustomer } from "../../../common/contexts/CustomerContext";
import { useUpdateCustomerBlockStatuses, useUpdateCustomerGoalLevel } from "../api/mutations";
import { RequirementCategoryDto, UpdateBlockStatusesRequest } from "../api/models";
import Spinner from "@murphy-frontend/web-core/components/Spinner";
import StandardContent from "./StandardContent";
import { useInjection } from "@murphy-frontend/common/contexts/InversifyContext";
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { openInNewTab } from "@murphy-frontend/common/utils";
import { OnlineEducationApi } from "../../OnlineEducation/api/OnlineEducationApi";
import { parseHtml } from "@murphy-frontend/web-core/features/RichText/helpers";
import RichTextDisplay from "@murphy-frontend/web-core/features/RichText/RichTextDisplay";
import { OnboardingFlowIds, useOnboarding } from "../../../common/contexts/OnboardingContext";
import RequirementPyramidGridOnboardingSteps from "./RequirementPyramidGridOnboardingSteps";
import { useModal } from "@murphy-frontend/web-core/contexts/ModalContext";
import { ModalConfiguration } from "@murphy-frontend/web-core/components/Modal";
import InfoIcon from "@murphy-frontend/web-core/components/InfoIcon";
import Button from "@murphy-frontend/web-core/components/Button";

interface RequirementPyramidGridProps {
    requirementCategories: RequirementCategoryDto[];
    translations: Record<string, string>;
}

const RequirementPyramidGrid: FunctionComponent<RequirementPyramidGridProps> = (
    {
        requirementCategories,
        translations
    }
) => {
    const [activeCategoryId, setActiveCategoryId] = useState<number | null>(null);
    const [activeRowLevel, setActiveRowLevel] = useState<number | null>(null);
    const [isEditingLevel, setIsEditingLevel] = React.useState<boolean>(false);

    const [currentPyramidContent, setCurrentPyramidContent] = useState<JSX.Element | null>(null);
    const [currentClickedBlockId, setCurrentClickedBlockId] = useState<number | null>(null);

    const { customer } = useCustomer();
    const { openModal, closeModal } = useModal();

    const { data: customerBlocks, isLoading: customerBlocksIsLoading } = useGetCustomerBlocks(customer.Id);
    const { data: requirementLevels, isLoading: requirmentLevelesIsLoading } = useGetRequirementLevels(customer.Id);

    const { mutate: updateCustomerBlock, isPending: updateCustomerBlockIsLoading } = useUpdateCustomerBlockStatuses();
    const { mutate: updateCustomerGoalLevel, isPending: updateCustomerGoalLevelIsLoading } = useUpdateCustomerGoalLevel();

    const [editGoalIsFlashing, setEditGoalIsFlashing] = useState<boolean>(false);
    const [requirementCategoryIsFlashing, setRequirementCategoryIsFlashing] = useState<boolean>(false);
    const [requirementBlockIsFlashing, setRequirementBlockIsFlashing] = useState<boolean>(false);
    const [informationFilmLinkIsFlashing, setInformationFilmLinkIsFlashing] = useState<boolean>(false);
    const [requirementLevelIsFlashing, setRequirementLevelIsFlashing] = useState<boolean>(false);

    const [onboardingTerminated, setOnboardingTerminated] = useState<boolean>(null);
    const [stepsEnabled, setStepsEnabled] = useState<boolean>(false);
    const { setOnboardingIdIsComplete, incompletedOnboardingFlows, isLoading: onBoardingIsLoading, setCurrentStep, onboardingsCurrentStep, resetOnboarding } = useOnboarding();

    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);

    const handleBlockClicked = (requirementBlockId: number): void => {
        setRequirementBlockIsFlashing(false);
        if (currentClickedBlockId === requirementBlockId) {
            setCurrentClickedBlockId(null);
            return;
        }

        setCurrentClickedBlockId(requirementBlockId);
    };

    const handleRowClick = (level: number): void => {
        // if same button is clicked again, unselect it and set content to null
        if (activeRowLevel === level) {
            setActiveRowLevel(null);
            return;
        }
        setRequirementLevelIsFlashing(false);
        setActiveRowLevel(level); // Set the active button
    };

    // handle pyramid content here
    useEffect(() => {
        const onClickLink = async (url: string) => {
            setInformationFilmLinkIsFlashing(false);
            const onlineEducationApi = new OnlineEducationApi(apiService);
            const tokenUrl = await onlineEducationApi.getTokenUrl(url);
            openInNewTab(tokenUrl);
        }
        const contentHeightWihoutEditingLevelExpanded = 317;
        const contentHeightWithEditingLevelExpanded = 246;
        const totalHeightWithoutEditingLevelExpanded = 436;
        const totalHeightWithEditingLevelExpanded = 365;
        const contentHeightWithNoLevel = 463;
        const totalHeightWithNoLevel = 582;

        if (currentClickedBlockId !== null) {
            const matchingBlock = customerBlocks?.find(p => p.Id === currentClickedBlockId);
            const parsedHtmlDescription = matchingBlock.HtmlDescription ? parseHtml(matchingBlock.HtmlDescription) : "";
            const linkClasses = `pyramid-button button button-link ${informationFilmLinkIsFlashing ? 'zoom-in-zoom-out-animation' : ''}`;

            const content = <StandardContent
                title={matchingBlock.Name}
                content={<RichTextDisplay content={parsedHtmlDescription} />}
                contentHeight={contentHeightWithNoLevel}
                totalHeight={totalHeightWithNoLevel}
                enableScroll={true}
                bottomElement={matchingBlock.Link && <div onClick={() => onClickLink(matchingBlock.Link)} className={linkClasses}>
                    <span> {matchingBlock.LessonIsCompleted ? translations.informationfilmhasbeenseen : translations.informationfilm}</span>
                </div>}
            />

            setCurrentPyramidContent(content);
            return;
        }

        if (activeRowLevel !== null) {
            const matchingLevel = requirementLevels.find(p => p.Level === activeRowLevel);
            const parsedHtmlDescription = matchingLevel.Description ? parseHtml(matchingLevel.Description) : "";

            const content = <StandardContent
                title={matchingLevel.Name}
                contentHeight={isEditingLevel ? contentHeightWithEditingLevelExpanded : contentHeightWihoutEditingLevelExpanded}
                totalHeight={isEditingLevel ? totalHeightWithEditingLevelExpanded : totalHeightWithoutEditingLevelExpanded}
                enableScroll={true}
                content={<RichTextDisplay content={parsedHtmlDescription} />}
            />
            setCurrentPyramidContent(content);
            return;
        }

        if (activeCategoryId !== null && activeRowLevel === null && currentClickedBlockId === null) {
            const matchingCategory = requirementCategories.find(p => p.Id === activeCategoryId);
            const parsedHtmlDescription = matchingCategory.Description ? parseHtml(matchingCategory.Description) : "";

            const content = <StandardContent
                title={matchingCategory.Name}
                contentHeight={contentHeightWithNoLevel}
                totalHeight={totalHeightWithNoLevel}
                enableScroll={true}
                content={<RichTextDisplay content={parsedHtmlDescription} />}
                bottomElement={matchingCategory.Link && <div onClick={() => onClickLink(matchingCategory.Link)} className="pyramid-button button button-link">
                    <span> {matchingCategory.LessonIsCompleted ? translations.informationfilmhasbeenseen : translations.informationfilm}</span>
                </div>}
            />
            setCurrentPyramidContent(content);
            return;
        }

        if (activeRowLevel === null && currentClickedBlockId === null) {
            setCurrentPyramidContent(null);
        }

    }, [activeCategoryId, currentClickedBlockId, activeRowLevel, isEditingLevel, informationFilmLinkIsFlashing]);

    const handleClickReset = (): void => {
        setCurrentClickedBlockId(null);
        setActiveRowLevel(null);
        setActiveCategoryId(null);
        setIsEditingLevel(false);
    }

    const handleSetActiveCategoryId = (categoryId: number): void => {
        setRequirementCategoryIsFlashing(false);
        setCurrentClickedBlockId(null);
        setActiveRowLevel(null);
        setIsEditingLevel(false);
        setActiveCategoryId(categoryId);
    }

    const handleUpdateCustomerBlock = (blockId: number): void => {

        const existingBlock = customerBlocks?.find(p => p.Id === blockId);
        const submitUpdateBlock = () => {
            const request: UpdateBlockStatusesRequest = {
                CustomerId: customer.Id,
                Reason: "Reason",
                RequirementBlockId: blockId,
                IsComplete: !existingBlock.IsCompleted,
            }
            updateCustomerBlock(request, {
                onSettled: () => {
                    if (stepsEnabled) {
                        const currentStep = onboardingsCurrentStep.get(OnboardingFlowIds.MurphyStandard);
                        if (currentStep === 8) {
                            setCurrentStep(OnboardingFlowIds.MurphyStandard, 9);
                            window.location.reload();
                        } else {
                            setCurrentStep(OnboardingFlowIds.MurphyStandard, 5);
                            window.location.reload();
                        }
                    }
                }
            });
        }
        submitUpdateBlock();
    }

    const handleChangeLevel = (level: number): void => {

        if (stepsEnabled) {
            setCurrentStep(OnboardingFlowIds.MurphyStandard, 5);
        }

        const request = {
            CustomerId: customer.Id,
            Level: level
        }
        updateCustomerGoalLevel(request, {
            onSuccess: () => {
                if (stepsEnabled) {
                    window.location.reload();
                }
            }
        });
    }

    const handleSetEditingLevel = (isEditingLevel: boolean): void => {
        setIsEditingLevel(isEditingLevel);
    }

    const getRemaingingBlocksToNextLevel = (highestCompleteLevel: number) => {
        const blocksForLevel = customerBlocks.filter(p => p.Level === highestCompleteLevel + 1);
        const remainingBlocks = blocksForLevel.filter(p => !p.IsCompleted);
        return remainingBlocks.length;
    }

    const getHighestCompleteLevel = () => {
        const isLevelComplete = (level: number): boolean => {
            const blocksForLevel = customerBlocks.filter(p => p.Level === level);
            return blocksForLevel.length > 0 && blocksForLevel.every(p => p.IsCompleted);
        }

        let highestCompleteLevel = 0;

        for (let level of requirementLevels.map(p => p.Level).sort((a, b) => a - b)) {
            if (isLevelComplete(level)) {
                highestCompleteLevel = level; // Update the highest complete level
            } else {
                break; // As soon as we find an incomplete level, we break out of the loop
            }
        }

        return highestCompleteLevel;
    }

    useEffect(() => {
        if (onboardingTerminated === true) {
            return;
        }
        const shouldShowOnboarding = incompletedOnboardingFlows.map(p => p.OnboardingFlowId).includes(OnboardingFlowIds.MurphyStandard);
        if (shouldShowOnboarding && !onBoardingIsLoading) {
            const currentStep = onboardingsCurrentStep.get(OnboardingFlowIds.MurphyStandard);
            if (currentStep === 0) {
                const stepsFromApi = incompletedOnboardingFlows.find(p => p.OnboardingFlowId === OnboardingFlowIds.MurphyStandard)?.StepsHtmlContents;
                const introText = stepsFromApi.get(1);
                const parsedHtmlIntro = introText ? parseHtml(introText) : "";
                const onClickOk = () => {
                    setCurrentStep(OnboardingFlowIds.MurphyStandard, 1);
                    closeModal();
                    setStepsEnabled(true);
                }

                const onClickClose = () => {
                    // setCurrentStep(OnboardingFlowIds.MurphyStandard, 0);
                    setStepsEnabled(false);
                }

                const modalConf: ModalConfiguration = {
                    okCallback: onClickOk,
                    onCloseModal: onClickClose,
                    type: 'info',
                    centerContent: true,
                    body: <div className="intro-outro-container">
                        <RichTextDisplay content={parsedHtmlIntro} />
                    </div>,
                    title: "Tutorial",
                    buttonText: "Start",
                    position: 'center'
                }
                openModal(modalConf);
            }
            else {
                setStepsEnabled(true);
            }
        }
    }, [incompletedOnboardingFlows, onBoardingIsLoading, onboardingsCurrentStep]);

    const handleOnboardingComplete = () => {
        const currentStep = onboardingsCurrentStep.get(OnboardingFlowIds.MurphyStandard);
        if (currentStep === 9) {
            const stepsFromApi = incompletedOnboardingFlows.find(p => p.OnboardingFlowId === OnboardingFlowIds.MurphyStandard)?.StepsHtmlContents;
            const introText = stepsFromApi.get(11);
            const parsedHtmlOutro = introText ? parseHtml(introText) : "";

            const onClickOk = () => {
                setOnboardingIsComplete();
            }

            const onClickClose = () => {
                setOnboardingIsComplete();
            }

            const modalConf: ModalConfiguration = {
                okCallback: onClickOk,
                onCloseModal: onClickClose,
                type: 'info',
                centerContent: true,
                body: <div className="intro-outro-container">
                    <RichTextDisplay content={parsedHtmlOutro} />
                </div>,
                title: "Congratulations!",
                buttonText: "Finish",
                position: 'center'
            }
            setStepsEnabled(false);
            openModal(modalConf);
        } else {
            setOnboardingIsComplete();
        }
        // TODO call onboarding complete
    };

    const setOnboardingIsComplete = () => {
        setOnboardingTerminated(true);
        setOnboardingIdIsComplete(OnboardingFlowIds.MurphyStandard);
        setCurrentStep(OnboardingFlowIds.MurphyStandard, 0);
        setStepsEnabled(false);
    }

    if (customerBlocksIsLoading || requirmentLevelesIsLoading || updateCustomerBlockIsLoading || updateCustomerGoalLevelIsLoading || onBoardingIsLoading) {
        return (<div className="spinner-container-global-center"><Spinner /></div>);
    }

    const highestCompleteLevel = getHighestCompleteLevel();
    const currentLevel = requirementLevels.find(p => p.IsGoalLevel);
    const getRemaingingBlocksToNextLevelCount = getRemaingingBlocksToNextLevel(highestCompleteLevel);

    const levelContentItem = <LevelContent
        isEditingLevel={isEditingLevel}
        setIsEditingLevel={handleSetEditingLevel}
        partsRemainingToNextLevel={getRemaingingBlocksToNextLevelCount}
        translations={translations}
        requirementLevels={requirementLevels}
        setCurrentLevel={handleChangeLevel}
        currentLevel={currentLevel}
        nextLevel={highestCompleteLevel + 1}
        editIconAdditionalClasses={editGoalIsFlashing ? 'zoom-in-zoom-out-animation' : ''}
    />;

    const stepsComponent = <RequirementPyramidGridOnboardingSteps
        setRequirementLevelIsFlashing={setRequirementLevelIsFlashing}
        setEditGoalIsFlashing={setEditGoalIsFlashing}
        setRequirementCategoryIsFlashing={setRequirementCategoryIsFlashing}
        setRequirementBlockIsFlashing={setRequirementBlockIsFlashing}
        setInformationFilmLinkIsFlashing={setInformationFilmLinkIsFlashing}
        activeRowLevel={activeRowLevel}
        currentClickedBlockId={currentClickedBlockId}
        activeCategoryId={activeCategoryId}
        currentPyramidContent={currentPyramidContent}
        stepsEnabled={stepsEnabled}
        onComplete={handleOnboardingComplete}
        setStepsEnabled={setStepsEnabled}
    />

    const onClickStartOnboarding = () => {
        setOnboardingTerminated(false);
        resetOnboarding(OnboardingFlowIds.MurphyStandard);
    }

    const stepsIcon = <InfoIcon
        id="info-onboarding-mbs"
        place={"right-end"}
        onHoverContents={<Button onClick={onClickStartOnboarding}>Start tutorial</Button>}
        clickable={true}
    />

    return (
        <div className="pbs-wrapper">
            <div className='h2-with-info-button'>
                <h2>The Murphy Standard</h2>
                {stepsIcon}
            </div>
            <div className="pyramid-grid-container">
                {stepsEnabled && stepsComponent}
                <div className="grid-item filtersitem">
                    <RequirementPyramidActionButtons
                        onClickReset={handleClickReset}
                        isResetPressed={activeCategoryId === null}
                    />
                </div>
                <div className="grid-item emptyitem">
                </div>
                <div className="grid-item pyramiditem">
                    <MyCompanyRequirementPyramid
                        activeCategoryId={activeCategoryId}
                        activeRowLevel={activeRowLevel}
                        handleBlockClicked={handleBlockClicked}
                        customerBlocks={customerBlocks}
                        requirementLevels={requirementLevels}
                        handleClickBlockCheckbox={handleUpdateCustomerBlock}
                        activeRequirementBlockId={currentClickedBlockId}
                        handleRowClick={handleRowClick}
                        blockIsFlashing={requirementBlockIsFlashing}
                        rowIsFlashing={requirementLevelIsFlashing}
                    />
                </div>
                <div className="grid-item contentitem">
                    {!activeCategoryId && levelContentItem}
                    {currentPyramidContent && <RequirementPyramidContent content={currentPyramidContent} />}
                </div>
                <div className="grid-item buttonsitem">
                    <RequirementCategoryButtons
                        requirementCategories={requirementCategories}
                        activeCategoryId={activeCategoryId}
                        setActiveCategoryId={handleSetActiveCategoryId}
                        requirementCategoryButtonIsFlashing={requirementCategoryIsFlashing}
                    />
                </div>

            </div>
        </div>
    );
}

export default RequirementPyramidGrid;