import { View, Text, } from "@react-pdf/renderer"
import * as React from "react"
import { DashboardFile } from "../../../api/DashboardFileApi"

export const DashboardFilePdfRow = (translations: Record<string, string>, styles: any, dashboardFile: DashboardFile) => {
    return <Text>
        {dashboardFile.FileName &&
            <Text>
                <Text style={styles.label}>
                    {translations.file}:&nbsp;
                </Text>
                <Text>
                    {dashboardFile.FileName}
                </Text>
            </Text>
        }
    </Text>
}