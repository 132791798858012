
import { Axios, AxiosResponse } from 'axios';


export interface UpdateFileRequest {
	Id: string | undefined;
	NewName: string | undefined;
}

export interface IUploadFileObj {
	name?: string | null | undefined;
	type?: string | null | undefined;
	uri: string | null | undefined;
}

export interface ILocalFileResMobile {
	fileName?: string | null;
	name?: string | null;
	type?: string | null;
	uri?: string | null;
}

export interface ICustomerFile {
    
        FilePath?: string,
        id?: string | undefined,
        Id: string | undefined,
        ID: string | undefined,
        customerId?: string,
        FileName: string | undefined,
        Type?: string,
        fileType?: number,
        CreatedDateTime?: string,
        removed?: boolean,
        RemovedDateTime?: string,
        addedBy?: string,
        removedBy?: string,
        modifiedBy?: string,
        ModifiedDateTime?: string
      
}

export interface IDashboardFile {
    
        ID: string | undefined,
        fileType?: number,
        FilePath?: string,
        CreatedDateTime?: string,
        RemovedDateTime?: string,
        DashboardId?: string,
        FileName: string | undefined,
        archived?: true,
        type?: string,
        addedBy?: string,
        removedBy?: string,
        ModifiedDateTime?: string,
        modifiedBy?: string,
        Status?: number,
      
}

export interface IFile {
    id?: string | undefined;
    Id?: string | undefined;  
    ID?: string | undefined;  
    name?: string | null | undefined;
    file?: string;
    created?: string | Date;
    isDownloaded?: boolean;
    path?: string;
    size?: string;
  }

export interface IFilesApi {
    getFilesByCustomer: (customerId: string) => Promise<ICustomerFile[]>,
    getFilesByDashboard: (dashboardId: string) => Promise<IDashboardFile[]>,
    getFileByFileId: (fileId: string) => Promise<AxiosResponse>,
    getDashboardFileByFileId: (dashboardId: string, fileId: string) => Promise<AxiosResponse>,
    postFileToCustomer: (fileToUpload: IUploadFileObj, customerId: string) => Promise<ICustomerFile[]>,
    postFileToDashboard: (fileToUpload: IUploadFileObj, dashboardId: string) => Promise<IDashboardFile>,
    updateFileName: (updateFileRequest: UpdateFileRequest) => Promise<AxiosResponse<any, any>>,
    deleteFile: (fileId: string) => Promise<AxiosResponse<any, any>>
}

const FilesApiType = {
    IFilesApi: Symbol("IFilesApi"), 
};

export default FilesApiType;
