import { useQuery } from '@tanstack/react-query';
import { useInjection } from '../../contexts/InversifyContext';
import UsersApiType, { IUsersApi } from '../../interfaces/IUsersApi';
import QueryKeys from './QueryKeys';

export function useGetUsers(customerId: string | null | undefined) {
  const usersApi = useInjection<IUsersApi>(UsersApiType.IUsersApi);
  return useQuery({
    queryKey: QueryKeys.list(customerId),

    queryFn: () => usersApi.getUsers({
      includeAdInfo: true,
      includeTrainingInfo: true,
      customerId,
    }),
    enabled: !!customerId, staleTime: 60 * 60 * 1000
  });
}

export function useGetAllUsers() {
  const usersApi = useInjection<IUsersApi>(UsersApiType.IUsersApi);
  return useQuery({
    queryKey: QueryKeys.all,

    queryFn: () => usersApi.getUsers({
      includeAdInfo: false,
      includeTrainingInfo: false,
    }),
    staleTime: 60 * 60 * 1000
  });
}

export function useGetUsersByObjectAndCustomerId({ customerId, objectId }) {
  const usersApi = useInjection<IUsersApi>(UsersApiType.IUsersApi);
  return useQuery({
    queryKey: QueryKeys.customerObject({ customerId, objectId }),

    queryFn: () => usersApi.getUsers({
      includeAdInfo: false,
      includeTrainingInfo: false,
      objectId,
      customerId,
    }),
    enabled: !!customerId && !!objectId, staleTime: 60 * 60 * 1000
  });
}

export function useGetUser(customerUserId: string) {
  const usersApi = useInjection<IUsersApi>(UsersApiType.IUsersApi);
  return useQuery({
    queryKey: QueryKeys.detail(customerUserId),
    queryFn: () => usersApi.getUser(customerUserId, true, true),
    enabled: !!customerUserId, staleTime: 60 * 60 * 1000
  });
}

export function useGetUserProvider(userName: string, isAllowed: boolean) {
  const usersApi = useInjection<IUsersApi>(UsersApiType.IUsersApi);
  return useQuery({
    queryKey: QueryKeys.detail(userName),
    queryFn: () => usersApi.getUserProvider(userName),
    enabled: !!userName && isAllowed, staleTime: 60 * 60 * 1000
  });
}

export function useGetUserOnBoardingFlows(enabled: boolean) {
  const usersApi = useInjection<IUsersApi>(UsersApiType.IUsersApi);
  return useQuery({
    queryKey: QueryKeys.onboardingFlows(),
    queryFn: () => usersApi.getUserOnboardingFlows(),
    staleTime: 60 * 60 * 1000, enabled
  });
}