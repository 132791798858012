import * as React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "@murphy-frontend/web-core/components/Button";
import Textarea from "@murphy-frontend/web-core/components/TextArea";
import DOMPurify from "dompurify";

yup.addMethod(yup.string, "html", function () {
  return this.test({
    name: "html",
    exclusive: true,
    message: "Invalid text",
    test: (value) => {
      DOMPurify.sanitize(value);
      if (DOMPurify.removed.length > 0) {
        return false;
      }
      return true;
    },
  });
});

const goalsSchema = yup.object().shape({
  WhatMessage: yup.string().nullable().html(),
  WhenMessage: yup.string().nullable().html(),
  HowMessage: yup.string().nullable().html(),
  AffectedMessage: yup.string().nullable().html(),
  ExtentMessage: yup.string().nullable().html(),
});

export interface BackGroundFormDto {
  WhatMessage?: string;
  WhenMessage?: string;
  HowMessage?: string;
  AffectedMessage?: string;
  ExtentMessage?: string;
}

interface BackGroundFormProps {
  backGround?: BackGroundFormDto;
  onSubmit: (data: BackGroundFormDto) => void;
  translations?: any;
  isLoading?: boolean;
}

const BackGroundForm = ({
  isLoading,
  backGround,
  onSubmit,
  translations,
}: BackGroundFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isDirty },
    reset,
    getValues,
  } = useForm<BackGroundFormDto>({
    resolver: yupResolver(goalsSchema),
    defaultValues: {
      WhatMessage: "",
      WhenMessage: "",
      HowMessage: "",
      AffectedMessage: "",
      ExtentMessage: "",
    },
  });

  useEffect(() => {
    if (backGround) {
      const defaults = {
        WhatMessage: backGround.WhatMessage,
        WhenMessage: backGround.WhenMessage,
        HowMessage: backGround.HowMessage,
        AffectedMessage: backGround.AffectedMessage,
        ExtentMessage: backGround.ExtentMessage,
      };
      reset(defaults);
    }
  }, [backGround, reset]);

  const onSubmitHandler = (data: BackGroundFormDto) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="dashboardform-container">
        <Textarea
          isControlled
          rows={8}
          register={register}
          name="WhatMessage"
          title={translations["class-what-has-happened"]}
          errorMessage={errors.WhatMessage?.message}
        />
        <Textarea
          isControlled
          rows={8}
          register={register}
          name="WhenMessage"
          title={translations["class-when-did-it-happen"]}
          errorMessage={errors.WhenMessage?.message}
        />
        <Textarea
          isControlled
          rows={8}
          register={register}
          name="HowMessage"
          title={translations["class-how-did-it-happen"]}
          errorMessage={errors.HowMessage?.message}
        />
        <Textarea
          isControlled
          rows={8}
          register={register}
          name="AffectedMessage"
          title={translations["class-affected"]}
          errorMessage={errors.AffectedMessage?.message}
        />
        <Textarea
          isControlled
          rows={8}
          register={register}
          name="ExtentMessage"
          title={translations["class-extent"]}
          errorMessage={errors.ExtentMessage?.message}
        />
        <div className="form-buttons">
          {isDirty && (
            <Button
              type="button"
              disabled={!isDirty || isLoading}
              onClick={() => reset()}
              buttonStyle="btn--undo"
              buttonSize="btn--medium"
              isNeutralButton={true}
            >
              {translations.undo}
            </Button>
          )}
          <Button
            isLoading={isLoading}
            disabled={!isDirty || (errors && errors.length > 0)}
            type="submit"
            buttonStyle="btn--primary"
            buttonSize="btn--medium"
          >
            {translations.save}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default BackGroundForm;
