import { FileTypes } from '@murphy-frontend/common/enums';
import { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { UpdateFileRequest } from '@murphy-frontend/common/interfaces/IFilesApi';

export class FileApi {
  constructor(private apiService: IApiService) { }

  deleteFile(id: string) {
    const url = `/api/File/${id}`;
    return this.apiService.callApi(url, 'DELETE', null);
  }

  updateFileName(editFileRequest: UpdateFileRequest) {
    const url = `/api/File/${editFileRequest.Id}`;
    return this.apiService.callApi(url, 'PUT', editFileRequest);
  }

  getFilesByCustomer(customerId: string) {
    const url = `api/File?customerId=${customerId}`;
    return this.apiService.callApi(url, 'GET', null);
  }
}
