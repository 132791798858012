import React, { memo, useState } from "react";
import RequirementCategoryButton from "./RequirementCategoryButton";
import PeopleIcon from "@murphy-frontend/web-core/assets/icons/group.svg";
import OrganisationIcon from "@murphy-frontend/web-core/assets/icons/organisation.svg";
import PlansIcon from "@murphy-frontend/web-core/assets/icons/plans.svg";
import ToolsIcon from "@murphy-frontend/web-core/assets/icons/tools.svg";
import CommunicationIcon from "@murphy-frontend/web-core/assets/icons/communication.svg";
import { Constant } from "@murphy-frontend/web-core/models/Constant";
import { RequirementCategoryDto } from "../api/models";

interface RequirementCategoryButtonsProps {
    // Define any props you might need here
    activeCategoryId: number;
    setActiveCategoryId: (categoryId: number) => void;
    requirementCategories: RequirementCategoryDto[];
    requirementCategoryButtonIsFlashing?: boolean;
}

const RequirementCategoryButtons: React.FC<RequirementCategoryButtonsProps> = memo((
    {
        activeCategoryId,
        setActiveCategoryId,
        requirementCategories,
        requirementCategoryButtonIsFlashing
    }
) => {
    const handleClick = (categoryId: number): void => {
        // if same button is clicked again, set the active button to null
        if (activeCategoryId === categoryId) {
            setActiveCategoryId(null);
            return;
        }
        setActiveCategoryId(categoryId); // Set the active button
    };

    const icon = (categoryId: number) => {
        switch (categoryId) {
            case 1:
                return <PeopleIcon />;
            case 2:
                return <OrganisationIcon />;
            case 3:
                return <PlansIcon />;
            case 4:
                return <ToolsIcon />;
            case 5:
                return <CommunicationIcon />;
            default:
                return null;
        }
    }

    const name = (categoryId: number) => {
        return requirementCategories.find(c => c.Id === categoryId)?.Name;
    }

    // Helper function to create a button
    const createButton = (categoryId: number, isFlashing: boolean) => {
        const buttonIcon = icon(categoryId);
        const buttonName = name(categoryId);

        return <RequirementCategoryButton
            key={categoryId}
            onClick={() => handleClick(categoryId)}
            buttonText={buttonName}
            isPressed={activeCategoryId === categoryId}
            icon={buttonIcon}
            isFlashing={isFlashing}
        />
    }

    return (
        <div className="buttons-container">
            {createButton(1, requirementCategoryButtonIsFlashing)}
            {createButton(2, false)}
            {createButton(3, false)}
            {createButton(4, false)}
            {createButton(5, false)}
        </div>
    );
});

export default RequirementCategoryButtons;


