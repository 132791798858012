import ReactPlayer from 'react-player';
import React from 'react';

function ResponsivePlayer({ url }) {
  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        url={url}
        width="100%"
        height="100%"
        controls
        config={{
          youtube: {
            playerVars: { showinfo: 1 },
          },
          facebook: {
            appId: '12345',
          },
          vimeo: {
            playerOptions: {
              muted: true,
            },
          },
        }}
      />
    </div>
  );
}

export default ResponsivePlayer;
