import * as React from 'react';
import { forwardRef, FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { IconContext } from 'react-icons/lib';
import { RiCheckboxBlankLine, RiCheckboxFill } from 'react-icons/ri';

interface MurphyCheckboxProps {
    isControlled: boolean,
    control: any,
    disabled?: boolean,
    name: string,
    errorMessage?: string,
    title?: string
}

interface CustomCheckboxProps {
    checked?: boolean,
    onChange: (checked: boolean) => void,

}

export const CustomCheckbox: FunctionComponent<CustomCheckboxProps> = forwardRef(({ checked, onChange, ...props }) => {
    const checkedIcon = <IconContext.Provider value={{ className: 'murphy-checkbox-status-icon' }}>
        <RiCheckboxFill />
    </IconContext.Provider>;

    const uncheckedIcon = <IconContext.Provider value={{ className: 'murphy-checkbox-status-icon' }}>
        <RiCheckboxBlankLine />
    </IconContext.Provider>;

    return (
        <label className='murphy-checkbox'>
            <input
                className='murphy-checkbox-input'
                checked={checked}
                {...props}
                type="checkbox"
                onChange={(e) => onChange(e.target.checked)}
            />
            {checked ? checkedIcon : uncheckedIcon}
        </label>
    );
})

const MurphyCheckbox: FunctionComponent<MurphyCheckboxProps> = ({ title, isControlled, control, disabled, name, errorMessage }) => {

    return (
        <>
            <Controller
                // as={CustomCheckbox}
                render={({ field }) =>
                    <CustomCheckbox
                        checked={field.value}
                        onChange={(e) => {
                            field.onChange(e);
                        }}
                        {...field} />}
                name={name}
                control={control}
            />
            {errorMessage ? <div className="input-error-message">{errorMessage}</div> : null}
        </>
    );
}

export default MurphyCheckbox;