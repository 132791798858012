import * as React from "react";
import { DashboardEntityTypes } from "@murphy-frontend/common/enums";
import { getConvertedDateDayjs } from '@murphy-frontend/common/services/TimeService';
import { Assumption } from "../../../api/AssumptionApi";
import AssumptionExpandedContent from "../../Assumption/ActionsCardExpandedContent";
import { MainLogCard } from "../models";
import { BaseCardProps } from "./MainLogCardFactory";

export interface AssumptionMainLogCardProps extends BaseCardProps {
    assumption: Assumption,
}

export const AssumptionMainLogCard =
    ({ translations, assumption, timeZone, dashboardId, openModal, closeModal }: AssumptionMainLogCardProps): MainLogCard => {
        const date = getConvertedDateDayjs(assumption.CreatedDateTime, timeZone);
        const expandedContent =
            <AssumptionExpandedContent
                assumption={assumption}
                dashboardId={dashboardId}
                openModal={openModal}
                closeModal={closeModal}
                translations={translations}
            />

        const title = translations['class-lang-title-infocard-prognosis'];

        const searchableContent = [];
        searchableContent.push(title);
        if (assumption.Best)
            searchableContent.push(assumption.Best);
        if (assumption.Worst)
            searchableContent.push(assumption.Worst);
        if (assumption.Prob)
            searchableContent.push(assumption.Prob);
        if (assumption.user)
            searchableContent.push(assumption.user);

        const logCard: MainLogCard = {
            ID: `${assumption.ID}-${DashboardEntityTypes.Assumption}`,
            Title: title,
            isArchived: assumption.archived,
            isDecision: false,
            SearchableContent: searchableContent,
            User: assumption.user,
            Date: date,
            DateWithoutTime: date.format("YYYY-MM-DD"),
            Time: date.format("HH:mm"),
            EntityType: DashboardEntityTypes.Assumption,
            ExpandedContent: expandedContent,
            OriginalCard: assumption,
        }
        return logCard;
    }