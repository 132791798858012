import React from "react";

interface RequirementStandardButtonProps {
    onClick: () => void;
    buttonText: string;
    icon: JSX.Element | null; // Allowing null if no icon is provided
    isPressed: boolean; // New prop to determine if the button is active
    isFlashing?: boolean; // New prop to determine if the button should be flashing
}

const RequirementCategoryButton: React.FC<RequirementStandardButtonProps> = ({
    onClick,
    buttonText,
    icon,
    isPressed,
    isFlashing
}) => {
    return (
        <div className={`button-frame ${isPressed ? 'pressed' : ''} ${isFlashing ? 'zoom-in-zoom-out-animation' : ''}`} onClick={onClick}>
            <div className="button-background"></div>
            {icon && <div className="button-icon">{icon}</div>}
            <div className="button-text">{buttonText}</div>
        </div>
    );
};

export default RequirementCategoryButton;
