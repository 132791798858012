import React, { FunctionComponent } from 'react';
import ExpandableContactGroup from '../../ContactGroups/components/ExpandableContactGroup';
import { uniqueItems } from '@murphy-frontend/common/utils';
import { MBSRecipient } from './models';

interface MBSConfirmationProps {
  messageTitle: string,
  messageBody: string,
  messageQuestion: string,
  questionRequired: boolean,
  recipients: MBSRecipient[],
  translations: Record<string, string>,
  criticalAlert?: boolean,
}

const MBSConfirmation: FunctionComponent<MBSConfirmationProps> = ({
  messageTitle,
  messageBody,
  messageQuestion,
  questionRequired,
  recipients,
  translations,
  criticalAlert
}) => {
  const allMobileRecipients = recipients.filter((p) => p.checked && p.mobile && p.mobileChecked);
  const allEmailRecipients = recipients.filter((p) => p.checked && p.email && p.emailChecked);
  const mobileRecipients = uniqueItems(allMobileRecipients, 'mobile');
  const emailRecipients = uniqueItems(allEmailRecipients, 'email');

  return (
    <>
      <p>
        {translations.thefollowingmessage}
        :
      </p>
      <br />
      <p>
        <b>
          {translations.title}
          :
        </b>
        <br />
        {messageTitle}
      </p>
      <br />
      <p>
        <b>
          {translations.message}
          :
        </b>
        <br />
        {messageBody}
      </p>
      <br />
      {questionRequired ? (
        <>
          <p>
            <b>
              {translations.question}
              :
            </b>
            <br />
            {messageQuestion}
          </p>
          <br />
        </>
      ) : null}
      {criticalAlert &&
        <> <p>
          <b>Critical Alert Enabled</b>
        </p>
          <br />
        </>
      }
      {
        mobileRecipients && mobileRecipients.length > 0
          ? (
            <>
              <p>
                <b>
                  {translations.tothefollowingrecipients}
                  {' '}
                  (SMS):
                </b>
              </p>
              {mobileRecipients.map((p) => (
                <div className="attribute-value" key={p.uniqueKey}>
                  {p.isGroup
                    ? (
                      <ExpandableContactGroup contactGroup={p} showMobile />
                    ) : <span>{p.mobile}</span>}
                  <br />
                </div>
              ))}
              <br />
            </>
          ) : null
      }
      {
        emailRecipients && emailRecipients.length > 0
          ? (
            <>
              <p>
                <b>
                  {translations.tothefollowingrecipients}
                  {' '}
                  (
                  {translations.email}
                  ):
                </b>
              </p>
              {emailRecipients.map((p) => (
                <div className="attribute-value" key={p.uniqueKey}>
                  {p.isGroup
                    ? (
                      <ExpandableContactGroup contactGroup={p} showEmail />
                    ) : <span>{p.email}</span>}
                  <br />
                </div>
              ))}
              <br />
            </>
          ) : null
      }

    </>
  );
}

export default MBSConfirmation;
