import React, {useState, useEffect} from "react";

export const FilterCourses = ({
  filterCourse,
  course,
  filterOnCourse,
}: {
  filterCourse: number[];
  course: any;
  filterOnCourse: (id: number) => void;
}) => {
  const [selected, setSelected] = useState<boolean>(false);

useEffect(() => {
  if(filterCourse?.includes(course?.id)) {
    setSelected(true)
  } else {
    setSelected(false);
  }
}, [filterCourse])
  return (
    <div onClick={() => filterOnCourse(course?.id)} className={`filter-control-box education-admin-courses-filter-box ${selected ? 'education-admin-courses-filter-box_selected' : ''}`}
    >
      <p>{`${course.id}. ${course.name}`}</p>
    </div>
  );
};
