
import * as React from 'react';
import { FunctionComponent } from 'react';
import ContactListItem from './ContactListItem';
import { OptionModel } from '@murphy-frontend/common/interfaces/IContactsApi';

export interface ContactListModel {
  ID: any,
  id: number,
  name: string,
  email?: string,
  mobile?: string,
  type?: string,
  deviceToken?: string,
  SelectedOptions?: OptionModel[],
}

interface ContactListProps {
  contacts: ContactListModel[],
  onClickEdit?: (id: number, type: string) => void,
  onClickDelete?: (id: number, type: string) => void,
  onClickAdd?: (id: number, type: string) => void,
  showEdit?: boolean,
  showDelete?: boolean,
  showAdd?: boolean,
  hideExpand?: boolean,
  translations: Record<string, string>
}

const ContactList: FunctionComponent<ContactListProps> = ({
  contacts, onClickEdit, onClickDelete, onClickAdd,
  showEdit, showDelete, showAdd, hideExpand,
  translations,
}) => {
  return (
    <ul>
      {contacts.map((p) => (
        <ContactListItem
          translations={translations}
          key={p.type ? `${p.type}-${p.ID}` : p.ID}
          id={p.id}
          type={p.type}
          name={p.name}
          email={p.email}
          mobile={p.mobile}
          selectedOptions={p.SelectedOptions}
          showEdit={showEdit}
          showDelete={showDelete}
          showAdd={showAdd}
          hideExpand={hideExpand}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
          onClickAdd={onClickAdd}
        />
      ))}
    </ul>
  );
}

export default ContactList;
