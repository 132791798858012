import { View, Text, } from "@react-pdf/renderer"
import * as React from "react"
import { convertBreaksToNewLine } from "@murphy-frontend/common/utils"
import { Assumption } from "../../../api/AssumptionApi"

export const AssumptionPdfRow = (translations: Record<string, string>, styles: any, assumption: Assumption) => {
    return <View>
        <Text>
            <Text style={styles.label}>
                {translations['class-button-best-case']}:&nbsp;
            </Text>
            <Text>
                {assumption.Best ? convertBreaksToNewLine(assumption.Best) : ''}
            </Text>
        </Text>
        <Text>
            <Text style={styles.label}>
                {translations['class-button-likely-scenario']}:&nbsp;
            </Text>
            <Text>
                {assumption.Prob ? convertBreaksToNewLine(assumption.Prob) : ''}
            </Text>
        </Text>
        <Text>
            <Text style={styles.label}>
                {translations['class-button-worst-case']}:&nbsp;
            </Text>
            <Text>
                {assumption.Worst ? convertBreaksToNewLine(assumption.Worst) : ''}
            </Text>
        </Text>
    </View>
}

