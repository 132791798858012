import { Drone, DroneComponent, DroneTab } from "@murphy-frontend/common/api/DroneEvents/types";
import React from "react";
import { FunctionComponent } from "react";
import TitleComponent from "./BlockComponents/TitleComponent";
import TextComponent from "./BlockComponents/TextComponent";
import AccordionComponent from "./BlockComponents/AccordionComponent";
import CheckListComponent from "./BlockComponents/CheckListComponent";
import CompleteButton from "./BlockComponents/CompleteButton";

interface StartedEventFlowTabProps {
    active: boolean,
    tab: DroneTab,
}

const StartedEventFlowTab: FunctionComponent<StartedEventFlowTabProps> = (
    {
        active,
        tab,
    }
) => {
    if (!active) {
        return <>
        </>;
    }

    return (<>
        {getTabComponents(tab.Components)}
    </>
    );
}

function getTabComponents(
    components: Readonly<DroneComponent[]>,
) {
    return components.map((component, index) => {
        return (
            <React.Fragment key={`${component.Title}-${index}`}>
                {getComponentBasedOnType(component)}
            </React.Fragment>
        );
    });
}

function getComponentBasedOnType(
    component: DroneComponent,
) {
    const { __Type: __type } = component;

    switch (__type) {
        case 'Title':
            return (
                <TitleComponent
                    eventFlowBlock={component}
                />
            );
        case 'Text':
            return (
                <TextComponent
                    eventFlowBlock={component}
                />
            );
        case 'Accordion':
            return <AccordionComponent
                accordionComponent={component}
            />;
        case 'CheckList':
            return <CheckListComponent
                checkListComponent={component}
            />;
        case 'CompletedButton':
            return <CompleteButton
                eventFlowBlock={component}
            />;
        default:
            return <></>;
    }
}

export default StartedEventFlowTab;