import React from "react";
import { FunctionComponent } from "react";

interface RequirementStandardFilterButtonProps {
    contents: React.ReactNode;
    isPressed: boolean;
    onClick: () => void;
}

const ButtonV2: FunctionComponent<RequirementStandardFilterButtonProps> = (
    {
        contents,
        isPressed,
        onClick
    }
) => {
    const className = `pyramid-button ${!isPressed ? 'faded' : ''}`;
    return (
        <div className={className} onClick={onClick}>
            {contents}
        </div>
    );
}

export default ButtonV2;