import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { FileApi } from './FileApi';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import queryKeys from './queryKeys';
import { UpdateFileRequest } from '@murphy-frontend/common/interfaces/IFilesApi';

const useApiService = () => useInjection<IApiService>(ApiServiceType.IApiService);

export const useRemoveCustomerFile = (customerId: string, translations: Record<string, string>) => {
  const apiService = useApiService();
  const fileApi = new FileApi(apiService);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => fileApi.deleteFile(id),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.customerList(customerId) });
      toast.success(translations['class-lang-title-infocard-file-removed']);
    },
  });
};

export const useEditCustomerFile = (customerId: string, translations: Record<string, string>) => {
  const apiService = useApiService();
  const fileApi = new FileApi(apiService);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (editFileRequest: UpdateFileRequest) => fileApi.updateFileName(editFileRequest),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.customerList(customerId) });
      toast.success(translations['class-lang-title-infocard-file-modified']);
    },
  });
};
