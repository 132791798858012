import { FunctionComponent } from "react";
import * as React from 'react'
import { StatusCard } from "../../api/StatusCardApi";
import StatusCardExpandedContent from "./StatusCardExpandedContent";
import ExpandableRow from "@murphy-frontend/web-core/components/DashboardTable/ExpandableRow";
import StatusSelectorButton from "./StatusSelectorButton";
import { StatusCardStatusService } from "./StatusCardStatusService";
import StatusIcon from "./StatusIcon";
import { IconContext } from 'react-icons';
import { FaLink, FaArrowUp } from 'react-icons/fa';


interface ParentStatusCardsTableRowProps {
    statusCard: StatusCard,
    updateStatus: (id: number, newStatus: number) => void,
    translations: Record<string, string>;
    dashboardId: string;
    openModal: any,
    closeModal: any,
    statusCardStatusService: StatusCardStatusService,
}

const ParentStatusCardsTableRow: FunctionComponent<ParentStatusCardsTableRowProps> = ({ statusCard, dashboardId, updateStatus, translations, openModal, closeModal, statusCardStatusService }) => {

    const onClickUpdate = (newStatus: number, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        updateStatus(statusCard.ID, newStatus);
    }

    const classes = [];
    classes.push('dashboard-table-icon');
    classes.push('disable');

    const statusSelector = <StatusSelectorButton
        statusCardStatusService={statusCardStatusService}
        onClickStatus={onClickUpdate}
        currentStatus={statusCard.Status} />;

    const header = <>
        <div className='left-icon-group'>
            {statusSelector}
            <IconContext.Provider value={{ className: classes.join(' ') }}>
                <FaLink />
            </IconContext.Provider>
            <IconContext.Provider value={{ className: classes.join(' ') }}>
                <FaArrowUp />
            </IconContext.Provider>
        </div>
        <div className="dashboard-table-row-text">
            {statusCard.Title}
        </div>
    </>

    // const addHeaderClasses = ['dashboard-parent-status-row'];

    const expandableContent =
        <StatusCardExpandedContent
            statusCard={statusCard}
            dashboardId={dashboardId}
            openModal={openModal}
            closeModal={closeModal}
            translations={translations}
            statusCardStatusService={statusCardStatusService} />;

    return <ExpandableRow headerContent={header} expandedContent={expandableContent} />
}

export default ParentStatusCardsTableRow;