import React, { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import UpdateUser from '../components/UpdateUser';
import Button from '@murphy-frontend/web-core/components/Button';
import { useDeleteUserMutation } from '@murphy-frontend/common/api/Users/Mutations';
import { useGetUser } from '@murphy-frontend/common/api/Users/Queries';
import { useUserPreferences } from '../../../common/contexts/UserPreferencesContext';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';
import { useCustomer } from '../../../common/contexts/CustomerContext';
import { mapUserViewModel } from '../mappers';
import FeatureServiceType, { IFeatureService } from '@murphy-frontend/common/interfaces/IFeatureService';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import { auth } from '../../../common/variables';

const EditUser: FunctionComponent = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { openModal } = useModal();
  const { translations } = useUserPreferences();

  const { customer, allCustomerProducts } = useCustomer();
  const { mutate: mutateDel, isPending } = useDeleteUserMutation(translations);

  const featureService = useInjection<IFeatureService>(
    FeatureServiceType.IFeatureService,
  );

  const {
    isLoading: isLoadingUser, isError: isErrorUser, data: dataUser, error: errorUser,
  } = useGetUser(id);

  const onClickNewUser = () => {
    navigate('/usernew');
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const onSubmitDeleteUser = (customerUserId: string) => {
    mutateDel(customerUserId, {
      onSuccess: () => {
        navigate('/users');
      },
    });
  };

  const onUpdateSuccess = () => {
    navigate('/users');
  };

  const DeleteConfirmationBody = () => (
    <>
      <p>
        {translations.delete}
        {' '}
        <strong>{dataUser.Username}</strong>
        {' '}
        ?
      </p>
    </>
  );

  const onClickDeleteUser = () => {
    const modalConf: ModalConfiguration = {
      title: `${translations.delete} ${translations.theuser}`,
      body: <DeleteConfirmationBody />,
      hideButton: false,
      buttonText: translations.delete,
      type: 'warning',

      okCallback: () => onSubmitDeleteUser(id),
    };

    openModal(modalConf);
  };

  if (!dataUser || !customer || isLoadingUser || isPending) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }

  const userViewModel = mapUserViewModel(dataUser);
  const addOrRemoveUserIsEnabled = featureService.createUserIsEnabled() === true;


  return (
    <div className="generalwrapper">
      <section className="header-container vertical-aligner">
        <div className="one">
          <h4>
            {translations.user.toUpperCase()}
          </h4>
        </div>
        <div className="two controls-container">
          <>
            <div>
              <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={onClickBack} />
            </div>
            <div>
              {addOrRemoveUserIsEnabled && <Button isIconButton buttonStyle="btn--add" buttonSize="btn-with-icon" onClick={onClickNewUser} />}
            </div>
            <div>
              {addOrRemoveUserIsEnabled && <Button isIconButton isNegativeButton buttonStyle="btn--remove" onClick={onClickDeleteUser} buttonSize="btn-with-icon" />}
            </div>
          </>
        </div>
        <div className="three" />
      </section>
      <UpdateUser
        isSetRoleReadonly={false}
        isSetPermissionsReadonly={false}
        allProducts={allCustomerProducts || []}
        user={userViewModel}
        customerId={customer.Id}
        showResetPassword={false}
        successCallback={onUpdateSuccess}
        showResetMfa={featureService.isResetMfaEnabled()}
      />
    </div>
  );
}

export default EditUser;
