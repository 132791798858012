import React from "react";
import './SignupFormEnglish.css';

interface SignupFormEnglishProps {
    formRef: React.RefObject<HTMLFormElement>;
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
    isLoading?: boolean;
}

const SignupFormEnglish: React.FC<SignupFormEnglishProps> = ({ formRef, handleSubmit, isLoading }) => {
    return (
        <div className="full-width">
            <form ref={formRef} onSubmit={handleSubmit} method="POST" action="https://murphysolution53049.activehosted.com/proc.php" id="_form_66_" className="_form _form_66 _inline-form  _dark" noValidate data-styles-version="5">
                <input type="hidden" name="u" value="66" />
                <input type="hidden" name="f" value="66" />
                <input type="hidden" name="s" />
                <input type="hidden" name="c" value="0" />
                <input type="hidden" name="m" value="0" />
                <input type="hidden" name="act" value="sub" />
                <input type="hidden" name="v" value="2" />
                <input type="hidden" name="or" value="7809cb070c64e62ba299ede38ccfa587" />
                <div className="_form-content">
                    <div className="_form_element _x62773456 _full_width " >
                        <label htmlFor="fullname" className="_form-label">
                            Full Name*
                        </label>
                        <div className="_field-wrapper">
                            <input type="text" id="fullname" name="fullname" required />
                        </div>
                    </div>
                    <div className="_form_element _x30334600 _full_width " >
                        <label htmlFor="email" className="_form-label">
                            Email*
                        </label>
                        <div className="_field-wrapper">
                            <input type="text" id="email" name="email" required />
                        </div>
                    </div>
                    <div className="_form_element _x43587470 _full_width " >
                        <label htmlFor="field[5]" className="_form-label">
                            Organisation
                        </label>
                        <div className="_field-wrapper">
                            <input type="text" id="field[5]" name="field[5]" />
                        </div>
                    </div>
                    <div className="_form_element _x96620392 _full_width " >
                        <fieldset className="_form-fieldset">
                            <div className="_row">
                                <legend className="_form-label">
                                    Consent for GDPR*
                                </legend>
                            </div>
                            <input data-autofill="false" type="hidden" id="field[2][]" name="field[2][]" value="~|" />
                            <div className="_row _checkbox-radio">
                                <input id="field_2Yes, I consent to receiving emails" type="checkbox" name="field[2][]" value="Yes, I consent to receiving emails" className="any" required />
                                <span>
                                    <label htmlFor="field_2Yes, I consent to receiving emails">
                                        Yes, I consent to receiving emails
                                    </label>
                                </span>
                            </div>
                        </fieldset>
                    </div>
                    <div className="_button-wrapper _full_width">
                        {isLoading === true
                            ? <div className='btn-icon-container vertical-aligner horizontal-aligner'>
                                <div className="small-spinner" />
                            </div> :
                            <button id="_form_66_submit" className="_submit" type="submit">
                                Access The Murphy Standard
                            </button>}
                    </div>
                    <div className="_clear-element">
                    </div>
                </div>
            </form>
        </div>
    );
}

export default SignupFormEnglish;

