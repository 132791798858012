import React, { FunctionComponent, useState } from 'react';
import Input from '@murphy-frontend/web-core/components/Input';
import Button from '@murphy-frontend/web-core/components/Button';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import { useCreateContactGroup } from '@murphy-frontend/common/api/ContactGroups/Mutations';

interface AddContactGroupProps {
  customerId: string,
  onSuccess: () => void,
  onFail: () => void,
  translations: Record<string, string>,
}

const AddContactGroup: FunctionComponent<AddContactGroupProps> = ({
  customerId, onSuccess, onFail, translations,
}) => {
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState('');

  const { mutate: createContactGroup, isPending: createContactIsLoading } = useCreateContactGroup(customerId);

  const onNameChanged = (event) => {
    if (!event.target.value) {
      setNameErrorMessage(translations.mandatory);
    } else {
      setNameErrorMessage('');
    }
    setName(event.target.value);
  };

  const submit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const createContactGroupRequest = {
      Name: name,
      CustomerId: customerId,
    };

    createContactGroup(createContactGroupRequest, {
      onSuccess: () => {
        setIsLoading(false);
        onSuccess();
      },
      onError: () => {
        setIsLoading(false);
        onFail();
      },
    });
  };

  if (isLoading || createContactIsLoading) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }

  return (
    <form>
      <Input title={translations.name} val={name} isRequired handleChange={(event) => onNameChanged(event)} errorMessage={nameErrorMessage} />
      <div className="modal-footer">
        <Button buttonStyle="btn--primary" buttonSize="btn--medium" onClick={submit}>{translations.save}</Button>
      </div>
    </form>
  );
}

export default AddContactGroup;
