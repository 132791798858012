import * as React from 'react';
import { FunctionComponent } from 'react';
import ActionsButton from '@murphy-frontend/web-core/components/ActionsButton';
import { StatusCardStatusService } from './StatusCardStatusService';
import StatusIcon from './StatusIcon';

interface StatusSelectorButtonProps {
    onClickStatus: (status: number, event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
    currentStatus: number,
    statusCardStatusService: StatusCardStatusService
}

const StatusSelectorButton: FunctionComponent<StatusSelectorButtonProps>
    = ({ onClickStatus,
        currentStatus,
        statusCardStatusService
    }) => {

        const currentStatusIcon = <StatusIcon statusCardStatusService={statusCardStatusService} statusId={currentStatus} />

        const statuses = statusCardStatusService.getStatuses();
        const actionButtonItems = statuses.map(status => {
            return <li key={status.Id} onClick={(e) => { onClickStatus(status.Id, e) }}>
                <div className="vertical-aligner">
                    {<StatusIcon statusId={status.Id} statusCardStatusService={statusCardStatusService} />}
                    &nbsp;&nbsp;
                    <span>{status.Name}</span>
                </div>
            </li>
        });

        return (
            <ActionsButton actionButtonItems={actionButtonItems} icon={currentStatusIcon} pullRightOnSmallScreen={true} />
        );
    }

export default StatusSelectorButton;