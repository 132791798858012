import { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { DashboardCardFilter } from '../models';

export interface StatusCard {
    ID: number,
    Title: string,
    Message?: string,
    CreatedDateTime: Date,
    Status: number,
    ProvidedBy?: string,
    Important: boolean,
    child?: number,
    archived: boolean,
    AlternatedTime?: Date,
    user: string,
    ParentDashboardId?: string,
    DashboardId?: string,
    DashboardName?: string,
    ChildCustomerId?: string,
    ChildCustomerName?: string,
}

export interface UpsertStatusCard {
    Title: string,
    Message?: string,
    Status?: number,
    ProvidedBy?: string,
    Important: boolean,
    AlternatedTime?: Date,
    ParentId?: number,
    archived?: boolean,
    ParentDashboardId?: string,
    DashboardId?: string,
    UpdatedFromDashboardId: string,
}

export interface PatchStatusCard {
    UpdatedFromDashboardId: string,
    DashboardId?: string,
    Id: number,
    archived?: boolean,
    child?: number,
}

export class StatusCardApi {
    parentId: string;
    baseUrl: string;
    apiService: IApiService;

    constructor(apiService: IApiService, dashboardId: string) {
        this.parentId = dashboardId
        this.baseUrl = `api/Dashboard/${dashboardId}/StatusCard`;
        this.apiService = apiService;
    }

    getById = (id: string) => {
        const url = `${this.baseUrl}/${id}`;
        return this.apiService.callApi(url, 'GET', null);
    }

    getAll = (filter?: DashboardCardFilter) => {
        let searchParamsString = '';
        if (filter) {
            const searchParams = new URLSearchParams();

            if (filter.fromDate) {
                searchParams.append('fromDate', filter.fromDate.toUTCString());
            }
            searchParamsString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
        }
        const url = `${this.baseUrl}${searchParamsString}`;
        return this.apiService.callApi(url, 'GET', null);
    }

    upsertStatusCard = (upsertActionCard: UpsertStatusCard) => {
        const url = `${this.baseUrl}`;
        return this.apiService.callApi(url, 'POST', upsertActionCard);
    }

    patchStatusCard = (patchActionCard: PatchStatusCard) => {
        const url = `${this.baseUrl}/${patchActionCard.Id}`;
        return this.apiService.callApi(url, 'PATCH', patchActionCard);
    }
};
