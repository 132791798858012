import * as React from "react";
import { DashboardEntityTypes } from "@murphy-frontend/common/enums";
import { getConvertedDateDayjs } from '@murphy-frontend/common/services/TimeService';
import { DashboardFile } from "../../../api/DashboardFileApi";
import DashboardFileFormatted from "../../DashboardFiles/DashboardFileFormatted";
import { MainLogCard } from "../models";
import { BaseCardProps } from "./MainLogCardFactory";

export interface DashboardFileMainLogCardProps extends BaseCardProps {
    file: DashboardFile,
    eventType: 'added' | 'removed' | 'modified';
}

export const DashboardFileMainLogCard =
    ({ translations, file, timeZone, eventType }: DashboardFileMainLogCardProps): MainLogCard => {
        let dateToUse;
        let userToShow;
        let title;

        if (eventType === 'added') {
            dateToUse = file.CreatedDateTime;
            userToShow = file.addedBy;
            title = translations['class-lang-title-infocard-file-added'];
        }

        if (eventType === 'removed') {
            dateToUse = file.RemovedDateTime;
            userToShow = file.removedBy;
            title = translations['class-lang-title-infocard-file-removed'];
        }

        if (eventType === 'modified') {
            dateToUse = file.ModifiedDateTime;
            userToShow = file.modifiedBy;
            title = translations['class-lang-title-infocard-file-modified'];
        }

        const date = getConvertedDateDayjs(dateToUse, timeZone);
        const expandedContent =
            <DashboardFileFormatted dashboardFile={file} translations={translations} user={userToShow} />

        const searchableContent = [];
        searchableContent.push(title);
        if (file.FileName)
            searchableContent.push(file.FileName);
        if (file.addedBy)
            searchableContent.push(file.addedBy);
        if (file.modifiedBy)
            searchableContent.push(file.modifiedBy);
        if (file.removedBy)
            searchableContent.push(file.removedBy);
        if (file.user)
            searchableContent.push(file.user);

        const logCard: MainLogCard = {
            ID: `${file.ID}-${eventType}`,
            Title: title,
            isArchived: file.archived,
            isDecision: false,
            SearchableContent: searchableContent,
            User: file.user,
            Date: date,
            DateWithoutTime: date.format("YYYY-MM-DD"),
            Time: date.format("HH:mm"),
            EntityType: DashboardEntityTypes.DashboardFile,
            ExpandedContent: expandedContent,
            OriginalCard: file,
        }
        return logCard;
    }