import { View, Text, } from "@react-pdf/renderer"
import * as React from "react"
import { Party } from "../../../api/PartyApi";

export const PartyPdfRow = (translations: Record<string, string>, styles: any, party: Party) => {
    let title = translations['class-lang-title-infocard-group-updated'];
    if (party.action === "Created") {
        title = translations['class-lang-title-infocard-group-created'];
    }
    return <Text>
        <Text>
            <Text style={styles.label}>
                {title}:&nbsp;
            </Text>
            <Text >
                {party.PartyName}
            </Text>
        </Text>
    </Text>
}

