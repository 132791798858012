import * as React from 'react';
import Button from '@murphy-frontend/web-core/components/Button';
import CardFooter from "../MainLog/CardFooter";
import { FunctionComponent } from "react";
import { ActionsCard, UpsertActionsCard } from '../../api/ActionsCardApi';
import { useUpsertActionsCard } from '../../mutations';
import UpsertActionsCardComponent from './UpsertActionsCardComponent';
import ActionsCardFormatted from './ActionsCardFormatted';
import { TimeZoneType } from "@murphy-frontend/common/services/TimeService"

interface ActionsCardExpandedContentProps {
    actionsCard: ActionsCard,
    dashboardId: string,
    openModal: any,
    closeModal: any,
    translations: Record<string, string>,
    timeZone: TimeZoneType,
}

const ActionsCardExpandedContent: FunctionComponent<ActionsCardExpandedContentProps> = ({ actionsCard, dashboardId, openModal, closeModal, translations, timeZone }) => {

    const { mutate: upsertActionsCardMutation, isPending } = useUpsertActionsCard(dashboardId);

    const onClickEdit = () => {
        const upsertActionsCardComponent =
            <UpsertActionsCardComponent
                translations={translations}
                timeZone={timeZone}
                dashboardId={dashboardId}
                actionsCard={actionsCard}
                onSuccess={() => closeModal()} />;

        const configuration = {
            centerContent: false,
            hideButton: true,
            body: upsertActionsCardComponent,
            title: translations['lang-action-card'],
        }
        openModal(configuration);
    }

    const onClickReactivate = () => {
        const newCard: UpsertActionsCard = { ...actionsCard }
        newCard.ParentId = actionsCard.ID;
        newCard.archived = false;
        upsertActionsCardMutation(newCard);
    }

    let footer;
    if (actionsCard.archived !== true) {
        const button =
            <Button
                isLoading={isPending}
                onClick={() => onClickEdit()}>
                {translations['class-lang-button-edit']}
            </Button>
        footer = <CardFooter rightContent={button} />
    }

    if (actionsCard.archived === true && !actionsCard.child) {
        const button =
            <Button
                isLoading={isPending}
                isNeutralButton
                buttonStyle="btn--unarchive"
                buttonSize="btn-with-icon"
                onClick={() => onClickReactivate()}>
                {translations['activate']}
            </Button>
        footer = <CardFooter rightContent={button} />
    }
    return <ActionsCardFormatted actionsCard={actionsCard} translations={translations} cardFooter={footer} />
}

export default ActionsCardExpandedContent;