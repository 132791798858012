import Spinner from '@murphy-frontend/web-core/components/Spinner';
import UpdateUser from '../components/UpdateUser';
import React, { FunctionComponent } from 'react';
import { useUserPreferences } from '../../../common/contexts/UserPreferencesContext';
import { useCustomer } from '../../../common/contexts/CustomerContext';
import { useUser } from '../../../common/contexts/UserContext';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import AuthServiceType, { IAuthService } from '@murphy-frontend/common/interfaces/IAuthService';
import FeatureServiceType, { IFeatureService } from '@murphy-frontend/common/interfaces/IFeatureService';
import { auth } from '../../../common/variables';

const Me: FunctionComponent = () => {
  const { customer, allCustomerProducts } = useCustomer();
  const { user } = useUser();
  const { translations } = useUserPreferences()
  const authService = useInjection<IAuthService>(AuthServiceType.IAuthService);
  const featureService = useInjection<IFeatureService>(FeatureServiceType.IFeatureService);

  const onClickChangePassword = () => {
    authService.changePassword();
  };

  if (!allCustomerProducts || !user || !customer) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }

  return (
    <div className="generalwrapper">
      <section className="header-container vertical-aligner">
        <div className="one">
          <h4>
            {translations.myprofile.toUpperCase()}
          </h4>
        </div>
        <div className="two" />
        <div className="three" />
      </section>
      <UpdateUser
        isSetRoleReadonly
        isSetPermissionsReadonly
        allProducts={allCustomerProducts}
        user={user}
        customerId={customer.Id}
        showResetPassword={featureService.isResetPasswordEnabled()}
        onClickChangePassword={onClickChangePassword}
        showResetMfa={featureService.isResetMfaEnabled()}
      />
    </div>
  );
}

export default Me;
