import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { SendOutResponseApi, SendOutResponseUpdate } from './SendOutResponseApi';

const useApiService = () => useInjection<IApiService>(ApiServiceType.IApiService);

export const useEditSendOutResponse = (id: string) => {
  const apiService = useApiService();
  const sendOutResponseApi = new SendOutResponseApi(apiService);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (editSendOutResponseRequest: SendOutResponseUpdate) => sendOutResponseApi.postSendOutResponse(id, editSendOutResponseRequest),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all });
      // Optionally, include a success message here if needed.
      // toast.success('Success');
    },
  });
};
