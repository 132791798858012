import React, { FunctionComponent } from "react";
import { IconContext } from "react-icons/lib";
import { useNavigate } from "react-router-dom";
import Button from '@murphy-frontend/web-core/components/Button';

interface CourseCardFooterProps {
  trainingId: number;
}

export const GuideCardFooter: FunctionComponent<CourseCardFooterProps> = ({
  trainingId,
}) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="course-card-booked-buttons">
        <Button onClick={() => navigate(`/training/${trainingId}`)}>
          <IconContext.Provider value={{ className: "btn-icon" }}>
            &nbsp;
            {"Go to guide"}
          </IconContext.Provider>
        </Button>
      </div>
    </div>
  );
};
