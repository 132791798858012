import * as React from "react";
import { FunctionComponent } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { FaFileExport } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { RiMoreFill } from "react-icons/ri";
import Button from '@murphy-frontend/web-core/components/Button';
import ActionsButton from "@murphy-frontend/web-core/components/ActionsButton";
import UpsertInformationCardComponent from "../InformationCard/UpsertInformationCardComponent";

interface MainLogTableHeaderProps {
    translations: Record<string, string>,
    dashboardId: string,
    openModal: any,
    closeModal: any,
    onClickExport: () => void,
}

const MainLogTableHeader: FunctionComponent<MainLogTableHeaderProps> =
    ({ translations, dashboardId, openModal, closeModal, onClickExport }) => {

        const onClickCreateInformationCard = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            event.preventDefault();
            const upsertInformationCardComponent =
                <UpsertInformationCardComponent translations={translations} dashboardId={dashboardId} onSuccess={() => closeModal()} />;
            const configuration = {
                centerContent: false,
                hideButton: true,
                body: upsertInformationCardComponent,
                title: translations['lang-modal-situation-card'],
            }
            openModal(configuration);
        }

        const moreButtonIcon = <IconContext.Provider value={{ className: 'dashboard-table-actions-button-icon' }}>
            <RiMoreFill />
        </IconContext.Provider>;

        const exportButton = <IconContext.Provider value={{ className: 'dashboard-table-action-status-icon' }}>
            <FaFileExport />
        </IconContext.Provider>;

        const actionButtonItems = [];
        actionButtonItems.push(
            <li key="export" onClick={(e) => { onClickExport() }}>
                <div className="vertical-aligner">
                    {exportButton}
                    &nbsp;&nbsp;
                    <span>{translations['lang-export']}</span>
                </div>
            </li>
        );

        const actionsButton = <ActionsButton actionButtonItems={actionButtonItems} icon={moreButtonIcon} pullLeft={true} />

        return <div className='dashboard-table-flex-header'>
            <div className="left"><div className='dashboard-table-title'>
                <h2>{translations['lang-rubrik-situation']}</h2>

            </div>
            </div>
            <div className="right">
                <div className='controls'>
                    {actionsButton}
                    <Button buttonStyle={"btn--add"} isIconButton onClick={onClickCreateInformationCard} />
                </div>
            </div>
        </div>
    }

export default MainLogTableHeader;