const QueryKeys = {
  all: ['users'],
  lists: () => [...QueryKeys.all, 'list'],
  list: (customerId: string) => [...QueryKeys.lists(), customerId],
  details: () => [...QueryKeys.all, 'detail'],
  detail: (id: string | null | undefined) => [...QueryKeys.details(), id],
  customerObject: ({ customerId, objectId }) => [...QueryKeys.list(customerId), objectId],
  onboardingFlows: () => [...QueryKeys.all, 'onboardingFlows'],
};

export default QueryKeys;
