import { Step, Steps } from "intro.js-react";
import React, { useEffect } from "react";
import { FunctionComponent, useRef, useState } from "react";
import { OnboardingFlowIds, useOnboarding } from "../../../common/contexts/OnboardingContext";

interface RequirementPyramidGridOnboardingStepsProps {
    setRequirementLevelIsFlashing: (isFlashing: boolean) => void;
    setEditGoalIsFlashing: (isFlashing: boolean) => void;
    setRequirementCategoryIsFlashing: (isFlashing: boolean) => void;
    setRequirementBlockIsFlashing: (isFlashing: boolean) => void;
    setInformationFilmLinkIsFlashing: (isFlashing: boolean) => void;
    stepsEnabled: boolean;
    activeRowLevel: number | null;
    currentClickedBlockId: number | null;
    activeCategoryId: number | null;
    currentPyramidContent: JSX.Element | null;
    onComplete: () => void;
    setStepsEnabled: (isEnabled: boolean) => void;
}

const RequirementPyramidGridOnboardingSteps: FunctionComponent<RequirementPyramidGridOnboardingStepsProps> = (
    {
        setRequirementLevelIsFlashing,
        setEditGoalIsFlashing,
        setRequirementCategoryIsFlashing,
        setRequirementBlockIsFlashing,
        setInformationFilmLinkIsFlashing,
        activeRowLevel,
        currentClickedBlockId,
        activeCategoryId,
        currentPyramidContent,
        stepsEnabled,
        setStepsEnabled,
        onComplete
    }
) => {

    const stepsRef = useRef(null);
    const [steps, setSteps] = useState<Step[]>([]);
    const [initialStep, setInitialStep] = useState<number>();
    const { incompletedOnboardingFlows, setCurrentStep, onboardingsCurrentStep } = useOnboarding();

    useEffect(() => {
        if (stepsEnabled && onboardingsCurrentStep && steps.length === 0 // Only initialize steps if they haven't been initialized yet.
        ) {
            console.log('Initializing onboarding steps');
            const stepsFromApi = incompletedOnboardingFlows.find(p => p.OnboardingFlowId === OnboardingFlowIds.MurphyStandard)?.StepsHtmlContents;
            if (stepsFromApi === undefined)
                return;

            const newSteps = createInitialSteps(stepsFromApi);
            setSteps(newSteps);
            const firstStep = onboardingsCurrentStep.get(OnboardingFlowIds.MurphyStandard);
            setInitialStep(firstStep);
        }
    }, [onboardingsCurrentStep, incompletedOnboardingFlows, stepsEnabled]);

    useEffect(() => {
        if (stepsEnabled && steps.length > 0) {
            const firstStep = onboardingsCurrentStep.get(OnboardingFlowIds.MurphyStandard);
            setInitialStep(firstStep);
        }
    }, [stepsEnabled, onboardingsCurrentStep, steps]);

    const onDummyExit = () => {
        console.log('Dummy exit');
    }

    useEffect(() => {
        if (stepsEnabled && initialStep !== undefined) {
            if (stepsRef) {
                if (initialStep > 1) { // if we don't do this, then there will be 2 instances of introjs running and it wont work properly
                    stepsRef.current?.introJs.exit(onDummyExit).then(() => {
                        stepsRef.current?.introJs?.goToStepNumber(initialStep);
                        stepsRef.current?.updateStepElement(initialStep);
                        stepsRef.current?.introJs?.refresh();
                        stepsRef.current?.introJs.start();
                    });
                }
            }
        }
    }, [stepsEnabled, stepsRef, initialStep]);


    useEffect(() => {
        if (stepsEnabled && currentPyramidContent !== null && activeRowLevel !== null) {
            const currentStep = stepsRef?.current?.introJs?._currentStep;
            if (currentStep === 1) {
                const timer = setTimeout(() => {
                    stepsRef.current.introJs?.goToStepNumber(3);
                    stepsRef.current.updateStepElement(3);
                    stepsRef.current.introJs.start();
                }, 500);

                return () => clearTimeout(timer);
            }
        }
    }, [stepsEnabled, currentPyramidContent, activeRowLevel]);

    useEffect(() => {
        if (stepsEnabled && currentPyramidContent !== null && activeRowLevel !== null) {
            const currentStep = stepsRef?.current?.introJs?._currentStep;
            if (currentStep === 2) {
                stepsRef.current.introJs?.goToStepNumber(3);
                stepsRef.current.updateStepElement(3);
                stepsRef.current.introJs.start();
            }
        }
    }, [stepsEnabled, currentPyramidContent, activeRowLevel]);

    useEffect(() => {
        if (stepsEnabled && activeCategoryId !== null && currentPyramidContent != null) {
            const currentStep = stepsRef?.current?.introJs?._currentStep;
            if (currentStep === 5) {
                const timer = setTimeout(() => {
                    stepsRef.current.introJs?.goToStepNumber(6);
                    stepsRef.current.updateStepElement(6);
                    stepsRef.current.introJs.start();
                }, 500);

                return () => clearTimeout(timer);
            }
        }
    }, [stepsEnabled, activeCategoryId, currentPyramidContent]);

    useEffect(() => {
        if (stepsEnabled && currentClickedBlockId && currentPyramidContent != null) {
            const currentStep = stepsRef?.current?.introJs?._currentStep;
            if (currentStep === 6) {
                const timer = setTimeout(() => {
                    stepsRef.current.introJs?.goToStepNumber(7);
                    stepsRef.current.updateStepElement(7);
                    stepsRef.current.introJs.start();
                }, 500);

                return () => clearTimeout(timer);
            }
        }
    }, [stepsEnabled, currentClickedBlockId, currentPyramidContent]);

    const onBeforeStep = (nextStepIndex: number, nextElement: Element): false | void | Promise<false | void> => {
        setRequirementLevelIsFlashing(false);
        setEditGoalIsFlashing(false);
        setRequirementCategoryIsFlashing(false);
        setRequirementBlockIsFlashing(false);
        setRequirementCategoryIsFlashing(false);

        if (stepsRef.current) {
            stepsRef.current.updateStepElement(nextStepIndex);
        }

        if (nextStepIndex === 3) {
            if (activeRowLevel === null) {
                setRequirementLevelIsFlashing(true);
                return false;
            }
        }

        if (nextStepIndex === 4) {
            // if (requirementLevels.find(p => p.IsGoalLevel) === undefined) {
            //     setEditGoalIsFlashing(true);
            //     return false;
            // }
        }

        if (nextStepIndex === 6) {
            if (activeCategoryId === null) {
                setRequirementCategoryIsFlashing(true);
                return false;
            }
        }

        if (nextStepIndex === 7) {
            if (currentClickedBlockId === null) {
                setRequirementBlockIsFlashing(true);
                return false;
            }
        }

        if (nextStepIndex === 8) {
            if (currentClickedBlockId === null) {
                setInformationFilmLinkIsFlashing(true);
                return false;
            }
        }

        if (stepsRef.current) {
            stepsRef.current.updateStepElement(nextStepIndex);
        }
    }

    const onAfterStep = (nextStepIndex: number, nextElement: Element) => {

        if (nextStepIndex === 4) {
            setEditGoalIsFlashing(true);
        }

        if (nextStepIndex === 5) {
            setEditGoalIsFlashing(false);
        }

        if (nextStepIndex === 7) {
            setInformationFilmLinkIsFlashing(true);
        }

        if (nextStepIndex === 8) {
            setInformationFilmLinkIsFlashing(false);
        }

        if (nextStepIndex === 0) {
            setCurrentStep(OnboardingFlowIds.MurphyStandard, nextStepIndex);
            setStepsEnabled(false);
            window.location.reload();
            return false;
        }

        setCurrentStep(OnboardingFlowIds.MurphyStandard, nextStepIndex);
    }

    const onChangeStep = (nextStepIndex: number, nextElement: Element) => {
    }

    const onPreventStep = (stepIndex: number) => {
    }

    const onExit = (stepIndex: number) => {
        console.log('Onboarding is exited from step ' + stepIndex);
        setInformationFilmLinkIsFlashing(false);
        setRequirementBlockIsFlashing(false);
        setRequirementCategoryIsFlashing(false);
        setEditGoalIsFlashing(false);
        setRequirementLevelIsFlashing(false);

        // sometimes introjs calls onExit by itself, but with -1 as stepIndex. When the user does it, it will be with a positive number.
        if (stepsRef?.current != null && stepIndex > -1) {
            if (stepsRef?.current.introJs._lastShowElementTimer) {
                onComplete();
            }
        }
    };

    const onStart = (step: number) => {
        setInformationFilmLinkIsFlashing(false);
        setRequirementBlockIsFlashing(false);
        setRequirementCategoryIsFlashing(false);
        setEditGoalIsFlashing(false);
        setRequirementLevelIsFlashing(false);
    }

    const createInitialSteps = (stepsFromApi: Map<number, string>): Step[] => {
        const steps = Array<Step>();
        // replace all <p></p> with <br> in the steps
        stepsFromApi?.forEach((value, key) => {
            stepsFromApi?.set(key, value.replace(/<p><\/p>/g, '<br>'));
        });

        steps.push({
            element: '.pyramiditem',
            intro: 'Dummy step because the first step is the modal outside these steps',
            highlightClass: 'pyramiditem-highlight-class',
            position: 'bottom'
        });
        steps.push({
            element: '.pyramiditem',
            intro: stepsFromApi?.get(2),
            highlightClass: 'pyramiditem-highlight-class',
            position: 'bottom'
        });
        steps.push({
            element: '.pyramiditem',
            intro: stepsFromApi?.get(3),
            highlightClass: 'pyramiditem-highlight-class',
            position: 'bottom'
        });
        steps.push({
            element: '.contentitem > .content-container:last-child',
            intro: stepsFromApi?.get(4),
            position: 'bottom'
        });
        steps.push({
            element: '.pyramid-content',
            intro: stepsFromApi?.get(5),
            position: 'left'
        });
        steps.push({
            element: '.buttonsitem',
            intro: stepsFromApi?.get(6),
            highlightClass: 'buttonsitem-highlight-class',
            position: 'bottom'
        });
        steps.push({
            element: '.pyramid-container > .pyramid-row:last-child > .pyramid-block:first-child',
            intro: stepsFromApi?.get(7),
            position: 'left',
        });
        steps.push({
            element: '.standard-content',
            intro: stepsFromApi?.get(8),
            position: 'left',
        });
        steps.push({
            element: '.pyramid-container > .pyramid-row:last-child > .pyramid-block:first-child > div > .top-right',
            intro: stepsFromApi?.get(9),
            position: 'right',
        });
        steps.push({
            element: '.pyramid-home',
            intro: stepsFromApi?.get(10),
            position: 'right',
        });

        // set tooltipClass to 'tooltip-class' on all steps
        steps.forEach(step => {
            step.tooltipClass = 'tooltip-class';
        });

        return steps;
    };

    return (stepsEnabled && <Steps
        ref={stepsRef}
        enabled={stepsEnabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
        onComplete={onComplete}
        onAfterChange={onAfterStep}
        onChange={onChangeStep}
        onBeforeChange={onBeforeStep}
        onStart={onStart}
        onPreventChange={onPreventStep}
        options={{
            hideNext: false,
            hidePrev: true,
            exitOnOverlayClick: false,
            showBullets: false,
            doneLabel: 'Next'
        }}
    />
    );
}

export default RequirementPyramidGridOnboardingSteps;