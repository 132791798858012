import { InformationCard } from "../../api/InformationCardApi";
import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';
import { parseHtmlAndLinkifySafe } from "@murphy-frontend/common/utils";

interface InformationCardFormattedProps {
    infoCard: InformationCard
    translations: Record<string, string>
    cardFooter: any
}

const InformationCardFormatted: FunctionComponent<InformationCardFormattedProps> = ({ infoCard, translations, cardFooter }) => {

    let extraInfoPresent = false;
    if (infoCard.Owner)
        extraInfoPresent = true;
    if (infoCard.ProvidedBy)
        extraInfoPresent = true;
    if (infoCard.isDecision)
        extraInfoPresent = true;
    if (infoCard.Confirmed)
        extraInfoPresent = true;
    if (infoCard.Important)
        extraInfoPresent = true;

    const extraInfo = <> {infoCard.Owner &&
        <>
            <br />
            <p>
                <strong>{translations['class-lang-modal-decision-maker']}:</strong>&nbsp;{infoCard.Owner}
            </p>
        </>}
        {infoCard.ProvidedBy &&
            <>
                <br />
                <p>
                    <strong>{translations['class-lang-modal-responsible']}:</strong>&nbsp;{infoCard.ProvidedBy}
                </p>
            </>}
        {infoCard.Important === true &&
            <>
                <br />
                <p>
                    <strong>{translations['class-lang-modal-check-priority']}:</strong>&nbsp;{translations.yes}
                </p>
            </>}
        {infoCard.isDecision === true &&
            <>
                <br />
                <p>
                    <strong>{translations['class-lang-modal-decision-check']}:</strong>&nbsp;{translations.yes}
                </p>
            </>}
        {infoCard.Confirmed === true &&
            <>
                <br />
                <p>
                    <strong>{translations['class-lang-modal-check-confirmed']}:</strong>&nbsp;{translations.yes}
                </p>
            </>}
    </>;

    return (
        <div className="formatted-card">
            <p>
                <strong>{translations['class-lang-by-user']}</strong>&nbsp;{infoCard.user}
            </p>
            {infoCard.Message &&
                <>
                    <br />
                    <p>
                        {parseHtmlAndLinkifySafe(infoCard.Message)}
                    </p>
                </>}
            {extraInfoPresent
                &&
                <>
                    <br />
                    <hr></hr>
                    {extraInfo}
                </>
            }
            {cardFooter &&
                <>
                    <br />
                    <hr></hr>
                    <br />
                    {cardFooter}
                </>
            }
        </div >
    );
}

export default InformationCardFormatted;