import { DashboardCardFilter } from '../models';
import { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
export interface ActionsCard {
    ID: number,
    Title: string,
    Message?: string,
    CreatedDateTime: Date,
    Status: number,
    ProvidedBy?: string,
    ProvidedByCustomerUserId?: string,
    Owner?: string,
    isDecision: boolean,
    Important: boolean,
    child?: number,
    archived: boolean,
    AlternatedTime?: Date,
    user: string,
    isCommunication: boolean,
    LatestEmailNotificationSent?: Date,
    LatestSmsNotificationSent?: Date,
    LatestPushNotificationSent?: Date,
}

export interface UpsertActionsCard {
    Title: string,
    Message?: string,
    Status?: number,
    ProvidedBy?: string,
    ProvidedByCustomerUserId?: string,
    Owner?: string,
    isDecision: boolean,
    Important: boolean,
    AlternatedTime?: Date,
    ParentId?: number,
    archived?: boolean,
    isCommunication: boolean,
    LatestEmailNotificationSent?: string,
    LatestSmsNotificationSent?: string,
    LatestPushNotificationSent?: string,
}

export interface PatchActionsCard {
    Id: number,
    archived?: boolean,
    child?: number,
}

export class ActionsCardApi {
    parentId: string;
    baseUrl: string;
    apiService: IApiService;

    constructor(
        apiService: IApiService,
        dashboardId: string) {
        this.parentId = dashboardId
        this.baseUrl = `api/Dashboard/${dashboardId}/ActionsCard`;
        this.apiService = apiService;
    }

    getById = (id: string) => {
        const url = `${this.baseUrl}/${id}`;
        return this.apiService.callApi(url, 'GET', null);
    }

    getAll = (filter?: DashboardCardFilter) => {
        let searchParamsString = '';
        if (filter) {
            const searchParams = new URLSearchParams();

            if (filter.fromDate) {
                searchParams.append('fromDate', filter.fromDate.toUTCString());
            }
            searchParamsString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
        }
        const url = `${this.baseUrl}${searchParamsString}`;
        return this.apiService.callApi(url, 'GET', null);
    }

    upsertActionCard = (upsertActionCard: UpsertActionsCard) => {
        const url = `${this.baseUrl}`;
        return this.apiService.callApi(url, 'POST', upsertActionCard);
    }

    patchActionCard = (patchActionCard: PatchActionsCard) => {
        const url = `${this.baseUrl}/${patchActionCard.Id}`;
        return this.apiService.callApi(url, 'PATCH', patchActionCard);
    }
};
