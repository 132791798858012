
import { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
export interface Recipiant {
    ID: string,
    Email: boolean,
    MobileNr: boolean,
    origin: string,
}

export interface GroupRecipiant {
    GroupId: string,
    Email: boolean,
    MobileNr: boolean,
    origin: string,
}

export interface Message {
    Title: string,
    Body: string,
    Question?: string,
}

export interface CreateSendOutBatchRequest {
    criticalAlert: boolean,
    geoLocation: boolean,
    licenseId: string,
    recipiants: Recipiant[],
    grouprecipiants: GroupRecipiant[],
    message: Message,
    alternatives: string[],
}

export class MBSApi {
    constructor(private apiService: IApiService) { }

    createSendOutBatch = (request: CreateSendOutBatchRequest) => {
        const url = '/SendOutBatch';
        return this.apiService.callApi(url, 'POST', request);
    }

    getSendOutBatches = (customerId: string) => {
        const url = `/SendOutBatch/?LicenseId=${customerId}`;
        return this.apiService.callApi(url, 'GET');
    };

    getSendOuts = (customerId: string) => {
        const url = `/SendOutBatch/?LicenseId=${customerId}`;
        return this.apiService.callApi(url, 'GET');
    };

    createSendOut = (customerId: string, personRecipients: Array<any>, groupRecipients: Array<any>, message: string, alternatives: any) => {
        const sendData = {
            licenseId: customerId,
            message,
        };

        if (personRecipients && personRecipients.length > 0) {
            sendData.recipiants = personRecipients;
        }

        if (groupRecipients && groupRecipients.length > 0) {
            sendData.grouprecipiants = groupRecipients;
        }

        if (alternatives) {
            sendData.alternatives = alternatives;
        }

        const url = '/SendOutBatch';
        return this.apiService.callApi(url, 'POST', sendData);
    };
}
