interface StatusCardStatus {
    Id: number,
    Name: string,
    IconClass: string,
    IsDefault: boolean,
    Priority: number,
}

export interface StatusCardStatusService {
    getStatuses(): StatusCardStatus[];
    getStatus(Id: number): StatusCardStatus;
    getDefaultStatus(): StatusCardStatus;
}

export class DefaultStatusCardStatusService implements StatusCardStatusService {

    statuses: StatusCardStatus[];

    constructor(translations: Record<string, string>) {
        this.statuses = [
            {
                Id: 0,
                Name: translations['class-lang-modal-status-red'],
                IconClass: 'red-status',
                IsDefault: false,
                Priority: 1,
            },
            {
                Id: 1,
                Name: translations['class-lang-modal-status-green'],
                IconClass: 'green-status',
                IsDefault: false,
                Priority: 3,
            },
            {
                Id: 2,
                Name: translations['class-lang-modal-status-yellow'],
                IconClass: 'yellow-status',
                IsDefault: false,
                Priority: 2,
            },
            {
                Id: 3,
                Name: translations['class-lang-modal-status-black'],
                IconClass: 'no-status',
                IsDefault: true,
                Priority: 4,
            }
        ]
    }

    getStatuses = (): StatusCardStatus[] => {
        return this.statuses;
    }

    getStatus = (Id: number): StatusCardStatus => {
        return this.statuses.find(p => p.Id === Id);
    }

    getDefaultStatus = (): StatusCardStatus => {
        return this.statuses.find(p => p.IsDefault === true);
    }
}

export class MSBStatusCardStatusService implements StatusCardStatusService {
    statuses: StatusCardStatus[];
    constructor(translations: Record<string, string>) {
        this.statuses = [
            {
                Id: 0,
                Name: translations['missingstatus'],
                IconClass: 'no-status',
                IsDefault: true,
                Priority: 6,
            },
            {
                Id: 1,
                Name: translations['noeffectstatus'],
                IconClass: 'green-status',
                IsDefault: false,
                Priority: 5,
            },
            {
                Id: 2,
                Name: translations['mediumeffectstatus'],
                IconClass: 'yellow-status',
                IsDefault: false,
                Priority: 4,
            },
            {
                Id: 3,
                Name: translations['higheffectstatus'],
                IconClass: 'orange-status',
                IsDefault: true,
                Priority: 3,
            },
            {
                Id: 4,
                Name: translations['veryhigheffectstatus'],
                IconClass: 'red-status',
                IsDefault: true,
                Priority: 2,
            },
            {
                Id: 5,
                Name: translations['criticaleffectstatus'],
                IconClass: 'critical-red-status',
                IsDefault: true,
                Priority: 1,
            }
        ]
    }

    getStatuses = (): StatusCardStatus[] => {
        return this.statuses;
    }

    getStatus = (Id: number): StatusCardStatus => {
        return this.statuses.find(p => p.Id === Id);
    }

    getDefaultStatus = (): StatusCardStatus => {
        return this.statuses.find(p => p.IsDefault === true);
    }
}


