import { useQuery } from '@tanstack/react-query';
import { TrainingFileApi } from './TrainingFileApi';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import queryKeys from './queryKeys';

const useApiService = () => useInjection<IApiService>(ApiServiceType.IApiService);

export function useGetTrainingFiles(trainingId: number) {
    const apiService = useApiService();
    const trainingFileApi = new TrainingFileApi(apiService);

    const getTrainingFilesAsync = async (trainingId: number) => {
        const data = await trainingFileApi.getTrainingFiles({ trainingId });
        return data;
    };

    return useQuery({
        queryKey: queryKeys.list(trainingId),
        queryFn: () => getTrainingFilesAsync(trainingId),
        enabled: !!trainingId, staleTime: Infinity
    });
}
