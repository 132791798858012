import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from "react-toastify";
import { CreateSendOutBatchRequest, MBSApi } from "./MBSApi";
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import queryKeys from "./queryKeys";

const useApiService = () => useInjection<IApiService>(ApiServiceType.IApiService);

export const useCreateSendOut = () => {
    const apiService = useApiService();
    const mbsApi = new MBSApi(apiService);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (request: CreateSendOutBatchRequest) => mbsApi.createSendOutBatch(request),
        onError: (err: any) => {
            // Error handling logic
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            // Invalidate queries on success
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
        },
    });
};
