import React, { useState, useEffect, FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import Spinner from "@murphy-frontend/web-core/components/Spinner";
import MBSResponseTable from "./MBSResponseTable";
import { getConvertedDate } from "@murphy-frontend/common/services/TimeService";
import { useGetSendOutResponses } from "../../../common/api/SendOutResponses/queries";
import { useUserPreferences } from "../../../common/contexts/UserPreferencesContext";
import { useModal } from "@murphy-frontend/web-core/contexts/ModalContext";
import { ModalConfiguration } from "@murphy-frontend/web-core/components/Modal";
import { useHub } from "../../../common/hooks/useHub";
import { createConnectionSendOut } from "../../../common/services/signalrService";
import { useClientMethod } from "../../../common/hooks/useClientMethod";
import queryKeys from "../../../common/api/SendOutResponses/queryKeys";
import { LogService } from "../../../common/services/LogService";
import { HubConnection, HubConnectionState } from "@microsoft/signalr";

const MBSItem: FunctionComponent = () => {
  const { id } = useParams();
  const { translations, timeZone } = useUserPreferences();
  const { openModal } = useModal();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [sendOutBatch, setSendOutBatch] = useState(null);
  const [responses, setResponses] = useState([]);
  const [message, setMessage] = useState({});
  const [alternatives, setAlternatives] = useState({});
  const queryClient = useQueryClient();
  const [hubConnection, setHubConnection] = useState<
    null | HubConnection | undefined
  >(null);

  const {
    data: sendOutResponsesData,
    isLoading: sendOutResponsesIsLoading,
    isError: sendOutResponsesIsError,
    error: sendOutResponsesError,
  } = useGetSendOutResponses(id);

  useEffect(() => {
    const signalRConnection = createConnectionSendOut(id);
    setHubConnection(signalRConnection);
  }, []);

  const { hubConnectionState, error: signalRError } = useHub(hubConnection);

  useClientMethod(hubConnection, id, (payload) => {
    queryClient.setQueriesData(
      {
        queryKey: queryKeys.list(id),
      },
      (oldData: any) => {
        const update = (record: any) => {
          const { sendOutResponseAnswers } = oldData;
          const newAnswers = sendOutResponseAnswers.map((ans) => {
            if (ans.SendOutResponseId === record.SendOutResponseId) {
              return {
                ...ans,
                responseAnswer: record.responseAnswer,
                responseSelectedAlternative: record.responseSelectedAlternative,
                dateansweredUtc: record.dateansweredUtc,
              };
            } else return ans;
          });
          return {
            ...oldData,
            sendOutResponseAnswers: newAnswers,
          };
        };
        return update(payload);
      },
    );
  });

  useEffect(() => {
    const loading =
      hubConnectionState !== HubConnectionState.Connected ||
      sendOutResponsesIsLoading;
    setIsLoading(loading);
  }, [hubConnectionState, sendOutResponsesIsLoading]);

  useEffect(() => {
    if (sendOutResponsesIsError) {
      console.log(sendOutResponsesIsError);
      throw new Error("SendOutResp error");
    }
    if (signalRError) {
      console.log(signalRError);
      const logService = new LogService();

      let errorMessage;
      if (typeof signalRError === "object") {
        errorMessage = JSON.stringify(signalRError);
      } else if (typeof signalRError === "string") {
        errorMessage = signalRError;
      } else {
        errorMessage = "Unexpected type of error";
      }

      const logError = new Error("SignalR error");
      logError.name = "SignalR error";
      logError.message = errorMessage;

      logService.error(logError);
      throw logError;
    }
  }, [sendOutResponsesIsError, signalRError]);

  const onGetSendOutResponsesBySendOutBatchIdSuccess = (data) => {
    const mappedResponses = data.sendOutResponseAnswers.map((p) => ({
      id: p.SendOutResponseId
        ? p.SendOutResponseId
        : Math.random().toString(36).substr(2, 5),
      dateAnswered: p.dateansweredUtc ? dayjs.utc(p.dateansweredUtc) : null,
      responseAnswer: p.responseAnswer,
      responseSelectedAlternative: p.responseSelectedAlternative,
      status: p.status,
      name: p.name,
    }));
    mappedResponses.sort((a, b) => b.dateAnswered - a.dateAnswered);
    const newMappedData = mappedResponses.map((p) => ({
      id: p.id,
      originalDate: p.dateAnswered,
      dateAnswered: p.dateAnswered
        ? getConvertedDate(p.dateAnswered, timeZone)
        : null,
      responseAnswer: p.responseAnswer,
      responseSelectedAlternative: p.responseSelectedAlternative,
      status: p.status,
      userName: p.name,
    }));
    setResponses(newMappedData);

    if (data.alternativ) {
      setAlternatives(data.alternativ);
    }

    if (data.message) {
      setMessage(data.message);
    }

    const mappedData = {
      originalDate: dayjs.utc(data.CreatedTimeUtc),
      date: getConvertedDate(dayjs.utc(data.CreatedTimeUtc), timeZone),
      status: data.status,
    };
    setSendOutBatch(mappedData);

    setIsLoading(false);
  };

  const ErrorMessage = () => <p>{error}</p>;

  useEffect(() => {
    if (error) {
      const modalConf: ModalConfiguration = {
        title: "Error",
        body: <ErrorMessage />,
        buttonText: "Ok",
        type: "error",
        okCallback: () => {
          setError("");
        },
      };
      openModal(modalConf);
    }
  }, [error]);

  useEffect(() => {
    if (sendOutResponsesIsError) {
      setError(sendOutResponsesError);
    }
    return () => {
      setError("");
    };
  }, [sendOutResponsesIsError, sendOutResponsesError]);

  useEffect(() => {
    const mappedData = responses.map((p) => ({
      ...p,
      dateAnswered: p.originalDate
        ? getConvertedDate(p.originalDate, timeZone)
        : null,
    }));
    setResponses(mappedData);
    if (sendOutBatch) {
      const mappedSendOutBatch = {
        ...sendOutBatch,
        date: sendOutBatch.originalDate
          ? getConvertedDate(sendOutBatch.originalDate, timeZone)
          : null,
      };
      setSendOutBatch(mappedSendOutBatch);
    }
  }, [timeZone]);

  useEffect(() => {
    if (!sendOutResponsesIsLoading) {
      onGetSendOutResponsesBySendOutBatchIdSuccess(sendOutResponsesData);
    }
  }, [sendOutResponsesIsLoading, sendOutResponsesData]);

  if (isLoading) {
    return (
      <div className="spinner-container-global-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="pbs-wrapper">
      <h1>{translations.sendout}</h1>
      <h3>{sendOutBatch ? sendOutBatch.date : null}</h3>

      <div className="pbs-grid">
        <span className="gradient" />
        <div>
          <fieldset className="form-fieldset response-form">
            <legend className="form-legend">
              {translations.messageinsendout}
            </legend>
            <b>{translations.title}:</b>
            <p>{message.Title}</p>
            <br />
            <b>{translations.message}:</b>
            <p>{message.Body}</p>
            <br />
            {message.Question ? (
              <>
                <b>{translations.question}:</b>
                <p>{message.Question}</p>
                <br />
              </>
            ) : null}
            {alternatives ? (
              <>
                <b>{translations.alternatives}:</b>
                <p>{alternatives.join(", ")}</p>
              </>
            ) : null}
          </fieldset>
        </div>
        <div className="table-container">
          <MBSResponseTable translations={translations} rows={responses} />
        </div>
      </div>
    </div>
  );
};

export default MBSItem;
