import React from "react";
import { FunctionComponent } from "react";
import ButtonV2 from "./ButtonV2";

import { BiSolidPyramid } from "react-icons/bi";
import { IconContext } from "react-icons/lib";


interface RequirementPyramidActionButtonsProps {
    onClickReset: () => void;
    isResetPressed: boolean;
}

const RequirementPyramidActionButtons: FunctionComponent<RequirementPyramidActionButtonsProps> = (
    {
        onClickReset,
        isResetPressed
    }
) => {


    const icon = <IconContext.Provider value={{ className: 'nav-bar-menu-icon' }}>
        <BiSolidPyramid />
    </IconContext.Provider>

    return (<div className="requirement-filters">
        <div className="pyramid-home">
            <ButtonV2
                contents={icon}
                isPressed={isResetPressed}
                onClick={onClickReset}
            />
        </div>
    </div>);
}

export default RequirementPyramidActionButtons;