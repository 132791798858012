import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useInjection } from '../../contexts/InversifyContext';
import UsersApiType, { CreateUserRequest, IUsersApi, UpdateUserRequest, UpsertUserOnboardingFlowRequest } from '../../interfaces/IUsersApi';
import QueryKeys from './QueryKeys';

export const updateUserMutation = (customerUserId: string, translations: Record<string, string>) => {
  const queryClient = useQueryClient();
  const usersApi = useInjection<IUsersApi>(UsersApiType.IUsersApi);

  return useMutation({
    mutationFn: (updateUserRequest: UpdateUserRequest) => usersApi.updateUser(customerUserId, updateUserRequest),
    onError: (err) => {
      if (err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.all });
      toast.success(`${translations.theuser} ${translations.updated}`);
    },
  });
};

export const useResetMfa = (customerUserId: string) => {
  // const queryClient = useQueryClient();
  const usersApi = useInjection<IUsersApi>(UsersApiType.IUsersApi);

  return useMutation({
    mutationFn: () => usersApi.resetMfa(customerUserId),
    onError: (err) => {
      if (err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      toast.success("MFA reset");
    },
  });
}


export const createUserMutation = (translations: Record<string, string>) => {
  const queryClient = useQueryClient();
  const usersApi = useInjection<IUsersApi>(UsersApiType.IUsersApi);

  return useMutation({
    mutationFn: (createUserRequest: CreateUserRequest) => usersApi.createUser(createUserRequest),
    onError: (err) => {
      if (err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.all });
      toast.success(`${translations.theuser} ${translations.created}`);
    },
  });
};


export const useDeleteUserMutation = (translations: Record<string, string>) => {
  const queryClient = useQueryClient();
  const usersApi = useInjection<IUsersApi>(UsersApiType.IUsersApi);

  return useMutation({
    mutationFn: (customerUserId: string) => usersApi.deleteUser(customerUserId),
    onError: (err) => {
      if (err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error deleting user');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.all });
      toast.success(`${translations.theuser} ${translations.deleted}`);
    },
  });
};


export const useGetUserProviderMutation = () => {
  const queryClient = useQueryClient();
  const usersApi = useInjection<IUsersApi>(UsersApiType.IUsersApi);

  return useMutation({
    mutationFn: (userName: string) => usersApi.getUserProvider(userName),
    onError: (err) => {
      console.log(err);
    },
    onSuccess: (data) => {
      console.log("provider fetched");
      // Optionally invalidate or refetch queries if needed
    },
  });
};

export const useUpsertOnBoardingFlowMutation = () => {
  const queryClient = useQueryClient();
  const usersApi = useInjection<IUsersApi>(UsersApiType.IUsersApi);

  return useMutation({
    mutationFn: (request: UpsertUserOnboardingFlowRequest) => usersApi.postUserOnboardingFlow(request),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.onboardingFlows() });
      console.log("onboarding flow updated")
    },
    onError: (err) => {
      console.log(err)
    },
  });
}
