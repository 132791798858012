import React, { FunctionComponent } from 'react';
import { IconContext } from 'react-icons/lib';
import { GiAutoRepair } from 'react-icons/gi';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import AuthServiceType, { IAuthService } from '@murphy-frontend/common/interfaces/IAuthService';

interface ErrorFallbackProps {
  error: Error,
  resetErrorBoundary: () => void
}

const ErrorFallback: FunctionComponent<ErrorFallbackProps> = ({ error, resetErrorBoundary }) => {

  const authService = useInjection<IAuthService>(AuthServiceType.IAuthService);
  const persistenceService = useInjection<IAuthService>(AuthServiceType.IAuthService);

  const clickLogout = () => {
    authService.logoutHard();
  };

  return (
    <div className="error-page" role="alert">
      <div>
        <IconContext.Provider value={{ className: 'error-page-icon' }}>
          <GiAutoRepair />
        </IconContext.Provider>
        <h1>Oops, an error has ocurred!</h1>
        <p><i>Anything that can go wrong, will go wrong..</i></p>
        <p><i>In this case the following went wrong: </i></p>
        <pre>{error.message}</pre>
        {/* <Button isIconButton isNeutralButton buttonStyle="btn--back" buttonSize="btn-with-icon" onClick={resetErrorBoundary} /> */}
        <button className="back-button" onClick={resetErrorBoundary}>
          <span>Go Back</span>
        </button>
        <button className="error-page-logout-button" onClick={clickLogout}>
          <span>LOG OUT</span>
        </button>
      </div>

    </div>
  );
}

export default ErrorFallback;