import * as React from "react";
import { DashboardEntityTypes } from "@murphy-frontend/common/enums";
import { getConvertedDateDayjs } from '@murphy-frontend/common/services/TimeService';
import { Party } from "../../../api/PartyApi";
import PartyExpandedContent from "../../Party/PartyExpandedContent";
import { MainLogCard } from "../models";
import { BaseCardProps } from "./MainLogCardFactory";

export interface PartyMainLogCardProps extends BaseCardProps {
    party: Party,
}

export const PartyMainLogCard =
    ({ translations, party, timeZone, dashboardId, openModal, closeModal }: PartyMainLogCardProps): MainLogCard => {
        const date = getConvertedDateDayjs(party.CreatedDateTimeUtc, timeZone);
        const expandedContent =
            <PartyExpandedContent
                party={party}
                dashboardId={dashboardId}
                openModal={openModal}
                closeModal={closeModal}
                translations={translations}
            />

        let title = translations['class-lang-title-infocard-group-updated'];
        if (party.action === "Created") {
            title = translations['class-lang-title-infocard-group-created'];
        }

        const searchableContent = [];
        searchableContent.push(title);
        if (party.PartyName)
            searchableContent.push(party.PartyName);
        if (party.createdby)
            searchableContent.push(party.createdby);



        const logCard: MainLogCard = {
            ID: `${party.ID}-${DashboardEntityTypes.Party}`,
            Title: title,
            isArchived: party.archived,
            isDecision: false,
            SearchableContent: searchableContent,
            User: party.createdby,
            Date: date,
            DateWithoutTime: date.format("YYYY-MM-DD"),
            Time: date.format("HH:mm"),
            EntityType: DashboardEntityTypes.Party,
            ExpandedContent: expandedContent,
            OriginalCard: party,
        }
        return logCard;
    }