import * as React from "react";
import { FunctionComponent } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import { RiMoreFill } from "react-icons/ri";
import Button from '@murphy-frontend/web-core/components/Button';
import ActionsButton from "@murphy-frontend/web-core/components/ActionsButton";
import UpsertPartyComponent from "./UpsertPartyComponent";

interface PartyTableHeaderProps {
    translations: Record<string, string>,
    dashboardId: string,
    openModal: any,
    closeModal: any,
    toggleSearch: () => void
}

const PartyTableHeader: FunctionComponent<PartyTableHeaderProps> =
    ({ translations, dashboardId, openModal, closeModal, toggleSearch }) => {

        const onClickCreateParty = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            event.preventDefault();
            const upsertPartyComponent =
                <UpsertPartyComponent translations={translations} dashboardId={dashboardId} onSuccess={() => closeModal()} />;
            const configuration = {
                centerContent: false,
                hideButton: true,
                body: upsertPartyComponent,
                title: translations['class-lang-edit-group'],
            }
            openModal(configuration);
        }
        const moreButtonIcon = <IconContext.Provider value={{ className: 'dashboard-table-actions-button-icon' }}>
            <RiMoreFill />
        </IconContext.Provider>;

        const searchIcon = <IconContext.Provider value={{ className: 'dashboard-table-action-status-icon' }}>
            <AiOutlineSearch />
        </IconContext.Provider>;

        const actionButtonItems = [];
        actionButtonItems.push(
            <li key="search" onClick={(e) => { toggleSearch() }}>
                <div className="vertical-aligner">
                    {searchIcon}
                    &nbsp;&nbsp;
                    <span>{translations['placeholder-lang-search-action']}</span>
                </div>
            </li>
        );

        const actionsButton = <ActionsButton actionButtonItems={actionButtonItems} icon={moreButtonIcon} pullLeft={true} />

        return <div className='dashboard-table-flex-header'>
            <div className="left"><div className='dashboard-table-title'><h2>{translations['class-lang-involved-actors']}</h2></div></div>
            <div className="right">
                <div className='controls'>
                    {actionsButton}
                    <Button buttonStyle={"btn--add"} isIconButton onClick={onClickCreateParty} />
                </div>
            </div>
        </div>
    }

export default PartyTableHeader;