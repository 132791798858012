import 'reflect-metadata';
import { Container, interfaces } from 'inversify';
import PersistenceType, {
	IPersistenceService,
} from '@murphy-frontend/common/interfaces/IPersistenceService';
import WebPersistenceService from './src/common/WebPersistenceService';
import ApiServiceType, {
	IApiService,
} from '@murphy-frontend/common/interfaces/IApiService';
import ApiService from '@murphy-frontend/common/services/ApiService';
import AuthServiceType, {
	IAuthService,
} from '@murphy-frontend/common/interfaces/IAuthService';
import { auth, azureB2C, baseURL, logToApi } from './src/common/variables';
import CustomersApiType, {
	ICustomersApi,
} from '@murphy-frontend/common/interfaces/ICustomersApi';
import { CustomersApi } from '@murphy-frontend/common/api/Customers/CustomersApi';
import UsersApiType, {
	IUsersApi,
} from '@murphy-frontend/common/interfaces/IUsersApi';
import { UsersApi } from '@murphy-frontend/common/api/Users/UsersApi';
import UsersTrainingSessionsApiType, {
	IUserTrainingSessionsApi,
} from './src/common/api/UserTrainingSessions/IUserTrainingSessionsApi';
import { UserTrainingSessionsApi } from './src/common/api/UserTrainingSessions/UserTrainingSessionsApi';
import ContactGroupApiType, {
	IContactGroupApi,
} from '@murphy-frontend/common/interfaces/IContactGroupApi';
import { ContactGroupApi } from '@murphy-frontend/common/api/ContactGroups/ContactGroupApi';

import ContactsApiType, { IContactsApi } from '@murphy-frontend/common/interfaces/IContactsApi';
import { ContactsApi } from '@murphy-frontend/common/api/Contacts/ContactsApi';

import TrainingApiType, {
	TrainingApi,
} from './src/common/api/Training/TrainingApi';
import TrainingSessionApiType, {
	TrainingSessionApi,
} from './src/common/api/TrainingSessions/TrainingSessionsApi';
import { WebStorageStateStore } from 'oidc-client-ts';
import DashboardApiType, {
	IDashboardApi,
} from '@murphy-frontend/common/interfaces/IDashboardApi';
import { DashboardApi } from '@murphy-frontend/common/api/Dashboard/DashboardApi';
import FeatureServiceType, { IFeatureService } from '@murphy-frontend/common/interfaces/IFeatureService';
import WebFeatureService from './src/common/services/WebFeatureService';
import { AuthServiceProps, WebAuthService } from '@murphy-frontend/web-core/features/auth/WebAuthService';
import { TokenRefreshManager } from '@murphy-frontend/common/services/TokenRefreshManager';
import LogServiceType, {
	ILogService,
} from '@murphy-frontend/web-core/services/LogService/ILogService';
import { AppInsightsLogService } from '@murphy-frontend/web-core/services/LogService/AppInsightsLogService';
import { ApiLogService } from '@murphy-frontend/web-core/services/LogService/ApiLogService';
import PreferencesApiType, { IPreferencesApi } from '@murphy-frontend/common/interfaces/IPreferencesApi';
import { PreferencesApi } from '@murphy-frontend/common/api/Preferences/PreferencesApi';
import { DroneEventsApi } from '@murphy-frontend/common/api/DroneEvents/DroneEventsApi';
import DroneEventsApiType, {
	IDroneEventsApi,
} from '@murphy-frontend/common/interfaces/IDroneEventsApi';


var container = new Container();

// Bind TokenRefreshManager as a singleton
container.bind<TokenRefreshManager>(TokenRefreshManager).to(TokenRefreshManager).inSingletonScope();

if (logToApi !== 'true') {
	container.bind<ILogService>(LogServiceType.ILogService).to(AppInsightsLogService);
} else {
	container.bind<ILogService>(LogServiceType.ILogService).to(ApiLogService);
}


container
	.bind<IPersistenceService>(PersistenceType.IPersistenceService)
	.to(WebPersistenceService);

container
	.bind<IAuthService>(AuthServiceType.IAuthService)
	.toDynamicValue((context: interfaces.Context) => {
		const persistenceService = context.container.get<IPersistenceService>(
			PersistenceType.IPersistenceService,
		);
		const authServiceProps: AuthServiceProps = {
			baseAuthServerUrl: auth.baseAuthServerUrl,
			redirectUrl: auth.redirectUrl,
			postLogoutUrl: auth.postLogoutUrl,
			clientId: auth.clientId,
			forgotPassClientId: azureB2C.clientId,
			forgotPassTenantName: azureB2C.tenantName,
			forgotPassTenantId: azureB2C.tenantId,
			forgotPassPolicyName: azureB2C.resetPasswordPolicyName,
			changePassPolicyName: azureB2C.changePasswordPolicyName,
			automaticSilentRenew: true,
			stateStore: new WebStorageStateStore({
				store: window.localStorage,
			}),
			enableChangePassword: auth.showResetPassword === 'true',
			enableForgotPassword: auth.showForgotPassword === 'true'
		};
		return new WebAuthService(authServiceProps, persistenceService);
	})
	.inSingletonScope();

container
	.bind<IFeatureService>(FeatureServiceType.IFeatureService)
	.toDynamicValue((context: interfaces.Context) => {
		const persistenceService = context.container.get<IPersistenceService>(
			PersistenceType.IPersistenceService,
		);
		return new WebFeatureService(persistenceService);
	});

container
	.bind<IApiService>(ApiServiceType.IApiService)
	.toDynamicValue((context: interfaces.Context) => {
		const authService = context.container.get<IAuthService>(
			AuthServiceType.IAuthService,
		);
		const tokenRefreshManager = container.get<TokenRefreshManager>(TokenRefreshManager);
		return new ApiService(authService, baseURL, tokenRefreshManager);
	});

container
	.bind<ICustomersApi>(CustomersApiType.ICustomersApi)
	.toDynamicValue((context: interfaces.Context) => {
		const apiService = context.container.get<IApiService>(
			ApiServiceType.IApiService,
		);
		return new CustomersApi(apiService);
	});

container
	.bind<IUsersApi>(UsersApiType.IUsersApi)
	.toDynamicValue((context: interfaces.Context) => {
		const apiService = context.container.get<IApiService>(
			ApiServiceType.IApiService,
		);
		return new UsersApi(apiService);
	});

container
	.bind<IUserTrainingSessionsApi>(
		UsersTrainingSessionsApiType.IUsersTrainingSessionsApi,
	)
	.toDynamicValue((context: interfaces.Context) => {
		const apiService = context.container.get<IApiService>(
			ApiServiceType.IApiService,
		);
		return new UserTrainingSessionsApi(apiService);
	});

container
	.bind<IContactGroupApi>(ContactGroupApiType.IContactGroupApi)
	.toDynamicValue((context: interfaces.Context) => {
		const apiService = context.container.get<IApiService>(
			ApiServiceType.IApiService,
		);
		return new ContactGroupApi(apiService);
	});

container
	.bind<IContactsApi>(ContactsApiType.IContactsApi)
	.toDynamicValue((context: interfaces.Context) => {
		const apiService = context.container.get<IApiService>(
			ApiServiceType.IApiService,
		);
		return new ContactsApi(apiService);
	});

container
	.bind<TrainingApi>(TrainingApiType.TrainingApiType)
	.toDynamicValue((context: interfaces.Context) => {
		const apiService = context.container.get<IApiService>(
			ApiServiceType.IApiService,
		);
		return new TrainingApi(apiService);
	});

container
	.bind<TrainingSessionApi>(TrainingSessionApiType.TrainingSessionApi)
	.toDynamicValue((context: interfaces.Context) => {
		const apiService = context.container.get<IApiService>(
			ApiServiceType.IApiService,
		);
		return new TrainingSessionApi(apiService);
	});

container
	.bind<IDashboardApi>(DashboardApiType.IDashboardApi)
	.toDynamicValue((context: interfaces.Context) => {
		const apiService = context.container.get<IApiService>(
			ApiServiceType.IApiService,
		);
		return new DashboardApi(apiService);
	});

container
	.bind<IPreferencesApi>(PreferencesApiType.IPreferencesApi)
	.toDynamicValue((context: interfaces.Context) => {
		const apiService = context.container.get<IApiService>(
			ApiServiceType.IApiService,
		);
		return new PreferencesApi(apiService);
	});

container
	.bind<IDroneEventsApi>(DroneEventsApiType.IDroneEventsApi)
	.toDynamicValue((context: interfaces.Context) => {
		const apiService = context.container.get<IApiService>(
			ApiServiceType.IApiService,
		);
		return new DroneEventsApi(apiService);
	});

export default container;
