import { FunctionComponent, useState } from "react";
import * as React from 'react'
import { MainLogCard } from "./models";
import ExpandableRow from "@murphy-frontend/web-core/components/DashboardTable/ExpandableRow";
import { DashboardEntityTypes } from "@murphy-frontend/common/enums";
import StatusIcon from "../StatusCards/StatusIcon";
import ActionsStatusIcon from "../ActionsCards/ActionsStatusIcon";
import { StatusCardStatusService } from "../StatusCards/StatusCardStatusService";
import { IconContext } from 'react-icons';
import { FaLink, FaArrowDown, FaArrowUp } from 'react-icons/fa';

interface MainLogRowProps {
    mainLogCard: MainLogCard,
    translations: Record<string, string>,
    statusCardStatusService: StatusCardStatusService,
}

const MainLogRow: FunctionComponent<MainLogRowProps> = ({ mainLogCard, translations, statusCardStatusService }) => {
    const addedHeaderClasses = [];
    const addedExpandedContentClasses = [];
    if (mainLogCard.ShowDateHeader) {
        addedHeaderClasses.push("dashboard-table-row-with-date");
    }

    if (mainLogCard.isArchived) {
        addedHeaderClasses.push("dashboard-table-row-archived");
        addedExpandedContentClasses.push("dashboard-table-row-archived");
    }

    let hierarchyIcons = null;
    if (mainLogCard.isParent || mainLogCard.isChild) {
        const classes = [];
        classes.push('dashboard-table-icon');
        classes.push('disable');
        const hierarchyIcon = <IconContext.Provider value={{ className: classes.join(' ') }}>
            <FaLink />
        </IconContext.Provider>

        if (mainLogCard.isParent) {
            hierarchyIcons = <div className='left-icon-group'>
                {hierarchyIcon}
                <IconContext.Provider value={{ className: classes.join(' ') }}>
                    <FaArrowUp />
                </IconContext.Provider>
            </div>
        }

        if (mainLogCard.isChild) {
            hierarchyIcons = <div className='left-icon-group'>
                {hierarchyIcon}
                <IconContext.Provider value={{ className: classes.join(' ') }}>
                    <FaArrowDown />
                </IconContext.Provider>
            </div>
        }
    }

    let icon;

    switch (mainLogCard.EntityType) {
        case DashboardEntityTypes.InformationCard:
            icon = <span className="timeline-lable info">{translations['class-lang-tag-info']}</span>
            break;
        case DashboardEntityTypes.ActionsCard:
            icon = <div className="vertical-aligner horizontal-aligner">
                <ActionsStatusIcon status={mainLogCard.Status} disabled={true} />
                <span className="timeline-lable atgarder">{translations['class-lang-tag-action']}</span>
            </div>
            break;
        case DashboardEntityTypes.StatusCard:
            icon = <div className="vertical-aligner horizontal-aligner left-icon-group">
                {hierarchyIcons}
                <StatusIcon statusCardStatusService={statusCardStatusService} statusId={mainLogCard.Status} disabled={true} />
                <span className="timeline-lable status">{translations['class-lang-tag-status']}</span>
            </div>
            break;
        case DashboardEntityTypes.Goals:
            icon = <span className="timeline-lable goal">{translations['class-lang-tag-target']}</span>
            break;
        case DashboardEntityTypes.Assumption:
            icon = <span className="timeline-lable kommunikation">{translations['class-lang-tag-assumption']}</span>
            break;
        case DashboardEntityTypes.BackGround:
            icon = <span className="timeline-lable kommunikation">{translations['class-lang-tag-background']}</span>
            break;
        case DashboardEntityTypes.DashboardFile:
            icon = <span className="timeline-lable file">{translations['class-lang-tag-file']}</span>
            break;
        case DashboardEntityTypes.DashboardLog:
            icon = <span className="timeline-lable file">LOG</span>
            break;
        case DashboardEntityTypes.Party:
            icon = <span className="timeline-lable file">{translations['class-lang-tag-actor']}</span>
            break;
        case DashboardEntityTypes.PartyPeople:
            icon = <span className="timeline-lable file">{translations['class-lang-tag-actor']}</span>
            break;
    }

    const header = <>
        <div className="dashboard-table-row-text">
            <b>{mainLogCard.Time}</b>&nbsp;{mainLogCard.Title}
        </div>
        <div className="right-icon">
            {icon}
        </div>
        {mainLogCard.ShowDateHeader &&
            <div className="dashboard-table-row-date">
                {mainLogCard.DateWithoutTime}
            </div>}
    </>

    return (<>
        <ExpandableRow
            addHeaderClasses={addedHeaderClasses}
            headerContent={header}
            addExpandedContentClasses={addedExpandedContentClasses}
            expandedContent={mainLogCard.ExpandedContent} />
    </>)
}

export default MainLogRow;