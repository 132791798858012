import parse from 'html-react-parser';
import ResponsivePlayer from './ResponsivePlayer';
import React from 'react';

export const parseHtml = (str: string) => {
    return parse(str, {
        replace: (node) => {
            if (
                node.parent &&
                node.parent.name &&
                node.parent.name === "a" &&
                node.parent.attribs &&
                node.parent.attribs.href
            ) {
                const { href } = node.parent.attribs;
                const youtubeRegex =
                    /^(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:(?:youtube\.com|youtu.be))(?:\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(?:\S+)?$/;
                const vimeoRegex =
                    /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/;
                const youtubeMatches = href.match(youtubeRegex);
                const vimeoMatches = href.match(vimeoRegex);
                if (vimeoMatches || youtubeMatches) {
                    return <ResponsivePlayer url={href} />;
                }
            }
        },
    });
}