import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { IconContext } from "react-icons/lib";
import { RiPencilFill } from "react-icons/ri";
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import TabPanel, { TabType } from '@murphy-frontend/web-core/components/Tabs/TabPanel';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { GoalTypes } from "@murphy-frontend/common/enums";
import { Goals } from "../../api/GoalsApi";
import { useGetGoals } from "../../queries";
import UpsertGoalsComponent from "./UpsertGoalsComponent";
import { parseHtmlAndLinkifySafe } from "@murphy-frontend/common/utils";

interface GoalsTabsProps {
    dashboardId: string,
    translations: Record<string, string>,
}

const GoalsTabs: FunctionComponent<GoalsTabsProps> = ({ dashboardId, translations }) => {
    const [tabs, setTabs] = useState<TabType[]>([]);
    const [comGoal, setComGoal] = useState<Goals>(null);
    const [mainGoal, setMainGoal] = useState<Goals>(null);
    const [selectedTabId, setSelectedTabId] = useState<number>(1);
    const onClickTab = (tabId: number) => {
        setSelectedTabId(tabId);
    };

    const {
        isLoading: goalsIsLoading, isError: goalsIsError, data: goalsData, error: goalsError,
    } = useGetGoals(dashboardId)

    const { openModal, closeModal } = useModal();

    const onClickEditGoals = (goalType: number, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        let upsertGoalsComponent = null;
        if (goalType === GoalTypes.ComGoal) {
            upsertGoalsComponent = <UpsertGoalsComponent goalType={GoalTypes.ComGoal} dashboardId={dashboardId} translations={translations} goals={comGoal} onSuccess={() => closeModal()} />
        }
        if (goalType === GoalTypes.MainGoal) {
            upsertGoalsComponent = <UpsertGoalsComponent goalType={GoalTypes.MainGoal} dashboardId={dashboardId} translations={translations} goals={mainGoal} onSuccess={() => closeModal()} />
        }

        const title = goalType === GoalTypes.ComGoal ? translations['lang-com-goal'] : translations['lang-goal-title'];

        const configuration = {
            centerContent: false,
            hideButton: true,
            body: upsertGoalsComponent,
            title: title,
        }
        openModal(configuration);
    }


    useEffect(() => {
        if (!goalsIsLoading) {
            const mainGoalData = goalsData.find(p => p.goalType == GoalTypes.MainGoal && p.archived === false);
            const comGoalData = goalsData.find(p => p.goalType == GoalTypes.ComGoal && p.archived === false);
            if (mainGoalData) {
                setMainGoal(mainGoalData);
            }
            if (comGoalData) {
                setComGoal(comGoalData);
            }
        }

    }, [goalsData, goalsIsLoading])

    const setInitialTabs = () => {
        const initialTabs: Array<TabType> = [
            {
                id: 1,
                displayText: translations['lang-goal-title'] as string,
                customClass: 'tab-solid-panel-tab',
                customSelectedClass: 'tab-solid-panel-tab-selected wrap-text',
            },
            {
                id: 2,
                displayText: translations['lang-com-goal'] as string,
                customClass: 'tab-solid-panel-tab',
                customSelectedClass: 'tab-solid-panel-tab-selected',
            },
        ];
        setTabs(initialTabs);
    };

    useEffect(() => {
        if (translations) {
            setInitialTabs();
        }
    }, [translations]);

    if (goalsIsLoading === true) {
        return (<div className="spinner-container-global-center"><Spinner /></div>);
    }

    return (<div>
        <TabPanel tabs={tabs} onClickTab={onClickTab} selectedTabId={selectedTabId} customClass='tab-solid-panel' />
        {selectedTabId === 1 && <div className='goals-tab-content tab-solid-panel-tab-selected'>
            <div className='tab-content-text'>
                {mainGoal && mainGoal.message && parseHtmlAndLinkifySafe(mainGoal.message)}
            </div>
            <div className="goals-edit-item-button-container">
                <div className="goals-edit-item-button" onClick={(e) => { onClickEditGoals(GoalTypes.MainGoal, e) }}>
                    <IconContext.Provider value={{ className: 'goals-edit-item-icon' }}>
                        <RiPencilFill />
                    </IconContext.Provider>
                </div>
            </div>
        </div>}
        {selectedTabId === 2 && <div className='goals-tab-content tab-solid-panel-tab-selected'>
            <div className='tab-content-text'>
                {comGoal && comGoal.message && parseHtmlAndLinkifySafe(comGoal.message)}
            </div>
            <div className="goals-edit-item-button-container">
                <div className="goals-edit-item-button" onClick={(e) => { onClickEditGoals(GoalTypes.ComGoal, e) }}>
                    <IconContext.Provider value={{ className: 'goals-edit-item-icon' }}>
                        <RiPencilFill />
                    </IconContext.Provider>
                </div>
            </div>
        </div>}
    </div>);
}

export default GoalsTabs;