import React, { useState, useEffect, FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { groupBy } from '@murphy-frontend/common/utils';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import CourseCarousel, {
	CourseCarouselViewModel,
} from '../../common/components/TrainingComponents/CourseCarousel';
import SearchBar from '@murphy-frontend/web-core/components/SearchBar';
import {
	CustomerUserTrainingSessionStates,
	TrainingTypes,
} from '../../../../common/enums';
import {
	courseLanguages,
	customerUserTrainingSessionStates,
} from '@murphy-frontend/web-core/constants';
import { translateConstants } from '../../common/services/translationService';
import { useGetTrainingSessions } from '../../common/api/TrainingSessions/queries';
import { useGetTrainings } from '../../common/api/Training/queries';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';
import {
	useUserPreferences,
} from '../../common/contexts/UserPreferencesContext';
import { TimeZoneType, getConvertedDate } from "@murphy-frontend/common/services/TimeService"
import useWindowDimensions from "@murphy-frontend/web-core/hooks/useWindowDimensions";
import { useCustomer } from '../../common/contexts/CustomerContext';
import { useUser } from '../../common/contexts/UserContext';
import { TrainingModel } from '../../common/api/Training/TrainingApi';
import { TrainingSessionModel } from '../../common/api/TrainingSessions/TrainingSessionsApi';
import { Constant } from '../../common/commonInterfaces';
import FilterControl, { FilterType } from '@murphy-frontend/web-core/components/FilterControl';
import { UserTrainingSession } from '../../common/api/UserTrainingSessions/IUserTrainingSessionsApi';
import { useGetUserTrainingSessionsByCustomerUserId } from '../../common/api/UserTrainingSessions/queries';
import CourseRegistrationOnboardingSteps from './CourseRegistrationOnboardingSteps';
import { OnboardingFlowIds, useOnboarding } from '../../common/contexts/OnboardingContext';
import { parseHtml } from '@murphy-frontend/web-core/features/RichText/helpers';
import RichTextDisplay from '@murphy-frontend/web-core/features/RichText/RichTextDisplay';
import InfoIcon from '@murphy-frontend/web-core/components/InfoIcon';
import Button from '@murphy-frontend/web-core/components/Button';

const CoursePortal: FunctionComponent = () => {
	const { openModal, closeModal } = useModal();
	const {
		translations,
		timeZone,
		language,
		selectedCourseLanguages,
		setSelectedCourseLanguages,
	} = useUserPreferences();

	const { currentSize } = useWindowDimensions();

	const navigate = useNavigate();
	const { customer } = useCustomer();
	const { user } = useUser();

	const [error, setError] = useState(null);

	const [groupedTrainingSessions, setGroupedTrainingSessions] = useState<
		GroupedTrainingSession[]
	>([]);
	const [trainings, setTrainings] = useState<TrainingModel[]>([]);
	const [customerUserTrainingSessions, setCustomerUserTrainingSessions] =
		useState<UserTrainingSession[]>([]);
	const [courseViews, setCourseViews] = useState<CourseCarouselViewModel[]>(
		[],
	);
	const [userSessionStates, setUserSessionStates] = useState<Constant[]>([]);

	const [filters, setFilters] = useState<FilterType[]>([]);
	const [markAllIsToggled, setMarkAllIsToggled] = useState<boolean>(true);

	const [filteredCourseViews, setFilteredCourseViews] = useState([]);
	const [currentSearchText, setCurrentSearchText] = useState('');

	const [stepsEnabled, setStepsEnabled] = useState<boolean>(false);
	const [onboardingTerminated, setOnboardingTerminated] = useState<boolean>(null);
	const { setOnboardingIdIsComplete, incompletedOnboardingFlows, isLoading: onBoardingIsLoading, resetOnboarding, setCurrentStep, onboardingsCurrentStep } = useOnboarding();

	const {
		isLoading: trainingSessionsIsLoading,
		isError: trainingSessionsIsError,
		data: trainingSessionsData,
		error: trainingSessionsError,
	} = useGetTrainingSessions({ customerId: customer.Id });

	const {
		isLoading: trainingsIsLoading,
		isError: trainingsIsError,
		data: trainingsData,
		error: trainingsError,
	} = useGetTrainings({
		customerUserId: user.CustomerUserId,
	});

	const {
		isLoading: customerUserTrainingSessionsIsLoading,
		isError: customerUserTrainingSessionsIsError,
		data: customerUserTrainingSessionsData,
		error: customerUserTrainingSessionsError,
	} = useGetUserTrainingSessionsByCustomerUserId(user.CustomerUserId);

	interface GroupedTrainingSession {
		Id: number;
		Name: string;
		Description: string;
		trainingSessions: TrainingSessionModel[];
	}

	const onToggleMarkAllFilters = () => {
		const newAllRows = filters.map((p) => {
			return { ...p, isActive: !markAllIsToggled };
		});

		setFilters(newAllRows);
		setMarkAllIsToggled(!markAllIsToggled);
		syncSelectedCourseLanguages(newAllRows);
	};

	const onToggleFilter = (filt: FilterType) => {
		const updatedFilters = filters.map((filter) => {
			const updatedItem = { ...filter };
			if (
				filter.columnname === filt.columnname &&
				filter.filtervalue === filt.filtervalue
			) {
				updatedItem.isActive = !filter.isActive;
			}
			return updatedItem;
		});

		if (updatedFilters.every((p) => p.isActive)) {
			setMarkAllIsToggled(true);
		} else {
			setMarkAllIsToggled(false);
		}
		setFilters(updatedFilters);
		syncSelectedCourseLanguages(updatedFilters);
	};

	const onResetFilters = () => {
		const initFilters = generateInitialFilters();
		setFilters(initFilters);
		syncSelectedCourseLanguages(initFilters);
	};

	const generateInitialFilters = () => {
		const mappedLanguages = courseLanguages.map((p) => {
			return {
				displayName: p.value,
				columnname: 'language',
				filtervalue: p.id,
				isActive: selectedCourseLanguages.includes(p.id),
			};
		});

		const allPossibleFilters = [...mappedLanguages];
		return allPossibleFilters;
	};

	const syncSelectedCourseLanguages = (filters: FilterType[]) => {
		const languageFilters = filters
			.filter((p) => p.columnname === 'language' && p.isActive === true)
			.map((p) => p.filtervalue);
		setSelectedCourseLanguages(languageFilters);
	};

	useEffect(() => {
		if (translations) {
			const initFilters = generateInitialFilters();
			setFilters(initFilters);
		}
	}, [translations, selectedCourseLanguages]);

	const convertLanguage = (id?: number) => {
		switch (id) {
			case 1:
				return 'swe';
			case 2:
				return 'eng';
			case 3:
				return 'nor';
			default:
				return 'swe';
		}
	};

	const createCourseViews = (
		trainingsRaw: TrainingModel[],
		groupedTrainingSessionsRaw: GroupedTrainingSession[],
		customerUserTrainingSessionsRaw: UserTrainingSession[],
		states: Constant[],
		tz: TimeZoneType,
	): CourseCarouselViewModel[] => {
		const newCourseViews = groupedTrainingSessionsRaw
			.filter(p => trainingsRaw.some(q => q.Id === p.Id))
			.map((p) => {
				const matchingCustomerUserTrainingSessions =
					customerUserTrainingSessionsRaw.filter(
						(q) =>
							q.TrainingId === p.Id &&
							q.TrainingSessionStateId !==
							CustomerUserTrainingSessionStates.Cancelled,
					);

				const matchingTraining = trainingsRaw.find(
					(q) => q.Id === p.Id,
				);
				const sortedTrainingSessions = [
					...p.trainingSessions.sort(
						(a, b) =>
							new Date(a.StartDateTimeUtc).valueOf() -
							new Date(b.StartDateTimeUtc).valueOf(),
					),
				];

				if (!matchingTraining) {
					return null;
				}

				const courseView: CourseCarouselViewModel = {
					id: p.Id,
					trainingTypeId: matchingTraining.TrainingTypeId,
					name: matchingTraining.Name,
					description: matchingTraining.Description,
					trainingSessions: sortedTrainingSessions,
					backGroundUrl: matchingTraining.ImageUrl,
					onClick: () => navigate(`/training/${p.Id}`),
					userTrainingSessions: [],
					language: convertLanguage(matchingTraining.TrainingLanguageId),
				};

				if (matchingCustomerUserTrainingSessions.length > 0) {
					courseView.userTrainingSessions =
						matchingCustomerUserTrainingSessions.map((r) => ({
							userTrainingSessionId: r.ID,
							trainingSessionStateId: r.TrainingSessionStateId,
							urlToMeeting: r.UrlToMeeting,
							trainingSessionStateName: states.filter(
								(q) => q.id === r.TrainingSessionStateId,
							)[0].value,
							trainingSessionStartDate: getConvertedDate(
								dayjs.utc(r.StartDateTimeUtc),
								tz,
							),
							trainingSessionEndDate: getConvertedDate(
								dayjs.utc(r.EndDateTimeUtc),
								tz,
							),
						}));
				}

				return courseView;
			})
			.filter(courseView => courseView !== null);;
		return newCourseViews;
	};

	const groupTrainingSessions = (
		sessions: TrainingSessionModel[],
	): GroupedTrainingSession[] => {
		const newGroupedTrainingSessions = groupBy(sessions, 'TrainingId');
		const mappedCourses = Object.values(newGroupedTrainingSessions).map(
			(trainingSessions: TrainingSessionModel[]) => ({
				Id: trainingSessions[0].TrainingId,
				Name: trainingSessions[0].Name,
				Description: trainingSessions[0].Desc,
				trainingSessions,
			}),
		);

		return mappedCourses;
	};

	useEffect(() => {
		const translatedUserSessionStates = translateConstants(
			language,
			customerUserTrainingSessionStates,
		);
		setUserSessionStates(translatedUserSessionStates);
		return () => {
			setUserSessionStates([]);
		};
	}, [language]);

	useEffect(() => {
		if (!trainingsIsLoading) {
			setTrainings(trainingsData);
		}
		return () => {
			setTrainings([]);
		};
	}, [trainingsData, trainingsIsLoading]);

	useEffect(() => {
		if (!trainingSessionsIsLoading) {
			const filteredBookableTrainingSessions =
				trainingSessionsData.filter(
					(p) => dayjs.utc(p.StartDateTimeUtc) > dayjs.utc(),
				);
			const mappedTrainingsSessions = groupTrainingSessions(
				filteredBookableTrainingSessions,
			);
			setGroupedTrainingSessions(mappedTrainingsSessions);
		}
	}, [trainingSessionsData, trainingSessionsIsLoading]);

	useEffect(() => {
		if (!customerUserTrainingSessionsIsLoading) {
			setCustomerUserTrainingSessions(customerUserTrainingSessionsData);
		}
	}, [
		customerUserTrainingSessionsData,
		customerUserTrainingSessionsIsLoading,
	]);

	useEffect(() => {
		if (
			groupedTrainingSessions &&
			userSessionStates.length > 0 &&
			timeZone &&
			trainings.length > 0
		) {
			const newCourseViews = createCourseViews(
				trainings,
				groupedTrainingSessions,
				customerUserTrainingSessions,
				userSessionStates,
				timeZone,
			);
			const filteredCourseViews = newCourseViews.filter(
				(p) => p.trainingTypeId === TrainingTypes.Course,
			);
			setCourseViews(filteredCourseViews);
		}
	}, [
		customerUserTrainingSessions,
		groupedTrainingSessions,
		userSessionStates,
		trainings,
		timeZone,
	]);

	useEffect(() => {
		let courseViewsCopy = [...courseViews];
		if (courseViews) {
			if (currentSearchText) {
				const searchValue = currentSearchText.toUpperCase();
				courseViewsCopy = courseViewsCopy.filter((p) =>
					p.name.toUpperCase().startsWith(searchValue),
				);
			}
		}
		if (filters) {
			let rowsWithActiveFilters: CourseCarouselViewModel[] = [];
			for (let i = 0; i < filters.length; i++) {
				const currentFilter = filters[i];
				if (currentFilter.isActive === true) {
					const currentFilterMatchingRows = courseViewsCopy.filter(
						(recipient: any) => {
							if (
								recipient[currentFilter.columnname] ===
								currentFilter.filtervalue
							) {
								return recipient;
							}
						},
					);
					rowsWithActiveFilters = rowsWithActiveFilters.concat(
						currentFilterMatchingRows,
					);
				}
			}
			courseViewsCopy = rowsWithActiveFilters;
		}
		setFilteredCourseViews(courseViewsCopy);
	}, [courseViews, filters, currentSearchText]);

	function ErrorMessage() {
		return <p>{error}</p>;
	}

	useEffect(() => {
		if (error) {
			const modalConf: ModalConfiguration = {
				title: 'Error',
				body: <ErrorMessage />,
				buttonText: 'Ok',
				type: 'error',
				okCallback: () => {
					setError(null);
				},
			};

			openModal(modalConf);
		}
	}, [error]);

	useEffect(() => {
		if (onboardingTerminated === true) {
			return;
		}
		const shouldShowOnboarding = incompletedOnboardingFlows.map(p => p.OnboardingFlowId).includes(OnboardingFlowIds.Academy);
		if (shouldShowOnboarding && !onBoardingIsLoading) {
			const currentStep = onboardingsCurrentStep.get(OnboardingFlowIds.Academy);
			if (currentStep === 0) {
				const stepsFromApi = incompletedOnboardingFlows.find(p => p.OnboardingFlowId === OnboardingFlowIds.Academy)?.StepsHtmlContents;
				const introText = stepsFromApi.get(1);
				const parsedHtmlIntro = introText ? parseHtml(introText) : "";
				const onClickOk = () => {
					setCurrentStep(OnboardingFlowIds.Academy, 1);
					closeModal();
					setStepsEnabled(true);
				}

				const onClickClose = () => {
					setOnboardingIsComplete();
				}

				const modalConf: ModalConfiguration = {
					okCallback: onClickOk,
					onCloseModal: onClickClose,
					type: 'info',
					centerContent: true,
					body: <div className="intro-outro-container">
						<RichTextDisplay content={parsedHtmlIntro} />
					</div>,
					title: "Tutorial",
					buttonText: "Start",
					position: 'center'
				}
				openModal(modalConf);
			}
			else {
				setStepsEnabled(true);
			}
		}
	}, [incompletedOnboardingFlows, onBoardingIsLoading, onboardingsCurrentStep]);

	const onSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		setCurrentSearchText(newValue);
	};

	const handleOnboardingComplete = () => {
		setOnboardingIsComplete();
	};

	const setOnboardingIsComplete = () => {
		setOnboardingTerminated(true);
		setOnboardingIdIsComplete(OnboardingFlowIds.Academy);
		setCurrentStep(OnboardingFlowIds.Academy, 0);
		setStepsEnabled(false);
	}

	if (
		trainingSessionsIsLoading ||
		trainingsIsLoading ||
		customerUserTrainingSessionsIsLoading
	) {
		return (
			<div className='spinner-container-global-center'>
				<Spinner />
			</div>
		);
	}

	const onClickStartOnboarding = () => {
		setOnboardingTerminated(false);
		resetOnboarding(OnboardingFlowIds.Academy);
	}

	const stepsComponent = <CourseRegistrationOnboardingSteps
		stepsEnabled={stepsEnabled}
		onComplete={handleOnboardingComplete}
		setStepsEnabled={setStepsEnabled} />

	const stepsIcon = <InfoIcon
		place={"right-end"}
		onHoverContents={<Button onClick={onClickStartOnboarding}>Start tutorial</Button>}
		clickable={true}
	/>

	return (
		<div className='generalwrapper'>
			{stepsEnabled && stepsComponent}
			<section className='header-container vertical-aligner'>
				<div className='one'>
					<FilterControl
						allFilters={filters}
						onToggleFilter={onToggleFilter}
						showReset={false}
						onClickResetFilters={onResetFilters}
						translations={translations}
						showMarkAll={true}
						onToggleMarkAll={onToggleMarkAllFilters}
						markAllIsToggled={markAllIsToggled}
					/>
				</div>
				<div className='three'>
					<SearchBar
						val={currentSearchText}
						handleChange={onSearchChanged}
						placeholder={
							translations['placeholder-lang-search-action']
						}
					/>
				</div>
			</section>
			<section className='user-table-container'>
				<div className='one header-with-info-button'>
					<h4>{translations.coursesmodulename}</h4>
					{stepsIcon}
				</div>
				<div className='course-registration-container'>
					<CourseCarousel
						translations={translations}
						courseViews={filteredCourseViews}
						currentScreenSize={currentSize}
						timeZone={timeZone}
						language={language}
					/>
				</div>
			</section>
		</div>
	);
};

export default CoursePortal;
