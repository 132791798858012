module.exports = {
	baseURL: 'https://murphyapi-prod-appservice-z7pvp4nvgy6ow.azurewebsites.net',
	imgPath: 'dist/img',
	cookiePath: '/app',
	gtagId: 'G-CZ429C2599',
	maxFileSizeMB: 20,
	appInsightsInstrumentationKey: '4a8fd2c0-b78d-40ff-848d-059bc6356a3e',
	logToApi: 'false',
	enabledFeatures: {
		upsertUser: 'true',
		addUserFromExistingAccountMode: 'false',
		user: {
			disabledFields: {
				email: 'false',
				username: 'false',
				role: 'false',
				permission: 'false',
				phonenumber: 'false',
			},
			disableUsernameValidation: 'false',
		},
		mbs: {
			disableSMS: 'false',
			disableEmail: 'false',
			disableNotification: 'false',
		},
	},
	enabledProducts: {
		white: 'true',
		mbs: 'true',
		training: 'true',
		exercise: 'true',
	},
	includeOnlyPublicRoutes: 'false',
	dashboardSettings: {
		statusCardStatusMode: 'default',
	},
	azureB2C: {
		loginPolicyName: 'B2C_1A_usernameOrEmail_SI',
		resetPasswordPolicyName: 'B2C_1A_usernameOrEmail_PasswordReset',
		changePasswordPolicyName: 'B2C_1A_profile_edit_password_change',
		tenantName: 'murphysolutionprod',
		clientId: 'c93a10df-dd8c-466f-a512-b0d33b342bcf',
		tenantId: 'c2e45755-454d-4a06-a3e9-25f445cc8f35',
		redirect_url: 'https://app.murphysolution.com',
		postLogoutUrl: 'https://app.murphysolution.com',
	},
	auth: {
		baseAuthServerUrl: 'https://idp.murphysolution.com',
		redirectUrl: 'https://app.murphysolution.com',
		postLogoutUrl: 'https://app.murphysolution.com',
		clientId: 'murphy_spa',
		hideLoginPageEmail: 'false',
		showResetPassword: 'true',
		showForgotPassword: 'true',
	},
	buildSettings: {
		buildOnlySPA: 'false',
	},
	e2eTesting: {
		ssoUserEmail: '',
		ssoUserPassword: '',
		adB2CUserEmail: '',
		adB2CUserPassword: '',
	}

	// LOCAL

	// baseURL: 'https://localhost:44370',
	// imgPath: 'dist/img',
	// cookiePath: '/app',
	// gtagId: 'G-CZ429C2599',
	// maxFileSizeMB: 20,
	// appInsightsInstrumentationKey: '3779bb71-6f2b-4d94-8ad5-1b52a4943055',
	// logToApi: 'false',
	// enabledFeatures: {
	// 	upsertUser: 'true',
	// 	addUserFromExistingAccountMode: 'false',
	// 	user: {
	// 		disabledFields: {
	// 			email: 'false',
	// 			username: 'false',
	// 			role: 'false',
	// 			permission: 'false',
	// 			phonenumber: 'false',
	// 		},
	// 		disableUsernameValidation: 'false',
	// 	},
	// 	mbs: {
	// 		disableSMS: 'false',
	// 		disableEmail: 'false',
	// 		disableNotification: 'false',
	// 	},
	// },
	// enabledProducts: {
	// 	white: 'true',
	// 	mbs: 'true',
	// 	training: 'true',
	// 	exercise: 'true',
	// },
	// includeOnlyPublicRoutes: 'false',
	// dashboardSettings: {
	// 	statusCardStatusMode: 'default',
	// },
	// azureB2C: {
	// 	loginPolicyName: 'B2C_1A_usernameOrEmail_SI',
	// 	resetPasswordPolicyName: 'B2C_1A_usernameOrEmail_PasswordReset',
	// 	changePasswordPolicyName: 'B2C_1A_profile_edit_password_change',
	// 	tenantName: 'murphysolutiondev',
	// 	clientId: '522a8042-ac41-4859-9d6d-621a30367310',
	// 	tenantId: '8831693e-c688-42be-856f-aa6839dd73c4',
	// 	redirect_url: 'http://localhost:9000/callback',
	// 	postLogoutUrl: 'http://localhost:9000/login',
	// },
	// auth: {
	// 	baseAuthServerUrl: 'https://localhost:7121',
	// 	redirectUrl: 'http://localhost:9000',
	// 	postLogoutUrl: 'http://localhost:9000/login',
	// 	clientId: 'murphy_spa',
	// 	hideLoginPageEmail: 'false',
	// 	showResetPassword: 'true',
	// 	showForgotPassword: 'true',
	// },
	// buildSettings: {
	// 	buildOnlySPA: 'false',
	// },
	// e2eTesting: {
	// 	ssoUserEmail: 'NestorW@vpc0q.onmicrosoft.com',
	// 	ssoUserPassword: '86W!ZxPjEK5MmC2',
	// 	adB2CUserEmail: 'leeg@vpc0q.onmicrosoft.com',
	// 	adB2CUserPassword: '3sAudu!P',
	// }

	// DEV

	// baseURL: 'https://murphyapidev.azurewebsites.net',
	// imgPath: 'dist/img',
	// cookiePath: '/app',
	// gtagId: 'G-CZ429C2599',
	// maxFileSizeMB: 20,
	// appInsightsInstrumentationKey: '3779bb71-6f2b-4d94-8ad5-1b52a4943055',
	// logToApi: 'false',
	// enabledFeatures: {
	// 	upsertUser: 'true',
	// 	addUserFromExistingAccountMode: 'false',
	// 	user: {
	// 		disabledFields: {
	// 			email: 'false',
	// 			username: 'false',
	// 			role: 'false',
	// 			permission: 'false',
	// 			phonenumber: 'false',
	// 		},
	// 		disableUsernameValidation: 'false',
	// 	},
	// 	mbs: {
	// 		disableSMS: 'false',
	// 		disableEmail: 'false',
	// 		disableNotification: 'false',
	// 	},
	// },
	// enabledProducts: {
	// 	white: 'true',
	// 	mbs: 'true',
	// 	training: 'true',
	// 	exercise: 'true',
	// },
	// includeOnlyPublicRoutes: 'false',
	// dashboardSettings: {
	// 	statusCardStatusMode: 'msb',
	// },
	// azureB2C: {
	// 	loginPolicyName: 'B2C_1A_usernameOrEmail_SI',
	// 	resetPasswordPolicyName: 'B2C_1A_usernameOrEmail_PasswordReset',
	// 	changePasswordPolicyName: 'B2C_1A_profile_edit_password_change',
	// 	tenantName: 'murphysolutiondev',
	// 	clientId: '522a8042-ac41-4859-9d6d-621a30367310',
	// 	tenantId: '8831693e-c688-42be-856f-aa6839dd73c4',
	// 	redirect_url: 'http://localhost:9000/callback',
	// 	postLogoutUrl: 'http://localhost:9000/login',
	// },
	// auth: {
	// 	baseAuthServerUrl: 'https://idp-webapp-dev.azurewebsites.net',
	// 	redirectUrl: 'http://localhost:9000',
	// 	postLogoutUrl: 'http://localhost:9000/login',
	// 	clientId: 'murphy_spa',
	// 	hideLoginPageEmail: 'false',
	// 	showResetPassword: 'true',
	// 	showForgotPassword: 'true',
	// },
	// buildSettings: {
	// 	buildOnlySPA: 'false',
	// },

	// LINKÖPING

	// baseURL: 'https://murphy.linkoping.se/mymurphy',
	// imgPath: 'dist/img',
	// cookiePath: '/app',
	// gtagId: 'G-CZ429C2599',
	// maxFileSizeMB: 20,
	// appInsightsInstrumentationKey: '3779bb71-6f2b-4d94-8ad5-1b52a4943055',
	// logToApi: 'true',
	// enabledFeatures: {
	//   upsertUser: 'true',
	//   addUserFromExistingAccountMode: 'true',
	//   user: {
	//     disabledFields: {
	//       email: 'true',
	//       username: 'true',
	//       role: 'true',
	//       permission: 'true',
	//       phonenumber: 'false',
	//     },
	// 	disableUsernameValidation: 'true',
	//   },
	// 		mbs: {
	// 			disableSMS: 'false',
	// 			disableEmail: 'false',
	// 			disableNotification: 'false',
	// 		},
	// },
	// enabledProducts: {
	//   white: 'true',
	//   mbs: 'true',
	//   training: 'true',
	//   exercise: 'false',
	// },
	// includeOnlyPublicRoutes: 'false',
	// dashboardSettings: {
	//   statusCardStatusMode: 'msb',
	// },
	// azureB2C: {
	//   loginPolicyName: 'B2C_1A_usernameOrEmail_SI',
	//   resetPasswordPolicyName: 'B2C_1A_usernameOrEmail_PasswordReset',
	//   changePasswordPolicyName: 'B2C_1A_profile_edit_password_change',
	//   tenantName: 'murphysolutiondev',
	//   clientId: '522a8042-ac41-4859-9d6d-621a30367310',
	//   tenantId: '8831693e-c688-42be-856f-aa6839dd73c4',
	//   redirect_url: 'https://murphy.linkoping.se/callback',
	//   postLogoutUrl: 'https://murphy.linkoping.se/login',
	// },
	// auth: {
	//   baseAuthServerUrl: 'https://murphy.linkoping.se/authserver',
	//   redirectUrl: 'https://murphy.linkoping.se',
	//   postLogoutUrl: 'https://murphy.linkoping.se/login',
	//   clientId: 'murphy_spa',
	// 	 hideLoginPageEmail: 'true',
	//   showResetPassword: 'false',
	//   showForgotPassword: 'false',
	// },
	// buildSettings: {
	//   buildOnlySPA: 'false',
	// },

	// LINKÖPING PUBLIC MBS SITE

	// baseURL: 'https://murphy-svar.linkoping.se/apiproxy',
	// imgPath: 'dist/img',
	// cookiePath: '/app',
	// gtagId: 'G-CZ429C2599',
	// maxFileSizeMB: 20,
	// appInsightsInstrumentationKey: '3779bb71-6f2b-4d94-8ad5-1b52a4943055',
	// logToApi: 'true',
	// enabledFeatures: {
	//   upsertUser: 'true',
	//   addUserFromExistingAccountMode: 'true',
	//   user: {
	//     disabledFields: {
	//       email: 'true',
	//       username: 'true',
	//       role: 'true',
	//       permission: 'true',
	//       phonenumber: 'false',
	//     },
	// 	disableUsernameValidation: 'true',
	//   },
	// 		mbs: {
	// 			disableSMS: 'false',
	// 			disableEmail: 'false',
	// 			disableNotification: 'false',
	// 		},
	// },
	// enabledProducts: {
	//   white: 'true',
	//   mbs: 'true',
	//   training: 'true',
	//   exercise: 'false',
	// },
	// includeOnlyPublicRoutes: 'true',
	// dashboardSettings: {
	//   statusCardStatusMode: 'msb',
	// },
	// azureB2C: {
	//   loginPolicyName: 'B2C_1A_usernameOrEmail_SI',
	//   resetPasswordPolicyName: 'B2C_1A_usernameOrEmail_PasswordReset',
	//   changePasswordPolicyName: 'B2C_1A_profile_edit_password_change',
	//   tenantName: 'murphysolutiondev',
	//   clientId: '522a8042-ac41-4859-9d6d-621a30367310',
	//   tenantId: '8831693e-c688-42be-856f-aa6839dd73c4',
	//   redirect_url: 'https://murphy.linkoping.se/callback.html',
	//   postLogoutUrl: 'https://murphy.linkoping.se/login.html',
	// },
	// auth: {
	//   baseAuthServerUrl: 'https://murphy.linkoping.se/authserver',
	//   redirectUrl: 'https://murphy.linkoping.se',
	//   postLogoutUrl: 'https://murphy.linkoping.se/login',
	//   clientId: 'murphy_spa',
	// 	hideLoginPageEmail: 'true',
	//   showResetPassword: 'false',
	//   showForgotPassword: 'false',
	// },
	// buildSettings: {
	//   buildOnlySPA: 'true',
	// },

	// TELIA PROD

	// baseURL: 'https://app.murphysolution.se/murphysolution',
	// imgPath: 'dist/img',
	// cookiePath: '/app',
	// gtagId: 'G-CZ429C2599',
	// maxFileSizeMB: 20,
	// appInsightsInstrumentationKey: '3779bb71-6f2b-4d94-8ad5-1b52a4943055',
	// logToApi: 'true',
	// enabledFeatures: {
	// 	upsertUser: 'true',
	// 	addUserFromExistingAccountMode: 'false',
	// 	user: {
	// 		disabledFields: {
	// 			email: 'false',
	// 			username: 'false',
	// 			role: 'false',
	// 			permission: 'false',
	// 			phonenumber: 'false',
	// 		},
	// 		disableUsernameValidation: 'false',
	// 	},
	// 	mbs: {
	// 		disableSMS: 'false',
	// 		disableEmail: 'false',
	// 		disableNotification: 'true',
	// 	},
	// },
	// enabledProducts: {
	// 	white: 'true',
	// 	mbs: 'true',
	// 	training: 'true',
	// 	exercise: 'true',
	// },
	// includeOnlyPublicRoutes: 'false',
	// dashboardSettings: {
	// 	statusCardStatusMode: 'default',
	// },
	// azureB2C: {
	// 	loginPolicyName: 'B2C_1A_usernameOrEmail_SI',
	// 	resetPasswordPolicyName: 'B2C_1A_usernameOrEmail_PasswordReset',
	// 	changePasswordPolicyName: 'B2C_1A_profile_edit_password_change',
	// 	tenantName: 'murphysolutionprod',
	// 	clientId: '3b3771be-11c3-4bcd-b25c-15fa64de781d',
	// 	tenantId: 'c2e45755-454d-4a06-a3e9-25f445cc8f35',
	// 	redirect_url: 'https://app.murphysolution.se',
	// 	postLogoutUrl: 'https://app.murphysolution.se/login',
	// },
	// auth: {
	// 	baseAuthServerUrl: 'https://app.murphysolution.se/authserver',
	// 	redirectUrl: 'https://app.murphysolution.se',
	// 	postLogoutUrl: 'https://app.murphysolution.se/login',
	// 	clientId: 'murphy_spa',
	// 	showResetPassword: 'true',
	// 	showForgotPassword: 'true',
	// },
	// buildSettings: {
	// 	buildOnlySPA: 'false',
	// },

	// SMTM

	// baseURL: 'https://murphy.smtm.se/murphyApi',
	// imgPath: 'dist/img',
	// cookiePath: '/app',
	// gtagId: 'G-CZ429C2599',
	// maxFileSizeMB: 20,
	// appInsightsInstrumentationKey: '3779bb71-6f2b-4d94-8ad5-1b52a4943055',
	// logToApi: 'true',
	// enabledFeatures: {
	// 	upsertUser: 'true',
	// 	addUserFromExistingAccountMode: 'true',
	// 	user: {
	// 		disabledFields: {
	// 			email: 'true',
	// 			username: 'true',
	// 			role: 'true',
	// 			permission: 'true',
	// 			phonenumber: 'false',
	// 		},
	// 		disableUsernameValidation: 'true',
	// 	},
	// 	mbs: {
	// 		disableSMS: 'false',
	// 		disableEmail: 'false',
	// 		disableNotification: 'true',
	// 	},
	// },
	// enabledProducts: {
	// 	white: 'true',
	// 	mbs: 'true',
	// 	training: 'true',
	// 	exercise: 'false',
	// },
	// includeOnlyPublicRoutes: 'false',
	// dashboardSettings: {
	// 	statusCardStatusMode: 'default',
	// },
	// azureB2C: {
	// 	loginPolicyName: 'B2C_1A_usernameOrEmail_SI',
	// 	resetPasswordPolicyName: 'B2C_1A_usernameOrEmail_PasswordReset',
	// 	changePasswordPolicyName: 'B2C_1A_profile_edit_password_change',
	// 	tenantName: 'murphysolutiondev',
	// 	clientId: '522a8042-ac41-4859-9d6d-621a30367310',
	// 	tenantId: '8831693e-c688-42be-856f-aa6839dd73c4',
	// 	redirect_url: 'https://murphy.smtm.se/callback',
	// 	postLogoutUrl: 'https://murphy.smtm.se/login',
	// },
	// auth: {
	// 	baseAuthServerUrl: 'https://murphy.smtm.se/idp',
	// 	redirectUrl: 'https://murphy.smtm.se',
	// 	postLogoutUrl: 'https://murphy.smtm.se/login',
	// 	clientId: 'murphy_spa',
	// 	hideLoginPageEmail: 'true',
	// 	showResetPassword: 'false',
	// 	showForgotPassword: 'false',
	// },
	// buildSettings: {
	// 	buildOnlySPA: 'false',
	// },

	// SSM

	// baseURL: 'https://murphy.ad.ssm.se/murphy-web-api',
	// imgPath: 'dist/img',
	// cookiePath: '/app',
	// gtagId: 'G-CZ429C2599',
	// maxFileSizeMB: 20,
	// appInsightsInstrumentationKey: '3779bb71-6f2b-4d94-8ad5-1b52a4943055',
	// logToApi: 'true',
	// enabledFeatures: {
	// 	upsertUser: 'true',
	// 	addUserFromExistingAccountMode: 'true',
	// 	user: {
	// 		disabledFields: {
	// 			email: 'true',
	// 			username: 'true',
	// 			role: 'false',
	// 			permission: 'false',
	// 			phonenumber: 'false',
	// 		},
	// 		disableUsernameValidation: 'true',
	// 	},
	// 	mbs: {
	// 		disableSMS: 'false',
	// 		disableEmail: 'false',
	// 		disableNotification: 'true',
	// 	},
	// },
	// enabledProducts: {
	// 	white: 'true',
	// 	mbs: 'true',
	// 	training: 'true',
	// 	exercise: 'false',
	// },
	// includeOnlyPublicRoutes: 'false',
	// dashboardSettings: {
	// 	statusCardStatusMode: 'default',
	// },
	// azureB2C: {
	// 	loginPolicyName: 'B2C_1A_usernameOrEmail_SI',
	// 	resetPasswordPolicyName: 'B2C_1A_usernameOrEmail_PasswordReset',
	// 	changePasswordPolicyName: 'B2C_1A_profile_edit_password_change',
	// 	tenantName: 'murphysolutiondev',
	// 	clientId: '522a8042-ac41-4859-9d6d-621a30367310',
	// 	tenantId: '8831693e-c688-42be-856f-aa6839dd73c4',
	// 	redirect_url: 'https://murphy.ad.ssm.se',
	// 	postLogoutUrl: 'https://murphy.ad.ssm.se',
	// },
	// auth: {
	// 	baseAuthServerUrl: 'https://murphy.ad.ssm.se/murphy-idp-api',
	// 	redirectUrl: 'https://murphy.ad.ssm.se',
	// 	postLogoutUrl: 'https://murphy.ad.ssm.se',
	// 	clientId: 'murphy_spa',
	// 	hideLoginPageEmail: 'true',
	// 	showResetPassword: 'false',
	// 	showForgotPassword: 'false',
	// },
	// buildSettings: {
	// 	buildOnlySPA: 'false',
	// },
};
