import React, { useState, useEffect, FunctionComponent } from 'react';
import Input from '@murphy-frontend/web-core/components/Input';
import Button from '@murphy-frontend/web-core/components/Button';
import { emailIsValid, parseMobileNumber } from '@murphy-frontend/common/validation';
import OptionSet from '@murphy-frontend/web-core/components/OptionSet';
import { createContactMutation, editContactMutation } from '../../../common/api/Contacts/mutations';
import { ContactModel, OptionModel, OptionSetViewModel, SelectedOptionSetViewModel } from '../../../common/api/Contacts/ContactsApi';
import Spinner from '@murphy-frontend/web-core/components/Spinner';

interface EditContactProps {
  nameInit?: string,
  emailInit?: string,
  mobileInit?: string,
  optionSets?: OptionSetViewModel[],
  selectedOptions?: SelectedOptionSetViewModel[],
  contactId?: number,
  customerId: string,
  isEditing?: boolean,
  onSuccess?: () => void,
  onFail?: () => void,
  displayOnly?: boolean,
  translations: Record<string, string>,
}

const EditContact: FunctionComponent<EditContactProps> = ({
  nameInit, emailInit, mobileInit,
  optionSets, selectedOptions,
  contactId,
  customerId,
  isEditing,
  onSuccess, onFail,
  displayOnly,
  translations,
}) => {
  const [name, setName] = useState(nameInit);
  const [email, setEmail] = useState(emailInit);
  const [mobile, setMobile] = useState(mobileInit);
  const [optionSetsWithSelection, setOptionSetsWithSelection] = useState([]);

  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [mobileErrorMessage, setMobileErrorMessage] = useState('');

  const { mutate: editMutate, isPending: editIsLoading } = editContactMutation(translations);
  const { mutate: createMutate, isPending: createIsLoading } = createContactMutation(translations);

  const onNameChanged = (event) => {
    if (!event.target.value) {
      setNameErrorMessage('');
    } else {
      setNameErrorMessage('');
    }
    setName(event.target.value);
  };

  const onEmailChanged = (event) => {
    const newVal = event.target.value;
    if (!newVal) {
      setEmailErrorMessage('');
    } else if (!emailIsValid(newVal)) {
      setEmailErrorMessage('Invalid email');
    } else {
      setEmailErrorMessage('');
    }
    setEmail(newVal);
  };

  const onMobileChanged = (event) => {
    const newVal = event.target.value;
    setMobile(newVal);
    if (!newVal) {
      setMobileErrorMessage('');
    } else {
      parseMobileNumber(newVal, (parsedNumber) => {
        setMobile(parsedNumber);
        setMobileErrorMessage('');
      },
        (error) => {
          setMobileErrorMessage('Invalid number. Exempel: +46700123123');
        });
    }
  };

  const handleOptionChange = (event, optionSetId) => {
    const selectedValue = event.target.value;

    const newOptionSelections = optionSetsWithSelection.map((item) => {
      if (item.id === optionSetId) {
        const updatedItem = {
          ...item,
          selectedOption: selectedValue,
        };
        return updatedItem;
      }
      return item;
    });

    setOptionSetsWithSelection(newOptionSelections);
  };

  useEffect(() => {
    if (optionSets) {
      const optsWithSelection = optionSets.map((p) => {
        const optionsDefault = {
          id: -1,
          value: '',
        };

        const optionsWithDefault = [...p.options];
        optionsWithDefault.push(optionsDefault);
        optionsWithDefault.sort((a, b) => a.id - b.id);

        let selectedVal = -1;

        if (selectedOptions) {
          const matchingSelection = selectedOptions.filter((q) => q.optionSetId === p.id);
          if (matchingSelection && matchingSelection.length > 0) {
            selectedVal = matchingSelection[0].id;
          }
        }

        return {
          id: p.id,
          name: p.name,
          options: optionsWithDefault,
          selectedOption: selectedVal,
        };
      });
      setOptionSetsWithSelection(optsWithSelection);
    }
  }, [optionSets, selectedOptions]);

  const submit = (e) => {
    e.preventDefault();
    if (emailErrorMessage || mobileErrorMessage) {
      return;
    }

    const selectedOptionsToSubmit = optionSetsWithSelection.map((p) => ({
      ID: p.selectedOption,
      OptionSetId: p.id,
    } as OptionModel));

    if (isEditing === true) {
      const editContactRequest: ContactModel = {
        ID: contactId,
        Name: name,
        Email: email,
        MobileNr: mobile,
        SelectedOptions: selectedOptionsToSubmit,
        CustomerId: customerId,
      };
      editMutate(editContactRequest, {
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          onFail();
        },
      });
    } else {
      const createContactRequest = {
        CustomerId: customerId,
        Name: name,
        Email: email,
        MobileNr: mobile,
        SelectedOptions: selectedOptionsToSubmit,
      };

      createMutate(createContactRequest, {
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          onFail();
        },
      });
    }
  };

  if (editIsLoading || createIsLoading) {
    return <Spinner isGlobal />;
  }

  return (
    <form className="modal-form">
      <Input title={translations.name} disabled={displayOnly === true} val={name} isRequired handleChange={(event) => onNameChanged(event)} errorMessage={nameErrorMessage} />
      <Input title={translations.email} disabled={displayOnly === true} val={email} handleChange={onEmailChanged} errorMessage={emailErrorMessage} />
      <Input title={translations.phonenumber} disabled={displayOnly === true} val={mobile} handleChange={onMobileChanged} errorMessage={mobileErrorMessage} />

      {optionSetsWithSelection ? optionSetsWithSelection.map((p) => (
        <OptionSet
          key={p.id}
          optionSetId={p.id}
          optionSetName={p.name}
          options={p.options}
          selectedOption={p.selectedOption}
          handleChange={handleOptionChange}
          disabled={displayOnly === true}
        />
      )) : null}

      {!displayOnly
        ? (
          <div className="modal-footer">
            <Button buttonStyle="btn--primary" buttonSize="btn--medium" onClick={submit}>{translations.save}</Button>
          </div>
        ) : null}

    </form>
  );
}

export default EditContact;
