import { inject, injectable } from "inversify";
import ApiServiceType, { IApiService } from "../../interfaces/IApiService";
import {
  IDashboardApi,
  Dashboard,
  CreateDashboardRequest,
  DashboardGoal,
  PostDashboardGoalRequest,
  PostDashboardBackGroundRequest,
  PostDashboardAssumptionRequest,
  PostDashboardActionRequest,
  PatchDashboardActionRequest,
  PostDashboardStatusRequest,
  PatchDashboardStatusRequest,
  PostDashboardLogRequest,
  PostDashboardInformationRequest,
  PatchDashboardInformationRequest,
  PostDashboardPartyRequest,
  PostDashboardPartyPeopleRequest,
  MutateDashboardFileRequest,
  UpdateDashboardRequest,
  PostDashboardAlertUsersRequest,
} from "../../interfaces/IDashboardApi";

@injectable()
export class DashboardApi implements IDashboardApi {
  constructor(
    @inject(ApiServiceType.IApiService) private apiService: IApiService
  ) {}

  getDashboardById = async (dashboardId: string): Promise<Dashboard> => {
    const url = `/api/Dashboard/${dashboardId}/`;
    const { data } = await this.apiService.callApi(url, "GET");
    return data;
  };

  getDashboards = async (customerId: string): Promise<Dashboard[]> => {
    const url = `/api/Dashboard?customerId=${customerId}`;
    const { data } = await this.apiService.callApi(url, "GET");
    return data;
  };

  createDashboard = (createDashboardRequest: CreateDashboardRequest) => {
    const url = `/api/Dashboard`;
    return this.apiService.callApi(url, "POST", createDashboardRequest);
  };

  editDashboard = (
    dashboardId: string,
    updateDashboardRequest: UpdateDashboardRequest
  ) => {
    const url = `/api/Dashboard/${dashboardId}`;
    return this.apiService.callApi(url, "PUT", updateDashboardRequest);
  };

  getTemplatesByCustomerId = async (customerId: string) => {
    const url = `/api/Dashboard/Template?customerId=${customerId}`;
    const { data } = await this.apiService.callApi(url, "GET");
    return data;
  };

  getDashboardGoals = async (dashboardId: string): Promise<DashboardGoal[]> => {
    const url = `/api/Dashboard/${dashboardId}/Goals`;
    const { data } = await this.apiService.callApi(url, "GET");
    return data;
  };

  postDashboardGoal = (
    dashboardId: string,
    postDashboardGoalRequest: PostDashboardGoalRequest
  ) => {
    const url = `/api/Dashboard/${dashboardId}/Goals`;
    return this.apiService.callApi(url, "POST", postDashboardGoalRequest);
  };

  getDashboardBackgrounds = async (dashboardId: string) => {
    const url = `/api/Dashboard/${dashboardId}/BackGround`;
    const { data } = await this.apiService.callApi(url, "GET", null);
    return data;
  };

  postDashboardBackGround = (
    dashboardId: string,
    postDashboardBackGroundRequest: PostDashboardBackGroundRequest
  ) => {
    const url = `/api/Dashboard/${dashboardId}/BackGround`;
    return this.apiService.callApi(url, "POST", postDashboardBackGroundRequest);
  };

  getDashboardAssumptions = async (dashboardId: string) => {
    const url = `/api/Dashboard/${dashboardId}/Assumption`;
    const { data } = await this.apiService.callApi(url, "GET", null);
    return data;
  };

  postDashboardAssumption = (
    dashboardId: string,
    postDashboardAssumptionRequest: PostDashboardAssumptionRequest
  ) => {
    const url = `/api/Dashboard/${dashboardId}/Assumption`;
    return this.apiService.callApi(url, "POST", postDashboardAssumptionRequest);
  };

  getDashboardActions = async (dashboardId: string) => {
    const url = `/api/Dashboard/${dashboardId}/ActionsCard`;
    const { data } = await this.apiService.callApi(url, "GET", null);
    return data;
  };

  postDashboardAction = (
    dashboardId: string,
    postDashboardActionCardRequest: PostDashboardActionRequest
  ) => {
    const url = `/api/Dashboard/${dashboardId}/ActionsCard`;
    return this.apiService.callApi(url, "POST", postDashboardActionCardRequest);
  };

  patchDashboardAction = (
    dashboardId: string,
    patchDashboardActionCardRequest: PatchDashboardActionRequest
  ) => {
    const url = `/api/Dashboard/${dashboardId}/ActionsCard/${patchDashboardActionCardRequest.Id}`;
    return this.apiService.callApi(
      url,
      "PATCH",
      patchDashboardActionCardRequest
    );
  };

  getDashboardStatuses = async (dashboardId: string) => {
    const url = `/api/Dashboard/${dashboardId}/StatusCard`;
    const { data } = await this.apiService.callApi(url, "GET", null);
    return data;
  };

  postDashboardStatus = (
    dashboardId: string,
    postDashboardStatusRequest: PostDashboardStatusRequest
  ) => {
    const url = `/api/Dashboard/${dashboardId}/StatusCard`;
    return this.apiService.callApi(url, "POST", postDashboardStatusRequest);
  };

  patchDashboardStatus = (
    dashboardId: string,
    patchDashboardStatusRequest: PatchDashboardStatusRequest
  ) => {
    const url = `/api/Dashboard/${dashboardId}/StatusCard/${patchDashboardStatusRequest.Id}`;
    return this.apiService.callApi(url, "PATCH", patchDashboardStatusRequest);
  };

  getDashboardLogItems = async (dashboardId: string) => {
    const url = `/api/Dashboard/${dashboardId}/DashboardLog`;
    const { data } = await this.apiService.callApi(url, "GET", null);
    return data;
  };

  postDashboardLogItem = (
    dashboardId: string,
    postDashboardLogRequest: PostDashboardLogRequest
  ) => {
    const url = `/api/Dashboard/${dashboardId}/DashboardLog`;
    return this.apiService.callApi(url, "POST", postDashboardLogRequest);
  };

  getDashboardFiles = async (dashboardId: string) => {
    const url = `api/Dashboard/${dashboardId}/File`;
    const { data } = await this.apiService.callApi(url, "GET");
    return data;
  };

  mutateDashboardFile = (
    dashboardId: string,
    mutateDashboardFileRequest: MutateDashboardFileRequest
  ) => {
    const url = `api/Dashboard/${dashboardId}/File`;
    const formData = new FormData();
    if (mutateDashboardFileRequest.file) {
      formData.append("File", mutateDashboardFileRequest.file);
    }
    const { file, ...formDataWithoutFile } = mutateDashboardFileRequest;
    formData.append("FileJsonData", JSON.stringify(formDataWithoutFile));
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return this.apiService.callApi(url, "POST", formData, headers);
  };

  getDashboardInformation = async (dashboardId: string) => {
    const url = `/api/Dashboard/${dashboardId}/InformationCard`;
    const { data } = await this.apiService.callApi(url, "GET", null);
    return data;
  };

  postDashboardInformation = (
    dashboardId: string,
    postDashboardInformationRequest: PostDashboardInformationRequest
  ) => {
    const url = `/api/Dashboard/${dashboardId}/InformationCard`;
    return this.apiService.callApi(
      url,
      "POST",
      postDashboardInformationRequest
    );
  };

  patchDashboardInformation = (
    dashboardId: string,
    patchDashboardInformationRequest: PatchDashboardInformationRequest
  ) => {
    const url = `/api/Dashboard/${dashboardId}/InformationCard/${patchDashboardInformationRequest.Id}`;

    return this.apiService.callApi(
      url,
      "PATCH",
      patchDashboardInformationRequest
    );
  };

  getDashboardParty = async (dashboardId: string) => {
    const url = `/api/Dashboard/${dashboardId}/Party`;
    const { data } = await this.apiService.callApi(url, "GET", null);
    return data;
  };

  postDashboardParty = (
    dashboardId: string,
    postDashboardPartyRequest: PostDashboardPartyRequest
  ) => {
    const url = `/api/Dashboard/${dashboardId}/Party`;
    return this.apiService.callApi(url, "POST", postDashboardPartyRequest);
  };

  getDashboardPartyPeople = async (dashboardId: string) => {
    const url = `/api/Dashboard/${dashboardId}/PartyPeople`;
    const { data } = await this.apiService.callApi(url, "GET", null);
    return data;
  };

  postDashboardPartyPeople = (
    dashboardId: string,
    postDashboardPartyPeopleRequest: PostDashboardPartyPeopleRequest
  ) => {
    const url = `/api/Dashboard/${dashboardId}/PartyPeople`;
    return this.apiService.callApi(
      url,
      "POST",
      postDashboardPartyPeopleRequest
    );
  };

  getDashboardAlertUsers = async (dashboardId: string) => {
    const url = `/api/Dashboard/${dashboardId}/AlertUser`;
    const { data } = await this.apiService.callApi(url, "GET", null);
    return data;
  };

  postDashboardAlertUsers = (
    dashboardId: string,
    postDashboardAlertUsersRequest: PostDashboardAlertUsersRequest
  ) => {
    const url = `/api/Dashboard/${dashboardId}/AlertUser`;
    return this.apiService.callApi(url, "POST", postDashboardAlertUsersRequest);
  };
}
