
export interface IFeatureService {
    isUserNameValidationEnabled: () => boolean;
    isUpsertUserEnabled: () => boolean;
    isUserNameDisabled: () => boolean;
    isEmailDisabled: () => boolean;
    isPhoneNumberDisabled: () => boolean;
    isResetPasswordEnabled: () => boolean;
    isResetMfaEnabled: () => boolean;
    createUserIsEnabled: () => boolean;
}

let FeatureServiceType = {
    IFeatureService: Symbol('IFeatureService'),
};

export default FeatureServiceType;
