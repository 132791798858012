import { AxiosResponse } from "axios";


export interface IPreferencesApi {
    getTranslations: (lang: string) => Promise<AxiosResponse>;
    postDeviceToken: (deviceToken: string) => Promise<AxiosResponse>;
    postLanguageId: (languageId: number) => Promise<AxiosResponse>;
}

const PreferencesApiType = {
    IPreferencesApi: Symbol("IPreferencesApi"),
};

export default PreferencesApiType;