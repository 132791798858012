import React, { useState, useEffect, FunctionComponent } from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import { MdGroupAdd } from 'react-icons/md';
import { IconContext } from 'react-icons/lib';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import AddContactGroup from './components/AddContactGroup';
import ContactGroupList from './components/ContactGroupList';
import SearchBar from '@murphy-frontend/web-core/components/SearchBar';

import { useGetContactGroups } from '@murphy-frontend/common/api/ContactGroups/Queries';
import { useDeleteContactGroup } from '@murphy-frontend/common/api/ContactGroups/Mutations';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';
import { useUserPreferences } from '../../common/contexts/UserPreferencesContext';
import { useCustomer } from '../../common/contexts/CustomerContext';

const ContactGroups: FunctionComponent = () => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { translations } = useUserPreferences();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [contactGroups, setContactGroups] = useState('');

  const [filteredContactGroups, setFilteredContactGroups] = useState([]);
  const [currentSearchText, setCurrentSearchText] = useState('');

  const { customer } = useCustomer();

  const {
    isLoading: isLoadingContactGroups, isError: isErrorContactGroups, data: dataContactGroups, error: errorContactGroups,
  } = useGetContactGroups(customer.Id);

  const { mutate: delMutate, isPending } = useDeleteContactGroup();

  const onGetContactGroupsSuccess = (data) => {
    const mappedData = data.map((p) => ({
      name: p.Name,
      id: p.ID,
    }));

    mappedData.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) - (a.name.toUpperCase() < b.name.toUpperCase()));
    setFilteredContactGroups(mappedData);
    setContactGroups(mappedData);
    setIsLoading(false);
  };

  function ErrorMessage() {
    return <p>{error}</p>;
  }
  function DeleteConfirmationBody({ name }) {
    return (
      <p>
        {translations.delete}
        {' '}
        <strong>{name}</strong>
        {' '}
        ?
      </p>
    );
  }

  useEffect(() => {
    if (dataContactGroups) {
      onGetContactGroupsSuccess(dataContactGroups);
    }
  }, [dataContactGroups]);

  const onClickNewGroup = () => {
    const modalConf: ModalConfiguration = {
      title: `${translations.newnew} ${translations.contactgroup}`,
      body:
        <AddContactGroup
          translations={translations}
          customerId={customer.Id}
          onSuccess={() => closeModal()}
          onFail={() => closeModal()}
        />,
      hideButton: true,
      type: 'info',
    };

    openModal(modalConf);
  };

  const onClickEditContactGroup = (contactGroupId) => {
    navigate(`/contactgroup/${contactGroupId}`);
  };

  const onClickDeleteContactGroup = (contactGroupId) => {
    const contactGroupToEdit = contactGroups.filter((p) => p.id === contactGroupId)[0];
    const modalConf: ModalConfiguration = {
      title: `${translations.delete} ${translations.contactgroup}`,
      body: <DeleteConfirmationBody name={contactGroupToEdit.name} />,
      hideButton: false,
      buttonText: translations.delete,
      type: 'warning',
      okCallback: () => delMutate(contactGroupId),
    };

    openModal(modalConf);
  };

  const onSearchChanged = (event) => {
    const newValue = event.target.value;
    setCurrentSearchText(newValue);
    if (!newValue) {
      setFilteredContactGroups([...contactGroups]);
    } else {
      const searchValue = newValue.toUpperCase();
      const newFilteredContacts = contactGroups.filter((p) => p.name.toUpperCase().startsWith(searchValue));
      setFilteredContactGroups(newFilteredContacts);
    }
  };

  useEffect(() => {
    if (error) {
      const modalConf: ModalConfiguration = {
        title: 'Error',
        body: <ErrorMessage />,
        buttonText: 'Ok',
        type: 'error',
        okCallback: () => { setError(null); },
      };

      openModal(modalConf);
    }
  }, [error]);

  if (isLoading || isPending || isLoadingContactGroups) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }

  return (
    <div className="pbs-wrapper">
      <h1>{translations.contactgroups}</h1>
      <span className="gradient" />
      <div className="pbs-grid">
        <div className="contacts-controls">
          <div>
            <SearchBar val={currentSearchText} handleChange={onSearchChanged} placeholder={translations['placeholder-lang-search-action']} />
          </div>
          <div>
            <IconContext.Provider value={{ className: 'contacts-controls-icon-large' }}>
              <MdGroupAdd onClick={onClickNewGroup} />
            </IconContext.Provider>
          </div>
        </div>
        <div className="table-container">
          <ContactGroupList contacts={filteredContactGroups} onClickEdit={onClickEditContactGroup} onClickDelete={onClickDeleteContactGroup} />
        </div>
      </div>
    </div>
  );
}

export default ContactGroups;
