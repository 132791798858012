import * as React from 'react';
import Button from '@murphy-frontend/web-core/components/Button';
import CardFooter from "../MainLog/CardFooter";
import { FunctionComponent } from "react";
import { BackGround } from '../../api/BackGroundApi';
import UpsertBackGroundComponent from './UpsertBackGroundComponent';
import BackGroundFormatted from './BackGroundFormatted';
interface BackGroundExpandedContentProps {
    backGround: BackGround,
    dashboardId: string,
    openModal: any,
    closeModal: any,
    translations: Record<string, string>,
}

const BackGroundExpandedContent: FunctionComponent<BackGroundExpandedContentProps> = ({ backGround, dashboardId, openModal, closeModal, translations }) => {

    const onClickEdit = () => {
        const upsertBackGroundComponent =
            <UpsertBackGroundComponent
                translations={translations}
                dashboardId={dashboardId}
                backGround={backGround}
                onSuccess={() => closeModal()} />;

        const configuration = {
            centerContent: false,
            hideButton: true,
            body: upsertBackGroundComponent,
            title: translations['class-lang-background-card'],
        }
        openModal(configuration);
    }

    let footer;
    if (backGround.archived !== true) {
        const button =
            <Button
                onClick={() => onClickEdit()}>
                {translations['class-lang-button-edit']}
            </Button>
        footer = <CardFooter rightContent={button} />
    }

    return <BackGroundFormatted backGround={backGround} translations={translations} cardFooter={footer} />
}

export default BackGroundExpandedContent;