import { useState, useEffect, FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { areArraysEqualSets } from '@murphy-frontend/common/utils';
import { emailIsValid, userNameIsValid } from '@murphy-frontend/common/validation';
import React from 'react';
import { UserTraningSession } from '@murphy-frontend/common/interfaces/IUsersApi';
import { Constant } from '../../../models/Constant';
import Input from '../../../components/Input';
import OptionSet from '../../../components/OptionSet';
import PermissionsSelector from '../../../components/PermissionsSelector';
import Button from '../../../components/Button';
import { roles } from '../../../constants';

const isUSPhoneNumber = (value: string) => {
  const usPhoneRegex = /^\+1[-.●]?\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
  return usPhoneRegex.test(value);
};

yup.addMethod(yup.string, 'phonenumber', function () {
  return this.test({
    name: 'phonenumber',
    exclusive: true,
    message: 'Not a valid phone number',
    test: function (value) {
      if (!isValidPhoneNumber(value)) {
        return false; // Fail validation if the phone number is invalid
      }
      // If the phone number is valid, check if it's a US number
      if (isUSPhoneNumber(value)) {
        // Logic for US phone number, such as showing a terms checkbox
        this.createError({
          message: 'As a US number, you have to agree to these terms: the terms',
        });

        // Trigger some UI change on the frontend to display a checkbox
        return true; // Continue validation, but handle the checkbox in the UI
      }

      return true; // Continue validation for non-US numbers
    },
  });
});

yup.addMethod(yup.string, 'email', function () {
  return this.test({
    name: 'email',
    exclusive: true,
    message: 'Not a valid email',
    test: (value) => emailIsValid(value),
  });
});

yup.addMethod(yup.string, 'username', function () {
  return this.test({
    name: 'username',
    exclusive: true,
    message: 'Not a valid username',
    test: (value) => {
      return true;

      // TODO: should this validation be active or not? SaaS vs Onprem?

      // const persistenceService = new WebPersistenceService();
      // const featureService = new WebFeatureService(persistenceService);

      // var userNameValidationIsEnabled = featureService.isUserNameValidationEnabled();
      // if (userNameValidationIsEnabled === false)
      //   return true;

      // return userNameIsValid(value)
    },
  });
});

const userSchema = yup.object().shape(
  {
    username: yup.string().required().username(),
    email: yup.string().required().email(),
    phonenumber: yup
      .string()
      .nullable()
      .notRequired()
      .when('phonenumber', {
        is: (value) => value?.length > 0, // Only apply validation if a phone number is provided
        then: yup.string().test({
          name: 'usPhoneNumberTerms',
          message: 'As a US number, you have to agree to these terms: the terms',
          test: function (value) {
            const { termsAgreed } = this.parent;
            if (isUSPhoneNumber(value) && !termsAgreed) {
              return this.createError({
                path: 'termsAgreed',
                message: 'You must agree to the terms for using a US phone number',
              });
            }
            return true;
          },
        }),
        otherwise: yup.string().nullable(), // Make sure it's not required if no value is present
      }),
    termsAgreed: yup.boolean().default(false),
  },
  [['phonenumber', 'phonenumber']],
);

export interface UserFormViewModel {
  id: string,
  username: string,
  email: string,
  role: number,
  phonenumber: string,
  deviceToken: string,
  permissions: number[],
  userTrainingSessions: UserTraningSession[],
  selectedproductids: number[],
}

interface UserFormProps {
  translations?: Record<string, string>,
  onSubmit: (updatedUser: any) => void,
  showResetMfa?: boolean,
  onResetMfa?: (userId: string) => void,
  allProducts: Constant[],
  userToBeUpdated?: UserFormViewModel,
  showResetPassword: boolean,
  onClickChangePassword?: any,
  isSetPermissionsReadonly: boolean,
  isSetRoleReadonly: boolean,
  isLoading: boolean,
  isUserNameDisabled: boolean,
  isEmailDisabled: boolean,
  isPhoneNumberDisabled: boolean,
  isUpsertUserEnabled: boolean,
}

const UserForm: FunctionComponent<UserFormProps> = ({
  translations,
  onSubmit,
  showResetMfa,
  onResetMfa,
  allProducts,
  isLoading,
  userToBeUpdated,
  showResetPassword,
  onClickChangePassword,
  isSetPermissionsReadonly,
  isSetRoleReadonly,
  isUserNameDisabled,
  isEmailDisabled,
  isPhoneNumberDisabled,
  isUpsertUserEnabled,
}) => {
  const {
    register, handleSubmit, formState: { errors, dirtyFields, isDirty }, reset, getValues, watch,
  } = useForm<UserFormViewModel>({
    resolver: yupResolver(userSchema),
    defaultValues: {
      username: '',
      email: '',
      phonenumber: '',
      selectedproductids: [],
      role: -1,
      termsAgreed: false,
    },
  });

  const phoneNumber = watch('phonenumber');
  const isUSPhone = isUSPhoneNumber(phoneNumber);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [role, setRole] = useState(-1);
  const [isReallyDirty, setIsReallyDirty] = useState(false);

  const roleIsDirty = () => {
    const initialRole = getValues('role') >= 0 ? getValues('role') : -1;
    let roleDirty = false;
    if (role >= 0) {
      if (role !== initialRole) {
        roleDirty = true;
      }
    }
    return roleDirty;
  };

  useEffect(() => {
    const initalProducts = getValues('selectedproductids') ? getValues('selectedproductids') : [];
    let productsDirty = false;
    if (!areArraysEqualSets(initalProducts, selectedProductIds)) {
      productsDirty = true;
    }

    const roleDirty = roleIsDirty();

    setIsReallyDirty(productsDirty || roleDirty || (isDirty || false));
  }, [isDirty, selectedProductIds, role]);

  useEffect(() => {
    const userRole = userToBeUpdated?.role >= 0 ? userToBeUpdated.role : -1;

    if (userToBeUpdated) {
      const defaults = {
        username: userToBeUpdated?.username,
        email: userToBeUpdated?.email,
        phonenumber: userToBeUpdated?.phonenumber,
        deviceToken: userToBeUpdated?.deviceToken,
        selectedproductids: userToBeUpdated?.permissions ?? [],
        role: userRole,
      };
      setSelectedProductIds(userToBeUpdated?.permissions ?? []);
      setRole(userRole);
      reset(defaults);
    }
  }, [userToBeUpdated, reset]);

  const onSubmitHandler = (data: UserFormViewModel) => {
    const allDirtyFields = Object.entries(dirtyFields).filter(([key, value]) => value === true).map((item) => item[0]);
    const dataToSubmit: any = {};

    Object.entries(data).forEach(([key, value]) => {
      const fieldIsDirty = allDirtyFields.includes(key);
      if (fieldIsDirty) {
        dataToSubmit[key] = value;
      }
    });

    if (!areArraysEqualSets(data.selectedproductids, selectedProductIds)) {
      dataToSubmit.selectedproductids = selectedProductIds;
    }

    const roleDirty = roleIsDirty();
    if (roleDirty) {
      dataToSubmit.role = role;
    }

    onSubmit(dataToSubmit);
  };

  const onSelectProduct = (productId: number) => {
    let updatedProductIds = [...selectedProductIds];
    if (updatedProductIds.includes(productId)) {
      updatedProductIds = updatedProductIds.filter((p) => p !== productId);
    } else {
      updatedProductIds.push(productId);
    }
    setSelectedProductIds(updatedProductIds);
  };

  const onChangeRole = (event) => {
    const parsedRoleId = Number.parseInt(event.target.value, 10);
    setRole(parsedRoleId);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="row">
        <div className="column">
          <div className="form-section">
            <span className="form-section-header">{translations ? translations.userdata.toUpperCase() : "USER DATA"}</span>

            <Input
              isControlled
              register={register}
              name="username"
              isRequired
              title={translations ? translations['placeholder-username'] : 'Username'}
              errorMessage={errors.username?.message}
              disabled={isUserNameDisabled}
            />
            <Input
              isControlled
              register={register}
              name="email"
              isRequired
              title={translations ? translations['lang-table-email'] : 'Email'}
              errorMessage={errors.email?.message}
              disabled={isEmailDisabled}
            />
            <Input
              isControlled
              register={register}
              name="phonenumber"
              title={translations ? translations.phonenumber : 'Phone number'}
              errorMessage={errors.phonenumber?.message}
              disabled={isPhoneNumberDisabled}
            />
            {showResetPassword === true && isUpsertUserEnabled && (
              <div className="link" onClick={onClickChangePassword}>
                {translations ? translations['class-lang-user-table-reset'] : 'Reset password'}
              </div>
            )}
          </div>
        </div>
        <div className="column">
          <div className="form-section">
            <span className="form-section-header">{translations ? translations.permissions.toUpperCase() : "PERMISSIONS"}</span>
            <OptionSet
              optionSetName={translations ? translations['lang-label-role'] : 'Role'}
              direction="row"
              key={1}
              optionSetId={1}
              options={roles}
              selectedOption={role}
              handleChange={onChangeRole}
              disabled={isSetRoleReadonly}
            />
            <div>
              <PermissionsSelector
                isReadonly={isSetPermissionsReadonly}
                allProducts={allProducts}
                selectedProductIds={selectedProductIds}
                onSelectProduct={onSelectProduct}
              />
            </div>
          </div>
        </div>

      </div>
      {isUpsertUserEnabled && <div className="row">
        <div className="column">
          <div className="form-section">
            {userToBeUpdated && showResetMfa && <Button isLoading={isLoading} onClick={() => onResetMfa(userToBeUpdated.id)} buttonStyle="btn--primary" buttonSize="btn--medium">
              Reset MFA
            </Button>}
            {isUSPhone && (
              <div>
                <label>
                By adding your mobile number you are subscribing to our SMS service, you agree to the following terms and conditions.<br></br> 
                To opt-out at any time, click the unsubscribe button in your profile page or text STOP to +12028947147. For help, text HELP to +12028947147 or contact our customer support at support@murphysolution.com. Message and data rates may apply. The number of messages you receive will vary based on you and your company's interaction with us.<br></br>
                We are committed to protecting your personal information in compliance with GDPR. Your details, including your mobile phone number, email address, and mobile opt-in data, will not be shared or sold to third parties or affiliates for marketing purposes. Only authorized personnel will have access to this information, ensuring your privacy is always safeguarded.
                <br></br>I agree to the Terms:  </label>
                <input
                    type="checkbox"
                    {...register('termsAgreed')}
                  />
                {errors.termsAgreed && <p>{errors.termsAgreed.message}</p>}
              </div>
            )}
            <Button isLoading={isLoading} disabled={!isReallyDirty || (errors && errors.length > 0)} type="submit" buttonStyle="btn--primary" buttonSize="btn--medium">
              {translations ? translations.save : 'Save'}
            </Button>
          </div>
        </div>
      </div>}
    </form >
  );
}

export default UserForm;
