import * as React from 'react';
import { FunctionComponent } from 'react';
import { parseHtmlAndLinkifySafe } from '@murphy-frontend/common/utils';
import { ActionsCard } from '../../api/ActionsCardApi';


interface ActionsCardFormattedProps {
    actionsCard: ActionsCard;
    translations: Record<string, string>
    cardFooter: any
}

const ActionsCardFormatted: FunctionComponent<ActionsCardFormattedProps> = ({ actionsCard, translations, cardFooter }) => {

    let extraInfoPresent = false;
    if (actionsCard.Owner)
        extraInfoPresent = true;
    if (actionsCard.ProvidedBy)
        extraInfoPresent = true;
    if (actionsCard.isDecision)
        extraInfoPresent = true;
    if (actionsCard.isCommunication)
        extraInfoPresent = true;
    if (actionsCard.Important)
        extraInfoPresent = true;

    const extraInfo = <> {actionsCard.Owner &&
        <>
            <br />
            <p>
                <strong>{translations['class-lang-modal-decision-maker']}:</strong>&nbsp;{actionsCard.Owner}
            </p>
        </>}
        {actionsCard.ProvidedBy &&
            <>
                <br />
                <p>
                    <strong>{translations['class-lang-modal-responsible']}:</strong>&nbsp;{actionsCard.ProvidedBy}
                </p>
            </>}
        {actionsCard.Important === true &&
            <>
                <br />
                <p>
                    <strong>{translations['class-lang-modal-check-priority']}:</strong>&nbsp;{translations.yes}
                </p>
            </>}
        {actionsCard.isDecision === true &&
            <>
                <br />
                <p>
                    <strong>{translations['class-lang-modal-decision-check']}:</strong>&nbsp;{translations.yes}
                </p>
            </>}
        {actionsCard.isCommunication === true &&
            <>
                <br />
                <p>
                    <strong>{translations['iscommunication']}:</strong>&nbsp;{translations.yes}
                </p>
            </>}
    </>;

    return (
        <div className="formatted-card">
            <p>
                <strong>{translations['class-lang-by-user']}</strong>&nbsp;{actionsCard.user}
            </p>
            {actionsCard.Message &&
                <>
                    <br />
                    <p>
                        {parseHtmlAndLinkifySafe(actionsCard.Message)}
                    </p>
                </>}
            {extraInfoPresent
                &&
                <>
                    <br />
                    <hr></hr>
                    {extraInfo}
                </>
            }

            {cardFooter &&
                <>
                    <br />
                    <hr></hr>
                    <br />
                    {cardFooter}
                </>
            }
        </div >
    );
}

export default ActionsCardFormatted;