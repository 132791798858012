import dayjs from 'dayjs';
import React, { useState, useEffect, FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { getTrainingSessionStateNames } from '../../../common/common';
import SimpleResponsiveTable from '@murphy-frontend/web-core/components/SimpleResponsiveTable/SimpleResponsiveTable';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import { useGetTrainingSessions } from '../../../common/api/TrainingSessions/queries';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { useUserPreferences } from '../../../common/contexts/UserPreferencesContext';
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';
import { useUser } from '../../../common/contexts/UserContext';
import { SimpleResponsiveListColumnModel, SimpleResponsiveListRowModel } from '@murphy-frontend/web-core/components/SimpleResponsiveTable/models';
import { TrainingSessionModel } from '../../../common/api/Training/TrainingApi';
import { useCreateUserTrainingSession, useEditUserTrainingSession } from '../../../common/api/UserTrainingSessions/mutations';

const Course: FunctionComponent = () => {
  const { id } = useParams();
  const { user } = useUser();

  const { openModal } = useModal();
  const { language } = useUserPreferences();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [availableCoursesSessions, setAvailableCourseSessions] = useState([]);
  const [courseName, setCourseName] = useState('');
  const [courseDescription, setCourseDescription] = useState('');

  const [rows, setRows] = useState<SimpleResponsiveListRowModel[]>([]);
  const [columns, setColumns] = useState<SimpleResponsiveListColumnModel[]>([]);

  const { data: trainingSessionsData, isLoading: trainingSessionsIsLoading } = useGetTrainingSessions(id);

  const { translations } = useUserPreferences();

  const { mutate: createUserTrainingSession, isPending: createUserIsLoading } = useCreateUserTrainingSession(translations);
  const { mutate: editUserTrainingSession, isPending: editUserIsLoading } = useEditUserTrainingSession(translations);

  const mapTrainingSessions = (sessions: TrainingSessionModel[]) => {
    const mappedSessions = sessions.map((course) => ({
      id: course.ID,
      startdatetime: dayjs(course.StartDateTimeUtc).format('YYYY-MM-DD HH:mm:ss'),
      enddatetime: dayjs(course.EndDateTimeUtc).format('YYYY-MM-DD HH:mm:ss'),
      link: course.ExternalLink,
      statusid: course.UserTrainingSessionStateId,
      statusname: getTrainingSessionStateNames(course.UserTrainingSessionStateId, language),
      useexternallink: course.UseExternalLink,
    }));

    return mappedSessions;
  };

  const onFail = (errorData) => {
    if (errorData.statusText) {
      setError(errorData.statusText);
    } else {
      setError('Error');
    }
    setIsLoading(false);
  };

  function ErrorMessage() {
    return <p>{error}</p>;
  }

  useEffect(() => {
    if (!trainingSessionsIsLoading) {
      const mappedSessions = mapTrainingSessions(trainingSessionsData);
      mappedSessions.sort((a, b) => a.startdatetime - b.startdatetime);
      setAvailableCourseSessions(mappedSessions);

      const firstSession = trainingSessionsData[0];
      setCourseName(firstSession.Name);
      setCourseDescription(firstSession.Desc);
    }
    return () => {
      setAvailableCourseSessions([]);
      setCourseName('');
      setCourseDescription('');
    };
  }, [trainingSessionsIsLoading, trainingSessionsData]);

  const onRegisterForTrainingSession = (trainingSessionId) => {
    const createUserTrainingSessionRequest = {
      TrainingSessionId: trainingSessionId,
      CustomerUserId: user.CustomerUserId,
    };

    createUserTrainingSession(createUserTrainingSessionRequest);
  };

  const onUnRegisterForTrainingSession = (customerUserTrainingSessionId) => {
    const editCustomerUserTrainingSessionRequest = {
      Id: customerUserTrainingSessionId,
      NewState: 1,
    };
    editUserTrainingSession(editCustomerUserTrainingSessionRequest);
  };

  function RegistrationBody({
    starttime, endtime,
  }) {
    return (
      <div>
        <p>
          <b>Starttid:</b>
          <br />
          {starttime}
        </p>
        <br />
        <p>
          <b>Sluttid:</b>
          <br />
          {endtime}
        </p>
      </div>
    );
  }

  function UnRegistrationBody({
    starttime, endtime,
  }) {
    return (
      <div>

        <p>
          <b>Starttid:</b>
          <br />
          {starttime}
        </p>
        <br />
        <p>
          <b>Sluttid:</b>
          <br />
          {endtime}
        </p>
        <br />
        <p>
          <b>Du är redan anmäld till detta utbildningstillfälle.</b>
        </p>
        <p>
          <b>Klicka nedan för att avregistrera dig.</b>
        </p>
      </div>
    );
  }

  const onClickRow = (rowId) => {
    const row = availableCoursesSessions.filter((sess) => sess.id === rowId)[0];

    if (row.statusid === 0 || row.statusid === 2) {
      const modalConf: ModalConfiguration = {
        title: `Anmäl dig till utbildningen ${courseName}`,
        body: <RegistrationBody starttime={row.startdatetime} endtime={row.enddatetime} />,
        buttonText: 'ANMÄL DIG!',
        type: 'info',
        okCallback: () => onRegisterForTrainingSession(row.id),
      };

      openModal(modalConf);
    } else if (row.statusid === 1) {
      const modalConf: ModalConfiguration = {
        title: `Avanmäl dig från utbildningen ${courseName}`,
        body: <UnRegistrationBody starttime={row.startdatetime} endtime={row.enddatetime} />,
        buttonText: 'AVANMÄL DIG',
        type: 'warning',
        okCallback: () => onUnRegisterForTrainingSession(row.id),
      };

      openModal(modalConf);
    } else {
      const modalConf: ModalConfiguration = {
        title: `Utbildningen ${courseName} är ej bokningsbar`,
        body: <p>Kontakta admin för mer info</p>,
        buttonText: 'OK',
        type: 'warning',
      };

      openModal(modalConf);
    }
  };

  useEffect(() => {
    const newColumns = [
      { key: 'Starttid', title: 'Starttid', columnSize: 1 },
      { key: 'Sluttid', title: 'Sluttid', columnSize: 1 },
      { key: 'Status', title: 'Status', columnSize: 1 },
    ];
    const newRows = availableCoursesSessions.map((course) => ({
      id: course.id,
      clickable: true,
      Starttid: course.startdatetime,
      Sluttid: course.enddatetime,
      Status: course.statusname,
    }));

    setColumns(newColumns);
    setRows(newRows);
    setIsLoading(false);
  }, [availableCoursesSessions]);

  useEffect(() => {
    if (error) {
      const modalConf: ModalConfiguration = {
        title: 'Error',
        body: <ErrorMessage />,
        buttonText: 'Ok',
        type: 'error',
        okCallback: () => { setError(null); },
      };

      openModal(modalConf);
    }
  }, [error]);

  if (isLoading || trainingSessionsIsLoading || createUserIsLoading || editUserIsLoading) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }

  return (
    <div className="pbs-wrapper">
      <h1>{courseName}</h1>
      <span className="gradient" />

      <div className="pbs-grid">
        <div>
          <fieldset className="form-fieldset response-form">
            <legend className="form-legend">Beskrivning</legend>
            <p>
              {courseDescription}
            </p>
          </fieldset>
        </div>
        {rows.length ?
          <SimpleResponsiveTable
            columns={columns}
            rows={rows} onClickRow={onClickRow} /> :
          <p>INGA TILLFÄLLEN FINNS TILLGÄNGLIGA FÖR NÄRVARANDE</p>
        }
      </div>
    </div>
  );
}

export default Course;
