/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

function MBSResponseTableHeader({ translations }) {
  return (
    <li className="item item-container item-container-configuration-pbsresponse">
      <div className="attribute" data-name={translations.answerdate} title={translations.answerdate}>{translations.answerdate}</div>
      <div className="attribute" data-name={translations.user} title={translations.user}>{translations.user}</div>
      <div className="attribute" data-name={translations.answeralternatives} title={translations.answeralternatives}>{translations.answeralternatives}</div>
      <div className="attribute" data-name={translations.freetextanswer} title={translations.freetextanswer}>{translations.freetextanswer}</div>
    </li>
  );
}

export default MBSResponseTableHeader;
