import React, { useState, useEffect, FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import * as dayjs from 'dayjs';
import { useGetTraining } from '../../../common/api/Training/queries';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import logo from '../../../assets/img/Praeparatus_Supervivet_Practise_Risk.jpg';
import TabPanel from '@murphy-frontend/web-core/components/Tabs/TabPanel';
import { parseHtml } from '@murphy-frontend/web-core/features/RichText/helpers';
import { useUserPreferences } from '../../../common/contexts/UserPreferencesContext';
import { useGetTrainingFiles } from '../../../common/api/TrainingFiles/queries';
import FileList from '@murphy-frontend/web-core/components/FileList';
import { getConvertedDate } from '@murphy-frontend/common/services/TimeService';

const Training: FunctionComponent = () => {
  const { id } = useParams();
  const [training, setTraining] = useState({});
  const [trainingView, setTrainingView] = useState({});
  const [selectedTabId, setSelectedTabId] = useState(2);
  const [tabs, setTabs] = useState([]);

  const [files, setFiles] = useState([]);
  const { timeZone, translations } = useUserPreferences();

  const {
    isLoading: trainingsIsLoading,
    isError: trainingsIsError,
    data: trainingsData,
    error: trainingsError,
  } = useGetTraining(id);

  const {
    isLoading: trainingFilesIsLoading,
    isError: trainingFilesIsError,
    data: trainingFilesData,
    error: trainingFilesError,
  } = useGetTrainingFiles(id);

  const mapFiles = (filesArray) =>
    filesArray.map((file) => ({
      id: file.ID,
      fileId: file.FileId,
      filepath: file.FilePath,
      filename: file.FileName,
      createddatetime: getConvertedDate(
        dayjs.utc(file.CreatedDateTime),
        timeZone
      ),
    }));

  const createTrainingView = (trainingRaw) => {
    const newTrainingView = {
      id: trainingRaw.Id,
      name: trainingRaw.Name,
      imageUrl: trainingRaw.ImageUrl,
      description: trainingRaw.Description,
    };

    if (trainingRaw.HtmlDescription) {
      newTrainingView.htmlContent = parseHtml(trainingRaw.HtmlDescription);

      // parse(trainingRaw.HtmlDescription, {
      //   replace: (node) => {
      //     if (
      //       node.parent &&
      //       node.parent.name &&
      //       node.parent.name === "a" &&
      //       node.parent.attribs &&
      //       node.parent.attribs.href
      //     ) {
      //       const { href } = node.parent.attribs;
      //       const youtubeRegex =
      //         /^(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:(?:youtube\.com|youtu.be))(?:\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(?:\S+)?$/;
      //       const vimeoRegex =
      //         /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/;
      //       const youtubeMatches = href.match(youtubeRegex);
      //       const vimeoMatches = href.match(vimeoRegex);
      //       if (vimeoMatches || youtubeMatches) {
      //         return <ResponsivePlayer url={href} />;
      //       }
      //     }
      //   },
      // });
    }
    return newTrainingView;
  };

  const formatString = (str) => {
    const lines = str.split(/\n/);
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  useEffect(() => {
    if (!trainingFilesIsLoading && trainingFilesData) {
      const mappedFiles = mapFiles(trainingFilesData.data || []);
      setFiles(mappedFiles);
    }
  }, [trainingFilesIsLoading, trainingFilesData, timeZone]);

  // Removed the return function that sets state to empty array or object
  useEffect(() => {
    if (!trainingsIsLoading && trainingsData) {
      setTraining(trainingsData);
    }
  }, [trainingsData, trainingsIsLoading]);

  const onClickTab = (tabId: number) => {
    setSelectedTabId(tabId);
  };

  const setInitialTabs = (translations: Record<string, string>) => {
    const initialTabs = [
      {
        id: 1,
        displayText: translations.summary,
      },
      {
        id: 2,
        displayText: translations["class-lang-modal-description"],
      },
      {
        id: 3,
        displayText: translations["class-lang-files"],
      },
    ];

    setTabs(initialTabs);
  };

  useEffect(() => {
    setInitialTabs(translations);
  }, [translations]);

  useEffect(() => {
    if (!trainingsIsLoading) {
      setTraining(trainingsData);
    }
    return () => {
      setTraining([]);
    };
  }, [trainingsData, trainingsIsLoading]);

  useEffect(() => {
    if (training) {
      const newTrainingView = createTrainingView(training);
      setTrainingView(newTrainingView);
    }
    return () => {
      setTrainingView({});
    };
  }, [training]);

  if (!trainingView) {
    return (
      <div className="spinner-container-global-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="training-detail-wrapper">
      <div className="training-image-wrapper">
        <div
          className="training-image-container"
          style={{
            backgroundImage: `url(${trainingView.imageUrl ?? logo})`,
          }}
        />
      </div>
      <h1 className="training-title">{trainingView.name}</h1>
      <div className="training-container">
        <div className="training-tab-panel">
          <TabPanel
            tabs={tabs}
            onClickTab={onClickTab}
            selectedTabId={selectedTabId}
          />
        </div>
        {selectedTabId === 1 && (
          <div className="training-htmldescription-display">
            {trainingView.description && formatString(trainingView.description)}
          </div>
        )}
        {selectedTabId === 2 && (
          <div className="training-htmldescription-display">
            {trainingView.htmlContent && trainingView.htmlContent}
          </div>
        )}
        {selectedTabId === 3 && (
          <div className="training-htmldescription-display">
            <FileList
              translations={translations}
              files={files}
              isReadOnly
              hidePageInputs={files.length < 10}
              hideFooter={files.length < 10}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Training;