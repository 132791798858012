import * as React from 'react'
import DashboardTable from '@murphy-frontend/web-core/components/DashboardTable'
import { useState, useEffect } from 'react';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { Party } from '../../api/PartyApi';
import { useGetParties, useGetPartyPeople } from '../../queries';
import UpsertPartyComponent from './UpsertPartyComponent';
import PartyTableRow from './PartyTableRow';
import { PartyPeople } from '../../api/PartyPeopleApi';
import PartyPeopleContactList from '../PartyPeople/PartyPeopleContactList';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import PartyTableHeader from './PartyTableHeader';
import DashboardSearch from '@murphy-frontend/web-core/components/DashboardSearch';

type PartyTableProps = {
    dashboardId: string,
    translations: Record<string, string>,
}

const PartyTable = ({ dashboardId, translations }: PartyTableProps) => {
    const [parties, setParties] = useState<Party[]>([]);
    const [partyPeople, setPartyPeople] = useState<PartyPeople[]>([]);
    const [filteredParties, setFilteredParties] = useState<Party[]>([]);
    const [currentSearchText, setCurrentSearchText] = useState('');
    const [searchActive, setSearchActive] = useState(false);

    const [rows, setRows] = useState([]);

    const { openModal, closeModal } = useModal();

    const { data: partyData, error: partyError,
        isError: partyIsError, isLoading: partyIsLoading } = useGetParties(dashboardId);

    const { data: partyPeopleData, error: partyPeopleError,
        isError: partyPeopleIsError, isLoading: partyPeopleIsLoading } = useGetPartyPeople(dashboardId);

    useEffect(() => {
        if (partyIsLoading === false) {
            const filteredCards = partyData.filter(p => p.archived === false).sort((a, b) => a.PartyName.localeCompare(b.PartyName));
            setParties(filteredCards);
            setFilteredParties(filteredCards);
        }
    }, [partyIsLoading, partyData])

    useEffect(() => {
        if (partyPeopleIsLoading === false) {
            const filteredCards = partyPeopleData.filter(p => p.archived === false).sort((a, b) => a.Name.localeCompare(b.Name));
            setPartyPeople(filteredCards);
        }
    }, [partyPeopleIsLoading, partyPeopleData])

    const onToggleSearch = () => {
        setSearchActive(!searchActive);
    }

    const onClickEditParty = (party: Party) => {
        const upsertPartyComponent =
            <UpsertPartyComponent party={party} translations={translations} dashboardId={dashboardId} onSuccess={() => closeModal()} />;
        const configuration = {
            centerContent: false,
            hideButton: true,
            body: upsertPartyComponent,
            title: translations['class-lang-edit-group'],
        }
        openModal(configuration);
    }

    const onClickOpenPartyPeople = (partyId: number) => {
        const partyPartyPeople = partyPeople.filter(p => p.PartyId === partyId);

        const partyPeopleContactList = <PartyPeopleContactList
            translations={translations}
            partyPeople={partyPartyPeople}
            openModal={openModal}
            closeModal={closeModal}
            dashboardId={dashboardId}
            partyId={partyId}
        />

        const configuration = {
            centerContent: false,
            hideButton: true,
            body: partyPeopleContactList,
            title: translations.contacts,
        }
        openModal(configuration);
    }

    useEffect(() => {
        let newFilteredCards = [...filteredParties];
        if (currentSearchText) {
            const searchValue = currentSearchText.toUpperCase();
            newFilteredCards =
                newFilteredCards.filter((party) => {
                    const partyPartyPeople = partyPeople.filter(p => p.PartyId === party.PartyId);
                    const matchingPartyPeople = partyPartyPeople.filter((partyPerson) => {
                        return (partyPerson.Email && partyPerson.Email.toUpperCase().includes(searchValue)) ||
                            (partyPerson.Name && partyPerson.Name.toUpperCase().includes(searchValue)) ||
                            (partyPerson.Title && partyPerson.Title.toUpperCase().includes(searchValue)) ||
                            (partyPerson.PhoneNr && partyPerson.PhoneNr.toUpperCase().includes(searchValue))
                    });

                    return (party.PartyName && party.PartyName.toUpperCase().includes(searchValue)) ||
                        (party.createdby && party.createdby.toUpperCase().includes(searchValue)) ||
                        matchingPartyPeople.length > 0
                }
                );
        }

        const tableRows = newFilteredCards.map((party) => {
            return <PartyTableRow
                translations={translations}
                party={party}
                partyPeople={partyPeople.filter(p => p.PartyId === party.PartyId)}
                openEditCard={onClickEditParty}
                openPartyPeople={onClickOpenPartyPeople}
                openModal={openModal}
                closeModal={closeModal}
                dashboardId={dashboardId}
            />
        });
        setRows(tableRows);

        return () => {
            setRows([]);
        }
    }, [filteredParties, partyPeople, currentSearchText, translations])

    const header = <PartyTableHeader
        translations={translations}
        dashboardId={dashboardId}
        openModal={openModal}
        closeModal={closeModal}
        toggleSearch={onToggleSearch}
    />

    const subHeader = <div>
        <DashboardSearch
            value={currentSearchText}
            setValue={setCurrentSearchText}
            placeholder={translations['placeholder-lang-search-action']}
            onClickClear={() => setCurrentSearchText('')} />
    </div>

    if (partyIsLoading) {
        return (<div className="spinner-container-global-center"><Spinner /></div>);
    }

    return <DashboardTable
        rows={rows}
        header={header}
        subHeader={searchActive && subHeader} />
}

export default PartyTable;