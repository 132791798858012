import { inject, injectable } from "inversify";
import ApiServiceType, { IApiService } from "../../interfaces/IApiService";
import { IPreferencesApi } from "../../interfaces/IPreferencesApi";

@injectable()
export class PreferencesApi implements IPreferencesApi {
    constructor(
        @inject(ApiServiceType.IApiService) private apiService: IApiService,
    ) { }

    getTranslations = async (lang: string) => {
        const url = `/GetAllText/${lang}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };

    postDeviceToken = async (deviceToken: string) => {
        const url = `/api/Me?DeviceToken=${deviceToken}`;
        const headers = {
            'accept': '*/*',
        };
        'accept: */*'
        const { data } = await this.apiService.callApi(url, 'POST');
        return data;
    }

    postLanguageId = async (languageId: number) => {
        const url = `/api/Me?LanguageId=${languageId}`;
        const headers = {
            'accept': '*/*',
        };
        'accept: */*'
        const { data } = await this.apiService.callApi(url, 'POST');
        return data;
    }

}