import { injectable } from 'inversify';
import { IPersistenceService } from '../../../common/interfaces/IPersistenceService';
import { LocalStorageKeys } from './persistence';

@injectable()
export default class WebPersistenceService implements IPersistenceService {
	setOnboaringFlowCurrentStep = (onboardingFlowId: number, step: number) => {
		let setStep = 0;
		if (step < 0) {
			setStep = 0;
		} else {
			setStep = step;
		}

		console.log('setStep', setStep);
		window.localStorage.setItem('onboardingFlowId' + onboardingFlowId, setStep.toString());
	};
	getOnboaringFlowCurrentStep = (onboardingFlowId: number): number => {
		const value = window.localStorage.getItem('onboardingFlowId' + onboardingFlowId);
		return value ? parseInt(value) : 0;
	};
	getTranslations = () => {
		return window.localStorage.getItem(LocalStorageKeys.Translations);
	};
	setTranslations = (value: string) => {
		window.localStorage.setItem(LocalStorageKeys.Translations, value);
	};
	setSelectedTimeZone = (value: string) => {
		window.localStorage.setItem(LocalStorageKeys.SelectedTimeZone, value);
	};
	getSelectedTimeZone = () => {
		return window.localStorage.getItem(LocalStorageKeys.SelectedTimeZone);
	};
	setLanguage = (value: string) => {
		window.localStorage.setItem(LocalStorageKeys.Language, value);
	};
	getLanguage = () => {
		return window.localStorage.getItem(LocalStorageKeys.Language);
	};
	setSelectedCustomerId = (id: string) => {
		window.localStorage.setItem(LocalStorageKeys.SelectedCustomerId, id);
	};
	getSelectedCustomerId = () => {
		return window.localStorage.getItem(LocalStorageKeys.SelectedCustomerId);
	};
	getCurrentObjectId = () => {
		return window.localStorage.getItem(LocalStorageKeys.CurrentObjectId);
	};
	getLogoutTriggeredFromPortal = () => {
		return (
			window.localStorage.getItem(
				LocalStorageKeys.LogoutTriggeredFromPortal,
			) === 'true' || false
		);
	};
	setSelectedCourseLanguages = (value: string) => {
		window.localStorage.setItem(
			LocalStorageKeys.SelectedCourseLanguages,
			value,
		);
	};
	getSelectedCourseLanguages = () => {
		return window.localStorage.getItem(
			LocalStorageKeys.SelectedCourseLanguages,
		);
	};
	setCourseFilter = (value: string) => {
		window.localStorage.setItem(
			LocalStorageKeys.CourseFilter,
			value,
		);
	};
	getCourseFilter = () => {
		return window.localStorage.getItem(
			LocalStorageKeys.CourseFilter,
		);
	};
	saveLogoutTriggeredFromPortal = (value: boolean) => {
		window.localStorage.setItem(
			LocalStorageKeys.LogoutTriggeredFromPortal,
			value.toString(),
		);
	};
	saveCurrentObjectId = (id: string) => {
		window.localStorage.setItem(LocalStorageKeys.CurrentObjectId, id);
	};
	saveProvider = (provider: string) => {
		window.localStorage.setItem(LocalStorageKeys.Provider, provider);
	};
	getProvider = () => {
		return window.localStorage.getItem(LocalStorageKeys.Provider);
	};
	setLoginFirstRedirectHasBeenMade = (value: boolean) => {
		window.localStorage.setItem(LocalStorageKeys.LoginFirstRedirectHasBeenMade, value.toString());
	};
	getLoginFirstRedirectHasBeenMade = () => {
		return window.localStorage.getItem(LocalStorageKeys.LoginFirstRedirectHasBeenMade) === "true" || false;
	};

	clearNonPersistentItems = () => {
		const lang = this.getLanguage();
		const timeZone = this.getSelectedTimeZone();
		const selectedCustomerId = this.getSelectedCustomerId();
		const logoutTriggeredFromPortal = this.getLogoutTriggeredFromPortal();
		const translations = this.getTranslations();
		const selectedCourseLanguages = this.getSelectedCourseLanguages();

		window.localStorage.clear();

		if (lang) {
			this.setLanguage(lang);
		}
		if (translations) {
			this.setTranslations(translations);
		}
		if (timeZone) {
			this.setSelectedTimeZone(timeZone);
		}
		if (selectedCustomerId) {
			this.setSelectedCustomerId(selectedCustomerId);
		}
		if (logoutTriggeredFromPortal) {
			this.saveLogoutTriggeredFromPortal(logoutTriggeredFromPortal);
		}
		if (selectedCourseLanguages) {
			this.setSelectedCourseLanguages(selectedCourseLanguages);
		}
	};
}
