import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import CheckMarkIcon from "@murphy-frontend/web-core/assets/icons/CheckMarkIcon.svg";
import { BsExclamationCircle } from 'react-icons/bs';
import {
  RiBroadcastFill, RiBookOpenLine,
} from 'react-icons/ri';
import { GiBullseye, GiPolarStar } from "react-icons/gi";
import { BiSolidPyramid } from "react-icons/bi";

import PortalModule from "./PortalModule";
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';
import { useUserPreferences } from "../../common/contexts/UserPreferencesContext";
import { enabledProducts } from "../../common/variables";
import { useUser } from "../../common/contexts/UserContext";
import { Step, Steps } from 'intro.js-react';
import { OnboardingFlowIds, useOnboarding } from '../../common/contexts/OnboardingContext';
import { IconContext } from 'react-icons';
import { useInjection } from "@murphy-frontend/common/contexts/InversifyContext";
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { openInNewTab } from "@murphy-frontend/common/utils";
import { OnlineEducationApi } from '../OnlineEducation/api/OnlineEducationApi';

const Portal: FunctionComponent = () => {
  const { openModal } = useModal();
  const { translations } = useUserPreferences();

  const navigate = useNavigate();
  const { user } = useUser();
  const { setOnboardingIdIsComplete, incompletedOnboardingFlows } = useOnboarding();

  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  // produce state variables for the 5 consts above
  const [trainingEnabled, setTrainingEnabled] = useState<boolean>(null);
  const [mbsEnabled, setMbsEnabled] = useState<boolean>(null);
  const [crisisEnabled, setCrisisEnabled] = useState<boolean>(null);
  const [guidanceEnabled, setGuidanceEnabled] = useState<boolean>(null);
  const [excersisesEnabled, setExcersisesEnabled] = useState<boolean>(null);
  const [pyramidEnabled, setPyramidEnabled] = useState<boolean>(null);
  const [eventFlowEnabled, setEventFlowEnabled] = useState<boolean>(null);
  const [elearningSweEnabled, setElearningsweEnabled] = useState<boolean>(null);
  const [elearningFtgEnabled, setElearningftgEnabled] = useState<boolean>(null);

  // make a useEffect that sets these state variables based on user
  useEffect(() => {
    setTrainingEnabled(user?.traningEnabled && enabledProducts.training === "true");
    setMbsEnabled(user?.mbsEnabled && enabledProducts.mbs === "true");
    setCrisisEnabled(user?.whiteEnabled && enabledProducts.white === "true");
    setGuidanceEnabled(user?.guidanceEnabled);
    setExcersisesEnabled(user?.excersisesEnabled && enabledProducts.exercise === "true");
    setPyramidEnabled(user?.myMurphyEnabled);
    setEventFlowEnabled(user?.eventFlowsEnabled);
    setElearningsweEnabled(user?.elearningSweEnabled);
    setElearningftgEnabled(user?.elearningFtgEnabled);
  }, [user]);

  // Create initialSteps based on what is enabled
  const createInitialSteps = (): Step[] => {
    const steps = Array<Step>();
    if (trainingEnabled) {
      steps.push({
        element: '.background-item1',
        intro: `${translations.coursesmodulename}: Join others and book live courses to educate yourself in crisis resilience and management.`,
      });
    }
    if (excersisesEnabled) {
      steps.push({
        element: '.background-item2',
        intro: `${translations.excercisesmodulename}: Time to move from theory to practice! Here you can find the exercises you have in your organization or book an exercise to level up your crisis management capabilities.`,
      });
    }
    if (guidanceEnabled) {
      steps.push({
        element: '.background-item6',
        intro: `${translations.guidance}: a place to take part of pre-recorded material as a guidance on how to handle a crisis situation.`,
      });
    }
    if (mbsEnabled) {
      steps.push({
        element: '.background-item3',
        intro: `${translations.mbsmodulename}: Send an alert to the crisis organization about a situation that requires their attention.`,
      });
    }
    if (crisisEnabled) {
      steps.push({
        element: '.background-item4',
        intro: `${translations.whitemodulename}: Collaborate and handle the ongoing event with the help of the dashboards!`,
      });
    }
    if (pyramidEnabled) {
      steps.push({
        element: '.background-item5',
        intro: `${translations.pyramidmodulename}: The pyramid is a tool to help you prepare for a crisis. It is a way to structure your work and make sure you have covered all the bases.`,
      });
    }
    if (elearningSweEnabled) {
      steps.push({
        element: '.background-item6',
        intro: `${translations.elearningswemodulename}: E-learning för anställda.`,
      });
    }
    if (elearningFtgEnabled) {
      steps.push({
        element: '.background-item8',
        intro: `${translations.elearningftgmodulename}: E-learning for FTG.`,
      });
    }
    

    steps.push({
      element: '.card-icon',
      intro: `Profile: This is where you can find your own info such as the courses you have attended and reach other customers in your organizaton.`,
    });

    steps.push({
      element: '.menu-icon2',
      intro: `Settings: Change system language and reach our knowledge base. As an Admin this is where you find your tools!`,
    });

    steps.push({
      element: '.menu-icon',
      intro: `Menu: Reach all the modules including the settings for the Alert module.`,
    });

    // set tooltipClass to 'tooltip-class' on all steps
    steps.forEach(step => {
      step.tooltipClass = 'tooltip-class';
    });

    return steps;
  };

  const [steps, setSteps] = useState<Step[]>([]);
  const [initialStep, setInitialStep] = useState<number>(0);
  const [stepsEnabled, setStepsEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (!user) return;
    // check that all products are not null before proceeding
    if (trainingEnabled === null || mbsEnabled === null || crisisEnabled === null || guidanceEnabled === null || excersisesEnabled === null  || elearningSweEnabled || elearningFtgEnabled ) return;
    const newSteps = createInitialSteps();
    setSteps(newSteps);
  }, [user, trainingEnabled, mbsEnabled, crisisEnabled, guidanceEnabled, excersisesEnabled]);

  useEffect(() => {
    if (steps && steps.length > 0) {
      const shouldShowOnboarding = incompletedOnboardingFlows.map(p => p.OnboardingFlowId).includes(OnboardingFlowIds.Portal);
      setStepsEnabled(shouldShowOnboarding);
    }
  }, [incompletedOnboardingFlows, steps]);

  const onExit = () => {
    // setStepsEnabled(false);
  };

  const onComplete = () => {
    setStepsEnabled(false);
    setOnboardingIdIsComplete(OnboardingFlowIds.Portal);
  }

  const MissingProductMessage = () => <div>{translations.unauthorized}</div>;

  const onMissingProduct = () => {
    const modalConf: ModalConfiguration = {
      title: translations.unauthorized,
      body: <MissingProductMessage />,
      buttonText: "Ok",
      type: "warning",
    };

    openModal(modalConf);
  };

  const onClickEducation = () => {
    if (trainingEnabled === true) {
      navigate("/courses");
    } else {
      onMissingProduct();
    }
  };

  const EducationModule = (
    <PortalModule
      onClick={onClickEducation}
      buttonIcon={
        <IconContext.Provider value={{ className: '' }}><RiBookOpenLine /></IconContext.Provider>
      }
      title={translations.coursesmodulename}
      footer="PREPARATUS SUPERVIVET."
      customClasses="background-item1"
    />
  );

  const onClickTraining = () => {
    navigate("/excersises");
  };

  const TrainingModule = (
    <PortalModule
      onClick={onClickTraining}
      buttonIcon={
        <IconContext.Provider value={{ className: '' }}><GiBullseye /></IconContext.Provider>
      }
      title={translations.excercisesmodulename}
      footer="TRAIN HARD, FIGHT EASY"
      customClasses="background-item2"
    />
  );

  const onClickGuidance = () => {
    if (guidanceEnabled === true) {
      navigate("/guidance");
    } else {
      onMissingProduct();
    }
  };
  const GuidanceModule = (
    <PortalModule
      onClick={onClickGuidance}
      buttonIcon={
        <IconContext.Provider value={{ className: '' }}><GiPolarStar /></IconContext.Provider>
      }
      title={translations.guidance}
      footer="I'M TOLD THERE'S NO GOING BACK. SO I'M CHOOSING FORWARD"
      customClasses="background-item6"
    />
  );

  const onClickMBS = () => {
    if (mbsEnabled === true) {
      navigate("/pbscreatenew");
    } else {
      onMissingProduct();
    }
  };

  const MBSModule = (
    <PortalModule
      onClick={onClickMBS}
      title={translations.mbsmodulename}
      buttonIcon={
        <IconContext.Provider value={{ className: '' }}><RiBroadcastFill /></IconContext.Provider>
      }
      footer="LET THEM KNOW. NOW."
      customClasses="background-item3"
    />
  );

  const onClickCrisis = () => {
    if (crisisEnabled === true) {
      navigate("/crisis");
    } else {
      onMissingProduct();
    }
  };

  const CrisisModule = (
    <PortalModule
      onClick={onClickCrisis}
      buttonIcon={
        <IconContext.Provider value={{ className: '' }}><BsExclamationCircle /></IconContext.Provider>
      }
      title={translations.whitemodulename}
      footer="RULE COMPLEXITY WITH SIMPLICITY."
      customClasses="background-item4"
    />
  );

  const onClickPyramid = () => {
    if (pyramidEnabled === true) {
      navigate("/murphystandard");
    } else {
      onMissingProduct();
    }
  };

  const PyramidModule = (
    <PortalModule
      onClick={onClickPyramid}
      buttonIcon={
        <IconContext.Provider value={{ className: '' }}><BiSolidPyramid /></IconContext.Provider>
      }
      title={translations.pyramidmodulename}
      footer="ARE YOU PREPARED?"
      customClasses="background-item5"
    />
  );

  const onClickEventFlows = () => {
    if (eventFlowEnabled === true) {
      navigate("/startedeventflows");
    } else {
      onMissingProduct();
    }
  };

  const EventFlowModule = (
    <PortalModule
      onClick={onClickEventFlows}
      buttonIcon={
        <div className='module-custom-icon'>
          <CheckMarkIcon />
        </div>

      }
      title={translations.eventflowsmodulename}
      footer="ACTIVATE PROTOCOL"
      customClasses="background-item7"
    />
  );

  const onClickElearningSwe = async () => {
    if (elearningSweEnabled === true) {
      const onlineEducationApi = new OnlineEducationApi(apiService);
      try {
        const tokenUrl = await onlineEducationApi.getTokenUrl('https://edu.murphysolution.com/courses/take/grunder-inom-krisledning-och-krisberedskap/lessons/58056565-introduktion-till-krishantering');
        openInNewTab(tokenUrl);
      } catch (error) {
        console.error('Error fetching token URL:', error);
        // Handle error, e.g., show an error message to the user
      }
    } else {
      onMissingProduct();
    }
  };

  const ElearningsweModule = (
    <PortalModule
      onClick={onClickElearningSwe}
      buttonIcon={
        <IconContext.Provider value={{ className: '' }}><GiPolarStar /></IconContext.Provider>
      }
      title={translations.elearningswemodulename}
      footer=""
      customClasses="background-item6"
    />
  );

  const onClickElearningFtg = async () => {
    if (elearningFtgEnabled === true) {
      const onlineEducationApi = new OnlineEducationApi(apiService);
      try {
        const tokenUrl = await onlineEducationApi.getTokenUrl('https://edu.murphysolution.com/courses/take/crisis-management-ftg/lessons/56340260-murphy-vetalum-welcome');
        openInNewTab(tokenUrl);
      } catch (error) {
        console.error('Error fetching token URL:', error);
        // Handle error, e.g., show an error message to the user
      }
    } else {
      onMissingProduct();
    }
  };

  const ElearningFtgModule = (
    <PortalModule
      onClick={onClickElearningFtg}
      buttonIcon={
        <IconContext.Provider value={{ className: '' }}><GiPolarStar /></IconContext.Provider>
      }
      title={translations.elearningftgmodulename}
      footer="For FTG"
      customClasses="background-item6"
    />
  );

  return (
    <div className="vertical-aligner horizontal-aligner">
      <Steps
        enabled={stepsEnabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
        onComplete={onComplete}
        options={{ hideNext: false, hidePrev: true, exitOnOverlayClick: false }}
      />
      <div className="portal-grid">
        {trainingEnabled && (
          <>
            <div className="vertical-aligner horizontal-aligner">
              {EducationModule}
            </div>
          </>
        )}
        {excersisesEnabled && (
          <div className="vertical-aligner horizontal-aligner">
            {TrainingModule}
          </div>
        )}
        {guidanceEnabled && (
          <div className="vertical-aligner horizontal-aligner">
            {GuidanceModule}
          </div>
        )}
        {mbsEnabled && (
          <div className="vertical-aligner horizontal-aligner">{MBSModule}</div>
        )}
        {crisisEnabled && (
          <div className="vertical-aligner horizontal-aligner">
            {CrisisModule}
          </div>
        )}
        {pyramidEnabled && (
          <div className="vertical-aligner horizontal-aligner">
            {PyramidModule}
          </div>
        )}
        {eventFlowEnabled &&
          <div className="vertical-aligner horizontal-aligner">
            {EventFlowModule}
          </div>
        }
         {elearningSweEnabled &&
          <div className="vertical-aligner horizontal-aligner">
            {ElearningsweModule}
          </div>
        }
         {elearningFtgEnabled &&
          <div className="vertical-aligner horizontal-aligner">
            {ElearningFtgModule}
          </div>
        }
      </div>
    </div>
  );
};

export default Portal;
