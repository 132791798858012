import { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { DashboardCardFilter } from '../models';

export interface Party {
    ID: number,
    PartyId: number,
    PartyGuid: string,
    PartyName: string,
    PartyInfoId: number,
    CreatedDateTimeUtc: Date,
    VersionToUtc: Date,
    archived: boolean,
    createdby: string,
    action: string,
    DashboardId: number,
}

export interface UpsertParty {
    PartyId?: number,
    PartyInfoId?: number,
    PartyName?: string,
    PartyGuid?: string,
    archived: boolean,
}

export class PartyApi {
    parentId: string;
    baseUrl: string;
    apiService: IApiService;

    constructor(apiService: IApiService, dashboardId: string) {
        this.parentId = dashboardId
        this.baseUrl = `api/Dashboard/${dashboardId}/Party`;
        this.apiService = apiService;
    }

    getById = (id: string) => {
        const url = `${this.baseUrl}/${id}`;
        return this.apiService.callApi(url, 'GET', null);
    }

    getAll = (filter?: DashboardCardFilter) => {
        let searchParamsString = '';
        if (filter) {
            const searchParams = new URLSearchParams();

            if (filter.fromDate) {
                searchParams.append('fromDate', filter.fromDate.toUTCString());
            }
            searchParamsString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
        }
        const url = `${this.baseUrl}${searchParamsString}`;
        return this.apiService.callApi(url, 'GET', null);
    }

    upsertParty = (upsertParty: UpsertParty) => {
        const url = `${this.baseUrl}`;
        return this.apiService.callApi(url, 'POST', upsertParty);
    }
};
