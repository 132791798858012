import { View, Text, } from "@react-pdf/renderer"
import * as React from "react"
import { ActionCardStatuses } from "@murphy-frontend/common/enums";
import { convertBreaksToNewLine } from "@murphy-frontend/common/utils";
import { ActionsCard } from "../../../api/ActionsCardApi"

const getStatusName = (status: number, translations: Record<string, string>) => {
    switch (status) {
        case (ActionCardStatuses.Done):
            return translations['class-lang-modal-status-finished'];
        case (ActionCardStatuses.NotStarted):
            return translations['class-lang-modal-status-notstarted'];
        case (ActionCardStatuses.Ongoing):
            return translations['class-lang-modal-status-ongoing'];
        default:
            return translations['class-lang-modal-status-notstarted'];
    }
}

export const ActionsCardPdfRow = (translations: Record<string, string>, styles: any, actionsCard: ActionsCard) => {

    const statusText = getStatusName(actionsCard.Status, translations);

    return <View>
        <Text>
            <Text style={styles.label}>
                {translations['statusname']}:&nbsp;
            </Text>
            <Text>
                {statusText}
            </Text>
        </Text>
        {actionsCard.Message ?
            <Text>
                <Text style={styles.label}>
                    {translations['class-lang-modal-description']}:&nbsp;
                </Text>
                <Text>
                    {convertBreaksToNewLine(actionsCard.Message)}
                </Text>
            </Text> : null
        }
        {actionsCard.Owner ?
            <Text>
                <Text style={styles.label}>
                    {translations['class-lang-modal-decision-maker']}:&nbsp;
                </Text>
                <Text>
                    {actionsCard.Owner}
                </Text>
            </Text> : null
        }
        {actionsCard.ProvidedBy ?
            <Text break>
                <Text style={styles.label}>
                    {translations['class-lang-modal-responsible']}:&nbsp;
                </Text>
                <Text>
                    {actionsCard.ProvidedBy}
                </Text>
            </Text> : null
        }
        {actionsCard.Important === true ?
            <Text>
                <Text style={styles.label}>
                    {translations['class-lang-modal-check-priority']}:&nbsp;
                </Text>
                <Text>
                    {translations.yes}
                </Text>
            </Text> : null
        }
        {actionsCard.isDecision === true ?
            <Text>
                <Text style={styles.label}>
                    {translations['class-lang-modal-decision-check']}:&nbsp;
                </Text>
                <Text>
                    {translations.yes}
                </Text>
            </Text> : null
        }
        {actionsCard.isCommunication === true ?
            <Text>
                <Text style={styles.label}>
                    {translations['iscommunication']}:&nbsp;
                </Text>
                <Text>
                    {translations.yes}
                </Text>
            </Text> : null
        }
    </View>
}

