import React, { } from "react";
import { UserListCourseViewModel } from '@murphy-frontend/web-core/features/UsersAdmin/components/UsersList';
import { FilterCourses } from "../components/FilterCourses";
import { FilterCourseBtn } from "../components/FilterCourseBtn";
import Button from '@murphy-frontend/web-core/components/Button';


interface IProps {
  allCourses?: UserListCourseViewModel[];
  filterLanguage?: number[];
  filterStatus?: string[];
  filterCourse?: number[];
  translations?: Record<string, string>;
  languageDefault?: number;
  toggleModal: () => void;
  filterOnCourse: (id: number) => void;
  languageFilterHandler: (language: number) => void;
  statusFilterHandler: (status: string) => void;
  allFilterHandler: () => void;
  clearFilterHandler: () => void;
}

export const FilterModal = ({ allCourses, filterLanguage, filterStatus, filterCourse, languageDefault, toggleModal, translations, filterOnCourse, languageFilterHandler, statusFilterHandler, allFilterHandler, clearFilterHandler }: IProps) => {


  return (
    <>
      <div className="modal-header">
        <span
          onClick={toggleModal}
          onKeyUp={toggleModal}
          role="button"
          tabIndex={0}
          className="close"
        >
          &times;
        </span>
        <h2>{translations.eamodaltitle}</h2>
      </div>
      <div className="modal-body">
        <div>
          <div className="filter-control-wrapper">
            <div className="filter-control-wrapper">
              <div className="table-column-group-header-label">
                {translations.eamodaltitle}
              </div>
              <div className="filter-control-container">
                {allCourses?.map((course) => {
                  return (
                    <React.Fragment key={course.id}>
                      <FilterCourses
                        filterCourse={filterCourse}
                        course={course}
                        filterOnCourse={filterOnCourse}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
            <div className="filter-control-wrapper">
              <div className="filter-control-container">
                <FilterCourseBtn
                  allCourses={allCourses}
                  filterCourse={filterCourse}
                  name={translations.eamodalbtnall}
                  selectable={false}
                  filterAction={() =>
                    allFilterHandler()
                  }
                  languageDefault={languageDefault}
                />
                <FilterCourseBtn
                  name={translations.eamodalbtnclear}
                  selectable={false}
                  filterAction={() => clearFilterHandler()}
                />
              </div>
              <div className="table-column-group-header-label">{translations.eamodallangtitle}</div>
              <div className="filter-control-container">
                <FilterCourseBtn
                  name={translations.eamodallangbtnswe}
                  defaultSelected={filterLanguage.includes(1)}
                  selectable={true}
                  filterAction={() => languageFilterHandler(1)}
                  languageDefault={languageDefault}
                />
                <FilterCourseBtn
                  name={translations.eamodallangbtneng}
                  defaultSelected={filterLanguage.includes(2)}
                  selectable={true}
                  filterAction={() => languageFilterHandler(2)}
                  languageDefault={languageDefault}
                />
                <FilterCourseBtn
                  name={translations.eamodallangbtnnor}
                  defaultSelected={filterLanguage.includes(3)}
                  selectable={true}
                  filterAction={() => languageFilterHandler(3)}
                  languageDefault={languageDefault}
                />
              </div>
              <div className="table-column-group-header-label">{translations.eamodalstatustitle}</div>
              <div className="filter-control-container">
                <FilterCourseBtn
                  name={translations.eamodalstatusbtnbooked}
                  defaultSelected={filterStatus.includes(
                    "trainingBooked"
                  )}
                  selectable={true}
                  filterAction={() =>
                    statusFilterHandler("trainingBooked")
                  }
                />
                <FilterCourseBtn
                  name={translations.eamodalstatusbtncompleted}
                  defaultSelected={filterStatus.includes(
                    "trainingCompleted"
                  )}
                  selectable={true}
                  filterAction={() =>
                    statusFilterHandler("trainingCompleted")
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer modal-filter-footer">
        <Button
          buttonStyle="btn--primary"
          buttonSize="btn--medium"
          onClick={toggleModal}
        >
          {translations.eamodalbtnsave}
        </Button>
      </div>
    </>

  )
}