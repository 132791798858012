import React, { FunctionComponent } from 'react';
import ContactGroupListItem from './ContactGroupListItem';


interface ContactGroupListProps {
  contacts: any[],
  onClickEdit: (id: any) => void,
  onClickDelete: (id: any) => void,
}

const ContactGroupList: FunctionComponent<ContactGroupListProps> = ({
  contacts, onClickEdit, onClickDelete,
}) => {
  return (
    <ul>
      {contacts.map((p) => (
        <ContactGroupListItem
          key={p.id}
          id={p.id}
          name={p.name}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
        />
      ))}
    </ul>
  );
}

export default ContactGroupList;