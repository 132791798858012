import { FunctionComponent } from "react";
import React from "react";
import TitleWithControls from "./TitleWithControls";
import { DroneCheckListItemType, DroneComponentCheckList, DroneComponentCheckListItem, DroneComponentCheckListItemTextInput } from "@murphy-frontend/common/api/DroneEvents/types";
import TextInputComponent from "./CheckListComponents/TextInputComponent";
import CheckboxInputComponent from "./CheckListComponents/CheckBoxInputComponent";

interface CheckListComponentProps {
    checkListComponent: DroneComponentCheckList;
}

const CheckListComponent: FunctionComponent<CheckListComponentProps> = (
    {
        checkListComponent: eventFlowBlock,
    }) => {

    const getCheckListComponentBasedOnType = (checkListItem: DroneComponentCheckListItem) => {

        const { __Type: __type } = checkListItem;

        switch (__type) {
            case DroneCheckListItemType.TextInput:
                return <TextInputComponent
                    eventFlowRespondBlock={checkListItem}
                />;
            case DroneCheckListItemType.Checkbox:
                return <CheckboxInputComponent
                    eventFlowRespondBlock={checkListItem}
                />;
            case DroneCheckListItemType.Select:
                return (
                    <div>
                        {checkListItem.Value}
                    </div>
                );
            default:
                return <input type="text" />;
        }
    }

    const content = <span>
        {eventFlowBlock.Title}
    </span>;

    const respondBlocks = eventFlowBlock.Items
        .sort((a, b) => { return a.Ranking - b.Ranking; })
        .map((item) => {
            return (
                <React.Fragment key={item.Id}>
                    {getCheckListComponentBasedOnType(item)}
                </React.Fragment>
            )
        }
        );

    return (
        <>
            <div className="m-spacing"></div>
            <div className="m-text">
                <TitleWithControls
                    content={content}
                />
            </div>
            {respondBlocks}
        </>

    );
}

export default CheckListComponent;