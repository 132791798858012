import ApiServiceType, { IApiService } from "@murphy-frontend/common/interfaces/IApiService";
import { inject, injectable } from "inversify";

export interface TrainingModel {
    Id: number,
    TrainingTypeId: number,
    Name: string,
    Description: string,
    ImageUrl: string,
    HtmlDescription: string,
    TrainingLanguageId?: number,
    CustomerId?: string,
    MaxAttendees?: number,
    PublicSector?: boolean,
}

export interface TrainingSessionModel {
    ID: number,
    TrainingId: number,
    Name: string,
    Desc: string,
    StartDateTimeUtc: Date,
    EndDateTimeUtc: Date,
    CustomerId?: string,
    ExternalLink: string,
    LastTimeToEnterUtc: Date,
    Educator: string,
    UseExternalLink: boolean,
    UrlToMeeting: string,
    AttendeeSpotLeft?: number | null
}

@injectable()
export class TrainingApi {

    constructor(
        @inject(ApiServiceType.IApiService) private apiService: IApiService,
    ) { }

    getAllTrainings = async (
        {
            customerId = null,
            customerUserId = null,
        } = {},
    ): Promise<TrainingModel[]> => {
        let baseUrl = 'api/Training/Training';
        const searchParams = new URLSearchParams();

        if (customerUserId) {
            searchParams.append('customerUserId', customerUserId);
        }
        if (customerId) {
            searchParams.append('customerId', customerId);
        }

        const searchParamsString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
        const url = `${baseUrl}${searchParamsString}`;

        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };

    getTraining = async (id: number): Promise<TrainingModel> => {
        const url = `api/Training/Training/${id}`;
        const { data } = await this.apiService.callApiWithoutToken(url, 'GET');
        return data
    };

    getTrainingSessions = async (
        {
            customerId = null,
            trainingId = null,
        } = {},
    ): Promise<TrainingSessionModel[]> => {
        const baseUrl = 'api/Training/TrainingSession';
        const searchParams = new URLSearchParams();

        if (customerId) {
            searchParams.append('customerId', customerId);
        }
        if (trainingId) {
            searchParams.append('trainingId', trainingId);
        }

        const searchParamsString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
        const url = `${baseUrl}${searchParamsString}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };

    getTrainingSession = async (id: number): Promise<TrainingSessionModel> => {
        const url = `api/Training/TrainingSession/${id}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };
}

const TrainingApiType = {
    TrainingApiType: Symbol("TrainingApi"),
};

export default TrainingApiType;