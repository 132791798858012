import * as React from 'react';
import { FunctionComponent } from 'react';
import { PartyPeople, UpsertPartyPeople } from '../../api/PartyPeopleApi';
import PartyPeopleSubListItem from './PartyPeopleSubListItem';

interface PartyPeopleContactListProps {
    translations: Record<string, string>,
    partyId: number,
    partyPeople: PartyPeople[],
    openModal: any,
    closeModal: any,
    dashboardId: string
}

const PartyPeopleContactList: FunctionComponent<PartyPeopleContactListProps>
    = ({ partyId, translations, partyPeople, openModal, closeModal, dashboardId }) => {
        return (
            <div>
                {partyPeople.map(p => {
                    return <PartyPeopleSubListItem
                        partyPeople={p}
                        dashboardId={dashboardId}
                        openModal={openModal}
                        closeModal={closeModal}
                        translations={translations} />
                })
                }
            </div>
        );
    }

export default PartyPeopleContactList;