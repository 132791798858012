import { useState } from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { ErrorBoundary } from 'react-error-boundary';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";

import 'intro.js/introjs.css';
import { registerLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';
import nb from 'date-fns/locale/nb';

import {
  Route,
  BrowserRouter as Router, Routes, Outlet,
} from 'react-router-dom';
import { ModalProvider } from '@murphy-frontend/web-core/contexts/ModalContext'
import { WebAuthProvider } from '@murphy-frontend/web-core/features/auth/WebAuthProvider';
import { AuthProviderProps } from "react-oidc-context";

import ErrorFallback from './common/components/ErrorFallback';
import MBSResponse from './features/MBS/MBSResponse/MBSResponse';
import Training from './features/CourseRegistration/pages/Training';
import Navbar from './navigation/Navbar';
import * as React from 'react';
import UserPreferencesProvider from './common/contexts/UserPreferencesContext';
import { auth, enabledFeatures, enabledProducts, includeOnlyPublicRoutes, logToApi } from './common/variables';
import { LogService } from './common/services/LogService';
import LoginPage from './features/Login/LoginPage';
import MBSCreateNew from './features/MBS/MBSCreateNew';
import MBSList from './features/MBS/MBSList/MBSList';
import MBSItem from './features/MBS/MBSItem/MBSItem';
import Contacts from './features/Contacts';
import ContactGroups from './features/ContactGroups';
import ContactGroup from './features/ContactGroups/pages/ContactGroup';
import CoursePortal from './features/CourseRegistration';
import Course from './features/CourseRegistration/pages/Course';
import GuidancePortal from "./features/Guidance";
import ExcersisePortal from "./features/ExcerciseRegistration";
import UpsertDashboard from "./features/DashboardAdmin/pages/UpsertDashboard";
import CrisisPortal from "./features/CrisisManagement";
import Dashboard from "./features/Dashboard";
import Portal from "./features/Portal";
import Me from "./features/UsersAdmin/pages/Me";
import UsersAdmin from "./features/UsersAdmin";
import { EducationAdmin } from "./features/EducationAdmin";
import EditUser from "./features/UsersAdmin/pages/EditUser";
import AddUserFromExistingAccount from "./features/UsersAdmin/pages/AddUserFromExistingAccount";
import AddUser from "./features/UsersAdmin/pages/AddUser";
import NotFound from '@murphy-frontend/web-core/components/NotFound'
import { UserProvider } from "./common/contexts/UserContext";
import { CustomerProvider } from "./common/contexts/CustomerContext";
import { WebStorageStateStore } from 'oidc-client-ts';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import OidcWrapper from '@murphy-frontend/web-core/features/auth/OidcWrapper';
import { OnboardingProvider } from './common/contexts/OnboardingContext';
import MyMurphy from './features/MyMurphy';

// the locale you want

import localizedFormat from "dayjs/plugin/localizedFormat";
import PrivateOutlet from './common/authentication/PrivateOutlet';
import FirstLoginPage from './features/Signup/SignupPage';
import EventFlows from './features/EventFlows';
import StartedEventFlowPage from './features/EventFlows/pages/StartedEventFlowPage';

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.utc().isUTC();
require("dayjs/locale/sv");
require("dayjs/locale/nb");

registerLocale("sv", sv); // register it with the name you want
registerLocale("nb", nb); // register it with the name you translateConstantsWithBackendTranslations

function App() {
  const [isCrashed, setIsCrashed] = useState(true);
  const appInsights = useAppInsightsContext();
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);

  const oidcConfig: AuthProviderProps = {
    authority: auth.baseAuthServerUrl,
    client_id: auth.clientId,
    redirect_uri: auth.redirectUrl,
    silent_redirect_uri: auth.redirectUrl,
    post_logout_redirect_uri: auth.postLogoutUrl,
    response_type: "code",
    automaticSilentRenew: true,
    scope: 'openid murphy_api offline_access',
    userStore: new WebStorageStateStore({
      store: localStorage
    }),
    // metadata: {
    //   issuer: auth.baseAuthServerUrl,
    //   authorization_endpoint: `${auth.baseAuthServerUrl}/connect/authorize`,
    //   token_endpoint: `${auth.baseAuthServerUrl}/connect/token`,
    //   end_session_endpoint: `${auth.baseAuthServerUrl}/connect/logout`,
    // },
  }

  const appErrorHandler = (error: Error, errorInfo: any) => {
    if (logToApi == "true") {
      // log to api
      const logService = new LogService(apiService);
      logService.error(error);
    } else {
      appInsights.trackException({
        error,
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: errorInfo,
      });
    }
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={appErrorHandler}
      onReset={() => setIsCrashed(false)}
      resetKeys={[isCrashed]}
    >
      <ModalProvider>
        <UserPreferencesProvider>
          <Router>
            <OidcWrapper {...oidcConfig}>
              <WebAuthProvider>
                <CustomerProvider>
                  <UserProvider>
                    <OnboardingProvider>
                      <Navbar />
                      <Routes>
                        <Route path="*" element={<NotFound />} />
                        <Route path="/login" element={<LoginPage />}></Route>
                        <Route path="/signup" element={<FirstLoginPage />}></Route>
                        <Route
                          path="/pbsresponse/:id"
                          element={<MBSResponse />}
                        ></Route>
                        <Route path="/training/:id" element={<Training />}></Route>

                        {includeOnlyPublicRoutes !== "true" && (
                          <Route element={<PrivateOutlet />}>
                            <Route path="/portal" element={<Portal />} />
                            <Route path="/" element={<Portal />} />
                            {enabledProducts.mbs === "true" && (
                              <>
                                <Route
                                  path="/pbscreatenew"
                                  element={<MBSCreateNew />}
                                />
                                <Route path="/pbslist" element={<MBSList />} />
                                <Route
                                  path="/pbssendoutbatch/:id"
                                  element={<MBSItem />}
                                />
                                <Route path="/contacts" element={<Contacts />} />
                                <Route
                                  path="/contactgroups"
                                  element={<ContactGroups />}
                                />
                                <Route
                                  path="/contactgroup/:id"
                                  element={<ContactGroup />}
                                />
                              </>
                            )}
                            {enabledProducts.training === "true" && (
                              <>
                                <Route path="/courses" element={<CoursePortal />} />
                                <Route path="/course/:id" element={<Course />} />
                                <Route
                                  path="/training/:id"
                                  element={<Training />}
                                />
                              </>
                            )}
                            <>
                              <Route
                                path="/guidance"
                                element={<GuidancePortal />}
                              />
                            </>
                            {enabledProducts.exercise === "true" && (
                              <>
                                <Route
                                  path="/excersises"
                                  element={<ExcersisePortal />}
                                />
                              </>
                            )}
                            {enabledProducts.white === "true" && (
                              <>
                                <Route path="/dashboards" element={<Outlet />}>
                                  <Route index element={<CrisisPortal />} />
                                  <Route path=":id" element={<UpsertDashboard />} />
                                  <Route path="new" element={<UpsertDashboard />} />
                                </Route>

                                <Route path="/dashboard" element={<Outlet />}>
                                  <Route index element={<CrisisPortal />} />
                                  <Route path=":id" element={<Dashboard />} />
                                </Route>

                                <Route path="/crisis" element={<CrisisPortal />} />
                              </>
                            )}
                            <Route path="/me" element={<Me />} />
                            <Route path="/users" element={<UsersAdmin />} />
                            <Route path="/users/:id" element={<EditUser />} />
                            <Route path="/education" element={<EducationAdmin />} />

                            {enabledFeatures.upsertUser === "true" && (
                              <Route
                                path="/usernew"
                                element={
                                  enabledFeatures.addUserFromExistingAccountMode ===
                                    "true" ? (
                                    <AddUserFromExistingAccount />
                                  ) : (
                                    <AddUser />
                                  )
                                }
                              />
                            )}
                            <Route path="/murphystandard" element={<MyMurphy />} />

                            <Route path="/startedeventflows" element={<Outlet />}>
                              <Route index element={<EventFlows />} />
                              <Route path=":id" element={<StartedEventFlowPage />} />
                              <Route path="new" element={<Outlet />} />
                            </Route>

                          </Route>



                        )}
                      </Routes>
                    </OnboardingProvider>
                  </UserProvider>
                </CustomerProvider>
              </WebAuthProvider>
            </OidcWrapper>
          </Router>

        </UserPreferencesProvider>
      </ModalProvider>
    </ErrorBoundary >
  );
}

export default App;
