import { inject, injectable } from "inversify";
import ApiServiceType, { IApiService } from "../../interfaces/IApiService";
import {
  ICustomersApi,
  MurphyBasicCustomerDto,
} from "../../interfaces/ICustomersApi";

@injectable()
export class CustomersApi implements ICustomersApi {
  constructor(
    @inject(ApiServiceType.IApiService) private apiService: IApiService
  ) {}

  getCustomersMobile = async (): Promise<MurphyBasicCustomerDto[]> => {
    const url = "/api/Customer";
    const { data } = await this.apiService.callApi(url, "GET");
    return data;
  };

  getCustomers = async (): Promise<MurphyBasicCustomerDto[]> => {
    const url = "/api/Customer/basic";
    const { data } = await this.apiService.callApi(url, "GET");
    return data;
  };

  getChildCustomers = async (
    customerId: string
  ): Promise<MurphyBasicCustomerDto[]> => {
    const url = `/api/Customer/${customerId}/children`;
    const { data } = await this.apiService.callApi(url, "GET");
    return data;
  };
  getCustomer = async (customerId: string): Promise<MurphyBasicCustomerDto> => {
    const url = `/api/Customer/${customerId}`;
    const { data } = await this.apiService.callApi(url, "GET");
    return data;
  };
}
