import { View, Text, } from "@react-pdf/renderer"
import * as React from "react"
import { convertBreaksToNewLine } from "@murphy-frontend/common/utils"
import { InformationCard } from "../../../api/InformationCardApi"

export const InformationsCardPdfRow = (translations: Record<string, string>, styles: any, informationCard: InformationCard) => {
    return <View>
        {informationCard.Message ?
            <Text>
                <Text style={styles.label}>
                    {translations['class-lang-modal-description']}:&nbsp;
                </Text>
                <Text>
                    {convertBreaksToNewLine(informationCard.Message)}
                </Text>
            </Text> : null
        }
        {informationCard.Owner ?
            <Text>
                <Text style={styles.label}>
                    {translations['class-lang-modal-decision-maker']}:&nbsp;
                </Text>
                <Text>
                    {informationCard.Owner}
                </Text>
            </Text> : null
        }
        {informationCard.ProvidedBy ?
            <Text>
                <Text style={styles.label}>
                    {translations['class-lang-modal-responsible']}:&nbsp;
                </Text>
                <Text>
                    {informationCard.ProvidedBy}
                </Text>
            </Text> : null
        }
        {informationCard.Important === true ?
            <Text break>
                <Text style={styles.label}>
                    {translations['class-lang-modal-check-priority']}:&nbsp;
                </Text>
                <Text>
                    {translations.yes}
                </Text>
            </Text> : null
        }
        {informationCard.isDecision === true ?
            <Text>
                <Text style={styles.label}>
                    {translations['class-lang-modal-decision-check']}:&nbsp;
                </Text>
                <Text>
                    {translations.yes}
                </Text>
            </Text> : null
        }
        {informationCard.Confirmed === true ?
            <Text>
                <Text style={styles.label}>
                    {translations['class-lang-modal-check-confirmed']}:&nbsp;
                </Text>
                <Text>
                    {translations.yes}
                </Text>
            </Text> : null
        }
    </View>
}

