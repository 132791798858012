import * as React from 'react';
import { FunctionComponent } from 'react';
import { DashboardLog } from '../../api/DashboardLogApi';

interface DashboardLogFormattedProps {
    dashboardLog: DashboardLog;
    translations: Record<string, string>
    cardFooter?: any
}

const DashboardLogFormatted: FunctionComponent<DashboardLogFormattedProps> = ({ dashboardLog, translations, cardFooter }) => {
    return (
        <div className="formatted-card">
            <p>
                <strong>{translations['class-lang-by-user']}</strong>&nbsp;{dashboardLog.user}
            </p>
            {cardFooter &&
                <>
                    <br />
                    <hr></hr>
                    <br />
                    {cardFooter}
                </>
            }
        </div >
    );
}

export default DashboardLogFormatted;