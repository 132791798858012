import { FunctionComponent, useState } from "react";
import * as React from "react";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "@murphy-frontend/web-core/components/Input";
import Button from "@murphy-frontend/web-core/components/Button";
import { MurphyBasicCustomerDto } from "@murphy-frontend/common/interfaces/ICustomersApi";
import ControlledFormSelect, {
  OptionType,
} from "@murphy-frontend/web-core/components/MurphySelect/ControlledFormSelect";
import { useDashboards } from "../../../../common/api/Dashboards/queries";
import FormLabel from "@murphy-frontend/web-core/components/FormLabel";

const childStatusCardsSchema = yup.object().shape({
  Title: yup.string().required(),
  ChildCustomerId: yup.string().required(),
  ChildDashboardId: yup.string().required(),
});

export interface ChildStatusCardFormDto {
  Title: string;
  ChildCustomerId: string;
  ChildDashboardId: string;
  ChildCustomerName: string;
  ChildDashboardName: string;
}

interface ChildStatusCardFormProps {
  childCustomers: MurphyBasicCustomerDto[];
  onSubmit: (dto: ChildStatusCardFormDto) => void;
  translations?: any;
  onClickArchive: () => void;
  isLoading?: boolean;
  childStatusCardDto: ChildStatusCardFormDto;
}

const ChildStatusCardForm: FunctionComponent<ChildStatusCardFormProps> = ({
  isLoading,
  onSubmit,
  onClickArchive,
  translations,
  childCustomers,
  childStatusCardDto,
}) => {
  const {
    control,
    watch,
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isDirty },
    reset,
    getValues,
    setValue,
  } = useForm<ChildStatusCardFormDto>({
    resolver: yupResolver(childStatusCardsSchema),
  });

  const [selectedCustomerDashboards, setSelectedCustomerDashboards] = useState<
    OptionType[]
  >([]);
  const [dashboardSelectKey, setDashboardSelectKey] = useState<number>(0);

  const watchCustomer = watch("ChildCustomerId");

  useEffect(() => {
    if (childStatusCardDto) {
      reset(childStatusCardDto);
    }
  }, [childStatusCardDto, reset]);

  useEffect(() => {
    if (watchCustomer && !childStatusCardDto) {
      setValue("ChildDashboardId", ""); // Resetting the value
      setDashboardSelectKey((prevKey) => prevKey + 1); // Force remount of the select component
    }
  }, [watchCustomer, setValue]);

  // Fetch dashboards for the selected customer
  const { data: dashboards, isLoading: dashboardsLoading } =
    useDashboards(watchCustomer);

  const onSubmitHandler = (data: ChildStatusCardFormDto) => {
    onSubmit(data);
  };

  const ref = useRef<HTMLFormElement>();
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === "Enter") {
        event.preventDefault();
        handleSubmit(onSubmitHandler)();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  const childCustomerOptions: OptionType[] = childCustomers.map((cust) => ({
    value: cust.Id,
    label: cust.Name,
  }));

  useEffect(() => {
    if (watchCustomer && dashboards && !dashboardsLoading) {
      const newSelectedCustomerDashboards = dashboards.filter(
        (dashboard) =>
          dashboard.CustomerId === watchCustomer && !dashboard.Arkiverad
      );
      if (newSelectedCustomerDashboards) {
        const dashboardsOptions = newSelectedCustomerDashboards.map(
          (dashboard) => ({
            value: dashboard.dashboardid,
            label: decodeURIComponent(dashboard.Name),
          })
        );
        setSelectedCustomerDashboards(dashboardsOptions);
      }
    }
  }, [watchCustomer, dashboards, dashboardsLoading]);

  return (
    <form ref={ref} onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="dashboardform-container">
        <div className="dashboardform-container-section">
          <Input
            isControlled
            register={register}
            name="Title"
            isRequired
            title={translations["class-lang-modal-title"]}
            errorMessage={errors.Title?.message}
          />
          {childStatusCardDto ? (
            <FormLabel
              title="License"
              text={childStatusCardDto.ChildCustomerName}
            />
          ) : (
            <ControlledFormSelect
              control={control}
              title="Child Customer"
              name="ChildCustomerId"
              label="Child Customer"
              options={childCustomerOptions}
              isMulti={false}
              disabled={!!childStatusCardDto}
            />
          )}
          {childStatusCardDto ? (
            <FormLabel
              title="Dashboard"
              text={childStatusCardDto.ChildDashboardName}
            />
          ) : watchCustomer && dashboardsLoading ? (
            <div className="small-spinner" />
          ) : (
            <ControlledFormSelect
              key={dashboardSelectKey}
              control={control}
              title="Child Dashboard"
              name="ChildDashboardId"
              label="Child Dashboard"
              options={selectedCustomerDashboards}
              disabled={!!childStatusCardDto}
              isMulti={false}
            />
          )}

          <div className="dashboardform-container-section">
            <div className="form-buttons">
              {childStatusCardDto && (
                <Button
                  type="button"
                  disabled={isDirty || isLoading}
                  onClick={() => onClickArchive()}
                  buttonStyle="btn--archive"
                  buttonSize="btn--medium"
                  isWarningButton={true}
                >
                  {translations.archive}
                </Button>
              )}
              {/* {isDirty && <Button disabled={!isDirty || isLoading} onClick={() => reset()} buttonStyle="btn--undo" buttonSize="btn--medium" isNeutralButton={true}>{translations.undo}</Button>} */}
            </div>
          </div>
          <div className="dashboardform-container-section">
            <div className="form-buttons save-button">
              <Button
                isLoading={isLoading}
                disabled={!isDirty || (errors && errors.length > 0)}
                type="submit"
                buttonStyle="btn--primary"
                buttonSize="btn--medium"
              >
                {translations.save}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChildStatusCardForm;
