import AuthServiceType, { IAuthService } from '@murphy-frontend/common/interfaces/IAuthService';
import container from '../../../inversify.config';
import { baseURL } from '../variables';
import { User } from 'oidc-client-ts';

const signalR = require('@microsoft/signalr');

export const createConnection = (dashboardId: string) => {
  const url = `${baseURL}/DashboardHub?dashboardid=${dashboardId}`;
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(url, {
      accessTokenFactory: () => {
        const authService = container.get<IAuthService>(AuthServiceType.IAuthService);
        const userStorageString = authService.getUserStorageString();
        const user = User.fromStorageString(userStorageString);
        return user.access_token;
        // return authService.getUserStorageString().then((user) => user.accessToken)
        //   .catch(error => {
        //     return authService.renewToken().then((user) => user.accessToken)
        //       .catch(err => {
        //         throw Error("Not authorized.")
        //       })
        //   });
      }
    })
    .configureLogging(signalR.LogLevel.Trace)
    .withAutomaticReconnect()
    .build();

  return connection;
};

export const createConnectionSendOut = (sendoutResponseId: string) => {
  const url = `${baseURL}/SendOutHub?sendOutId=${sendoutResponseId}`;
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(url, {
      accessTokenFactory: () => {
        const authService = container.get<IAuthService>(AuthServiceType.IAuthService);
        const userStorageString = authService.getUserStorageString();
        const user = User.fromStorageString(userStorageString);
        return user.access_token;
      }
    })
    .configureLogging(signalR.LogLevel.Trace)
    .withAutomaticReconnect()
    .build();

  return connection;
};