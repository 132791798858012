import * as React from "react";
import { DashboardEntityTypes } from "@murphy-frontend/common/enums";
import { getConvertedDateDayjs } from '@murphy-frontend/common/services/TimeService';
import { ActionsCard } from "../../../api/ActionsCardApi";
import ActionsCardExpandedContent from "../../ActionsCards/ActionsCardExpandedContent";
import { MainLogCard } from "../models";
import { BaseCardProps } from "./MainLogCardFactory";

export interface ActionsMainLogCardProps extends BaseCardProps {
    actionLogCard: ActionsCard,
}

export const ActionsCardMainLogCard =
    ({ translations, actionLogCard, timeZone, dashboardId, openModal, closeModal }: ActionsMainLogCardProps): MainLogCard => {
        const date = getConvertedDateDayjs(actionLogCard.AlternatedTime || actionLogCard.CreatedDateTime, timeZone);
        const expandedContent =
            <ActionsCardExpandedContent
                timeZone={timeZone}
                actionsCard={actionLogCard}
                dashboardId={dashboardId}
                openModal={openModal}
                closeModal={closeModal}
                translations={translations}
            />

        const searchableContent = [];
        searchableContent.push(actionLogCard.Title);
        if (actionLogCard.Owner)
            searchableContent.push(actionLogCard.Owner);
        if (actionLogCard.ProvidedBy)
            searchableContent.push(actionLogCard.ProvidedBy);
        if (actionLogCard.Message)
            searchableContent.push(actionLogCard.Message);
        if (actionLogCard.user)
            searchableContent.push(actionLogCard.user);

        const logCard: MainLogCard = {
            ID: `${actionLogCard.ID}-${DashboardEntityTypes.ActionsCard}`,
            Title: actionLogCard.Title,
            isArchived: actionLogCard.archived,
            isDecision: actionLogCard.isDecision,
            Status: actionLogCard.Status,
            SearchableContent: searchableContent,
            User: actionLogCard.user,
            Date: date,
            DateWithoutTime: date.format("YYYY-MM-DD"),
            Time: date.format("HH:mm"),
            EntityType: DashboardEntityTypes.ActionsCard,
            ExpandedContent: expandedContent,
            OriginalCard: actionLogCard,
        }
        return logCard;
    }