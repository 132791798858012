import { FunctionComponent } from "react";
import * as React from 'react'
import { StatusCard } from "../../api/StatusCardApi";
import ExpandableRow from "@murphy-frontend/web-core/components/DashboardTable/ExpandableRow";
import { StatusCardStatusService } from "./StatusCardStatusService";
import StatusIcon from "./StatusIcon";
import { IconContext } from 'react-icons';
import { FaLink, FaArrowDown } from 'react-icons/fa';
import ChildStatusCardExpandedContent from "./ChildStatusCardExpandedContent";

interface ChildStatusCardsTableRowProps {
    statusCard: StatusCard,
    translations: Record<string, string>;
    dashboardId: string;
    openModal: any,
    closeModal: any,
    statusCardStatusService: StatusCardStatusService,
}

const ChildStatusCardsTableRow: FunctionComponent<ChildStatusCardsTableRowProps> = ({ statusCard, dashboardId, translations, openModal, closeModal, statusCardStatusService }) => {

    const currentStatusIcon = <StatusIcon disabled={true} statusCardStatusService={statusCardStatusService} statusId={statusCard.Status} />
    const classes = [];
    classes.push('dashboard-table-icon');
    classes.push('disable');

    const header = <>
        <div className='left-icon-group'>
            {currentStatusIcon}
            <IconContext.Provider value={{ className: classes.join(' ') }}>
                <FaLink />
            </IconContext.Provider>
            <IconContext.Provider value={{ className: classes.join(' ') }}>
                <FaArrowDown />
            </IconContext.Provider>
        </div>
        <div className="dashboard-table-row-text">
            {statusCard.Title}
        </div>
    </>

    const expandableContent =
        <ChildStatusCardExpandedContent
            statusCard={statusCard}
            dashboardId={dashboardId}
            openModal={openModal}
            closeModal={closeModal}
            translations={translations}
            statusCardStatusService={statusCardStatusService} />;

    return <ExpandableRow
        headerContent={header} expandedContent={expandableContent} />
}

export default ChildStatusCardsTableRow;