import { View, Text, } from "@react-pdf/renderer"
import * as React from "react"
import { convertBreaksToNewLine } from "@murphy-frontend/common/utils"
import { StatusCard } from "../../../api/StatusCardApi"
import { StatusCardStatusService } from "../../StatusCards/StatusCardStatusService"

export const StatusCardPdfRow = (translations: Record<string, string>, styles: any, statusCard: StatusCard, statusCardStatusService: StatusCardStatusService) => {

    const statusText = statusCardStatusService.getStatus(statusCard.Status).Name;

    return <View>
        <Text>
            <Text style={styles.label}>
                {translations['statusname']}:&nbsp;
            </Text>
            <Text>
                {statusText}
            </Text>
        </Text>
        {statusCard.Message ?
            <Text>
                <Text style={styles.label}>
                    {translations['class-lang-modal-description']}:&nbsp;
                </Text>
                <Text>
                    {convertBreaksToNewLine(statusCard.Message)}
                </Text>
            </Text> : null
        }
        {statusCard.ProvidedBy ?
            <Text>
                <Text style={styles.label}>
                    {translations['class-lang-modal-responsible']}:&nbsp;
                </Text>
                <Text>
                    {statusCard.ProvidedBy}
                </Text>
            </Text> : null
        }
        {statusCard.Important === true ?
            <Text >
                <Text style={styles.label}>
                    {translations['class-lang-modal-check-priority']}:&nbsp;
                </Text>
                <Text>
                    {translations.yes}
                </Text>
            </Text> : null
        }
    </View>
}

