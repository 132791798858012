const queryKeys = {
  all: ['files'],
  lists: () => [...queryKeys.all, 'list'],
  customerList: (id: string) => [...queryKeys.lists(), { customerId: id }],
  dashboardList: (id: string) => [...queryKeys.lists(), { dashboardId: id }],
  details: () => [...queryKeys.all, 'detail'],
  detail: (id) => [...queryKeys.details(), id],
};

export default queryKeys;
