import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import Input from "@murphy-frontend/web-core/components/Input";
import Textarea from "@murphy-frontend/web-core/components/TextArea";

interface MBSMessageEditorProps {
  message: string;
  title: string;
  question?: string;
  showQuestionEditor: boolean;
  handleQuestionChange: any;
  handleMessageBodyChange: any;
  handleMessageTitleChange: any;
  messageQuestionError: string;
  messageBodyError: string;
  messageTitleError: string;
  placeholdertext?: string;
  rows: number;
  cols: number;
  showNoOfSms: boolean;
  translations: Record<string, string>;
}

const MBSMessageEditor: FunctionComponent<MBSMessageEditorProps> = ({
  message,
  title,
  question,
  showQuestionEditor,
  handleQuestionChange,
  handleMessageBodyChange,
  handleMessageTitleChange,
  messageQuestionError,
  messageBodyError,
  messageTitleError,
  placeholdertext,
  rows,
  cols,
  showNoOfSms,
  translations,
}) => {
  const messageInfoMessages = [];

  if (message) {
    const charsPrinted = message.length;
    const charsPerSMS = 160;
    const noOfSms = Math.ceil(charsPrinted / charsPerSMS);
    messageInfoMessages.push(
      `${translations.numberofcharacters}: ${charsPrinted}`
    );
    if (showNoOfSms) {
      messageInfoMessages.push(`${translations.numberof} SMS:  ${noOfSms}`);
    }
  }

  return (
    <fieldset className="form-fieldset">
      <div>
        <Input
          title={translations.title}
          val={title}
          isRequired
          handleChange={handleMessageTitleChange}
          errorMessage={messageTitleError}
          placeholder={translations.thistextwillnotshowinsms}
        />
      </div>
      <div>
        <Textarea
          title={translations.message}
          val={message}
          isRequired
          handleChange={handleMessageBodyChange}
          errorMessage={messageBodyError}
          infoMessages={messageInfoMessages}
          rows={rows}
          cols={cols}
          placeholder={placeholdertext}
        />
      </div>
      <div>
        {showQuestionEditor ? (
          <Textarea
            title={translations.question}
            val={question}
            isRequired
            handleChange={handleQuestionChange}
            errorMessage={messageQuestionError}
            rows={rows}
            cols={cols}
            placeholder={placeholdertext}
          />
        ) : null}
      </div>
    </fieldset>
  );
};

MBSMessageEditor.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  question: PropTypes.string,
  messageTitleError: PropTypes.string,
  messageBodyError: PropTypes.string,
  messageQuestionError: PropTypes.string,
  showQuestionEditor: PropTypes.bool,
  placeholdertext: PropTypes.string,
  rows: PropTypes.number.isRequired,
  cols: PropTypes.number.isRequired,
  handleMessageBodyChange: PropTypes.func.isRequired,
  handleMessageTitleChange: PropTypes.func.isRequired,
  handleQuestionChange: PropTypes.func,
  showNoOfSms: PropTypes.bool,
};

MBSMessageEditor.defaultProps = {
  title: '',
  message: '',
  question: '',
  placeholdertext: '',
  messageTitleError: '',
  messageBodyError: '',
  messageQuestionError: '',
  showQuestionEditor: false,
  handleQuestionChange: null,
  showNoOfSms: false,
};

export default MBSMessageEditor;
