import * as React from 'react';
import Button from '@murphy-frontend/web-core/components/Button';
import CardFooter from "../MainLog/CardFooter";
import { FunctionComponent } from "react";
import { useUpsertStatusCard, useUpsertStatusCardOtherDashboard } from '../../mutations';
import StatusCardFormatted from './StatusCardFormatted';
import { StatusCard, UpsertStatusCard } from '../../api/StatusCardApi';
import UpsertStatusCardComponent from './UpsertStatusCardComponent';
import { StatusCardStatusService } from './StatusCardStatusService';

interface StatusCardExpandedContentProps {
    statusCard: StatusCard,
    dashboardId: string,
    openModal: any,
    closeModal: any,
    translations: Record<string, string>,
    statusCardStatusService: StatusCardStatusService,
    isChildCard?: boolean,
    isParentCard?: boolean,
}

const StatusCardExpandedContent: FunctionComponent<StatusCardExpandedContentProps> = ({ statusCard, isChildCard, isParentCard, dashboardId, openModal, closeModal, translations, statusCardStatusService }) => {

    const { mutate: upsertStatusCardMutationOther } = useUpsertStatusCardOtherDashboard();

    const onClickEdit = () => {

        const upsertStatusCardComponent =
            <UpsertStatusCardComponent
                statusCardStatusService={statusCardStatusService}
                translations={translations}
                dashboardId={dashboardId}
                statusCard={statusCard}
                onSuccess={() => closeModal()} />;
        const configuration = {
            centerContent: false,
            hideButton: true,
            body: upsertStatusCardComponent,
            title: translations['lang-status-card'],
        }
        openModal(configuration);
    }

    const onClickReactivate = () => {
        const newCard: UpsertStatusCard = { ...statusCard }
        newCard.UpdatedFromDashboardId = dashboardId;
        newCard.ParentId = statusCard.ID;
        newCard.archived = false;
        upsertStatusCardMutationOther(newCard);
    }

    let footer;
    if (statusCard.archived !== true) {
        const button =
            <Button
                onClick={() => onClickEdit()}>
                {translations['class-lang-button-edit']}
            </Button>
        footer = <CardFooter rightContent={button} />
    }

    if (statusCard.archived === true && !statusCard.child) {
        const button =
            <Button
                isNeutralButton
                buttonStyle="btn--unarchive"
                buttonSize="btn-with-icon"
                onClick={() => onClickReactivate()}>
                {translations['activate']}
            </Button>
        footer = <CardFooter rightContent={button} />
    }
    return <StatusCardFormatted showChildInfo={isChildCard} statusCard={statusCard} translations={translations} cardFooter={footer} />
}

export default StatusCardExpandedContent;