import { IApiService } from "@murphy-frontend/common/interfaces/IApiService";

export class ActiveCampaignApi {

    constructor(
        private apiService: IApiService,
    ) { }

    addTagToUser = async (email: string, tag: string): Promise<void> => {
        const url = `api/Activecampaign`;
        const addTagToUserRequest = {
            Email: email,
            Tag: tag,
        };

        await this.apiService.callApiWithoutToken(url, 'POST', addTagToUserRequest);
    }
}