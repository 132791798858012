import { AxiosResponse } from "axios";

export interface UserTraningSession {
    CustomerUserTrainingSessionId: string,
    CustomerUserId: string,
    TraningName: string,
    TrainingId: number,
    TrainingTypeId: number,
    TrainingSessionID: number,
    TrainingSessionStateID: number,
    TrainingLanguageId?: number,
    Date: Date,
    Status: string,
    UrlToMeeting: string,
}

export interface MurphyUser {
    UserId: string,
    ExternalId: string,
    CustomerUserId: string,
    CustomerId: string,
    CustomerName: string,
    IsSuperAdmin: boolean,
    Username: string,
    Role: number,
    Email: string,
    Phonenumber: string,
    DeviceToken: string,
    Permissions: number[],
    UserTrainingSessions: UserTraningSession[],
    StartPage: string,
    LanguageId: number,
}

export interface CreateUserRequest {
    Username: string,
    Email: string,
    Phonenumber?: string,
    CustomerId: string,
    Role: number,
    InitialProducts: number[];
}

export interface UpdateUserRequest {
    NewRole?: number,
    NewUserName?: string,
    NewEmail?: string,
    NewMobilePhone?: string,
    UpdatedProductsList?: number[]
}

export interface AdUserDTO {
    UserName: string,
    SID: string,
    Email: string,
    Department: string,
}

export interface AdUserGroupRequest {
    CustomerId: string,
    Role: number,
    ExternalId: string,
}

export interface UsersFilterProps {
    objectId: string,
    customerId: string,
    includeAdInfo: boolean,
    includeTrainingInfo: boolean,
}

export interface UserOnboardingFlow {
    OnboardingFlowId: number;
    IsCompleted: boolean;
    StepsHtmlContents: Map<number, string>;
}

export interface UpsertUserOnboardingFlowRequest {
    OnboardingFlowId: number;
    IsCompleted: boolean;
}

export interface IUsersApi {
    getUsers: ({ objectId, customerId, includeAdInfo, includeTrainingInfo, }?: {
        objectId?: string;
        customerId?: string;
        includeAdInfo?: boolean;
        includeTrainingInfo?: boolean;
    }) => Promise<MurphyUser[]>,
    getUser: (customerUserId: string, includeAdInfo: boolean, includeTrainingInfo: boolean) => Promise<MurphyUser>,
    updateUser: (customerUserId: string, updateUserRequest: UpdateUserRequest) => Promise<AxiosResponse<any, any>>,
    resetMfa: (customerUserId: string) => Promise<AxiosResponse<any, any>>
    createUser: (createUserRequest: CreateUserRequest) => Promise<AxiosResponse<any, any>>,
    deleteUser: (customerUserId: string) => Promise<AxiosResponse<any, any>>,
    addUserToADGroup: (request: AdUserGroupRequest) => Promise<AxiosResponse<any, any>>,
    getExternalUserByUserName: (userName: string) => Promise<AxiosResponse<any, any>>,
    getUserProvider: (userName: string) => Promise<AxiosResponse<any, any>>,
    getUserOnboardingFlows: () => Promise<UserOnboardingFlow[]>;
    postUserOnboardingFlow: (request: UpsertUserOnboardingFlowRequest) => Promise<AxiosResponse<any, any>>;
}

const UsersApiType = {
    IUsersApi: Symbol("IUsersApi"),
};

export default UsersApiType;

