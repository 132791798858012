import { Constant } from "../commonInterfaces";

/* eslint-disable import/prefer-default-export */
export const translateConstants = (lang: string, constants: Constant[]): Constant[] => constants.map((constant) => {
  let translatedValue = constant.value;
  switch (lang) {
    case 'swe':
      translatedValue = constant.valueSwe;
      break;
    case 'eng':
      translatedValue = constant.valueEng;
      break;
    case 'nor':
      translatedValue = constant.valueNor;
      break;
    case 'fin':
      translatedValue = constant.valueFin;
      break;
    default:
      translatedValue = constant.value;
  }

  if (!translatedValue) translatedValue = constant.value;

  const translatedConstant: Constant = {
    id: constant.id,
    value: translatedValue,
  }

  return translatedConstant;
});

export const translateConstantsWithBackendTranslations = (constants: Constant[], translations: Record<string, string>): Constant[] => constants.map((constant) => {
  let translatedValue = constant.value;
  const { translationKey } = constant;

  if (translationKey) {
    translatedValue = translations[translationKey];
  }

  if (!translatedValue) {
    translatedValue = constant.value;
  }

  return {
    id: constant.id,
    value: translatedValue,
  };
});
