import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';
import { convertBreaksToNewLine, convertNewLinesToBreak } from '@murphy-frontend/common/utils';
import { InformationCard, PatchInformationCard, UpsertInformationCard } from '../../api/InformationCardApi';
import { usePatchInformationCard, useUpsertInformationCard } from '../../mutations';
import InformationCardForm, { InformationCardFormDto } from './InformationCardForm';

interface UpsertInformationCardProps {
    dashboardId: string,
    informationCard?: InformationCard,
    onSuccess: () => void,
    translations: Record<string, string>,
}

const UpsertInformationCardComponent: FunctionComponent<UpsertInformationCardProps> = ({ dashboardId, informationCard, onSuccess, translations }) => {

    const { mutate: upsertInformationCardMutation, isPending: upsertIsLoading } = useUpsertInformationCard(dashboardId);
    const { mutate: patchInformationCardMutation, isPending: patchIsLoading } = usePatchInformationCard(dashboardId);
    const [informationCardFormData, setInformationCardFormData] = useState<InformationCardFormDto>(null);

    const onSubmitHandler = (informationCardFormDto: InformationCardFormDto) => {

        const upsertInformationCard: UpsertInformationCard = {
            Title: informationCardFormDto.Title,
            Message: informationCardFormDto.Message ? convertNewLinesToBreak(informationCardFormDto.Message) : null,
            Status: informationCardFormDto.Status,
            ProvidedBy: informationCardFormDto.ProvidedBy,
            Owner: informationCardFormDto.Owner,
            isDecision: informationCardFormDto.isDecision,
            Important: informationCardFormDto.Important,
            Confirmed: informationCardFormDto.Confirmed,
            AlternatedTime: informationCardFormDto?.AlternatedTime,
        }

        if (informationCard && informationCard.ID) {
            upsertInformationCard.ParentId = informationCard.ID;
        }

        upsertInformationCardMutation(upsertInformationCard, {
            onSuccess: () => {
                onSuccess();
            }
        });
    };

    const onClickArchiveActionCard = (id: number) => {
        const patchCard: PatchInformationCard = {
            Id: id,
            archived: true,
        }

        patchInformationCardMutation(patchCard, {
            onSuccess: () => {
                onSuccess();
            }
        });
    }

    useEffect(() => {
        if (informationCard) {
            const informationCardFormDto: InformationCardFormDto = {
                ID: informationCard.ID,
                Title: informationCard.Title,
                Message: informationCard.Message ? convertBreaksToNewLine(informationCard.Message) : null,
                Status: informationCard.Status,
                ProvidedBy: informationCard.ProvidedBy,
                Owner: informationCard.Owner,
                isDecision: informationCard.isDecision,
                Important: informationCard.Important,
                Confirmed: informationCard.Confirmed,
                AlternatedTime: informationCard.AlternatedTime,
            }
            setInformationCardFormData(informationCardFormDto);
        }

    }, [informationCard])

    return (
        <div>
            <InformationCardForm
                translations={translations}
                onClickArchive={onClickArchiveActionCard}
                onSubmit={onSubmitHandler}
                informationCard={informationCardFormData}
                isLoading={upsertIsLoading || patchIsLoading}
            />
        </div>
    );
}

export default UpsertInformationCardComponent;