import * as React from 'react';
import { FunctionComponent } from 'react';

interface MeetingNavbarItemProps {
    icon?: any,
    title: string,
    meetingTime: string,
    onClick?: () => void;
}

const MeetingNavbarItem: FunctionComponent<MeetingNavbarItemProps> = ({ icon, title, meetingTime, onClick }) => {
    return (
        <div className='dashboard-nav-bar-item' onClick={onClick}>
            <div className='dashboard-nav-bar-item-icon'>
                {icon}
            </div>
            <span className='dashboard-nav-bar-item-meeting-title'>
                {title}
            </span>
            <span className='dashboard-nav-bar-item-meeting-time'>
                {meetingTime}
            </span>
        </div>
    );
}

export default MeetingNavbarItem;