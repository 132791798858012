import React, { useState } from "react";
import { FunctionComponent } from "react";
import StartedEventFlowTabs from "./StartedEventFlowTabs";
import { Drone } from "@murphy-frontend/common/api/DroneEvents/types";
import StartedEventFlowTab from "./StartedEventFlowTab";

interface StartedEventFlowViewProps {
    eventFlow: Drone;
}

const StartedEventFlowView: FunctionComponent<StartedEventFlowViewProps> = (
    {
        eventFlow
    }
) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    return (
        <div className="event-flow-main-wrapper">
            <StartedEventFlowTabs
                tabs={[...eventFlow.Tabs]}
                selectedTabIndex={selectedTabIndex}
                setSelectedTabIndex={setSelectedTabIndex}
            />
            <div className="divider"></div>
            {eventFlow.Tabs?.map((tab, index) => (
                <StartedEventFlowTab
                    key={`${tab.Title}-${index}`}
                    tab={tab}
                    active={index === selectedTabIndex} />
            ))
            }
        </div>
    );
}

export default StartedEventFlowView;