import React from "react";
import { FunctionComponent } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { PlacesType, Tooltip, VariantType } from 'react-tooltip'

interface IncoIconProps {
    onHoverEnabled?: boolean;
    onHoverContents: React.ReactNode;
    clickable?: boolean;
    place?: PlacesType;
    id: string,
    variant?: VariantType
}

const InfoIcon: FunctionComponent<IncoIconProps> = (
    {
        onHoverEnabled,
        onHoverContents,
        clickable,
        place,
        id,
        variant
    }
) => {

    const selectedPlace = place ?? "top";
    const uniqueClassName = `anchor-element-${id}`
    const uniqueClassNameSelector = `.${uniqueClassName}`

    const tooltip = <Tooltip
        id={id}
        anchorSelect={uniqueClassNameSelector}
        place={selectedPlace} clickable={clickable ?? false}
        variant={variant ?? "dark"}
    >
        {onHoverContents}
    </Tooltip>

    return (
        <>
            {tooltip}
            <a
                className={uniqueClassName}
            >
                <div className="info-icon-container">
                    <IconContext.Provider
                        value={{ className: "info-icon" }}
                    >
                        <FaInfoCircle />
                    </IconContext.Provider>
                </div>
            </a>
        </>
    );
}

export default InfoIcon;