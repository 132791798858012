import * as React from "react";
import { DashboardEntityTypes } from "@murphy-frontend/common/enums";
import { getConvertedDateDayjs } from '@murphy-frontend/common/services/TimeService';
import { StatusCard } from "../../../api/StatusCardApi";
import StatusCardExpandedContent from "../../StatusCards/StatusCardExpandedContent";
import { StatusCardStatusService } from "../../StatusCards/StatusCardStatusService";
import { MainLogCard } from "../models";
import { BaseCardProps } from "./MainLogCardFactory";
import ChildStatusCardExpandedContent from "../../StatusCards/ChildStatusCardExpandedContent";

export interface StatusCardMainLogCardProps extends BaseCardProps {
    statusCard: StatusCard,
    statusCardStatusService: StatusCardStatusService
}

export const StatusCardMainLogCard =
    ({ translations, statusCard, timeZone, dashboardId, openModal, closeModal, statusCardStatusService }: StatusCardMainLogCardProps): MainLogCard => {
        const date = getConvertedDateDayjs(statusCard.AlternatedTime || statusCard.CreatedDateTime, timeZone);
        let expandedContent =
            <StatusCardExpandedContent
                statusCardStatusService={statusCardStatusService}
                statusCard={statusCard}
                dashboardId={dashboardId}
                openModal={openModal}
                closeModal={closeModal}
                translations={translations}
            />

        if (statusCard.ParentDashboardId) { // this means that this is a child status card
            if (statusCard.ParentDashboardId == dashboardId) { // this means that this is a child status card from another dashboard
                expandedContent =
                    expandedContent = <ChildStatusCardExpandedContent
                        statusCard={statusCard}
                        dashboardId={dashboardId}
                        openModal={openModal}
                        closeModal={closeModal}
                        translations={translations}
                        statusCardStatusService={statusCardStatusService} />
            }
        }

        const searchableContent = [];
        searchableContent.push(statusCard.Title);
        if (statusCard.ProvidedBy)
            searchableContent.push(statusCard.ProvidedBy);
        if (statusCard.Message)
            searchableContent.push(statusCard.Message);
        if (statusCard.user)
            searchableContent.push(statusCard.user);

        const logCard: MainLogCard = {
            ID: `${statusCard.ID}-${DashboardEntityTypes.StatusCard}`,
            Title: statusCard.Title,
            isArchived: statusCard.archived,
            isDecision: false,
            Status: statusCard.Status,
            SearchableContent: searchableContent,
            User: statusCard.user,
            Date: date,
            DateWithoutTime: date.format("YYYY-MM-DD"),
            Time: date.format("HH:mm"),
            EntityType: DashboardEntityTypes.StatusCard,
            ExpandedContent: expandedContent,
            OriginalCard: statusCard,
            isChild: statusCard.ParentDashboardId && statusCard.ParentDashboardId == dashboardId ? true : false,
            isParent: statusCard.ParentDashboardId && statusCard.ParentDashboardId != dashboardId ? true : false,
        }
        return logCard;
    }