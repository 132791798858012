import * as React from 'react';
import { FunctionComponent } from 'react';
import { Goals } from '../../api/GoalsApi';
import { parseHtmlAndLinkifySafe } from '@murphy-frontend/common/utils';

interface GoalsFormattedProps {
    goals: Goals;
    translations: Record<string, string>
    cardFooter: any
}

const GoalsFormatted: FunctionComponent<GoalsFormattedProps> = ({ goals, translations, cardFooter }) => {
    return (
        <div className="formatted-card">
            <p>
                <strong>{translations['class-lang-by-user']}</strong>&nbsp;{goals.user}
            </p>
            {goals.message &&
                <>
                    <br />
                    {parseHtmlAndLinkifySafe(goals.message)}
                    <br />
                </>}
            {cardFooter &&
                <>
                    <br />
                    <hr></hr>
                    <br />
                    {cardFooter}
                </>
            }
        </div >
    );
}

export default GoalsFormatted;