import { inject, injectable } from "inversify";
import ApiServiceType, { IApiService } from "../../interfaces/IApiService";
import { CreateContactGroupRequest, CreateContactGroupUserRequest, EditContactGroupRequest, IContactGroupApi, AddUserToGroupsRequest, AddContactToGroupsRequest } from "../../interfaces/IContactGroupApi";

@injectable()
export class ContactGroupApi implements IContactGroupApi {

    constructor(
        @inject(ApiServiceType.IApiService) private apiService: IApiService,
    ) { }

    getContactGrpById = async (id: number, includeContacts = true, includeUsers = true) => {
        const url = `/api/Contact/ContactGroup/${id}?includeContacts=${includeContacts}&includeUsers=${includeUsers}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };

    getContactsByContactGrpId = async (contactGroupId: number) => {
        const url = `/api/Contact?contactGroupId=${contactGroupId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };

    getUsersByContactGrpId = async (contactGroupId: number) => {
        const url = `/api/Contact/ContactGroupUser/${contactGroupId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };

    getContactGroupsByCustId = async (customerId: string, includeContacts = true, includeUsers = true) => {
        const url = `/api/Contact/ContactGroup?customerId=${customerId}&includeContacts=${includeContacts}&includeUsers=${includeUsers}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    };

    deleteContactGrp = (id: number) => {
        const url = `/api/Contact/ContactGroup/${id}`;
        return this.apiService.callApi(url, 'DELETE');
    };

    deleteContactGroupContact = (contactId: number, contactGroupId: number) => {
        const url = `/api/Contact/ContactGroupContact?contactGroupId=${contactGroupId}&contactId=${contactId}`;
        return this.apiService.callApi(url, 'DELETE');
    };

    deleteContactGroupUser = (id: number) => {
        const url = `/api/Contact/ContactGroupUser?contactGroupUserId=${id}`;
        return this.apiService.callApi(url, 'DELETE');
    };

    createContactGroupContact = (contactId: number, contactGroupId: number) => {
        const url = `/api/Contact/ContactGroupContact?contactGroupId=${contactGroupId}&contactId=${contactId}`;
        return this.apiService.callApi(url, 'POST');
    };

    createContactGroupUser = (createContactGroupUserRequest: CreateContactGroupUserRequest) => {
        const url = `/api/Contact/ContactGroupUser?contactGroupId=${createContactGroupUserRequest.ContactGroupId}&customerUserId=${createContactGroupUserRequest.CustomerUserId}`;
        return this.apiService.callApi(url, 'POST');
    };

    editContactGrp = (editContactGroupRequest: EditContactGroupRequest) => {
        const url = `/api/Contact/ContactGroup/${editContactGroupRequest.ID}`;
        return this.apiService.callApi(url, 'PUT', editContactGroupRequest);
    };

    createContactGroup = (createContactGroupRequest: CreateContactGroupRequest) => {
        const url = '/api/Contact/ContactGroup';
        return this.apiService.callApi(url, 'POST', createContactGroupRequest);
    };

    createContactGroupWithUsers = (createContactGroupRequest: CreateContactGroupRequest) => {
        const url = '/api/Contact/ContactGroupWithUsers';
        return this.apiService.callApi(url, 'POST', createContactGroupRequest);
    };
    addUserToGroups = (customerUserId: string, addUserToGroupsRequest: AddUserToGroupsRequest) => {
        const url = `/api/Contact/ContactGroupsUser&customerUserId=${customerUserId}`;
        return this.apiService.callApi(url, 'POST', addUserToGroupsRequest);
    };
    addContactToGroups = (contactId: number, addContactToGroupsRequest: AddContactToGroupsRequest) => {
        const url = `/api/Contact/ContactGroupsContact&contactId=${contactId}`;
        return this.apiService.callApi(url, 'POST', addContactToGroupsRequest);
    };



    getCustomerContactGroups = (customerId: string, includeContacts: boolean, includeUsers: boolean) => {
        let includeContactsVal = false;
        let includeUsersVal = false;

        if (includeContacts === true) {
            includeContactsVal = true;
        }
        if (includeUsers === true) {
            includeUsersVal = true;
        }

        const url = `/api/Contact/ContactGroup?customerId=${customerId}&includeContacts=${includeContactsVal}&includeUsers=${includeUsersVal}`;
        return this.apiService.callApi(url, 'GET');
    };
}