import React from "react";
import { FunctionComponent } from "react";
import SignupFormEnglish from "./SignupFormEnglish";
import useFormSubmit from "./hooks/useFormSubmit";
import SignupFormSwedish from "./SignupFormSwedish";

interface SignupFormProps {
    language?: string;
    onSubmit?: (data: any) => void;
    onEmailSubmit: (email: string) => void; // Function to call with the email
}

const gdprCheckboxFormNames: { [key: string]: string } = {
    'sv': 'field[24][]',
    'en': 'field[2][]',
};

const SignupForm: FunctionComponent<SignupFormProps> = ({ onSubmit, onEmailSubmit, language }) => {

    const [isLoading, setIsLoading] = React.useState(false);

    const { formRef, handleSubmit } = useFormSubmit(async (formData: FormData) => {
        const email = formData.get('email') as string;
        const fullname = formData.get('fullname') as string;

        // Check if gdpr checkbox is checked
        const gdprCheckboxName = gdprCheckboxFormNames[language ?? 'en'];
        const gdprCheckbox = formData.getAll(gdprCheckboxName);
        if (!gdprCheckbox || gdprCheckbox.length < 2) { // If the checkbox is checked, 2 values will be present
            alert('Please accept the GDPR consent.');
            return;
        }

        if (!fullname.trim() || !email.trim()) {
            alert('Please fill in both your full name and email address.');
            return;
        }

        setIsLoading(true);
        try {
            const response = await fetch(formRef.current?.action || '', {
                method: 'POST',
                mode: 'no-cors',
                body: formData,
            });

            if (response.status === 0 || response.status === 302) {
                // Handle the successful form submission case here
                // You can't read the redirected URL or the response body with no-cors mode, so you may need to consider a different approach if you need that information
                onEmailSubmit(email);
                if (onSubmit) {
                    onSubmit(await response.json());  // Assuming JSON response, if needed
                }
            } else {
                // Handle the error case here
                console.error('Failed to submit form:', response.status);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setIsLoading(false);
        }
    });

    if (language === 'sv') {
        return (
            <SignupFormSwedish isLoading={isLoading} formRef={formRef} handleSubmit={handleSubmit} />
        );
    }

    if (language === 'en') {
        return (
            <SignupFormEnglish isLoading={isLoading} formRef={formRef} handleSubmit={handleSubmit} />
        );
    }

    // Default to English form
    return <SignupFormEnglish isLoading={isLoading} formRef={formRef} handleSubmit={handleSubmit} />;
}

export default SignupForm;