import * as React from 'react';
import { FunctionComponent } from 'react';
import { ActionCardStatuses } from '@murphy-frontend/common/enums';
import ActionsButton from '@murphy-frontend/web-core/components/ActionsButton';
import ActionsStatusIcon from './ActionsStatusIcon';


interface ActionStatusSelectorProps {
    onClickStatus: (status: number, event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
    translations: Record<string, string>,
    currentStatus: number,
    pullRightOnSmallScreen?: boolean,
}

const ActionStatusSelector: FunctionComponent<ActionStatusSelectorProps>
    = ({ onClickStatus,
        translations,
        currentStatus,
        pullRightOnSmallScreen
    }) => {

        let icon = <ActionsStatusIcon status={currentStatus} />;
        const notStartedIcon = <ActionsStatusIcon status={ActionCardStatuses.NotStarted} />;
        const onGoingIcon = <ActionsStatusIcon status={ActionCardStatuses.Ongoing} />;
        const completedIcon = <ActionsStatusIcon status={ActionCardStatuses.Done} />;

        const actionButtonItems = [];
        actionButtonItems.push(
            <li key="notstarted" onClick={(e) => { onClickStatus(ActionCardStatuses.NotStarted, e) }}>
                <div className="vertical-aligner">
                    {notStartedIcon}
                    &nbsp;&nbsp;
                    <span>{translations['class-lang-modal-status-notstarted']}</span>
                </div>
            </li>
        );
        actionButtonItems.push(
            <li key="started" onClick={(e) => { onClickStatus(ActionCardStatuses.Ongoing, e) }}>
                <div className="vertical-aligner">
                    {onGoingIcon}
                    &nbsp;&nbsp;
                    <span>{translations['class-lang-modal-status-ongoing']}</span>
                </div>
            </li>
        );
        actionButtonItems.push(
            <li key="complete" onClick={(e) => { onClickStatus(ActionCardStatuses.Done, e) }}>
                <div className="vertical-aligner">
                    {completedIcon}
                    &nbsp;&nbsp;
                    <span>{translations['class-lang-modal-status-finished']}</span>
                </div>
            </li>
        );

        return <ActionsButton actionButtonItems={actionButtonItems} icon={icon} pullRightOnSmallScreen={pullRightOnSmallScreen} />
    }

export default ActionStatusSelector;