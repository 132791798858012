import React from "react";
import { FunctionComponent } from "react";

interface RichTextDisplayProps {
    content: string | JSX.Element | JSX.Element[],
}

const RichTextDisplay: FunctionComponent<RichTextDisplayProps> = (
    {
        content,
    }
) => {
    return (<div className="richtext-display">
        {content}
    </div>);
}

export default RichTextDisplay;