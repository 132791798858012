import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import ColumnGroupTable from "@murphy-frontend/web-core/components/ColumnGroupTable";
import { ColumnGroup, ColumnGroupColumnCell, ColumnGroupRowCell } from "@murphy-frontend/web-core/components/ColumnGroupTable/Models";
import { createColumnGroup, sortByKey } from "@murphy-frontend/web-core/components/ColumnGroupTable/TableUtils";
import { ContactLisPaginatedRow } from "./ContactLisPaginatedRow";
import { ContactModel, OptionSetModel } from '../../../common/api/Contacts/ContactsApi';

interface IContactListPaginated {
    translations: Record<string, string>,
    contacts: ContactModel[]
    onClickEdit?: (id: number, type: string) => void,
    onClickDelete?: (id: number, type: string) => void,
    onClickAdd?: (id: number, type: string) => void,
    showEdit?: boolean,
    showDelete?: boolean,
    showAdd?: boolean,
}

export const ContactListPaginated: FunctionComponent<IContactListPaginated>
    = ({ translations, contacts, showDelete, showEdit, showAdd, onClickEdit, onClickDelete, onClickAdd }) => {
        const [columnGroups, setColumnGroups] = useState<ColumnGroup[]>([]);
        const [currentSortingColumn, setCurrentSortingColumn] = useState<string>('name');
        const [sortedAscending, setSortedAscending] = useState<boolean>(true);
        const [pageNum, setPageNum] = useState<number>(1)

        const sortByColumn = (columnKey: string) => {
            setCurrentSortingColumn(columnKey);
            const newSortingOrder = !sortedAscending;
            setSortedAscending(newSortingOrder);
        };

        const navigateHandler = (num: number) => {
            setPageNum(num)
        }

        const createMainColumnGroup = (contacts: ContactModel[]) => {
            const nameColumn: ColumnGroupColumnCell[] = [
                {
                    columnKey: "name",
                    displayName: translations.name,
                    isSortable: true,
                    size: "column-extra-large-contacts",
                    onClickColumn: sortByColumn,
                },
            ];

            const userNameRows: ColumnGroupRowCell[][] = contacts.map((row) => (
                [
                    {
                        id: `name-${row.id}`,
                        type: row.type,
                        rowId: row.uniqueKey,
                        title: row.name,
                        customClass: 'contact-expandable-cell',
                        displayValue: <ContactLisPaginatedRow
                            contact={row}
                            translations={translations}
                            showEdit={showEdit}
                            showDelete={showDelete}
                            showAdd={showAdd}
                            onClickEdit={onClickEdit}
                            onClickDelete={onClickDelete}
                            onClickAdd={onClickAdd}
                            pageNum={pageNum}
                        />,
                        columnKey: 'name',
                    }
                ]
            )
            );
            const userNameColumnGroup = createColumnGroup("", nameColumn, userNameRows);
            return userNameColumnGroup;
        };

        useEffect(() => {
            if (contacts) {
                const allColumnGroups = [];
                const contactsCopy = [...contacts];

                if (currentSortingColumn) {
                    contactsCopy.sort(sortByKey(currentSortingColumn, sortedAscending));
                }

                const mainColGroup = createMainColumnGroup(contactsCopy);
                allColumnGroups.push(mainColGroup);
                setColumnGroups(allColumnGroups);
            }
        }, [contacts, sortedAscending, currentSortingColumn, translations, pageNum]);

        return (
            <ColumnGroupTable
                columngroups={columnGroups}
                sortedByColumn={currentSortingColumn}
                isSortedAscending={sortedAscending}
                hidePageInputs={true}
                navigateHandler={navigateHandler}
            />
        );
    }

