import React, { useState, useEffect, FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@murphy-frontend/web-core/components/Button';
import MBSMessageEditor from './MBSMessageEditor';
import MBSTemplateSelector from './MBSTemplateSelector';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import ContactList, { ContactListModel } from '../../Contacts/components/ContactList';
import EditContact from '../../Contacts/components/EditContact';
import MBSConfirmation from './MBSConfirmation';
import { useGetUsers } from '@murphy-frontend/common/api/Users/Queries';
import queryKeys from '../queryKeys';
import { useGetContacts, useGetCustomerContactOptionSets } from '../../../common/api/Contacts/queries';
import { useGetContactGroups } from '@murphy-frontend/common/api/ContactGroups/Queries';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { useUserPreferences } from '../../../common/contexts/UserPreferencesContext';
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';
import { useCreateSendOut } from '../mutations';
import { CreateSendOutBatchRequest, Message } from '../MBSApi';
import { SendOutBatchTemplate } from '../models';
import { ContactModel, OptionSetModel, OptionSetViewModel, SelectedOptionSetViewModel } from '../../../common/api/Contacts/ContactsApi';
import { MBSGroupMember, MBSRecipient } from './models';
import { useCustomer } from '../../../common/contexts/CustomerContext';
import MBSSearchRecipients from './MBSSearchRecipients';
import MBSSelectedRecipients from './MBSSelectedRecipients';
import CommunicationTypeFilter from '@murphy-frontend/web-core/components/CommunicationTypeFilter';
import { MurphyUser } from '@murphy-frontend/common/interfaces/IUsersApi';
import { ContactGroupModel } from '@murphy-frontend/common/interfaces/IContactGroupApi';
import { useUser } from "../../../common/contexts/UserContext";
import { Roles } from '@murphy-frontend/common/enums';
import { CustomCheckbox } from '../../../common/components/MurphyCheckbox';
import InfoIcon from '@murphy-frontend/web-core/components/InfoIcon';
import { OnboardingFlowIds, useOnboarding } from '../../../common/contexts/OnboardingContext';
import RichTextDisplay from '@murphy-frontend/web-core/features/RichText/RichTextDisplay';
import { parseHtml } from '@murphy-frontend/web-core/features/RichText/helpers';
import MBSCreateNewOnboardingSteps from './MBSCreateNewOnboardingSteps';
import { enabledFeatures } from '../../../common/variables';

const MBSCreateNew: FunctionComponent = () => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { translations } = useUserPreferences();
  const { user } = useUser();

  const {
    mutate: createSendOut,
    isPending: createSendOutIsLoading,
    isError: createSendOutIsError,
    error: createSendOutError,
  } = useCreateSendOut();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<MBSRecipient[]>([]);
  const [contacts, setContacts] = useState<MBSRecipient[]>([]);
  const [contactGroups, setContactGroups] = useState<MBSRecipient[]>([]);

  const [stepsEnabled, setStepsEnabled] = useState<boolean>(false);
  const [onboardingTerminated, setOnboardingTerminated] = useState<boolean>(null);
  const { setOnboardingIdIsComplete, incompletedOnboardingFlows, isLoading: onBoardingIsLoading, resetOnboarding, setCurrentStep, onboardingsCurrentStep } = useOnboarding();
  const [messageTemplateIsFlashing, setMessageTemplateIsFlashing] = useState<boolean>(false);

  const [notSelectedRecipients, setNotSelectedRecipients] = useState<
    MBSRecipient[]
  >([]);
  const [selectedRecipients, setSelectedRecipients] = useState<MBSRecipient[]>(
    []
  );

  const [mobileAllChecked, setMobileAllChecked] = useState(
    user?.Role === 3 ? false : true
  );
  const [emailAllChecked, setEmailAllChecked] = useState(true);
  const [notificationAllChecked, setNotificationAllChecked] = useState(true);
  const [criticalAlertIsChecked, setCriticalAlertIsChecked] = useState(false);
  const [geoLocationIsChecked, setGeoLocationIsChecked] = useState(false);

  const [messageBody, setMessageBody] = useState("");
  const [messageTitle, setMessageTitle] = useState("");
  const [messageQuestion, setMessageQuestion] = useState("");
  const [messageTitleError, setMessageTitleError] = useState("");
  const [messageBodyError, setMessageBodyError] = useState("");
  const [messageQuestionError, setMessageQuestionError] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState<number>(null);
  const [questionRequired, setQuestionRequired] = useState(false);

  const [optionSets, setOptionSets] = useState<OptionSetViewModel[]>([]);

  const queryClient = useQueryClient();

  const templates: SendOutBatchTemplate[] = [
    {
      id: 1,
      title: translations.onewaycommunication,
      description: translations.onewaycomdescription,
      replyRequired: false,
      questionIncluded: false,
    },
    {
      id: 2,
      title: translations.twowaycommunication,
      description: translations.twowaycomdescription,
      alternatives: [translations.yes, translations.no],
      replyRequired: true,
      questionIncluded: true,
    },
  ];

  const { customer } = useCustomer();
  const {
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    data: dataUsers,
    error: errorUsers,
  } = useGetUsers(customer.Id);

  const {
    isLoading: isLoadingContacts,
    isError: isErrorContacts,
    data: dataContacts,
    error: errorContacts,
  } = useGetContacts(customer.Id);

  const {
    isLoading: isLoadingContactGroups,
    isError: isErrorContactGroups,
    data: dataContactGroups,
    error: errorContactGroups,
  } = useGetContactGroups(customer.Id);

  const {
    isLoading: isLoadingCustomerContactOptionSets,
    isError: isErrorCustomerContactOptionSets,
    data: dataCustomerContactOptionSets,
    error: errorCustomerContactOptionSets,
  } = useGetCustomerContactOptionSets(customer.Id);

  const createOptionSetViewModels = (
    data: OptionSetModel[]
  ): OptionSetViewModel[] => {
    const mappedData = data.map((p) => ({
      name: p.Name,
      id: p.ID,
      options: p.Options.map((q) => ({
        id: q.ID,
        value: q.Value,
      })),
    }));

    mappedData.sort((a, b) => a.name.localeCompare(b.name));
    return mappedData;
  };

  const createUserRecipients = (data: MurphyUser[]): MBSRecipient[] => {
    const mappedUsers = data.map(
      (p) =>
      ({
        uniqueKey: `${"White"}:${p.UserId}`,
        id: p.UserId,
        name: p.Username,
        email: p.Email,
        mobile: p.Phonenumber,
        deviceToken: p.DeviceToken,
        type: "internaluser",
        isGroup: false,
        mobileChecked: true,
        emailChecked: true,
        notificationChecked: true,
        checked: true,
      } as MBSRecipient)
    );

    return mappedUsers;
  };

  const createContactRecipients = (data: ContactModel[]): MBSRecipient[] => {
    const mappedContacts = data.map(
      (p) =>
      ({
        uniqueKey: `${"internalcontact"}:${p.ID}`,
        id: p.ID,
        name: p.Name,
        email: p.Email,
        mobile: p.MobileNr,
        deviceToken: p.DeviceToken,
        type: "internalcontact",
        mobileChecked: true,
        emailChecked: true,
        notificationChecked: true,
        checked: true,
        isGroup: false,
        selectedOptions: p.SelectedOptions
          ? p.SelectedOptions.map(
            (q) =>
            ({
              id: q.ID,
              value: q.Value,
              optionSetId: q.OptionSetId,
              optionSetName: q.OptionSetName,
            } as SelectedOptionSetViewModel)
          )
          : [],
      } as MBSRecipient)
    );
    return mappedContacts;
  };

  const createContactGroupRecipients = (
    data: ContactGroupModel[]
  ): MBSRecipient[] => {
    const mappedGroups = data.map((p) => {
      let allGroupMembers = [];
      let mappedContacts: MBSGroupMember[] = [];
      let mappedUsers: MBSGroupMember[] = [];

      if (p.ContactGroupContacts) {
        mappedContacts = p.ContactGroupContacts.map(
          (q) =>
          ({
            name: q.Name,
            id: q.ID.toString(),
            email: q.Email,
            mobile: q.MobileNr,
            deviceToken: q.DeviceToken,
            type: "contact",
          } as MBSGroupMember)
        );
      }

      if (p.ContactGroupUsers) {
        mappedUsers = p.ContactGroupUsers.map(
          (q) =>
          ({
            name: q.UserName,
            id: q.ID.toString(),
            customeruserid: q.CustomerUserId.toUpperCase(),
            email: q.Email,
            mobile: q.MobilePhone,
            deviceToken: q.DeviceToken,
            type: "user",
          } as MBSGroupMember)
        );
      }

      allGroupMembers = [...mappedUsers, ...mappedContacts];

      return {
        uniqueKey: `${"internalgroup"}:${p.ID}`,
        id: p.ID,
        name: p.Name,
        email: `${p.Name} (${translations.group})`,
        mobile: `${p.Name} (${translations.group})`,
        deviceToken: `${p.Name} (${translations.group})`,
        type: "internalgroup",
        mobileChecked: true,
        emailChecked: true,
        notificationChecked: true,
        checked: true,
        isGroup: true,
        groupMembers: allGroupMembers,
      } as MBSRecipient;
    });
    return mappedGroups;
  };

  const onFail = (errorData) => {
    if (errorData.statusText) {
      setError(errorData.statusText);
    } else {
      setError("Error");
    }
  };

  const onSuccess = () => {
    setIsLoading(false);
    setMessageBody("");
    setMessageTitle("");
    queryClient.invalidateQueries({ queryKey: queryKeys.all });
    navigate("/pbslist");
    setIsLoading(false);
  };

  const submit = () => {
    setIsLoading(true);
    const recipients = selectedRecipients.filter(
      (p) =>
        p.checked &&
        ((p.mobileChecked && p.mobile) ||
          (p.emailChecked && p.email) ||
          (p.notificationChecked && p.deviceToken))
    );

    const personRecipients = recipients
      .filter((p) => p.isGroup === false)
      .map((q) => ({
        ID: q.id.toString(),
        Username: q.id.toString(),
        Email: q.emailChecked,
        MobileNr: q.mobileChecked,
        DeviceToken: q.notificationChecked,
        origin: q.type,
      }));

    const groupRecipients = recipients
      .filter((p) => p.isGroup === true)
      .map((q) => ({
        GroupId: q.id.toString(),
        Email: q.emailChecked,
        MobileNr: q.mobileChecked,
        DeviceToken: q.notificationChecked,
        origin: q.type,
      }));

    const message: Message = {
      Title: messageTitle,
      Body: messageBody,
    };
    const selectedTemplate = templates.filter(
      (p) => p.id === selectedTemplateId
    )[0];
    let alternatives = null;
    if (selectedTemplate.alternatives) {
      alternatives = [...selectedTemplate.alternatives];
    }

    if (questionRequired) {
      message.Question = messageQuestion;
    }

    const createSendOutRequest: CreateSendOutBatchRequest = {
      licenseId: customer.Id,
      recipiants: personRecipients,
      grouprecipiants: groupRecipients,
      message: message,
      alternatives: alternatives,
      criticalAlert: criticalAlertIsChecked,
      geoLocation: geoLocationIsChecked,
    };

    createSendOut(createSendOutRequest, {
      onSuccess: () => {
        onSuccess();
      },
      onError: (err) => {
        onFail(err.response);
      },
    });
  };

  const onSelectTemplate = (templateId: number) => {
    setSelectedTemplateId(templateId);

    const required = templateId
      ? templates.filter((p) => p.id === templateId)[0].questionIncluded
      : false;

    setQuestionRequired(required);
  };

  useEffect(() => {
    if (stepsEnabled && selectedTemplateId !== null) {
      setMessageTemplateIsFlashing(false);
    }
  }, [selectedTemplateId, stepsEnabled]);

  function NoUsers() {
    return <p>{translations.nousersselected}</p>;
  }

  function NoTemplate() {
    return <p>{translations.notemplateselected}</p>;
  }

  function ErrorMessage() {
    return <p>{error}</p>;
  }

  const onClickSubmit = (e) => {
    e.preventDefault();
    if (stepsEnabled && !onboardingTerminated) {
      handleOnboardingComplete();
    }
    if (!selectedTemplateId) {
      const modalConf: ModalConfiguration = {
        title: translations.notemplateselected,
        body: <NoTemplate />,
        buttonText: translations.back,
        type: "warning",
      };

      openModal(modalConf);
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      return;
    }
    if (!messageTitle) {
      setMessageTitleError(translations.mandatory);
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    if (!messageBody) {
      setMessageBodyError(translations.mandatory);
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    if (questionRequired && !messageQuestion) {
      setMessageQuestionError(translations.mandatory);
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    const recipients = selectedRecipients.filter(
      (p) =>
        p.checked &&
        ((p.mobileChecked && p.mobile) ||
          (p.emailChecked && p.email) ||
          (p.notificationChecked && p.deviceToken))
    );
    if (!recipients || recipients.length === 0) {
      const modalConf: ModalConfiguration = {
        title: translations.nousersselected,
        body: <NoUsers />,
        buttonText: translations.back,
        type: "warning",
      };
      openModal(modalConf);
    } else if (
      messageBody &&
      messageTitle &&
      !messageTitleError &&
      !messageBodyError &&
      recipients &&
      recipients.length > 0
    ) {
      const modalBody = (
        <MBSConfirmation
          translations={translations}
          messageTitle={messageTitle}
          messageBody={messageBody}
          messageQuestion={messageQuestion}
          questionRequired={questionRequired}
          recipients={recipients}
          criticalAlert={criticalAlertIsChecked}
        />
      );

      const modalConf: ModalConfiguration = {
        title: translations.confirm,
        body: modalBody,
        buttonText: translations.send,
        type: "info",
        okCallback: submit,
      };

      openModal(modalConf);
    }
  };

  const onClickRecipient = (recipient: MBSRecipient) => {
    const type = recipient.type;
    const clickedId = recipient.id;

    if (type === "internalgroup") {
      const contactGroupToDisplay = contactGroups.filter(
        (p) => p.id == clickedId
      )[0];
      const contactGroupTitle = `${translations.contactgroup} - ${contactGroupToDisplay.name}`;
      const mappedContacts = contactGroupToDisplay.groupMembers.map((p) => {
        return {
          id: p.id,
          name: p.name,
          email: p.email,
          mobile: p.mobile,
          deviceToken: p.deviceToken,
          type: p.type,
        } as ContactListModel;
      });

      const contactList = (
        <div>
          <h3>{translations.groupmembers}</h3>
          <ContactList
            translations={translations}
            contacts={mappedContacts}
            showEdit={false}
            showDelete={false}
            showAdd={false}
          />
        </div>
      );

      const modalConf: ModalConfiguration = {
        title: contactGroupTitle,
        body: contactList,
        buttonText: "Ok",
        type: "info",
        centerContent: false,
      };

      openModal(modalConf);
    } else if (type === "internaluser") {
      const userToDisplay = users.filter((p) => p.id == clickedId)[0];
      const userTitle = `${translations.user} - ${userToDisplay.name}`;

      const modalConf: ModalConfiguration = {
        title: userTitle,
        body: (
          <EditContact
            translations={translations}
            customerId={customer.Id}
            contactId={userToDisplay.id}
            nameInit={userToDisplay.name}
            emailInit={userToDisplay.email}
            mobileInit={userToDisplay.mobile}
            displayOnly
          />
        ),
        hideButton: true,
        type: "info",
      };

      openModal(modalConf);
    } else if (type === "internalcontact") {
      const contactToDisplay = contacts.filter((p) => p.id == clickedId)[0];
      const contactTitle = `${translations.contact} - ${contactToDisplay.name}`;

      const modalConf: ModalConfiguration = {
        title: contactTitle,
        body: (
          <EditContact
            translations={translations}
            customerId={customer.Id}
            contactId={contactToDisplay.id}
            nameInit={contactToDisplay.name}
            emailInit={contactToDisplay.email}
            mobileInit={contactToDisplay.mobile}
            selectedOptions={contactToDisplay.selectedOptions}
            optionSets={optionSets}
            displayOnly={true}
          />
        ),
        hideButton: true,
        type: "info",
      };
      openModal(modalConf);
    }
  };

  const onCheckAllMobile = () => {
    const newSelectedRecipients = selectedRecipients.map((item) => {
      const updatedItem = {
        ...item,
        mobileChecked: !mobileAllChecked,
      };
      return updatedItem;
    });
    setSelectedRecipients(newSelectedRecipients);

    const newNotSelectedRecipients = notSelectedRecipients.map((item) => {
      const updatedItem = {
        ...item,
        mobileChecked: !mobileAllChecked,
      };
      return updatedItem;
    });
    setNotSelectedRecipients(newNotSelectedRecipients);

    setMobileAllChecked(!mobileAllChecked);
  };

  const onCheckAllEmail = () => {
    const newSelectedRecipients = selectedRecipients.map((item) => {
      const updatedItem = {
        ...item,
        emailChecked: !emailAllChecked,
      };
      return updatedItem;
    });
    setSelectedRecipients(newSelectedRecipients);

    const newNotSelectedRecipients = notSelectedRecipients.map((item) => {
      const updatedItem = {
        ...item,
        emailChecked: !emailAllChecked,
      };
      return updatedItem;
    });
    setNotSelectedRecipients(newNotSelectedRecipients);
    setEmailAllChecked(!emailAllChecked);
  };

  const onCheckAllNotification = () => {
    const newSelectedRecipients = selectedRecipients.map((item) => {
      const updatedItem = {
        ...item,
        notificationChecked: !notificationAllChecked,
      };
      return updatedItem;
    });
    setSelectedRecipients(newSelectedRecipients);

    const newNotSelectedRecipients = notSelectedRecipients.map((item) => {
      const updatedItem = {
        ...item,
        notificationChecked: !notificationAllChecked,
      };
      return updatedItem;
    });
    setNotSelectedRecipients(newNotSelectedRecipients);
    setNotificationAllChecked(!notificationAllChecked);
  };

  const onMessageBodyChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setMessageBodyError(translations.mandatory);
    } else {
      setMessageBodyError("");
    }
    setMessageBody(event.target.value);
  };

  const onMessageTitleChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.value) {
      setMessageTitleError(translations.mandatory);
    } else {
      setMessageTitleError("");
    }
    setMessageTitle(event.target.value);
  };

  const onMessageQuestionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.value) {
      setMessageQuestionError(translations.mandatory);
    } else {
      setMessageQuestionError("");
    }
    setMessageQuestion(event.target.value);
  };

  const addRecipient = (recipient: MBSRecipient): void => {
    const selectedRecipientsCopy = [...selectedRecipients, recipient];
    setSelectedRecipients(selectedRecipientsCopy);

    const notSelectedRecipientsCopy = notSelectedRecipients.filter(
      (p) => p.uniqueKey !== recipient.uniqueKey
    );
    setNotSelectedRecipients(notSelectedRecipientsCopy);
  };

  const removeRecipient = (recipient: MBSRecipient): void => {
    const notSelectedRecipientsCopy = [...notSelectedRecipients, recipient];
    setNotSelectedRecipients(notSelectedRecipientsCopy);

    const selectedRecipientsCopy = selectedRecipients.filter(
      (p) => p.uniqueKey !== recipient.uniqueKey
    );
    setSelectedRecipients(selectedRecipientsCopy);
  };

  const onCheckCriticalAlert = (checked: boolean) => {
    setCriticalAlertIsChecked(checked);
  }

  useEffect(() => {
    if (isLoadingContactGroups === false) {
      const contactGroupRecipients =
        createContactGroupRecipients(dataContactGroups);
      setContactGroups(contactGroupRecipients);
    }
    return () => {
      setContactGroups([]);
    };
  }, [isLoadingContactGroups, dataContactGroups]);

  useEffect(() => {
    if (isLoadingUsers === false) {
      const userRecipients = createUserRecipients(dataUsers);
      setUsers(userRecipients);
    }
    return () => {
      setUsers([]);
    };
  }, [isLoadingUsers, dataUsers]);

  useEffect(() => {
    if (isLoadingContacts === false) {
      const contactRecipients = createContactRecipients(dataContacts);
      setContacts(contactRecipients);
    }
    return () => {
      setContacts([]);
    };
  }, [isLoadingContacts, dataContacts]);

  useEffect(() => {
    if (isLoadingCustomerContactOptionSets === false) {
      const optionSetViewModels = createOptionSetViewModels(
        dataCustomerContactOptionSets
      );
      setOptionSets(optionSetViewModels);
    }
  }, [isLoadingCustomerContactOptionSets, dataCustomerContactOptionSets]);

  useEffect(() => {
    const allRec = [
      ...contactGroups.sort((a, b) => a.name.localeCompare(b.name)),
      ...users.sort((a, b) => a.name.localeCompare(b.name)),
      ...contacts.sort((a, b) => a.name.localeCompare(b.name)),
    ];
    setNotSelectedRecipients([...allRec]);
  }, [users, contactGroups, contacts]);

  useEffect(() => {
    if (error) {
      const modalConf: ModalConfiguration = {
        title: "Error",
        body: <ErrorMessage />,
        buttonText: "Ok",
        type: "error",
        okCallback: () => {
          setError(null);
        },
      };

      openModal(modalConf);
    }
  }, [error]);

  useEffect(() => {
    if (onboardingTerminated === true) {
      return;
    }
    const shouldShowOnboarding = incompletedOnboardingFlows.map(p => p.OnboardingFlowId).includes(OnboardingFlowIds.Alert);
    if (shouldShowOnboarding && !onBoardingIsLoading) {
      const currentStep = onboardingsCurrentStep.get(OnboardingFlowIds.Alert);
      if (currentStep === 0) {
        const stepsFromApi = incompletedOnboardingFlows.find(p => p.OnboardingFlowId === OnboardingFlowIds.Alert)?.StepsHtmlContents;
        const introText = stepsFromApi.get(1);
        const parsedHtmlIntro = introText ? parseHtml(introText) : "";
        const onClickOk = () => {
          setCurrentStep(OnboardingFlowIds.Alert, 1);
          closeModal();
          setStepsEnabled(true);
        }

        const onClickClose = () => {
          setOnboardingIsComplete();
        }

        const modalConf: ModalConfiguration = {
          okCallback: onClickOk,
          onCloseModal: onClickClose,
          type: 'info',
          centerContent: true,
          body: <div className="intro-outro-container">
            <RichTextDisplay content={parsedHtmlIntro} />
          </div>,
          title: "Tutorial",
          buttonText: "Start",
          position: 'center'
        }
        openModal(modalConf);
      }
      else {
        setStepsEnabled(true);
      }
    }
  }, [incompletedOnboardingFlows, onBoardingIsLoading, onboardingsCurrentStep]);

  const handleOnboardingComplete = () => {
    setOnboardingIsComplete();
  };

  const setOnboardingIsComplete = () => {
    setOnboardingTerminated(true);
    setOnboardingIdIsComplete(OnboardingFlowIds.Alert);
    setCurrentStep(OnboardingFlowIds.Alert, 0);
    setStepsEnabled(false);
  }

  if (
    isLoading ||
    isLoadingContactGroups ||
    isLoadingContacts ||
    isLoadingCustomerContactOptionSets ||
    isLoadingUsers ||
    createSendOutIsLoading
  ) {
    return (
      <div className="spinner-container-global-center">
        <Spinner />
      </div>
    );
  }

  const onClickStartOnboarding = () => {
    setOnboardingTerminated(false);
    resetOnboarding(OnboardingFlowIds.Alert);
  }

  const stepsComponent = <MBSCreateNewOnboardingSteps
    stepsEnabled={stepsEnabled}
    onComplete={handleOnboardingComplete}
    setStepsEnabled={setStepsEnabled}
    setMessageTemplateIsFlashing={setMessageTemplateIsFlashing}
    messageTemplateIsSelected={!!selectedTemplateId}
  />

  const stepsIcon = <InfoIcon
    id="info-onboarding-mbs"
    place={"right-end"}
    onHoverContents={<Button onClick={onClickStartOnboarding}>Start tutorial</Button>}
    clickable={true}
  />

  return (
    <div className="pbs-wrapper">
      {stepsEnabled && stepsComponent}
      <div className='header-with-info-button'>
        <h1>{translations.newmbs}</h1>
      </div>
      <form className="pbs-grid">
        <span className="gradient" />
        <div>
          <div className='header-with-info-button'>
            <h3>{translations.choosetemplate}</h3>
            <div className='margin-bottom-40'>
              {stepsIcon}
            </div>
          </div>

          <MBSTemplateSelector
            templates={templates}
            onSelectTemplate={onSelectTemplate}
            selectedId={selectedTemplateId}
            messageTemplateIsFlashing={messageTemplateIsFlashing}
          />
        </div>
        <span className="gradient" />
        {selectedTemplateId ? (
          <>
            <div>
              <h3>{translations.message}</h3>
              <div className="message-editor-container">
                <MBSMessageEditor
                  translations={translations}
                  handleMessageBodyChange={onMessageBodyChanged}
                  handleMessageTitleChange={onMessageTitleChanged}
                  handleQuestionChange={onMessageQuestionChange}
                  messageTitleError={messageTitleError}
                  messageBodyError={messageBodyError}
                  messageQuestionError={messageQuestionError}
                  message={messageBody}
                  title={messageTitle}
                  question={messageQuestion}
                  showQuestionEditor={questionRequired}
                  rows={5}
                  cols={20}
                  showNoOfSms={mobileAllChecked}
                />
              </div>
            </div>

            <span className="gradient" />
          </>
        ) : null}
        <div>
          <h3>{translations.communicationchannels}</h3>
          <CommunicationTypeFilter
            onCheckAllMobile={onCheckAllMobile}
            onCheckAllEmail={onCheckAllEmail}
            onCheckAllNotification={onCheckAllNotification}
            checkAllMobileChecked={mobileAllChecked}
            checkAllEmailChecked={emailAllChecked}
            checkAllNotificationChecked={notificationAllChecked}
            smsDisabled={user?.Role === Roles.Trial || enabledFeatures.mbs.disableSMS === "true"}
            translations={translations}
            notificationDisabled={enabledFeatures.mbs.disableNotification === "true"}
            emailDisabled={enabledFeatures.mbs.disableEmail === "true"}
          />
          {notificationAllChecked && <div className='flex-row-container gap-10 vertical-aligner horizontal-aligner left-aligner margin-top-20'>
            <CustomCheckbox
              onChange={onCheckCriticalAlert}
              checked={criticalAlertIsChecked}
            />
            Critical alert
            <InfoIcon
              id="info-mbs-critical-alert"
              onHoverContents={translations.criticalalerttooltip}
            />
          </div>}
        </div>
        <span className="gradient" />
        <div className="contacts-container">
          <div className="mbs-users-container">
            <div className="form-section-header">
              {translations.selectedrecipients}
            </div>
            <div className="horizontal-aligner">
              {selectedRecipients && selectedRecipients.length > 0 && (
                <MBSSelectedRecipients
                  translations={translations}
                  recipients={selectedRecipients}
                  removeRecipient={removeRecipient}
                  clickRecipient={onClickRecipient}
                />
              )}
            </div>
          </div>
          <div className="mbs-users-container">
            <div className="form-section-header">
              {translations.searchrecipients}
            </div>
            <div className="horizontal-aligner">
              <MBSSearchRecipients
                translations={translations}
                recipients={notSelectedRecipients}
                addRecipient={addRecipient}
                clickRecipient={onClickRecipient}
              />
            </div>
          </div>
        </div>
        <div>
          <Button
            buttonStyle="btn--primary"
            buttonSize="btn--large"
            type="button"
            onClick={onClickSubmit}
          >
            {translations.send}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default MBSCreateNew;
