import { AxiosResponse } from "axios";

export interface OptionModel {
    ID: number,
    Value: string,
    OptionSetId: number,
    OptionSetName: string,
}

export interface OptionSetModel {
    ID: number,
    Name: string,
    CustomerId: string,
    EnabledForContacts: boolean,
    Options: OptionModel[],
}

export interface SelectedOptionSetViewModel {
    id: number,
    value: any,
    optionSetId: number,
    optionSetName: string,
}

export interface OptionViewModel {
    id: number,
    value: any,
}

export interface OptionSetViewModel {
    name: string,
    id: number,
    options: OptionViewModel[],
}

export interface ContactModel {
    ID?: number,
    uniqueKey?: string,
    Name: string,
    Email?: string,
    MobileNr?: string,
    DeviceToken?: string,
    CustomerId: string | null | undefined,
    type?: string,
    SelectedOptions?: OptionModel[],
    contactGroupId?: number[],
}

export interface ContactViewModel {
    id: number,
    name: string,
    email?: string,
    mobile?: string,
    selectedOptions?: SelectedOptionSetViewModel[],
}

export interface IContactsApi {
    getContactsByCustId: (customerId: string) => Promise<ContactModel[]>;
    createContact: (request: ContactModel) => Promise<AxiosResponse<any, any>>;
    editContact: (request: ContactModel) => Promise<AxiosResponse<any, any>>;
    removeContact: (contactId: number) => Promise<AxiosResponse<any, any>>;
    getCustomerContactsOptionSets: (customerId: string) => Promise<AxiosResponse<any, any>>;


}

const ContactsApiType = {
    IContactsApi: Symbol("IContactsApi"),
};

export default ContactsApiType;
