import React, {useState, FunctionComponent} from "react"
import Input from "@murphy-frontend/web-core/components/Input"
import Button from "@murphy-frontend/web-core/components/Button"
import {CreateTemplateRequest} from "../../../common/api/Dashboards/DashboardsApi"
import {useCreateTemplate} from "../../../common/api/Dashboards/mutations"
import {DashboardsListViewModel} from "./DashboardsList"
import {MurphyBasicCustomerDto} from "@murphy-frontend/common/interfaces/ICustomersApi"

interface CreateTemplateProps {
	row: DashboardsListViewModel
	translations: Record<string, string>
	customer: MurphyBasicCustomerDto
	closeModal: () => void
}

const CreateTemplate: FunctionComponent<CreateTemplateProps> = ({
	row,
	translations,
	customer,
	closeModal
}) => {
	const [name, setName] = useState("")
	const [nameErrorMessage, setNameErrorMessage] = useState("")
	const [isLoading, setIsLoading] = useState(false)

	const {mutate: createTemplate} = useCreateTemplate(customer?.Id, translations)

	const submitCreateTemplate = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault()
		if (nameErrorMessage) {
			return
		}

		setIsLoading(true)
		const createTemplateRequest: CreateTemplateRequest = {
			TemplateName: name,
			customerId: customer.Id,
			dashboardId: row.id
		}

		createTemplate(createTemplateRequest, {
			onSuccess: response => {
				setIsLoading(false)
				closeModal()
			},
			onError: () => {
				setIsLoading(false)
				closeModal()
			}
		})
	}

	const onNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newVal = event.target.value
		if (!newVal || (newVal.length && !newVal.trim())) {
			setNameErrorMessage(translations.templateinputerror)
		} else {
			setNameErrorMessage("")
		}
		setName(newVal)
	}

	return (
		<form className='modal-form' key={row.id}>
			<Input
				isRequired
				placeholder={translations.template}
				maxLength={70}
				min={3}
				handleChange={onNameChanged}
				errorMessage={nameErrorMessage}
			/>
			<div className='modal-footer'>
				<Button
					buttonStyle='btn--primary'
					buttonSize='btn--medium'
					onClick={submitCreateTemplate}
					isLoading={isLoading}>
					{translations.save}
				</Button>
			</div>
		</form>
	)
}

export default CreateTemplate
