import * as React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "@murphy-frontend/web-core/components/Button";
import Textarea from "@murphy-frontend/web-core/components/TextArea";
import DOMPurify from "dompurify";

yup.addMethod(yup.string, "html", function () {
  return this.test({
    name: "html",
    exclusive: true,
    message: "Invalid text",
    test: (value) => {
      DOMPurify.sanitize(value);
      if (DOMPurify.removed.length > 0) {
        return false;
      }
      return true;
    },
  });
});

const goalsSchema = yup.object().shape({
  message: yup.string().nullable().html(),
});

export interface GoalsFormDto {
  message?: string;
}

interface GoalsFormProps {
  goals?: GoalsFormDto;
  onSubmit: (data: GoalsFormDto) => void;
  translations?: any;
  isLoading?: boolean;
}

const GoalsForm = ({
  isLoading,
  goals,
  onSubmit,
  translations,
}: GoalsFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isDirty },
    reset,
    getValues,
  } = useForm<GoalsFormDto>({
    resolver: yupResolver(goalsSchema),
    defaultValues: {
      message: "",
    },
  });

  useEffect(() => {
    if (goals) {
      const defaults = {
        message: goals.message,
      };
      reset(defaults);
    }
  }, [goals, reset]);

  const onSubmitHandler = (data: GoalsFormDto) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="dashboardform-container">
        <Textarea
          isControlled
          rows={24}
          register={register}
          name="message"
          title={translations["class-lang-modal-description"]}
          errorMessage={errors.message?.message}
        />
        <div className="form-buttons">
          {isDirty && (
            <Button
              type="button"
              disabled={!isDirty || isLoading}
              onClick={() => reset()}
              buttonStyle="btn--undo"
              buttonSize="btn--medium"
              isNeutralButton={true}
            >
              {translations.undo}
            </Button>
          )}
          <Button
            isLoading={isLoading}
            disabled={!isDirty || (errors && errors.length > 0)}
            type="submit"
            buttonStyle="btn--primary"
            buttonSize="btn--medium"
          >
            {translations.save}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default GoalsForm;
