import React, {
	useState,
	useContext,
	createContext,
	useEffect,
	FunctionComponent,
} from 'react';
import dayjs from 'dayjs';
import { courseLanguages, languages } from '@murphy-frontend/web-core/constants';
import { useInjection } from '../../../../common/contexts/InversifyContext';
import PersistenceType, {
	IPersistenceService,
	LocalStorageKeys,
} from '../../../../common/interfaces/IPersistenceService';
import ApiServiceType, {
	IApiService,
} from '../../../../common/interfaces/IApiService';

import { TimeZoneType } from "@murphy-frontend/common/services/TimeService"

export type LanguageType = 'swe' | 'eng' | 'nor' | 'fin' | 'dan';
export type CourseLanguageType = 'swe' | 'eng';
export type SelectedCourseLanguagesType = CourseLanguageType[];
interface UserPreferencesContextType {
	translations: Record<string, string>;
	timeZone: TimeZoneType;
	language: LanguageType;
	selectedCourseLanguages: SelectedCourseLanguagesType;
	courseFilter: string;
	setTimeZone: (tz: TimeZoneType) => void;
	setLanguage: (lang: LanguageType) => void;
	setSelectedCourseLanguages: (languages: LanguageType[]) => void;
	updateCourseFilter: (courseFilter: string) => void;
}

interface UserPreferencesContextProps {
	children?: React.ReactNode;
}

const UserPreferencesContext = createContext<UserPreferencesContextType | undefined>(undefined);
const defaultLang = 'eng'; // Swedish default
const defaultTimeZone = '1'; // Local time default

const UserPreferencesProvider: FunctionComponent<
	UserPreferencesContextProps
> = ({ children }) => {
	const [translations, setTranslations] = useState<any>('');
	const [timeZone, setTimeZone] = useState<TimeZoneType>(null);
	const [language, setLanguage] = useState<LanguageType>(null);
	const [selectedCourseLanguages, setSelectedCourseLanguages] =
		useState<SelectedCourseLanguagesType>(null);
	const [courseFilter, setCourseFilter] = useState<string>(null)
	const persistenceService = useInjection<IPersistenceService>(
		PersistenceType.IPersistenceService,
	);
	const apiService = useInjection<IApiService>(ApiServiceType.IApiService);

	const getTranslationsFromApi = (lang: string) => {
		apiService.getLangTranslations(lang).then((response) => {
			const { data } = response;
			const stringifiedTranslations = JSON.stringify(data);
			persistenceService.setTranslations(stringifiedTranslations);
			setTranslations(data);
		});
	};

	const setLocale = (lang: string) => {
		let locale = 'sv';
		switch (lang) {
			case 'swe':
				locale = 'sv';
				break;
			case 'eng':
				locale = 'en';
				break;
			case 'nor':
				locale = 'nb';
				break;
			case 'fin':
				locale = 'fi';
				break;
			default:
				locale = 'sv';
		}

		dayjs.locale(locale);
	};

	useEffect(() => {
		if (timeZone) {
			persistenceService.setSelectedTimeZone(timeZone);
		}
	}, [timeZone]);

	useEffect(() => {
		if (language) {
			persistenceService.setLanguage(language);
			getTranslationsFromApi(language);
			setLocale(language);
		}
	}, [language]);

	useEffect(() => {
		if (selectedCourseLanguages) {
			const jsonVal = JSON.stringify(selectedCourseLanguages);
			persistenceService.setSelectedCourseLanguages(jsonVal);
		}
	}, [selectedCourseLanguages]);

	useEffect(() => {
		if (courseFilter) {
			persistenceService.setCourseFilter(courseFilter);
		} else {
			setCourseFilter(persistenceService.getCourseFilter());
		}
	}, [courseFilter]);

	const updateLanguage = () => {
		const lang = persistenceService.getLanguage();
		let currentLang = languages.filter((p) => p.id == defaultLang)[0].id;
		if (lang) {
			currentLang = languages.filter((p) => p.id == lang)[0].id;
			setLanguage(currentLang);
		} else {
			setLanguage(currentLang);
		}
	};

	const updateTimeZone = () => {
		const tz = persistenceService.getSelectedTimeZone();
		if (tz) {
			setTimeZone(tz as TimeZoneType);
		} else {
			setTimeZone(defaultTimeZone);
		}
	};

	const updateSelectedCourseLanguages = () => {
		const val = persistenceService.getSelectedCourseLanguages();
		if (val && val.length > 0) {
			const parsedVal = JSON.parse(val);
			setSelectedCourseLanguages(
				parsedVal as SelectedCourseLanguagesType,
			);
		} else {
			setSelectedCourseLanguages(courseLanguages.map((p) => p.id));
		}
	};

	const updateCourseFilter = (courseFilter?: string) => {
		if (courseFilter) {
			setCourseFilter(courseFilter);
		}
	}

	useEffect(() => {
		updateTimeZone();
		updateLanguage();
		updateSelectedCourseLanguages();
		function storageEventHandler(event: any) {
			if (event.key === LocalStorageKeys.Language) {
				updateLanguage();
			}
			if (event.key === LocalStorageKeys.SelectedTimeZone) {
				updateTimeZone();
			}
			if (event.key === LocalStorageKeys.SelectedCourseLanguages) {
				updateSelectedCourseLanguages();
			}
		}
		// Hook up the event handler
		window.addEventListener('storage', storageEventHandler);
		return () => {
			// Remove the handler when the component unmounts
			window.removeEventListener('storage', storageEventHandler);
		};
	}, []);

	const value = {
		translations,
		timeZone,
		language,
		courseFilter,
		selectedCourseLanguages,
		setTimeZone,
		setLanguage,
		setSelectedCourseLanguages,
		updateCourseFilter
	};

	return (
		<UserPreferencesContext.Provider value={value}>
			{children}
		</UserPreferencesContext.Provider>
	);
};

export default UserPreferencesProvider;

export const useUserPreferences = () => useContext(UserPreferencesContext);
