import * as React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "@murphy-frontend/web-core/components/Input";
import Button from "@murphy-frontend/web-core/components/Button";
import Textarea from "@murphy-frontend/web-core/components/TextArea";
import dayjs from "dayjs";
import MurphyDatePicker from "@murphy-frontend/web-core/components/MurphyDatePicker";
import MurphyCheckbox from "../../../../common/components/MurphyCheckbox";
import DOMPurify from "dompurify";

yup.addMethod(yup.string, "html", function () {
  return this.test({
    name: "html",
    exclusive: true,
    message: "Invalid text",
    test: (value) => {
      DOMPurify.sanitize(value);
      if (DOMPurify.removed.length > 0) {
        return false;
      }
      return true;
    },
  });
});

const informationCardSchema = yup.object().shape({
  Title: yup.string().required(),
  Message: yup.string().nullable().html(),
  Status: yup.number(),
  ProvidedBy: yup.string().nullable().notRequired(),
  Owner: yup.string().nullable().notRequired(),
  isDecision: yup.boolean(),
  Confirmed: yup.boolean(),
  Important: yup.boolean(),
  AlternatedTime: yup.date().nullable().notRequired(),
});

export interface InformationCardFormDto {
  ID: number;
  Title: string;
  Message?: string;
  Status: number;
  ProvidedBy?: string;
  Owner: string;
  isDecision: boolean;
  Important: boolean;
  Confirmed: boolean;
  AlternatedTime?: Date;
  ParentId?: number;
}

interface InformationCardFormProps {
  informationCard?: InformationCardFormDto;
  onSubmit: (data: InformationCardFormDto) => void;
  translations?: any;
  onClickArchive: (id: number) => void;
  isLoading?: boolean;
}

const InformationCardForm = ({
  informationCard,
  onSubmit,
  translations,
  onClickArchive,
  isLoading,
}: InformationCardFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isDirty },
    reset,
    getValues,
  } = useForm<InformationCardFormDto>({
    resolver: yupResolver(informationCardSchema),
    defaultValues: {
      Title: "",
      Message: "",
      Status: 0,
      ProvidedBy: "",
      Owner: "",
      isDecision: false,
      Important: false,
      Confirmed: false,
    },
  });

  useEffect(() => {
    if (informationCard) {
      const defaults = {
        Title: informationCard.Title,
        Message: informationCard.Message,
        Status: informationCard.Status,
        ProvidedBy: informationCard?.ProvidedBy,
        Owner: informationCard.Owner,
        isDecision: informationCard.isDecision,
        Important: informationCard.Important,
        AlternatedTime: informationCard.AlternatedTime
          ? dayjs(informationCard.AlternatedTime).toDate()
          : undefined,
        Confirmed: informationCard.Confirmed,
      };
      reset(defaults);
    }
  }, [informationCard, reset]);

  const onSubmitHandler = (data: InformationCardFormDto) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="dashboardform-container double-col">
        <div className="dashboardform-container-section">
          {/* <span className="form-section-header">INFO</span> */}
          <Input
            isControlled
            register={register}
            name="Title"
            isRequired
            title={translations["class-lang-modal-title"]}
            errorMessage={errors.Title?.message}
          />
          <Input
            isControlled
            register={register}
            name="Owner"
            title={translations["class-lang-modal-decision-maker"]}
            errorMessage={errors.Owner?.message}
          />
          <Input
            isControlled
            register={register}
            name="ProvidedBy"
            title={translations["class-lang-modal-responsible"]}
            errorMessage={errors.ProvidedBy?.message}
          />
          <div className="form-input flex-input-container">
            <MurphyCheckbox
              isControlled
              control={control}
              name="isDecision"
              errorMessage={errors.isDecision?.message}
            />
            <label>{translations["class-lang-modal-decision-check"]}</label>
          </div>
          <MurphyDatePicker
            title={translations["class-lang-modal-alt-time"]}
            isControlled
            control={control}
            name="AlternatedTime"
            locale="sv"
            showTime={true}
            errorMessage={errors.AlternatedTime?.message}
          />
          <div className="form-input flex-input-container">
            <MurphyCheckbox
              isControlled
              control={control}
              name="Important"
              errorMessage={errors.Important?.message}
            />
            <label>{translations["class-lang-modal-check-priority"]}</label>
          </div>
          <div className="form-input flex-input-container">
            <MurphyCheckbox
              isControlled
              control={control}
              name="Confirmed"
              errorMessage={errors.Confirmed?.message}
            />
            <label>{translations["class-lang-modal-check-confirmed"]}</label>
          </div>
        </div>
        <div className="dashboardform-container-section">
          {/* <span className="form-section-header">Beskrivning</span> */}

          <Textarea
            isControlled
            rows={24}
            register={register}
            name="Message"
            title={translations["class-lang-modal-description"]}
            errorMessage={errors.Message?.message}
          />
        </div>
        <div className="dashboardform-container-section">
          <div className="form-buttons">
            {informationCard && (
              <Button
                type="button"
                disabled={isDirty || isLoading}
                onClick={() => onClickArchive(informationCard.ID)}
                buttonStyle="btn--archive"
                buttonSize="btn--medium"
                isWarningButton={true}
              >
                {translations.archive}
              </Button>
            )}
            {isDirty && (
              <Button
                type="button"
                disabled={!isDirty || isLoading}
                onClick={() => reset()}
                buttonStyle="btn--undo"
                buttonSize="btn--medium"
                isNeutralButton={true}
              >
                {translations.undo}
              </Button>
            )}
          </div>
        </div>
        <div className="dashboardform-container-section">
          <div className="form-buttons save-button">
            <Button
              isLoading={isLoading}
              disabled={!isDirty || (errors && errors.length > 0)}
              type="submit"
              buttonStyle="btn--primary"
              buttonSize="btn--medium"
            >
              {translations.save}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default InformationCardForm;
