import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useInjection } from '../../contexts/InversifyContext';
import ContactGroupApiType, { CreateContactGroupRequest, CreateContactGroupUserRequest, EditContactGroupRequest, IContactGroupApi, AddContactToGroupsRequest, AddUserToGroupsRequest } from '../../interfaces/IContactGroupApi';

import queryKeys from './QueryKeys';

export const useCreateContactGroup = (customerId: string | null | undefined) => {
  const queryClient = useQueryClient();
  const api = useInjection<IContactGroupApi>(ContactGroupApiType.IContactGroupApi);

  return useMutation({
    mutationFn: (createContactGroupRequest: CreateContactGroupRequest) => api.createContactGroup(createContactGroupRequest),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list(customerId) });
      // Optional success message can be added here.
    },
  });
};

export const useCreateContactGroupWithUsers = (customerId: string | null | undefined) => {
  const queryClient = useQueryClient();
  const api = useInjection<IContactGroupApi>(ContactGroupApiType.IContactGroupApi);

  return useMutation({
    mutationFn: (createContactGroupRequest: CreateContactGroupRequest) => api.createContactGroupWithUsers(createContactGroupRequest),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list(customerId) });
      // Optional success message can be added here.
    },
  });
};

// ... Similarly refactor the remaining functions

export const useEditContactGroup = (contactGroupId: number, customerId: string) => {
  const queryClient = useQueryClient();
  const api = useInjection<IContactGroupApi>(ContactGroupApiType.IContactGroupApi);

  return useMutation({
    mutationFn: (editContactGroupRequest: EditContactGroupRequest) => api.editContactGrp(editContactGroupRequest),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list(customerId) });
      queryClient.invalidateQueries({ queryKey: queryKeys.detail(contactGroupId) });
      // Success message here
    },
  });
};

// Use Delete Contact Group Mutation
export const useDeleteContactGroup = () => {
  const queryClient = useQueryClient();
  const api = useInjection<IContactGroupApi>(ContactGroupApiType.IContactGroupApi);

  return useMutation({
    mutationFn: (id: number) => api.deleteContactGrp(id),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all });
      // Optional success message can be added here
    },
  });
};

// Use Add User To Contact Group Mutation
export const useAddUserToContactGroup = (contactGroupId: number, customerId: string) => {
  const queryClient = useQueryClient();
  const api = useInjection<IContactGroupApi>(ContactGroupApiType.IContactGroupApi);

  return useMutation({
    mutationFn: (createContactGroupUserRequest: CreateContactGroupUserRequest) => api.createContactGroupUser(createContactGroupUserRequest),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list(customerId) });
      queryClient.invalidateQueries({ queryKey: queryKeys.users(contactGroupId) });
      // Success message here
    },
  });
};

// Use Delete User From Contact Group Mutation
export const useDeleteUserFromContactGroup = (contactGroupId: number, customerId: string) => {
  const queryClient = useQueryClient();
  const api = useInjection<IContactGroupApi>(ContactGroupApiType.IContactGroupApi);

  return useMutation({
    mutationFn: (contactGroupUserId: number) => api.deleteContactGroupUser(contactGroupUserId),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list(customerId) });
      queryClient.invalidateQueries({ queryKey: queryKeys.users(contactGroupId) });
      // Success message here
    },
  });
};

// Use Add Contact To Contact Group Mutation
export const useAddContactToContactGroup = (contactGroupId: number, customerId: string) => {
  const queryClient = useQueryClient();
  const api = useInjection<IContactGroupApi>(ContactGroupApiType.IContactGroupApi);

  return useMutation({
    mutationFn: (contactId: number) => api.createContactGroupContact(contactId, contactGroupId),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list(customerId) });
      queryClient.invalidateQueries({ queryKey: queryKeys.contacts(contactGroupId) });
      // Success message here
    },
  });
};

// Use Add Contact To Contact Groups Mutation
export const useAddContactToContactGroups = (customerId: string) => {
  const queryClient = useQueryClient();
  const api = useInjection<IContactGroupApi>(ContactGroupApiType.IContactGroupApi);

  return useMutation({
    mutationFn: ({ contactId, addContactToGroupsRequest }: { contactId: number; addContactToGroupsRequest: AddContactToGroupsRequest }) => api.addContactToGroups(contactId, addContactToGroupsRequest),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list(customerId) });
      // Optional success message can be added here
    },
  });
};

// Use Add User To Contact Groups Mutation
export const useAddUserToContactGroups = (customerId: string) => {
  const queryClient = useQueryClient();
  const api = useInjection<IContactGroupApi>(ContactGroupApiType.IContactGroupApi);

  return useMutation({
    mutationFn: ({ customerUserId, addUserToGroupsRequest }: { customerUserId: string; addUserToGroupsRequest: AddUserToGroupsRequest }) => api.addUserToGroups(customerUserId, addUserToGroupsRequest),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list(customerId) });
      // Optional success message can be added here
    },
  });
};

// Use Delete Contact From Contact Group Mutation
export const useDeleteContactFromContactGroup = (contactGroupId: number, customerId: string) => {
  const queryClient = useQueryClient();
  const api = useInjection<IContactGroupApi>(ContactGroupApiType.IContactGroupApi);

  return useMutation({
    mutationFn: (contactId: number) => api.deleteContactGroupContact(contactId, contactGroupId),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list(customerId) });
      queryClient.invalidateQueries({ queryKey: queryKeys.contacts(contactGroupId) });
      // Success message here
    },
  });
};
