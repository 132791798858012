import { DroneTab } from "@murphy-frontend/common/api/DroneEvents/types";
import React from "react";
import { FunctionComponent } from "react";

interface StartedEventFlowTabsProps {
    tabs: DroneTab[];
    selectedTabIndex: number;
    setSelectedTabIndex: (index: number) => void;
}

const StartedEventFlowTabs: FunctionComponent<StartedEventFlowTabsProps> = (
    {
        tabs,
        selectedTabIndex,
        setSelectedTabIndex
    }
) => {

    return (
        <div className="tab-titles-wrapper">
            {tabs.map((tab, index) => {
                const tabClassName = index === selectedTabIndex ? "chosen-tab-title-wrapper" : "not-chosen-tab-title-wrapper";
                const textClassName = index === selectedTabIndex ? "chosen-tab-text" : "not-chosen-tab-text";

                return (
                    <div
                        key={`${tab.Title}-${index}`}
                        className={tabClassName}
                        onClick={() => setSelectedTabIndex(index)}
                    >
                        <span className={`drone-regular-text ${textClassName}`}>
                            {tab.Title}
                        </span>
                    </div>
                );
            })}
        </div>
    );
}

export default StartedEventFlowTabs;