import React, {useEffect, useState} from "react";
import { FunctionComponent } from "react";
import { FaEnvelope, FaSms, FaUsers } from "react-icons/fa";
import { IconContext } from 'react-icons/lib';
import { useSpring, animated } from 'react-spring'
import useMeasure from 'react-use-measure'
import ContactListItem from "./ContactListItem";
import {
    RiPencilFill, RiAddCircleFill, RiDeleteBin7Fill, RiArrowUpSLine, RiArrowDownSLine, RiShieldUserFill, RiUserFill,
  } from 'react-icons/ri';
import { ContactModel, OptionSetModel } from '../../../common/api/Contacts/ContactsApi';
import { ContactListItemExpanded } from "./ContactListItemExpanded";


  
  interface IContactLisPaginatedRow {
    contact: ContactModel,
    onClickEdit?: (id: number, contact: string) => void,
    onClickDelete?: (id: number, contact: string) => void,
    onClickAdd?: (id: number, contact: string) => void,
    showEdit?: boolean,
    showDelete?: boolean,
    showAdd?: boolean,
    translations: Record<string, string>,
    pageNum?: number
  }
  

export const ContactLisPaginatedRow: FunctionComponent<IContactLisPaginatedRow>
    = ({
        onClickEdit, onClickDelete, onClickAdd, translations, showEdit, showDelete, showAdd, pageNum,
        contact }) => {
            const [isExpanded, setIsExpanded] = useState(false);
            const onExpandClick = () => {
                setIsExpanded(!isExpanded);
              };

    const [ref, bounds] = useMeasure();
    const props = useSpring({
        from: {
            height: 0
        },
        to: {
            height: isExpanded ? bounds.height : 0
        }
    });

    useEffect(() => {
        setIsExpanded(false);
    }, [pageNum])

        return (
            <div onClick={(e) => {
                e.preventDefault();
                onExpandClick();
            }} className="expandable-table-row clickable-cell">
                <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className="expandable-table-row-left-container">
                {isExpanded ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                {contact.type === "contact" ?
                   <div>
                   <IconContext.Provider value={{ className: 'user-table-icon' }}>
                       <RiShieldUserFill />
                   </IconContext.Provider>
               </div>
                :null}
                {contact.type === "user" ?
                   <div>
                   <IconContext.Provider value={{ className: 'user-table-icon' }}>
                       <RiUserFill />
                   </IconContext.Provider>
               </div>
                :null}
                    <div className="expandable-table-row-left-text">
                        {contact.name}
                    </div>
                </div>
                <div className="expandable-table-row-right-container">

                    <IconContext.Provider value={{ className: 'mobile-email-icon' }}>
                        {showEdit === true
                            ? <span className={showEdit ? '' : 'contact-item-icon cursor-pointer'} title={'showEdit'}>
                                <RiPencilFill onClick={(e) => { onClickEdit(contact.id); e.stopPropagation(); }} />
                            </span>
                            : null}
                        {showAdd === true
                            ? <span className={showAdd ? '' : 'contact-item-icon cursor-pointer'} title={'showAdd'}>
                                <RiAddCircleFill onClick={(e) => { onClickAdd(contact.id, contact.type); e.stopPropagation(); }} />
                            </span>
                            : null}
                        {showDelete === true
                            ? <span className={showDelete ? '' : 'contact-item-icon cursor-pointer'} title={'showDelete'}>
                                <RiDeleteBin7Fill onClick={(e) => { onClickDelete(contact.id, contact.type); e.stopPropagation(); }} />
                            </span>
                            : null}
                    </IconContext.Provider>
                    <IconContext.Provider value={{ className: 'mbs-action-button icon-hover' }}>
                    </IconContext.Provider>
                </div>
                </div>
                {!isExpanded ? null :
                        <animated.div style={{ ...props, overflow: "hidden" }}>
                            <div ref={ref}>
                            <ContactListItemExpanded translations={translations} contact={contact}/>
                            </div>
                </animated.div>
                } 
                </div>
            </div>
        );
    }

