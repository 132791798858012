import React, { FunctionComponent } from "react";

interface StandardContentProps {
    title: React.ReactNode;
    content: React.ReactElement;
    bottomElement?: React.ReactElement;
    contentHeight?: number; // Height in pixels
    totalHeight?: number; // Height in pixels
    enableScroll?: boolean;
}

const StandardContent: FunctionComponent<StandardContentProps> = ({
    title,
    content,
    bottomElement,
    contentHeight,
    totalHeight,
    enableScroll
}) => {
    const contentStyle = {
        height: contentHeight ? `${contentHeight}px` : undefined,
        overflowY: enableScroll ? 'auto' : undefined
    };

    const totalStyle = {
        height: totalHeight ? `${totalHeight}px` : undefined,
    };

    return (
        <div className="standard-content" style={totalStyle}>
            <div className="background" />
            <div className="title">{title}</div>
            <div className="content" style={contentStyle}>
                {content}
            </div>
            {bottomElement && <div className="bottom-shadow">
                {bottomElement}
            </div>}
        </div>
    );
}

export default StandardContent;
