import React from 'react';
import PropTypes from 'prop-types';
import {
  useNavigate,
} from 'react-router-dom';
import MBSListHeader from './MBSListHeader';
import MBSListRow from './MBSListRow';
import { getConvertedDate } from '@murphy-frontend/common/services/TimeService';

function MBSListTable({
  rows, timeZone, translations,
}) {
  const navigate = useNavigate();
  const onClickRow = (id) => {
    navigate(`/pbssendoutbatch/${id}`);
  };

  return (
    <ol className="collection collection-container">
      <MBSListHeader translations={translations} />
      {!rows.length && <div className="horizontal-aligner no-items-text">{translations.noAlertsMessage}</div>}
      {
        rows.map((row) => (
          <MBSListRow
            translations={translations}
            key={row.id}
            id={row.id}
            username={row.username}
            date={getConvertedDate(row.date, timeZone)}
            title={row.title}
            handleClick={() => onClickRow(row.id)}
            status={translations.sent}
          />
        ))
      }
    </ol>
  );
}

MBSListTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    username: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  })),
  timeZone: PropTypes.string.isRequired,
};

MBSListTable.defaultProps = {
  rows: [],
};

export default MBSListTable;
