import * as React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "@murphy-frontend/web-core/components/Button";
import Input from "@murphy-frontend/web-core/components/Input";

const partySchema = yup.object().shape({
  PartyName: yup.string().required(),
  archived: yup.boolean(),
});

export interface PartyFormDto {
  PartyName: string;
  archived: boolean;
}

interface PartyFormProps {
  party?: PartyFormDto;
  onSubmit: (data: PartyFormDto) => void;
  translations?: any;
  onClickArchive: () => void;
  isLoading?: boolean;
}

const PartyForm = ({
  isLoading,
  party,
  onSubmit,
  translations,
  onClickArchive,
}: PartyFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isDirty },
    reset,
    getValues,
  } = useForm<PartyFormDto>({
    resolver: yupResolver(partySchema),
    defaultValues: {
      PartyName: "",
      archived: false,
    },
  });

  useEffect(() => {
    if (party) {
      const defaults = {
        PartyName: party.PartyName,
        archived: party.archived,
      };
      reset(defaults);
    }
  }, [party, reset]);

  const onSubmitHandler = (data: PartyFormDto) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="dashboardform-container">
        <Input
          isControlled
          register={register}
          name="PartyName"
          isRequired
          title={translations["class-lang-modal-group-name"]}
          errorMessage={errors.PartyName?.message}
        />

        <div className="form-buttons">
          {party && (
            <Button
              type="button"
              disabled={isDirty || isLoading}
              onClick={() => onClickArchive()}
              buttonStyle="btn--archive"
              buttonSize="btn--medium"
              isWarningButton={true}
            >
              {translations.archive}
            </Button>
          )}
          {isDirty && (
            <Button
              type="button"
              disabled={!isDirty || isLoading}
              onClick={() => reset()}
              buttonStyle="btn--undo"
              buttonSize="btn--medium"
              isNeutralButton={true}
            >
              {translations.undo}
            </Button>
          )}
          <Button
            isLoading={isLoading}
            disabled={!isDirty || (errors && errors.length > 0)}
            type="submit"
            buttonStyle="btn--primary"
            buttonSize="btn--medium"
          >
            {translations.save}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default PartyForm;
