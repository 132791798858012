import { FunctionComponent } from "react";
import React from "react";

interface TitleWithControlsProps {
    content: React.ReactNode;
}

const TitleWithControls: FunctionComponent<TitleWithControlsProps> = (
    {
        content
    }) => {
    return (
        <div className="l-text">
            {content}
        </div>
    );
}

export default TitleWithControls;