import { FunctionComponent, useEffect } from "react";
import RequirementPyramidGrid from "./components/RequirementPyramidGrid";
import React from "react";
import { translateConstantsWithBackendTranslations } from "../../common/services/translationService";
import { RequirementCategories } from "@murphy-frontend/web-core/constants";
import { useUserPreferences } from "../../common/contexts/UserPreferencesContext";
import Spinner from "@murphy-frontend/web-core/components/Spinner";
import { useGetRequirementCategories } from "./api/queries";

interface CustomerPyramidProps {

}

const CustomerPyramid: FunctionComponent<CustomerPyramidProps> = () => {

    const { translations } = useUserPreferences();

    const { data: requirementCategories, isLoading: requirmentCategoriesIsLoading } = useGetRequirementCategories();
    const translatedRequirementCategories = translateConstantsWithBackendTranslations(RequirementCategories, translations);

    if (!translations || !translatedRequirementCategories || requirmentCategoriesIsLoading) {
        return (<div className="spinner-container-global-center"><Spinner /></div>);
    }

    return (
        <div className="pyramid-grid-wrapper">
            <RequirementPyramidGrid requirementCategories={requirementCategories} translations={translations} />
        </div>
    );
}

export default CustomerPyramid;