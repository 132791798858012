import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';
import { Party, UpsertParty } from '../../api/PartyApi';
import { useUpsertParty } from '../../mutations';
import PartyForm, { PartyFormDto } from './PartyForm';

interface UpsertPartyProps {
    dashboardId: string,
    party?: Party,
    onSuccess: () => void,
    translations: Record<string, string>,
}

const UpsertPartyComponent: FunctionComponent<UpsertPartyProps> = ({ dashboardId, party, onSuccess, translations }) => {

    const { mutate: upsertPartyMutation, isPending } = useUpsertParty(dashboardId);
    const [partyFormData, setPartyFormData] = useState<PartyFormDto>(null);

    const onSubmitHandler = (partyFormDto: PartyFormDto) => {

        const upsertParty: UpsertParty = {
            PartyName: partyFormDto.PartyName,
            PartyId: party?.PartyId,
            PartyInfoId: party?.PartyInfoId,
            PartyGuid: party?.PartyGuid,
            archived: party?.archived
        }

        upsertPartyMutation(upsertParty, {
            onSuccess: () => {
                onSuccess();
            }
        });
    };

    const onClickArchiveParty = () => {

        const upsertParty: UpsertParty = {
            PartyName: party.PartyName,
            PartyId: party.PartyId,
            PartyInfoId: party.PartyInfoId,
            PartyGuid: party.PartyGuid,
            archived: true
        }
        upsertPartyMutation(upsertParty, {
            onSuccess: () => {
                onSuccess();
            }
        });
    }

    useEffect(() => {
        if (party) {
            const partyFormDto: PartyFormDto = {
                PartyName: party.PartyName,
                archived: party.archived,
            }
            setPartyFormData(partyFormDto);
        }
    }, [party])

    return (
        <div>
            <PartyForm
                translations={translations}
                onSubmit={onSubmitHandler}
                onClickArchive={onClickArchiveParty}
                party={partyFormData}
                isLoading={isPending}
            />
        </div>
    );
}

export default UpsertPartyComponent;