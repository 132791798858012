import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import UsersTrainingSessionsApiType, { CreateCustomerUserTrainingSessionRequest, IUserTrainingSessionsApi, UpdateCustomerUserTrainingSessionsRequest } from './IUserTrainingSessionsApi';
import queryKeys from './queryKeys';
import QueryKeys from '@murphy-frontend/common/api/Users/QueryKeys';

export const useCreateUserTrainingSession = (translations: Record<string, string>) => {
    const queryClient = useQueryClient();
    const api = useInjection<IUserTrainingSessionsApi>(UsersTrainingSessionsApiType.IUsersTrainingSessionsApi);

    return useMutation({
        mutationFn: (createUserTrainingSessionRequest: CreateCustomerUserTrainingSessionRequest) => api.createUserTrainingSession(createUserTrainingSessionRequest),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
            queryClient.invalidateQueries({ queryKey: QueryKeys.all });
            toast.success(translations.usertrainingsessioncreated);
        },
    });
};

export const useEditUserTrainingSession = (translations: Record<string, string>) => {
    const queryClient = useQueryClient();
    const api = useInjection<IUserTrainingSessionsApi>(UsersTrainingSessionsApiType.IUsersTrainingSessionsApi);

    return useMutation({
        mutationFn: (editUserTrainingSessionRequest: UpdateCustomerUserTrainingSessionsRequest) => api.updateUserTrainingSession(editUserTrainingSessionRequest),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
            queryClient.invalidateQueries({ queryKey: QueryKeys.all });
            toast.success(translations.usertrainingsessionmodified);
        },
    });
};

export const useDeleteUserTrainingSession = (translations: Record<string, string>) => {
    const queryClient = useQueryClient();
    const api = useInjection<IUserTrainingSessionsApi>(UsersTrainingSessionsApiType.IUsersTrainingSessionsApi);

    return useMutation({
        mutationFn: (id: number) => api.deleteUserTrainingSession(id),
        onError: (err) => {
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error('Error');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.all });
            queryClient.invalidateQueries({ queryKey: QueryKeys.all });
            toast.success(translations.usertrainingsessiondeleted);
        },
    });
};
