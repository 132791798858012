import React, { useState, useEffect, FunctionComponent } from 'react';
import TableFooter from '@murphy-frontend/web-core/components/ColumnGroupTable/TableFooter';
import { CustomerUserTrainingSessionStates } from '@murphy-frontend/common/enums';
import { TrainingSessionModel } from '../../../api/TrainingSessions/TrainingSessionsApi';
import { LanguageType } from '../../../contexts/UserPreferencesContext';
import { TimeZoneType } from "@murphy-frontend/common/services/TimeService"
import CourseCard from '../CourseCard';
import CourseCardFooter from '../CourseCard/components/CourseCardFooter';

export interface CourseCarouselViewModel {
  id: number,
  trainingTypeId: number,
  name: string,
  description: string,
  trainingSessions: TrainingSessionModel[],
  userTrainingSessions: UserTrainingSessionView[],
  backGroundUrl: string,
  language: string,
  onClick: () => void,
}

export interface UserTrainingSessionView {
  userTrainingSessionId: string;
  trainingSessionStateId: number;
  urlToMeeting: string;
  trainingSessionStateName: string;
  trainingSessionStartDate: string;
  trainingSessionEndDate: string;
}

interface CourseCarouselProps {
  translations: Record<string, string>,
  currentScreenSize: string,
  courseViews: CourseCarouselViewModel[],
  timeZone: TimeZoneType,
  language: LanguageType,
}

const CourseCarousel: FunctionComponent<CourseCarouselProps> = ({
  translations, currentScreenSize, courseViews, timeZone, language,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState(1);
  const [selectedTrainingSessionId, setSelectedTrainingSessionId] = useState<
    number | null
  >(null);
  const [allCourseCards, setAllCourseCards] = useState([]);

  const selectedTrainingSessionIdHandler = (trainingSessionId: number) => {
    setSelectedTrainingSessionId(trainingSessionId);
  };

  const mapCourseCards = (cViews: CourseCarouselViewModel[], transl: any) =>
    cViews.map((course) => {
      const bookedTrainingSessions = course.userTrainingSessions?.filter(
        (p) =>
          p.trainingSessionStateId === CustomerUserTrainingSessionStates.Booked
      );
      let bookedTrainingSession = null;
      if (bookedTrainingSessions?.length > 0) {
        bookedTrainingSession = bookedTrainingSessions[0];
      }

      const completedTrainingSessions = course.userTrainingSessions?.filter(
        (p) =>
          p.trainingSessionStateId ===
          CustomerUserTrainingSessionStates.Completed
      );

      const selectedTrainingSession = course.trainingSessions?.filter(
        (session) => session.ID === selectedTrainingSessionId
      );

      return (
        <CourseCard
          key={course.id}
          title={course.name}
          description={course.description}
          bookedTrainingSession={bookedTrainingSession}
          completedTrainingSessions={completedTrainingSessions}
          backGroundUrl={course.backGroundUrl}
          trainingId={course.id}
          translations={translations}
          selectedTrainingSession={selectedTrainingSession}
          footer={
            <CourseCardFooter
              bookedTrainingSession={bookedTrainingSession}
              trainingSessions={course.trainingSessions}
              timeZone={timeZone}
              language={language}
              translations={transl}
              selectedTrainingSessionIdHandler={
                selectedTrainingSessionIdHandler
              }
            />
          }
        />
      );
    });

  const getNumberOfTotalItemsToBeDisplayed = (screenSize: string) => {
    switch (screenSize) {
      case "xs":
        return 4;
      case "sm":
        return 3;
      case "md":
        return 3;
      case "lg":
        return 4;
      case "xl":
        return 6;
      default:
        return 1;
    }
  };

  useEffect(() => {
    const numberOfItems = getNumberOfTotalItemsToBeDisplayed(currentScreenSize);
    setNumberOfItemsPerPage(numberOfItems);
    return () => {
      setNumberOfItemsPerPage(1);
    };
  }, [currentScreenSize]);

  const onChangeNumberOfItemsPerPage = (event: any) => {
    const newValue = parseInt(event.target.value, 10);
    setNumberOfItemsPerPage(newValue);
  };

  const onChangePage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * numberOfItemsPerPage;
  const endIndex = startIndex + numberOfItemsPerPage;
  const rowsToBeDisplayed = allCourseCards.slice(startIndex, endIndex);

  useEffect(() => {
    if (courseViews) {
      const mappedCourseCards = mapCourseCards(courseViews, translations);
      setAllCourseCards(mappedCourseCards);
    }
    return () => {
      setAllCourseCards([]);
    };
  }, [courseViews, translations, selectedTrainingSessionId]);

  return (
    <>
      <div className="course-carousel-container">
        {rowsToBeDisplayed}
      </div>
      {rowsToBeDisplayed.length < allCourseCards.length && (
        <TableFooter
          currentScreenSize={currentScreenSize}
          numberOfItems={courseViews.length}
          currentSelectedPage={currentPage}
          numberOfItemsPerPage={numberOfItemsPerPage}
          onChangeNumberOfItemsPerPage={onChangeNumberOfItemsPerPage}
          onChangePage={onChangePage}
          hidePageInputs
        />
      )}
    </>
  );
}

export default CourseCarousel;
