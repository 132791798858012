import * as React from "react";
import { FunctionComponent } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import { RiMoreFill, RiSortDesc, RiSortAsc } from "react-icons/ri";
import Button from '@murphy-frontend/web-core/components/Button';
import ActionsButton from "@murphy-frontend/web-core/components/ActionsButton";
import UpsertActionsCardComponent from "./UpsertActionsCardComponent";
import { TimeZoneType } from "@murphy-frontend/common/services/TimeService"

interface ActionsCardsTableHeaderProps {
    translations: Record<string, string>,
    timeZone: TimeZoneType,
    dashboardId: string,
    openModal: any,
    closeModal: any,
    toggleSearch: () => void,
    sortActionCardsByCreated: (direction: 'asc' | 'desc') => void
}

const ActionsCardsTableHeader: FunctionComponent<ActionsCardsTableHeaderProps> =
    ({ timeZone, translations, dashboardId, openModal, closeModal, toggleSearch, sortActionCardsByCreated }) => {

        const onClickCreateActionCard = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            event.preventDefault();
            const upsertActionCardComponent =
                <UpsertActionsCardComponent timeZone={timeZone} translations={translations} dashboardId={dashboardId} onSuccess={() => closeModal()} />;
            const configuration = {
                centerContent: false,
                hideButton: true,
                body: upsertActionCardComponent,
                title: translations['lang-action-card'],
            }
            openModal(configuration);
        }

        const moreButtonIcon = <IconContext.Provider value={{ className: 'dashboard-table-actions-button-icon' }}>
            <RiMoreFill />
        </IconContext.Provider>;

        const searchIcon = <IconContext.Provider value={{ className: 'dashboard-table-action-status-icon' }}>
            <AiOutlineSearch />
        </IconContext.Provider>;

        const sortDescIcon = <IconContext.Provider value={{ className: 'dashboard-table-action-status-icon' }}>
            <RiSortDesc />
        </IconContext.Provider>;
        const sortAscIcon = <IconContext.Provider value={{ className: 'dashboard-table-action-status-icon' }}>
            <RiSortAsc />
        </IconContext.Provider>;

        const actionButtonItems = [];
        const searchButton = <li key="search" onClick={(_) => { toggleSearch() }}>
                <div className="vertical-aligner">
                    {searchIcon}
                    &nbsp;&nbsp;
                    <span>{translations['placeholder-lang-search-action']}</span>
                </div>
            </li>;
        const sortByCreatedDesc = <li key="sort-by-created-desc" onClick={(_) => { sortActionCardsByCreated('desc') }}>
                <div className="vertical-aligner">
                    {sortDescIcon}
                    &nbsp;&nbsp;
                    <span>{translations['placeholder-lang-sort-created-desc-action']}</span>
                </div>
            </li>;
        const sortByCreatedAsc = <li key="sort-by-created-asc" onClick={(_) => { sortActionCardsByCreated('asc') }}>
                <div className="vertical-aligner">
                    {sortAscIcon}
                    &nbsp;&nbsp;
                    <span>{translations['placeholder-lang-sort-created-asc-action']}</span>
                </div>
            </li>;
        actionButtonItems.push(searchButton, sortByCreatedDesc, sortByCreatedAsc);

        const actionsButton = <ActionsButton actionButtonItems={actionButtonItems} icon={moreButtonIcon} pullLeft={true} />

        return <div className='dashboard-table-flex-header'>
            <div className="left"><div className='dashboard-table-title'>
                <h2>{translations['lang-rubrik-action']}</h2>

            </div>
            </div>
            <div className="right">
                <div className='controls'>
                    {actionsButton}
                    <Button buttonStyle={"btn--add"} isIconButton onClick={onClickCreateActionCard} />
                </div>
            </div>
        </div>
    }

export default ActionsCardsTableHeader;