import { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { DashboardCardFilter } from '../models';

export interface Goals {
    ID: number,
    Title: string,
    message?: string,
    goalType: number,
    CreatedDateTime: Date,
    hl: number,
    archived: boolean,
    user: string,
    versionTo?: Date,
}

export interface UpsertGoals {
    Title: string,
    message?: string,
    goalType: number,
}

export class GoalsApi {
    parentId: string;
    baseUrl: string;
    apiService: IApiService;

    constructor(apiService: IApiService, dashboardId: string) {
        this.parentId = dashboardId
        this.baseUrl = `api/Dashboard/${dashboardId}/Goals`;
        this.apiService = apiService;
    }

    getById = (id: string) => {
        const url = `${this.baseUrl}/${id}`;
        return this.apiService.callApi(url, 'GET', null);
    }

    getAll = (filter?: DashboardCardFilter) => {
        let searchParamsString = '';
        if (filter) {
            const searchParams = new URLSearchParams();

            if (filter.fromDate) {
                searchParams.append('fromDate', filter.fromDate.toUTCString());
            }
            searchParamsString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
        }
        const url = `${this.baseUrl}${searchParamsString}`;
        return this.apiService.callApi(url, 'GET', null);
    }

    upsertGoals = (upsertGoals: UpsertGoals) => {
        const url = `${this.baseUrl}`;
        return this.apiService.callApi(url, 'POST', upsertGoals);
    }
};
