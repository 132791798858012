import * as React from 'react';
import Button from '@murphy-frontend/web-core/components/Button';
import CardFooter from "../MainLog/CardFooter";
import { FunctionComponent } from "react";
import { Assumption } from '../../api/AssumptionApi';
import UpsertAssumptionComponent from './UpsertAssumptionComponent';
import AssumptionFormatted from './AssumptionFormatted';
interface AssumptionExpandedContentProps {
    assumption: Assumption,
    dashboardId: string,
    openModal: any,
    closeModal: any,
    translations: Record<string, string>,
}

const AssumptionExpandedContent: FunctionComponent<AssumptionExpandedContentProps> = ({ assumption, dashboardId, openModal, closeModal, translations }) => {

    const onClickEdit = () => {
        const upsertAssumptionComponent =
            <UpsertAssumptionComponent
                translations={translations}
                dashboardId={dashboardId}
                assumption={assumption}
                onSuccess={() => closeModal()} />;

        const configuration = {
            centerContent: false,
            hideButton: true,
            body: upsertAssumptionComponent,
            title: translations['lang-action-card'],
        }
        openModal(configuration);
    }

    let footer;
    if (assumption.archived !== true) {
        const button =
            <Button
                onClick={() => onClickEdit()}>
                {translations['class-lang-button-edit']}
            </Button>
        footer = <CardFooter rightContent={button} />
    }

    return <AssumptionFormatted assumption={assumption} translations={translations} cardFooter={footer} />
}

export default AssumptionExpandedContent;