import React, {
  useState, useEffect, FunctionComponent,
} from 'react';
import { IconContext } from 'react-icons/lib';
import {
  RiCheckFill,
} from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import dayjs from "dayjs";
import { useDimensions } from '../../../hooks/useDimensions';
import logo from '../../../../assets/img/Praeparatus_Supervivet_Practise_Risk.jpg';

interface CourseCardProps {
  title: string;
  description: string;
  footer: any;
  bookedTrainingSession: any;
  completedTrainingSessions: any;
  backGroundUrl: any;
  trainingId: any;
  translations: Record<string, string>;
  selectedTrainingSession: any;
}

const CourseCard: FunctionComponent<CourseCardProps> = ({
  title,
  description,
  footer,
  bookedTrainingSession,
  completedTrainingSessions,
  backGroundUrl,
  trainingId,
  translations,
  selectedTrainingSession,
}) => {
  const navigate = useNavigate();
  const [snipDescription, setSnipDescription] = useState(false);
  const [coursecardTextClass, setCoursecardTextClass] =
    useState("course-card-text");

  const [isExpanded, setIsExpanded] = useState(false);

  const [ref, { height }] = useDimensions();

  const onClickImage = () => {
    const url = `/training/${trainingId}`;
    navigate(url);
  };

  useEffect(() => {
    if (isExpanded) {
      setCoursecardTextClass("course-card-text-expanded");
    } else if (height) {
      if (height > 120) {
        setSnipDescription(true);
        setCoursecardTextClass("course-card-text course-card-text-more");
      } else {
        setSnipDescription(false);
        setCoursecardTextClass("course-card-text");
      }
    } else {
      setSnipDescription(false);
      setCoursecardTextClass("course-card-text");
    }
  }, [height, isExpanded]);

  const onClickExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const formatString = (str: string) => {
    const lines = str.split(/\n/);
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="course-card-container">
      <div className="course-card-header">
        <div
          onClick={onClickImage}
          className="course-card-background-image"
          style={{
            backgroundImage: `url(${backGroundUrl ?? logo})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          {bookedTrainingSession && (
            <div className="course-card-state-icon-container">
              <IconContext.Provider
                value={{ className: "course-card-state-icon" }}
              >
                <RiCheckFill />
              </IconContext.Provider>
            </div>
          )}
          {bookedTrainingSession && (
            <>
              <div className="course-card-state-date">
                <b>
                  {dayjs(
                    bookedTrainingSession?.trainingSessionStartDate
                  ).format("ddd DD MMM")}
                </b>{" "}
                {dayjs(bookedTrainingSession?.trainingSessionStartDate).format(
                  "HH:mm"
                )}
                {"-"}
                {dayjs(bookedTrainingSession?.trainingSessionEndDate).format(
                  "HH:mm"
                )}
              </div>
            </>
          )}
          <div className="course-card-completed-sessions">
            {completedTrainingSessions &&
              completedTrainingSessions.length > 0 && (
                <div className="course-card-completed-icon">
                  {completedTrainingSessions[0]?.trainingSessionStateName}
                </div>
              )}
            {bookedTrainingSession && (
              <div className="course-card-booked-icon">
                {bookedTrainingSession?.trainingSessionStateName}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="course-card-body">
        <h2>{title}</h2>
        <div className={coursecardTextClass}>
          {Number.isInteger(selectedTrainingSession[0]?.AttendeeSpotLeft) && (
            <div className="course-spots-left-container">
              <h6>{translations.spotsleft}</h6>
              <h6
                className={
                  selectedTrainingSession[0]?.AttendeeSpotLeft < 4
                    ? `course-spots-left_few`
                    : `course-spots-left`
                }
              >
                {` ${selectedTrainingSession[0]?.AttendeeSpotLeft}`}
              </h6>
            </div>
          )}
          <p ref={ref}>{formatString(description)}</p>
        </div>
        {snipDescription === true && (
          <div className="course-card-more">
            {!isExpanded && (
              <p onClick={onClickExpand}>{translations.showmore} &gt;</p>
            )}
          </div>
        )}
      </div>
      <div className="course-card-footer">{footer}</div>
    </div>
  );
};

export default CourseCard;
