const QueryKeys = {
  all: ['customers'],
  lists: () => [...QueryKeys.all, 'list'],
  list: (filters: any) => [...QueryKeys.lists(), { filters }],
  details: () => [...QueryKeys.all, 'detail'],
  children: (customerId: string) => [...QueryKeys.all, 'children', customerId],
  detail: (id: string) => [...QueryKeys.details(), id],
};

export default QueryKeys;
