import { View, Text, } from "@react-pdf/renderer"
import * as React from "react"
import { convertBreaksToNewLine } from "@murphy-frontend/common/utils"
import { BackGround } from "../../../api/BackGroundApi"

export const BackGroundPdfRow = (translations: Record<string, string>, styles: any, backGround: BackGround) => {
    return <View>
        <Text>
            <Text style={styles.label}>
                {translations['class-what-has-happened']}:&nbsp;
            </Text>
            <Text>
                {backGround.WhatMessage ? convertBreaksToNewLine(backGround.WhatMessage) : ''}
            </Text>
        </Text>
        <Text>
            <Text style={styles.label}>
                {translations['class-when-did-it-happen']}:&nbsp;
            </Text>
            <Text>
                {backGround.WhenMessage ? convertBreaksToNewLine(backGround.WhenMessage) : ''}
            </Text>
        </Text>
        <Text>
            <Text style={styles.label}>
                {translations['class-how-did-it-happen']}:&nbsp;
            </Text>
            <Text>
                {backGround.HowMessage ? convertBreaksToNewLine(backGround.HowMessage) : ''}
            </Text>
        </Text>
        <Text>
            <Text style={styles.label}>
                {translations['class-affected']}:&nbsp;
            </Text>
            <Text>
                {backGround.AffectedMessage ? convertBreaksToNewLine(backGround.AffectedMessage) : ''}
            </Text>
        </Text>
        <Text>
            <Text style={styles.label}>
                {translations['class-extent']}:&nbsp;
            </Text>
            <Text>
                {backGround.ExtentMessage ? convertBreaksToNewLine(backGround.ExtentMessage) : ''}
            </Text>
        </Text>
    </View>
}

