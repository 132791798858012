import { Step, Steps } from "intro.js-react";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { OnboardingFlowIds, useOnboarding } from "../../../common/contexts/OnboardingContext";
import React from "react";

interface MBSCreateNewOnboardingStepsProps {
    stepsEnabled: boolean;
    onComplete: () => void;
    setStepsEnabled: (isEnabled: boolean) => void;
    messageTemplateIsSelected: boolean;
    setMessageTemplateIsFlashing: (isFlashing: boolean) => void;
}

const MBSCreateNewOnboardingSteps: FunctionComponent<MBSCreateNewOnboardingStepsProps> = (
    {
        stepsEnabled,
        onComplete,
        setStepsEnabled,
        messageTemplateIsSelected,
        setMessageTemplateIsFlashing
    }
) => {

    const stepsRef = useRef(null);
    const [steps, setSteps] = useState<Step[]>([]);
    const [initialStep, setInitialStep] = useState<number>();
    const { incompletedOnboardingFlows, setCurrentStep, onboardingsCurrentStep } = useOnboarding();

    useEffect(() => {
        if (stepsEnabled && onboardingsCurrentStep && steps.length === 0 // Only initialize steps if they haven't been initialized yet.
        ) {
            console.log('Initializing onboarding steps');
            const stepsFromApi = incompletedOnboardingFlows.find(p => p.OnboardingFlowId === OnboardingFlowIds.Alert)?.StepsHtmlContents;
            if (stepsFromApi === undefined)
                return;

            const newSteps = createInitialSteps(stepsFromApi);
            setSteps(newSteps);
            const firstStep = onboardingsCurrentStep.get(OnboardingFlowIds.Alert);
            setInitialStep(firstStep);
        }
    }, [onboardingsCurrentStep, incompletedOnboardingFlows, stepsEnabled]);

    useEffect(() => {
        if (stepsEnabled && steps.length > 0) {
            const firstStep = onboardingsCurrentStep.get(OnboardingFlowIds.Alert);
            setInitialStep(firstStep);
        }
    }, [stepsEnabled, onboardingsCurrentStep, steps]);

    const onDummyExit = () => {
        console.log('Dummy exit');
    }

    useEffect(() => {
        if (stepsEnabled && initialStep !== undefined) {
            if (stepsRef) {
                if (initialStep > 1) { // if we don't do this, then there will be 2 instances of introjs running and it wont work properly
                    stepsRef.current?.introJs.exit(onDummyExit).then(() => {
                        stepsRef.current?.introJs?.goToStepNumber(initialStep);
                        stepsRef.current?.updateStepElement(initialStep);
                        stepsRef.current?.introJs?.refresh();
                        stepsRef.current?.introJs.start();
                    });
                }
            }
        }
    }, [stepsEnabled, stepsRef, initialStep]);

    useEffect(() => {
        if (stepsEnabled && messageTemplateIsSelected == true) {
            const currentStep = stepsRef?.current?.introJs?._currentStep;
            if (currentStep === 1) {
                const timer = setTimeout(() => {
                    stepsRef.current.introJs?.goToStepNumber(2);
                    stepsRef.current.updateStepElement(2);
                    stepsRef.current.introJs.start();
                }, 500);

                return () => clearTimeout(timer);
            }
        }
    }, [stepsEnabled, messageTemplateIsSelected]);

    const createInitialSteps = (stepsFromApi: Map<number, string>): Step[] => {
        const steps = Array<Step>();
        // replace all <p></p> with <br> in the steps
        stepsFromApi?.forEach((value, key) => {
            stepsFromApi?.set(key, value.replace(/<p><\/p>/g, '<br>'));
        });

        steps.push({
            element: '.one',
            intro: stepsFromApi?.get(2),
            position: 'bottom'
        });
        steps.push({
            element: '.template-selector-wrapper',
            intro: stepsFromApi?.get(2),
            position: 'bottom'
        });
        steps.push({
            element: '.message-editor-container',
            intro: stepsFromApi?.get(3),
            position: 'bottom'
        });
        steps.push({
            element: '.recipient-list-item',
            intro: stepsFromApi?.get(4),
            position: 'bottom'
        });
        steps.push({
            element: '.contacts-container',
            intro: stepsFromApi?.get(5),
            position: 'bottom'
        });
        steps.push({
            element: '.btn--large',
            intro: stepsFromApi?.get(6),
            position: 'bottom'
        });

        // set tooltipClass to 'tooltip-class' on all steps
        steps.forEach(step => {
            step.tooltipClass = 'tooltip-class';
        });

        return steps;
    };

    const onStart = (step: number) => {
        setMessageTemplateIsFlashing(false);
    }

    const onBeforeStep = (nextStepIndex: number, nextElement: Element): false | void | Promise<false | void> => {
        setMessageTemplateIsFlashing(false);

        if (stepsRef.current) {
            stepsRef.current.updateStepElement(nextStepIndex);
        }

        if (stepsEnabled && nextStepIndex === 2 && !messageTemplateIsSelected) {
            setMessageTemplateIsFlashing(true);
            return false;
        }

        if (stepsRef.current) {
            stepsRef.current.updateStepElement(nextStepIndex);
        }
    }

    const onChangeStep = (nextStepIndex: number, nextElement: Element) => {
        if (nextStepIndex === 3) {
            // return false;
        }
    }

    const onAfterStep = (nextStepIndex: number, nextElement: Element) => {

        if (nextStepIndex === 0) {
            setCurrentStep(OnboardingFlowIds.Alert, nextStepIndex);
            setStepsEnabled(false);
            window.location.reload();
            return false;
        }

        setCurrentStep(OnboardingFlowIds.Alert, nextStepIndex);
    }

    const onExit = (stepIndex: number) => {
        console.log('Onboarding is exited from step ' + stepIndex);
        setMessageTemplateIsFlashing(false);

        // sometimes introjs calls onExit by itself, but with -1 as stepIndex. When the user does it, it will be with a positive number.
        if (stepsRef?.current != null && stepIndex > -1) {
            if (stepsRef?.current.introJs._lastShowElementTimer) {
                onComplete();
            }
        }
    };

    return (stepsEnabled && <Steps
        ref={stepsRef}
        enabled={stepsEnabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
        onComplete={onComplete}
        onAfterChange={onAfterStep}
        onChange={onChangeStep}
        onBeforeChange={onBeforeStep}
        onStart={onStart}
        options={{
            hideNext: false,
            hidePrev: true,
            exitOnOverlayClick: false,
            showBullets: false,
            doneLabel: 'Finish'
        }}
    />);
}

export default MBSCreateNewOnboardingSteps;