import * as React from "react";
import { DashboardEntityTypes } from "@murphy-frontend/common/enums";
import { getConvertedDateDayjs } from '@murphy-frontend/common/services/TimeService';
import { PartyPeople } from "../../../api/PartyPeopleApi";
import PartyPeopleExpandedContent from "../../PartyPeople/PartyPeopleExpandedContent";
import { MainLogCard } from "../models";
import { BaseCardProps } from "./MainLogCardFactory";

export interface PartyPeopleMainLogCardProps extends BaseCardProps {
    partyPeople: PartyPeople,
}

export const PartyPeopleMainLogCard =
    ({ translations, partyPeople, timeZone, dashboardId, openModal, closeModal }: PartyPeopleMainLogCardProps): MainLogCard => {
        const date = getConvertedDateDayjs(partyPeople.CreatedDateTimeUtc, timeZone);
        const expandedContent =
            <PartyPeopleExpandedContent
                partyPeople={partyPeople}
                dashboardId={dashboardId}
                openModal={openModal}
                closeModal={closeModal}
                translations={translations}
            />

        let title = translations['class-lang-title-infocard-actor-updated'];
        if (partyPeople.action === "Created") {
            title = translations['class-lang-title-infocard-actor-created'];
        }

        const searchableContent = [];
        searchableContent.push(title);
        if (partyPeople.Email)
            searchableContent.push(partyPeople.Email);
        if (partyPeople.Name)
            searchableContent.push(partyPeople.Name);
        if (partyPeople.Title)
            searchableContent.push(partyPeople.Title);
        if (partyPeople.PhoneNr)
            searchableContent.push(partyPeople.PhoneNr);
        if (partyPeople.createdby)
            searchableContent.push(partyPeople.createdby);

        const logCard: MainLogCard = {
            ID: `${partyPeople.ID}-${DashboardEntityTypes.PartyPeople}`,
            Title: title,
            isArchived: partyPeople.archived,
            isDecision: false,
            SearchableContent: searchableContent,
            User: partyPeople.createdby,
            Date: date,
            DateWithoutTime: date.format("YYYY-MM-DD"),
            Time: date.format("HH:mm"),
            EntityType: DashboardEntityTypes.PartyPeople,
            ExpandedContent: expandedContent,
            OriginalCard: partyPeople,
        }
        return logCard;
    }