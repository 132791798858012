import { inject, injectable } from "inversify";
import FeatureServiceType, {
    IFeatureService,
} from '@murphy-frontend/common/interfaces/IFeatureService';
import PersistenceType, {
    IPersistenceService,
} from '@murphy-frontend/common/interfaces/IPersistenceService';
import { Providers } from "../enums";
import { auth, enabledFeatures } from "../variables";

@injectable()
export default class WebFeatureService implements IFeatureService {

    constructor(
        @inject(PersistenceType.IPersistenceService)
        private persistenceService: IPersistenceService,
    ) { }


    providerAllowsUpdate() {
        return true;
        const provider = this.persistenceService.getProvider();
        let providerAllowsUpdate = false;
        if (provider) {
            providerAllowsUpdate = false;
        }
        if (provider === Providers.ADB2C) {
            providerAllowsUpdate = true;
        }

        return providerAllowsUpdate;
    }

    isUserNameValidationEnabled = () => {
        const providerAllowsUpdate = this.providerAllowsUpdate();
        if (providerAllowsUpdate === false ||
            enabledFeatures.user.disableUsernameValidation === 'true')
            return false;

        return true;
    }

    isUpsertUserEnabled = () => {
        return enabledFeatures.upsertUser === 'true';
    }

    isUserNameDisabled = () => {
        const providerAllowsUpdate = this.providerAllowsUpdate();

        return enabledFeatures.upsertUser !== 'true' ||
            enabledFeatures.user.disabledFields.username === 'true' ||
            providerAllowsUpdate === false;
    };

    isEmailDisabled = () => {
        const providerAllowsUpdate = this.providerAllowsUpdate();

        return enabledFeatures.upsertUser !== 'true' ||
            enabledFeatures.user.disabledFields.email === 'true' ||
            providerAllowsUpdate === false;
    };

    isPhoneNumberDisabled = () => {
        return enabledFeatures.upsertUser !== 'true' ||
            enabledFeatures.user.disabledFields.phonenumber === 'true';
    };

    isResetPasswordEnabled = () => {
        const providerAllowsUpdate = this.providerAllowsUpdate();
        return providerAllowsUpdate && auth.showResetPassword === 'true';
    }

    isResetMfaEnabled = () => {
        const providerAllowsUpdate = this.providerAllowsUpdate();
        return providerAllowsUpdate && auth.showResetPassword === 'true';
    }

    createUserIsEnabled = () => {
        if (enabledFeatures.addUserFromExistingAccountMode === 'true')
            return true;

        const providerAllowsUpdate = this.providerAllowsUpdate();
        return providerAllowsUpdate && enabledFeatures.upsertUser === 'true';
    }
}