import * as React from "react";
import { DashboardEntityTypes, LogTypes } from "@murphy-frontend/common/enums";
import { getConvertedDateDayjs } from '@murphy-frontend/common/services/TimeService';
import { DashboardLog } from "../../../api/DashboardLogApi";
import DashboardLogFormatted from "../../DashboardLog/DashboardLogFormatted";
import { MainLogCard } from "../models";
import { BaseCardProps } from "./MainLogCardFactory";

export interface DashboardLogMainLogCardProps extends BaseCardProps {
    dashboardLog: DashboardLog,
}

export const DashboardLogMainLogCard =
    ({ translations, dashboardLog, timeZone, dashboardId, openModal, closeModal }: DashboardLogMainLogCardProps): MainLogCard => {
        const date = getConvertedDateDayjs(dashboardLog.CreatedDateTimeUtc, timeZone);
        const expandedContent = <DashboardLogFormatted dashboardLog={dashboardLog} translations={translations} />

        let title = "LOG";
        switch (dashboardLog.LogType) {
            case LogTypes.ExcelCreated:
                title = translations['class-lang-title-titelexcelfilecreated'];
                break;
            case LogTypes.PdfCreated:
                title = translations['class-lang-title-pdffilecreated'];
                break;
        }

        const searchableContent = [];
        searchableContent.push(title);
        if (dashboardLog.user)
            searchableContent.push(dashboardLog.user);

        const logCard: MainLogCard = {
            ID: `${dashboardLog.ID}-${DashboardEntityTypes.DashboardLog}`,
            Title: title,
            isArchived: false,
            isDecision: false,
            SearchableContent: searchableContent,
            User: dashboardLog.user,
            Date: date,
            DateWithoutTime: date.format("YYYY-MM-DD"),
            Time: date.format("HH:mm"),
            EntityType: DashboardEntityTypes.DashboardLog,
            ExpandedContent: expandedContent,
            OriginalCard: dashboardLog,
        }
        return logCard;
    }