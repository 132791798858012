import React, { FunctionComponent, ReactNode, useState } from 'react';
import { IconContext } from 'react-icons';
import Button from '@murphy-frontend/web-core/components/Button';

interface PortalModuleProps {
  title: string,
  subtitle?: string,
  footer: string,
  customClasses?: string,
  onClick: () => void,
  buttonIcon: ReactNode,
}

const PortalModule: FunctionComponent<PortalModuleProps> = ({
  title, subtitle, footer, customClasses, onClick, buttonIcon,
}) => {
  let containerClass = 'module-container';
  if (customClasses) {
    containerClass += ` ${customClasses}`;
  }

  return (
    <div onClick={onClick} className={containerClass}>
      <div className="module-container-header">
        <h2>{title}</h2>
        <h3>{subtitle}</h3>
      </div>
      <div className="module-container-body">
        {buttonIcon}
      </div>
      <div className="module-container-footer">
        <h2>{footer}</h2>
      </div>
    </div>
  );
}

export default PortalModule;