/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

function MBSListRow({
  username, date, id, status, title, handleClick, translations,
}) {
  return (
    <li id={id} key={id} className="item item-container item-container-configuration-pbslist" onClick={handleClick} onKeyUp={handleClick}>
      <div className="attribute" data-name={translations.datename} title={date}>{date}</div>
      <div className="attribute" data-name={translations.sender} title={username}>{username}</div>
      <div className="attribute" data-name={translations.title} title={title}>{title}</div>
      <div className="attribute" data-name={translations.statusname} title={translations.statusname}>{status}</div>
    </li>
  );
}

MBSListRow.propTypes = {
  username: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
};

MBSListRow.defaultProps = {
  title: '',
};

export default MBSListRow;
