import { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { DashboardCardFilter } from '../models';

export interface BackGround {
    ID: number,
    WhatMessage?: string,
    WhenMessage?: string,
    HowMessage?: string,
    AffectedMessage?: string,
    ExtentMessage?: string,
    CreatedDateTime: Date,
    archived: boolean,
    user: string,
    versionTo?: Date,
}

export interface UpsertBackGround {
    WhatMessage?: string,
    WhenMessage?: string,
    HowMessage?: string,
    AffectedMessage?: string,
    ExtentMessage?: string,
}

export class BackGroundApi {
    parentId: string;
    baseUrl: string;
    apiService: IApiService;

    constructor(
        apiService: IApiService,
        dashboardId: string) {
        this.parentId = dashboardId
        this.baseUrl = `api/Dashboard/${dashboardId}/BackGround`;
        this.apiService = apiService;
    }

    getById = (id: string) => {
        const url = `${this.baseUrl}/${id}`;
        return this.apiService.callApi(url, 'GET', null);
    }

    getAll = (filter?: DashboardCardFilter) => {
        let searchParamsString = '';
        if (filter) {
            const searchParams = new URLSearchParams();

            if (filter.fromDate) {
                searchParams.append('fromDate', filter.fromDate.toUTCString());
            }
            searchParamsString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
        }
        const url = `${this.baseUrl}${searchParamsString}`;
        return this.apiService.callApi(url, 'GET', null);
    }

    upsertBackGround = (upsertGoals: UpsertBackGround) => {
        const url = `${this.baseUrl}`;
        return this.apiService.callApi(url, 'POST', upsertGoals);
    }
};
