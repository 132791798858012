import { useQuery } from '@tanstack/react-query';
import { useInjection } from '../../contexts/InversifyContext';
import { CustomerFileApi } from './CustomerFilesApi';
import ApiServiceType, { IApiService } from "../../interfaces/IApiService";
import queryKeys from './queryKeys';

export function useGetCustomerFiles(customerId: string) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const customerFileApi = new CustomerFileApi(apiService);

    return useQuery({
        queryKey: queryKeys.customerFiles(customerId),
        queryFn: () => customerFileApi.getCustomerFiles(customerId)
    });
}

export function useGetHistoricFiles(customerId: string, fileTypeId: number) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const customerFileApi = new CustomerFileApi(apiService);

    return useQuery({
        queryKey: queryKeys.historicFiles(customerId, fileTypeId),
        queryFn: () => customerFileApi.getHistoricFiles(customerId, fileTypeId)
    });
}
