import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';
import Button from '@murphy-frontend/web-core/components/Button';
import { CustomCheckbox } from '../../../../../common/components/MurphyCheckbox';
import { LogTypes, MainLogReportTypes } from '@murphy-frontend/common/enums';
import { getUtcOffset } from '@murphy-frontend/common/services/TimeService'
import { InsertDashboardLog } from '../../../api/DashboardLogApi';
import { useInsertDashboardLog } from '../../../mutations';
import { downloadFromLink } from '@murphy-frontend/common/utils';
import { MainLogCard } from '../models';
import ExportPdfComponent from './ExportPdfComponent';
import { StatusCardStatusService } from '../../StatusCards/StatusCardStatusService';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';

interface ExportLogProps {
    dashboardId: string,
    dashboardName: string,
    translations: Record<string, string>,
    timeZone: string,
    language: string,
    currentUserId: string
    onSuccess: () => void,
    logCards: MainLogCard[],
    statusCardStatusService: StatusCardStatusService
}

interface ReportType {
    name: string,
    type: number,
    checked: boolean,
    requested: boolean,
    downloaded: boolean,
}

const initialReportTypes = [
    {
        name: 'Excel',
        type: MainLogReportTypes.Excel,
        checked: false,
        requested: false,
        downloaded: false,
    },
    {
        name: 'PDF',
        type: MainLogReportTypes.PDF,
        checked: false,
        requested: false,
        downloaded: false,
    }
] as ReportType[];

const ExportLog: FunctionComponent<ExportLogProps> = ({ logCards, dashboardId, dashboardName, currentUserId, translations, timeZone, language, onSuccess, statusCardStatusService }) => {
    const [reportTypes, setReportTypes] = useState<ReportType[]>([]);
    const [pdfComponent, setPdfComponent] = useState<any>(null)
    const [pdfBlob, setPdfBlob] = useState(null);
    const { mutate: insertLogMutation } = useInsertDashboardLog(dashboardId);
    const [excelReport, setExcelReport] = useState(null);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);

    useEffect(() => {
        setReportTypes(initialReportTypes);
    }, [])

    useEffect(() => {
        if (reportTypes.find(p => p.type === MainLogReportTypes.PDF && p.requested === true && p.downloaded === false)) {
            const exportPdfComponent = <ExportPdfComponent dashboardName={dashboardName} logCards={logCards} translations={translations}
                getBlob={function (blob: Blob): void {
                    if (blob) {
                        setPdfBlob(blob);
                    }
                }}
                statusCardStatusService={statusCardStatusService}
            />;
            setPdfComponent(exportPdfComponent);
        } else {
            setPdfComponent(null);
        }
        return () => {
            setPdfComponent(null);
        }
    }, [reportTypes])

    useEffect(() => {
        if (pdfBlob) {
            if (reportTypes.find(p => p.type === MainLogReportTypes.PDF && p.requested === true && p.downloaded === false)) {
                const date = new Date().toISOString().slice(0, 10);
                const baseFileName = `${dashboardName}_${date}`;
                const fileName = `${baseFileName}.pdf`;
                downloadFromLink(fileName, pdfBlob);

                // GENERATE PDF - IF SUCCESSFUL THEN LOG (SEE BELOW)
                const insertDashboardLog: InsertDashboardLog = {
                    LogType: LogTypes.PdfCreated,
                    userid: currentUserId,
                }
                insertLogMutation(insertDashboardLog);
                onDownloadReportType(MainLogReportTypes.PDF);
            }
        }
    }, [pdfBlob])

    useEffect(() => {
        if (reportTypes.find(p => p.type === MainLogReportTypes.Excel && p.requested === true && p.downloaded === false)
            && excelReport) {
            const date = new Date().toISOString().slice(0, 10);
            const baseFileName = `${dashboardName}_${date}`;
            const fileName = `${baseFileName}.xlsx`;

            downloadFromLink(fileName, excelReport);

            // GENERATE PDF - IF SUCCESSFUL THEN LOG (SEE BELOW)
            const insertDashboardLog: InsertDashboardLog = {
                LogType: LogTypes.ExcelCreated,
                userid: currentUserId,
            }
            insertLogMutation(insertDashboardLog);
            onDownloadReportType(MainLogReportTypes.Excel);
        }
    }, [excelReport, reportTypes])

    const onCheckReportType = (reportType: number) => {
        const newCheckedReportTypes = reportTypes.map(p =>
            p.type === reportType
                ? { ...p, checked: !p.checked }
                : p
        );
        setReportTypes(newCheckedReportTypes);
    }

    const onDownloadReportType = (reportType: number) => {
        const newCheckedReportTypes = reportTypes.map(p =>
            p.type === reportType
                ? { ...p, downloaded: true }
                : p
        );
        if (newCheckedReportTypes
            .filter(p => p.requested === true && p.downloaded === false).length === 0) {
            setIsLoading(false);
            onSuccess();
        }
        setReportTypes(newCheckedReportTypes);
    }

    const onClickExportReports = () => {
        setError('');
        setIsLoading(true);
        const currentCheckedReportTypes = reportTypes.filter(p => p.checked === true);
        let newRequestedReports = [...reportTypes];

        if (currentCheckedReportTypes.find(p => p.type === MainLogReportTypes.Excel)) {
            newRequestedReports = newRequestedReports.map(p =>
                p.type === MainLogReportTypes.Excel
                    ? { ...p, requested: true }
                    : p
            );
            const utcOffset = getUtcOffset(timeZone);
            apiService.downloadExcel(dashboardId, language, utcOffset).then((response) => {
                setExcelReport(response.data);
            }).catch((error) => {
                setError("Error exporting excel report");
                setIsLoading(false);
            });
        }
        if (currentCheckedReportTypes.find(p => p.type === MainLogReportTypes.PDF)) {
            newRequestedReports = newRequestedReports.map(p =>
                p.type === MainLogReportTypes.PDF
                    ? { ...p, requested: true }
                    : p
            );
        }
        setReportTypes(newRequestedReports);
    }

    return (<>
        <div className="export-main-log-control">
            <div className="">
                {reportTypes.map((reportType) => {
                    return <div key={reportType.type} className='form-input flex-input-container'>
                        <CustomCheckbox
                            onChange={() => onCheckReportType(reportType.type)}
                            checked={reportType.checked}
                        />
                        <label>
                            {reportType.name}
                        </label>
                    </div>
                })}
            </div>
            {pdfComponent}
            {error && <div className="input-error-message">{error}</div>}
            <div>
                <div className='form-buttons save-button'>
                    <Button isLoading={isLoading} onClick={onClickExportReports} disabled={reportTypes.filter(p => p.checked).length === 0}
                        buttonStyle="btn--primary" buttonSize="btn--medium">{translations['lang-export']}</Button>
                </div>
            </div>
        </div>
    </>);
}

export default ExportLog;