import * as React from 'react';
import { FunctionComponent } from 'react';
import { Party } from '../../api/PartyApi';
interface PartyFormattedProps {
    party: Party;
    translations: Record<string, string>
    cardFooter: any
}

const PartyFormatted: FunctionComponent<PartyFormattedProps> = ({ party, translations, cardFooter }) => {
    return (
        <div className="formatted-card">
            <p>
                <strong>{translations['class-lang-by-user']}</strong>&nbsp;{party.createdby}
            </p>
            {party.PartyName &&
                <>
                    <br />
                    {party.PartyName}
                    <br />
                </>}
            {cardFooter &&
                <>
                    <br />
                    <hr></hr>
                    <br />
                    {cardFooter}
                </>
            }
        </div >
    );
}

export default PartyFormatted;