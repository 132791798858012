import React, { FunctionComponent } from 'react';
import { SendOutBatchTemplate } from '../models';
import MBSTemplate from './MBSTemplate';

interface MBSTemplateSelectorProps {
  templates: SendOutBatchTemplate[],
  onSelectTemplate: (templateId: number) => void,
  selectedId: number,
  messageTemplateIsFlashing: boolean
}

const MBSTemplateSelector: FunctionComponent<MBSTemplateSelectorProps>
  = ({ templates, onSelectTemplate, selectedId, messageTemplateIsFlashing }) => {
    return (
      <ul className="template-selector-wrapper">
        {templates.map((p, index) => (
          <li key={p.id}>
            <MBSTemplate
              id={p.id}
              title={p.title}
              flashing={messageTemplateIsFlashing && index === 0}
              description={p.description}
              replyRequired={p.replyRequired}
              onSelectTemplate={onSelectTemplate}
              selected={p.id === selectedId} />
          </li>
        ))}

      </ul>
    );
  }

export default MBSTemplateSelector;
