import { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { DashboardCardFilter } from '../models';

export interface DashboardLog {
    ID: number,
    LogType: number,
    CreatedDateTimeUtc: Date,
    userid: string,
    user: string,
    DashboardId: number,
}

export interface InsertDashboardLog {
    LogType: number,
    userid: string,
}

export class DashboardLogApi {
    parentId: string;
    baseUrl: string;
    apiService: IApiService;

    constructor(apiService: IApiService, dashboardId: string) {
        this.parentId = dashboardId
        this.baseUrl = `api/Dashboard/${dashboardId}/DashboardLog`;
        this.apiService = apiService;
    }

    getById = (id: string) => {
        const url = `${this.baseUrl}/${id}`;
        return this.apiService.callApi(url, 'GET', null);
    }

    getAll = (filter?: DashboardCardFilter) => {
        let searchParamsString = '';
        if (filter) {
            const searchParams = new URLSearchParams();

            if (filter.fromDate) {
                searchParams.append('fromDate', filter.fromDate.toUTCString());
            }
            searchParamsString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
        }
        const url = `${this.baseUrl}${searchParamsString}`;
        return this.apiService.callApi(url, 'GET', null);
    }

    insertDashboardLog = (insertDashboardLog: InsertDashboardLog) => {
        const url = `${this.baseUrl}`;
        return this.apiService.callApi(url, 'POST', insertDashboardLog);
    }
};
