import * as React from 'react';
import Button from '@murphy-frontend/web-core/components/Button';
import CardFooter from "../MainLog/CardFooter";
import { FunctionComponent } from "react";
import { PartyPeople } from '../../api/PartyPeopleApi';
import UpsertPartyPeopleComponent from './UpsertPartyPeopleComponent';
import PartyPeopleFormatted from './PartyPeopleFormatted';
interface PartyPeopleExpandedContentProps {
    partyPeople: PartyPeople,
    dashboardId: string,
    openModal: any,
    closeModal: any,
    translations: Record<string, string>,
    hideUser?: boolean,
}

const PartyPeopleExpandedContent: FunctionComponent<PartyPeopleExpandedContentProps> = ({ hideUser, partyPeople, dashboardId, openModal, closeModal, translations }) => {

    const onClickEdit = () => {
        const upsertPartyPeopleComponent =
            <UpsertPartyPeopleComponent
                partyPeople={partyPeople}
                translations={translations}
                dashboardId={dashboardId}
                onSuccess={() => closeModal()}
                partyId={partyPeople.PartyId}
            />;

        const configuration = {
            centerContent: false,
            hideButton: true,
            body: upsertPartyPeopleComponent,
            title: translations['class-lang-ny-aktor-title'],
        }
        openModal(configuration);
    }

    let footer;
    if (partyPeople.archived !== true) {
        const button =
            <Button
                onClick={() => onClickEdit()}>
                {translations['class-lang-button-edit']}
            </Button>
        footer = <CardFooter rightContent={button} />
    }

    return <PartyPeopleFormatted partyPeople={partyPeople} translations={translations} cardFooter={footer} hideUser={hideUser} />
}

export default PartyPeopleExpandedContent;