import React, { FunctionComponent, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useQueryClient } from '@tanstack/react-query';
import FileList, { FileListFile } from '@murphy-frontend/web-core/components/FileList';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import SearchBar from '@murphy-frontend/web-core/components/SearchBar';
import Button from '@murphy-frontend/web-core/components/Button';
import EditFile from '@murphy-frontend/web-core/components/EditFile';
import FileUploadControl from '@murphy-frontend/web-core/components/FileUploadControl';
import { FileTypes, Roles } from '@murphy-frontend/common/enums';
import { useEditCustomerFile, useRemoveCustomerFile } from '../../Files/mutations';
import { useGetCustomerFiles } from '../../Files/queries';
import { useGetCustomerFiles as useGetCustomerFilesV2 } from "@murphy-frontend/common/api/CustomerFiles/queries";
import queryKeys from '../../Files/queryKeys';
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';
import { useCustomer } from '../../../common/contexts/CustomerContext';
import { useUser } from "../../../common/contexts/UserContext";
import { TimeZoneType, getConvertedDate } from "@murphy-frontend/common/services/TimeService"
import { CustomerFileApi } from '@murphy-frontend/common/api/CustomerFiles/CustomerFilesApi';
import ApiServiceType, { IApiService } from "@murphy-frontend/common/interfaces/IApiService";
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import { downloadFromLink } from '@murphy-frontend/common/utils';
import { CustomerFileResponse } from '@murphy-frontend/common/api/CustomerFiles/models';
import { toast } from 'react-toastify';

interface CrisisFilesProps {
  spawnModal: (configuration: ModalConfiguration) => void;
  closeModal: () => void;
  timeZone: TimeZoneType;
  translations: Record<string, string>;
}

const CrisisFiles: FunctionComponent<CrisisFilesProps> = ({
  spawnModal,
  closeModal,
  timeZone,
  translations,
}) => {
  const { customer } = useCustomer();
  const { user } = useUser();
  const queryClient = useQueryClient();

  const [error, setError] = useState(null);

  const [files, setFiles] = useState<Array<FileListFile>>([]);
  const [filteredFiles, setFilteredFiles] = useState<Array<FileListFile>>([]);

  const [currentSearchText, setCurrentSearchText] = useState("");

  const { mutate: deleteFile, isPending: removeFileIsLoading } = useRemoveCustomerFile(
    customer.Id,
    translations
  );
  const {
    isLoading: isCustomerFilesLoading,
    isError: isErrorCustomerFiles,
    data: dataCustomerFiles,
    error: getCustomerFilesError,
  } = useGetCustomerFiles(customer.Id);

  const { data: customerFilesV2, isLoading: isLoadingCustomerFilesV2 } = useGetCustomerFilesV2(customer.Id);
  const { mutate: editFile, isPending: editFileIsLoading } = useEditCustomerFile(customer.Id, translations);
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const customerFilesApi = new CustomerFileApi(apiService);

  const mapV1CustomerFiles = (filesArray, tz: TimeZoneType): Array<FileListFile> => {
    const mappedFiles = filesArray.map((file: any) => ({
      id: file.Id,
      filepath: file.FilePath,
      filename: file.FileName,
      createddatetime: getConvertedDate(dayjs.utc(file.CreatedDateTime), tz),
      filetypename: "",
      isEditable: true,
    } as FileListFile));
    return mappedFiles;
  }

  const mapV2CustomerFiles = (filesArray: CustomerFileResponse[], tz: TimeZoneType): Array<FileListFile> => {
    const mappedFiles = filesArray.map((file: CustomerFileResponse) => ({
      id: file.ID,
      filename: file.FileName,
      filetypename: file.FileTypeName,
      createddatetime: getConvertedDate(dayjs.utc(file.PeriodStart), tz),
      isEditable: false,
      onClickDownloadOverride: () => { handleDownloadV2(file) },
    } as FileListFile));
    return mappedFiles;
  }

  const handleDownloadV2 = async (customerFile: CustomerFileResponse) => {
    var file = await customerFilesApi.download(customer.Id, customerFile.ID);
    downloadFromLink(customerFile.FileName, file);
  }

  const onSubmitNewFile = (file: File) => {
    apiService.addFileToCustomer(file, customer.Id).then((response) => {
      toast.info("File uploaded successfully");
      queryClient.invalidateQueries({ queryKey: queryKeys.customerList(customer.Id) });
    }).catch((err) => {
      toast.error("Error uploading file");
    }).finally(() => {
      closeModal()
    });
  }

  function DeleteConfirmationBody({ name }) {
    return (
      <p>
        {translations["del-file"]} <strong>{name}</strong>{" "}
      </p>
    );
  }

  useEffect(() => {
    if (isCustomerFilesLoading === false && isLoadingCustomerFilesV2 === false && timeZone) {
      let v1CustomerFiles = mapV1CustomerFiles(dataCustomerFiles, timeZone);
      const sharedCustomerFiles = customerFilesV2.filter((p) => p.IsShared === true);
      let v2NewCustomerFiles = mapV2CustomerFiles(sharedCustomerFiles, timeZone);
      const mergedFiles = [...v1CustomerFiles, ...v2NewCustomerFiles];
      setFiles(mergedFiles);
    }
  }, [dataCustomerFiles, customerFilesV2, isCustomerFilesLoading, isLoadingCustomerFilesV2, timeZone]);

  const submitRemoveFile = (fileId) => {
    deleteFile(fileId);
  };

  const onClickNewFile = () => {
    const modalConf: ModalConfiguration = {
      title: translations["lang-button-add-file"],
      body: (
        <div>
          <FileUploadControl
            translations={translations}
            onClose={() => closeModal()}
            onSubmit={onSubmitNewFile}
          />
        </div>
      ),
      buttonText: "Ok",
      type: "info",
      hideButton: true,
    };
    spawnModal(modalConf);
  };

  const onClickEditFile = (fileRow) => {
    const modalConf: ModalConfiguration = {
      title: translations.edit,
      body: (
        <EditFile
          id={fileRow.id}
          nameInit={fileRow.filename}
          onSuccess={() => closeModal()}
          onFail={() => closeModal()}
          translations={translations}
          onSaveEdit={editFile}
        />
      ),
      hideButton: true,
      type: "info",
    };

    spawnModal(modalConf);
  };

  const onClickRemoveFile = (fileRow) => {
    const modalConf: ModalConfiguration = {
      title: translations["class-remove-file"],
      body: <DeleteConfirmationBody name={fileRow.filename} />,
      hideButton: false,
      buttonText: translations["class-lang-user-table-delete"],
      type: "warning",
      okCallback: () => submitRemoveFile(fileRow.id),
    };

    spawnModal(modalConf);
  };

  const onSearchChanged = (event) => {
    const newValue = event.target.value;
    setCurrentSearchText(newValue);
  };

  function ErrorMessage() {
    return <p>{error}</p>;
  }

  useEffect(() => {
    if (files) {
      // let newFilteredFiles = mapOldCustomerFiles(files);
      let newFilteredFiles = [...files];

      if (currentSearchText) {
        const searchValue = currentSearchText.toUpperCase();
        newFilteredFiles = newFilteredFiles.filter((p) =>
          p.filename.toUpperCase().startsWith(searchValue)
        );
      }

      setFilteredFiles(newFilteredFiles);
    }
  }, [files, currentSearchText]);

  useEffect(() => {
    if (error) {
      const modalConf: ModalConfiguration = {
        title: "Error",
        body: <ErrorMessage />,
        buttonText: "Ok",
        type: "error",
        okCallback: () => {
          setError(null);
        },
      };
      spawnModal(modalConf);
    }
  }, [error]);

  if (isCustomerFilesLoading || isLoadingCustomerFilesV2 || removeFileIsLoading || editFileIsLoading) {
    return (
      <div className="spinner-container-global-center">
        <Spinner />
      </div>
    );
  }
  return (
    <div className="generalwrapper">
      <section className="header-container vertical-aligner">
        {user?.Role !== Roles.Trial && (
          <div className="one">
            <Button
              isIconButton
              buttonStyle="btn--add"
              buttonSize="btn-with-icon"
              onClick={onClickNewFile}
            >
              <span className="btn-with-icon-text">
                {translations.uploadnewfile}
              </span>
            </Button>
          </div>
        )}
        <div className="two" />
        <div className="three">
          <SearchBar
            val={currentSearchText}
            handleChange={onSearchChanged}
            placeholder={translations["placeholder-lang-search-action"]}
          />
        </div>
      </section>
      <section className="user-table-container">
        <div>
          <FileList
            translations={translations}
            files={filteredFiles}
            onClickRemoveFile={onClickRemoveFile}
            onClickEditFile={onClickEditFile}
          />
        </div>
      </section>
    </div>
  );
};

export default CrisisFiles;
