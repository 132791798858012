import { FunctionComponent } from "react";
import CreatableSelect from "react-select/creatable";
import { ActionMeta, CSSObjectWithLabel, ControlProps, GroupBase, InputProps, MultiValue, OptionsOrGroups, PropsValue, StylesConfig } from "react-select";
import React from "react";

interface CreateableMurphySelectProps {
    isClearable?: boolean;
    isMulti?: boolean;
    placeholder?: string;
    title?: string,
    isLoading?: boolean;
    isSearchable?: boolean;
    direction?: string,
    name?: string;
    createLabel?: string;
    errorMessage?: string,
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    options: OptionsOrGroups<{ label: string; value: string; }, GroupBase<{ label: string; value: string; }>>;
    onChange?: (newValue: PropsValue<{
        label: string;
        value: string;
    }>, actionMeta: ActionMeta<{
        label: string;
        value: string;
    }>) => void
    value?: PropsValue<{
        label: string;
        value: string;
    }>
}

const customStyles: StylesConfig = {
    option: (provided: any, state: any) => ({
        ...provided,
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        color: 'black',

    }),
    control: (base: CSSObjectWithLabel, state: ControlProps<unknown, boolean, GroupBase<unknown>>) => {
        return {
            ...base,
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            borderRadius: 0,
            minHeight: '60px',
            fontSize: '14px',
            color: '#a5a3a9',
            outline: 'none',
            boxShadow: 'none',
            borderBottom: state.isFocused ? '3px solid #0065bd' : '2px solid #e0e1dd'
        }
    },
    singleValue: (provided: any, state: any) => ({
        ...provided,
        border: 0,
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',

    }),
    multiValue: (provided: any, state: any) => ({
        ...provided,
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        color: '#a5a3a9',
        borderRadius: '8px',
    }),
    input: (base: CSSObjectWithLabel, state: InputProps<unknown, boolean, GroupBase<unknown>>) => {
        return {
            ...base,
            borderRadius: 0,
            fontSize: '16px',
            maxWidth: '80vw',
            padding: '10px',
            width: '260px',
            outline: 'none',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
        }
    },
    placeholder: (provided: any, state: any) => ({
        ...provided,
        fontSize: '14px',
        color: '#a5a3a9'
    }),
}


const CreateableMurphySelect: FunctionComponent<CreateableMurphySelectProps> = (
    { errorMessage, createLabel, title, direction, isClearable, isMulti, placeholder, options, onChange, value, isLoading, onBlur, name, isSearchable }
) => {

    const id = `input-${new Date().getTime()}`;
    let classes = 'form-input';
    if (direction === 'column') {
        classes = ` ${'form-input-column-flow'}`;
    }


    return (
        <div className={classes}>
            {title ? <label htmlFor={id}>{title}</label> : null}

            <CreatableSelect
                isSearchable={isSearchable}
                isLoading={isLoading}
                styles={customStyles}
                isClearable={isClearable}
                isMulti={isMulti}
                placeholder={placeholder}
                options={options}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                formatCreateLabel={userInput => `${createLabel} ${userInput}`}
            />
            {errorMessage ? <div className="input-error-message">{errorMessage}</div> : null}
        </div>
    );
}

export default CreateableMurphySelect;