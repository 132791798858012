import { IApiService } from '@murphy-frontend/common/interfaces/IApiService';

export class OnlineEducationApi {
    constructor(
        private apiService: IApiService,
    ) { }

    getTokenUrl = async (onlineEducationUrl: string): Promise<string> => {
        const url = `api/OnlineEducation?onlineEducationUrl=${onlineEducationUrl}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }
}