import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { ContactsApi, ContactModel } from './ContactsApi';
import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';

export const createContactMutation = (translations: Record<string, string>) => {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const contactsApi = new ContactsApi(apiService);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (upsertContactRequest: ContactModel) => contactsApi.createContact(upsertContactRequest),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all });
      toast.success(translations.contactcreated);
    },
  });
};

export const editContactMutation = (translations: Record<string, string>) => {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const contactsApi = new ContactsApi(apiService);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (editContactRequest: ContactModel) => contactsApi.editContact(editContactRequest),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all });
      toast.success(translations.contactedited);
    },
  });
};

export const deleteContactMutation = (translations: Record<string, string>) => {
  const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
  const contactsApi = new ContactsApi(apiService);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (contactId: number) => contactsApi.removeContact(contactId),
    onError: (err) => {
      if (err.response && err.response.data) {
        toast.error(err.response.data);
      } else {
        toast.error('Error');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.all });
      toast.success(translations.contactdeleted);
    },
  });
};
