import React, { useState, useEffect, FunctionComponent } from 'react';
import dayjs from 'dayjs';
import MBSListTable from './MBSListTable';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import { getConvertedDate, compareMonth } from '@murphy-frontend/common/services/TimeService';
import { useGetSendOuts } from '../queries';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { useUserPreferences } from '../../../common/contexts/UserPreferencesContext';
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';
import { useCustomer } from '../../../common/contexts/CustomerContext';
import OptionSet from '@murphy-frontend/web-core/components/OptionSet';
import { alertDropDowns } from '@murphy-frontend/web-core/constants';
import { translateConstantsWithBackendTranslations } from "../../../common/services/translationService";

const MBSList: FunctionComponent = () => {
  const { customer } = useCustomer();
  const { openModal } = useModal();
  const { translations, timeZone } = useUserPreferences();

  const [error, setError] = useState(null);
  const [sendOutBatches, setSendOutBatches] = useState([]);
  const [filterValue, setFilterValue] = useState("1");
  const [options, setOptions] = useState(alertDropDowns)


  const {
    isLoading: isSendOutsLoading, isError: isGetSendOutsError, data: sendOutsData, error: getSendOutsError,
  } = useGetSendOuts(customer.Id);

  const ErrorMessage = () => (<p>{error}</p>);

  useEffect(() => {
      if (translations) {
        const translatedProducts = translateConstantsWithBackendTranslations(alertDropDowns, translations);
        setOptions(translatedProducts)
      }
  }, [translations])

  const onGetSendOutBatchesSuccess = (data: any, tz, filterValue: string) => {
    const mappedData = data.map((p) => ({
      username: p.User,
      id: p.SendOutId,
      date: dayjs.utc(p.CreatedDateTimeUtc),
      title: p.Title,
    }));

    mappedData.sort((a, b) => b.date - a.date);
    const mappedDataMonthSort = mappedData.filter(m => compareMonth(m.date, tz, filterValue))
    const newMappedData = mappedDataMonthSort.map((p) => ({
      username: p.username,
      id: p.id,
      originalDate: p.date,
      date: getConvertedDate(p.date, tz),
      title: p.title,
    }))
    ;
    setSendOutBatches(newMappedData);
  };


  useEffect(() => {
    if (!isSendOutsLoading && sendOutsData && timeZone) {
      onGetSendOutBatchesSuccess(sendOutsData, timeZone, filterValue);
    }
  }, [sendOutsData, isSendOutsLoading, timeZone, filterValue]);

  useEffect(() => {
    if (error) {
      const modalConf: ModalConfiguration = {
        title: 'Error',
        body: <ErrorMessage />,
        buttonText: 'Ok',
        type: 'error',
        okCallback: () => { setError(null); },
      };

      openModal(modalConf);
    }
  }, [error]);

  const onFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    setFilterValue(event.target.value);
  };

  if (isSendOutsLoading) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }

  const dropDown = (
    <li key="dropDown" className="nav-item">
      <div className="navbar-optionset-container">
        <div className="navbar-optionset">
          <OptionSet
            direction="column"
            key={1}
            optionSetId={1}
            options={options}
            selectedOption={filterValue}
            handleChange={onFilterChange}
          />
        </div>
      </div>
    </li>
  );

  return (
    <div className="pbs-wrapper">
      <h1>{translations.sentmbs}</h1>
      {dropDown}
      <span className="gradient" />
      <div className="pbs-grid">
        <div className="table-container">
          <MBSListTable
            translations={translations}
            rows={sendOutBatches}
            timeZone={timeZone}
          />
        </div>
      </div>
    </div>
  );
}

export default MBSList;
