import * as React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "@murphy-frontend/web-core/components/Input";
import Button from "@murphy-frontend/web-core/components/Button";

const partyPeopleSchema = yup.object().shape({
  Title: yup.string().nullable().notRequired(),
  Name: yup.string().required(),
  Email: yup.string().nullable().notRequired(),
  PhoneNr: yup.string().nullable().notRequired(),
  archived: yup.boolean(),
});

export interface PartyPeopleFormDto {
  Name: string;
  Email: string;
  PhoneNr: string;
  Title: string;
  archived: boolean;
}

type PartyPeopleFormProps = {
  partyPeople?: PartyPeopleFormDto;
  onSubmit: (data: PartyPeopleFormDto) => void;
  onClickArchive?: () => void;
  translations?: any;
  isLoading?: boolean;
};

const PartyPeopleForm = ({
  isLoading,
  partyPeople,
  onSubmit,
  translations,
  onClickArchive,
}: PartyPeopleFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isDirty },
    reset,
    getValues,
  } = useForm<PartyPeopleFormDto>({
    resolver: yupResolver(partyPeopleSchema),
    defaultValues: {
      Name: "",
      Email: "",
      PhoneNr: "",
      Title: "",
      archived: false,
    },
  });

  useEffect(() => {
    if (partyPeople) {
      const defaults = {
        Name: partyPeople.Name,
        Email: partyPeople.Email,
        PhoneNr: partyPeople.PhoneNr,
        Title: partyPeople.Title,
        archived: partyPeople.archived,
      };
      reset(defaults);
    }
  }, [partyPeople, reset]);

  const onSubmitHandler = (data: PartyPeopleFormDto) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="dashboardform-container">
        <Input
          isControlled
          register={register}
          name="Name"
          isRequired
          title={translations["class-lang-modal-actor-name"]}
          errorMessage={errors.Name?.message}
        />
        <Input
          isControlled
          register={register}
          name="Email"
          title={translations["class-lang-modal-actor-email"]}
          errorMessage={errors.Email?.message}
        />
        <Input
          isControlled
          register={register}
          name="PhoneNr"
          title={translations["class-lang-modal-actor-phone"]}
          errorMessage={errors.PhoneNr?.message}
        />
        <Input
          isControlled
          register={register}
          name="Title"
          title={translations["class-lang-modal-actor-worktitle"]}
          errorMessage={errors.Title?.message}
        />
        <div className="form-buttons">
          {partyPeople && (
            <Button
              type="button"
              disabled={isDirty || isLoading}
              onClick={() => onClickArchive()}
              buttonStyle="btn--archive"
              buttonSize="btn--medium"
              isWarningButton={true}
            >
              {translations.archive}
            </Button>
          )}
          {isDirty && (
            <Button
              type="button"
              disabled={!isDirty || isLoading}
              onClick={() => reset()}
              buttonStyle="btn--undo"
              buttonSize="btn--medium"
              isNeutralButton={true}
            >
              {translations.undo}
            </Button>
          )}
          <Button
            isLoading={isLoading}
            disabled={!isDirty || (errors && errors.length > 0)}
            type="submit"
            buttonStyle="btn--primary"
            buttonSize="btn--medium"
          >
            {translations.save}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default PartyPeopleForm;
