import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';
import { convertBreaksToNewLine, convertNewLinesToBreak } from '@murphy-frontend/common/utils';
import { BackGround, UpsertBackGround } from '../../api/BackGroundApi';
import { useUpsertBackGround } from '../../mutations';
import BackGroundForm, { BackGroundFormDto } from './BackGroundForm';

interface UpsertBackGroundCardProps {
    dashboardId: string,
    backGround?: BackGround,
    onSuccess: () => void,
    translations: Record<string, string>,
}

const UpsertBackGroundComponent: FunctionComponent<UpsertBackGroundCardProps> = ({ dashboardId, backGround, onSuccess, translations }) => {

    const { mutate: upsertBackGroundMutation, isPending } = useUpsertBackGround(dashboardId);
    const [backGroundCardData, setBackGroundFormData] = useState<BackGroundFormDto>(null);

    const onSubmitHandler = (backGroundFormDto: BackGroundFormDto) => {

        const upsertBackGround: UpsertBackGround = {
            WhatMessage: backGroundFormDto.WhatMessage ? convertNewLinesToBreak(backGroundFormDto.WhatMessage) : null,
            WhenMessage: backGroundFormDto.WhenMessage ? convertNewLinesToBreak(backGroundFormDto.WhenMessage) : null,
            HowMessage: backGroundFormDto.HowMessage ? convertNewLinesToBreak(backGroundFormDto.HowMessage) : null,
            AffectedMessage: backGroundFormDto.AffectedMessage ? convertNewLinesToBreak(backGroundFormDto.AffectedMessage) : null,
            ExtentMessage: backGroundFormDto.ExtentMessage ? convertNewLinesToBreak(backGroundFormDto.ExtentMessage) : null,
        }

        upsertBackGroundMutation(upsertBackGround, {
            onSuccess: () => {
                onSuccess();
            }
        });
    };

    useEffect(() => {
        if (backGround) {
            const backGroundFormDto: BackGroundFormDto = {
                WhatMessage: backGround.WhatMessage ? convertBreaksToNewLine(backGround.WhatMessage) : null,
                WhenMessage: backGround.WhenMessage ? convertBreaksToNewLine(backGround.WhenMessage) : null,
                HowMessage: backGround.HowMessage ? convertBreaksToNewLine(backGround.HowMessage) : null,
                AffectedMessage: backGround.AffectedMessage ? convertBreaksToNewLine(backGround.AffectedMessage) : null,
                ExtentMessage: backGround.ExtentMessage ? convertBreaksToNewLine(backGround.ExtentMessage) : null,
            }
            setBackGroundFormData(backGroundFormDto);
        }

    }, [backGround])

    return (
        <div>
            <BackGroundForm
                translations={translations}
                onSubmit={onSubmitHandler}
                backGround={backGroundCardData}
                isLoading={isPending}
            />
        </div>
    );
}

export default UpsertBackGroundComponent;