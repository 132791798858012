import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';
import { convertBreaksToNewLine, convertNewLinesToBreak } from '@murphy-frontend/common/utils';
import { Assumption, UpsertAssumption } from '../../api/AssumptionApi';
import { useUpsertAssumption } from '../../mutations';
import AssumptionForm, { AssumptionFormDto } from './AssumptionForm';

interface UpsertAssumptionProps {
    dashboardId: string,
    assumption?: Assumption,
    onSuccess: () => void,
    translations: Record<string, string>,
}

const UpsertAssumptionComponent: FunctionComponent<UpsertAssumptionProps> = ({ dashboardId, assumption, onSuccess, translations }) => {

    const { mutate: upsertAssumptionMutation, isPending } = useUpsertAssumption(dashboardId);
    const [assumptionFormData, setAssumptionFormData] = useState<AssumptionFormDto>(null);

    const onSubmitHandler = (assumptionFormDto: AssumptionFormDto) => {

        const upsertAssumption: UpsertAssumption = {
            Best: assumptionFormDto.Best ? convertNewLinesToBreak(assumptionFormDto.Best) : null,
            Worst: assumptionFormDto.Worst ? convertNewLinesToBreak(assumptionFormDto.Worst) : null,
            Prob: assumptionFormDto.Prob ? convertNewLinesToBreak(assumptionFormDto.Prob) : null,
        }

        upsertAssumptionMutation(upsertAssumption, {
            onSuccess: () => {
                onSuccess();
            }
        });
    };

    useEffect(() => {
        if (assumption) {
            const assumptionFormDto: AssumptionFormDto = {
                Best: assumption.Best ? convertBreaksToNewLine(assumption.Best) : null,
                Worst: assumption.Worst ? convertBreaksToNewLine(assumption.Worst) : null,
                Prob: assumption.Prob ? convertBreaksToNewLine(assumption.Prob) : null,
            }
            setAssumptionFormData(assumptionFormDto);
        }

    }, [assumption])

    return (
        <div>
            <AssumptionForm
                translations={translations}
                onSubmit={onSubmitHandler}
                assumption={assumptionFormData}
                isLoading={isPending}
            />
        </div>
    );
}

export default UpsertAssumptionComponent;