import * as React from "react";
import { DashboardEntityTypes } from "@murphy-frontend/common/enums";
import { getConvertedDateDayjs } from '@murphy-frontend/common/services/TimeService';
import { InformationCard } from "../../../api/InformationCardApi";
import InformationCardExpandedContent from "../../InformationCard/InformationCardExpandedContent";
import { MainLogCard } from "../models";
import { BaseCardProps } from "./MainLogCardFactory";

export interface InformationCardMainLogCardProps extends BaseCardProps {
    informationCard: InformationCard,
}

export const InformationCardMainLogCard =
    ({ translations, informationCard, timeZone, dashboardId, openModal, closeModal }: InformationCardMainLogCardProps): MainLogCard => {
        const date = getConvertedDateDayjs(informationCard.AlternatedTime || informationCard.CreatedDateTime, timeZone);
        const expandedContent =
            <InformationCardExpandedContent
                informationCard={informationCard}
                dashboardId={dashboardId}
                openModal={openModal}
                closeModal={closeModal}
                translations={translations}
            />

        const searchableContent = [];
        searchableContent.push(informationCard.Title);
        if (informationCard.Owner)
            searchableContent.push(informationCard.Owner);
        if (informationCard.ProvidedBy)
            searchableContent.push(informationCard.ProvidedBy);
        if (informationCard.Message)
            searchableContent.push(informationCard.Message);
        if (informationCard.user)
            searchableContent.push(informationCard.user);

        const logCard: MainLogCard = {
            ID: `${informationCard.ID}-${DashboardEntityTypes.InformationCard}`,
            Title: informationCard.Title,
            isArchived: informationCard.archived,
            isDecision: informationCard.isDecision,
            SearchableContent: searchableContent,
            Date: date,
            User: informationCard.user,
            DateWithoutTime: date.format("YYYY-MM-DD"),
            Time: date.format("HH:mm"),
            EntityType: DashboardEntityTypes.InformationCard,
            ExpandedContent: expandedContent,
            OriginalCard: informationCard,
        }
        return logCard;
    }