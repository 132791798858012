import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
    useParams,
} from 'react-router-dom';
import { useUserPreferences } from '../../common/contexts/UserPreferencesContext';
import { EventTypes } from '@murphy-frontend/common/enums';
import { useClientMethod } from '../../common/hooks/useClientMethod';
import { useHub } from '../../common/hooks/useHub';
import { createConnection } from '../../common/services/signalrService';
import ActionsCardsTable from './components/ActionsCards/ActionsCardsTable';
import BackGroundAssumptionTabs from './components/BackGroundAssumptionTabs';
import DashboardNavbar from './components/DashboardNavbar';
import DashboardNavbarItem from './components/DashboardNavbar/DashboardNavbarItem';
import GoalsTabs from './components/Goals/GoalsTabs';
import StatusCardsTable from './components/StatusCards/StatusCardsTable';
import { DashboardEvent } from './models';
import queryKeys from './queryKeys';
import { IconContext } from 'react-icons/lib';
import { ImHome } from 'react-icons/im';
import { MdGroups } from 'react-icons/md';
import { FaBook, FaFolder, FaRegCalendarAlt } from 'react-icons/fa';
import PartyTable from './components/Party/PartyTable';
import DashboardFiles from './components/DashboardFiles';
import MainLog from './components/MainLog';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import UpsertMeetingComponent from './components/Meeting/UpsertMeetingComponent';
import MeetingNavbarItem from './components/DashboardNavbar/MeetingNavbarItem';
import { getConvertedDateDayjs } from '@murphy-frontend/common/services/TimeService'
import { useGetDashboard } from './queries';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import { DefaultStatusCardStatusService, MSBStatusCardStatusService, StatusCardStatusService } from './components/StatusCards/StatusCardStatusService';
import { dashboardSettings } from '../../common/variables';
import { StatusTypes } from '../../common/enums';
import { LogService } from '../../common/services/LogService';
import { useCustomer } from '../../common/contexts/CustomerContext';

const Dashboard: FunctionComponent = () => {
    const queryClient = useQueryClient();
    const { id } = useParams()
    const [hubConnection, setHubConnection] = useState<null | HubConnection>(null);
    const { translations, timeZone } = useUserPreferences();
    const [selectedTab, setSelectedTab] = useState(1)
    const [meetingTime, setMeetingTime] = useState('');
    const [dashboardName, setDashboardName] = useState('');
    const [statusTypeId, setStatusTypeId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [statusCardStatusService, setStatusCardStatusService] = useState<StatusCardStatusService>();

    const { openModal, closeModal } = useModal();

    const { data: dashboardData, error: dashboardError,
        isError: dashboardIsError, isLoading: dashboardIsLoading } = useGetDashboard(id)

    useEffect(() => {
        if (dashboardData) {
            if (dashboardData.Name) {
                const title = decodeURIComponent(dashboardData.Name);
                document.title = `Murphy | ${title}`;
                setDashboardName(title);
            }
            if (dashboardData.StatusTypeId) {
                setStatusTypeId(dashboardData.StatusTypeId);
            }
            if (dashboardData.StatusMeetingDateTime) {
                const dateString = getConvertedDateDayjs(dashboardData.StatusMeetingDateTime, timeZone).format('ddd DD MMM');
                const startTimeString = getConvertedDateDayjs(dashboardData.StatusMeetingDateTime, timeZone).format('HH:mm');
                const meetingString = `${dateString} ${startTimeString}`;
                setMeetingTime(meetingString);
            } else {
                setMeetingTime('');
            }
        }
        return () => {
            setDashboardName('');
            setMeetingTime('');
        }
    }, [dashboardData, timeZone])

    useEffect(() => {
        if (statusTypeId) {
            if (dashboardSettings.statusCardStatusMode === "msb") {
                const statusCardStatusServiceInstance =
                    new MSBStatusCardStatusService(translations);
                setStatusCardStatusService(statusCardStatusServiceInstance);
            } else {
                if (
                    statusTypeId &&
                    dashboardData.StatusTypeId === StatusTypes.MSB
                ) {
                    const statusCardStatusServiceInstance =
                        new MSBStatusCardStatusService(translations);
                    setStatusCardStatusService(statusCardStatusServiceInstance);
                }
                if (
                    statusTypeId &&
                    dashboardData.StatusTypeId === StatusTypes.Default
                ) {
                    const statusCardStatusServiceInstance =
                        new DefaultStatusCardStatusService(translations);
                    setStatusCardStatusService(statusCardStatusServiceInstance);
                }
            }

        }
    }, [translations, statusTypeId])

    useEffect(() => {
        const signalRConnection = createConnection(id);
        setHubConnection(signalRConnection);
    }, [])

    const { hubConnectionState, error: signalRError } = useHub(hubConnection);
    useClientMethod(hubConnection, id, (events) => {
        events.map((event: DashboardEvent) => {
            const eventType = event.EventType;
            const data = event.Payload;
            const entity = event.Entity;

            queryClient.setQueriesData({
                queryKey: queryKeys.dashboardentity(id, entity),

            }, (oldData: any) => {
                const update = (record: any) => {
                    if (record.ID === data.ID) {
                        return { ...record, ...data }
                    } else {
                        return record;
                    }
                }
                if (Array.isArray(oldData)) {
                    if (eventType === EventTypes.Update) {
                        return oldData.map(update);
                    }
                    if (eventType === EventTypes.Add) {
                        return [...oldData, data];
                    }
                    if (eventType === EventTypes.Deleted) { // might need special treatment for files to show deletion in log
                        return oldData.map(update);
                    }
                } else {
                    return update(oldData);
                }
            })

        })
    });

    useEffect(() => {
        const newIsLoading = hubConnectionState !== HubConnectionState.Connected || dashboardIsLoading;
        setIsLoading(newIsLoading);
    }, [hubConnectionState, dashboardIsLoading])

    useEffect(() => {
        if (dashboardIsError) {
            console.log(dashboardError);
            throw new Error("Dashboard error")
        }
        if (signalRError) {
            console.log(signalRError);
            const logService = new LogService();

            let errorMessage;
            if (typeof signalRError === 'object') {
                errorMessage = JSON.stringify(signalRError);
            } else if (typeof signalRError === 'string') {
                errorMessage = signalRError;
            } else {
                errorMessage = 'Unexpected type of error';
            }

            const logError = new Error("SignalR error");
            logError.name = "SignalR error";
            logError.message = errorMessage;

            logService.error(logError);
            throw logError;
        }

    }, [dashboardIsError, signalRError])

    const onClickEditMeeting = () => {
        const upsertMeetingCardComponent =
            <UpsertMeetingComponent translations={translations} dashboard={dashboardData} onSuccess={() => closeModal()} />;
        const configuration = {
            centerContent: false,
            hideButton: true,
            body: upsertMeetingCardComponent,
            title: translations['class-lang-next-meeting-card'],
        }
        openModal(configuration);
    }

    const whiteIcon = <IconContext.Provider value={{ className: 'dashboard-nav-bar-item-icon' }}>
        <ImHome />
    </IconContext.Provider>

    const actorsIcon = <IconContext.Provider value={{ className: 'dashboard-nav-bar-item-icon' }}>
        <MdGroups />
    </IconContext.Provider>

    const filesIcon = <IconContext.Provider value={{ className: 'dashboard-nav-bar-item-icon' }}>
        <FaFolder />
    </IconContext.Provider>

    const logIcon = <IconContext.Provider value={{ className: 'dashboard-nav-bar-item-icon' }}>
        <FaBook />
    </IconContext.Provider>

    const meetingIcon = <IconContext.Provider value={{ className: 'dashboard-nav-bar-item-icon' }}>
        <FaRegCalendarAlt />
    </IconContext.Provider>

    const whiteTab = <div className='dashboard-grid'>
        <div className="goals">
            <GoalsTabs dashboardId={id} translations={translations} />
        </div>
        <div className="assumption">
            <BackGroundAssumptionTabs dashboardId={id} translations={translations} />
        </div>
        <div className="status-cards">
            <StatusCardsTable dashboardId={id} translations={translations} statusCardStatusService={statusCardStatusService} />
        </div>
        <div className="action-cards">
            <ActionsCardsTable dashboardId={id} translations={translations} timeZone={timeZone} />
        </div>
    </div>;

    const actorsTab = <div className='dashboard-flex-container margin-on-large-screen'>
        <div className='dashboard-flex-container-item'>
            <PartyTable dashboardId={id} translations={translations} />
        </div>
    </div>;

    const filesTab = <div className='dashboard-flex-container'>
        <div className='dashboard-flex-container-item'>
            <DashboardFiles dashboardId={id} />
        </div>
    </div>;

    const logTab = <div className='dashboard-flex-container'>
        <div className='dashboard-flex-container-item'>
            <MainLog dashboardId={id} translations={translations} statusCardStatusService={statusCardStatusService} />
        </div>
    </div>;

    if (isLoading === true) {
        return (<div className="spinner-container-global-center"><Spinner /></div>);
    }

    const navItems = <DashboardNavbar>
        <MeetingNavbarItem onClick={() => onClickEditMeeting()} title={translations['class-lang-next-meeting-card']} icon={meetingIcon} meetingTime={meetingTime} />
        <DashboardNavbarItem isSelected={selectedTab === 1} onClick={() => { setSelectedTab(1) }} title={translations.leadingroom} icon={whiteIcon} />
        <DashboardNavbarItem isSelected={selectedTab === 2} onClick={() => { setSelectedTab(2) }} title={translations['lang-involved-actors']} icon={actorsIcon} />
        <DashboardNavbarItem isSelected={selectedTab === 3} onClick={() => { setSelectedTab(3) }} title={translations['class-lang-files']} icon={filesIcon} />
        <DashboardNavbarItem isSelected={selectedTab === 4} onClick={() => { setSelectedTab(4) }} title={translations['lang-rubrik-situation']} icon={logIcon} />
    </DashboardNavbar>;

    return (
        <>
            <h1 className='dashboard-title'>{dashboardName}</h1>
            <div className='dashboard-grid-container'>
                {navItems}
                {selectedTab === 1 && whiteTab}
                {selectedTab === 2 && actorsTab}
                {selectedTab === 3 && filesTab}
                {selectedTab === 4 && logTab}
            </div>
        </>
    );
}

export default Dashboard;