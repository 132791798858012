import { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { DashboardCardFilter } from '../models';

export interface InformationCard {
    ID: number,
    Title: string,
    Message?: string,
    CreatedDateTime: Date,
    Important: boolean,
    Status: number,
    ProvidedBy?: string
    Confirmed: boolean,
    child?: number,
    archived: boolean,
    AlternatedTime?: Date,
    user: string,
    Owner: string,
    isDecision: boolean,
}
export interface UpsertInformationCard {
    Title: string,
    Message?: string,
    Confirmed: boolean,
    Status: number,
    ProvidedBy?: string,
    Owner: string,
    isDecision: boolean,
    Important: boolean,
    AlternatedTime?: Date,
    ParentId?: number,
    archived?: boolean
}

export interface PatchInformationCard {
    Id: number,
    archived?: boolean,
    child?: number,
}

export class InformationCardApi {
    parentId: string;
    baseUrl: string;
    apiService: IApiService;

    constructor(apiService: IApiService, dashboardId: string) {
        this.apiService = apiService;
        this.parentId = dashboardId
        this.baseUrl = `api/Dashboard/${dashboardId}/InformationCard`;
    }

    getById = (id: string) => {
        const url = `${this.baseUrl}/${id}`;
        return this.apiService.callApi(url, 'GET', null);
    }

    getAll = (filter?: DashboardCardFilter) => {
        let searchParamsString = '';
        if (filter) {
            const searchParams = new URLSearchParams();

            if (filter.fromDate) {
                searchParams.append('fromDate', filter.fromDate.toUTCString());
            }
            searchParamsString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
        }
        const url = `${this.baseUrl}${searchParamsString}`;
        return this.apiService.callApi(url, 'GET', null);
    }

    upsertInformationCard = (upsertInformationCard: UpsertInformationCard) => {
        const url = `${this.baseUrl}`;
        return this.apiService.callApi(url, 'POST', upsertInformationCard);
    }

    patchInformationCard = (patchInformationCard: PatchInformationCard) => {
        const url = `${this.baseUrl}/${patchInformationCard.Id}`;
        return this.apiService.callApi(url, 'PATCH', patchInformationCard);
    }
};
