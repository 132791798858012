import { FunctionComponent } from "react";
import TitleWithControls from "./TitleWithControls";
import React from "react";
import { DroneComponent } from "@murphy-frontend/common/api/DroneEvents/types";

interface CompleteButtonProps {
    eventFlowBlock: DroneComponent;
}

const CompleteButton: FunctionComponent<CompleteButtonProps> = (
    {
        eventFlowBlock,
    }
) => {

    const content = <span>
        {eventFlowBlock.Title}
    </span>;

    return (<div className="m-spacing">
        <div className="drone-button-wrapper">
            <div className="drone-button-title">
                <TitleWithControls
                    content={content}
                />
            </div>
        </div>
    </div>);
}

export default CompleteButton;