import { useDroneEvent } from "@murphy-frontend/common/api/DroneEvents/Queries";
import Spinner from "@murphy-frontend/web-core/components/Spinner";
import React from "react";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import StartedEventFlowView from "../components/StartedEventFlowView";

interface StartedEventFlowPageProps {

}

const StartedEventFlowPage: FunctionComponent<StartedEventFlowPageProps> = () => {

    const { id } = useParams();
    const { data: startedEventFlow, isLoading: startedEventFlowIsLoading } = useDroneEvent(id);

    if (startedEventFlowIsLoading || !startedEventFlow) {
        return <Spinner isGlobal />
    }

    return (<div className="general-wrapper">
        <div className='h2-with-info-button'>
            <h2>{startedEventFlow.Title}</h2>
        </div>
        <div className="event-flow-wrapper">
            <StartedEventFlowView
                eventFlow={startedEventFlow} />
        </div>

    </div>);
}

export default StartedEventFlowPage;