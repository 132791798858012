import * as React from 'react';
import { useRef } from 'react';
import useDetectOutsideClick from '../hooks/useDetectOutsideClick';

type ActionsButtonProps = {
    actionButtonItems: any[],
    icon: any,
    pullRightOnSmallScreen?: boolean,
    pullLeft?: boolean,
}

const ActionsButton: React.FunctionComponent<ActionsButtonProps> = ({ actionButtonItems, icon, pullRightOnSmallScreen, pullLeft }) => {

    const dropdownRef = useRef(null);
    const [dropDownVisible, toggleDropDown] = useDetectOutsideClick(dropdownRef, false);

    const onClickToggleDropdown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        toggleDropDown(!dropDownVisible);
    };


    let ddlClasses = dropDownVisible ? 'dropdown-content icon-hover-child active' : 'icon-hover dropdown-content';
    if (pullRightOnSmallScreen === true) {
        ddlClasses += ' pull-right';
    }
    if (pullLeft) {
        ddlClasses += ' pull-left';
    }

    return <div className="dashboard-table-actions-button">
        <div onClick={onClickToggleDropdown} className="dropdown">
            <div className='left-icon'>
                {icon}
            </div>
            <ul className={ddlClasses} ref={dropdownRef}>
                {actionButtonItems.map((actionButtonItem) => (actionButtonItem))}
            </ul>
        </div>
    </div>
}

export default ActionsButton;