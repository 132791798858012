import {useMutation, useQueryClient} from "@tanstack/react-query"
import {toast} from "react-toastify"
import {
	CreateDashboardRequest,
	DashboardApi,
	EditMeetingRequest,
	UpdateDashboardRequest,
	CreateTemplateRequest,
	DeleteTemplateRequest
} from "./DashboardsApi"
import queryKeys from "./queryKeys"
import {useInjection} from "@murphy-frontend/common/contexts/InversifyContext"
import ApiServiceType, {IApiService} from "@murphy-frontend/common/interfaces/IApiService"

export const useEditDashboard = (customerId: string, translations: Record<string, string>) => {
	const apiService = useInjection<IApiService>(ApiServiceType.IApiService)
	const queryClient = useQueryClient()
	const dashboardApi = new DashboardApi(apiService)

	return useMutation({
		mutationFn: (editDashboardRequest: UpdateDashboardRequest) => dashboardApi.editDashboard(editDashboardRequest),
		onMutate: editDashboardRequest => {
			const dashId = editDashboardRequest.DashboardId
			return {dashId}
		},
		onError: err => {
			if (err.response.data) {
				toast.error(err.response.data)
			} else {
				toast.error("Error")
			}
		},
		onSuccess: context => {
			queryClient.invalidateQueries({queryKey: queryKeys.list(customerId)})
			queryClient.invalidateQueries({queryKey: queryKeys.detail(context.dashId)})
			toast.success(translations.dashboardedited)
		}
	})
}

export const useEditMeeting = () => {
	const apiService = useInjection<IApiService>(ApiServiceType.IApiService)
	const dashboardApi = new DashboardApi(apiService)

	return useMutation({
		mutationFn: (editDashboardRequest: EditMeetingRequest) => dashboardApi.editMeeting(editDashboardRequest),
		onError: err => {
			if (err.response.data) {
				toast.error(err.response.data)
			} else {
				toast.error("Error")
			}
		},
		onSuccess: context => {
			toast.success("Meeting updated")
		}
	})
}

export const useCreateDashboard = (customerId: string, translations: Record<string, string>) => {
	const apiService = useInjection<IApiService>(ApiServiceType.IApiService)
	const dashboardApi = new DashboardApi(apiService)
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (createDashboardRequest: CreateDashboardRequest) =>
			dashboardApi.createDashboard(createDashboardRequest),
		onError: err => {
			if (err.response.data) {
				toast.error(err.response.data)
			} else {
				toast.error("Error")
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries({queryKey: queryKeys.list(customerId)})
			toast.success(translations.dashboardcreated)
		}
	})
}

export const useCreateTemplate = (customerId: string, translations: Record<string, string>) => {
	const apiService = useInjection<IApiService>(ApiServiceType.IApiService)
	const dashboardApi = new DashboardApi(apiService)
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (createTemplateRequest: CreateTemplateRequest) =>
			dashboardApi.createTemplate(createTemplateRequest),
		onError: err => {
			if (err.response.data) {
				toast.error(err.response.data)
			} else {
				toast.error("Error")
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries({queryKey: queryKeys.list(customerId)})
			toast.success(translations.templatecreated)
		}
	})
}

export const useDeleteTemplate = (customerId: string, translations: Record<string, string>) => {
	const apiService = useInjection<IApiService>(ApiServiceType.IApiService)
	const dashboardApi = new DashboardApi(apiService)
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (deleteTemplateRequest: DeleteTemplateRequest) =>
			dashboardApi.deleteTemplate(deleteTemplateRequest),
		onError: err => {
			if (err.response.data) {
				toast.error(err.response.data)
			} else {
				toast.error("Error")
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries({queryKey: ['templates', customerId]})
			toast.success(translations.templatedeleted)
		}
	})
}

export const useAddMemberToDashboard = (dashboardId: string, translations: Record<string, string>) => {
	const apiService = useInjection<IApiService>(ApiServiceType.IApiService)
	const dashboardApi = new DashboardApi(apiService)
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (customerUserId: string) => dashboardApi.addMemberToDashboard(dashboardId, customerUserId),
		onError: err => {
			if (err.response.data) {
				toast.error(err.response.data)
			} else {
				toast.error("Error")
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries({queryKey: queryKeys.members(dashboardId)})
			toast.success(translations.dashboardmemberadded)
		}
	})
}

export const useRemoveMemberFromDashboard = (dashboardId: string, translations: Record<string, string>) => {
	const apiService = useInjection<IApiService>(ApiServiceType.IApiService)
	const dashboardApi = new DashboardApi(apiService)
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (dashboardMemberId: string) =>
			dashboardApi.removeMemberFromDashboard(dashboardId, dashboardMemberId),
		onError: err => {
			if (err.response.data) {
				toast.error(err.response.data)
			} else {
				toast.error("Error")
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries({queryKey: queryKeys.members(dashboardId)})
			toast.success(translations.dashboardmemberremoved)
		}
	})
}
