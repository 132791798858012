import * as React from 'react';
import Button from '@murphy-frontend/web-core/components/Button';
import CardFooter from "../MainLog/CardFooter";
import { FunctionComponent } from "react";
import { useUpsertStatusCard } from '../../mutations';
import StatusCardFormatted from './StatusCardFormatted';
import { StatusCard, UpsertStatusCard } from '../../api/StatusCardApi';
import { StatusCardStatusService } from './StatusCardStatusService';
import UpsertChildStatus from './UpsertChildStatus';
import { useCustomer } from '../../../../common/contexts/CustomerContext';

interface StatusCardExpandedContentProps {
    statusCard: StatusCard,
    dashboardId: string,
    openModal: any,
    closeModal: any,
    translations: Record<string, string>,
    statusCardStatusService: StatusCardStatusService,
}

const ChildStatusCardExpandedContent: FunctionComponent<StatusCardExpandedContentProps> = ({ statusCard, dashboardId, openModal, closeModal, translations }) => {

    const { mutate: upsertStatusCardMutation } = useUpsertStatusCard(dashboardId);
    const customer = useCustomer();

    const onClickEdit = () => {
        const upsertChildStatusCardComponent =
            <UpsertChildStatus
                translations={translations}
                dashboardId={dashboardId}
                childStatusCard={statusCard}
                onSuccess={() => closeModal()}
                currentCustomer={customer.customer}
            />;

        const configuration = {
            centerContent: false,
            hideButton: true,
            body: upsertChildStatusCardComponent,
            title: 'Edit Child Status Card',
        }
        openModal(configuration);
    }

    const onClickReactivate = () => {
        const newCard: UpsertStatusCard = { ...statusCard }
        newCard.ParentId = statusCard.ID;
        newCard.archived = false;
        newCard.UpdatedFromDashboardId = dashboardId;
        upsertStatusCardMutation(newCard);
    }

    let footer;
    if (statusCard.archived !== true) {
        const button =
            <Button
                onClick={() => onClickEdit()}>
                {translations['class-lang-button-edit']}
            </Button>
        footer = <CardFooter rightContent={button} />
    }

    if (statusCard.archived === true && !statusCard.child) {
        const button =
            <Button
                isNeutralButton
                buttonStyle="btn--unarchive"
                buttonSize="btn-with-icon"
                onClick={() => onClickReactivate()}>
                {translations['activate']}
            </Button>
        footer = <CardFooter rightContent={button} />
    }
    return <StatusCardFormatted showChildInfo={true} statusCard={statusCard} translations={translations} cardFooter={footer} />
}

export default ChildStatusCardExpandedContent;