import * as React from 'react';
import { FunctionComponent } from 'react';
import { StatusCard } from '../../api/StatusCardApi';
import { parseHtmlAndLinkifySafe } from '@murphy-frontend/common/utils';
interface StatusCardFormattedProps {
    statusCard: StatusCard,
    translations: Record<string, string>,
    cardFooter: any,
    showChildInfo?: boolean,
}

const StatusCardFormatted: FunctionComponent<StatusCardFormattedProps> = ({ statusCard, translations, cardFooter, showChildInfo }) => {

    let extraInfoPresent = false;
    if (statusCard.ProvidedBy)
        extraInfoPresent = true;
    if (statusCard.Important)
        extraInfoPresent = true;

    const extraInfo = <>



        {statusCard.ProvidedBy &&
            <>
                <br />
                <p>
                    <strong>{translations['class-lang-modal-decision-maker']}:</strong>&nbsp;{statusCard.ProvidedBy}
                </p>
            </>}
        {statusCard.Important === true &&
            <>
                <br />
                <p>
                    <strong>{translations['class-lang-modal-check-priority']}:</strong>&nbsp;{translations.yes}
                </p>
            </>}</>

    return (
        <div className="formatted-card">
            {showChildInfo && statusCard.ParentDashboardId &&
                <>
                    {statusCard.ChildCustomerName &&
                        <>
                            <p>
                                <strong>{translations.license}:</strong>&nbsp;{statusCard.ChildCustomerName}
                            </p>
                        </>
                    }

                    {statusCard.DashboardName &&
                        <>
                            <p>
                                <strong>Dashboard:</strong>&nbsp;{statusCard.DashboardName}
                            </p>
                        </>}
                </>
            }
            <p>
                <strong>{translations['class-lang-by-user']}</strong>&nbsp;{statusCard.user}
            </p>
            {statusCard.Message &&
                <>
                    <br />
                    <p>
                        {parseHtmlAndLinkifySafe(statusCard.Message)}
                    </p>
                </>}
            {extraInfoPresent
                &&
                <>
                    <br />
                    <hr></hr>
                    {extraInfo}
                </>
            }

            {cardFooter &&
                <>
                    <br />
                    <hr></hr>
                    <br />
                    {cardFooter}
                </>
            }
        </div >
    );
}

export default StatusCardFormatted;