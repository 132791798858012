import React, { useState, useEffect, FunctionComponent } from 'react';
import dayjs from 'dayjs';
import { groupBy } from '@murphy-frontend/common/utils';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import Button from '@murphy-frontend/web-core/components/Button';
import CourseCarousel from '../../common/components/TrainingComponents/CourseCarousel';
import SearchBar from '@murphy-frontend/web-core/components/SearchBar';

import { CustomerUserTrainingSessionStates, TrainingTypes } from '@murphy-frontend/common/enums';
import { customerUserTrainingSessionStates } from '@murphy-frontend/web-core/constants';
import { translateConstants } from '../../common/services/translationService';
import { getConvertedDate } from '@murphy-frontend/common/services/TimeService'
import { useGetTrainingSessions } from '../../common/api/TrainingSessions/queries';
import { useGetTrainings } from '../../common/api/Training/queries';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { useUserPreferences } from '../../common/contexts/UserPreferencesContext';
import useWindowDimensions from "@murphy-frontend/web-core/hooks/useWindowDimensions";
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';
import { useCustomer } from '../../common/contexts/CustomerContext';
import { useUser } from '../../common/contexts/UserContext';
import { useGetUserTrainingSessionsByCustomerUserId } from '../../common/api/UserTrainingSessions/queries';
import { useCreateUserTrainingSession, useEditUserTrainingSession } from '../../common/api/UserTrainingSessions/mutations';

const ExcersisePortal: FunctionComponent = () => {

  const { customer } = useCustomer();
  const { user } = useUser();
  const { openModal } = useModal();
  const { translations, language, timeZone } = useUserPreferences();
  const { currentSize } = useWindowDimensions();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [groupedTrainingSessions, setGroupedTrainingSessions] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [customerUserTrainingSessions, setCustomerUserTrainingSessions] = useState([]);
  const [courseViews, setCourseViews] = useState([]);
  const [userSessionStates, setUserSessionStates] = useState([]);

  const [filteredCourseViews, setFilteredCourseViews] = useState([]);
  const [currentSearchText, setCurrentSearchText] = useState('');

  const {
    isLoading: trainingSessionsIsLoading, isError: trainingSessionsIsError,
    data: trainingSessionsData, error: trainingSessionsError,
  } = useGetTrainingSessions({ customerId: customer.Id });

  const {
    isLoading: trainingsIsLoading, isError: trainingsIsError,
    data: trainingsData, error: trainingsError,
  } = useGetTrainings({ customerUserId: user.CustomerUserId });

  const {
    isLoading: customerUserTrainingSessionsIsLoading, isError: customerUserTrainingSessionsIsError,
    data: customerUserTrainingSessionsData, error: customerUserTrainingSessionsError,
  } = useGetUserTrainingSessionsByCustomerUserId(user.CustomerUserId);

  const { mutate: editUserTrainingSession, isPending: isEditUserTrainingSessionLoading } = useEditUserTrainingSession(translations);
  const { mutate: createUserTrainingSession, isPending: isCreateUserTrainingSessionLoading } = useCreateUserTrainingSession(translations);

  const onClickUnbook = (id) => {
    const editUserTrainingSessionRequest = {
      Id: id,
      NewStateId: CustomerUserTrainingSessionStates.Cancelled,
    };
    editUserTrainingSession(editUserTrainingSessionRequest);
  };

  const onClickBook = (trainingSessionId) => {
    const createUserTrainingSessionRequest = {
      TrainingSessionId: trainingSessionId,
      CustomerUserId: user.CustomerUserId,
    };

    createUserTrainingSession(createUserTrainingSessionRequest);
  };

  const createCourseViews = (trainingsRaw, groupedTrainingSessionsRaw, customerUserTrainingSessionsRaw, states, tz) => {
    const newCourseViews = groupedTrainingSessionsRaw.map((p) => {
      const matchingCustomerUserTrainingSessions = customerUserTrainingSessionsRaw
        .filter((q) => q.TrainingId === p.Id
          && q.TrainingSessionStateId !== CustomerUserTrainingSessionStates.Cancelled);

      const matchingTraining = trainingsRaw.filter((q) => q.Id === p.Id)[0];
      const sortedTrainingSessions = [...p.trainingSessions.sort((a, b) => new Date(a.StartDateTimeUtc) - new Date(b.StartDateTimeUtc))];

      const courseView = {
        id: p.Id,
        trainingTypeId: matchingTraining.TrainingTypeId,
        name: matchingTraining.Name,
        description: matchingTraining.Description,
        trainingSessions: sortedTrainingSessions,
        backGroundUrl: matchingTraining.ImageUrl,
      };

      if (matchingCustomerUserTrainingSessions.length > 0) {
        courseView.userTrainingSessions = matchingCustomerUserTrainingSessions.map((r) => ({
          userTrainingSessionId: r.ID,
          trainingSessionStateId: r.TrainingSessionStateId,
          urlToMeeting: r.UrlToMeeting,
          trainingSessionStateName: states.filter((q) => q.id === r.TrainingSessionStateId)[0].value,
          trainingSessionStartDate: getConvertedDate(dayjs.utc(r.StartDateTimeUtc), tz),
          trainingSessionEndDate: getConvertedDate(dayjs.utc(r.EndDateTimeUtc), tz),
        }));
      } else {
        courseView.userTrainingSessions = [];
      }
      return courseView;
    });
    return newCourseViews;
  };

  const groupTrainingSessions = (sessions) => {
    const newGroupedTrainingSessions = groupBy(sessions, 'TrainingId');
    const mappedCourses = Object.values(newGroupedTrainingSessions).map((trainingSessions) => ({
      Id: trainingSessions[0].TrainingId,
      Name: trainingSessions[0].Name,
      Description: trainingSessions[0].Desc,
      trainingSessions,
    }));

    return mappedCourses;
  };

  useEffect(() => {
    const translatedUserSessionStates = translateConstants(language, customerUserTrainingSessionStates);
    setUserSessionStates(translatedUserSessionStates);
    return () => {
      setUserSessionStates([]);
    };
  }, [language]);

  useEffect(() => {
    if (!trainingsIsLoading) {
      setTrainings(trainingsData);
    }
    return () => {
      setTrainings([]);
    };
  }, [trainingsData, trainingsIsLoading]);

  useEffect(
    () => {
      if (!trainingSessionsIsLoading) {
        const filteredBookableTrainingSessions = trainingSessionsData
          .filter((p) => dayjs.utc(p.StartDateTimeUtc) > dayjs.utc());
        const mappedTrainingsSessions = groupTrainingSessions(filteredBookableTrainingSessions);
        setGroupedTrainingSessions(mappedTrainingsSessions);
      }
    },
    [trainingSessionsData, trainingSessionsIsLoading],
  );

  useEffect(
    () => {
      if (!customerUserTrainingSessionsIsLoading) {
        setCustomerUserTrainingSessions(customerUserTrainingSessionsData);
      }
    },
    [customerUserTrainingSessionsData, customerUserTrainingSessionsIsLoading],
  );

  useEffect(
    () => {
      if (groupedTrainingSessions && userSessionStates.length > 0 && timeZone && trainings.length > 0) {
        const newCourseViews = createCourseViews(trainings, groupedTrainingSessions, customerUserTrainingSessions, userSessionStates, timeZone);
        const filteredCourseViews = newCourseViews.filter((p) => p.trainingTypeId === TrainingTypes.Excersise);
        setCourseViews(filteredCourseViews);
      }
    },
    [customerUserTrainingSessions, groupedTrainingSessions, userSessionStates, trainings, timeZone],
  );

  useEffect(() => {
    if (courseViews) {
      let courseViewsCopy = [...courseViews];

      if (currentSearchText) {
        const searchValue = currentSearchText.toUpperCase();
        courseViewsCopy = courseViewsCopy.filter((p) => p.name.toUpperCase().startsWith(searchValue));
      }
      setFilteredCourseViews(courseViewsCopy);
    }
  }, [courseViews, currentSearchText]);

  function ErrorMessage() {
    return <p>{error}</p>;
  }

  useEffect(() => {
    if (error) {
      const modalConf: ModalConfiguration = {
        title: 'Error',
        body: <ErrorMessage />,
        buttonText: 'Ok',
        type: 'error',
        okCallback: () => { setError(null); },
      };

      openModal(modalConf);
    }
  }, [error]);

  const onSearchChanged = (event) => {
    const newValue = event.target.value;
    setCurrentSearchText(newValue);
  };

  if (trainingSessionsIsLoading || trainingsIsLoading || customerUserTrainingSessionsIsLoading) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }

  const onClickRequestExcersise = () => {
    const email = 'mats.bohman@murphysolution.com';
    window.location.href = `mailto:${email}?subject=Beställning av övning`;
  };

  if (isLoading || isCreateUserTrainingSessionLoading || isEditUserTrainingSessionLoading) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }

  return (
    <div className="pbs-wrapper">
      <h1>{translations.excercisesmodulename}</h1>
      <span className="gradient" />

      <div className="pbs-grid">
        <div className="form-fieldset response-form">
          <p>
            Saknar du en specifik övning i tabellen nedan?
            Eller har du önskemål om att beställa en övning utformad speciellt för er verksamhet?
            Klicka på knappen nedan för att initiera en sådan beställning!
          </p>
          <div>
            <Button isIconButton buttonStyle="btn--add" buttonSize="btn-with-icon" onClick={onClickRequestExcersise}><span className="btn-with-icon-text">BESTÄLL ÖVNING</span></Button>
          </div>
        </div>
        <span className="gradient" />
        <section className="header-container vertical-aligner">

          <div className="three">
            <SearchBar val={currentSearchText} handleChange={onSearchChanged} placeholder={translations['placeholder-lang-search-action']} />
          </div>
        </section>
        <section className="user-table-container">
          <div className="one">
            <h4>
              {translations.excercisesmodulename}
            </h4>
          </div>
          <CourseCarousel
            translations={translations}
            courseViews={filteredCourseViews}
            onClickBook={onClickBook}
            onClickUnbook={onClickUnbook}
            currentScreenSize={currentSize}
            timeZone={timeZone}
            language={language}
          />

        </section>
      </div>
    </div>
  );
}

export default ExcersisePortal;
