import React from "react";
import { FunctionComponent } from "react";

interface RequirementPyramidContentProps {
    content: JSX.Element | null;
}

const RequirementPyramidContent: FunctionComponent<RequirementPyramidContentProps> = (
    {
        content
    }
) => {
    return (<div className="content-container">
        <div className="pyramid-content">
            {content}
        </div>
    </div>);
}

export default RequirementPyramidContent;