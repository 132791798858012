import * as React from 'react'
import DashboardTable from '@murphy-frontend/web-core/components/DashboardTable'
import { useState, useEffect } from 'react';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { StatusCard, UpsertStatusCard } from '../../api/StatusCardApi';
import { useUpsertStatusCard, useUpsertStatusCardOtherDashboard } from '../../mutations';
import { useGetDashboardStatusCards } from '../../queries';
import StatusCardsTableRow from './StatusCardsTableRow';
import StatusCardsTableHeader from './StatusCardsTableHeader';
import DashboardSearch from '@murphy-frontend/web-core/components/DashboardSearch';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import { StatusCardStatusService } from './StatusCardStatusService';
import ChildStatusCardsTableRow from './ChildStatusCardsTableRow';
import ParentStatusCardsTableRow from './ParentStatusCardsTableRow';

type StatusCardsTableProps = {
    dashboardId: string,
    translations: Record<string, string>,
    statusCardStatusService: StatusCardStatusService
}

const StatusCardsTable = ({ statusCardStatusService, dashboardId, translations }: StatusCardsTableProps) => {
    const [statusCards, setStatusCards] = useState<StatusCard[]>([]);
    const [filteredStatusCards, setFilteredStatusCards] = useState<StatusCard[]>([]);
    const [currentSearchText, setCurrentSearchText] = useState('');
    const [searchActive, setSearchActive] = useState(false);

    const [rows, setRows] = useState([]);

    const { mutate: upsertStatusCardMutation, isPending: upsertStatusIsLoading } = useUpsertStatusCard(dashboardId);
    const { mutate: upsertStatusCardMutationOtherDashboard, isPending: upsertOtherIsLoading } = useUpsertStatusCardOtherDashboard();
    const { openModal, closeModal } = useModal();

    const { data: statusCardsData, error: statusCardsError,
        isError: statusCardsIsError, isLoading: statusCardsIsLoading } = useGetDashboardStatusCards(dashboardId);

    function statusCardSortFunction(a: StatusCard, b: StatusCard) {
        return (+b.Important) - (+a.Important) || prioritizeStatus(a.Status) - prioritizeStatus(b.Status) || a.Title.localeCompare(b.Title);
    }

    function prioritizeStatus(status: number) {
        return statusCardStatusService.getStatus(status).Priority;
    }

    useEffect(() => {
        if (statusCardsIsLoading === false) {
            const filteredCards = statusCardsData.filter(p => p.archived === false)
                .sort(statusCardSortFunction);
            setStatusCards(filteredCards);
            setFilteredStatusCards(filteredCards)
        }
    }, [statusCardsIsLoading, statusCardsData])

    const onClickUpdateStatus = (id: number, status: number) => {
        const card = statusCards.find(p => p.ID === id);
        const updatedCard: UpsertStatusCard = {
            Title: card.Title,
            Message: card.Message,
            Status: status,
            ProvidedBy: card.ProvidedBy,
            Important: card.Important,
            ParentId: id,
            ParentDashboardId: card.ParentDashboardId,
            UpdatedFromDashboardId: dashboardId,
        }

        if (card && card.DashboardId) {
            updatedCard.DashboardId = card.DashboardId;
            upsertStatusCardMutationOtherDashboard(updatedCard);
        } else {
            upsertStatusCardMutation(updatedCard);
        }
    }

    const onToggleSearch = () => {
        setSearchActive(!searchActive);
    }

    useEffect(() => {
        const searchValue = currentSearchText.toUpperCase();

        const filteredCards = filteredStatusCards.filter((p) =>
            (p.Title && p.Title.toUpperCase().includes(searchValue)) ||
            (p.ProvidedBy && p.ProvidedBy.toUpperCase().includes(searchValue)) ||
            (p.Message && p.Message.toUpperCase().includes(searchValue)) ||
            (p.user && p.user.toUpperCase().includes(searchValue))
        );

        const tableRows = filteredCards.map((statusCard) => {
            const commonProps = {
                statusCardStatusService,
                translations,
                statusCard,
                openModal,
                closeModal,
                dashboardId,
            };

            if (statusCard.ParentDashboardId === dashboardId) {
                return <ChildStatusCardsTableRow {...commonProps} />;
            } else if (statusCard.ParentDashboardId && statusCard.ParentDashboardId !== dashboardId) {
                return <ParentStatusCardsTableRow {...commonProps} updateStatus={onClickUpdateStatus} />;
            } else {
                return <StatusCardsTableRow {...commonProps} updateStatus={onClickUpdateStatus} />;
            }
        });

        setRows(tableRows);

        return () => {
            setRows([]);
        };
    }, [filteredStatusCards, currentSearchText, translations, statusCardStatusService, dashboardId]);

    const subHeader = <div>
        <DashboardSearch
            value={currentSearchText}
            setValue={setCurrentSearchText}
            placeholder={translations['placeholder-lang-search-action']}
            onClickClear={() => setCurrentSearchText('')} />
    </div>

    const header = <StatusCardsTableHeader
        statusCardStatusService={statusCardStatusService}
        translations={translations}
        dashboardId={dashboardId}
        openModal={openModal}
        closeModal={closeModal}
        toggleSearch={onToggleSearch}
    />

    if (statusCardsIsLoading || upsertOtherIsLoading || upsertStatusIsLoading) {
        return (<div className="spinner-container-global-center"><Spinner /></div>);
    }

    return <DashboardTable
        rows={rows}
        subHeader={searchActive && subHeader}
        header={header} />
}

export default StatusCardsTable;