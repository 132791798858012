import * as React from "react";
import { DashboardEntityTypes } from "@murphy-frontend/common/enums";
import { getConvertedDateDayjs } from '@murphy-frontend/common/services/TimeService';
import { BackGround } from "../../../api/BackGroundApi";
import BackGroundExpandedContent from "../../BackGround/BackGroundExpandedContent";
import { MainLogCard } from "../models";
import { BaseCardProps } from "./MainLogCardFactory";

export interface BackGroundMainLogCardProps extends BaseCardProps {
    backGround: BackGround,
}

export const BackGroundMainLogCard =
    ({ translations, backGround, timeZone, dashboardId, openModal, closeModal }: BackGroundMainLogCardProps): MainLogCard => {
        const date = getConvertedDateDayjs(backGround.CreatedDateTime, timeZone);
        const expandedContent =
            <BackGroundExpandedContent
                backGround={backGround}
                dashboardId={dashboardId}
                openModal={openModal}
                closeModal={closeModal}
                translations={translations}
            />

        const title = translations['class-lang-title-infocard-background'];
        const searchableContent = [];
        searchableContent.push(title);
        if (backGround.AffectedMessage)
            searchableContent.push(backGround.AffectedMessage);
        if (backGround.ExtentMessage)
            searchableContent.push(backGround.ExtentMessage);
        if (backGround.HowMessage)
            searchableContent.push(backGround.HowMessage);
        if (backGround.WhatMessage)
            searchableContent.push(backGround.WhatMessage);
        if (backGround.WhenMessage)
            searchableContent.push(backGround.WhenMessage);
        if (backGround.user)
            searchableContent.push(backGround.user);

        const logCard: MainLogCard = {
            ID: `${backGround.ID}-${DashboardEntityTypes.BackGround}`,
            Title: title,
            isArchived: backGround.archived,
            isDecision: false,
            Date: date,
            SearchableContent: searchableContent,
            User: backGround.user,
            DateWithoutTime: date.format("YYYY-MM-DD"),
            Time: date.format("HH:mm"),
            EntityType: DashboardEntityTypes.BackGround,
            ExpandedContent: expandedContent,
            OriginalCard: backGround,
        }
        return logCard;
    }