import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';
import { convertBreaksToNewLine, convertNewLinesToBreak } from '@murphy-frontend/common/utils';
import { ActionsCard, PatchActionsCard, UpsertActionsCard } from '../../api/ActionsCardApi';
import { usePatchActionsCard, useUpsertActionsCard } from '../../mutations';
import {
    usePostDashboardAlertUsers,
} from '@murphy-frontend/common/api/Dashboard/Mutations';
import { useGetDashboardAlertUsers } from '@murphy-frontend/common/api/Dashboard/Queries';
import { PostDashboardAlertUsersRequest } from '@murphy-frontend/common/interfaces/IDashboardApi';

import ActionsCardsForm, { ActionsCardFormDto } from './ActionsCardsForm';
import { TimeZoneType, getConvertedDateDayjs } from "@murphy-frontend/common/services/TimeService"

interface UpsertActionsCardProps {
    dashboardId: string,
    actionsCard?: ActionsCard,
    onSuccess: () => void,
    translations: Record<string, string>,
    timeZone: TimeZoneType,
}

const UpsertActionsCardComponent: FunctionComponent<UpsertActionsCardProps> = ({ dashboardId, actionsCard, onSuccess, translations, timeZone }) => {

    const currentTimeUtc = new Date().toISOString();

    const { mutate: upsertActionsCardMutation, isPending: upsertIsLoading } = useUpsertActionsCard(dashboardId);
    const { mutate: patchActionsCardMutation, isPending: patchIsLoading } = usePatchActionsCard(dashboardId);
    const { mutate: postDashboardAlertUsersMutation, isPending: postDashboardAlertUsersIsLoading } = usePostDashboardAlertUsers(dashboardId);

    const { data: dashboardAlertUsers, isLoading: useGetDashboardAlertUsersIsLoading } = useGetDashboardAlertUsers(dashboardId);

    const [actionsCardFormData, setActionsCardFormData] = useState<ActionsCardFormDto>(null);
    const [latestSentNotificationText, setLatestSentNotificationText] = useState<string>(null);

    const onSubmitHandler = (actionsCardFormDto: ActionsCardFormDto) => {

        const upsertActionCard: UpsertActionsCard = {
            Title: actionsCardFormDto.Title,
            Message: actionsCardFormDto.Message ? convertNewLinesToBreak(actionsCardFormDto.Message) : null,
            Status: actionsCardFormDto.Status,
            ProvidedBy: actionsCardFormDto.ProvidedBy,
            ProvidedByCustomerUserId: null,
            Owner: actionsCardFormDto.Owner,
            isDecision: actionsCardFormDto.isDecision,
            Important: actionsCardFormDto.Important,
            AlternatedTime: actionsCardFormDto?.AlternatedTime,
            isCommunication: actionsCardFormDto?.isCommunication,
            LatestEmailNotificationSent: actionsCardFormDto.isEmailChecked ? currentTimeUtc : null,
            LatestSmsNotificationSent: actionsCardFormDto.isMobileChecked ? currentTimeUtc : null,
            LatestPushNotificationSent: actionsCardFormDto.isNotificationChecked ? currentTimeUtc : null,
        }

        if (actionsCardFormDto.ProvidedByIsUser === true) {
            const dashboardAlertUser = dashboardAlertUsers?.find(x => x.Id === actionsCardFormDto.ProvidedBy);
            upsertActionCard.ProvidedByCustomerUserId = dashboardAlertUser?.Id;
            upsertActionCard.ProvidedBy = dashboardAlertUser?.Name || actionsCardFormDto.ProvidedBy;

            if (actionsCardFormDto.isEmailChecked || actionsCardFormDto.isMobileChecked || actionsCardFormDto.isNotificationChecked) {
                const postDashboardAlterUsersRequest: PostDashboardAlertUsersRequest = {
                    CustomerUser: actionsCardFormDto.ProvidedBy,
                    email: actionsCardFormDto.isEmailChecked,
                    sms: actionsCardFormDto.isMobileChecked,
                    push: actionsCardFormDto.isNotificationChecked,
                }
                postDashboardAlertUsersMutation(postDashboardAlterUsersRequest);
            }
        }

        if (actionsCard && actionsCard.ID) {
            upsertActionCard.ParentId = actionsCard.ID;
        }

        upsertActionsCardMutation(upsertActionCard, {
            onSuccess: () => {
                onSuccess();
            }
        });
    };

    const onClickArchiveActionCard = (id: number) => {
        const patchCard: PatchActionsCard = {
            Id: id,
            archived: true,
        }

        patchActionsCardMutation(patchCard, {
            onSuccess: () => {
                onSuccess();
            }
        });
    }

    useEffect(() => {
        if (actionsCard && timeZone) {
            const actionsCardFormDto: ActionsCardFormDto = {
                ID: actionsCard.ID,
                Title: actionsCard.Title,
                Message: actionsCard.Message ? convertBreaksToNewLine(actionsCard.Message) : null,
                Status: actionsCard.Status,
                ProvidedBy: actionsCard.ProvidedBy,
                ProvidedByCustomerId: actionsCard.ProvidedByCustomerUserId,
                ProvidedByIsUser: actionsCard.ProvidedByCustomerUserId ? true : false,
                Owner: actionsCard.Owner,
                isDecision: actionsCard.isDecision,
                Important: actionsCard.Important,
                AlternatedTime: actionsCard.AlternatedTime,
                isCommunication: actionsCard.isCommunication,
            }
            setActionsCardFormData(actionsCardFormDto);
            const latestSentNotification = [actionsCard.LatestEmailNotificationSent, actionsCard.LatestSmsNotificationSent, actionsCard.LatestPushNotificationSent].filter(x => x).sort().reverse()[0];
            if (latestSentNotification) {
                const latestSentNotificationText = `${translations.latestnotificationsent} ${getConvertedDateDayjs(latestSentNotification, timeZone).format('YYYY-MM-DD HH:mm')}`;
                setLatestSentNotificationText(latestSentNotificationText);
            }
        }

    }, [actionsCard, dashboardAlertUsers, timeZone])

    return (
        <div>
            <ActionsCardsForm
                translations={translations}
                onClickArchive={onClickArchiveActionCard}
                onSubmit={onSubmitHandler}
                actionsCard={actionsCardFormData}
                dashboardAlertUsers={dashboardAlertUsers}
                isLoading={upsertIsLoading || patchIsLoading || postDashboardAlertUsersIsLoading || useGetDashboardAlertUsersIsLoading}
                latestSentNotificationText={latestSentNotificationText}
            />
        </div>
    );
}

export default UpsertActionsCardComponent;