import dayjs from "dayjs";
import 'dayjs/locale/se';

// 1 is local and 2 is utc
export type TimeZoneType = "1" | "2";

export const getConvertedDateDayjs = (date: any, timeZone: TimeZoneType) => {
  const dateCopy = dayjs.utc(date);
  return timeZone == '2' ? dateCopy : dateCopy.local();
};

export const getConvertedDate = (date: any, timeZone: TimeZoneType) => {
  const dateCopy = getConvertedDateDayjs(date, timeZone);
  return dateCopy.format('YYYY-MM-DD HH:mm');
};

export const getUtcDate = (date: any, timeZone: TimeZoneType) => {
  if (timeZone == '1') {
    return new Date(dayjs(date).format());
  }
  return new Date(dayjs(date).utc(true).format());
};

export const getUtcOffset = (timeZone: TimeZoneType) => {
  let utcOffsetInMinutes = 0;
  if (timeZone == '1') {
    utcOffsetInMinutes = dayjs().utcOffset();
  }
  return utcOffsetInMinutes;
};

export const getConvertedDateWithoutTime = (date: any) => {
  dayjs.locale('se')
  const dateCopy = dayjs(date);
  return dateCopy.format("YYYY-MM-DD");
};

export const convertDateToUTC = (date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const convertUTCDateToLocalDate = (date) => {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
}

export const compareMonth = (date, timeZone: TimeZoneType, monthOpt: string) => {
  const dateCopy = getConvertedDateDayjs(date, timeZone);
  if (monthOpt === "1") {
    if (dateCopy.format('YYYY-MM') === dayjs().format("YYYY-MM"))
      return true
    else
      return false
  } else if (monthOpt === "2") {
    if ( dayjs().diff(dateCopy, "day") <= 90) {
      return true
    } else
      return false
  } else
  return true

}
