import React from 'react';
import { FunctionComponent } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useAuth } from '@murphy-frontend/web-core/features/auth/WebAuthProvider';
import Button from '@murphy-frontend/web-core/components/Button';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import { useUserPreferences } from '../../common/contexts/UserPreferencesContext';
import { auth, onPremMode } from '../../common/variables';
import PersistenceType, {
	IPersistenceService,
} from '@murphy-frontend/common/interfaces/IPersistenceService';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import AuthServiceType, {
	IAuthService,
} from '@murphy-frontend/common/interfaces/IAuthService';
import UsersApiType, { IUsersApi } from '@murphy-frontend/common/interfaces/IUsersApi';

const LoginPage: FunctionComponent = () => {
	const { translations } = useUserPreferences();
	const webAuth = useAuth();
	const location = useLocation();

	let from = location.state?.from?.pathname || '/portal';

	const [loginName, setLoginName] = React.useState<string>('');
	const [errorMessage, setErrorMessage] = React.useState<string>('');

	const onChangeLoginName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLoginName(e.target.value);
	};

	const persistenceService = useInjection<IPersistenceService>(
		PersistenceType.IPersistenceService,
	);
	const authService = useInjection<IAuthService>(
		AuthServiceType.IAuthService,
	);

	const usersApi = useInjection<IUsersApi>(UsersApiType.IUsersApi);

	if (!translations) {
		return (
			<div className='spinner-container-global-center'>
				<Spinner />
			</div>
		);
	}

	const onClickLogin = async (e: React.MouseEvent<HTMLButtonElement>) => {
		setErrorMessage("");
		e.preventDefault();
		await persistenceService.clearNonPersistentItems()
		from = from != '/login' ? from : '/portal';

		if (auth.hideLoginPageEmail && auth.hideLoginPageEmail === 'true') {
			return webAuth.signIn(from, null, null);
		}

		if (!loginName) {
			setErrorMessage("Please enter a email");
			return;
		}

		usersApi.getUserProvider(loginName).then((provider) => {
			if (provider.data) {
				webAuth.signIn(from, provider.data, loginName);
			} else {
				setErrorMessage("Invalid email");
				return;
			}
		});
	};

	const onClickForgotPassword = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();

		if (auth.showForgotPassword === 'true') {
			authService.forgotPassword();
		}
	};

	return (
		<div className='login-flex-container'>
			<div className='login-left-container'>
				<div className='login-buttons-container'>
					<div>
						<img
							className='murphy-logo'
							src={require('../../assets/img/LOGO.png')}
							alt='Murphy LOGO'
						/>
					</div>
					{(auth.hideLoginPageEmail && auth.hideLoginPageEmail === 'true') ? null : <input
						autoComplete='email'
						title={translations['lang-label-email']}
						placeholder={translations['lang-label-email']}
						id="email"
						type='email'
						name='email'
						required
						value={loginName}
						onChange={onChangeLoginName}
					/>}
					{errorMessage ? <div className="input-error-message">{errorMessage}</div> : null}
					<Button title='login' buttonSize={'btn--large'} onClick={onClickLogin}>
						<span>{translations['personnummer-form-link']}</span>
					</Button>

					{auth.showForgotPassword === 'true' && (
						<div className='link' onClick={onClickForgotPassword}>
							{translations['forgot-password']}
						</div>
					)}
				</div>
			</div>
			<div className='login-right-container'>
				<div>
					<div>
						<img
							className='preaparatus-pic'
							src={require('../../assets/img/Praeparatus_Supervivet_Practise_Risk.jpg')}
							alt='Praeparatus Supervivet'
						/>
					</div>
					<div className='login-page-text'>
						<h3 className='login-page-title'>
							<strong className='login-page-title-bold'>
								Murphy{' '}
							</strong>
							- a digital crisis management system
						</h3>
						<p>
							Murphy Solution offers a software that upgrades
							and simplifies the entire crisis management experience.
						</p>
						<p>
							The software supports customers through planning, exercising,
							management of crises &amp; incidents, as well as
							evaluation.
						</p>
						<p>
							Contact us for more information or support:{' '}
							<a href='mailto:support@murphysolution.com'>
								support@murphysolution.com
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
