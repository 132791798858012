import React, {useState, useEffect} from "react";

interface IFIlterCourseBtn {
  name: string;
  filterAction: () => void;
  allCourses?: any;
  filterCourse?: number[];
  selectable: boolean;
  defaultSelected?: boolean;
  languageDefault?: number;
}
export const FilterCourseBtn = ({ name, selectable, filterAction, defaultSelected, languageDefault }: IFIlterCourseBtn) => {
  const [selected, setSelected] = useState<boolean>(false)
  const buttonClickHandler = () => {
    setSelected(!selected);
    filterAction();
  };

  useEffect(() => {
    if(defaultSelected) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [defaultSelected])

  return (
    <div
      onClick={() => buttonClickHandler()}
      className={`filter-control-box education-admin-courses-filter-box ${!selected && !defaultSelected ? '' : selectable && selected ? 'education-admin-courses-filter-box_selected' : ''}`}
    >
      <p>{name}</p>
    </div>
  );
};
