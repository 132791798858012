import { useQuery } from "@tanstack/react-query";
import { useInjection } from "../../contexts/InversifyContext";
import { DashboardEntityTypes } from "../../enums";
import DashboardApiType, {
  IDashboardApi,
} from "../../interfaces/IDashboardApi";
import QueryKeys from "./QueryKeys";

export const useGetDashboard = (dashboardId: string | undefined) => {
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );
  return useQuery({
    queryKey: QueryKeys.dashboardentity(dashboardId, DashboardEntityTypes.Dashboard),
    queryFn: () => dashboardApi.getDashboardById(dashboardId),
    enabled: !!dashboardId, staleTime: Infinity
  });
};

export const useGetDashboards = (
  customerId: string | undefined | null
) => {
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );
  return useQuery({
    queryKey: QueryKeys.list(customerId),
    queryFn: () => dashboardApi.getDashboards(customerId),
    enabled: dashboardApi != null,
    retry: dashboardApi != null ? 3 : false,
    staleTime: Infinity,
  });
};

export const useGetDashboardTemplates = (customerId: string) => {
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );
  return useQuery({
    queryKey: QueryKeys.template(customerId),
    queryFn: () => dashboardApi.getTemplatesByCustomerId(customerId),
    enabled: !!customerId, staleTime: 60 * 60 * 1000
  });
}


export const useGetDashboardGoals = (
  dashboardId: string | undefined | null
) => {
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );

  return useQuery({
    queryKey: QueryKeys.dashboardentity(dashboardId, DashboardEntityTypes.Goals),
    queryFn: () => dashboardApi.getDashboardGoals(dashboardId),
    enabled: dashboardApi != null,
    retry: dashboardApi != null ? 3 : false,
    staleTime: 100,
  });
};

export const useGetDashboardBackGround = (
  dashboardId: string | undefined | null
) => {
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );
  return useQuery({
    queryKey: QueryKeys.dashboardentity(dashboardId, DashboardEntityTypes.BackGround),
    queryFn: () => dashboardApi.getDashboardBackgrounds(dashboardId),
    enabled: dashboardApi != null, staleTime: Infinity
  });
};

export const useGetDashboardAssumptions = (dashboardId: string | undefined) => {
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );
  return useQuery({
    queryKey: QueryKeys.dashboardentity(dashboardId, DashboardEntityTypes.Assumption),
    queryFn: () => dashboardApi.getDashboardAssumptions(dashboardId),
    enabled: dashboardApi != null, staleTime: Infinity
  });
};

export const useGetDashboardActions = (dashboardId: string | undefined) => {
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );
  return useQuery({
    queryKey: QueryKeys.dashboardentity(dashboardId, DashboardEntityTypes.ActionsCard),
    queryFn: () => dashboardApi.getDashboardActions(dashboardId),
    enabled: !!dashboardId, staleTime: Infinity
  });
};

export const useGetDashboardStatuses = (dashboardId: string | undefined) => {
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );
  return useQuery({
    queryKey: QueryKeys.dashboardentity(dashboardId, DashboardEntityTypes.StatusCard),
    queryFn: () => dashboardApi.getDashboardStatuses(dashboardId),
    enabled: !!dashboardId, staleTime: Infinity
  });
};

export const useGetDashboardLogs = (dashboardId: string | undefined) => {
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );
  return useQuery({
    queryKey: QueryKeys.dashboardentity(dashboardId, DashboardEntityTypes.DashboardLog),
    queryFn: () => dashboardApi.getDashboardLogItems(dashboardId),
    enabled: !!dashboardId, staleTime: Infinity
  });
};

export const useGetDashboardFiles = (
  dashboardId: string | undefined | null
) => {
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );
  return useQuery({
    queryKey: QueryKeys.dashboardentity(dashboardId, DashboardEntityTypes.DashboardFile),
    queryFn: () => dashboardApi.getDashboardFiles(dashboardId),
    enabled: !!dashboardApi,
    staleTime: Infinity,
  });
};

export const useGetDashboardInformation = (dashboardId: string | undefined) => {
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );
  return useQuery({
    queryKey: QueryKeys.dashboardentity(
      dashboardId,
      DashboardEntityTypes.InformationCard
    ),
    queryFn: () => dashboardApi.getDashboardInformation(dashboardId),
    enabled: !!dashboardId, staleTime: Infinity
  });
};

export const useGetDashboardParties = (dashboardId: string | undefined) => {
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );
  return useQuery({
    queryKey: QueryKeys.dashboardentity(dashboardId, DashboardEntityTypes.Party),
    queryFn: () => dashboardApi.getDashboardParty(dashboardId),
    enabled: !!dashboardId, staleTime: Infinity
  });
}

export const useGetDashboardPartyPeople = (dashboardId: string | undefined) => {
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );
  return useQuery({
    queryKey: QueryKeys.dashboardentity(dashboardId, DashboardEntityTypes.PartyPeople),
    queryFn: () => dashboardApi.getDashboardPartyPeople(dashboardId),
    enabled: !!dashboardId, staleTime: Infinity
  });
}

export const useGetDashboardAlertUsers = (dashboardId: string | undefined) => {
  const dashboardApi = useInjection<IDashboardApi>(
    DashboardApiType.IDashboardApi
  );
  return useQuery({
    queryKey: QueryKeys.dashboardentity(dashboardId, DashboardEntityTypes.AlertUser),
    queryFn: () => dashboardApi.getDashboardAlertUsers(dashboardId),
    enabled: !!dashboardId, staleTime: Infinity
  });
}

