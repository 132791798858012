import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { useUserPreferences } from '../../../../common/contexts/UserPreferencesContext';
import { useGetAssumptions, useGetBackGrounds, useGetDashboardActionsCards, useGetDashboardFiles, useGetDashboardInformationCards, useGetDashboardLogs, useGetDashboardStatusCards, useGetGoals, useGetParties, useGetPartyPeople } from '../../queries';
import DashboardTable from '@murphy-frontend/web-core/components/DashboardTable';
import { MainLogCard } from './models';
import MainLogRow from './MainLogRow';
import FilterControl, { FilterType } from '@murphy-frontend/web-core/components/FilterControl';
import { groupBy } from '@murphy-frontend/common/utils';
import { useGetDashboard } from '../../../../common/api/Dashboards/queries';
import MurphyDatePicker from '@murphy-frontend/web-core/components/MurphyDatePicker';
import DashboardSearch from '@murphy-frontend/web-core/components/DashboardSearch';
import MainLogTableHeader from './MainLogTableHeader';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import ExportLog from './ExportLog';
import { MainLogCardFactory } from './MainLogCards/MainLogCardFactory';
import { StatusCardStatusService } from '../StatusCards/StatusCardStatusService';
import { useUser } from '../../../../common/contexts/UserContext';
interface MainLogProps {
    dashboardId: string,
    translations: Record<string, string>,
    statusCardStatusService: StatusCardStatusService
}

const MainLog: FunctionComponent<MainLogProps> = ({ dashboardId, translations, statusCardStatusService }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isMainLogCardsLoading, setIsMainLogCardsLoading] = useState<boolean>(true);
    const [isFilteredCardsLoading, setIsFilteredCardsLoading] = useState<boolean>(true);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
    const { timeZone, language } = useUserPreferences();
    const { user } = useUser();

    const [allCardTypes, setAllCardTypes] = useState<FilterType[]>([]);
    const [markAllCardTypesToggled, setMarkAllCardTypesToggled] = useState<boolean>(true);
    const [allGeneralFilters, setAllGeneralFilters] = useState<FilterType[]>([]);
    const [markAllGeneralFiltersToggled, setMarkAllGeneralFiltersToggled] = useState<boolean>(false);
    const [fromDate, setFromDate] = useState<Date>();
    const [toDate, setToDate] = useState<Date>();
    const [currentSearchText, setCurrentSearchText] = useState<string>('');

    const [logCards, setLogCards] = useState<MainLogCard[]>([]);
    const [filteredLogCards, setFilteredLogCards] = useState<MainLogCard[]>([]);
    const [rows, setRows] = useState([]);

    const { openModal, closeModal } = useModal();

    const { data: actionsCardsData, error: actionsCardsError,
        isError: actionsCardsIsError, isLoading: actionsCardsIsLoading } = useGetDashboardActionsCards(dashboardId);

    const { data: statusCardsData, error: statusCardsError,
        isError: statusCardsIsError, isLoading: statusCardsIsLoading } = useGetDashboardStatusCards(dashboardId);

    const { data: informationCardsData, error: informationCardsError,
        isError: informationCardsIsError, isLoading: informationCardsIsLoading } = useGetDashboardInformationCards(dashboardId);

    const { data: filesData, error: filesError,
        isError: filesIsError, isLoading: filesIsLoading } = useGetDashboardFiles(dashboardId);

    const { data: partyData, error: partyError,
        isError: partyIsError, isLoading: partyIsLoading } = useGetParties(dashboardId);

    const { data: partyPeopleData, error: partyPeopleError,
        isError: partyPeopleIsError, isLoading: partyPeopleIsLoading } = useGetPartyPeople(dashboardId);

    const { data: goalsData, error: goalsError,
        isError: goalsIsError, isLoading: goalsIsLoading } = useGetGoals(dashboardId);

    const { data: assumptionData, error: assumptionError,
        isError: assumptionIsError, isLoading: assumptionIsLoading } = useGetAssumptions(dashboardId);

    const { data: backGroundData, error: backGroundError,
        isError: backGroundIsError, isLoading: backGroundIsLoading } = useGetBackGrounds(dashboardId);

    const { data: dashboardData, error: dashboardError,
        isError: dashboardIsError, isLoading: dashboardIsLoading } = useGetDashboard(dashboardId)

    const { data: dashboardLogData, error: dashboardLogError,
        isError: dashboardLogIsError, isLoading: dashboardLogIsLoading } = useGetDashboardLogs(dashboardId)

    const onToggleAllGeneralFilters = () => {
        const newAllGeneralFilters = allGeneralFilters.map(p => {
            return { ...p, isActive: !markAllGeneralFiltersToggled }
        })

        setAllGeneralFilters(newAllGeneralFilters);
        setMarkAllGeneralFiltersToggled(!markAllGeneralFiltersToggled);
    }

    const onToggleGeneralFilter = (filt: FilterType) => {
        const updatedFilters = allGeneralFilters.map((filter) => {
            const updatedItem = { ...filter };
            if (filter.columnname === filt.columnname && filter.filtervalue === filt.filtervalue) {
                updatedItem.isActive = !filter.isActive;
            }
            return updatedItem;
        });

        if (updatedFilters.every(p => p.isActive)) {
            setMarkAllGeneralFiltersToggled(true);
        } else {
            setMarkAllGeneralFiltersToggled(false);
        }

        setAllGeneralFilters(updatedFilters);
    };

    const onToggleMarkAllCardTypes = () => {
        const newAllCardTypes = allCardTypes.map(p => {
            return { ...p, isActive: !markAllCardTypesToggled }
        })

        setAllCardTypes(newAllCardTypes);
        setMarkAllCardTypesToggled(!markAllCardTypesToggled);
    }

    const onToggleCardType = (filt: FilterType) => {
        const updatedFilters = allCardTypes.map((filter) => {
            const updatedItem = { ...filter };
            if (filter.columnname === filt.columnname && filter.filtervalue === filt.filtervalue) {
                updatedItem.isActive = !filter.isActive;
            }
            return updatedItem;
        });

        if (updatedFilters.every(p => p.isActive)) {
            setMarkAllCardTypesToggled(true);
        } else {
            setMarkAllCardTypesToggled(false);
        }

        setAllCardTypes(updatedFilters);
    };

    const onResetFilters = () => {
        const initFilters = generateEntityTypesFilters();
        setAllCardTypes(initFilters);
    }

    const onResetGeneralFilters = () => {
        const initGeneralFilters = generateGeneralFilters();
        setAllGeneralFilters(initGeneralFilters);
    }

    const generateEntityTypesFilters = () => {
        const allPossibleFilters = [
            {
                displayName: translations['lang-filter-dropdown-status'],
                columnname: 'EntityType',
                filtervalue: 'StatusCard',
                isActive: true,
            },
            {
                displayName: translations['lang-filter-dropdown-situation'],
                columnname: 'EntityType',
                filtervalue: 'InformationCard',
                isActive: true,
            },
            {
                displayName: translations['lang-filter-dropdown-actions'],
                columnname: 'EntityType',
                filtervalue: 'ActionsCard',
                isActive: true,
            },
            {
                displayName: translations['lang-filter-dropdown-goal'],
                columnname: 'EntityType',
                filtervalue: 'Goals',
                isActive: true,
            },
            {
                displayName: translations['lang-filter-dropdown-background'],
                columnname: 'EntityType',
                filtervalue: 'BackGround',
                isActive: true,
            },
            {
                displayName: translations['lang-filter-dropdown-prognosis'],
                columnname: 'EntityType',
                filtervalue: 'Assumption',
                isActive: true,
            },
            {
                displayName: translations['lang-filter-dropdown-aktorer'],
                columnname: 'EntityType',
                filtervalue: 'Party',
                isActive: true,
            },
            {
                displayName: translations.contacts,
                columnname: 'EntityType',
                filtervalue: 'PartyPeople',
                isActive: true,
            },
            {
                displayName: translations['lang-filter-dropdown-filer'],
                columnname: 'EntityType',
                filtervalue: 'DashboardFile',
                isActive: true,
            },
            {
                displayName: 'Log',
                columnname: 'EntityType',
                filtervalue: 'DashboardLog',
                isActive: true,
            },
        ];
        return allPossibleFilters;
    };

    const generateGeneralFilters = () => {
        const allPossibleFilters = [
            {
                displayName: translations.onlyarchived,
                columnname: 'isArchived',
                filtervalue: true,
                isActive: false,
            },
            {
                displayName: translations.onlyactive,
                columnname: 'isArchived',
                filtervalue: false,
                isActive: false,
            },
            {
                displayName: translations['class-lang-modal-decision-check'],
                columnname: 'isDecision',
                filtervalue: true,
                isActive: false,
            },
        ];
        return allPossibleFilters;
    }

    const onClickExport = () => {
        const exportLogComponent =
            <ExportLog
                statusCardStatusService={statusCardStatusService}
                translations={translations}
                dashboardId={dashboardId}
                timeZone={timeZone}
                dashboardName={decodeURIComponent(dashboardData.Name)}
                language={language}
                currentUserId={user.UserId}
                onSuccess={closeModal}
                logCards={filteredLogCards}
            />;

        const configuration = {
            centerContent: false,
            hideButton: true,
            body: exportLogComponent,
            title: translations['lang-modal-export-report'],
        }
        openModal(configuration);
    }

    useEffect(() => {
        if (translations) {
            const initFilters = generateEntityTypesFilters();
            setAllCardTypes(initFilters);
            const initGeneralFilters = generateGeneralFilters();
            setAllGeneralFilters(initGeneralFilters);
        }
    }, [translations])

    useEffect(() => {
        const loading =
            actionsCardsIsLoading || statusCardsIsLoading || informationCardsIsLoading ||
            filesIsLoading || partyIsLoading || partyPeopleIsLoading || goalsIsLoading ||
            assumptionIsLoading || backGroundIsLoading || dashboardLogIsLoading || dashboardIsLoading;
        setIsDataLoading(loading);
        return () => {
            setIsDataLoading(false);
        }
    }, [actionsCardsIsLoading, statusCardsIsLoading, informationCardsIsLoading,
        filesIsLoading, partyIsLoading, partyPeopleIsLoading,
        goalsIsLoading, assumptionIsLoading, backGroundIsLoading, dashboardLogIsLoading,
        dashboardIsLoading])

    useEffect(() => {
        if (isDataLoading === false && translations) {
            const mainLogCardFactory = new MainLogCardFactory(translations, statusCardStatusService, openModal, closeModal, timeZone, dashboardId);
            const infoLogCards = informationCardsData.map((informationCard) => {
                return mainLogCardFactory.createInformationCard(informationCard);
            });
            const actionLogCards = actionsCardsData.map((actionLogCard) => {
                return mainLogCardFactory.createActionCard(actionLogCard);
            });
            const statusLogCards = statusCardsData.map((statusCard) => {
                return mainLogCardFactory.createStatusCard(statusCard);
            });
            const assumptions = assumptionData.map((assumption) => {
                return mainLogCardFactory.createAssumptionCard(assumption);
            });

            const backGrounds = backGroundData.map((backGround) => {
                return mainLogCardFactory.createBackGroundCard(backGround);
            });

            const addedashboardFiles = filesData.map((file) => {
                return mainLogCardFactory.createDashboardFileCard(file, 'added');
            });

            const removedDashboardFiles = filesData.filter(p => p.RemovedDateTime).map((file) => {
                return mainLogCardFactory.createDashboardFileCard(file, 'removed');
            });

            const modifiedDashboardFiles = filesData.filter(p => p.ModifiedDateTime).map((file) => {
                return mainLogCardFactory.createDashboardFileCard(file, 'modified');
            });

            const goals = goalsData.map((goal) => {
                return mainLogCardFactory.createGoalCard(goal);
            });

            const parties = partyData.map((party) => {
                return mainLogCardFactory.createPartyCard(party);
            });

            const partyPeoples = partyPeopleData.map((partyPeople) => {
                return mainLogCardFactory.createPartyPeopleCard(partyPeople);
            });

            const dashboardLogs = dashboardLogData.map((dashboardLog) => {
                return mainLogCardFactory.createDashboardLogCard(dashboardLog);
            });

            const allCards =
                [...infoLogCards, ...actionLogCards, ...statusLogCards,
                ...assumptions, ...backGrounds, ...addedashboardFiles,
                ...removedDashboardFiles, ...modifiedDashboardFiles,
                ...goals, ...parties, ...partyPeoples, ...dashboardLogs];

            setLogCards(allCards);
            setIsMainLogCardsLoading(false);
        }
        return () => {
            setLogCards([]);
        }
    }, [isDataLoading, translations,
        timeZone, informationCardsData, actionsCardsData, statusCardsData, assumptionData,
        backGroundData, filesData, goalsData, partyData, partyPeopleData, dashboardLogData])

    useEffect(() => {
        if (isMainLogCardsLoading === true)
            return;

        setIsFilteredCardsLoading(true);

        let newFilteredLogCards = [...logCards];

        if (currentSearchText) {
            const searchValue = currentSearchText.toUpperCase();
            newFilteredLogCards = newFilteredLogCards
                .filter((p) =>
                    p.SearchableContent.some(item => item.toUpperCase().includes(searchValue))
                );
        }
        if (allCardTypes) {
            let cardsWithActiveFilters: MainLogCard[] = [];
            for (let i = 0; i < allCardTypes.length; i++) {
                const currentFilter = allCardTypes[i];
                if (currentFilter.isActive === true) {
                    const currentFilterMatchingCards = newFilteredLogCards.filter((logCard: any) => {
                        if (logCard[currentFilter.columnname] === currentFilter.filtervalue) {
                            return logCard;
                        }
                    });
                    cardsWithActiveFilters = cardsWithActiveFilters.concat(currentFilterMatchingCards);
                }
            }
            newFilteredLogCards = cardsWithActiveFilters;
        }
        if (allGeneralFilters) {
            for (let i = 0; i < allGeneralFilters.length; i++) {
                const currentFilter = allGeneralFilters[i];
                if (currentFilter.isActive === true) {
                    newFilteredLogCards = newFilteredLogCards.filter((logCard: any) => {
                        if (logCard[currentFilter.columnname] === currentFilter.filtervalue) {
                            return logCard;
                        }
                    });
                }
            }
        }

        if (fromDate) {
            newFilteredLogCards = newFilteredLogCards.filter(p => p.Date >= new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), 0, 0, 0));
        }

        if (toDate) {
            newFilteredLogCards = newFilteredLogCards.filter(p => p.Date <= new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59, 59));
        }

        const groupedLogCards = groupBy(newFilteredLogCards, 'DateWithoutTime');
        const firstItemsInGroup = Object.values(groupedLogCards)
            .map((groupedLogCards: any) => {
                return groupedLogCards.sort((a, b) => a.Date - b.Date || a.Title.localeCompare(b.Title)).reverse()[0].ID;
            });

        newFilteredLogCards = newFilteredLogCards.map(logCard => {
            if (firstItemsInGroup.includes(logCard.ID)) {
                return { ...logCard, ShowDateHeader: true }
            }
            return logCard;
        });

        newFilteredLogCards.sort((a, b) => b.Date - a.Date || b.Title.localeCompare(a.Title));
        setIsFilteredCardsLoading(false);
        setFilteredLogCards(newFilteredLogCards);
        return () => {
            setFilteredLogCards([]);
        }
    }, [logCards, allCardTypes, allGeneralFilters, currentSearchText, fromDate, toDate])

    useEffect(() => {
        if (isFilteredCardsLoading === false) {
            let newRows = filteredLogCards.map((logCard) => {
                const row = <MainLogRow mainLogCard={logCard} translations={translations} statusCardStatusService={statusCardStatusService} />;
                return row;
            })

            setRows(newRows);
            setIsLoading(false);
        }
        return () => {
            setRows([]);
        }
    }, [filteredLogCards, isMainLogCardsLoading])

    const header = <MainLogTableHeader
        translations={translations}
        dashboardId={dashboardId}
        openModal={openModal}
        closeModal={closeModal}
        onClickExport={onClickExport}
    />

    if (isDataLoading === true) {
        return (<div className="spinner-container-global-center"><Spinner /></div>);
    }

    return <div>
        <div className="filter-control-wrapper">
            <div className="table-column-group-header-label">{translations['sltitle']}</div>
            <FilterControl
                allFilters={allCardTypes}
                onToggleFilter={onToggleCardType}
                showReset={false}
                addFilterButtonText={translations.addcardtype}
                onClickResetFilters={onResetFilters}
                translations={translations}
                showMarkAll={true}
                onToggleMarkAll={onToggleMarkAllCardTypes}
                markAllIsToggled={markAllCardTypesToggled}
            />
            <div className="table-column-group-header-label margin-top-small">{translations['slfilter']}</div>
            <FilterControl
                allFilters={allGeneralFilters}
                onToggleFilter={onToggleGeneralFilter}
                showReset={false}
                onClickResetFilters={onResetGeneralFilters}
                translations={translations}
                showMarkAll={true}
                onToggleMarkAll={onToggleAllGeneralFilters}
                markAllIsToggled={markAllGeneralFiltersToggled}
            />
            <div className='main-dashboard-second-row-filters' >
                <div className='main-dashboard-date-controls'>
                    <MurphyDatePicker
                        title={translations['lang-filter-dropdown-from-date']}
                        isControlled={false}
                        placeholderText={translations['fromdate']}
                        locale="sv"
                        onChange={(date: Date) => setFromDate(date)}
                        selected={fromDate}
                        showClearIcon={true}
                        showTime={false}
                        onClickClear={() => setFromDate(undefined)}
                    />

                    <MurphyDatePicker
                        title={translations['lang-filter-dropdown-to-date']}
                        placeholderText={translations['todate']}
                        isControlled={false}
                        locale="sv"
                        onChange={(date: Date) => setToDate(date)}
                        selected={toDate}
                        showClearIcon={true}
                        showTime={false}
                        onClickClear={() => setToDate(undefined)}
                    />
                </div>
                <div className="right-icon">
                    <DashboardSearch
                        value={currentSearchText}
                        setValue={setCurrentSearchText}
                        placeholder={translations['placeholder-lang-search-action']}
                        onClickClear={() => setCurrentSearchText('')} />
                </div>
            </div>
        </div>
        {isLoading ? <div className="spinner-container-global-center"><Spinner /></div> :
            <DashboardTable rows={rows} header={header} />}
    </div >
}

export default MainLog;
