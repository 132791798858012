import React, { useState, useEffect, FunctionComponent, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import dayjs from "dayjs";
import { roles } from '@murphy-frontend/web-core/constants';
import { UserListCourseViewModel, UserListTrainingSessionViewModel, UserListViewModel } from '@murphy-frontend/web-core/features/UsersAdmin/components/UsersList';
import { useGetUsers } from "@murphy-frontend/common/api/Users/Queries";
import {
  CustomerUserTrainingSessionStates,
  Roles,
  TrainingTypes,
} from "@murphy-frontend/common/enums";
import { useGetTrainings } from "../../common/api/Training/queries";
import { getConvertedDate } from '@murphy-frontend/common/services/TimeService';
import { useUserPreferences } from "../../common/contexts/UserPreferencesContext";
import { useCustomer } from "../../common/contexts/CustomerContext";
import { useUser } from "../../common/contexts/UserContext";
import { UserCourses } from "./components/UserCourses";
import { FilterCourseBtn } from "./components/FilterCourseBtn";
import { MurphyUser } from "@murphy-frontend/common/interfaces/IUsersApi";
import { FilterModal } from "./components/FilterModal";

export interface IUserEducationViewModel extends UserListViewModel {
  courses?: UserListTrainingSessionViewModel[] | [];
}

export const EducationAdmin: FunctionComponent = () => {
  const navigate = useNavigate();
  const { translations, timeZone, language, courseFilter, updateCourseFilter } =
    useUserPreferences();
  const [languageDefault] = useState<number | null>(null);
  const [filteredUsers, setFilteredUsers] = useState<IUserEducationViewModel[]>(
    []
  );
  const [filterCourse, setFilterCourse] = useState<number[]>([]);
  const [filterLanguage, setFilterLangugage] = useState<number[]>([]);
  const [filterStatus, setFilterStatus] = useState<string[]>([]);
  const [allCourses, setAllCourses] = useState<UserListCourseViewModel[]>([]);

  const [currentSearchText] = useState("");

  const { customer } = useCustomer();
  const { user } = useUser();

  const wrapperRef = useRef(null);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const toggleModal = () => {
    setOpenFilterModal(!openFilterModal);
  };

  if (user.Role !== Roles.Admin) {
    navigate("/portal");
    toast("Unauthorized");
    return null;
  }

  const {
    isLoading: isLoadingCourses,
    isError: isErrorCourses,
    data: dataCourses,
    error: errorCourses,
  } = useGetTrainings({
    customerId: customer.Id,
  });

  const {
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    data: dataUsers,
    error: errorUsers,
  } = useGetUsers(customer.Id);

  const mapUsers = (
    rawDataUsers: MurphyUser[],
    tz: string
  ): IUserEducationViewModel[] =>
    rawDataUsers.map((rawUser) => {
      const mappedUserTrainingSessions = rawUser?.UserTrainingSessions?.map(
        (uts) =>
        ({
          id: uts.TrainingId,
          date: getConvertedDate(dayjs.utc(uts.Date), tz),
          trainingTypeId: uts.TrainingTypeId,
          trainingSessionStateId: uts.TrainingSessionStateID,
          trainingTag: "",
          TrainingLanguageId: uts.TrainingLanguageId,
        } as UserListTrainingSessionViewModel)
      );
      return {
        id: rawUser.CustomerUserId,
        username: rawUser.Username,
        email: rawUser.Email,
        role: roles.filter((r) => r.id === rawUser.Role)[0].value,
        mobile: rawUser.Phonenumber,
        permissions: rawUser.Permissions,
        courses: mappedUserTrainingSessions
          ?.map((trainingSession) => {
            let newTrainingSession = trainingSession;
            if (
              trainingSession.trainingTypeId === TrainingTypes.Course &&
              trainingSession.trainingSessionStateId ===
              CustomerUserTrainingSessionStates.Completed
            ) {
              newTrainingSession.trainingTag = "trainingCompleted";
              return newTrainingSession;
            } else if (
              trainingSession.trainingTypeId === TrainingTypes.Course &&
              trainingSession.trainingSessionStateId ===
              CustomerUserTrainingSessionStates.Booked
            ) {
              newTrainingSession.trainingTag = "trainingBooked";
              return newTrainingSession;
            } else if (
              trainingSession.trainingTypeId === TrainingTypes.Excersise &&
              trainingSession.trainingSessionStateId ===
              CustomerUserTrainingSessionStates.Completed
            ) {
              newTrainingSession.trainingTag = "exerciseCompleted";
              return newTrainingSession;
            }
            return newTrainingSession;
          })
          .filter((trainingSession) => trainingSession.trainingTag !== ""),
      } as IUserEducationViewModel;
    });

  useEffect(() => {
    if (dataCourses && dataCourses.length > 0) {
      const mappedCourses: UserListCourseViewModel[] = dataCourses
        .filter((p) => p.TrainingTypeId === TrainingTypes.Course)
        .map((course) => {
          return {
            id: course.Id,
            name: course.Name,
            displayName: course.Name,
          } as UserListCourseViewModel;
        });
      setAllCourses(mappedCourses);
    }
  }, [dataCourses]);

  useEffect(() => {
    if (!isLoadingUsers && timeZone) {
      let newFilteredUsers = mapUsers(dataUsers, timeZone);
      if (currentSearchText) {
        const searchValue = currentSearchText.toUpperCase();
        newFilteredUsers = newFilteredUsers.filter(
          (p) =>
            p.username.toUpperCase().startsWith(searchValue) ||
            p.email.toUpperCase().startsWith(searchValue)
        );
      }

      setFilteredUsers(newFilteredUsers);
    }
  }, [currentSearchText, dataUsers, isLoadingUsers, timeZone]);

  const filterOnCourse = (id: number) => {
    if (filterCourse.includes(id)) {
      const newCoursesFilter = filterCourse.filter(
        (courseId) => courseId !== id
      );
      setFilterCourse(newCoursesFilter);
    } else {
      const newCoursesFilter = [...filterCourse, id];
      setFilterCourse(newCoursesFilter);
    }
  };

  const [userFilterValue, setUserFilterValue] = useState<string>("");

  const languageFilterHandler = (languageId: number) => {
    if (filterLanguage.includes(languageId)) {
      const filteredArr = filterLanguage.filter(
        (langId) => langId !== languageId
      );
      setFilterLangugage(filteredArr);
    } else {
      setFilterLangugage([...filterLanguage, languageId]);
    }
  };

  const statusFilterHandler = (status: string) => {
    if (filterStatus.includes(status)) {
      const filteredArr = filterStatus.filter(
        (filterStat) => filterStat !== status
      );
      setFilterStatus(filteredArr);
    } else {
      setFilterStatus([...filterStatus, status]);
    }
  };

  useEffect(() => {
    if (courseFilter) {

      const defaultCourseFilter = JSON.parse(courseFilter);
      let defaultCourses = allCourses?.map((course) => course.id);
      let defaultStatus = ["trainingBooked", "trainingCompleted"];

      setFilterCourse(
        defaultCourseFilter.courses.length === 0
          ? defaultCourses
          : defaultCourseFilter.courses
      );

      setFilterStatus(
        defaultCourseFilter.status.length === 0 ? defaultStatus : defaultCourseFilter.status
      );

      let defaultLang;
      if (language === "swe") {
        defaultLang = 1;
      }
      if (language === "eng") {
        defaultLang = 2;
      }
      if (language === "nor") {
        defaultLang = 3;
      }

      setFilterLangugage(defaultCourseFilter.language.length === 0
        ? [defaultLang]
        : defaultCourseFilter.language);
    }
  }, [allCourses]);

  useEffect(() => {
    const courseFilterConf = {
      language: filterLanguage,
      courses: [...filterCourse],
      status: [...filterStatus],
    };
    updateCourseFilter(JSON.stringify(courseFilterConf));
  }, [filterLanguage, filterCourse, filterStatus]);

  const allFilterHandler = () => {
    setFilterCourse(allCourses?.map((course) => course.id))
  }

  const clearFilterHandler = () => {
    setFilterCourse([])
  }

  return (
    <div className="generalwrapper">
      {!openFilterModal ? null : (
        <div className="modal">
          <div ref={wrapperRef} className="modal-content">
            <FilterModal
              allCourses={allCourses}
              filterLanguage={filterLanguage}
              filterStatus={filterStatus}
              filterCourse={filterCourse}
              toggleModal={toggleModal}
              translations={translations}
              languageDefault={languageDefault}
              filterOnCourse={filterOnCourse}
              languageFilterHandler={languageFilterHandler}
              statusFilterHandler={statusFilterHandler}
              allFilterHandler={allFilterHandler}
              clearFilterHandler={clearFilterHandler}
            />
          </div>
        </div>
      )}

      <section className="header-container">
        <div className="one">
          <h4>{translations.eamaintitle}</h4>
          <FilterCourseBtn
            name={translations.eafilterbtn}
            selectable={false}
            filterAction={() => toggleModal()}
            languageDefault={languageDefault}
          />
          <div className="filter-control-container">
            <div className="search-bar-murphy">
              <input
                value={userFilterValue}
                onChange={(e) => setUserFilterValue(e.target.value)}
                placeholder={translations.eafiltersearch}
              />
            </div>
          </div>
        </div>
      </section>
      <div className="education-admin-users-container"
      >
        <div className="education-admin-users-wrapper">
          {filteredUsers?.map((user) => {
            if (userFilterValue === "") {
              return (
                <React.Fragment key={user.id}>
                  <UserCourses
                    allCourses={allCourses}
                    user={user}
                    filterCourse={filterCourse}
                    filterLanguage={filterLanguage}
                    filterStatus={filterStatus}
                  />
                </React.Fragment>
              );
            } else if (user.email.includes(userFilterValue)) {
              return (
                <React.Fragment key={user.id}>
                  <UserCourses
                    allCourses={allCourses}
                    user={user}
                    filterCourse={filterCourse}
                    filterLanguage={filterLanguage}
                    filterStatus={filterStatus}
                  />
                </React.Fragment>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};
