const queryKeys = {
  all: ['dashboards'],
  lists: () => [...queryKeys.all, 'list'],
  list: (customerId: string) => [...queryKeys.lists(), customerId],
  details: () => [...queryKeys.all, 'detail'],
  detail: (dashboardId: string) => [...queryKeys.details(), dashboardId],
  allMembers: () => [...queryKeys.all, 'members'],
  members: (dashboardId: string) => [...queryKeys.allMembers(), dashboardId],
};

export default queryKeys;
