import React from "react";
import { FunctionComponent } from "react";
import CustomerPyramid from "../CustomerPyramid";

interface MyMurphyProps {

}

const MyMurphy: FunctionComponent<MyMurphyProps> = () => {
    return (<CustomerPyramid />)
}

export default MyMurphy;