import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';

type RowProps = {

}

const Row: React.FunctionComponent<RowProps> = ({ children }) => {
    return <div>
        {children}
    </div>
}

export default Row;