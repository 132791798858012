
import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Constant } from "../commonInterfaces";
import { products } from '@murphy-frontend/web-core/constants';
import { translateConstantsWithBackendTranslations } from "../services/translationService";
import { useUserPreferences } from "./UserPreferencesContext";
import { useInjection } from "@murphy-frontend/common/contexts/InversifyContext";
import { useGetCustomers } from "@murphy-frontend/common/api/Customers/Queries";
import PersistenceType, { IPersistenceService, LocalStorageKeys } from "@murphy-frontend/common/interfaces/IPersistenceService";
import { MurphyBasicCustomerDto, } from "@murphy-frontend/common/interfaces/ICustomersApi";
import { useAuth as useOidcAuth } from "react-oidc-context";
import { useAuth } from '@murphy-frontend/web-core/features/auth/WebAuthProvider';

interface CustomerContextType {
    customer: MurphyBasicCustomerDto,
    userCustomers: MurphyBasicCustomerDto[],
    selectedCustomerId: string,
    allCustomerProducts: Constant[],
    setSelectedCustomerId: (customerId: string) => void,
}

const CustomerContext = createContext<CustomerContextType | undefined>(undefined);

export function CustomerProvider({ children }: { children: React.ReactNode }) {
    const [customer, setCustomer] = useState<MurphyBasicCustomerDto>(null);
    const [userCustomers, setUserCustomers] = useState<MurphyBasicCustomerDto[]>([]);
    const [selectedCustomerId, setSelCustomerId] = useState<string>(null);
    const [allCustomerProducts, setAllCustomerProducts] = useState<Constant[]>([]);

    const navigate = useNavigate();
    const auth = useAuth();
    const oidcAuth = useOidcAuth();

    const { translations } = useUserPreferences();

    const persistenceService = useInjection<IPersistenceService>(PersistenceType.IPersistenceService);

    const onChangeSelectedCustomerId = (newSelectedCustomerId: string) => {
        if (newSelectedCustomerId) {
            setSelCustomerId(newSelectedCustomerId);
            persistenceService.setSelectedCustomerId(newSelectedCustomerId);
            navigate('/portal', { replace: true })
        }
    };

    const updateSelectedCustomerId = () => {
        const initialSelectedCustomerId = persistenceService.getSelectedCustomerId();
        if (initialSelectedCustomerId) {
            setSelCustomerId(initialSelectedCustomerId);
        }
    };

    const excludedPaths = ['/login', '/pbsresponse/', '/training/'];
    const isPathExcluded = excludedPaths.some(excludedPath => location.pathname.startsWith(excludedPath));

    const {
        isLoading: isGetCustomersLoading,
        isError: isGetCustomersError,
        data: customersData,
        error: getCustomersError,
    } = useGetCustomers(!isPathExcluded && !!oidcAuth.user && !oidcAuth.isLoading && oidcAuth.isAuthenticated, false);

    useEffect(() => {
        if (isGetCustomersError === true) {
            if (getCustomersError) {
                auth.signOut();
            }
        }
    }, [isGetCustomersError])

    useEffect(() => {
        if (translations) {
            const translatedProducts = translateConstantsWithBackendTranslations(products, translations);
            if (customer) {
                const customerActiveProductIds = customer.ActiveProductIds;
                const translatedCustomerProducts = translatedProducts.filter((p) => customerActiveProductIds.includes(p.id));
                setAllCustomerProducts(translatedCustomerProducts);
            }
        }
    }, [translations, customer]);

    useEffect(() => {
        if (!isGetCustomersLoading && customersData) {
            const filteredAllUserCustomers = customersData;
            setUserCustomers(filteredAllUserCustomers);
        }
    }, [isGetCustomersLoading, customersData]);

    useEffect(() => {
        if (customersData) {
            if (selectedCustomerId && customersData.map((p) => p.Id).includes(selectedCustomerId)) {
                const matchingCustomer1 = customersData.find((p) => p.Id === selectedCustomerId);
                setCustomer(matchingCustomer1);
            } else {
                const matchingCustomer2 = customersData[0];
                persistenceService.setSelectedCustomerId(matchingCustomer2.Id);
                setCustomer(matchingCustomer2);
            }
        }
    }, [selectedCustomerId, customersData]);

    useEffect(() => {
        updateSelectedCustomerId();

        // Respond to the `storage` event
        function storageEventHandler(event: any) {
            if (event.key === LocalStorageKeys.SelectedCustomerId) {
                updateSelectedCustomerId();
            }
        }
        // Hook up the event handler
        window.addEventListener('storage', storageEventHandler);
        return () => {
            // Remove the handler when the component unmounts
            window.removeEventListener('storage', storageEventHandler);
        };
    }, []);

    const value = {
        customer,
        userCustomers,
        selectedCustomerId,
        allCustomerProducts,
        setSelectedCustomerId: onChangeSelectedCustomerId,
    };
    return <CustomerContext.Provider value={value}>{children}</CustomerContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useCustomer = () => {
    const context = useContext(CustomerContext);
    if (!context) throw new Error("CustomerContext Provider not found");
    return context as CustomerContextType;
}