import * as React from 'react';
import { FunctionComponent } from 'react';

interface CardFooterProps {
    leftContent?: any,
    rightContent?: any
}

const CardFooter: FunctionComponent<CardFooterProps> = ({ leftContent, rightContent }) => {
    return (
        <div className='card-footer'>
            <div className='left-footer-container'>
                {leftContent}
            </div>
            <div className='right-footer-container'>
                {rightContent}
            </div>
        </div>
    );
}

export default CardFooter;