import React, { useState, useEffect, FunctionComponent } from "react";
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import SearchBar from '@murphy-frontend/web-core/components/SearchBar';
import {
  TrainingTypes,
} from "../../../../common/enums";
import {
  courseLanguages,
} from '@murphy-frontend/web-core/constants';
import { useGetTrainings } from "../../common/api/Training/queries";
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';
import {
  useUserPreferences,
} from "../../common/contexts/UserPreferencesContext";
import useWindowDimensions from "@murphy-frontend/web-core/hooks/useWindowDimensions";
import { TrainingModel } from "../../common/api/Training/TrainingApi";
import FilterControl, { FilterType } from '@murphy-frontend/web-core/components/FilterControl';
import { GuideCarousel, GuideCarouselViewModel } from "../../common/components/TrainingComponents/GuideCarousel/GuideCarousel";
import { useUser } from "../../common/contexts/UserContext";

const GuidancePortal: FunctionComponent = () => {
  const { openModal } = useModal();
  const {
    translations,
    timeZone,
    language,
    selectedCourseLanguages,
    setSelectedCourseLanguages,
  } = useUserPreferences();

  const { currentSize } = useWindowDimensions();

  const [error, setError] = useState(null);

  const [trainings, setTrainings] = useState<TrainingModel[]>([]);
  const [courseViews, setCourseViews] = useState<GuideCarouselViewModel[]>([]);

  const [filters, setFilters] = useState<FilterType[]>([]);
  const [markAllIsToggled, setMarkAllIsToggled] = useState<boolean>(true);

  const [filteredCourseViews, setFilteredCourseViews] = useState([]);
  const [currentSearchText, setCurrentSearchText] = useState("");

  const { user } = useUser();

  const {
    isLoading: trainingsIsLoading,
    isError: trainingsIsError,
    data: trainingsData,
    error: trainingsError,
  } = useGetTrainings({
    customerUserId: user.CustomerUserId
  });

  const onToggleMarkAllFilters = () => {
    const newAllRows = filters.map((p) => {
      return { ...p, isActive: !markAllIsToggled };
    });

    setFilters(newAllRows);
    setMarkAllIsToggled(!markAllIsToggled);
    syncSelectedCourseLanguages(newAllRows);
  };

  const onToggleFilter = (filt: FilterType) => {
    const updatedFilters = filters.map((filter) => {
      const updatedItem = { ...filter };
      if (
        filter.columnname === filt.columnname &&
        filter.filtervalue === filt.filtervalue
      ) {
        updatedItem.isActive = !filter.isActive;
      }
      return updatedItem;
    });

    if (updatedFilters.every((p) => p.isActive)) {
      setMarkAllIsToggled(true);
    } else {
      setMarkAllIsToggled(false);
    }
    setFilters(updatedFilters);
    syncSelectedCourseLanguages(updatedFilters);
  };

  const onResetFilters = () => {
    const initFilters = generateInitialFilters();
    setFilters(initFilters);
    syncSelectedCourseLanguages(initFilters);
  };

  const generateInitialFilters = () => {
    const mappedLanguages = courseLanguages.map((p) => {
      return {
        displayName: p.value,
        columnname: "language",
        filtervalue: p.id,
        isActive: selectedCourseLanguages.includes(p.id),
      };
    });

    const allPossibleFilters = [...mappedLanguages];
    return allPossibleFilters;
  };

  const syncSelectedCourseLanguages = (filters: FilterType[]) => {
    const languageFilters = filters
      .filter((p) => p.columnname === "language" && p.isActive === true)
      .map((p) => p.filtervalue);
    setSelectedCourseLanguages(languageFilters);
  };

  useEffect(() => {
    if (translations) {
      const initFilters = generateInitialFilters();
      setFilters(initFilters);
    }
  }, [translations, selectedCourseLanguages]);

  const convertLanguage = (id?: number) => {
    switch (id) {
      case 1:
        return "swe";
      case 2:
        return "eng";
      case 3:
        return "nor";
      default:
        return "swe";
    }
  };

  const createCourseViews = (
    trainingsRaw: TrainingModel[],
  ): GuideCarouselViewModel[] => {
    return trainingsRaw.map((training) => {
      return {
        id: training.Id,
        name: training.Name,
        description: training.Description,
        backGroundUrl: training.ImageUrl,
        language: convertLanguage(training.TrainingLanguageId),
      } as GuideCarouselViewModel
    });
  };

  useEffect(() => {
    if (!trainingsIsLoading) {
      setTrainings(trainingsData.filter((p) => p.TrainingTypeId === TrainingTypes.Guidance));
    }
    return () => {
      setTrainings([]);
    };
  }, [trainingsData, trainingsIsLoading]);

  useEffect(() => {
    if (
      trainings.length > 0
    ) {
      const newCourseViews = createCourseViews(
        trainings,
      );

      setCourseViews(newCourseViews);
    }
  }, [
    trainings,
  ]);

  useEffect(() => {
    let courseViewsCopy = [...courseViews];
    if (courseViews) {
      if (currentSearchText) {
        const searchValue = currentSearchText.toUpperCase();
        courseViewsCopy = courseViewsCopy.filter((p) =>
          p.name.toUpperCase().startsWith(searchValue)
        );
      }
    }
    if (filters) {
      let rowsWithActiveFilters: GuideCarouselViewModel[] = [];
      for (let i = 0; i < filters.length; i++) {
        const currentFilter = filters[i];
        if (currentFilter.isActive === true) {
          const currentFilterMatchingRows = courseViewsCopy.filter(
            (recipient: any) => {
              if (
                recipient[currentFilter.columnname] ===
                currentFilter.filtervalue
              ) {
                return recipient;
              }
            }
          );
          rowsWithActiveFilters = rowsWithActiveFilters.concat(
            currentFilterMatchingRows
          );
        }
      }
      courseViewsCopy = rowsWithActiveFilters;
    }
    setFilteredCourseViews(courseViewsCopy);
  }, [courseViews, filters, currentSearchText]);

  function ErrorMessage() {
    return <p>{error}</p>;
  }

  useEffect(() => {
    if (error) {
      const modalConf: ModalConfiguration = {
        title: "Error",
        body: <ErrorMessage />,
        buttonText: "Ok",
        type: "error",
        okCallback: () => {
          setError(null);
        },
      };

      openModal(modalConf);
    }
  }, [error]);

  const onSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setCurrentSearchText(newValue);
  };

  if (
    trainingsIsLoading
  ) {
    return (
      <div className="spinner-container-global-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="generalwrapper">
      <section className="header-container vertical-aligner">
        <div className="one">
          <FilterControl
            allFilters={filters}
            onToggleFilter={onToggleFilter}
            showReset={false}
            onClickResetFilters={onResetFilters}
            translations={translations}
            showMarkAll={true}
            onToggleMarkAll={onToggleMarkAllFilters}
            markAllIsToggled={markAllIsToggled}
          />
        </div>
        <div className="three">
          <SearchBar
            val={currentSearchText}
            handleChange={onSearchChanged}
            placeholder={translations["placeholder-lang-search-action"]}
          />
        </div>
      </section>
      <section className="user-table-container">
        <div className="one">
          <h4>{translations.guidance}</h4>
        </div>
        <div className="course-registration-container">
          <GuideCarousel
            translations={translations}
            courseViews={filteredCourseViews}
            currentScreenSize={currentSize}
            timeZone={timeZone}
            language={language}
          />
        </div>
      </section>
    </div>
  );
};

export default GuidancePortal;
