import { useState, useEffect, FunctionComponent } from 'react';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import { updateUserMutation, useResetMfa } from '@murphy-frontend/common/api/Users/Mutations';
import UserForm, { UserFormViewModel } from '@murphy-frontend/web-core/features/UsersAdmin/components/UserForm';
import React from 'react';
import { useUserPreferences } from '../../../common/contexts/UserPreferencesContext';
import { Constant } from '../../../common/commonInterfaces';
import { CustomerUserTrainingSessionStates, Products } from '@murphy-frontend/common/enums';
import { enabledProducts } from '../../../common/variables';
import { UserViewModel } from '../../../common/contexts/UserContext';
import { UpdateUserRequest } from '@murphy-frontend/common/interfaces/IUsersApi';
import UserTrainingSessions from '@murphy-frontend/web-core/components/UserTrainingSessions';
import FeatureServiceType, { IFeatureService } from '@murphy-frontend/common/interfaces/IFeatureService';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import { useEditUserTrainingSession } from '../../../common/api/UserTrainingSessions/mutations';

interface UpdateUserProps {
  allProducts: Constant[],
  user: UserViewModel,
  customerId: string,
  showResetPassword: boolean,
  showResetMfa: boolean,
  onClickChangePassword?: any,
  successCallback?: any,
  isSetPermissionsReadonly: boolean,
  isSetRoleReadonly: boolean,
}

const UpdateUser: FunctionComponent<UpdateUserProps>
  = ({
    allProducts,
    user,
    showResetPassword,
    showResetMfa,
    onClickChangePassword,
    successCallback,
    isSetPermissionsReadonly,
    isSetRoleReadonly,
  }) => {
    const [mappedUser, setMappedUser] = useState(null);
    const [trainingSessions, setTrainingSessions] = useState([]);
    const { translations, timeZone } = useUserPreferences();

    const { mutate: updMutate, isPending: isLoading } = updateUserMutation(user?.CustomerUserId, translations);
    const { mutate: editUserTrainingSession, isPending: editUserTrainingSessionIsLoading } = useEditUserTrainingSession(translations);
    const { mutate: resetMfa, isPending: resetMfaIsLoading } = useResetMfa(user?.CustomerUserId);

    const featureService = useInjection<IFeatureService>(
      FeatureServiceType.IFeatureService,
    );

    const mapUser = (rawDataUser: UserViewModel): UserFormViewModel => ({
      id: rawDataUser.CustomerUserId,
      username: rawDataUser.Username,
      email: rawDataUser.Email,
      deviceToken: rawDataUser.DeviceToken,
      role: rawDataUser.Role,
      phonenumber: rawDataUser.Phonenumber,
      permissions: rawDataUser.Permissions,
      userTrainingSessions: rawDataUser.UserTrainingSessions,
    });

    const onSubmitHandler = (updateData: any) => {
      const updateUserRequest: UpdateUserRequest = {
      };

      if (typeof updateData.phonenumber !== 'undefined') {
        updateUserRequest.NewMobilePhone = updateData.phonenumber;
      }

      if (updateData.email) {
        updateUserRequest.NewEmail = updateData.email;
      }
      if (updateData.username) {
        updateUserRequest.NewUserName = updateData.username;
      }

      if (updateData.selectedproductids) {
        updateUserRequest.UpdatedProductsList = updateData.selectedproductids;
      }

      if (updateData.role >= 0) {
        updateUserRequest.NewRole = updateData.role;
      }

      if (Object.keys(updateUserRequest).length > 0) {
        updMutate(updateUserRequest, {
          onSuccess: () => {
            if (successCallback) {
              successCallback();
            }
          },
        });
      }
    };

    const onResetMfa = (userId: string) => {
      resetMfa();
    }

    const onUnRegisterForTrainingSession = (customerUserTrainingSessionId: string) => {
      const editUserTrainingSessionRequest = {
        Id: customerUserTrainingSessionId,
        NewStateId: CustomerUserTrainingSessionStates.Cancelled,
      };

      editUserTrainingSession(editUserTrainingSessionRequest);
    };

    useEffect(() => {
      if (user) {
        const newMappedUser = mapUser(user);
        setMappedUser(newMappedUser);
        setTrainingSessions(newMappedUser.userTrainingSessions);
      }
    }, [user]);

    if (!allProducts || editUserTrainingSessionIsLoading || resetMfaIsLoading) {
      return (<div className="spinner-container-global-center"><Spinner /></div>);
    }

    // disable username and email if persistenceService.getProvider() not null
    const isUserNameDisabled = featureService.isUserNameDisabled();
    const isEmailDisabled = featureService.isEmailDisabled();
    const isPhoneNumberDisabled = featureService.isPhoneNumberDisabled();
    const isUpsertUserEnabled = featureService.isUpsertUserEnabled();

    return (
      <>
        <section className="container-with-background">
          <div className="form-container">
            <UserForm
              translations={translations}
              allProducts={allProducts}
              userToBeUpdated={mappedUser}
              showResetPassword={showResetPassword}
              onClickChangePassword={onClickChangePassword}
              onSubmit={onSubmitHandler}
              isSetPermissionsReadonly={isSetPermissionsReadonly}
              isSetRoleReadonly={isSetRoleReadonly}
              isLoading={isLoading}
              isUserNameDisabled={isUserNameDisabled}
              isEmailDisabled={isEmailDisabled}
              isPhoneNumberDisabled={isPhoneNumberDisabled}
              isUpsertUserEnabled={isUpsertUserEnabled}
              onResetMfa={onResetMfa}
              showResetMfa={showResetMfa}
            />
          </div>
        </section>
        {enabledProducts.training === 'true' && allProducts && allProducts.find(p => p.id === Products.Training) &&
          <UserTrainingSessions
            translations={translations}
            customerUserTrainingSessions={trainingSessions}
            timeZone={timeZone}
            onUnRegisterForTrainingSession={onUnRegisterForTrainingSession}
          />
        }
      </>
    );
  };


export default UpdateUser;
