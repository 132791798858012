const QueryKeys = {
    all: ['dashboard'],
    dashboardall: (dashboardid: string) => [...QueryKeys.all, dashboardid] as const,
    dashboardentity: (dashboardid: string | undefined, entity: string | undefined) => [...QueryKeys.dashboardall(dashboardid), entity],
    lists: () => [...QueryKeys.all, 'list'],
    list: (filters: any) => [...QueryKeys.lists(), { filters }],
    details: () => [...QueryKeys.all, 'detail'],
    detail: (id: string) => [...QueryKeys.details(), id],
    templates: () => [...QueryKeys.all, 'template'],
    template: (id: string) => [...QueryKeys.templates(), id],
  };
  
  export default QueryKeys;
  