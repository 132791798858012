import React from "react";
import { FunctionComponent } from "react";
import { FaEnvelope, FaSms, FaUsers, FaBell } from "react-icons/fa";
import { IconContext } from 'react-icons/lib';
import {
    RiAddCircleFill, RiShieldUserFill, RiUserFill
} from 'react-icons/ri';
import { MBSRecipient } from "./models";

interface MBSSearchRecipientRowProps {
    recipient: MBSRecipient,
    addRecipient?: (recipient: MBSRecipient) => void,
    clickRecipient?: (recipient: MBSRecipient) => void,
}

const MBSSearchRecipientRow: FunctionComponent<MBSSearchRecipientRowProps>
    = ({ clickRecipient, addRecipient, recipient }) => {
        return (
            <div onClick={(e) => {
                e.preventDefault();
                clickRecipient(recipient);
            }} className="expandable-table-row clickable-cell">
                <div className="expandable-table-row-left-container">
                    <div>
                        <IconContext.Provider value={{ className: 'user-table-icon' }}>
                            {recipient.type === 'internalcontact' ? <RiShieldUserFill /> : null}
                            {recipient.type === 'internaluser' ? <RiUserFill /> : null}
                            {recipient.type === 'internalgroup' ? <FaUsers /> : null}
                        </IconContext.Provider>
                    </div>
                    <div className="expandable-table-row-left-text">
                        {recipient.name}
                    </div>
                </div>
                <div className="expandable-table-row-right-container">

                    <IconContext.Provider value={{ className: 'mobile-email-icon' }}>
                        {recipient.mobile
                            ? <span className={recipient.mobileChecked ? '' : 'recipient-item-icon-faded'} title={recipient.mobile}><FaSms /></span>
                            : null}
                        {recipient.email
                            ? <span className={recipient.emailChecked ? '' : 'recipient-item-icon-faded'} title={recipient.email}><FaEnvelope /></span> : null}
                        {recipient.deviceToken
                            ? <span className={recipient.notificationChecked ? '' : 'recipient-item-icon-faded'} title={recipient.deviceToken}><FaBell /></span> : null}
                    </IconContext.Provider>

                    <IconContext.Provider value={{ className: 'mbs-action-button icon-hover' }}>
                        <span>
                            <RiAddCircleFill onClick={(e) => {
                                addRecipient(recipient);
                                e.stopPropagation();
                            }}
                            />
                        </span>
                    </IconContext.Provider>
                </div>
            </div>
        );
    }

export default MBSSearchRecipientRow;