import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';
import { usePatchStatusCard, useUpsertStatusCard, useUpsertStatusCardOtherDashboard } from '../../mutations';
import { PatchStatusCard, StatusCard, UpsertStatusCard } from '../../api/StatusCardApi';
import StatusCardForm, { StatusCardFormDto } from './StatusCardForm';
import { convertBreaksToNewLine, convertNewLinesToBreak } from '@murphy-frontend/common/utils';
import { StatusCardStatusService } from './StatusCardStatusService';

interface UpsertStatusCardProps {
    dashboardId: string,
    statusCard?: StatusCard,
    onSuccess: () => void,
    translations: Record<string, string>,
    statusCardStatusService: StatusCardStatusService
}

const UpsertStatusCardComponent: FunctionComponent<UpsertStatusCardProps> = ({ dashboardId, statusCard, onSuccess, translations, statusCardStatusService }) => {

    const { mutate: upsertStatusCardMutation, isPending: upsertIsLoading } = useUpsertStatusCard(dashboardId);
    const { mutate: upsertStatusCardMutationOtherDashboard, isPending: upsertOtherIsLoading } = useUpsertStatusCardOtherDashboard();
    const { mutate: patchStatusCardMutation, isPending: patchIsLoading } = usePatchStatusCard(dashboardId);

    const [statusCardFormData, setStatusCardFormData] = useState<StatusCardFormDto>(null);
    const [isParentCard, setIsParentCard] = useState<boolean>(false);

    const onSubmitHandler = (statusCardFormDto: StatusCardFormDto) => {

        const upsertStatusCard: UpsertStatusCard = {
            Title: statusCardFormDto.Title,
            Message: statusCardFormDto.Message ? convertNewLinesToBreak(statusCardFormDto.Message) : null,
            ProvidedBy: statusCardFormDto.ProvidedBy,
            Status: statusCardFormDto.Status,
            Important: statusCardFormDto.Important,
            AlternatedTime: statusCardFormDto?.AlternatedTime,
            ParentDashboardId: statusCard?.ParentDashboardId,
            UpdatedFromDashboardId: dashboardId,
        }

        if (statusCard && statusCard.ID) {
            upsertStatusCard.ParentId = statusCard.ID;
        }

        if (statusCard && statusCard.DashboardId) {
            upsertStatusCard.DashboardId = statusCard.DashboardId;

            upsertStatusCardMutationOtherDashboard(upsertStatusCard, {
                onSuccess: () => {
                    onSuccess();
                }
            });
        } else {
            upsertStatusCardMutation(upsertStatusCard, {
                onSuccess: () => {
                    onSuccess();
                }
            });
        }
    };

    const onClickArchiveStatusCard = (id: number) => {
        const patchCard: PatchStatusCard = {
            UpdatedFromDashboardId: dashboardId,
            Id: id,
            archived: true,
        }

        patchStatusCardMutation(patchCard, {
            onSuccess: () => {
                onSuccess();
            }
        });
    }

    useEffect(() => {
        if (statusCard) {
            const statusCardFormDto: StatusCardFormDto = {
                ID: statusCard.ID,
                Title: statusCard.Title,
                Message: statusCard.Message ? convertBreaksToNewLine(statusCard.Message) : null,
                Status: statusCard.Status,
                ProvidedBy: statusCard.ProvidedBy,
                Important: statusCard.Important,
                AlternatedTime: statusCard.AlternatedTime,

            }
            if (statusCard.ParentDashboardId && statusCard.ParentDashboardId !== dashboardId) {
                setIsParentCard(true);
            }

            setStatusCardFormData(statusCardFormDto);
        }

    }, [statusCard])

    return (
        <div>
            <StatusCardForm
                statusCardStatusService={statusCardStatusService}
                translations={translations}
                onClickArchive={onClickArchiveStatusCard}
                onSubmit={onSubmitHandler}
                statusCard={statusCardFormData}
                isParentCard={isParentCard}
                isLoading={patchIsLoading || upsertIsLoading || upsertOtherIsLoading}
            />
        </div>
    );
}

export default UpsertStatusCardComponent;