import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Font,
} from "@react-pdf/renderer";
import * as React from "react";
import { MainLogCard } from "../models";
import { PdfLogCardFactory } from "./PdfLogCardFactory";
import regularFont from '../../../../../assets/fonts/Poppins/Poppins-Regular.ttf'
import boldFont from '../../../../../assets/fonts/Poppins/Poppins-Bold.ttf'
import italicFont from '../../../../../assets/fonts/Poppins/Poppins-Italic.ttf'
import semiBoldItalicFont from '../../../../../assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf'
import { StatusCardStatusService } from "../../StatusCards/StatusCardStatusService";

Font.register({
    family: "Poppins-SemiBoldItalic",
    format: "truetype",
    src: semiBoldItalicFont
});

Font.register({
    family: "Poppins-Regular",
    format: "truetype",
    src: regularFont
});

Font.register({
    family: "Poppins-Bold",
    format: "truetype",
    src: boldFont
});

Font.register({
    family: "Poppins-Italic",
    format: "truetype",
    src: italicFont
});

// Create styles
const styles = StyleSheet.create({
    page: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontSize: 12,
        fontFamily: "Poppins-Regular",
    },
    section: {
        margin: 10,
        padding: 10,
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    card: {
        padding: 6,
        marginTop: 6,
        backgroundColor: '#ececec'
    },
    cardHeader: {
        fontSize: 13,
        fontFamily: "Poppins-Bold",
        alignSelf: 'flex-start',
    },
    cardHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    cardBody: {
        fontWeight: 'normal'
    },
    cardTitle: {
        fontFamily: 'Poppins-SemiBoldItalic'
    },
    label: {
        fontFamily: "Poppins-Bold",
    },
    cardUser: {
        fontFamily: "Poppins-Italic",
    },
    timelineLable: {
        marginLeft: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        paddingHorizontal: 5,
        paddingVertical: 3,
        textAlign: 'center',
        textTransform: 'uppercase',
        width: 50,
        color: 'white',
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
});


const MainLogPdfReport = (pdfTitle: string, logCards: MainLogCard[], translations: Record<string, string>, statusCardStatusService: StatusCardStatusService) => {
    const pdfLogCardFactory = new PdfLogCardFactory(translations, styles, statusCardStatusService);

    return (
        <Document>
            {/*render a single page*/}
            <Page size="A4" style={styles.page}>
                <Text style={styles.header} fixed>
                    {pdfTitle}
                </Text>
                <View style={styles.section}>
                    {logCards.sort((a, b) => b.Date - a.Date || b.Title.localeCompare(a.Title))
                        .map(logCard => {

                            const card = pdfLogCardFactory.createRow(logCard);
                            const header = pdfLogCardFactory.createRowHeader(logCard);

                            return <View wrap={true} style={styles.card}>
                                {header}
                                {card}
                            </View>
                        })}
                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    );
}


export default MainLogPdfReport;