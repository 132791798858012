import { FunctionComponent, useState } from "react";
import TitleWithControls from "./TitleWithControls";
import { IconContext } from "react-icons";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import React from "react";
import { DroneComponent, DroneComponentAccordion } from "@murphy-frontend/common/api/DroneEvents/types";

interface AccordionComponentProps {
    accordionComponent: DroneComponentAccordion;
}

const AccordionComponent: FunctionComponent<AccordionComponentProps> = (
    {
        accordionComponent,
    }
) => {

    const [showDropDown, setShowDropDown] = useState(false);

    const onPressHandler = () => {
        setShowDropDown(!showDropDown);
    };

    const content = <span>
        {accordionComponent.Content}
    </span>;

    return (<div className="m-spacing drone-checklist-item-card">
        <div onClick={onPressHandler} className="drone-accordion-button-container">
            <div className="drone-accordion-button-title">
                <TitleWithControls
                    content={content}
                />
            </div>
            {showDropDown === true ? (

                <IconContext.Provider value={{ className: 'btn-icon' }}>
                    <IoChevronUp />
                </IconContext.Provider>
            ) : (
                <IconContext.Provider value={{ className: 'btn-icon' }}>
                    <IoChevronDown />
                </IconContext.Provider>
            )}

        </div>
        {showDropDown && <div className="drone-accordion-button-title">{accordionComponent.Title}</div>}
    </div>);
}

export default AccordionComponent;