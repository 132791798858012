import * as React from 'react';
import { FunctionComponent } from 'react';
import { BackGround } from '../../api/BackGroundApi';
import { parseHtmlAndLinkifySafe } from '@murphy-frontend/common/utils';
interface BackGroundFormattedProps {
    backGround: BackGround;
    translations: Record<string, string>
    cardFooter: any
}

const BackGroundFormatted: FunctionComponent<BackGroundFormattedProps> = ({ backGround, translations, cardFooter }) => {
    return (
        <div className="formatted-card">
            <p>
                <strong>{translations['class-lang-by-user']}</strong>&nbsp;{backGround.user}
            </p>
            {backGround &&
                <>
                    <br />
                    <b>
                        {translations['class-what-has-happened']}
                    </b>
                    <br />
                    {backGround.WhatMessage && parseHtmlAndLinkifySafe(backGround.WhatMessage)}
                    <br />
                    <br />
                    <b>
                        {translations['class-when-did-it-happen']}
                    </b>
                    <br />
                    {backGround.WhenMessage && parseHtmlAndLinkifySafe(backGround.WhenMessage)}
                    <br />
                    <br />
                    <b>
                        {translations['class-how-did-it-happen']}
                    </b>
                    <br />
                    {backGround.HowMessage && parseHtmlAndLinkifySafe(backGround.HowMessage)}
                    <br />
                    <br />
                    <b>
                        {translations['class-affected']}
                    </b>
                    <br />
                    {backGround.AffectedMessage && parseHtmlAndLinkifySafe(backGround.AffectedMessage)}
                    <br />
                    <br />
                    <b>
                        {translations['class-extent']}
                    </b>
                    <br />
                    {backGround.ExtentMessage && parseHtmlAndLinkifySafe(backGround.ExtentMessage)}
                    <br />
                </>}
            {cardFooter &&
                <>
                    <br />
                    <hr></hr>
                    <br />
                    {cardFooter}
                </>
            }
        </div >
    );
}

export default BackGroundFormatted;