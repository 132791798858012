import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';

type HeaderProps = {
}

const Header: React.FC<HeaderProps> = ({ children }) => {
    return <div className="dashboard-table-header">
        {children}
    </div>
}

export default Header;