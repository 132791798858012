import * as React from 'react';
import { FunctionComponent } from 'react';
import { Assumption } from '../../api/AssumptionApi';
import { parseHtmlAndLinkifySafe } from '@murphy-frontend/common/utils';

interface AssumptionFormattedProps {
    assumption: Assumption;
    translations: Record<string, string>
    cardFooter: any
}

const AssumptionFormatted: FunctionComponent<AssumptionFormattedProps> = ({ assumption, translations, cardFooter }) => {
    return (
        <div className="formatted-card">
            <p>
                <strong>{translations['class-lang-by-user']}</strong>&nbsp;{assumption.user}
            </p>
            {assumption &&
                <>
                    <br />
                    <b>
                        {translations['class-button-best-case']}:
                    </b>
                    <br />
                    {assumption.Best && parseHtmlAndLinkifySafe(assumption.Best)}
                    <br />
                    <br />
                    <b>
                        {translations['class-button-likely-scenario']}:
                    </b>
                    <br />
                    {assumption.Prob && parseHtmlAndLinkifySafe(assumption.Prob)}
                    <br />
                    <br />
                    <b>
                        {translations['class-button-worst-case']}:
                    </b>
                    <br />
                    {assumption.Worst && parseHtmlAndLinkifySafe(assumption.Worst)}
                    <br />
                </>}
            {cardFooter &&
                <>
                    <br />
                    <hr></hr>
                    <br />
                    {cardFooter}
                </>
            }
        </div >
    );
}

export default AssumptionFormatted;