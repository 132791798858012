import { AxiosResponse } from "axios";
import { ContactModel } from "../../web/src/common/api/Contacts/ContactsApi";
import { OptionModel } from "./IContactsApi";
import { UserTrainingSession } from "../../web/src/common/api/UserTrainingSessions/IUserTrainingSessionsApi";

export interface IContactOrUser {
    ID?: number;
    uniqueKey?: string;
    Name?: string;
    Email?: string;
    MobileNr?: string;
    DeviceToken?: string;
    CustomerId?: string;
    type?: string;
    SelectedOptions?: OptionModel[];
    UserId?: string;
    ExternalId?: string;
    CustomerUserId?: string;
    CustomerName?: string;
    IsSuperAdmin?: boolean;
    Username?: string;
    Role?: number;
    Phonenumber?: string;
    Permissions?: number[];
    UserTrainingSessions?: UserTrainingSession[];
}

export interface ContactGroupUserModel {
    ID: number,
    CustomerUserId: string,
    ObjectId: string,
    UserName: string,
    Email?: string,
    Roles?: string,
    MobilePhone?: string,
    DeviceToken?: string,
    Source?: string,
    GroupId: string,
}

export interface ContactGroupModel {
    ID: number,
    Name: string,
    CustomerId: string,
    ContactGroupContacts?: ContactModel[],
    ContactGroupUsers?: ContactGroupUserModel[],
}

export interface CreateContactGroupUserRequest {
    ContactGroupId: number,
    CustomerUserId: string,
}

export interface EditContactGroupRequest {
    CustomerId: string | null | undefined,
    ID: number,
    Name: string,
    AddContanctIds?: (number | string | undefined)[],
    AddCustomerUserIds?: (string | undefined)[],
    ContactGroupContacts?: IContactOrUser[],
    ContactGroupUsers?: IContactOrUser[],
}

export interface CreateContactGroupRequest {
    CustomerId: string | null | undefined,
    Name: string,
    AddContanctIds?: (number | string | undefined)[],
    AddCustomerUserIds?: (string | undefined)[],
    ContactGroupContacts?: IContactOrUser[],
    ContactGroupUsers?: IContactOrUser[],
}
export interface AddUserToGroupsRequest {
    groupIds: number[],
}

export interface AddContactToGroupsRequest {
    groupIds: number[],
}

export interface IContactGroupApi {
    getContactGrpById: (id: number, includeContacts: boolean, includeUsers: boolean) => Promise<ContactGroupModel>;
    getContactsByContactGrpId: (contactGroupId: number) => Promise<ContactModel[]>;
    getUsersByContactGrpId: (contactGroupId: number) => Promise<ContactGroupUserModel[]>;
    getContactGroupsByCustId: (customerId: string, includeContacts: boolean, includeUsers: boolean) => Promise<ContactGroupModel[]>;
    deleteContactGrp: (id: number) => Promise<AxiosResponse<any, any>>;
    deleteContactGroupContact: (contactId: number, contactGroupId: number) => Promise<AxiosResponse<any, any>>;
    deleteContactGroupUser: (id: number) => Promise<AxiosResponse<any, any>>;
    createContactGroupContact: (contactId: number, contactGroupId: number) => Promise<AxiosResponse<any, any>>;
    createContactGroupUser: (createContactGroupUserRequest: CreateContactGroupUserRequest) => Promise<AxiosResponse<any, any>>;
    editContactGrp: (editContactGroupRequest: EditContactGroupRequest) => Promise<AxiosResponse<any, any>>;
    createContactGroup: (createContactGroupRequest: CreateContactGroupRequest) => Promise<AxiosResponse<any, any>>;
    createContactGroupWithUsers: (createContactGroupRequest: CreateContactGroupRequest) => Promise<AxiosResponse<any, any>>;
    getCustomerContactGroups: (customerId: string, includeContacts: boolean, includeUsers: boolean) => Promise<AxiosResponse<any, any>>;
    addUserToGroups: (customerUserId: string, addUserToGroupsRequest: AddUserToGroupsRequest) => Promise<AxiosResponse<any, any>>;
    addContactToGroups: (contactId: number, addContactToGroupsRequest: AddContactToGroupsRequest) => Promise<AxiosResponse<any, any>>;
}

const ContactGroupApiType = {
    IContactGroupApi: Symbol("IContactGroupApi"),
};

export default ContactGroupApiType;