import React from "react";
import { FunctionComponent } from "react";

interface FormLabelProps {
    title: string;
    direction?: "row" | "column";
    id?: string;
    text?: string;
}

const FormLabel: FunctionComponent<FormLabelProps> = ({
    title,
    direction = "row",
    id,
    text,
}) => {
    let classes = 'form-input';
    if (direction === 'column') {
        classes = ` ${'form-input-column-flow'}`;
    }

    return (
        <div className={classes}>
            {title ? <label htmlFor={id}>{title}</label> : null}
            {text ? <span>{text}</span> : null}
        </div>
    );
}

export default FormLabel;