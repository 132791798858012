import { FunctionComponent } from "react";
import CheckListTitleWithControls from "./CheckboxTitleWithControls";
import React from "react";
import { DroneComponentCheckListItemTextInput } from "@murphy-frontend/common/api/DroneEvents/types";

interface TextInputComponentProps {
    eventFlowRespondBlock: DroneComponentCheckListItemTextInput;
}

const TextInputComponent: FunctionComponent<TextInputComponentProps> = (
    {
        eventFlowRespondBlock,
    }) => {

    const content = <span>
        {eventFlowRespondBlock.Label}
    </span>;

    return (
        <>
            <div className="m-spacing"></div>
            <div className="drone-checklist-item-card">
                <div className="drone-text-input-label">

                    <CheckListTitleWithControls
                        content={content}
                    />
                </div>
                <input disabled type="text" placeholder={eventFlowRespondBlock.Placeholder} className="drone-text-input">
                </input>
            </div>
        </>
    );
}

export default TextInputComponent;