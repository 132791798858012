import React, { FunctionComponent, useState } from 'react';
import {
  RiArrowDropRightLine, RiArrowDropDownLine,
} from 'react-icons/ri';
import { IconContext } from 'react-icons';

interface ExpandableContactGroupProps {
  contactGroup: any,
  showMobile?: boolean,
  showEmail?: boolean
}

const ExpandableContactGroup: FunctionComponent<ExpandableContactGroupProps> =
  ({ contactGroup, showMobile, showEmail }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const onClickExpanded = () => {
      setIsExpanded(!isExpanded);
    };

    return (
      <div className="contact-group-expandable" onClick={onClickExpanded}>

        <IconContext.Provider value={{ className: 'contactgroup-info-icon' }}>
          {isExpanded ? <RiArrowDropDownLine title="Info" onClick={onClickExpanded} /> : <RiArrowDropRightLine title="Info" onClick={onClickExpanded} />}
        </IconContext.Provider>

        <span>{contactGroup.name}</span>

        <div className="break" />

        {isExpanded
          ? (
            <ul className="contact-group-expandable-list">
              {contactGroup.groupMembers.filter((q) => {
                let showContact = false;
                if (showMobile && q.mobile) {
                  showContact = true;
                }
                if (showEmail && q.email) {
                  showContact = true;
                }
                return showContact;
              }).map((p) => (
                <li className="contactgroup-expanded-members">
                  {p.name}
                  {showMobile ? <i>{`: ${p.mobile}`}</i> : null}
                  {showEmail ? <i>{`: ${p.email}`}</i> : null}
                </li>
              ))}
            </ul>
          )
          : null}

      </div>
    );
  }

export default ExpandableContactGroup;
