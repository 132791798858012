import { View, Text, } from "@react-pdf/renderer"
import * as React from "react"
import { LogTypes } from "@murphy-frontend/common/enums";
import { DashboardLog } from "../../../api/DashboardLogApi"

export const DashboardLogPdfRow = (translations: Record<string, string>, styles: any, dashboardLog: DashboardLog) => {

    let title = "LOG";
    switch (dashboardLog.LogType) {
        case LogTypes.ExcelCreated:
            title = translations['class-lang-title-titelexcelfilecreated'];
            break;
        case LogTypes.PdfCreated:
            title = translations['class-lang-title-pdffilecreated'];
            break;
    }

    return <Text>
        {/* <Text>
            <Text style={styles.label}>
                {title}
            </Text>
        </Text> */}
    </Text>
}

