import React from "react";
import { FunctionComponent } from "react";

export interface ExistingAccountViewModel {
    ExternalId: string,
    Name: string,
    Department?: string,
    Email?: string,
}

interface ExistingAccountCardProps {
    existingAccountViewModel: ExistingAccountViewModel,
    translations: Record<string, string>,
}

const ExistingAccountCard: FunctionComponent<ExistingAccountCardProps> = ({ existingAccountViewModel, translations }) => {
    return (
        <div>
            <div>
                <div><b>{translations.name}:</b></div>
                <span>{existingAccountViewModel.Name}</span>
                <br />
                <br />
            </div>
            <div>
                <div><b>{translations.email}:</b></div>
                <span>{existingAccountViewModel.Email}</span>
                <br />
                <br />
            </div>
            <div>
                <div><b>{translations.department}:</b></div>
                <span>{existingAccountViewModel.Department}</span>
                <br />
                <br />
            </div>
        </div>
    );
}

export default ExistingAccountCard;