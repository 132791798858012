import * as React from 'react';
import { FunctionComponent } from 'react';
import { PartyPeople } from '../../api/PartyPeopleApi';
interface PartyPeopleFormattedProps {
    partyPeople: PartyPeople;
    translations: Record<string, string>
    cardFooter: any
    hideUser?: boolean
}

const PartyPeopleFormatted: FunctionComponent<PartyPeopleFormattedProps> = ({ hideUser, partyPeople, translations, cardFooter }) => {
    return (
        <div className="formatted-card">
            {hideUser === true || <p>
                <strong>{translations['class-lang-by-user']}</strong>&nbsp;{partyPeople.createdby}
            </p>}
            {partyPeople &&
                <>
                    <br />
                    <b>
                        {translations['class-lang-modal-actor-name']}:
                    </b>
                    <br />
                    {partyPeople.Name}
                    <br />
                    <br />
                    <b>
                        {translations['class-lang-modal-actor-email']}:
                    </b>
                    <br />
                    {partyPeople.Email}
                    <br />
                    <br />
                    <b>
                        {translations['class-lang-modal-actor-phone']}:
                    </b>
                    <br />
                    {partyPeople.PhoneNr}
                    <br />
                    <br />
                    <b>
                        {translations['class-lang-modal-actor-worktitle']}:
                    </b>
                    <br />
                    {partyPeople.Title}
                    <br />
                </>}
            {cardFooter &&
                <>
                    <br />
                    <hr></hr>
                    <br />
                    {cardFooter}
                </>
            }
        </div >
    );
}

export default PartyPeopleFormatted;