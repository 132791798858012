import { DashboardEntityTypes } from "@murphy-frontend/common/enums";
import { StatusCardStatusService } from "../../StatusCards/StatusCardStatusService";
import { MainLogCard } from "../models";
import { ActionsCardPdfRow } from "./ActionsCardPdfRow";
import { AssumptionPdfRow } from "./AssumptionPdfRow";
import { BackGroundPdfRow } from "./BackGroundPdfRow";
import { DashboardFilePdfRow } from "./DashboardFilePdfRow";
import { DashboardLogPdfRow } from "./DashboardLogPdfRow";
import { GoalsPdfRow } from "./GoalsPdfRow";
import { InformationsCardPdfRow } from "./InformationsCardPdfRow";
import { LogCardRowHeader } from "./LogCardRowHeader";
import { PartyPdfRow } from "./PartyPdfRow";
import { PartyPeoplePdfRow } from "./PartyPeoplePdfRow";
import { StatusCardPdfRow } from "./StatusCardPdfRow";

export class PdfLogCardFactory {
    translations: Record<string, string>;
    styles: any;
    statusCardStatusService: StatusCardStatusService;

    constructor(translations: Record<string, string>, styles: any, statusCardStatusService: StatusCardStatusService) {
        this.translations = translations;
        this.styles = styles;
        this.statusCardStatusService = statusCardStatusService;
    }

    createRow = (mainLogCard: MainLogCard) => {
        switch (mainLogCard.EntityType) {
            case DashboardEntityTypes.ActionsCard:
                return ActionsCardPdfRow(this.translations, this.styles, mainLogCard.OriginalCard);
            case DashboardEntityTypes.Assumption:
                return AssumptionPdfRow(this.translations, this.styles, mainLogCard.OriginalCard);
            case DashboardEntityTypes.BackGround:
                return BackGroundPdfRow(this.translations, this.styles, mainLogCard.OriginalCard);
            case DashboardEntityTypes.DashboardFile:
                return DashboardFilePdfRow(this.translations, this.styles, mainLogCard.OriginalCard);
            case DashboardEntityTypes.DashboardLog:
                return DashboardLogPdfRow(this.translations, this.styles, mainLogCard.OriginalCard);
            case DashboardEntityTypes.Goals:
                return GoalsPdfRow(this.translations, this.styles, mainLogCard.OriginalCard);
            case DashboardEntityTypes.InformationCard:
                return InformationsCardPdfRow(this.translations, this.styles, mainLogCard.OriginalCard);
            case DashboardEntityTypes.Party:
                return PartyPdfRow(this.translations, this.styles, mainLogCard.OriginalCard);
            case DashboardEntityTypes.PartyPeople:
                return PartyPeoplePdfRow(this.translations, this.styles, mainLogCard.OriginalCard);
            case DashboardEntityTypes.StatusCard:
                return StatusCardPdfRow(this.translations, this.styles, mainLogCard.OriginalCard, this.statusCardStatusService);
            default:
                return null;
        }
    }

    createRowHeader = (mainLogCard: MainLogCard) => {
        return LogCardRowHeader(this.translations, this.styles, mainLogCard);
    }
}

