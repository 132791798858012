import * as React from "react";
import { FunctionComponent, useMemo } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import { RiMoreFill } from "react-icons/ri";
import Button from '@murphy-frontend/web-core/components/Button';
import ActionsButton from "@murphy-frontend/web-core/components/ActionsButton";
import { StatusCardStatusService } from "./StatusCardStatusService";
import UpsertStatusCardComponent from "./UpsertStatusCardComponent";
import UpsertChildStatus from "./UpsertChildStatus";
import { useCustomer } from "../../../../common/contexts/CustomerContext";
import { useGetChildCustomers } from "@murphy-frontend/common/api/Customers/Queries";
import Spinner from "@murphy-frontend/web-core/components/Spinner";

interface StatusCardsTableHeaderProps {
    translations: Record<string, string>,
    dashboardId: string,
    openModal: any,
    closeModal: any,
    toggleSearch: () => void,
    statusCardStatusService: StatusCardStatusService
}

const StatusCardsTableHeader: FunctionComponent<StatusCardsTableHeaderProps> =
    ({ translations, statusCardStatusService, dashboardId, openModal, closeModal, toggleSearch }) => {

        const customer = useCustomer();
        const { data: customers, isLoading: customersIsLoading } = useGetChildCustomers(customer?.customer?.Id);

        const currentCustomer = customer.customer;

        const filteredCustomers = useMemo(() => {
            if (!customers || !currentCustomer) return [];

            return customers.filter(c => c.Id !== currentCustomer.Id);
        }, [customers, currentCustomer]);

        const onClickCreateStatusCard = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            event.preventDefault();
            const upsertStatusCardComponent =
                <UpsertStatusCardComponent
                    statusCardStatusService={statusCardStatusService}
                    translations={translations}
                    dashboardId={dashboardId}
                    onSuccess={() => closeModal()} />;
            const configuration = {
                centerContent: false,
                hideButton: true,
                body: upsertStatusCardComponent,
                title: translations['lang-status-card'],
            }
            openModal(configuration);
        }

        const onClickCreateChildStatusCard = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            event.preventDefault();
            const upsertChildStatusCardComponent =
                <UpsertChildStatus
                    currentCustomer={customer.customer}
                    dashboardId={dashboardId}
                    onSuccess={() => closeModal()}
                    translations={translations} />

            const configuration = {
                centerContent: false,
                hideButton: true,
                body: upsertChildStatusCardComponent,
                title: 'Create Child Status Card',
            }
            openModal(configuration);
        }

        const moreButtonIcon = <IconContext.Provider value={{ className: 'dashboard-table-actions-button-icon' }}>
            <RiMoreFill />
        </IconContext.Provider>;

        const searchIcon = <IconContext.Provider value={{ className: 'dashboard-table-action-status-icon' }}>
            <AiOutlineSearch />
        </IconContext.Provider>;

        const actionButtonItems = [];
        actionButtonItems.push(
            <li key="search" onClick={(e) => { toggleSearch() }}>
                <div className="vertical-aligner">
                    {searchIcon}
                    &nbsp;&nbsp;
                    <span>{translations['placeholder-lang-search-action']}</span>
                </div>
            </li>
        );

        if (filteredCustomers.length > 0) {
            actionButtonItems.push(
                <li key="createChild" onClick={onClickCreateChildStatusCard}>
                    <div className="vertical-aligner">
                        <span>Create Child Status</span>
                    </div>
                </li>
            );
        }

        const actionsButton = <ActionsButton actionButtonItems={actionButtonItems} icon={moreButtonIcon} pullLeft={true} />
        if (customersIsLoading) {
            <Spinner isGlobal />
        }

        return <div className='dashboard-table-flex-header'>
            <div className="left"><div className='dashboard-table-title'>
                <h2>{translations['lang-rubrik-status']}</h2>

            </div>
            </div>
            <div className="right">
                <div id="dashboard-status-controls" className='controls'>
                    {actionsButton}
                    <Button buttonStyle={"btn--add"} isIconButton onClick={onClickCreateStatusCard} />
                </div>
            </div>
        </div>
    }

export default StatusCardsTableHeader;