import React, { FunctionComponent, useEffect, useState } from 'react';
import CrisisFiles from './components/CrisisFiles';
import Dashboards from '../DashboardAdmin';
import TabPanel, { TabType } from '@murphy-frontend/web-core/components/Tabs/TabPanel';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { useUserPreferences } from '../../common/contexts/UserPreferencesContext';
import useWindowDimensions from "@murphy-frontend/web-core/hooks/useWindowDimensions";

const CrisisPortal: FunctionComponent = () => {
  const { openModal, closeModal } = useModal();
  const { translations, timeZone, language } = useUserPreferences();
  const { currentSize } = useWindowDimensions();


  const [selectedTabId, setSelectedTabId] = useState<number>(1);
  const [tabs, setTabs] = useState<TabType[]>([]);

  const onClickTab = (id: number) => {
    setSelectedTabId(id);
  };

  const setInitialTabs = () => {
    const initialTabs = [
      {
        id: 1,
        displayText: 'Dashboards',
      },
      {
        id: 2,
        displayText: translations['class-lang-files'],
      },
    ];

    setTabs(initialTabs);
  };

  useEffect(() => {
    setInitialTabs();
  }, []);

  useEffect(() => {
    setInitialTabs();
  }, [translations]);

  return (
    <div className="pbs-grid">
      <div>
        <TabPanel tabs={tabs} onClickTab={onClickTab} selectedTabId={selectedTabId} />
      </div>

      {selectedTabId === 1
        ? (

          <Dashboards
            spawnModal={openModal}
            translations={translations}
            language={language}
            currentScreenSize={currentSize}
            timeZone={timeZone}
          />
        ) : null}
      {selectedTabId === 2
        ? (
          <CrisisFiles
            spawnModal={openModal}
            closeModal={closeModal}
            translations={translations}
            timeZone={timeZone}
          />
        ) : null}
    </div>

  );
}

export default CrisisPortal;
