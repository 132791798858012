import React, { memo, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import RequirementPyramidBlock from "./RequirementPyramidBlock";
import { CustomerBlockDto, CustomerRequirementLevelDto } from "../api/models";

interface MyCompanyRequirementPyramidProps {
    activeCategoryId: number;
    customerBlocks: CustomerBlockDto[];
    requirementLevels: CustomerRequirementLevelDto[];
    handleClickBlockCheckbox: (blockId: number) => void;
    activeRequirementBlockId: number | null;
    activeRowLevel: number | null;
    handleBlockClicked: (requirementBlockId: number) => void;
    handleRowClick: (level: number) => void;
    blockIsFlashing?: boolean;
    rowIsFlashing?: boolean;
}

const MyCompanyRequirementPyramid: FunctionComponent<MyCompanyRequirementPyramidProps> = (
    {
        activeCategoryId,
        customerBlocks,
        requirementLevels,
        handleClickBlockCheckbox,
        activeRequirementBlockId,
        activeRowLevel,
        handleBlockClicked,
        handleRowClick,
        blockIsFlashing,
        rowIsFlashing
    }
) => {
    const [blocks, setBlocks] = useState<JSX.Element[]>([]);
    const [rows, setRows] = useState<JSX.Element[]>([]);

    const handleCheckBoxClick = (blockId: number): void => {
        handleClickBlockCheckbox(blockId);
    }

    const createRequirementBlock = (id: number, text: string, isComplete: boolean,
        isVerified: boolean, isLoading: boolean, isPressed: boolean, isFlashing: boolean) => {
        return <RequirementPyramidBlock
            key={id}
            id={id}
            text={text}
            onClick={() => handleBlockClicked(id)}
            isPressed={isPressed}
            level={0}
            showProgressBar={false}
            progress={0}
            isComplete={isComplete}
            isVerified={isVerified}
            showCheckbox={!isComplete}
            onClickCheckbox={() => handleCheckBoxClick(id)}
            fontSize="small"
            isLoading={isLoading}
            isFlashing={isFlashing}
        />
    }

    const createRequirementRow = (level: CustomerRequirementLevelDto, progress: number,
        verified: boolean, isPressed: boolean, isFlashing: boolean) => {
        return (<div key={level.Level} className="pyramid-row">
            <RequirementPyramidBlock
                level={level.Level}
                isPressed={isPressed}
                onClick={() => handleRowClick(level.Level)}
                text={level.Name}
                showProgressBar={true}
                progress={progress}
                isComplete={progress === 100}
                isVerified={verified}
                isFlashing={isFlashing}
            />
        </div>)
    }

    useEffect(() => {
        const generateBlocksForRow = (level: number) => {
            const levelBlocks = customerBlocks?.filter(p => p.Level === level && p.RequirementCategoryId === activeCategoryId);

            return levelBlocks?.map((block, index) => {
                // Determine if the block should be flashing based on level and index
                const flashing = level === 1 && blockIsFlashing && index === 0;
                return createRequirementBlock(block.Id,
                    block.Name,
                    block.IsCompleted,
                    block.IsVerified,
                    // This line now checks the block's index in addition to the original conditions
                    false,
                    activeRequirementBlockId === block.Id,
                    flashing) ?? null; // Use the flashing variable conditionally here
            });
        };

        const blocks = Array.from({ length: 4 }, (_, i) => (
            <div className="pyramid-row" key={`level-${i + 1}`}>
                {generateBlocksForRow(i + 1)}
            </div>
        )).reverse();

        setBlocks(blocks);
    }, [customerBlocks, activeCategoryId, activeRequirementBlockId, blockIsFlashing]);

    useEffect(() => {
        const rows = requirementLevels.map((level) => {
            const levelBlocks = customerBlocks?.filter(p => p.Level === level.Level);
            const progress = levelBlocks?.length ? levelBlocks.filter(p => p.IsCompleted).length / levelBlocks.length * 100 : 0;
            //round progress to integer
            const roundedProgress = Math.round(progress);

            const verified = levelBlocks?.length ? levelBlocks.every(p => p.IsVerified) : false;
            const flashing = rowIsFlashing && level.Level === 1;
            return createRequirementRow(level, roundedProgress, verified, activeRowLevel === level.Level, flashing);
        }).reverse();

        setRows(rows);
    }, [customerBlocks, activeCategoryId, activeRowLevel, requirementLevels, rowIsFlashing]);

    return (<div className="pyramid-container">
        {activeCategoryId ? blocks : rows}
    </div>);
};

export default MyCompanyRequirementPyramid;