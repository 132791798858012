import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';
import { Dashboard, EditMeetingRequest } from '../../../../common/api/Dashboards/DashboardsApi';
import { useEditMeeting } from '../../../../common/api/Dashboards/mutations';
import MeetingForm, { MeetingFormDto } from './MeetingForm';

interface UpsertMeetingProps {
    dashboard: Dashboard,
    onSuccess: () => void,
    translations: Record<string, string>,
}

const UpsertMeetingComponent: FunctionComponent<UpsertMeetingProps> = ({ dashboard, onSuccess, translations }) => {

    const { mutate: upsertGoalsCardMutation, isPending: isLoading } = useEditMeeting();

    const [meetingFormData, setMeetingFormData] = useState<MeetingFormDto>(null);

    const onSubmitHandler = (meetingFormDto: MeetingFormDto) => {

        const editMeeting: EditMeetingRequest = {
            DashboardId: dashboard.dashboardid,
            StatusMeetingDateTime: meetingFormDto?.StatusMeetingDateTime,
        }

        upsertGoalsCardMutation(editMeeting, {
            onSuccess: () => {
                onSuccess();
            }
        });
    };

    useEffect(() => {
        if (dashboard) {
            const meetingFormDto: MeetingFormDto = {
                StatusMeetingDateTime: dashboard.StatusMeetingDateTime
            };
            setMeetingFormData(meetingFormDto);
        }
    }, [dashboard])

    return (
        <div>
            <MeetingForm
                translations={translations}
                onSubmit={onSubmitHandler}
                meeting={meetingFormData}
                isLoading={isLoading}
            />
        </div>
    );
}

export default UpsertMeetingComponent;