import React, { useState, useEffect, FunctionComponent } from "react";
import { GuideCard } from "../GuideCard";
import { GuideCardFooter } from "../GuideCard/components/GudieCardFooter";
import { LanguageType } from "../../../contexts/UserPreferencesContext";
import { TimeZoneType } from "@murphy-frontend/common/services/TimeService"
import TableFooter from "@murphy-frontend/web-core/components/ColumnGroupTable/TableFooter";
import { languages } from '@murphy-frontend/web-core/constants';

export interface GuideCarouselViewModel {
  id: number;
  trainingTypeId: number;
  name: string;
  description: string;
  backGroundUrl: string;
  language: string;
  onClick: () => void;
}

interface GuideCarouselProps {
  translations: Record<string, string>;
  currentScreenSize: string;
  courseViews: GuideCarouselViewModel[];
  timeZone: TimeZoneType;
  language: LanguageType;
}

export const GuideCarousel: FunctionComponent<GuideCarouselProps> = ({
  translations,
  currentScreenSize,
  courseViews,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState(1);
  const [allCourseCards, setAllCourseCards] = useState([]);

  const mapCourseCards = (cViews: GuideCarouselViewModel[], transl: any) =>
    cViews.map((course) => {
      return (
        <GuideCard
          key={course.id}
          title={course.name}
          description={course.description}
          backGroundUrl={course.backGroundUrl}
          trainingId={course.id}
          translations={translations}
          language={languages.find(p => course.language == p.id)?.value}
          footer={
            <GuideCardFooter
              trainingId={course.id}
            />
          }
        />
      );
    });

  const getNumberOfTotalItemsToBeDisplayed = (screenSize: string) => {
    switch (screenSize) {
      case "xs":
        return 4;
      case "sm":
        return 3;
      case "md":
        return 3;
      case "lg":
        return 4;
      case "xl":
        return 6;
      default:
        return 1;
    }
  };

  useEffect(() => {
    const numberOfItems = getNumberOfTotalItemsToBeDisplayed(currentScreenSize);
    setNumberOfItemsPerPage(numberOfItems);
    return () => {
      setNumberOfItemsPerPage(1);
    };
  }, [currentScreenSize]);

  const onChangeNumberOfItemsPerPage = (event: any) => {
    const newValue = parseInt(event.target.value, 10);
    setNumberOfItemsPerPage(newValue);
  };

  const onChangePage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * numberOfItemsPerPage;
  const endIndex = startIndex + numberOfItemsPerPage;
  const rowsToBeDisplayed = allCourseCards.slice(startIndex, endIndex);

  useEffect(() => {
    if (courseViews) {
      const mappedCourseCards = mapCourseCards(courseViews, translations);
      setAllCourseCards(mappedCourseCards);
    }
    return () => {
      setAllCourseCards([]);
    };
  }, [courseViews, translations]);

  return (
    <>
      <div className="course-carousel-container">{rowsToBeDisplayed}</div>
      {rowsToBeDisplayed.length < allCourseCards.length && (
        <TableFooter
          currentScreenSize={currentScreenSize}
          numberOfItems={courseViews.length}
          currentSelectedPage={currentPage}
          numberOfItemsPerPage={numberOfItemsPerPage}
          onChangeNumberOfItemsPerPage={onChangeNumberOfItemsPerPage}
          onChangePage={onChangePage}
          hidePageInputs
        />
      )}
    </>
  );
};
