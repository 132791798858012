import React, { useEffect, useRef } from 'react'
import { ContactModel } from '../../../common/api/Contacts/ContactsApi';


interface IContactListItemExpanded {
    contact: ContactModel,
    translations: Record<string, string>
  }

export const ContactListItemExpanded = ({translations, contact}: IContactListItemExpanded)=> {


  

  return (
    <div style={{display: 'flex', flexDirection: 'column', backgroundColor: 'white'}}>
            <div className="recipient-item-text">
            {`${translations.email}: ${contact.email ? contact.email : ''}`}
            </div>
            <div className="recipient-item-text">
              {`${translations.phonenumber}: ${contact.mobile ? contact.mobile : ''}`}
            </div>
          {contact.selectedOptions ? contact.selectedOptions.map((selectedContact) => (
              <div key={selectedContact.optionSetId} className="recipient-item-text">
                {`${selectedContact.optionSetName}: ${selectedContact.value}`}
              </div>)
          ): null}
          </div>)
}
