import { IApiService } from '@murphy-frontend/common/interfaces/IApiService';

export interface SendOutResponseUpdate {
  responseAnswer: string,
  responseSelectedAlternative: string,
}

export interface Message {
  Title: string,
  Body: string,
  Question: string,
}

export interface SendOutResponseAnswer {
  dateansweredUtc: Date,
  name: string,
  responseAnswer: string,
  responseSelectedAlternative: string,
  status: string,
  SendOutResponseId: string,
}

export interface SendOutBatchResponse {
  CreatedTimeUtc: Date,
  status: number,
  message: Message,
  alternativ: string[],
  sendOutResponseAnswers: SendOutResponseAnswer[],
}

export interface SendOutResponse {
  dateansweredUtc?: Date,
  responseQuestion: string,
  responseAlternatives: string[],
  responseAnswer: string,
  responseSelectedAlternative: string,
  Question: string,
}

export class SendOutResponseApi {
  constructor(private apiService: IApiService) { }

  getSendOutResponsesByBatchId(sendOutBatchId: string) {
    const url = `/SendOutResponse?SendOutBatchId=${sendOutBatchId}`;
    return this.apiService.callApi(url, 'GET');
  }

  getSendOutResp(id: string) {
    const url = `/SendOutResponse/${id}`;
    return this.apiService.callApiWithoutToken(url, 'GET');
  }

  postSendOutResponse(id: string, editSendOutResponseRequest: SendOutResponseUpdate) {
    const url = `/SendOutResponse/${id}`;
    return this.apiService.callApiWithoutToken(url, 'POST', editSendOutResponseRequest);
  }
}
