import * as React from "react";
import { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "@murphy-frontend/web-core/components/Input";
import Button from "@murphy-frontend/web-core/components/Button";
import Textarea from "@murphy-frontend/web-core/components/TextArea";
import dayjs from "dayjs";
import MurphyDatePicker from "@murphy-frontend/web-core/components/MurphyDatePicker";
import MurphyCheckbox from "../../../../common/components/MurphyCheckbox";
import StatusSelectorButton from "./StatusSelectorButton";
import DOMPurify from "dompurify";
import { StatusCardStatusService } from "./StatusCardStatusService";

yup.addMethod(yup.string, "html", function () {
  return this.test({
    name: "html",
    exclusive: true,
    message: "Invalid text",
    test: (value) => {
      DOMPurify.sanitize(value);
      if (DOMPurify.removed.length > 0) {
        return false;
      }
      return true;
    },
  });
});

const statusCardsSchema = yup.object().shape({
  Title: yup.string().required(),
  Message: yup.string().nullable().html(),
  Status: yup.number(),
  ProvidedBy: yup.string().nullable().notRequired(),
  Important: yup.boolean(),
  AlternatedTime: yup.date().nullable().notRequired(),
});

export interface StatusCardFormDto {
  ID: number;
  Title: string;
  Message?: string;
  Status: number;
  ProvidedBy?: string;
  Important: boolean;
  AlternatedTime?: Date;
  ParentId?: number;
}

type StatusCardFormProps = {
  statusCard?: StatusCardFormDto;
  onSubmit: (data: StatusCardFormDto) => void;
  translations?: any;
  onClickArchive: (id: number) => void;
  isLoading?: boolean;
  isParentCard?: boolean;
  statusCardStatusService: StatusCardStatusService;
};

const StatusCardForm = ({
  isLoading,
  statusCard,
  isParentCard,
  onSubmit,
  translations,
  onClickArchive,
  statusCardStatusService,
}: StatusCardFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isDirty },
    reset,
    getValues,
  } = useForm<StatusCardFormDto>({
    resolver: yupResolver(statusCardsSchema),
    defaultValues: {
      Title: "",
      Message: "",
      Status: statusCardStatusService.getDefaultStatus().Id,
      ProvidedBy: "",
      Important: false,
    },
  });

  const ref = useRef<HTMLFormElement>();
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === "Enter") {
        event.preventDefault();
        handleSubmit(onSubmitHandler)();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);
  useEffect(() => {
    if (statusCard) {
      const defaults = {
        Title: statusCard.Title,
        Message: statusCard.Message,
        Status: statusCard.Status,
        ProvidedBy: statusCard.ProvidedBy,
        Important: statusCard.Important,
        AlternatedTime: statusCard.AlternatedTime
          ? dayjs(statusCard.AlternatedTime).toDate()
          : undefined,
      };
      reset(defaults);
    }
  }, [statusCard, reset]);

  const onSubmitHandler = (data: StatusCardFormDto) => {
    onSubmit(data);
  };

  return (
    <form ref={ref} onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="dashboardform-container double-col">
        <div className="dashboardform-container-section">
          {/* <span className="form-section-header">INFO</span> */}
          <Input
            isControlled
            register={register}
            name="Title"
            isRequired
            title={translations["class-lang-modal-title"]}
            errorMessage={errors.Title?.message}
            disabled={isParentCard}
          />
          <div className="form-input flex-input-container">
            <Controller
              control={control}
              name="Status"
              render={({ field }) => (
                <StatusSelectorButton
                  currentStatus={field.value}
                  onClickStatus={(status, e) => {
                    field.onChange(status);
                  }}
                  statusCardStatusService={statusCardStatusService}
                />
              )}
            />
            <label>{translations["statusname"]}</label>
          </div>
          <Input
            isControlled
            register={register}
            name="ProvidedBy"
            title={translations["class-lang-modal-decision-maker"]}
            errorMessage={errors.ProvidedBy?.message}
          />
          <MurphyDatePicker
            title={translations["class-lang-modal-alt-time"]}
            isControlled
            control={control}
            name="AlternatedTime"
            locale="sv"
            showTime={true}
            errorMessage={errors.AlternatedTime?.message}
          />
          <div className="form-input flex-input-container">
            <MurphyCheckbox
              isControlled
              control={control}
              name="Important"
              errorMessage={errors.Important?.message}
            />
            <label>{translations["class-lang-modal-check-priority"]}</label>
          </div>
        </div>
        <div className="dashboardform-container-section">
          {/* <span className="form-section-header">Beskrivning</span> */}

          <Textarea
            isControlled
            rows={24}
            register={register}
            name="Message"
            title={translations["class-lang-modal-description"]}
            errorMessage={errors.Message?.message}
          />
        </div>
        <div className="dashboardform-container-section">
          <div className="form-buttons">
            {statusCard && !isParentCard && (
              <Button
                type="button"
                disabled={isDirty || isLoading}
                onClick={() => onClickArchive(statusCard.ID)}
                buttonStyle="btn--archive"
                buttonSize="btn--medium"
                isWarningButton={true}
              >
                {translations.archive}
              </Button>
            )}
            {isDirty && (
              <Button
                type="button"
                disabled={!isDirty || isLoading}
                onClick={() => reset()}
                buttonStyle="btn--undo"
                buttonSize="btn--medium"
                isNeutralButton={true}
              >
                {translations.undo}
              </Button>
            )}
          </div>
        </div>
        <div className="dashboardform-container-section">
          <div className="form-buttons save-button">
            <Button
              isLoading={isLoading}
              disabled={!isDirty || (errors && errors.length > 0)}
              type="submit"
              buttonStyle="btn--primary"
              buttonSize="btn--medium"
            >
              {translations.save}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default StatusCardForm;
