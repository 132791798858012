import * as React from 'react';
import { FunctionComponent } from 'react';

interface DashboardNavbarItemProps {
    icon?: any,
    isSelected: boolean,
    title: string,
    onClick?: () => void;
}

const DashboardNavbarItem: FunctionComponent<DashboardNavbarItemProps> = ({ icon, title, onClick, isSelected }) => {

    let classes = 'dashboard-nav-bar-item';
    if (isSelected === true) {
        classes += ' dashboard-nav-bar-item-isselected';
    }

    return (
        <div className={classes} onClick={onClick}>
            <div className='dashboard-nav-bar-item-icon'>
                {icon}
            </div>
            <span className='dashboard-nav-bar-item-title'>
                {title}
            </span>
        </div>
    );
}

export default DashboardNavbarItem;