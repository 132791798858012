import React, { FunctionComponent } from 'react';
import { FaShare, FaReplyAll } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';

interface MBSTemplateProps {
  id: number,
  title: string,
  description: string,
  replyRequired: boolean,
  selected: boolean,
  flashing?: boolean,
  onSelectTemplate: (templateId: number) => void,
}

const MBSTemplate: FunctionComponent<MBSTemplateProps>
  = ({
    id,
    title,
    description,
    replyRequired,
    selected,
    flashing,
    onSelectTemplate,
  }) => {

    let classes = selected ? 'template-wrapper selected' : 'template-wrapper';
    if (flashing) {
      classes += ' zoom-in-zoom-out-animation';
    }
    return (
      <div
        className={classes}
        onClick={() => onSelectTemplate(id)}>
        <div>
          <IconContext.Provider value={{ className: 'template-icon' }}>
            <FaShare />
            {replyRequired ? (
              <span>
                &nbsp;&nbsp;
                <FaReplyAll />
              </span>
            ) : null}
          </IconContext.Provider>

        </div>
        <div className="template-title">
          <h3>{title}</h3>
        </div>
        <br />
        <p>
          {description}
        </p>
      </div>
    );
  }

export default MBSTemplate;
