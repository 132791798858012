import React from 'react';
import PropTypes from 'prop-types';
import {
  useNavigate,
} from 'react-router-dom';
import MBSResponseTableHeader from './MBSResponseTableHeader';
import MBSResponseRow from './MBSResponseRow';

function MBSResponseTable({
  rows,
  translations,
}) {
  const navigate = useNavigate();
  const onClickRow = (id) => {
    navigate(`/pbsresponse/${id}`);
  };

  return (
    <ol className="collection collection-container">
      <MBSResponseTableHeader translations={translations} />
      {
        rows.map((row) => (
          <MBSResponseRow
            translations={translations}
            key={row.id}
            id={row.id}
            responseSelectedAlternative={row.responseSelectedAlternative}
            responseAnswer={row.responseAnswer}
            date={row.dateAnswered}
            userName={row.userName}
            handleClick={() => onClickRow(row.id)}
          />
        ))
      }
    </ol>
  );
}

MBSResponseTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    responseSelectedAlternative: PropTypes.string,
    responseAnswer: PropTypes.string,
    id: PropTypes.string.isRequired,
    date: PropTypes.string,
  })),
};

MBSResponseTable.defaultProps = {
  rows: [],
};

export default MBSResponseTable;
