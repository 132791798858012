import { inject, injectable } from "inversify";
import ApiServiceType, { IApiService } from "../../interfaces/IApiService";
import { AdUserGroupRequest, CreateUserRequest, IUsersApi, UpdateUserRequest, UpsertUserOnboardingFlowRequest } from "../../interfaces/IUsersApi";
import { AxiosResponse } from "axios";

@injectable()
export class UsersApi implements IUsersApi {

    constructor(
        @inject(ApiServiceType.IApiService) private apiService: IApiService,
    ) { }
    getUsers = async (
        {
            objectId = '',
            customerId = '',
            includeAdInfo = true,
            includeTrainingInfo = true,
        } = {},
    ) => {
        const baseUrl = '/api/User';
        const searchParams = new URLSearchParams();

        if (customerId) {
            searchParams.append('customerId', customerId);
        }
        if (includeAdInfo) {
            searchParams.append('includeAdInfo', includeAdInfo.toString());
        }
        if (includeTrainingInfo) {
            searchParams.append('includeTrainingInfo', includeTrainingInfo.toString());
        }
        if (objectId) {
            searchParams.append('objectId', objectId);
        }
        const searchParamsString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
        const url = `${baseUrl}${searchParamsString}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    getUser = async (customerUserId: string, includeAdInfo: boolean, includeTrainingInfo: boolean) => {
        const url = `/api/User/${customerUserId}?includeAdInfo=${includeAdInfo}&includeTrainingInfo=${includeTrainingInfo}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    updateUser = (customerUserId: string, updateUserRequest: UpdateUserRequest) => {
        const url = `/api/User/${customerUserId}`;
        return this.apiService.callApi(url, 'PUT', updateUserRequest);
    }

    resetMfa(customerUserId: string): Promise<AxiosResponse<any, any>> {
        const url = `/api/User/${customerUserId}/ResetMfa`;
        return this.apiService.callApi(url, 'PATCH');
    }

    createUser = (createUserRequest: CreateUserRequest) => {
        const url = '/api/User';
        return this.apiService.callApi(url, 'POST', createUserRequest);
    }

    deleteUser = (customerUserId: string) => {
        const url = `/api/User/${customerUserId}`;
        return this.apiService.callApi(url, 'DELETE');
    }

    addUserToADGroup = (request: AdUserGroupRequest) => {
        const url = '/api/AdGroup';
        return this.apiService.callApi(url, 'POST', request);
    }

    getExternalUserByUserName = (userName: string) => {
        const url = `/api/AdUser/${userName}`;
        return this.apiService.callApi(url, 'GET');
    }

    getUserProvider = (userName: string) => {
        const url = `/api/Provider?userName=${userName}`;
        return this.apiService.callApiWithoutToken(url, 'GET');
    }

    getUserOnboardingFlows = async () => {
        const url = '/api/UserOnboardingFlow';
        const { data } = await this.apiService.callApi(url, 'GET');
        data.forEach((flow: any) => {
            const map = new Map<number, string>();
            Object.entries(flow.StepsHtmlContents).forEach(([key, value]) => {
                map.set(parseInt(key), value as string);
            });

            flow.StepsHtmlContents = map;
        });
        return data;
    }

    postUserOnboardingFlow = (request: UpsertUserOnboardingFlowRequest) => {
        const url = '/api/UserOnboardingFlow';
        return this.apiService.callApi(url, 'POST', request);
    }
}