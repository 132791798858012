import React, { useEffect } from "react";
import { FunctionComponent } from "react";
import { useAuth } from '@murphy-frontend/web-core/features/auth/WebAuthProvider';
import SignupForm from "./SignupForm";
import { useSearchParams } from "react-router-dom";
import { useInjection } from "@murphy-frontend/common/contexts/InversifyContext";
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService'
    ;
import { ActiveCampaignApi } from "./api/ActiveCampaignApi";
interface FirstLoginPageProps {

}

const FirstLoginPage: FunctionComponent<FirstLoginPageProps> = () => {
    const webAuth = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    const lang = searchParams.get('lang');
    const source = searchParams.get('source');

    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);

    const onEmailSubmit = async (email: string) => {
        if (source) {
            const activeCampaignApi = new ActiveCampaignApi(apiService);
            const decodedSource = decodeURIComponent(source);
            await activeCampaignApi.addTagToUser(email, decodedSource);
        }
        webAuth.signIn(null, "SIGNUP", email);
    }

    return (<div>
        <div className="general-wrapper">
            <SignupForm onEmailSubmit={onEmailSubmit} language={lang} />
        </div>
    </div>);
}

export default FirstLoginPage;