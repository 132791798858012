import React from "react";
import { FunctionComponent } from "react";

interface CheckListTitleWithControlsProps {
    content: React.ReactNode;
}

const CheckListTitleWithControls: FunctionComponent<CheckListTitleWithControlsProps> = ({
    content }) => {

    return (
        <div className="l-text">
            {content}
        </div>
    );
}

export default CheckListTitleWithControls;