import * as React from "react";
import { DashboardEntityTypes, GoalTypes } from "@murphy-frontend/common/enums";
import { getConvertedDateDayjs } from '@murphy-frontend/common/services/TimeService';
import { Goals } from "../../../api/GoalsApi";
import GoalsExpandedContent from "../../Goals/GoalsExpandedContent";
import { MainLogCard } from "../models";
import { BaseCardProps } from "./MainLogCardFactory";

export interface GoalMainLogCardProps extends BaseCardProps {
    goal: Goals,
}

export const GoalMainLogCard =
    ({ translations, goal, timeZone, dashboardId, openModal, closeModal }: GoalMainLogCardProps): MainLogCard => {
        const date = getConvertedDateDayjs(goal.CreatedDateTime, timeZone);
        const expandedContent =
            <GoalsExpandedContent
                goals={goal}
                dashboardId={dashboardId}
                openModal={openModal}
                closeModal={closeModal}
                translations={translations}
            />

        const title = goal.goalType === GoalTypes.MainGoal ? translations['class-lang-title-infocard-target'] : translations['class-lang-title-infocard-com-target'];

        const searchableContent = [];
        searchableContent.push(title);
        if (goal.Title)
            searchableContent.push(goal.Title);
        if (goal.message)
            searchableContent.push(goal.message);
        if (goal.user)
            searchableContent.push(goal.user);

        const logCard: MainLogCard = {
            ID: `${goal.ID}-${DashboardEntityTypes.Goals}-${goal.goalType}`,
            Title: title,
            isArchived: goal.archived,
            isDecision: false,
            SearchableContent: searchableContent,
            User: goal.user,
            Date: date,
            DateWithoutTime: date.format("YYYY-MM-DD"),
            Time: date.format("HH:mm"),
            EntityType: DashboardEntityTypes.Goals,
            ExpandedContent: expandedContent,
            OriginalCard: goal,
        }
        return logCard;
    }