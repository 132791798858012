import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';
import { convertBreaksToNewLine, convertNewLinesToBreak } from '@murphy-frontend/common/utils';
import { Goals, UpsertGoals } from '../../api/GoalsApi';
import { useUpsertGoals } from '../../mutations';
import GoalsForm, { GoalsFormDto } from './GoalsForm';

interface UpsertGoalsProps {
    dashboardId: string,
    goalType: number,
    goals?: Goals,
    onSuccess: () => void,
    translations: Record<string, string>,
}

const UpsertGoalsComponent: FunctionComponent<UpsertGoalsProps> = ({ dashboardId, goals, goalType, onSuccess, translations }) => {
    const { mutate: upsertGoalsCardMutation, isPending } = useUpsertGoals(dashboardId);

    const [goalsFormData, setGoalsFormData] = useState<GoalsFormDto>(null);

    const onSubmitHandler = (goalsFormDto: GoalsFormDto) => {

        const upsertGoals: UpsertGoals = {
            Title: 'Mål',
            message: goalsFormDto.message ? convertNewLinesToBreak(goalsFormDto.message) : "",
            goalType: goalType,
        }

        upsertGoalsCardMutation(upsertGoals, {
            onSuccess: () => {
                onSuccess();
            }
        });
    };

    useEffect(() => {
        if (goals) {
            const goalsFormDto: GoalsFormDto = {
                message: goals.message ? convertBreaksToNewLine(goals.message) : "",
            };
            setGoalsFormData(goalsFormDto);
        }
    }, [goals])

    return (
        <div>
            <GoalsForm
                translations={translations}
                onSubmit={onSubmitHandler}
                goals={goalsFormData}
                isLoading={isPending}
            />
        </div>
    );
}

export default UpsertGoalsComponent;