import { View, Text, } from "@react-pdf/renderer"
import * as React from "react"
import { DashboardEntityTypes } from "@murphy-frontend/common/enums"
import { MainLogCard } from "../models"

const getLogCardTypeText = (mainLogCard: MainLogCard, translations: Record<string, string>, styles: any) => {
    switch (mainLogCard.EntityType) {
        case DashboardEntityTypes.InformationCard:
            return <Text style={[styles.timelineLable, { backgroundColor: '#c19bb2' }]}> {translations['class-lang-tag-info']}</ Text>
        case DashboardEntityTypes.ActionsCard:
            return <Text style={[styles.timelineLable, { backgroundColor: '#adbf91' }]}>{translations['class-lang-tag-action']}</Text>
        case DashboardEntityTypes.StatusCard:
            return <Text style={[styles.timelineLable, { backgroundColor: '#6d7c8c' }]}>{translations['class-lang-tag-status']}</Text>
        case DashboardEntityTypes.Goals:
            return <Text style={[styles.timelineLable, { backgroundColor: '#921e5b' }]}>{translations['class-lang-tag-target']}</Text>
        case DashboardEntityTypes.Assumption:
            return <Text style={[styles.timelineLable, { backgroundColor: '#a7a7a7' }]}>{translations['class-lang-tag-assumption']}</Text>
        case DashboardEntityTypes.BackGround:
            return <Text style={[styles.timelineLable, { backgroundColor: '#a7a7a7' }]}>{translations['class-lang-tag-background']}</Text>
        case DashboardEntityTypes.DashboardFile:
            return <Text style={[styles.timelineLable, { backgroundColor: '#5d5764' }]}>{translations['class-lang-tag-file']}</Text>
        case DashboardEntityTypes.DashboardLog:
            return <Text style={[styles.timelineLable, { backgroundColor: '#5d5764' }]}>LOG</Text>
        case DashboardEntityTypes.Party:
            return <Text style={[styles.timelineLable, { backgroundColor: '#5d5764' }]}>{translations['class-lang-tag-actor']}</Text>
        case DashboardEntityTypes.PartyPeople:
            return <Text style={[styles.timelineLable, { backgroundColor: '#5d5764' }]}>{translations['class-lang-tag-actor']}</Text>
        default:
            return <Text style={[styles.timelineLable, { backgroundColor: '#5d5764' }]}>---</Text>
    }
}

export const LogCardRowHeader = (translations: Record<string, string>, styles: any, logCard: MainLogCard) => {
    const date = `${logCard.DateWithoutTime} ${logCard.Time}`
    const logCardTypeText = getLogCardTypeText(logCard, translations, styles);
    return <View>
        <View style={styles.cardHeaderContainer}>
            <Text style={styles.cardHeader}>{date}</Text>
            {logCardTypeText}
        </View>
        <Text style={styles.cardUser} >{translations['class-lang-by-user']} {logCard.User}</Text>
        <Text style={styles.cardTitle}>{logCard.Title}</Text>
    </View>
}
