import { FunctionComponent, useEffect, useRef, useState } from "react";
import { OnboardingFlowIds, useOnboarding } from "../../common/contexts/OnboardingContext";
import { Step, Steps } from "intro.js-react";
import React from "react";

interface CrisisRegistrationOnboardingStepsProps {
    stepsEnabled: boolean;
    onComplete: () => void;
    setStepsEnabled: (isEnabled: boolean) => void;
}

const CrisisRegistrationOnboardingSteps: FunctionComponent<CrisisRegistrationOnboardingStepsProps> = (
    { stepsEnabled, onComplete, setStepsEnabled }
) => {

    const stepsRef = useRef(null);
    const [steps, setSteps] = useState<Step[]>([]);
    const [initialStep, setInitialStep] = useState<number>();
    const { incompletedOnboardingFlows, setCurrentStep, onboardingsCurrentStep } = useOnboarding();

    useEffect(() => {
        if (stepsEnabled && onboardingsCurrentStep && steps.length === 0 // Only initialize steps if they haven't been initialized yet.
        ) {
            console.log('Initializing onboarding steps');
            const stepsFromApi = incompletedOnboardingFlows.find(p => p.OnboardingFlowId === OnboardingFlowIds.Academy)?.StepsHtmlContents;
            if (stepsFromApi === undefined)
                return;

            const newSteps = createInitialSteps(stepsFromApi);
            setSteps(newSteps);
            const firstStep = onboardingsCurrentStep.get(OnboardingFlowIds.Academy);
            setInitialStep(firstStep);
        }
    }, [onboardingsCurrentStep, incompletedOnboardingFlows, stepsEnabled]);

    useEffect(() => {
        if (stepsEnabled && steps.length > 0) {
            const firstStep = onboardingsCurrentStep.get(OnboardingFlowIds.Academy);
            setInitialStep(firstStep);
        }
    }, [stepsEnabled, onboardingsCurrentStep, steps]);

    const onDummyExit = () => {
        console.log('Dummy exit');
    }

    useEffect(() => {
        if (stepsEnabled && initialStep !== undefined) {
            if (stepsRef) {
                if (initialStep > 1) { // if we don't do this, then there will be 2 instances of introjs running and it wont work properly
                    stepsRef.current?.introJs.exit(onDummyExit).then(() => {
                        stepsRef.current?.introJs?.goToStepNumber(initialStep);
                        stepsRef.current?.updateStepElement(initialStep);
                        stepsRef.current?.introJs?.refresh();
                        stepsRef.current?.introJs.start();
                    });
                }
            }
        }
    }, [stepsEnabled, stepsRef, initialStep]);

    const createInitialSteps = (stepsFromApi: Map<number, string>): Step[] => {
        const steps = Array<Step>();
        // replace all <p></p> with <br> in the steps
        stepsFromApi?.forEach((value, key) => {
            stepsFromApi?.set(key, value.replace(/<p><\/p>/g, '<br>'));
        });

        steps.push({
            element: '.header-container',
            intro: 'Dummy step because the first step is the modal outside these steps',
            position: 'bottom'
        });
        steps.push({
            element: '.one',
            intro: stepsFromApi?.get(2),
            position: 'bottom'
        });
        steps.push({
            element: '.search-bar-murphy > input',
            intro: stepsFromApi?.get(3),
            position: 'bottom'
        });
        steps.push({
            element: '.course-carousel-container > .course-card-container:first-child',
            intro: stepsFromApi?.get(4),
            position: 'bottom'
        });
        steps.push({
            element: '.course-carousel-container > .course-card-container:first-child > .course-card-footer',
            intro: stepsFromApi?.get(5),
            position: 'bottom'
        });

        // set tooltipClass to 'tooltip-class' on all steps
        steps.forEach(step => {
            step.tooltipClass = 'tooltip-class';
        });

        return steps;
    };

    const onExit = (stepIndex: number) => {
        console.log('Onboarding is exited from step ' + stepIndex);

        // sometimes introjs calls onExit by itself, but with -1 as stepIndex. When the user does it, it will be with a positive number.
        if (stepsRef?.current != null && stepIndex > -1) {
            if (stepsRef?.current.introJs._lastShowElementTimer) {
                onComplete();
            }
        }
    };

    const onAfterStep = (nextStepIndex: number, nextElement: Element) => {

        if (nextStepIndex === 0) {
            setCurrentStep(OnboardingFlowIds.Academy, nextStepIndex);
            setStepsEnabled(false);
            window.location.reload();
            return false;
        }

        setCurrentStep(OnboardingFlowIds.Academy, nextStepIndex);
    }
    const onBeforeStep = (nextStepIndex: number, nextElement: Element): false | void | Promise<false | void> => {
        if (stepsRef.current) {
            stepsRef.current.updateStepElement(nextStepIndex);
        }
    }


    return (stepsEnabled && <Steps
        ref={stepsRef}
        enabled={stepsEnabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
        onComplete={onComplete}
        onAfterChange={onAfterStep}
        onBeforeChange={onBeforeStep}
        options={{
            hideNext: false,
            hidePrev: true,
            exitOnOverlayClick: false,
            showBullets: false,
            doneLabel: 'Finish'
        }}
    />);
}

export default CrisisRegistrationOnboardingSteps;