import { useQuery } from "@tanstack/react-query";
import { useInjection } from "../../contexts/InversifyContext";
import CustomersApiType, {
  ICustomersApi,
} from "../../interfaces/ICustomersApi";
import QueryKeys from "./QueryKeys";

export function useGetCustomers(enabled: boolean, retry: boolean) {
  const customerApi = useInjection<ICustomersApi>(
    CustomersApiType.ICustomersApi
  );
  return useQuery({
    queryKey: QueryKeys.lists(),
    queryFn: () => customerApi.getCustomers(),
    enabled: enabled && customerApi != null,
    retry: retry && customerApi != null ? 10 : false,
  });
}

export function useGetChildCustomers(customerId: string) {
  const customerApi = useInjection<ICustomersApi>(
    CustomersApiType.ICustomersApi
  );
  return useQuery({
    queryKey: QueryKeys.children(customerId),
    queryFn: () => customerApi.getChildCustomers(customerId),
    enabled: customerId != null && customerApi != null,
  });
}

export function useGetCustomer(id: string | null | undefined) {
  const customerApi = useInjection<ICustomersApi>(
    CustomersApiType.ICustomersApi
  );
  return useQuery({
    queryKey: QueryKeys.detail(id),
    queryFn: () => customerApi.getCustomer(id),
    enabled: !!id && customerApi != null,
    staleTime: 60 * 60 * 1000,
  });
}
