import { useState, useEffect, FunctionComponent } from 'react';
import {
  useParams,
} from 'react-router-dom';
import dayjs from 'dayjs';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import Input from '@murphy-frontend/web-core/components/Input';
import Textarea from '@murphy-frontend/web-core/components/TextArea';
import Button from '@murphy-frontend/web-core/components/Button';
import { getConvertedDate } from '@murphy-frontend/common/services/TimeService';
import { useGetSendOutResponse } from '../../../common/api/SendOutResponses/queries';
import { useEditSendOutResponse } from '../../../common/api/SendOutResponses/mutations';
import { useUserPreferences } from '../../../common/contexts/UserPreferencesContext';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import React from 'react';
import { SendOutResponse } from '../../../common/api/SendOutResponses/SendOutResponseApi';
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';

interface ResponseViewModel {
  originalDate?: any,
  dateAnswered?: any,
  responseMessage: string,
  responseAlternatives: string[],
  responseQuestion: string,
  responseAnswer: string,
  responseSelectedAlternative: string,
}

const MBSResponse: FunctionComponent = () => {
  const { id } = useParams();

  const { translations, timeZone } = useUserPreferences();
  const { openModal } = useModal();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [freeTextAnswer, setFreeTextAnswer] = useState('');
  const [pbsResponse, setPbsResponse] = useState<ResponseViewModel>({} as ResponseViewModel);
  const [selectedAlternative, setSelectedAlternative] = useState(null);
  const [isAlreadyAnswered, setIsAlreadyAnswered] = useState(false);
  const [selectedAlternativeError, setSelectedAlternativeError] = useState(null);

  const getSendOutResponseSuccess = (data: SendOutResponse, tz: string) => {
    const mappedData: ResponseViewModel = {
      originalDate: dayjs.utc(data.dateansweredUtc),
      dateAnswered: data.dateansweredUtc ? getConvertedDate(dayjs.utc(data.dateansweredUtc), tz) : null,
      responseMessage: data.responseQuestion,
      responseAlternatives: data.responseAlternatives,
      responseQuestion: data.Question,
      responseAnswer: data.responseAnswer,
      responseSelectedAlternative: data.responseSelectedAlternative,
    };

    if (mappedData.responseSelectedAlternative) {
      setSelectedAlternative(mappedData.responseSelectedAlternative);
    }
    if (mappedData.responseAnswer) {
      setFreeTextAnswer(mappedData.responseAnswer);
    }

    setPbsResponse(mappedData);
    setIsAlreadyAnswered(!!data.dateansweredUtc);
    setIsLoading(false);
  };

  const {
    data: sendOutResponseData, isLoading: sendOutResponseIsLoading,
    isError: sendOutResponseIsError, error: sendOutResponseError,
  } = useGetSendOutResponse(id);

  const { mutate: editSendOutResponse, isPending: editSendOutResponseIsLoading } = useEditSendOutResponse(id);

  useEffect(() => {
    const mappedData = { ...pbsResponse, dateAnswered: pbsResponse.originalDate ? getConvertedDate(pbsResponse.originalDate, timeZone) : null };
    setPbsResponse(mappedData);
  }, [timeZone]);

  const onFail = (errorData: any) => {
    if (errorData.responseText) {
      if (errorData.responseText === '"Already answered"') {
        const modalConf: ModalConfiguration = {
          title: translations.alreadyanswered,
          body: <AlreadyAnsweredBody />,
          buttonText: 'Ok',
          type: 'warning',
        };

        openModal(modalConf);
        return;
      }
    }

    if (errorData.statusText) {
      setError(errorData.statusText);
    } else {
      setError('Error');
    }
    setIsLoading(false);
  };

  const onSuccess = () => {
    setIsLoading(false);
    setIsAlreadyAnswered(true);
    const modalConf: ModalConfiguration = {
      title: translations.answerreceived,
      body: <SuccessBody />,
      buttonText: 'Ok',
      type: 'info',
    };

    openModal(modalConf);
    setIsLoading(true);
  };

  useEffect(() => {
    if (!sendOutResponseIsLoading && timeZone) {
      getSendOutResponseSuccess(sendOutResponseData, timeZone);
    }
  }, [sendOutResponseIsLoading, sendOutResponseData, timeZone]);

  const submit = () => {
    const editSendOutResponseRequest = {
      responseAnswer: freeTextAnswer,
      responseSelectedAlternative: selectedAlternative,
    };

    editSendOutResponse(editSendOutResponseRequest, {
      onSuccess: () => {
        onSuccess();
      },
      onError: (err) => {
        onFail(err.response.data);
      },
    });
  };

  const SuccessBody = () => (
    <>
      <p>
        {translations.youcannowclosethispage}
        !
      </p>
    </>
  );

  const AlreadyAnsweredBody = () => (
    <>
      <p>
        {translations.alreadyansweredyoucannowclosethispage}
        !
      </p>
    </>
  );

  const ModalConfirmationBody = () => (
    <>
      <p>
        {translations.thefollowinganswerwillbesent}
        :
      </p>
      <br />
      <p>
        <b>
          {translations.message}
          :
        </b>
        <br />
        {pbsResponse.responseMessage}
      </p>
      {pbsResponse.responseQuestion ? (
        <>
          <br />
          <p>
            <b>
              {translations.question}
              :
            </b>
            <br />
            {pbsResponse.responseQuestion}
          </p>
        </>
      ) : null}
      <br />
      {selectedAlternative
        ? (
          <p>
            <b>
              {translations.youranswer}
              :
            </b>
            <br />
            {selectedAlternative}
          </p>
        ) : null}
      {freeTextAnswer
        ? (
          <>
            <br />
            <p>
              <b>
                {translations.yourfreetextanswer}
                :
              </b>
              <br />
              {freeTextAnswer}
            </p>

          </>
        ) : null}
    </>
  );

  const onFreeTextChanged = (event) => {
    setFreeTextAnswer(event.target.value);
  };

  const onClickSubmit = (e) => {
    e.preventDefault();
    if (pbsResponse.responseAlternatives && pbsResponse.responseAlternatives.filter((p) => p !== '').length > 0 && !selectedAlternative) {
      setSelectedAlternativeError(translations.mandatory);
      return;
    }

    const modalConf: ModalConfiguration = {
      title: translations.confirm,
      body: <ModalConfirmationBody />,
      buttonText: translations.send,
      type: 'info',
      okCallback: submit,
    };

    openModal(modalConf);
  };

  const onChangeAnswer = (e) => {
    const selectedVal = e.target.value;
    if (selectedVal) {
      setSelectedAlternativeError('');
    }
    setSelectedAlternative(selectedVal);
  };

  const ErrorMessage = () => (<p>{error}</p>);

  useEffect(() => {
    if (error) {
      const modalConf: ModalConfiguration = {
        title: 'Error',
        body: <ErrorMessage />,
        buttonText: 'Ok',
        type: 'error',
        okCallback: () => { setError(null); },
      };

      openModal(modalConf);
    }
  }, [error]);

  if (isLoading || !translations || !timeZone) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }


  return (
    <div className="pbs-wrapper">
      <h1>{translations.sendoutresponse}</h1>
      <span className="gradient" />
      <form className="pbs-grid">
        <div>
          <fieldset className="form-fieldset response-form">
            <legend className="form-legend">{translations.message}</legend>
            <div className="text-already-answered">
              {pbsResponse.responseMessage}
            </div>
          </fieldset>
        </div>
        {pbsResponse.responseQuestion
          ? (
            <div>
              <fieldset className="form-fieldset response-form">
                <legend className="form-legend">{translations.question}</legend>
                <div className="text-already-answered">
                  {pbsResponse.responseQuestion}
                </div>
              </fieldset>
            </div>
          ) : null}
        <div>
          <fieldset className="form-fieldset response-form">
            <legend className="form-legend">{translations.answer}</legend>

            {isAlreadyAnswered ? (
              <div className="text-already-answered">
                <i>
                  {translations.youanswered}
                  {' '}
                  {pbsResponse.dateAnswered}
                </i>
              </div>
            ) : null}
            <div>
              {pbsResponse.responseAlternatives
                ? pbsResponse.responseAlternatives.filter((p) => p !== '').map((p) => (
                  <div key={p} className="radio-button-container">
                    <Input
                      disabled={isAlreadyAnswered}
                      type="radio"
                      handleChange={onChangeAnswer}
                      title={p}
                      name={translations.answer.toLowerCase()}
                      val={p}
                      checked={selectedAlternative === p}
                      defaultChecked={selectedAlternative === p}
                      errorMessage={selectedAlternativeError} direction="column"
                    />
                  </div>
                ))
                : null}
            </div>
            <Textarea
              val={freeTextAnswer}
              disabled={isAlreadyAnswered}
              title={translations.freetext}
              handleChange={onFreeTextChanged}
            />
          </fieldset>
        </div>
        {!isAlreadyAnswered ? (
          <div>
            <Button
              isLoading={editSendOutResponseIsLoading}
              disabled={isAlreadyAnswered}
              buttonStyle="btn--primary"
              buttonSize="btn--large"
              type="button"
              onClick={onClickSubmit}>
              {translations.send}
            </Button>
          </div>
        ) : null}

      </form>
    </div>
  );
}

export default MBSResponse;