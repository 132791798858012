import queryKeys from './queryKeys';
import { useInjection } from '@murphy-frontend/common/contexts/InversifyContext';
import ApiServiceType, { IApiService } from '@murphy-frontend/common/interfaces/IApiService';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { RequirementApi } from './RequirementApi';

export function useGetRequirementBlocks() {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const requirementApi = new RequirementApi(apiService);
    const queryKey = queryKeys.blocks();

    return useQuery({
        queryKey: queryKey,
        queryFn: () => requirementApi.getRequirementBlocks(),
        // Optionally add other configurations like staleTime if needed
    });
}

export function useGetCustomerBlocks(customerId: string) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const requirementApi = new RequirementApi(apiService);
    const queryKey = queryKeys.customerBlocks(customerId);

    return useQuery({
        queryKey: queryKey,
        queryFn: () => requirementApi.getCustomerRequirementBlocks(customerId),
        enabled: !!customerId // Enable the query only if customerId is truthy
    });
}

export function useGetRequirementLevels(customerId: string) {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const requirementApi = new RequirementApi(apiService);
    const queryKey = queryKeys.requirementLevels(customerId);

    return useQuery({
        queryKey: queryKey,
        queryFn: () => requirementApi.getRequirementLevels(customerId),
        enabled: !!customerId // Enable the query only if customerId is truthy
    });
}

export function useGetRequirementCategories() {
    const apiService = useInjection<IApiService>(ApiServiceType.IApiService);
    const requirementApi = new RequirementApi(apiService);
    const queryKey = queryKeys.requirementCategories();

    return useQuery({
        queryKey: queryKey,
        queryFn: () => requirementApi.getRequirementCategories(),
    });
}