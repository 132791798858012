const queryKeys = {
    all: ['requirements'],
    blocks: () => [...queryKeys.all, 'blocks'],
    block: (blockId: number) => [...queryKeys.all, 'block', { blockId }],
    detail: (requirementId: number) => [...queryKeys.all, 'detail', { requirementId }],
    customerBlocks: (customerId: string) => [...queryKeys.all, 'customerBlocks', { customerId }],
    requirementLevels: (customerId: string) => [...queryKeys.all, 'requirementLevels', { customerId }],
    requirementCategories: () => [...queryKeys.all, 'requirementCategories'],
};

export default queryKeys;
