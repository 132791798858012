import React, { useState, useEffect, FunctionComponent } from 'react';
import {
  useParams,
} from 'react-router-dom';
import Input from '@murphy-frontend/web-core/components/Input';
import Spinner from '@murphy-frontend/web-core/components/Spinner';
import SearchBar from '@murphy-frontend/web-core/components/SearchBar';
import { useGetUsers } from '@murphy-frontend/common/api/Users/Queries';
import { useGetContacts } from '../../../common/api/Contacts/queries';
import { useGetContactGroupContacts, useGetContactGroupUsers, useGetContractGroup } from '@murphy-frontend/common/api/ContactGroups/Queries';
import {
  useAddContactToContactGroup, useAddUserToContactGroup, useDeleteContactFromContactGroup, useDeleteUserFromContactGroup, useEditContactGroup,
} from '@murphy-frontend/common/api/ContactGroups/Mutations';
import { useModal } from '@murphy-frontend/web-core/contexts/ModalContext';
import { useUserPreferences } from '../../../common/contexts/UserPreferencesContext';
import { ModalConfiguration } from '@murphy-frontend/web-core/components/Modal';
import { useCustomer } from '../../../common/contexts/CustomerContext';
import { ContactListPaginated } from '../../Contacts/components/ContactListPaginated';
import { EditContactGroupRequest } from '../../../../../common/interfaces/IContactGroupApi';

const ContactGroup: FunctionComponent = () => {
  const { id } = useParams();
  const { customer } = useCustomer();

  const { openModal } = useModal();
  const { translations } = useUserPreferences();

  const [name, setName] = useState('');
  const [oldName, setOldName] = useState('');
  const [nameErrorMessage, setNameErrorMessage] = useState('');

  const [error, setError] = useState(null);
  const [contactGroup, setContactGroup] = useState(null);
  const [contactGroupContacts, setContactGroupContacts] = useState([]);
  const [contactGroupUsers, setContactGroupUsers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [filteredContactsAndUsersInGroup, setFilteredContactsAndUsersInGroup] = useState([]);
  const [contactGroupUsersAndContacts, setContactGroupUsersAndContacts] = useState([]);
  const [currentSearchText, setCurrentSearchText] = useState('');
  const [currentSearchAddedText, setCurrentSearchAddedText] = useState('');

  const {
    isLoading: isLoadingUsers, isError: isErrorUsers, data: dataUsers, error: errorUsers,
  } = useGetUsers(customer.Id);

  const {
    isLoading: isLoadingContacts, isError: isErrorContacts, data: dataContacts, error: errorContacts,
  } = useGetContacts(customer.Id);

  const {
    isLoading: isLoadingContactGroupUsers, isError: isErrorContactGroupUsers,
    data: dataContactGroupUsers, error: errorContactGroupusers,
  } = useGetContactGroupUsers(id);

  const {
    isLoading: isLoadingContactGroupContacts, isError: isErrorContactGroupContacts,
    data: dataContactGroupContacts, error: errorContactGroupContacts,
  } = useGetContactGroupContacts(id);

  const {
    isLoading: isLoadingContactGroup, isError: isErrorContactGroup,
    data: dataContactGroup, error: errorContactGroup,
  } = useGetContractGroup(id);

  const { mutate: addUserToGroup, isPending: addUserToGroupIsLoading } = useAddUserToContactGroup(id, customer.Id);
  const { mutate: deleteUserFromGroup, isPending: deleteUserFromGroupIsLoading } = useDeleteUserFromContactGroup(id, customer.Id);
  const { mutate: deleteContactFromGroup, isPending: deleteContactFromGroupIsLoading } = useDeleteContactFromContactGroup(id, customer.Id);
  const { mutate: addContactToGroup, isPending: addContactToGroupIsPending } = useAddContactToContactGroup(id, customer.Id);
  const { mutate: editContactGroup, isPending: editContactIsPending } = useEditContactGroup(id, customer.Id);

  const onNameChanged = (event) => {
    if (!event.target.value) {
      setNameErrorMessage(translations.mandatory);
    } else {
      setNameErrorMessage('');
    }
    setName(event.target.value);
  };

  const onGetContactGroupSuccess = (data) => {
    const mappedContactGroup = {
      id: data.ID,
      name: data.Name,
    };

    setName(mappedContactGroup.name);
    setOldName(mappedContactGroup.name);
    setContactGroup(mappedContactGroup);
  };

  const onGetContactGroupContactsSuccess = (data) => {
    const mappedData = data.map((p) => ({
      name: p.Name,
      id: p.ID,
      email: p.Email,
      mobile: p.MobileNr,
      type: 'contact',
      selectedOptions: p.SelectedOptions.map((q) => ({
        id: q.ID,
        value: q.Value,
        optionSetId: q.OptionSetId,
        optionSetName: q.OptionSetName,
      })),
    }));

    mappedData.sort((a, b) => a.name - b.name);
    setContactGroupContacts(mappedData);
  };

  const onGetContactGroupUsersSuccess = (data) => {
    const mappedData = data.map((p) => ({
      name: p.UserName,
      id: p.ID,
      customeruserid: p.CustomerUserId.toUpperCase(),
      email: p.Email,
      mobile: p.MobilePhone,
      type: 'user',
    }));

    mappedData.sort((a, b) => a.name - b.name);
    setContactGroupUsers(mappedData);
  };

  const onGetContactsSuccess = (data) => {
    const mappedData = data.map((p) => ({
      id: p.ID,
      name: p.Name,
      email: p.Email,
      mobile: p.MobileNr,
      type: 'contact',
      selectedOptions: p.SelectedOptions.map((q) => ({
        id: q.ID,
        value: q.Value,
        optionSetId: q.OptionSetId,
        optionSetName: q.OptionSetName,
      })),
    }));

    mappedData.sort((a, b) => a.name - b.name);
    setContacts(mappedData);
  };

  const onGetUsersSuccess = (data) => {
    const mappedData = data.map((p) => ({
      id: p.CustomerUserId.toUpperCase(),
      name: p.Username,
      email: p.Email,
      mobile: p.Phonenumber,
      type: 'user',
    }));

    mappedData.sort((a, b) => a.name - b.name);
    setUsers(mappedData);
  };

  const onDeleteContactFromGroup = (contactOrUserId, type) => {
    const parsedId = parseInt(contactOrUserId, 10);
    if (type === 'contact') {
      deleteContactFromGroup(parsedId);
    } else if (type === 'user') {
      deleteUserFromGroup(parsedId);
    }
  };

  const onAddToGroup = (contactOrUserId, type) => {
    const parsedContactGroupId = parseInt(id, 10);
    if (type === 'contact') {
      const parsedId = parseInt(contactOrUserId, 10);
      addContactToGroup(parsedId);
    } else if (type === 'user') {
      const createContactGroupUserRequest = {
        ContactGroupId: parsedContactGroupId,
        CustomerUserId: contactOrUserId,
      };
      addUserToGroup(createContactGroupUserRequest);
    }
  };

  function ErrorMessage() {
    return <p>{error}</p>;
  }

  const onSearchChanged = (event) => {
    const newValue = event.target.value;
    setCurrentSearchText(newValue);
  };

  const onSearchChangedAdded = (event) => {
    const newValue = event.target.value;
    setCurrentSearchAddedText(newValue);
  }

  useEffect(() => {
    if (!isLoadingContactGroup) {
      onGetContactGroupSuccess(dataContactGroup);
    }
  }, [dataContactGroup]);

  useEffect(() => {
    if (!isLoadingContactGroupContacts) {
      onGetContactGroupContactsSuccess(dataContactGroupContacts);
    }
  }, [dataContactGroupContacts]);

  useEffect(() => {
    if (!isLoadingContactGroupUsers) {
      onGetContactGroupUsersSuccess(dataContactGroupUsers);
    }
  }, [dataContactGroupUsers]);

  useEffect(() => {
    if (!isLoadingContacts) {
      onGetContactsSuccess(dataContacts);
    }
  }, [dataContacts]);

  useEffect(() => {
    if (!isLoadingUsers) {
      onGetUsersSuccess(dataUsers);
    }
  }, [dataUsers]);

  useEffect(() => {
    let contactsNotInGroup = contacts
      .filter((p) => !contactGroupContacts.map((q) => q.id).includes(p.id));

    let usersNotInGroup = users
      .filter((p) => !contactGroupUsers.map((q) => q.customeruserid).includes(p.id));

    if (currentSearchText) {
      const searchValue = currentSearchText.toUpperCase();
      contactsNotInGroup = contactsNotInGroup.filter((p) => p.name.toUpperCase().startsWith(searchValue));
      usersNotInGroup = usersNotInGroup.filter((p) => p.name.toUpperCase().startsWith(searchValue));
    }

    const mergedUsersAndContactsNotInGroup = [...contactsNotInGroup, ...usersNotInGroup];
    mergedUsersAndContactsNotInGroup.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) - (a.name.toUpperCase() < b.name.toUpperCase()));

    setFilteredContacts(mergedUsersAndContactsNotInGroup);

    const mergedUsersAndContactsInGroup = [...contactGroupContacts, ...contactGroupUsers];
    mergedUsersAndContactsInGroup.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) - (a.name.toUpperCase() < b.name.toUpperCase()));
    setContactGroupUsersAndContacts(mergedUsersAndContactsInGroup);
  }, [contactGroupContacts, contactGroupUsers, users, contacts, currentSearchText]);


  useEffect(() => {
    let contactsOrUsersInGroup = contactGroupUsersAndContacts;

    if (currentSearchAddedText) {
      const searchValueAdded = currentSearchAddedText.toUpperCase();
      contactsOrUsersInGroup = contactGroupUsersAndContacts.filter((p) => p.name.toUpperCase().startsWith(searchValueAdded));
    }
    contactsOrUsersInGroup.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) - (a.name.toUpperCase() < b.name.toUpperCase()));
    setFilteredContactsAndUsersInGroup(contactsOrUsersInGroup);

  }, [contactGroupContacts, contactGroupUsers, users, contacts, contactGroupUsersAndContacts, currentSearchAddedText])

  const handleClickSaveName = () => {
    const editContactGroupRequest: EditContactGroupRequest = {
      ID: id,
      Name: name,
    };
    editContactGroup(editContactGroupRequest);
  };

  const handleCancelRename = () => {
    setName(oldName);
  }

  useEffect(() => {
    if (error) {
      const modalConf: ModalConfiguration = {
        title: 'Error',
        body: <ErrorMessage />,
        buttonText: 'Ok',
        type: 'error',
        okCallback: () => { setError(null); },
      };

      openModal(modalConf);
    }
  }, [error]);

  if (isLoadingContactGroup || isLoadingContactGroupContacts || isLoadingContactGroupUsers || isLoadingContacts || isLoadingUsers
    || addUserToGroupIsLoading || deleteUserFromGroupIsLoading || deleteContactFromGroupIsLoading || addContactToGroupIsPending || editContactIsPending) {
    return (<div className="spinner-container-global-center"><Spinner /></div>);
  }

  return (
    <div className="pbs-wrapper">
      <h1>
        {translations.contactgroup}
        {' - '}
        {contactGroup ? <i>{contactGroup.name}</i> : null}
      </h1>
      <form className="pbs-grid">
        <span className="gradient" />
        <div>
          <h3>{translations.name}</h3>
          <Input
            width='medium'
            onClickSave={handleClickSaveName}
            onClickUndo={handleCancelRename}
            showEdit
            direction="column"
            val={name}
            isRequired
            handleChange={(event) => onNameChanged(event)} errorMessage={nameErrorMessage} />
        </div>
        <span className="gradient" />
        <div className="contacts-container">
          <div>
            <h3>{translations.groupmembers}</h3>
            <div className="contacts-controls">
              <div>
                <SearchBar val={currentSearchAddedText} handleChange={onSearchChangedAdded} placeholder={translations['placeholder-lang-search-action']} />
              </div>
            </div>
            <div className="contacts-box">
              <ContactListPaginated
                translations={translations}
                contacts={filteredContactsAndUsersInGroup}
                showDelete
                onClickDelete={onDeleteContactFromGroup}
              />
            </div>
          </div>
          <div className="contacts-box-group">
            <h3>{translations.addmembers}</h3>
            <div className="contacts-controls">
              <div>
                <SearchBar val={currentSearchText} handleChange={onSearchChanged} placeholder={translations['placeholder-lang-search-action']} />
              </div>
            </div>
            <div className="contacts-box">
              <ContactListPaginated
                translations={translations}
                contacts={filteredContacts}
                showAdd
                onClickAdd={onAddToGroup}
              />
            </div>
          </div>
        </div>

      </form>
    </div>
  );
}

export default ContactGroup;
