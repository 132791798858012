import { inject, injectable } from "inversify";
import ApiServiceType, { IApiService } from "@murphy-frontend/common/interfaces/IApiService";
import { CreateCustomerUserTrainingSessionRequest, IUserTrainingSessionsApi, UpdateCustomerUserTrainingSessionsRequest } from "./IUserTrainingSessionsApi";

@injectable()
export class UserTrainingSessionsApi implements IUserTrainingSessionsApi {

    constructor(
        @inject(ApiServiceType.IApiService) private apiService: IApiService,
    ) { }

    getUserTrainingSessionsByCustomerUserId = async (customerUserId: string) => {
        const url = `api/Training/CustomerUserTrainingSession?customerUserId=${customerUserId}`;
        const { data } = await this.apiService.callApi(url, 'GET');
        return data;
    }

    updateUserTrainingSession = (updateUserTrainingSessionRequest: UpdateCustomerUserTrainingSessionsRequest) => {
        const url = `api/Training/CustomerUserTrainingSession/${updateUserTrainingSessionRequest.Id}`;
        return this.apiService.callApi(url, 'PUT', updateUserTrainingSessionRequest);
    }

    createUserTrainingSession = (createUserTrainingSessionRequest: CreateCustomerUserTrainingSessionRequest) => {
        const url = 'api/Training/CustomerUserTrainingSession';
        return this.apiService.callApi(url, 'POST', createUserTrainingSessionRequest);
    }

    deleteUserTrainingSession = (id: number) => {
        const url = `api/Training/CustomerUserTrainingSession/${id}`;
        return this.apiService.callApi(url, 'DELETE');
    }
}