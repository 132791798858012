import { FunctionComponent } from "react";
import TitleWithControls from "./TitleWithControls";
import { DroneComponent, DroneComponentText } from "@murphy-frontend/common/api/DroneEvents/types";
import React from "react";

interface TextComponentProps {
    eventFlowBlock: DroneComponentText;
}

const TextComponent: FunctionComponent<TextComponentProps> = (
    {
        eventFlowBlock,
    }
) => {

    const content = <div className="m-text">
        {eventFlowBlock.Content}
    </div>;

    return (<div className="m-spacing">
        <div className="m-text">
            <TitleWithControls
                content={content}
            />
        </div>
    </div>);
}

export default TextComponent;