import * as React from 'react';
import Button from '@murphy-frontend/web-core/components/Button';
import CardFooter from "../MainLog/CardFooter";
import { FunctionComponent } from "react";
import { Party } from '../../api/PartyApi';
import UpsertPartyComponent from './UpsertPartyComponent';
import PartyFormatted from './PartyFormatted';

interface PartyExpandedContentProps {
    party: Party,
    dashboardId: string,
    openModal: any,
    closeModal: any,
    translations: Record<string, string>,
}

const PartyExpandedContent: FunctionComponent<PartyExpandedContentProps> = ({ party, dashboardId, openModal, closeModal, translations }) => {

    const onClickEdit = () => {
        const upsertPartyComponent =
            <UpsertPartyComponent
                party={party}
                translations={translations}
                dashboardId={dashboardId}
                onSuccess={() => closeModal()} />;

        const configuration = {
            centerContent: false,
            hideButton: true,
            body: upsertPartyComponent,
            title: translations['class-lang-edit-group'],
        }
        openModal(configuration);
    }

    let footer;
    if (party.archived !== true) {
        const button =
            <Button
                onClick={() => onClickEdit()}>
                {translations['class-lang-button-edit']}
            </Button>
        footer = <CardFooter rightContent={button} />
    }

    return <PartyFormatted party={party} translations={translations} cardFooter={footer} />
}

export default PartyExpandedContent;