import React, { FunctionComponent } from 'react';
import {
  RiPencilFill, RiDeleteBin7Fill,
} from 'react-icons/ri';
import { IconContext } from 'react-icons/lib';

interface ContactGroupListItemProps {
  id: any,
  name: string,
  onClickEdit: (id: any) => void,
  onClickDelete: (id: any) => void,
}

const ContactGroupListItem: FunctionComponent<ContactGroupListItemProps> = ({
  id, name, onClickEdit, onClickDelete,
}) => {
  return (
    <li className="contact-list-item" key={id}>
      <div className="contact-item">
        <span className="recipient-item-text">
          <strong>{name}</strong>
        </span>
        <div className="right-container">
          <IconContext.Provider value={{ className: 'contact-item-icon cursor-pointer' }}>
            <RiPencilFill onClick={() => onClickEdit(id)} />
          </IconContext.Provider>
          <IconContext.Provider value={{ className: 'contact-item-icon cursor-pointer' }}>
            <RiDeleteBin7Fill onClick={() => onClickDelete(id)} />
          </IconContext.Provider>
        </div>
      </div>
    </li>
  );
}

export default ContactGroupListItem;