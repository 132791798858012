import * as React from 'react';
import Button from '@murphy-frontend/web-core/components/Button';
import CardFooter from "../MainLog/CardFooter";
import { FunctionComponent } from "react";
import { Goals } from '../../api/GoalsApi';
import UpsertGoalsComponent from './UpsertGoalsComponent';
import GoalsFormatted from './GoalsFormatted';
import { GoalTypes } from '@murphy-frontend/common/enums';

interface GoalsExpandedContentProps {
    goals: Goals,
    dashboardId: string,
    openModal: any,
    closeModal: any,
    translations: Record<string, string>,
}

const GoalsExpandedContent: FunctionComponent<GoalsExpandedContentProps> = ({ goals, dashboardId, openModal, closeModal, translations }) => {

    const onClickEdit = () => {
        const upsertGoalsComponent =
            <UpsertGoalsComponent
                translations={translations}
                dashboardId={dashboardId}
                goals={goals}
                onSuccess={() => closeModal()}
                goalType={goals.goalType} />;

        const title = goals.goalType === GoalTypes.ComGoal ? translations['lang-com-goal'] : translations['lang-goal-title'];

        const configuration = {
            centerContent: false,
            hideButton: true,
            body: upsertGoalsComponent,
            title: title,
        }
        openModal(configuration);
    }

    let footer;
    if (goals.archived !== true) {
        const button =
            <Button
                onClick={() => onClickEdit()}>
                {translations['class-lang-button-edit']}
            </Button>
        footer = <CardFooter rightContent={button} />
    }

    return <GoalsFormatted goals={goals} translations={translations} cardFooter={footer} />
}

export default GoalsExpandedContent;