import { FunctionComponent } from "react";
import StandardContent from "./StandardContent";
import React from "react";
import MurphySelect from "@murphy-frontend/web-core/components/MurphySelect/MurphySelect";
import RequirementPyramidContent from "./RequirementPyramidContent";
import { OptionType } from "@murphy-frontend/web-core/components/MurphySelect/ControlledFormSelect";
import { CustomerRequirementLevelDto } from "../api/models";
import { IconContext } from "react-icons/lib";
import { RiPencilFill } from "react-icons/ri";

interface LevelContentProps {
    currentLevel: CustomerRequirementLevelDto;
    setCurrentLevel: (level: number) => void;
    requirementLevels: CustomerRequirementLevelDto[];
    translations: Record<string, string>;
    partsRemainingToNextLevel: number;
    isEditingLevel: boolean;
    setIsEditingLevel: (isEditingLevel: boolean) => void;
    nextLevel: number,
    editIconAdditionalClasses?: string
}

const LevelContent: FunctionComponent<LevelContentProps> = (
    {
        currentLevel,
        setCurrentLevel,
        requirementLevels,
        translations,
        partsRemainingToNextLevel,
        isEditingLevel,
        setIsEditingLevel,
        nextLevel,
        editIconAdditionalClasses
    }
) => {
    const options = requirementLevels.map(p => ({ label: p.Name, value: p.Level }));

    const handleSetLevel = (level: OptionType): void => {
        setIsEditingLevel(false);
        setCurrentLevel(level.value as number);
    }

    const currentLevelOptionType = options.find(p => p.value === currentLevel?.Level);
    const content = <MurphySelect options={options} value={currentLevelOptionType || options[0]} onChange={handleSetLevel} />;

    const onClickEdit = () => {
        setIsEditingLevel(!isEditingLevel);
    }

    const editIconClasses = `dashboard-table-action-status-icon party-icon ${editIconAdditionalClasses}`;
    const editIcon = <IconContext.Provider value={{ className: editIconClasses }}>
        <RiPencilFill />
    </IconContext.Provider>;

    const title = <div className='vertical-aligner gap-10'>
        <div>{currentLevel ? `${translations.currentgoal}: ${currentLevel.Name}` : "No goal level chosen"}</div>
        <div onClick={onClickEdit}>{editIcon}</div>
    </div>

    const nextLevelName = requirementLevels.find(p => p.Level === nextLevel)?.Name;
    const bottomText = nextLevelName ? `${partsRemainingToNextLevel} ${translations.partsremainingtonextlevel} (${nextLevelName})`
        : ``;


    const standardContent = <StandardContent
        title={title}
        content={isEditingLevel ? content : null}
        contentHeight={isEditingLevel ? 80 : 0}
        bottomElement={
            <>
                <p>{bottomText}</p>
            </>
        }
    />;

    return (<RequirementPyramidContent content={standardContent} />);
}

export default LevelContent;