import { BlobProvider, usePDF } from '@react-pdf/renderer';
import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';
import { StatusCardStatusService } from '../../StatusCards/StatusCardStatusService';
import { MainLogCard } from '../models';
import MainLogPdfReport from './MainLogPdfReport';

interface ExportPdfComponentProps {
    dashboardName: string,
    logCards: MainLogCard[],
    translations: Record<string, string>,
    getBlob: (blob: Blob) => void
    statusCardStatusService: StatusCardStatusService,
}

const ExportPdfComponent: FunctionComponent<ExportPdfComponentProps> = ({ dashboardName, logCards, translations, getBlob, statusCardStatusService }) => {
    return (<BlobProvider document={MainLogPdfReport(dashboardName, logCards, translations, statusCardStatusService)}>
        {({ blob, url, loading, error }) => {
            getBlob(blob);
            // Do whatever you need with blob here
            return <></>;
        }}
    </BlobProvider>);
}

export default ExportPdfComponent;