import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "@murphy-frontend/web-core/components/Input";
import Button from "@murphy-frontend/web-core/components/Button";
import Textarea from "@murphy-frontend/web-core/components/TextArea";
import dayjs from "dayjs";
import MurphyDatePicker from "@murphy-frontend/web-core/components/MurphyDatePicker";
import MurphyCheckbox from "../../../../common/components/MurphyCheckbox";
import { ActionCardStatuses } from "@murphy-frontend/common/enums";
import ActionsStatusSelector from "./ActionsStatusSelector";
import { DashboardAlertUser } from "@murphy-frontend/common/interfaces/IDashboardApi";
import DOMPurify from "dompurify";
import CreateableMurphySelect from "@murphy-frontend/web-core/components/MurphySelect/CreateableMurphySelect";
import { ActionMeta, PropsValue } from "react-select";
import CommunicationTypeFilter from "@murphy-frontend/web-core/components/CommunicationTypeFilter";
import { enabledFeatures } from "../../../../common/variables";

yup.addMethod(yup.string, "html", function () {
  return this.test({
    name: "html",
    exclusive: true,
    message: "Invalid text",
    test: (value) => {
      DOMPurify.sanitize(value);
      if (DOMPurify.removed.length > 0) {
        return false;
      }
      return true;
    },
  });
});

const actionsCardsSchema = yup.object().shape({
  Title: yup.string().required(),
  Message: yup.string().nullable().html(),
  Status: yup.number(),
  ProvidedBy: yup.string().nullable().notRequired(),
  Owner: yup.string().notRequired(),
  isDecision: yup.boolean(),
  Important: yup.boolean(),
  AlternatedTime: yup.date().nullable().notRequired(),
  isCommunication: yup.boolean(),
});

export interface ActionsCardFormDto {
  ID: number;
  Title: string;
  Message: string;
  Status: number;
  ProvidedBy?: string;
  ProvidedByCustomerId?: string;
  ProvidedByIsUser?: boolean;
  Owner: string;
  isDecision: boolean;
  Important: boolean;
  AlternatedTime?: Date;
  ParentId?: number;
  isCommunication: boolean;
  isMobileChecked?: boolean;
  isEmailChecked?: boolean;
  isNotificationChecked?: boolean;
}

type ActionsCardFormProps = {
  actionsCard?: ActionsCardFormDto;
  onSubmit: (data: ActionsCardFormDto) => void;
  translations?: any;
  onClickArchive: (id: number) => void;
  dashboardAlertUsers?: DashboardAlertUser[] | undefined;
  isLoading?: boolean;
  latestSentNotificationText?: string;
};

const ActionsCardsForm = ({
  isLoading,
  actionsCard,
  onSubmit,
  translations,
  onClickArchive,
  dashboardAlertUsers,
  latestSentNotificationText,
}: ActionsCardFormProps) => {
  const {
    watch,
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields, isDirty },
    reset,
  } = useForm<ActionsCardFormDto>({
    resolver: yupResolver(actionsCardsSchema),
    defaultValues: {
      Title: "",
      Message: "",
      Status: ActionCardStatuses.NotStarted,
      ProvidedBy: "",
      Owner: "",
      isDecision: false,
      Important: false,
      isCommunication: false,
    },
  });

  const watchProvidedByIsUser = watch("ProvidedByIsUser");
  const watchProvidedBy = watch("ProvidedBy");

  const [mobileAllChecked, setMobileAllChecked] = useState(false);
  const [emailAllChecked, setEmailAllChecked] = useState(false);
  const [notificationAllChecked, setNotificationAllChecked] = useState(false);
  const [initialProvidedBy, setInitialProvidedBy] = useState<string>("");

  useEffect(() => {
    if (actionsCard) {
      const defaults = {
        Title: actionsCard.Title,
        Message: actionsCard.Message,
        Status: actionsCard.Status,
        ProvidedBy: actionsCard.ProvidedBy,
        ProvidedByIsUser: actionsCard.ProvidedByIsUser,
        Owner: actionsCard.Owner,
        isDecision: actionsCard.isDecision,
        Important: actionsCard.Important,
        AlternatedTime: actionsCard.AlternatedTime
          ? dayjs(actionsCard.AlternatedTime).toDate()
          : undefined,
        isCommunication: actionsCard.isCommunication,
      };
      reset(defaults);
      setInitialProvidedBy(actionsCard.ProvidedBy || ""); // set initial value when actionCard is updated
    }
  }, [actionsCard, reset]);

  const onSubmitHandler = (data: ActionsCardFormDto) => {
    data.isEmailChecked = emailAllChecked;
    data.isMobileChecked = mobileAllChecked;
    data.isNotificationChecked = notificationAllChecked;

    onSubmit(data);
  };

  const onChangeProvidedBy = (
    newValue: PropsValue<{ label: string; value: string }>,
    actionMeta: ActionMeta<{ label: string; value: string }>
  ) => {
    if (actionMeta.action === "create-option") {
      console.log("create-option");
      setValue("ProvidedByIsUser", false);
    }
    if (actionMeta.action === "select-option") {
      console.log("select-option");
      setValue("ProvidedByIsUser", true);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="dashboardform-container double-col">
        <div className="dashboardform-container-section">
          <Input
            isControlled
            register={register}
            name="Title"
            isRequired
            title={translations["class-lang-modal-title"]}
            errorMessage={errors.Title?.message}
          />
          <div className="form-input flex-input-container">
            <Controller
              control={control}
              name="Status"
              render={({ field }) => (
                <ActionsStatusSelector
                  translations={translations}
                  currentStatus={field.value}
                  onClickStatus={(status, e) => {
                    field.onChange(status);
                  }}
                  pullRightOnSmallScreen={true}
                />
              )}
            />
            <label>{translations["statusname"]}</label>
          </div>
          <Input
            isControlled
            register={register}
            name="Owner"
            title={translations["class-lang-modal-decision-maker"]}
            errorMessage={errors.Owner?.message}
          />

          {dashboardAlertUsers && dashboardAlertUsers.length > 0 && (
            <div>
              <Controller
                control={control}
                name="ProvidedBy"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <CreateableMurphySelect
                    title={translations["class-lang-modal-responsible"]}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="ProvidedBy"
                    options={dashboardAlertUsers.map((n) => ({
                      label: n.Name,
                      value: n.Id,
                    }))}
                    isLoading={isLoading}
                    onChange={(value, action) => {
                      if (action.action === "clear") {
                        onChange("");
                        return;
                      }
                      if (value === null) {
                        onChange("");
                        return;
                      }

                      onChange(value?.value);
                      onChangeProvidedBy(value, action);
                    }}
                    createLabel="Fritext ansvarig: "
                    onBlur={onBlur}
                    value={
                      dashboardAlertUsers.find((n) => n.Id === value) ===
                        undefined
                        ? {
                          label: value,
                          value: value,
                        }
                        : {
                          label: dashboardAlertUsers.find(
                            (n) => n.Id === value
                          )?.Name,
                          value: value,
                        }
                    }
                    name={name}
                    isMulti={false}
                    errorMessage={errors.ProvidedBy?.message}
                  />
                )}
              />
            </div>
          )}

          {latestSentNotificationText &&
            latestSentNotificationText !== "" &&
            watchProvidedBy === initialProvidedBy && (
              <div className="form-input flex-input-container">
                <div className="input-info-message">
                  {latestSentNotificationText}
                </div>
              </div>
            )}

          {watchProvidedBy && watchProvidedByIsUser === true && (
            <CommunicationTypeFilter
              onCheckAllMobile={() => setMobileAllChecked(!mobileAllChecked)}
              onCheckAllEmail={() => setEmailAllChecked(!emailAllChecked)}
              onCheckAllNotification={() =>
                setNotificationAllChecked(!notificationAllChecked)
              }
              checkAllMobileChecked={mobileAllChecked}
              checkAllEmailChecked={emailAllChecked}
              checkAllNotificationChecked={notificationAllChecked}
              smsDisabled={enabledFeatures.mbs.disableSMS === "true"}
              size="small"
              translations={translations}
              notificationDisabled={enabledFeatures.mbs.disableNotification === "true"}
              emailDisabled={enabledFeatures.mbs.disableEmail === "true"}
            />
          )}

          <div className="form-input flex-input-container">
            <MurphyCheckbox
              isControlled
              control={control}
              name="isDecision"
              errorMessage={errors.isDecision?.message}
            />
            <label>{translations["class-lang-modal-decision-check"]}</label>
          </div>
          <div className="form-input flex-input-container">
            <MurphyCheckbox
              isControlled
              control={control}
              name="isCommunication"
              errorMessage={errors.isCommunication?.message}
            />
            <label>{translations["iscommunication"]}</label>
          </div>
          <MurphyDatePicker
            title={translations["class-lang-modal-alt-time"]}
            isControlled
            control={control}
            name="AlternatedTime"
            showTime={true}
            locale="sv"
            errorMessage={errors.AlternatedTime?.message}
          />
          <div className="form-input flex-input-container">
            <MurphyCheckbox
              isControlled
              control={control}
              name="Important"
              errorMessage={errors.Important?.message}
            />
            <label>{translations["class-lang-modal-check-priority"]}</label>
          </div>
        </div>
        <div className="dashboardform-container-section">
          <Textarea
            isControlled
            rows={24}
            register={register}
            name="Message"
            title={translations["class-lang-modal-description"]}
            errorMessage={errors.Message?.message}
          />
        </div>
        <div className="dashboardform-container-section">
          <div className="form-buttons">
            {actionsCard && (
              <Button
                type="button"
                disabled={isDirty || isLoading}
                onClick={() => onClickArchive(actionsCard.ID)}
                buttonStyle="btn--archive"
                buttonSize="btn--medium"
                isWarningButton={true}
              >
                {translations.archive}
              </Button>
            )}
            {isDirty && (
              <Button
                type="button"
                disabled={!isDirty || isLoading}
                onClick={() => reset()}
                buttonStyle="btn--undo"
                buttonSize="btn--medium"
                isNeutralButton={true}
              >
                {translations.undo}
              </Button>
            )}
          </div>
        </div>
        <div className="dashboardform-container-section">
          <div className="form-buttons save-button">
            <Button
              isLoading={isLoading}
              disabled={!isDirty || (errors && errors.length > 0)}
              type="submit"
              buttonStyle="btn--primary"
              buttonSize="btn--medium"
            >
              {translations.save}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ActionsCardsForm;
