import React, { useState, useEffect, FunctionComponent } from 'react';
import { IconContext } from 'react-icons/lib';
import {
  SiMicrosoftteams,
} from 'react-icons/si';
import { toast, ToastOptions } from 'react-toastify';
import { CustomerUserTrainingSessionStates } from '@murphy-frontend/common/enums';
import { openInNewTab } from '@murphy-frontend/common/utils';
import { UserTrainingSessionView } from '../../CourseCarousel';
import { TrainingSessionModel } from '../../../../api/TrainingSessions/TrainingSessionsApi';
import { LanguageType } from '../../../../contexts/UserPreferencesContext';
import { TimeZoneType, getConvertedDateDayjs } from "@murphy-frontend/common/services/TimeService"
import { useUser } from '../../../../contexts/UserContext';
import Button from '@murphy-frontend/web-core/components/Button';
import { useCreateUserTrainingSession, useEditUserTrainingSession } from '../../../../api/UserTrainingSessions/mutations';
import { CreateCustomerUserTrainingSessionRequest, UpdateCustomerUserTrainingSessionsRequest } from '../../../../api/UserTrainingSessions/IUserTrainingSessionsApi';

interface CourseCardFooterProps {
  bookedTrainingSession: UserTrainingSessionView;
  trainingSessions: TrainingSessionModel[];
  timeZone: TimeZoneType;
  language: LanguageType;
  translations: Record<string, string>;
  selectedTrainingSessionIdHandler: (trainingSessionId: number) => void;
}

const CourseCardFooter: FunctionComponent<CourseCardFooterProps> = ({
  bookedTrainingSession,
  trainingSessions,
  timeZone,
  language,
  translations,
  selectedTrainingSessionIdHandler,
}) => {
  const [selectedTrainingSessionId, setSelectedTrainingSessionId] = useState(-1);
  const [selectedTrainingSessionIsBookable, setSelectedTrainingSessionIsBookable] = useState(false);

  const onSelectTrainingSession = (event) => {
    const trainingSessionId = Number.parseInt(event.target.value, 10);
    const matchingTrainingSession = trainingSessions.find((p) => p.ID === trainingSessionId);
    setSelectedTrainingSessionIsBookable(matchingTrainingSession?.isAvailableToRegister === true);
    setSelectedTrainingSessionId(trainingSessionId);
    selectedTrainingSessionIdHandler(trainingSessionId);
  };

  const { user } = useUser();
  const {
    mutate: editUserTrainingSession,
    isPending: editUserTrainingSessionIsLoading,
  } = useEditUserTrainingSession(translations);
  const {
    mutate: createUserTrainingSession,
    isPending: createUserTrainingSessionIsLoading,
  } = useCreateUserTrainingSession(translations);

  const onSubmitUnbook = () => {
    const editUserTrainingSessionRequest: UpdateCustomerUserTrainingSessionsRequest =
    {
      Id: bookedTrainingSession?.userTrainingSessionId,
      NewStateId: CustomerUserTrainingSessionStates.Cancelled,
    };
    editUserTrainingSession(editUserTrainingSessionRequest);
  };

  const onClickUnbook = () => {
    const options: ToastOptions = {
      onClick: () => onSubmitUnbook(),
      autoClose: false,
      type: toast.TYPE.INFO,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER,
    };

    const confirmDialogText = translations.clicktoconfirm;
    toast(confirmDialogText, options);
  };

  const onClickBook = () => {
    const createUserTrainingSessionRequest: CreateCustomerUserTrainingSessionRequest =
    {
      TrainingSessionId: selectedTrainingSessionId,
      CustomerUserId: user?.CustomerUserId,
    };

    createUserTrainingSession(createUserTrainingSessionRequest);
  };

  const onClickJoinMeeting = () => {
    openInNewTab(bookedTrainingSession?.urlToMeeting);
  };

  const [mappedTrainingSessions, setMappedTrainingSessions] = useState([]);

  const getFormattedTrainingSessionDate = (
    startDate: Date,
    endDate: Date,
    tz: TimeZoneType
  ) => {
    const dateString = getConvertedDateDayjs(startDate, tz).format(
      "ddd DD MMM"
    );
    const startTimeString = getConvertedDateDayjs(startDate, tz).format(
      "HH:mm"
    );
    const endTimeString = getConvertedDateDayjs(endDate, tz).format("HH:mm");
    return `${dateString} ${startTimeString}-${endTimeString}`;
  };

  useEffect(() => {
    if (trainingSessions && timeZone) {
      const newMappedTrainingSessions = trainingSessions.map((p) => ({
        id: p.ID,
        value: getFormattedTrainingSessionDate(
          p.StartDateTimeUtc,
          p.EndDateTimeUtc,
          timeZone
        ),
      }));

      newMappedTrainingSessions.unshift({
        id: -1,
        value: translations.datename,
      });

      setMappedTrainingSessions(newMappedTrainingSessions);
      if (newMappedTrainingSessions.length > 0) {
        setSelectedTrainingSessionId(newMappedTrainingSessions[0].id);
      }
    }

    return () => {
      setMappedTrainingSessions([]);
      setSelectedTrainingSessionId(-1);
    };
  }, [trainingSessions, timeZone, language, translations]);

  return (
    <div>
      {bookedTrainingSession ? (
        <div className="course-card-booked-buttons">
          {bookedTrainingSession?.urlToMeeting && (
            <Button
              disabled={editUserTrainingSessionIsLoading}
              onClick={onClickJoinMeeting}
            >
              <IconContext.Provider value={{ className: "btn-icon" }}>
                <SiMicrosoftteams />
                &nbsp;
                {translations.connecttext}
              </IconContext.Provider>
            </Button>
          )}
          <Button
            isLoading={editUserTrainingSessionIsLoading}
            isWarningButton
            onClick={onClickUnbook}
          >
            <span>{translations.unbook}</span>
          </Button>
        </div>
      ) : (
        <div className="course-card-footer-booking-button">
          <select
            value={selectedTrainingSessionId}
            onChange={onSelectTrainingSession}
          >
            {mappedTrainingSessions.map((p) => (
              <option key={p.id} value={p.id}>
                {p.value}
              </option>
            ))}
          </select>
          <Button
            disabled={selectedTrainingSessionId === -1 || selectedTrainingSessionIsBookable === false}
            isLoading={createUserTrainingSessionIsLoading}
            onClick={onClickBook}
          >
            {translations.booktraining}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CourseCardFooter;